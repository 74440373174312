import { Dayjs } from "dayjs";
import { dayjsExt } from "./dayjs";

export const datetimeFormatter = (
  datetime: Date | Dayjs,
  format: string
): string => {
  let customFormat = format;
  if (format === "date") {
    customFormat = "MMM DD, YYYY";
  }
  if (format === "datetime") {
    customFormat = "MMM DD, YYYY, hh:mm A";
  }
  if (format === "year-month") {
    customFormat = "MMM, YYYY";
  }
  if (format === "departure-arrival") {
    customFormat = "dddd, MMMM DD, YYYY [at] hh:mm A";
  }
  if (format === "complete-short") {
    customFormat = "MM.DD.YY HH:mm";
  }
  return dayjsExt(datetime).format(customFormat);
};
