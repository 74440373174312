import React from "react";

import { DrawerDetailsBlock, Loading, Map } from "@busie/ui-kit";

import { Quote, Trip } from "@busie/utils";
import { useTripRoute } from "~/QuotesAndBookingsPage/model";

interface Props {
  trip: Trip;
  quote: Quote;
}

export const TripRoute: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
}: Props) => {
  const firstLeg = trip._legs[0];
  const lastLeg = trip._legs[trip._legs.length - 1];
  const { data: route, isLoading: isRouteLoading } = useTripRoute({
    vehicleType: firstLeg._mainVehicleType,
    start: `${firstLeg._startLocation.latitude},${firstLeg._startLocation.longitude}`,
    end: `${lastLeg._destinationLocation.latitude},${lastLeg._destinationLocation.longitude}`,
    routeType:
      `${firstLeg._destinationLocation.latitude},${firstLeg._destinationLocation.longitude}` ===
      `${lastLeg._startLocation.latitude},${lastLeg._startLocation.longitude}`
        ? "ROUND_TRIP"
        : "ONE_WAY",
    waypoints: trip._legs
      .slice(0, -1)
      .map(
        ({ _destinationLocation: { latitude, longitude } }) =>
          `${latitude},${longitude}`
      ),
  });

  if (!trip._legs.length) return <div />;
  const markers = trip._legs.map((leg) => ({
    lat: Number(leg._startLocation.latitude),
    lng: Number(leg._startLocation.longitude),
  }));

  markers.push({
    lat: Number(
      trip._legs[trip._legs.length - 1]._destinationLocation.latitude
    ),
    lng: Number(
      trip._legs[trip._legs.length - 1]._destinationLocation.longitude
    ),
  });

  return (
    <DrawerDetailsBlock
      collapsible
      name="Trip Route"
      padding="0"
      className="no-print"
    >
      {isRouteLoading ? (
        <Loading size={24} />
      ) : (
        <Map height="400px" markers={markers} route={route} />
      )}
    </DrawerDetailsBlock>
  );
};
