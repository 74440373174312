import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";

import store from "~/rates/RateMenuPage/store";
import EditRecipePopup from "./EditRecipePopup";
import IngredientPopup from "./EditIngredientPopup";
import { useRatesAuthToken } from "@busie/core";
import { Ingredient } from "@busie/utils";
//import { useQueryClient } from "react-query";

const PopupContainer = observer(() => {
  const authToken = useRatesAuthToken();
  //const queryClient = useQueryClient();

  const onRecipeUpdate = useCallback(() => {
    store.recipeEdit.submitData(authToken);
    //.then(() => queryClient.invalidateQueries("rates"));
  }, [authToken]);

  const onIngredientUpdate = useCallback(
    (ingredient: Ingredient) => {
      store.ingredientModal
        .submitData(authToken, ingredient)
        .then((ingredient) => {
          //queryClient.invalidateQueries("rates");
          store.rates.upsertActiveRecipeIngredient(ingredient);
        });
    },
    [authToken]
  );

  return (
    <>
      <EditRecipePopup
        onClose={store.recipeEdit.closeModal}
        isPopupOpened={store.recipeEdit.isModalOpen}
        data={store.recipeEdit.recipe}
        onUpdate={store.recipeEdit.updateIngredientValue}
        submitDisabled={store.recipeEdit.getIsSubmitDisabled()}
        isNameEdit={store.recipeEdit.isRuleName}
        onSubmit={onRecipeUpdate}
      />
      <IngredientPopup
        onClose={store.ingredientModal.closeModal}
        isPopupOpened={store.ingredientModal.isModalOpen}
        ingredient={store.ingredientModal.ingredient}
        valueInDollars={store.ingredientModal.valueInDollars}
        onUpdate={store.ingredientModal.updateIngredientValue}
        isEdit={store.ingredientModal.isEdit}
        onSubmit={onIngredientUpdate}
      />
    </>
  );
});

export default PopupContainer;
