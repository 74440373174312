import { styled } from "@mui/system";

export const ItemContainer = styled("div")(({ theme }) => ({
  background: "white",
  width: "100%",
  border: `1px solid ${theme.palette.black.plus70}`,
  borderRadius: "6px",
  padding: "24px 18px",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  cursor: "pointer",
}));
