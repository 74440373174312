import { palette, Text, TimeIcon } from "@busie/ui-kit";
import { Invoice } from "@busie/utils";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { dayjsExt } from "@busie/utils";

interface Props {
  invoice?: Invoice;
}

const getInvoiceDaysUntilDue = (invoice: Invoice) => {
  const today = new Date();
  const invoiceCreationDate = dayjsExt(invoice.createdAt);
  const dueDate = invoiceCreationDate.add(invoice.daysUntilDue, "day");
  return dueDate.diff(today, "day");
};

const getIconColor = (days: number | null) => {
  if (!days && days !== 0) return palette.black.main;

  if (days > 10) {
    return palette.green.main;
  } else if (days > 5) {
    return palette.supernova.main;
  } else {
    return palette.red.main;
  }
};

const TooltipIconStyled = styled("div")(() => {
  // const palette = props.theme?.palette;
  return {
    display: "flex",
  };
});

export const InvoiceDueDate: React.FC<React.PropsWithChildren<Props>> = ({
  invoice,
}) => {
  const daysTilDue = invoice ? getInvoiceDaysUntilDue(invoice) : null;

  return !invoice ? null : (
    <Tooltip title={`Invoice due in ${daysTilDue} days`} placement="top">
      <TooltipIconStyled>
        <TimeIcon color={getIconColor(daysTilDue)} />
      </TooltipIconStyled>
    </Tooltip>
  );
};
