import { useQuery } from "react-query";

import { fetchGroup } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";

import { CUSTOMERS_QUERY_KEY } from "~/CustomersPage/entity/model";

interface FetchGroupParams {
  id: string;
}

const GROUP_QUERY_KEY = Symbol();
const DEFAULT_STALE_TIME = 5 * 60 * 1000;

export const useFetchGroup = ({ id }: FetchGroupParams) => {
  const customersAuthToken = useCustomerAuthToken();

  const query = useQuery(
    [CUSTOMERS_QUERY_KEY, GROUP_QUERY_KEY, id],
    () => fetchGroup(customersAuthToken, id),
    {
      enabled: !!customersAuthToken,
      staleTime: DEFAULT_STALE_TIME,
      keepPreviousData: true,
    }
  );

  return query;
};
