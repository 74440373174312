import React from "react";

import { ErrorMessage } from "@busie/ui-kit";

interface Props {
  error: Record<string, unknown> | undefined;
}

export const PlaceError: React.FC<React.PropsWithChildren<Props>> = ({
  error,
}) => {
  return error ? (
    <ErrorMessage>You must select one of the provided options.</ErrorMessage>
  ) : null;
};
