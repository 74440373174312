import React from "react";
import { styled } from "@mui/system";
import VehiclesBlankState from "./components/VehiclesBlankState";
import { observer } from "mobx-react";
import VehiclesStore from "~/vehicles/store";
import VehiclesFormStore from "~/vehicles/store/vehiclesForm.store";
import { dispatchLocationStore } from "~/settings/store/dispatch";
import {
  CommonTable,
  Popup,
  palette,
  FlexContainer,
  Button,
  ModalContent,
  TablePagination,
  CheckmarkIcon,
  CloseIcon,
  Text,
} from "@busie/ui-kit";
import TableContainer from "./components/styled/TableContainer";
import VehicleTypeLabel from "./components//VehicleTypeLabel";
import VehicleListContainer from "./components/VehicleListContainer";
import VehicleAmenitiesRow from "./components/VehicleAmenitiesRow";
import VehicleActionButtons from "./components/VehicleActionButtons";
import {
  useDispatchLocationsAuthToken,
  useVehiclesAuthToken,
} from "@busie/core";
import VehiclesEditForm from "~/vehicles/VehiclesForm/VehiclesEditForm";
import { VEHICLES_PER_PAGE } from "~/vehicles/store/constants";
import { autorun } from "mobx";
import { useMediaQuery } from "@mui/material";
import MobileVehicleView from "./components/MobileVehicleView";
import { Vehicle } from "@busie/utils";

const EditModalContent = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    width: "100%",
    padding: theme.spacing(4),
    paddingBottom: 0,
    paddingTop: 0,
  };
});

const VehiclesList: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const store = VehiclesStore;
    const formStore = VehiclesFormStore;
    const vehiclesAuthToken = useVehiclesAuthToken();
    const dispatchLocationsToken = useDispatchLocationsAuthToken();
    const [isShowingDeleteModal, setIsShowingDeleteModal] =
      React.useState(false);
    const [isShowingEditModal, setIsShowingEditModal] = React.useState(false);
    const [vehicleToDelete, setVehicleToDelete] =
      React.useState<Vehicle | null>(null);
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
      if (vehiclesAuthToken)
        store.fetchVehicles(vehiclesAuthToken, currentPage);
      if (dispatchLocationsToken)
        dispatchLocationStore.setAuthToken(dispatchLocationsToken);
    }, [vehiclesAuthToken, dispatchLocationsToken, currentPage, store]);

    React.useEffect(
      () =>
        autorun(() => {
          if (dispatchLocationStore.authToken) {
            dispatchLocationStore.getDispatchLocations();
          }
        }),
      []
    );

    React.useEffect(() => {
      formStore.setEditMode(isShowingEditModal);
    }, [formStore, isShowingEditModal]);

    const handleDelete = async () => {
      if (vehicleToDelete) {
        const success = await store.deleteVehicle(
          vehiclesAuthToken,
          vehicleToDelete.id
        );

        // TODO: [PT-135] Modal message to communicate the vehicle type was deleted properly?
        if (success) {
          store.fetchVehicles(vehiclesAuthToken, currentPage);
        }
      }

      setIsShowingDeleteModal(false);
    };

    const handleEdit = (vehicle: Vehicle) => {
      formStore.setEditMode(true);
      formStore.setVehicleToEdit(vehicle);
      setIsShowingEditModal(true);
    };

    const BooleanIcon: React.FC<
      React.PropsWithChildren<{ condition: boolean }>
    > = ({ condition = false }) => {
      return condition ? (
        <CheckmarkIcon color={palette?.green?.main} />
      ) : (
        <CloseIcon color={palette?.red?.plus40} />
      );
    };

    const isMobile = useMediaQuery("@media (max-width: 975px)");

    const tableConfig = {
      columnNames: [
        {
          id: "vehicle-type",
          name: "Vehicle type",
          ordered: false,
          width: "15%",
        },
        {
          id: "license-plate",
          name: "License plate #",
          ordered: false,
          width: "25%",
        },
        { id: "total-seats", name: "Total seats", ordered: false, width: "5%" },
        {
          id: "wheelchair-accessibility",
          name: "Wheelchair Accessible",
          ordered: false,
          width: "5%",
        },
        { id: "spab", name: "SPAB", ordered: false, width: "5%" },
        { id: "amenities", name: "Amenities", ordered: false, width: "30%" },
        {
          id: "dispatch-location",
          name: "Dispatch location",
          ordered: false,
          width: "10%",
        },
        { id: "", name: "", ordered: false, width: "5%" },
      ],
      data: store.sortedVehicles.map((v: Vehicle) => ({
        vehicleType: (
          <VehicleTypeLabel
            isMobile={isMobile}
            vehicleType={v.vehicleType.type}
          />
        ),
        licensePlate: v.licensePlate,
        totalSeats: v.seatsTotal,
        wheelchairAccessible: (
          <BooleanIcon condition={v.wheelchairAccessible} />
        ),
        spab: <BooleanIcon condition={v.spabCertified} />,
        amenities: <VehicleAmenitiesRow amenities={v.amenities} />,
        dispatch: dispatchLocationStore.dispatchLocations?.find(
          (location) => location.id === v.dispatchLocationId
        )?.name,
        actions: (
          <VehicleActionButtons
            onEdit={() => handleEdit(v)}
            onDelete={() => {
              setVehicleToDelete(v);
              setIsShowingDeleteModal(true);
            }}
          />
        ),
      })),
    };

    return (
      <>
        <Popup
          isPopupOpened={isShowingDeleteModal}
          onClose={() => setIsShowingDeleteModal(false)}
          title="Delete vehicle"
        >
          <ModalContent>
            <Text>Are you sure you want to delete this vehicle?</Text>
            <FlexContainer columnGap={1} justify="flex-end">
              <Button onClick={handleDelete}>Yes</Button>
              <Button
                typestyle="secondary"
                onClick={() => setIsShowingDeleteModal(false)}
              >
                No
              </Button>
            </FlexContainer>
          </ModalContent>
        </Popup>

        <Popup
          isPopupOpened={isShowingEditModal}
          onClose={() => setIsShowingEditModal(false)}
          title="Edit vehicle"
        >
          <EditModalContent>
            <FlexContainer columnGap={1} justify="flex-end">
              <VehiclesEditForm onClose={() => setIsShowingEditModal(false)} />
            </FlexContainer>
          </EditModalContent>
        </Popup>

        <VehicleListContainer>
          {store.currentFleetItems.length === 0 ? (
            <VehiclesBlankState />
          ) : isMobile ? (
            <>
              {tableConfig.data.map((vehicle) => (
                <MobileVehicleView key={vehicle.licensePlate} item={vehicle} />
              ))}
              <TablePagination
                itemsTotal={store.vehiclesTotal}
                pageNumber={currentPage}
                perPage={VEHICLES_PER_PAGE}
                currentOffset={VEHICLES_PER_PAGE * (currentPage - 1)}
                nextOffset={store.vehiclesNextOffset || store.vehiclesTotal}
                onNextClick={() => setCurrentPage(currentPage + 1)}
                onPrevClick={() => setCurrentPage(currentPage - 1)}
                onToEndClick={() =>
                  setCurrentPage(
                    Math.ceil(store.vehiclesTotal / VEHICLES_PER_PAGE)
                  )
                }
                onToBeginningClick={() => setCurrentPage(1)}
              />
            </>
          ) : (
            <TableContainer>
              <CommonTable
                columnNames={tableConfig.columnNames}
                data={tableConfig.data}
              />
              <TablePagination
                itemsTotal={store.vehiclesTotal}
                pageNumber={currentPage}
                perPage={VEHICLES_PER_PAGE}
                currentOffset={VEHICLES_PER_PAGE * (currentPage - 1)}
                nextOffset={store.vehiclesNextOffset || store.vehiclesTotal}
                onNextClick={() => setCurrentPage(currentPage + 1)}
                onPrevClick={() => setCurrentPage(currentPage - 1)}
                onToEndClick={() =>
                  setCurrentPage(
                    Math.ceil(store.vehiclesTotal / VEHICLES_PER_PAGE)
                  )
                }
                onToBeginningClick={() => setCurrentPage(1)}
              />
            </TableContainer>
          )}
        </VehicleListContainer>
      </>
    );
  }
);

export default VehiclesList;
