import React from "react";
import { observer } from "mobx-react-lite";
import { H4, palette, TextInput } from "@busie/ui-kit";
import store from "~/vehicles/store";

import InputFieldStyled from "./styled/InputFieldStyled";

const DescriptionField: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    return (
      <InputFieldStyled>
        <H4 color={palette?.black?.plus40}>Reservation description</H4>
        <TextInput
          onChange={(e) => store.updateReservationDescription(e.target.value)}
          value={store.reservationPopup.form.description}
          required
          errorMessage={
            store.reservationPopup.form.description.length === 0
              ? "Please provide the description"
              : ""
          }
        />
      </InputFieldStyled>
    );
  }
);

export default DescriptionField;
