import React from "react";
import { observer } from "mobx-react-lite";
import { styled, Theme } from "@mui/system";

import {
  FlexContainer,
  IconButton,
  Menu,
  palette,
  CalendarIcon,
  GridIcon,
  MediumViewIcon,
  ChevronDown,
} from "@busie/ui-kit";

import store from "~/vehicles/store/vehicles.store";
import { VehiclesPageViewType } from "@busie/api";

interface StyledProps {
  theme?: Theme;
}

const ViewModeSwitchSwitchStyled = styled(FlexContainer)(
  (props: StyledProps) => {
    const palette = props.theme?.palette;
    return {
      backgroundColor: palette?.black?.plus100,
      border: `1px solid ${palette?.black?.plus70}`,
      borderRadius: "6px",
      color: palette?.black?.plus100,
      position: "relative",
      "button:first-of-type": {
        background: palette?.black?.plus10,
        "&:hover,&:focus": {
          background: palette?.black?.plus30,
        },
      },
      ".menu-block": {
        position: "absolute",
        top: "40px",
        left: 0,
      },
    };
  }
);

const ViewModeSwitchSwitch: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const setCookbooksView = (type: VehiclesPageViewType) => {
      store.setView(type);
    };

    const getIcon = (type: VehiclesPageViewType) => {
      const color = palette?.black?.plus100;
      switch (type) {
        case "collapsed":
          return <MediumViewIcon color={color} />;
        case "list":
          return <GridIcon color={color} />;
        default:
          return <CalendarIcon color={color} />;
      }
    };
    return (
      <Menu
        toggler={
          <ViewModeSwitchSwitchStyled px={0.5} py={0.5} align="center">
            <IconButton
              type="button"
              size={31}
              icon={getIcon(store.viewMode)}
            />
            <IconButton
              type="button"
              size={31}
              highlightOnHover={false}
              icon={<ChevronDown color={palette?.black?.plus50} />}
            />
          </ViewModeSwitchSwitchStyled>
        }
        items={[
          {
            content: "Calendar view",
            action: () => setCookbooksView("calendar"),
          },
          {
            content: "List view",
            action: () => setCookbooksView("list"),
          },
          {
            content: "Collapsed view",
            action: () => setCookbooksView("collapsed"),
          },
        ]}
      />
    );
  });

export default ViewModeSwitchSwitch;
