interface Props {
  size?: number;
}

export const RulerIcon: React.FC<React.PropsWithChildren<Props>> = ({
  size = 24,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.4062 4.53125L19.7823 0.907349L1.34485 19.3448L4.96875 22.9687L23.4062 4.53125Z"
      stroke="#3B3B3B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="2.17678"
      y1="18.8232"
      x2="3.17678"
      y2="19.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="3.17678"
      y1="17.8232"
      x2="4.17678"
      y2="18.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="4.17678"
      y1="16.8232"
      x2="6.2981"
      y2="18.9445"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="6.17678"
      y1="14.8232"
      x2="7.17678"
      y2="15.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="7.17678"
      y1="13.8232"
      x2="8.17678"
      y2="14.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="5.17678"
      y1="15.8232"
      x2="6.17678"
      y2="16.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="8.17678"
      y1="12.8232"
      x2="10.2981"
      y2="14.9445"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="10.1768"
      y1="10.8232"
      x2="11.1768"
      y2="11.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="11.1768"
      y1="9.82322"
      x2="12.1768"
      y2="10.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="9.17678"
      y1="11.8232"
      x2="10.1768"
      y2="12.8232"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="12.1768"
      y1="8.82322"
      x2="14.2981"
      y2="10.9445"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="14.1768"
      y1="6.82322"
      x2="15.1768"
      y2="7.82322"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="15.1768"
      y1="5.82322"
      x2="16.1768"
      y2="6.82322"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="13.1768"
      y1="7.82322"
      x2="14.1768"
      y2="8.82322"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="16.1768"
      y1="4.82322"
      x2="18.2981"
      y2="6.94454"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="18.1768"
      y1="2.82322"
      x2="19.1768"
      y2="3.82322"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="19.1768"
      y1="1.82322"
      x2="20.1768"
      y2="2.82322"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
    <line
      x1="17.1768"
      y1="3.82322"
      x2="18.1768"
      y2="4.82322"
      stroke="#3B3B3B"
      stroke-width="0.5"
    />
  </svg>
);
