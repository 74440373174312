import { TextArea } from "@busie/ui-kit";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
}

export const Controlled: <T extends FieldValues>(
  props: Props<T>
) => React.ReactElement = ({ control, name, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextArea
          {...field}
          label={label}
          placeholder="add a few words..."
          sx={{
            width: "100%",
            resize: "none",
            height: "100px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        />
      )}
    />
  );
};
