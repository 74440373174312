import { Vehicle } from "@busie/utils";
import { FlexContainer, H3 } from "@busie/ui-kit";
import { observer } from "mobx-react";
import React from "react";

import VehicleBlock from "./components/VehicleBlock";

import ListViewStyled from "./styled/ListViewStyled";
import VehiclesStore from "~/vehicles/store/vehicles.store";
import uniqBy from "lodash.uniqby";

const ListView: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  const reservations = VehiclesStore.reservations;
  const vehicles = uniqBy(
    reservations.map((r) => r.vehicle),
    "id"
  ) as Vehicle[];

  const blocks = vehicles.map((vehicle, index) => {
    const vehicleReservations = reservations.filter(
      (r) => r.vehicleId === vehicle.id
    );
    if (reservations && reservations.length > 0) {
      return (
        <VehicleBlock
          key={index}
          vehicle={vehicle}
          reservations={vehicleReservations}
        />
      );
    } else {
      return null;
    }
  });
  if (blocks.every((el) => el === null)) {
    return (
      <FlexContainer justify="center">
        <H3>No reservations found!</H3>
      </FlexContainer>
    );
  }
  return <ListViewStyled>{blocks}</ListViewStyled>;
});

export default ListView;
