import { createCustomerRegExp } from "./constants";
import * as types from "./store.types";
import { Customer } from "@busie/utils";

export interface ErrorMessage {
  customer?: string;
  name?: string;
  email?: string;
  organization?: string;
  phoneNumber?: string;
  groupname?: string;
}

export interface ValidationResponse {
  valid: boolean;
  errors: ErrorMessage;
}

export const validateForm = ({
  selectionMode,
  customer,
  fields,
}: {
  selectionMode: types.SelectionMode;
  customer: Customer | null;
  fields: types.CustomerFormFields;
}): ValidationResponse => {
  const result: ValidationResponse = {
    valid: true,
    errors: {},
  };
  if (selectionMode === "select_existing_customer") {
    result.valid = customer !== null;
    result.errors["customer"] = "Customer is required";
  }
  if (selectionMode === "create_new_customer") {
    if (!fields.name.trim().match(createCustomerRegExp.NAME)) {
      result.valid = false;
      result.errors["name"] = "Full name is required";
    }
    if (!fields.email.trim().match(createCustomerRegExp.EMAIL)) {
      result.valid = false;
      result.errors["email"] = "Valid email is required";
    }
    if (!fields.organization) {
      result.valid = false;
      result.errors["organization"] = "Please select an organization";
    }
    if (fields.phoneNumber.length < 10) {
      result.valid = false;
      result.errors["phoneNumber"] = "Please enter a valid phone number";
    }
  }
  return result;
};

export const validateEmbeddedForm = ({
  customer,
  fields,
}: {
  customer: Customer | null;
  fields: types.CustomerFormFields;
}): ValidationResponse => {
  const result: ValidationResponse = {
    valid: true,
    errors: {},
  };
  if (!fields.name.trim().match(createCustomerRegExp.NAME)) {
    result.valid = false;
    result.errors["name"] = "Full name is required";
  }
  if (fields.groupname === "") {
    result.valid = false;
    result.errors["groupname"] = "Please enter an organization name";
  }
  if (!fields.email.trim().match(createCustomerRegExp.EMAIL)) {
    result.valid = false;
    result.errors["email"] = "Valid email is required";
  }
  if (fields.phoneNumber.length < 10) {
    result.valid = false;
    result.errors["phoneNumber"] = "Please enter your phone number";
  }
  return result;
};
