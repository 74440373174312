import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { metersToMiles, TripLeg } from "@busie/utils";
import { useDispatchLocationsAuthToken } from "@busie/core";
import { Trip } from "@busie/utils";
import { getDispatchLocation } from "@busie/api";
import { Loading } from "@busie/ui-kit";

import { LegBlock } from "./LegBlock";

interface Props {
  trip: Trip;
  dispatchLocationNameProp?: string;
  showPriceData?: boolean;
  // TODO: REFACTOR
  isEmbedded?: boolean;
}
export const TripLegsDetails: React.FC<React.PropsWithChildren<Props>> =
  observer(
    ({ trip, isEmbedded, dispatchLocationNameProp, showPriceData = true }) => {
      const tripLegs: TripLeg[] = trip._legs;

      // TODO: refactor. This is a TEMPORARY hack to get the default dispatch location name to display in the summary block for the embedded form
      // due to the fact that Dispatch Location API Does not allow guest fetching of dispatch location, and ETP-BFF does not support dispatch location fetching at this time.
      const dispatchLocationAuthToken =
        !isEmbedded && useDispatchLocationsAuthToken();
      const dispatchLocationId = isEmbedded ? null : trip._dispatchId;

      const [dispatchLocationNameState, setdispatchLocationNameState] =
        useState("");

      useEffect(() => {
        const fetchDispatchLocation = async () => {
          if (!dispatchLocationId) {
            return setdispatchLocationNameState("Dispatch Location");
          }
          if (dispatchLocationAuthToken) {
            const dispatchLocation = await getDispatchLocation(
              dispatchLocationId,
              dispatchLocationAuthToken
            );
            setdispatchLocationNameState(
              dispatchLocation.name || "Dispatch Location"
            );
          }
        };

        !dispatchLocationNameProp && fetchDispatchLocation();
      }, [
        dispatchLocationAuthToken,
        dispatchLocationId,
        dispatchLocationNameProp,
      ]);

      const dispatchLocationName: string =
        dispatchLocationNameProp || dispatchLocationNameState;
      // TODO: set distance and price for every waypoint
      return dispatchLocationName ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {tripLegs?.map((leg, index, legs) => {
            return (
              <LegBlock
                key={index}
                legNumber={index + 1}
                leg={leg}
                isDeadLeg={index === 0 || index === legs.length - 1}
                dispatchLocationName={dispatchLocationName}
                distance={metersToMiles(leg._meters)}
                distanceUnit={"mi"}
                showPriceData={!isEmbedded && showPriceData}
                isLastLeg={index === legs.length - 1}
                isFirstLeg={index === 0}
              />
            );
          })}
        </>
      ) : (
        <Loading />
      );
    }
  );
export default TripLegsDetails;
