import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Popover, useMediaQuery } from "@mui/material";
import {
  ChevronDown,
  theme,
  palette,
  Button,
  ChevronUp,
  FlexContainer,
  Select,
  FilterSelectorStyled,
} from "@busie/ui-kit";
import VehiclesStore from "~/vehicles/store";
import { AmenityValues, VehiclesFilter } from "@busie/utils";
import { dispatchLocationStore } from "~/settings/store";
import {
  mapVehicleTypeToName,
  mapAmenityToName,
  useVehiclesAuthToken,
} from "@busie/core";
import { observer } from "mobx-react";
import { SearchBlockStyled } from "~/vehicles/styled/SearchBlockStyled";

const VehiclesFilterStyled = styled("div")({
  position: "relative",
});

const defaultFilterState = {
  dispatchLocationId: "all",
  amenities: [-1],
  spab: "not specified",
  wheelchairAccessibility: "not specified",
  vehicleTypeId: "all",
};

const VehicleFleetFilter: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const vehiclesAuthToken = useVehiclesAuthToken();
    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [filters, setFilters] = useState<VehiclesFilter>(defaultFilterState);
    const open = Boolean(anchorEl);

    const setFilterValue = (
      key: keyof VehiclesFilter,
      value: string | number
    ) => {
      setFilters((filters) => ({
        ...filters,
        [key]: value,
      }));
    };

    const setAmenitiesFilterValue = (value: number[]) => {
      let amenities: number[];
      //if none of the items are selected or
      //selected items include "not specified" not in the first place - only return "not specified"
      if (!value.length || (value.includes(-1) && value[0] !== -1)) {
        amenities = [-1];
      } else amenities = value.filter((v) => v !== -1);

      return setFilters((filters) => ({
        ...filters,
        amenities,
      }));
    };

    useEffect(() => {
      if (!vehiclesAuthToken) return;
      VehiclesStore.fetchVehicleTypes(vehiclesAuthToken);
    }, [vehiclesAuthToken]);

    const dispatchLocationsItems = [
      {
        name: "All locations",
        value: "all",
      },
      ...(dispatchLocationStore.dispatchLocations?.map((location) => ({
        name: location.name,
        value: location.id,
      })) || []),
    ];

    const amenitiesItems = [
      { name: "Not specified", value: -1 },
      ...AmenityValues.map((amenity) => ({
        name: mapAmenityToName(amenity),
        value: amenity,
      })),
    ];

    const spabItems = [
      { name: "Not specified", value: "not specified" },
      { name: "Certified", value: true },
      { name: "Not certified", value: false },
    ];

    const wheelchairAccessibilityItems = [
      { name: "Not specified", value: "not specified" },
      { name: "Accessible", value: true },
      { name: "Not accessible", value: false },
    ];

    const vehicleTypeItems = [
      { name: "All vehicles", value: "all" },
      ...VehiclesStore.vehicleTypes.map((vehicleType) => ({
        value: vehicleType.id,
        name: mapVehicleTypeToName(vehicleType.type),
      })),
    ];

    const applyFilters = () => {
      VehiclesStore.setVehiclesFilter(vehiclesAuthToken, filters);
      setAnchorEl(null);
    };

    const clearFilters = () => {
      setFilters(defaultFilterState);
    };

    return (
      <VehiclesFilterStyled>
        {!isMobile && (
          <Button
            typestyle="secondary"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            size="small"
            endIcon={
              !open ? <ChevronDown size={22} /> : <ChevronUp size={22} />
            }
            sx={{
              border: `1px solid ${palette.black.plus70} !important`,
              height: "40px",
            }}
          >
            <span>Filter</span>
          </Button>
        )}
        {isMobile && (
          <FilterSelectorStyled
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{ height: "40px", padding: "3px 10px !important" }}
          >
            •••
          </FilterSelectorStyled>
        )}

        <Popover
          id="filters"
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SearchBlockStyled>
            <Paper
              elevation={3}
              sx={{
                padding: "23px",
                width: "270px",
                [theme.breakpoints.down("tablet")]: {
                  width: "calc(100vw - 40px)",
                },
              }}
            >
              <Select
                label="Location"
                onChange={(e) =>
                  setFilterValue("dispatchLocationId", e.target.value)
                }
                value={filters.dispatchLocationId}
                items={dispatchLocationsItems}
              />
              <Select
                label="Amenities"
                multiple
                onChange={(e) => setAmenitiesFilterValue(e.target.value)}
                value={filters.amenities}
                items={amenitiesItems}
                displayEmpty
              />
              <Select
                label="SPAB"
                onChange={(e) => setFilterValue("spab", e.target.value)}
                value={filters.spab}
                items={spabItems}
              />
              <Select
                label="Wheelchair accessibility"
                onChange={(e) =>
                  setFilterValue("wheelchairAccessibility", e.target.value)
                }
                value={filters.wheelchairAccessibility}
                items={wheelchairAccessibilityItems}
              />
              <Select
                label="Vehicle"
                onChange={(e) =>
                  setFilterValue("vehicleTypeId", e.target.value)
                }
                value={filters.vehicleTypeId}
                items={vehicleTypeItems}
              />
              <FlexContainer fullWidth columnGap={1} pt={2}>
                <Button fullWidth typestyle="secondary" onClick={clearFilters}>
                  Clear
                </Button>
                <Button fullWidth typestyle="primary" onClick={applyFilters}>
                  Apply
                </Button>
              </FlexContainer>
            </Paper>
          </SearchBlockStyled>
        </Popover>
      </VehiclesFilterStyled>
    );
  }
);

export default VehicleFleetFilter;
