import { styled } from "@mui/system";
import { Paper, PaperProps } from "@mui/material";

type Props = { enabled: boolean } & PaperProps;

export const IntegrationCardStyled = styled(Paper)<Props>((props) => {
  const {
    enabled,
    theme: { palette },
  } = props;

  const boxShadowColor = enabled
    ? palette.green.minus5
    : "rgba(34, 34, 34, 0.15)";
  return {
    width: "100%",
    maxWidth: "280px",
    textAlign: "center",
    border: `1px solid ${
      enabled ? palette.green.minus5 : palette.black.plus80
    }`,
    boxShadow: `0 0 0 ${enabled ? 1 : 0}px ${boxShadowColor}`,
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0px 8px 24px ${boxShadowColor}`,
    },
  };
});
