import * as yup from "yup";
import { PAYMENT_METHOD_OPTIONS } from "./constants";
import { InvoicePaymentMethod } from "@busie/utils";

export const schema = yup.object({
  paymentMethod: yup
    .string()
    .oneOf<InvoicePaymentMethod>(
      PAYMENT_METHOD_OPTIONS,
      `Payment Method must be one of: ${PAYMENT_METHOD_OPTIONS.join(", ")}`
    )
    .required("Payment method is required"),
});
