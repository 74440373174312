import { styled } from "@mui/system";

export const Styled = styled("div")(() => {
  return {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "30px",
    padding: "20px 0",
  };
});
