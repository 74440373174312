import { Select } from "@busie/ui-kit";
import { DispatchLocation } from "@busie/utils";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface Props<T extends FieldValues> {
  dispatchLocations: DispatchLocation[];
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
}

export const Controlled: <T extends FieldValues>(
  props: Props<T>
) => React.ReactElement = ({
  control,
  dispatchLocations,
  name,
  disabled = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          label="Select Dispatch Location"
          items={dispatchLocations.map(({ id, name }) => ({
            name,
            value: id,
          }))}
          disabled={disabled}
          fullWidth
        />
      )}
    />
  );
};
