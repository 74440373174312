import axios from "axios";
import {
  CreateTripLeg,
  fetchBooking,
  fetchCustomer,
  fetchGroup,
  fetchQuote,
  fetchTrip,
  getDispatchLocations,
  patchQuoteFuelSurcharge,
  patchQuoteLastMinuteSurgeCharge,
  patchQuotePrice,
  patchQuoteContactData,
  patchTrip,
  QuoteWithAttrs,
  patchQuoteTaxSurcharge,
  updateQuoteEnablePlatformPricingMarkup,
} from "@busie/api";
import {
  Booking,
  Customer,
  DispatchLocation,
  Location,
  Trip,
  TripLeg,
  centsToDollars,
  dollarsToCents,
} from "@busie/utils";
import { FetchingStatus } from "@busie/core";
import { makeAutoObservable, runInAction } from "mobx";
import { notificationStore } from "@busie/features";
import {
  LegToChange,
  PopupName,
  PopupState,
  RecipeToChange,
} from "./store.types";
import { createRouteRequest } from "@busie/api";
import { toHours } from "@busie/api";

class Store {
  quoteId: string | undefined = undefined;
  bookingId: string | undefined = undefined;
  quote: QuoteWithAttrs | undefined = undefined;
  booking: Booking | undefined = undefined;
  trip: Trip | undefined = undefined;
  customer: Customer | undefined = undefined;
  mainContactId: string | undefined = undefined;
  mainContactEmail: string | undefined = undefined;
  mainContactName: string | undefined = undefined;
  dispatchLocations: DispatchLocation[] = [];
  dispatchLocation: string | undefined = undefined;
  additionalInfo: string | undefined = undefined;
  quotePrice = 0;
  fuelSurcharge = 0;
  taxSurcharge = 0;
  lastMinuteSurgeCharge = 0;
  enablePlatformPricingMarkup = false;
  legsToChange: LegToChange[] = [];
  newLegs: TripLeg[] = [];
  recipesToChange: RecipeToChange[] = [];
  submittingStatus: FetchingStatus = "notFetched";
  popups: PopupState = {
    addVehicle: {
      isOpen: false,
    },
    tripUpdate: {
      isOpen: false,
    },
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchQuote(authToken: string, quoteId?: string) {
    const id = quoteId || this.quoteId;
    if (id) {
      try {
        const quote = await fetchQuote(authToken, id);
        runInAction(() => {
          this.quote = quote;
          this.mainContactEmail = quote._contactEmail;
          this.mainContactName = quote._contactName;
          this.quotePrice = centsToDollars(quote._price || 0);
          this.fuelSurcharge = quote._fuelSurcharge;
          this.taxSurcharge = quote._taxSurcharge;
          this.lastMinuteSurgeCharge = quote._lastMinuteSurgeCharge;
          this.enablePlatformPricingMarkup =
            quote._enablePlatformPricingMarkup || false;
        });
      } catch (e) {
        notificationStore.setNotificationFromError(e);
      }
    }
  }
  async fetchBooking(authToken: string) {
    if (this.bookingId) {
      try {
        const booking = await fetchBooking(authToken, this.bookingId);
        this.booking = booking;
      } catch (e) {
        notificationStore.setNotificationFromError(e);
      }
    }
  }
  async fetchTrip(authToken: string) {
    if (this.quote) {
      try {
        const trip = await fetchTrip(authToken, this.quote._experienceId, {
          populateLegs: true,
        });
        this.setTrip(trip);
        this.mainContactId = trip._mainContactId;
      } catch (e) {
        notificationStore.setNotificationFromError(e);
      }
    }
  }
  async fetchCustomer(authToken: string, id: string) {
    try {
      const customer = await fetchCustomer(authToken, id);
      const group = await fetchGroup(authToken, customer.groupIds[0]);
      this.customer = { ...customer, groupName: group.name };
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  }
  async fetchDispatchLocations(authToken: string) {
    try {
      const data = await getDispatchLocations(authToken);
      this.dispatchLocations = data;
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  }

  async setItems(
    quotesAuthToken: string,
    tripsAuthToken: string,
    isBooking: boolean,
    isAdmin: boolean,
    dispatchLocationAuthToken?: string
  ) {
    if (isBooking) {
      await this.fetchBooking(quotesAuthToken);
      await this.fetchQuote(quotesAuthToken, this.booking?._quoteId);
    } else {
      await this.fetchQuote(quotesAuthToken);
    }
    await this.fetchTrip(tripsAuthToken);
    if (isAdmin && dispatchLocationAuthToken) {
      await this.fetchDispatchLocations(dispatchLocationAuthToken);
      this.dispatchLocation = this.trip?._dispatchId;
    }
  }

  async getUpdatedLegsPayload(trip: Trip, pathfinderAuthToken: string) {
    if (!this.newLegs.length) return [];

    const firstLeg = trip._legs[0];
    const lastLeg = trip._legs[trip._legs.length - 1];

    const start = `${firstLeg._destinationLocation.latitude},${firstLeg._destinationLocation.longitude}`;
    const end = `${lastLeg._startLocation.latitude},${lastLeg._startLocation.longitude}`;

    const waypoints = this.newLegs
      .filter(
        (l, index, legsArray) => index > 1 && index !== legsArray.length - 1
      )
      .map(
        (leg) =>
          `${leg._startLocation.latitude},${leg._startLocation.longitude}`
      );

    const route = await createRouteRequest(pathfinderAuthToken, {
      vehicleType: trip._legs[0]._mainVehicleType || "MOTOR_COACH",
      routeType: start === end ? "ROUND_TRIP" : "ONE_WAY",
      start,
      end,
      waypoints,
    });

    const legs = this.newLegs.map((leg, index) => {
      const routeLeg = route.legs[index + 1];

      return {
        startLocation: leg._startLocation,
        destinationLocation: leg._destinationLocation,
        departureDatetime: leg._departureDateTime,
        arrivalDatetime: leg._arrivalDateTime,
        meters: routeLeg?.distance || 0,
        hours: toHours(routeLeg?.estimateTravelTime) || 0,
      } as CreateTripLeg;
    });

    route.legs.forEach((leg, index) => {
      const nextIndex = index + 1;
      legs[nextIndex] = {
        ...legs[nextIndex],
        meters: leg.distance,
        hours: toHours(leg.estimateTravelTime),
      };
    });

    return legs;
  }

  async submitTrip(tripsAuthToken: string, pathfinderAuthToken: string) {
    this.submittingStatus = "fetching";

    try {
      if (this.trip && Array.isArray(this.trip?._legs)) {
        const legs = await this.getUpdatedLegsPayload(
          this.trip,
          pathfinderAuthToken
        );

        await patchTrip(tripsAuthToken, this.trip?._id || "", {
          passengers: this.trip?._passengers || 0,
          wheelchairs: this.trip?._wheelchairs || 0,
          additionalInformation: this.trip?._additionalInformation || "",
          dispatchId: this.dispatchLocation || "",
          mainContactId: this.mainContactId || this.trip?._mainContactId || "",
          legs,
        });
        this.newLegs = [];
        this.submittingStatus = "fetched";
      } else {
        throw Error("Failed to update trip");
      }
    } catch (err) {
      this.submittingStatus = "failedFetching";
      if (axios.isAxiosError(err)) {
        throw new Error(err.response?.data?.message || err.message);
      } else {
        throw err;
      }
    }
  }

  async submit(tripsAuthToken: string, quotesAuthToken: string) {
    this.submittingStatus = "fetching";
    try {
      const quoteId = this.booking ? this.booking._quoteId : this.quoteId || "";
      await this.updateQuotePrice(quotesAuthToken, quoteId);
      await this.updateFuelSurcharge(quotesAuthToken, quoteId);
      await this.updateTaxSurcharge(quotesAuthToken, quoteId);
      await this.updateLastMinuteSurgeCharge(quotesAuthToken, quoteId);
      await this.updateEnablePlatformPricingMarkup(quotesAuthToken, quoteId);
      await this.updateQuoteContactData(quotesAuthToken, quoteId);
      this.submittingStatus = "fetched";
    } catch (e) {
      this.submittingStatus = "failedFetching";
      throw e;
    }
  }

  private async updateQuotePrice(
    token: string,
    quoteId: string
  ): Promise<void> {
    // noop if theres no change
    if (this.quotePrice === centsToDollars(this.quote?._price || 0)) return;

    await patchQuotePrice(token, quoteId, dollarsToCents(this.quotePrice));
  }

  private async updateFuelSurcharge(
    token: string,
    quoteId: string
  ): Promise<void> {
    // noop if there's no change
    if (this.fuelSurcharge === this.quote?._fuelSurcharge) return;

    await patchQuoteFuelSurcharge(token, quoteId, this.fuelSurcharge);
  }

  private async updateTaxSurcharge(
    token: string,
    quoteId: string
  ): Promise<void> {
    // noop if there's no change
    if (this.taxSurcharge === this.quote?._taxSurcharge) return;

    await patchQuoteTaxSurcharge(token, quoteId, this.taxSurcharge);
  }

  private async updateLastMinuteSurgeCharge(
    token: string,
    quoteId: string
  ): Promise<void> {
    // noop if there's no change
    if (this.lastMinuteSurgeCharge === this.quote?._lastMinuteSurgeCharge)
      return;

    await patchQuoteLastMinuteSurgeCharge(
      token,
      quoteId,
      this.lastMinuteSurgeCharge
    );
  }

  private async updateEnablePlatformPricingMarkup(
    token: string,
    quoteId: string
  ): Promise<void> {
    // noop if there's no change
    if (
      this.enablePlatformPricingMarkup ===
      this.quote?._enablePlatformPricingMarkup
    )
      return;

    await updateQuoteEnablePlatformPricingMarkup(
      token,
      quoteId,
      this.enablePlatformPricingMarkup || false
    );
  }

  private async updateQuoteContactData(
    token: string,
    quoteId: string
  ): Promise<void> {
    // noop if there's no change
    if (
      this.mainContactName === this.quote?._contactName &&
      this.mainContactEmail === this.quote?._contactEmail
    ) {
      return;
    }

    await patchQuoteContactData(
      token,
      quoteId,
      this.mainContactName || "",
      this.mainContactEmail || ""
    );
  }

  setQuoteId(id: string) {
    runInAction(() => {
      this.quoteId = id;
    });
  }
  setBookingId(id: string) {
    runInAction(() => {
      this.bookingId = id;
    });
  }
  setCustomer(customer: Customer | null) {
    if (customer) {
      this.customer = customer;
    }
  }
  setMainContactId(mainContactId: string) {
    this.mainContactId = mainContactId;
  }
  setMainContactEmail(mainContactEmail: string) {
    this.mainContactEmail = mainContactEmail;
  }
  setMainContactName(mainContactName: string) {
    this.mainContactName = mainContactName;
  }
  setTrip(trip: Trip) {
    this.trip = trip;
  }
  setQuote(quote: QuoteWithAttrs) {
    this.quote = quote;
  }
  setDispatchLocation(location: string) {
    runInAction(() => {
      this.dispatchLocation = location;
    });
  }
  setQuotePrice(price: number) {
    runInAction(() => {
      this.quotePrice = price;
    });
  }
  setFuelSurcharge(surcharge: number) {
    runInAction(() => {
      this.fuelSurcharge = surcharge;
    });
  }

  setTaxSurcharge(surcharge: number) {
    runInAction(() => {
      this.taxSurcharge = surcharge;
    });
  }

  setLastMinuteSurgeCharge(surcharge: number) {
    runInAction(() => {
      this.lastMinuteSurgeCharge = surcharge;
    });
  }

  setEnablePlatformPricingMarkup(enable: boolean) {
    runInAction(() => {
      this.enablePlatformPricingMarkup = enable;
    });
  }

  setTripAdditionalInfo(additionalInfo: string) {
    runInAction(() => {
      this.additionalInfo = additionalInfo;
    });
  }
  togglePopup(name: PopupName, state: boolean) {
    this.popups[name]["isOpen"] = state;
  }
  addLegToChange(legToChange: LegToChange) {
    let legExists = this.legsToChange.find(
      (leg) => leg.legId === legToChange.legId
    );
    if (legExists) {
      legExists = legToChange;
      return;
    }
    this.legsToChange.push(legToChange);
  }
  updateLeg(legId: string, newLocation: Omit<Location, "_id">) {
    if (this.trip && Array.isArray(this.trip._legs)) {
      let destinationLegIndex = 0;
      this.newLegs = this.trip._legs.map((tripLeg: TripLeg, index: number) => {
        if (tripLeg._id === legId) {
          tripLeg._startLocation.address = newLocation.address;
          tripLeg._startLocation.latitude = newLocation.latitude;
          tripLeg._startLocation.longitude = newLocation.longitude;
          destinationLegIndex = index - 1;
        }
        return tripLeg;
      });
      if (destinationLegIndex >= 0) {
        this.newLegs[destinationLegIndex]._destinationLocation.address =
          newLocation.address;
        this.newLegs[destinationLegIndex]._destinationLocation.latitude =
          newLocation.latitude;
        this.newLegs[destinationLegIndex]._destinationLocation.longitude =
          newLocation.longitude;
      }
    }
  }
  addLeg(currentLeg: TripLeg, index: number) {
    if (!this.trip) return;

    const newLeg: TripLeg = {
      _startLocation: { ...currentLeg._destinationLocation },
      _destinationLocation: { ...this.trip._legs[index + 1]._startLocation },
      _departureDateTime: currentLeg._arrivalDateTime,
      _arrivalDateTime: this.trip._legs[index + 1]._departureDateTime,
      _tripId: currentLeg._tripId,
      _previousLeg: currentLeg._id,
      _nextLeg: currentLeg._nextLeg,
      _mainVehicleType: currentLeg._mainVehicleType,
      _id: "",
      _createdAt: "",
      _updatedAt: "",
      _status: "",
      _legPrice: [],
      _meters: 0,
      _hours: 0,
    };

    currentLeg._nextLeg = newLeg._id;

    const l = [...this.trip._legs];
    l.splice(index + 1, 0, newLeg);
    this.trip._legs = [];

    setTimeout(() => {
      if (!this.trip) return;
      this.setTrip({ ...this.trip, _legs: l });
      if (Array.isArray(this.trip._legs)) {
        this.newLegs = [...this.trip._legs];
      }
    }, 0);
  }
  removeLeg(index: number) {
    if (!this.trip) return;

    const l = [...this.trip._legs];
    const currentLeg = l[index];
    const previousLeg = l[index - 1];
    const nextLeg = l[index + 1];

    if (previousLeg) {
      previousLeg._nextLeg = currentLeg._nextLeg;
    }
    if (nextLeg) {
      nextLeg._previousLeg = currentLeg._previousLeg;
    }

    l.splice(index, 1);
    this.trip._legs = [];

    setTimeout(() => {
      if (!this.trip) return;
      this.setTrip({ ...this.trip, _legs: l });
      if (Array.isArray(this.trip._legs)) {
        this.newLegs = [...this.trip._legs];
      }
    }, 0);
  }

  updateLegDepartureDateTime(legId: string, departureDateTime: Date) {
    if (this.trip && Array.isArray(this.trip._legs)) {
      this.newLegs = this.trip._legs.map((tripLeg: TripLeg) => {
        if (tripLeg._id === legId) {
          tripLeg._departureDateTime = departureDateTime.toISOString();
          tripLeg._arrivalDateTime = new Date(
            departureDateTime.getTime() + tripLeg._hours * 60 * 60 * 1000
          ).toISOString();
        }
        return tripLeg;
      });
    }
  }
  addRecipeToChange(recipeToChange: RecipeToChange) {
    let recipeExists = this.recipesToChange.find(
      (r) => r.legId === recipeToChange.legId
    );
    if (recipeExists) {
      recipeExists = recipeToChange;
      return;
    }
    this.recipesToChange.push(recipeToChange);
  }
  resetForm() {
    this.quoteId = undefined;
    this.bookingId = undefined;
    this.quote = undefined;
    this.booking = undefined;
    this.trip = undefined;
    this.customer = undefined;
    this.mainContactEmail = undefined;
    this.mainContactName = undefined;
    this.mainContactId = undefined;
    this.dispatchLocations = [];
    this.dispatchLocation = undefined;
    this.quotePrice = 0;
    this.fuelSurcharge = 0;
    this.enablePlatformPricingMarkup = false;
    this.legsToChange = [];
    this.recipesToChange = [];
    this.submittingStatus = "notFetched";
    this.popups = {
      addVehicle: {
        isOpen: false,
      },
      tripUpdate: {
        isOpen: false,
      },
    };
  }
}

export const store = new Store();
