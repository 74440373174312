import React from "react";

import { User } from "@busie/utils";
import {
  FlexContainer,
  Loading,
  OrganizationMemberWithAvatar,
  Select,
  Text,
  palette,
} from "@busie/ui-kit";
import { Subtitle } from "../Subtitle";
import { useUsers } from "@busie/core";

interface Props {
  assigneeId: string | undefined;
  onSelect: (assigneeId: string) => void;
}

export const Assignee: React.FC<Props> = ({ assigneeId, onSelect }) => {
  const { data: usersMap = {}, isLoading: isUsersLoading } = useUsers();

  const renderValue = (selected: unknown) =>
    isUsersLoading ? (
      <Loading size={24} padding="0" />
    ) : selected !== "UNASSIGNED" ? (
      <OrganizationMemberWithAvatar
        color={palette.black.plus10}
        size={24}
        name={usersMap[selected as string]?.name ?? ""}
        imgSrc={usersMap[selected as string]?.picture ?? ""}
      />
    ) : (
      <Text color={palette.black.plus10}>Unassigned</Text>
    );
  return (
    <FlexContainer
      px={2}
      rowGap={1}
      direction="column"
      align="flex-start"
      fullWidth
    >
      <Subtitle>Assignee</Subtitle>
      <Select
        name="assignee"
        value={assigneeId ?? ""}
        onChange={(e) => onSelect(e.target.value as string)}
        label="Select a Member of your Team"
        items={Object.entries(usersMap ?? {})
          .map(([id, user]) => ({
            value: id,
            name: user.name,
          }))
          .concat([{ value: "UNASSIGNED", name: "Unassigned" }])}
        renderValue={renderValue}
        fullWidth
      />
    </FlexContainer>
  );
};
