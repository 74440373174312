import React from "react";

interface Props {
  color?: string;
}

export const Download: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="14"
        width="2"
        height="7"
        rx="1"
        fill={props.color || "currentColor"}
      />
      <rect
        x="11"
        y="2"
        width="2"
        height="13"
        rx="1"
        fill={props.color || "currentColor"}
      />
      <rect
        x="6.34326"
        y="11"
        width="2"
        height="8"
        rx="1"
        transform="rotate(-45 6.34326 11)"
        fill={props.color || "currentColor"}
      />
      <rect
        x="10.5859"
        y="15.2426"
        width="8"
        height="2"
        rx="1"
        transform="rotate(-45 10.5859 15.2426)"
        fill={props.color || "currentColor"}
      />
      <rect
        x="20"
        y="14"
        width="2"
        height="7"
        rx="1"
        fill={props.color || "currentColor"}
      />
      <rect
        x="2"
        y="19"
        width="20"
        height="2"
        rx="1"
        fill={props.color || "currentColor"}
      />
    </svg>
  );
};
