import { Modal } from "@busie/ui-kit";
import { Group, GroupMetadata } from "@busie/utils";
import React from "react";
import { CustomFieldForm } from "~/CustomersPage/entity";
import { useUpdateGroupMetadata } from "./model";

interface Props {
  isOpen: boolean;
  groupId: string;
  metadata: GroupMetadata;
  onClose: () => void;
  onSuccess: (group: Group) => void;
  onError: (e: unknown) => void;
}

export const UpdateCustomField: React.FC<Props> = ({
  groupId,
  metadata,
  isOpen,
  onClose,
  onSuccess,
  onError,
}) => {
  const { mutateAsync: updateCustomField } = useUpdateGroupMetadata(
    groupId,
    metadata.id
  );

  const onSubmit = (data: { key: string; value: string }) =>
    updateCustomField(data, { onSuccess, onError });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CustomFieldForm
        mode="edit"
        name={metadata.key}
        value={metadata.value}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
