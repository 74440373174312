import React from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

import Menu from "../Menu";
import { MenuIcon } from "../icons";
import IconButton from "../IconButton";
import { HeaderItem } from "./Header";

interface Props {
  logout: () => void;
  headerLinks: HeaderItem[];
}

const MobileNavigationMenu: React.FC<React.PropsWithChildren<Props>> = ({
  logout,
  headerLinks,
}) => {
  const history = useHistory();
  const location = useLocation();

  const goTo = (path: string) => {
    history.push(path);
  };

  const menuItems = [
    ...headerLinks
      .map((i) =>
        i.children?.length
          ? i.children.map(({ name, path }) => ({
              content: name,
              action: () => goTo(path),
              selected: location.pathname.includes(path),
            }))
          : {
              content: i.name,
              action: () => goTo(i.to),
              selected:
                location.pathname === i.to ||
                i.children?.some((c) => location.pathname.includes(c.path)),
            }
      )
      .flat(),
    {
      content: "Profile",
      action: () => goTo("/profile"),
      selected: location.pathname === "/profile",
    },
    {
      content: "Organizations",
      action: () => goTo("/organization-settings/dispatch"),
      selected: location.pathname.includes("organization-settings"),
    },
    {
      content: "Logout",
      action: logout,
    },
  ];

  return (
    <Menu
      right="0"
      top="100%"
      toggler={<IconButton size={36} icon={<MenuIcon />} />}
      items={menuItems}
    />
  );
};

export default MobileNavigationMenu;
