import { useMutation, useQuery } from "react-query";

import { useTripPlannerAuthToken, queryClient } from "@busie/core";
import {
  UpdateEmbeddedTripPlannerSettingsParams,
  updateEmbeddedTripPlannerSettings,
  getEmbeddedTripPlannerSettings,
} from "@busie/api";

const EMBEDDED_TRIP_PLANNER_SETTINGS_QUERY_KEY =
  "EMBEDDED_TRIP_PLANNER_SETTINGS";
export const DEFAULT_STALE_TIME = 2 * 60 * 1000; // 2 minutes

export const invalidateEmbeddedTripPlannerSettingsQueries = () => {
  queryClient.invalidateQueries(EMBEDDED_TRIP_PLANNER_SETTINGS_QUERY_KEY);
};

export const useEmbeddedTripPlannerSettings = (id?: string) => {
  const authToken = useTripPlannerAuthToken();

  const result = useQuery(
    [EMBEDDED_TRIP_PLANNER_SETTINGS_QUERY_KEY],
    () => getEmbeddedTripPlannerSettings(authToken, id as string),
    {
      enabled: !!authToken && !!id,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useUpdateEmbeddedTripPlannerSettings = (id?: string) => {
  const authToken = useTripPlannerAuthToken();

  const result = useMutation(
    (params: UpdateEmbeddedTripPlannerSettingsParams) =>
      updateEmbeddedTripPlannerSettings(authToken, id as string, params),
    {
      onSuccess: () => {
        invalidateEmbeddedTripPlannerSettingsQueries();
      },
    }
  );

  return result;
};
