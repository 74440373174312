export const INVALID_ACTION_MODAL_COPY = {
  title: "Invalid Status Action",
  text: "",
  note: "",
  button: "Submit",
};

export const SEND_QUOTE_MODAL_COPY = {
  title: "Send Quote",
  text: "Are you sure you want to send this quote?",
  note: "This will send a Quote Confirmation Email branded to your Organization to the email address set as the Quotes 'main contact', which includes Trip itinerary details and total quoted price. You should do this when you confirm that there are available vehicles and the price is correct.",
  button: "Send Quote",
};

export const DECLINE_QUOTE_MODAL_COPY = {
  title: "Decline Quote",
  text: "Are you sure you want to decline this quote?",
  note: "This will archive the quote. This will not send a quote declined email to the customer.",
  button: "Decline Quote",
};

export const ACCEPT_QUOTE_MODAL_COPY = {
  title: "Accept Quote",
  text: "Are you sure you want to accept this quote?",
  note: "This will create a PENDING Booking and will automatically send an e-contract to first be signed by the Admin of your organization, and then by the main contact for this quote",
  button: "Accept Quote",
};

export const REOPEN_QUOTE_MODAL_COPY = {
  title: "Reopen Quote",
  text: "Are you sure you want to reopen this quote?",
  note: "This will move the quote back to a PENDING status, which means you have not confirmed availability or the price of the quote.",
  button: "Reopen Quote",
};
