import React from "react";

import { Vehicle, Reservation, dayjsExt, DayjsExt } from "@busie/utils";
import CalendarCell from "../components/MonthView/calendarCell";
import DayNumberStyled from "../styled/dayNumber.styled";
import {
  addDays,
  eachDayOfInterval,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isFuture,
  isToday,
  startOfDay,
  subDays,
} from "date-fns";

const useCalendarCells = (
  startDate: Date,
  endDate: Date,
  reservations: Reservation[],
  vehicles: Vehicle[],
  setActiveDay: (date?: DayjsExt) => void,
  availability: number[],
  activeDay?: DayjsExt
): JSX.Element[] => {
  const [totalSlots, setTotalSlots] = React.useState<JSX.Element[]>([]);

  React.useEffect(() => {
    const getAvailableVehicles = (date: DayjsExt) =>
      vehicles.filter((vehicle) => {
        return (
          vehicle.reservations?.filter((res) => {
            const startBefore = dayjsExt(res.startAt).isSameOrBefore(date);
            const endOfTheDay = date.utc().endOf("day").subtract(1, "m");
            const endAfter = dayjsExt(res.endAt).isSameOrAfter(endOfTheDay);
            return startBefore && endAfter;
          }).length === 0
        );
      });

    const currentDay = dayjsExt();

    // prev month
    const prevBlanksCount = startDate.getDay();
    let prevBlanks: JSX.Element[] = [];

    if (prevBlanksCount !== 0) {
      prevBlanks = eachDayOfInterval({
        start: subDays(startDate, prevBlanksCount),
        end: subDays(startDate, 1),
      }).map((date, i) => {
        const availableCount = getAvailableVehicles(dayjsExt(date));
        const dayReservations = reservations.filter(
          (el) =>
            (isBefore(startOfDay(date), new Date(el.startAt)) &&
              isAfter(endOfDay(date), new Date(el.startAt))) ||
            (isAfter(endOfDay(date), new Date(el.endAt)) &&
              isBefore(startOfDay(date), new Date(el.endAt))) ||
            (isAfter(startOfDay(date), new Date(el.startAt)) &&
              isBefore(endOfDay(date), new Date(el.endAt)))
        );
        return (
          <CalendarCell
            date={dayjsExt(date)}
            key={`prev-blank-${i}`}
            isOtherMonth
            availableVehicles={availableCount}
            reservations={dayReservations}
            isFuture={currentDay.isBefore(date)}
          >
            <DayNumberStyled>{format(date, "d")}</DayNumberStyled>
          </CalendarCell>
        );
      });
    }

    // This month
    const daysInMonth = eachDayOfInterval({
      start: startDate,
      end: endDate,
    }).map((date, i) => {
      const isDateToday = isToday(date);
      const isDateFuture = isDateToday || isFuture(date);
      const availableVehicles = getAvailableVehicles(dayjsExt(date));
      const dayReservations = reservations.filter(
        (el) =>
          (isBefore(startOfDay(date), new Date(el.startAt)) &&
            isAfter(endOfDay(date), new Date(el.startAt))) ||
          (isAfter(endOfDay(date), new Date(el.endAt)) &&
            isBefore(startOfDay(date), new Date(el.endAt))) ||
          (isAfter(startOfDay(date), new Date(el.startAt)) &&
            isBefore(endOfDay(date), new Date(el.endAt)))
      );

      return (
        <CalendarCell
          date={dayjsExt(date)}
          setActiveDay={(date) => {
            setActiveDay(date);
          }}
          isActive={activeDay?.isSame(date)}
          availableVehicles={isDateFuture ? availableVehicles : []}
          reservations={dayReservations}
          isFuture={isDateFuture}
          availability={availability[parseInt(dayjsExt(date).format("D")) - 1]}
          key={i}
        >
          <DayNumberStyled isToday={isDateToday}>
            {format(date, "d")}
          </DayNumberStyled>
        </CalendarCell>
      );
    });

    // Next month
    const postBlanks: JSX.Element[] = [];
    const postBlanksCount = 6 - endDate.getDay();
    if (postBlanksCount) {
      eachDayOfInterval({
        start: addDays(endDate, 1),
        end: addDays(endDate, postBlanksCount),
      }).forEach((date, i) => {
        const availableCount = getAvailableVehicles(dayjsExt(date));
        const dayReservations = reservations.filter(
          (el) =>
            (isBefore(startOfDay(date), new Date(el.startAt)) &&
              isAfter(endOfDay(date), new Date(el.startAt))) ||
            (isAfter(endOfDay(date), new Date(el.endAt)) &&
              isBefore(startOfDay(date), new Date(el.endAt))) ||
            (isAfter(startOfDay(date), new Date(el.startAt)) &&
              isBefore(endOfDay(date), new Date(el.endAt)))
        );
        postBlanks.push(
          <CalendarCell
            date={dayjsExt(date)}
            isOtherMonth
            availableVehicles={availableCount}
            reservations={dayReservations}
            isFuture={currentDay.isBefore(date)}
            key={`post-blank-${i}`}
          >
            <DayNumberStyled>{format(date, "d")}</DayNumberStyled>
          </CalendarCell>
        );
      });
    }
    setTotalSlots([...prevBlanks, ...daysInMonth, ...postBlanks]);
  }, [
    startDate,
    endDate,
    reservations,
    vehicles,
    activeDay,
    setActiveDay,
    availability,
  ]);

  return totalSlots;
};

export default useCalendarCells;
