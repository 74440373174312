import { QueryKey } from "react-query";

import { queryClient } from "./client";

export const QUERY_KEY: QueryKey = "TRIPS";

export const DEFAULT_STALE_TIME = 1000 * 60 * 5; // 5 minutes

export const invalidate = () => {
  queryClient.invalidateQueries(QUERY_KEY);
};
