import React from "react";
import { notificationStore } from "@busie/features";
import { Modal } from "@busie/ui-kit";
import { Invoice } from "@busie/utils";
import { FinalizeInvoiceForm } from "~/QuotesAndBookingsPage/entity/Invoice";

import { useFinalizeInvoice } from "./model";

interface Props {
  isOpen: boolean;
  hasPayouts: boolean;
  invoiceId: string;
  onClose: () => void;
  onInvoiceFinalized: (invoice: Invoice) => Promise<void> | void;
}

export const FinalizeInvoice: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  invoiceId,
  hasPayouts,
  onClose,
  onInvoiceFinalized,
}) => {
  const { mutateAsync: finalizeInvoice } = useFinalizeInvoice();

  const onSubmit = async () => {
    try {
      await finalizeInvoice(invoiceId, {
        onSuccess: async (result) => {
          onClose();
          await onInvoiceFinalized(result);
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <FinalizeInvoiceForm
        hasPayouts={hasPayouts}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    </Modal>
  );
};
