import { Leg, RouteResponse } from "@busie/api";
import {
  DrawerDetailsBlock,
  FlexContainer,
  H2,
  Loading,
  Map,
  ToggleSwitch,
} from "@busie/ui-kit";
import { useState } from "react";

interface Props {
  legs: Leg[];
  route?: RouteResponse;
  isLoading?: boolean;
}

export const TripRoute: React.FC<React.PropsWithChildren<Props>> = ({
  legs,
  route,
  isLoading = false,
}: Props) => {
  const [isMapInteractive, setIsMapInteractive] = useState(false);

  if (!legs.length) return <Loading />;

  const markers = legs.map((leg) => ({
    lat: Number(leg.START_LOCATION.LATITUDE),
    lng: Number(leg.START_LOCATION.LONGITUDE),
  }));

  const { DESTINATION_LOCATION } = legs[legs.length - 1];

  markers.push({
    lat: Number(DESTINATION_LOCATION.LATITUDE),
    lng: Number(DESTINATION_LOCATION.LONGITUDE),
  });

  return (
    <DrawerDetailsBlock
      collapsible
      name="Trip Route"
      padding="0"
      className="no-print"
    >
      {isLoading ? (
        <Loading size={24} />
      ) : (
        <FlexContainer direction="column" p={2} rowGap={2}>
          <FlexContainer
            direction="row"
            justify="flex-start"
            columnGap={2}
            align="center"
          >
            <H2>Make Map Interactive</H2>
            <ToggleSwitch
              value={isMapInteractive}
              onChange={() => setIsMapInteractive((val) => !val)}
            />
          </FlexContainer>
          <Map
            height="400px"
            route={route}
            markers={markers}
            interactive={isMapInteractive}
          />
        </FlexContainer>
      )}
    </DrawerDetailsBlock>
  );
};
