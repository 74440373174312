import { FlexContainer, H3, H4 } from "@busie/ui-kit";
import React from "react";

interface Props {
  groupName: string;
  mainContactName: string;
}

export const Cell: React.FC<Props> = ({ groupName, mainContactName }) => (
  <FlexContainer
    direction="column"
    align="flex-start"
    justify="center"
    fullWidth
  >
    <H3 margin="0px">{groupName}</H3>
    <H4>{mainContactName}</H4>
  </FlexContainer>
);
