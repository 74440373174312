import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { NumberInput, TextInput, IconButton, DeleteIcon } from "@busie/ui-kit";

import IngredientFormStyled from "~/rates/RateMenuPage/components/CreateRecipeModal/styled/IngredientFormStyled";
// import IngredientFormRemoveButton from "./styled/IngredientFormRemoveButton";

import { Ingredient } from "@busie/utils";

interface Props {
  index: number;
  values: Ingredient;
  showRemoveButton: boolean;
  onPriceUpdate: (index: number, value: string) => void;
  onNameUpdate: (index: number, value: string) => void;
  onRemove: () => void;
}

const ERROR_MESSAGE = "This field is required";

const IngredientForm: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    index,
    values,
    showRemoveButton,
    onPriceUpdate,
    onNameUpdate,
    onRemove,
  }) => {
    const [isPriceDirty, setIsPriceDirty] = useState(false);
    const [isNameDirty, setIsNameDirty] = useState(false);
    const priceErrorMessage =
      isPriceDirty && !values.value ? ERROR_MESSAGE : "";
    const nameErrorMessage = isNameDirty && !values.name ? ERROR_MESSAGE : "";

    const handleChange = (field: string, value: string) => {
      if (field === "price") {
        setIsPriceDirty(true);
        onPriceUpdate(index, value);
      }
      if (field === "name") {
        setIsNameDirty(true);
        onNameUpdate(index, value);
      }
    };

    return (
      <IngredientFormStyled>
        <NumberInput
          required
          label="Price"
          placeholder="e.g. $10.00"
          isMoneyField={true}
          value={values.value}
          onChange={(e) => handleChange("price", e.target.value)}
          errorMessage={priceErrorMessage}
        />
        <TextInput
          required
          label="Ingredient name"
          placeholder="e.g. Overtime"
          value={values.name}
          onChange={(e) => handleChange("name", e.target.value)}
          errorMessage={nameErrorMessage}
        />
        {showRemoveButton && (
          <IconButton onClick={onRemove} type="button" icon={<DeleteIcon />} />
        )}
      </IngredientFormStyled>
    );
  }
);

export default IngredientForm;
