import { track as amplitudeTrack } from "@amplitude/analytics-browser";

import { ChefQuote, Experience } from "@busie/api";
import { NotificationStore } from "@busie/features";
import { centsToDollars } from "@busie/utils";
import { FormValues } from "../model";

export const getPickupAddress = ({
  LEGS: [
    {
      DESTINATION_LOCATION: { ADDRESS, STREET, CITY, STATE },
    },
  ],
}: Experience): string => ADDRESS ?? `${STREET}, ${CITY} ${STATE}`;

export const getDestinationAddress = ({ LEGS }: Experience): string => {
  const {
    START_LOCATION: { ADDRESS, STREET, CITY, STATE },
  } = LEGS[LEGS.length - 1];

  return ADDRESS ?? `${STREET}, ${CITY} ${STATE}`;
};

export const shouldUpdate = <T>(p: T, n: T) => p !== n;

export const successHandlerFactory = (
  notificationStore: NotificationStore,
  track: typeof amplitudeTrack
) => {
  return (ev: string, data: { [key: string]: number | string | boolean }) => {
    notificationStore.setSuccessNotification(
      "Success!",
      ev
        .split(" ")
        .map((part) => part[0].toUpperCase().concat(part.slice(1)))
        .join(" ")
    );
    track(ev, data);
  };
};

export const createDefaultFormValues = (quote: ChefQuote): FormValues => ({
  contactId: quote.MAIN_CONTACT_ID || "",
  contactName: quote.MAIN_CONTACT?.NAME || quote.CONTACT_NAME,
  contactEmail: quote.MAIN_CONTACT?.EMAIL || quote.CONTACT_EMAIL,
  subtotal: centsToDollars(quote.SUBTOTAL),
  fuelSurcharge: quote.SURCHARGES.FUEL_SURCHARGE,
  taxSurcharge: quote.SURCHARGES.TAX_SURCHARGE,
  lastMinuteSurcharge: quote.SURCHARGES.LAST_MINUTE_SURCHARGE,
  markupPlatformFees: quote.ENABLE_PLATFORM_PRICING_MARKUP,
  assigneeId: quote.ASSIGNEE_ID ?? "UNASSIGNED",
});
