import {
  CommonTable,
  MobileTable,
  tableColumns,
  tableData,
} from "@busie/ui-kit";
import React from "react";

interface Props {
  data: tableData;
  columns: tableColumns;
  isMobile: boolean;
  order: [string, "asc" | "desc"];
  onRowClick: (id: string) => void;
  onSort: (id: string) => void;
}

export const Table: React.FC<Props> = ({
  data,
  columns,
  isMobile,
  order,
  onSort,
  onRowClick,
}) => {
  const Table = isMobile ? MobileTable : CommonTable;

  return (
    <Table
      data={data}
      columnNames={columns}
      withCheckbox={false}
      orderBy={order[0]}
      order={order[1]}
      onRowClick={onRowClick}
      onSort={onSort}
    />
  );
};
