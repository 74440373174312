import React from "react";

import SearchInputOptionStyled from "./styled/SearchInputOptionStyled";

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  groupname?: string;
}
const SearchInputOption: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <SearchInputOptionStyled {...props}>
      <span className="main">
        {props.children}
        {props.groupname && ","}
      </span>
      <span className="secondary">{props.groupname}</span>
    </SearchInputOptionStyled>
  );
};

export default SearchInputOption;
