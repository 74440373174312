import React, { useState, useEffect } from "react";

import { ContractStatusText, palette } from "@busie/ui-kit";
import { EnvelopeStatus } from "@busie/utils";

interface Props {
  status: EnvelopeStatus;
}

const getColorFromEnvelopeStatus = (status: EnvelopeStatus) => {
  let color: string;
  switch (status) {
    case EnvelopeStatus.SENT:
    case EnvelopeStatus.DELIVERED:
      color = palette.supernova.minus10;
      break;
    case EnvelopeStatus.COMPLETED:
    case EnvelopeStatus.SIGNED:
      color = palette.green.main;
      break;
    case EnvelopeStatus.DECLINED:
    case EnvelopeStatus.DELETED:
    case EnvelopeStatus.VOIDED:
      color = palette.red.main;
      break;
    default:
      color = palette.black.main;
      break;
  }

  return color;
};

export const ContractStatus: React.FC<React.PropsWithChildren<Props>> = ({
  status,
}) => {
  const [statusColor, setStatusColor] = useState(
    getColorFromEnvelopeStatus(status)
  );

  useEffect(() => {
    setStatusColor(getColorFromEnvelopeStatus(status));
  }, [status]);

  return <ContractStatusText color={statusColor} value={status} />;
};
