import { styled } from "@mui/styles";
import { theme } from "@busie/ui-kit";

export const CookbooksTableColumns = styled("div")(() => {
  return {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "1fr",
    columnGap: "2px",
    width: "100%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  };
});
