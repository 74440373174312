import React from "react";
import { DrawerObjectDetails } from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  customer: {
    NAME: string;
    EMAIL: string;
    AREA_CODE: string;
    COUNTRY_CODE: string;
    PHONE_NUMBER: string;
    GROUP: {
      NAME: string;
      TYPE: string;
    };
  };
  title?: string;
}

export const CustomerContact: React.FC<React.PropsWithChildren<Props>> = ({
  customer: { NAME, EMAIL, AREA_CODE, COUNTRY_CODE, PHONE_NUMBER, GROUP },
  title = "Customer",
}) => {
  return (
    <DrawerObjectDetails
      name={title}
      secondaryProperties={[
        ["Name", NAME],
        [
          "Email",
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${EMAIL}`}
            className="force-print"
          >
            {EMAIL}
          </a>,
        ],
        [
          "Phone Number",
          `${COUNTRY_CODE ? "+" + COUNTRY_CODE : ""} ${
            AREA_CODE ? "(" + AREA_CODE + ")" : ""
          } ${PHONE_NUMBER}`,
        ],
        ["Group", GROUP.NAME],
        ["Group Type", GROUP.TYPE],
      ]}
    />
  );
};
