export interface ModalCopy {
  title: string;
  text: string;
  note: string;
  button: string;
}
export const INVALID_ACTION_MODAL_COPY: ModalCopy = {
  title: "Invalid Status Action",
  text: "",
  note: "",
  button: "Submit",
};

export const CONFIRM_BOOKING_MODAL_COPY: ModalCopy = {
  title: "Confirm Booking",
  text: "Are you sure you want to manually confirm this booking?",
  note: "This will update the booking status to CONFIRMED and will automatically confirm any associated Reservations (which removes the assigned vehicles from inventory). This effectively overrides the E-Contract step if not already completed, however you will still be able to send an E-Contract to the customer if you wish. This will not send any correspondence to the customer",
  button: "Confirm Booking",
};

export const MARK_PAID_MODAL_COPY: ModalCopy = {
  title: "Mark Booking as Paid",
  text: "Are you sure you want to mark this booking as paid?",
  note: "You should only do this if you do not plan to collect payment through Busie, and/or have already collected payment from the customer. This, however, does not prevent you from sending payment links or invoices later. This qualifies as an offline payment and you may be billed according to your agreement with Busie or the Integration associated with this booking if applicable.",
  button: "Mark Booking as Paid",
};

export const CANCEL_BOOKING_MODAL_COPY: ModalCopy = {
  title: "Cancel Booking",
  text: "Are you sure you want to cancel this booking?",
  note: "This will cancel the booking and automatically cancel any associated Reservations (which will return the assigned vehicles to inventory). This will not send any correspondence to the customer.",
  button: "Cancel Booking",
};

export const MARK_COMPLETED_MODAL_COPY: ModalCopy = {
  title: "Mark Booking as Completed",
  text: "Are you sure you want to mark this booking as completed?",
  note: "This is for record keeping purposes and does not cause any side effects in the platform.",
  button: "Mark Booking as Completed",
};
