import { styled } from "@mui/system";

const VehiclesPageStyled = styled("div")(() => {
  return {
    width: "100%",
    maxWidth: "1360px",
  };
});

export default VehiclesPageStyled;
