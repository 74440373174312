import React from "react";

interface Props {
  color?: string;
}

const H4: React.FC<React.PropsWithChildren<Props>> = ({ color, children }) => {
  return (
    <h4
      style={{
        color: color,
        fontSize: "10px",
        margin: 0,
        fontWeight: 100,
        lineHeight: "150%",
      }}
    >
      {children}
    </h4>
  );
};

export default H4;
