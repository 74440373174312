import axios from "axios";
import {
  env,
  QuoteIntegration,
  QuoteIntegrationFormData,
  QuoteIntegrationResponse,
  QuoteIntegrationsResponse,
  QuoteIntegrationStatus,
} from "@busie/utils";

const QUOTES_AND_BOOKINGS_SERVICE_API_URL = `${env(
  "BUSIE_QUOTES_AND_BOOKINGS_SERVICE_API_URL"
)}/qnb`;

export const fetchQuoteIntegrations = async (
  authToken: string
): Promise<QuoteIntegrationsResponse> => {
  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes-integration`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const fetchQuoteIntegration = async (
  authToken: string,
  id: string
): Promise<QuoteIntegrationResponse> => {
  const { data } = await axios.get(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes-integration/${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const createQuoteIntegration = async (
  authToken: string,
  quoteIntegration: QuoteIntegrationFormData
): Promise<QuoteIntegration> => {
  const { data } = await axios.post(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes-integration`,
    quoteIntegration,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const updateIntegrationStatus = async (
  authToken: string,
  id: string,
  status: QuoteIntegrationStatus
): Promise<void> => {
  const { data } = await axios.patch(
    `${QUOTES_AND_BOOKINGS_SERVICE_API_URL}/quotes-integration/${id}/status`,
    { status },
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};
