import React from "react";
import { styled } from "@mui/system";
import { User } from "@auth0/auth0-spa-js";
import palette from "../theme/palette";

interface Props {
  user?: User;
  size?: string;
}

const AvatarBlockStyled = styled("div")(({ size }: { size: string }) => {
  return {
    width: "100%",
    maxWidth: size,
    height: size,
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "auto",
    },
    "& .no-pic": {
      width: "100%",
      height: size,
      background: palette?.black?.plus50,
    },
  };
});

const AvatarBlock: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  size = "80px",
}: Props) => {
  return (
    <AvatarBlockStyled size={size}>
      {user?.picture ? (
        <img src={user.picture} alt={user?.name} />
      ) : (
        <div className="no-pic"></div>
      )}
    </AvatarBlockStyled>
  );
};

export default AvatarBlock;
