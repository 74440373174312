import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";

import {
  FlexContainer,
  H3,
  IconButton,
  CustomerIcon,
  palette,
  NoteText,
  EditIcon,
  DeleteIcon,
} from "@busie/ui-kit";
import { Customer, dayjsExt } from "@busie/utils";
import { formatPhoneNumber } from "react-phone-number-input";
import { getCountryCode } from "~/CustomersPage/entity/lib";

interface Props {
  customer: Customer;
  onEdit: () => void;
  onDelete: () => void;
  onClick: () => void;
}

export const CustomerRow: React.FC<React.PropsWithChildren<Props>> = ({
  customer,
  onEdit,
  onDelete,
  onClick,
}) => {
  const [areButtonsVisible, setAreButtonsVisible] = useState(false);

  const onClickDelete = (e: Event) => {
    e.stopPropagation();
    onDelete();
  };

  const onClickEdit = (e: Event) => {
    e.stopPropagation();
    onEdit();
  };

  const isTablet = useMediaQuery("@media (max-width: 1040px)");

  return (
    <FlexContainer
      direction="row"
      py={1}
      px={2}
      columnGap={2}
      align="center"
      justify="flex-start"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
      onMouseEnter={() => setAreButtonsVisible(true)}
      onMouseLeave={() => setAreButtonsVisible(false)}
      bordered
      fullWidth
    >
      <FlexContainer>
        <CustomerIcon />
      </FlexContainer>

      <FlexContainer direction="row" justify="space-between" fullWidth>
        <FlexContainer direction="column" rowGap={0} fullWidth>
          <NoteText>Name</NoteText>
          <H3 margin="0">{customer.name}</H3>
        </FlexContainer>

        <FlexContainer direction="column" rowGap={0} fullWidth>
          <NoteText>Email</NoteText>
          <H3 margin="0">{customer.email}</H3>
        </FlexContainer>

        <FlexContainer direction="column" rowGap={0} fullWidth>
          <NoteText>Phone Number</NoteText>{" "}
          <H3 margin="0">
            {formatPhoneNumber(
              `${getCountryCode(customer.countryCode)}${customer.areaCode}${
                customer.phoneNumber
              }`
            )}
          </H3>
        </FlexContainer>

        <FlexContainer direction="column" rowGap={0} fullWidth>
          <NoteText>Created At</NoteText>
          <H3 margin="0">{dayjsExt(customer.createdAt).format("L LT")}</H3>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer direction="row" columnGap={0} justify="flex-end" w={100}>
        {(areButtonsVisible || isTablet) && (
          <>
            <IconButton
              size={30}
              icon={<EditIcon color={palette.black.main} />}
              highlightOnHover
              onClick={onClickEdit}
            />
            <IconButton
              size={30}
              icon={<DeleteIcon color={palette.red.main} />}
              highlightOnHover
              onClick={onClickDelete}
            />
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
