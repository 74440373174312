import React from "react";
import { CalculateIcon, FlexContainer, IconButton, Text } from "@busie/ui-kit";
import { Experience } from "@busie/api";
import { millisecondsToHours, timeFormatter } from "@busie/utils";

interface Props {
  trip: Experience;
  onClick: () => void;
}

export const ServiceTime: React.FC<Props> = ({ trip, onClick }) => {
  if (!trip.TOTAL_SERVICE_BREAKDOWN)
    return <Text weight={500}>Could not determine service time </Text>;

  return (
    <FlexContainer align="center" columnGap={2}>
      {timeFormatter(
        millisecondsToHours(trip.TOTAL_SERVICE_BREAKDOWN.MILLISECONDS),
        "hours"
      )}

      <IconButton icon={<CalculateIcon color="black" />} onClick={onClick} />
    </FlexContainer>
  );
};
