import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import LoadingStyled from "./styled/LoadingStyled";

export interface LoadingBlockProps {
  padding?: string;
  size?: number | string;
}

const LoadingBlock: React.FC<React.PropsWithChildren<LoadingBlockProps>> = (
  props
) => {
  return (
    <LoadingStyled {...props}>
      <CircularProgress size={props.size} color="primary" />
    </LoadingStyled>
  );
};

export default LoadingBlock;
