import { styled } from "@mui/system";

import { Theme } from "@mui/system";

interface Props {
  theme: Theme;
  fullwidth?: boolean;
}

const FormContainerStyled = styled("div")((props: Props) => {
  const palette = props.theme.palette;
  return {
    background: palette.black.plus100,
    boxShadow: `inset 0px -1px 0px ${palette.black.plus70}, inset -1px 0px 0px ${palette.black.plus70}, inset 0px 1px 0px ${palette?.black?.plus70}, inset 1px 0px 0px ${palette?.black?.plus70}`,
    borderRadius: "8px",
    maxWidth: "740px",
    width: "inherit",
    padding: "45px 40px 40px",
    [props.theme.breakpoints.down("tablet")]: {
      padding: "35px 25px",
    },
    margin: "16px auto 0",
    boxSizing: "border-box",
    "&.fullWidth": {
      maxWidth: "100%",
      width: "100%",
    },
    ".title-block": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "25px",
    },
    "& .form-submit-control": {
      paddingTop: "40px",
      display: "flex",
      justifyContent: "flex-end",
      gap: "16px",
    },
  };
});

export default FormContainerStyled;
