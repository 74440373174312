import React from "react";

import { TextInput } from "@busie/ui-kit";
import { NumberInput } from "@busie/ui-kit";

import CustomerDataFieldsStyled from "./styled/CustomerDataFieldsStyled";

interface Props {
  authToken: string;
  // organizationId?: string;
  groupName?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
}

const ShowCustomerDataFields: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <CustomerDataFieldsStyled>
      <TextInput
        label="Full Name"
        disabled={true}
        name="name"
        value={props.name}
      />
      <TextInput
        label="Group"
        disabled={true}
        name="organization"
        value={props.groupName || "No organization"}
      />
      <TextInput
        label="Email"
        disabled={true}
        name="email"
        value={props.email}
      />
      <NumberInput
        label="Phone number"
        disabled={true}
        name="phoneNumber"
        isPhoneField={true}
        placeholder="+1-000-000-0000"
        onChange={() => true}
        value={props.phoneNumber}
      />
    </CustomerDataFieldsStyled>
  );
};

export default ShowCustomerDataFields;
