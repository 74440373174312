import React from "react";
import VehiclesStore from "~/vehicles/store";
import { VehicleIcon, mapVehicleTypeToName } from "@busie/core";
import { observer } from "mobx-react";
import {
  FlexContainer,
  H3,
  IconButton,
  palette,
  MinusIcon,
  PlusIcon,
} from "@busie/ui-kit";
import { PopupListItemWrapper } from "./popupListItem";

interface Props {
  vehicleTypeId: string;
  vehiclesNumber: number;
  selectedVehiclesAmount: number;
  setSelectedTypes: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
}

const AvailableVehicleType: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    vehicleTypeId,
    vehiclesNumber,
    setSelectedTypes,
    selectedVehiclesAmount,
  }) => {
    const vehicleTypes = VehiclesStore.vehicleTypes;
    const typeEnumNumber = vehicleTypes.find(
      (type) => type.id === vehicleTypeId
    )?.type;
    const handleVehiclesIncrease = () => {
      setSelectedTypes((prev) => ({
        ...prev,
        [vehicleTypeId]:
          prev[vehicleTypeId] === vehiclesNumber
            ? vehiclesNumber
            : selectedVehiclesAmount + 1,
      }));
    };
    const handleVehiclesDecrease = () => {
      setSelectedTypes((prev) => ({
        ...prev,
        [vehicleTypeId]: !prev[vehicleTypeId] ? 0 : selectedVehiclesAmount - 1,
      }));
    };

    if (typeEnumNumber !== undefined) {
      return (
        <PopupListItemWrapper>
          <FlexContainer align="center" columnGap={1}>
            <VehicleIcon type={typeEnumNumber} size={32} />
            <H3 sx={{ margin: "0 !important" }}>
              {mapVehicleTypeToName(typeEnumNumber)} ({vehiclesNumber})
            </H3>
          </FlexContainer>

          <FlexContainer columnGap={0.5} align="center">
            <IconButton
              onClick={handleVehiclesDecrease}
              icon={<MinusIcon color={palette?.black?.plus50} />}
            />
            {selectedVehiclesAmount}
            <IconButton
              onClick={handleVehiclesIncrease}
              icon={<PlusIcon color={palette?.black?.plus50} />}
            />
          </FlexContainer>
        </PopupListItemWrapper>
      );
    } else {
      return null;
    }
  }
);

export default AvailableVehicleType;
