import React from "react";

import { Modal } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";
import { SendContractForm } from "~/QuotesAndBookingsPage/entity";
import { SendContractParams } from "~/QuotesAndBookingsPage/entity";
import { useSendContract } from "./model";
import { useAmplitude } from "@busie/core";

interface Props {
  isOpen: boolean;
  data: { bookingId: string; email: string; name: string };
  metadata: { balance: number; price: number; quoteId: string };
  onClose: () => void;
  onSuccess: () => void;
}

export const SendContract: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  data: { bookingId: id, email: contactEmail, name: contactName },
  metadata: { balance, price, quoteId },
  onClose,
  onSuccess,
}) => {
  const { mutateAsync: sendContract } = useSendContract();
  const { track } = useAmplitude();

  const onSend = async (data: SendContractParams) => {
    try {
      await sendContract(
        {
          sendContractData: {
            contactInfo: { contactEmail: data.email, contactName: data.name },
            adminInfo: { name: data.adminName, email: data.adminEmail },
          },
          bookingId: id,
        },
        {
          onSuccess: () => {
            track("e-contract sent", {
              balance,
              bookingId: id,
              price,
              quoteId,
            });

            notificationStore.setNotification({
              type: "success",
              header: "Success",
              message: "E-Contract sent successfully",
            });

            onSuccess();
            onClose();
          },
          onError: (e) => {
            // idea: do something on error with the content of the modal (get isError from useSendContract)
            notificationStore.setNotificationFromError(e);
          },
        }
      );
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SendContractForm
        email={contactEmail}
        name={contactName}
        onSubmit={onSend}
        onCancel={onClose}
      />
    </Modal>
  );
};
