export const getAmenitiesMap = (amenities: number[]): Map<number, boolean> => {
  return new Map(amenities.map((key) => [key, true]));
};

export const getActiveAmenities = (
  activeAmenities: number[],
  clickedAmenity: number
): number[] => {
  const amenities = getAmenitiesMap(activeAmenities);

  if (amenities.has(clickedAmenity)) amenities.set(clickedAmenity, false);
  else amenities.set(clickedAmenity, true);

  const result: number[] = [];

  for (const [amenity, isSelected] of amenities.entries()) {
    if (isSelected) result.push(amenity);
  }

  return result;
};
