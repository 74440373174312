import React from "react";

import { Button, ButtonProps } from "@busie/ui-kit";

import ButtonBlockStyled from "./styled/ButtonBlockStyled";

const CustomButton: React.FC<React.PropsWithChildren<ButtonProps>> = (
  props: ButtonProps
) => {
  return (
    <ButtonBlockStyled>
      <Button {...props} />
    </ButtonBlockStyled>
  );
};

export default CustomButton;
