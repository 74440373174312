import React from "react";

import { Map } from "@busie/ui-kit";

import FormContainer from "../FormContainer";

import DeparturesForm from "../../store/departuresForm";

interface Props {
  setCurrentStep: (step: number) => void;
  departuresForm: DeparturesForm;
}

const MapRouteBlock: React.FC<React.PropsWithChildren<Props>> = ({
  departuresForm,
}: Props) => {
  const legs = departuresForm.trip?._legs;
  if (!legs?.length) return <div></div>;

  const markers = legs.map((leg, index) => ({
    lat: leg._startLocation?.latitude as number,
    lng: leg._startLocation?.longitude as number,
  }));

  // remove dispatch waypoint from map
  markers.shift();
  return (
    <FormContainer title="Map route">
      <Map
        height="400px"
        markers={markers}
        route={departuresForm.route || undefined}
      />
    </FormContainer>
  );
};

export default MapRouteBlock;
