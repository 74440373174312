import { Icon } from "@mui/material";

import {
  BlackCarLimousineIcon,
  CheckmarkIcon,
  CloseIcon,
  CustomIcon,
  DoubleDeckerIcon,
  EntertainerCoachIcon,
  ExecutiveCoachIcon,
  FlexContainer,
  H3,
  LicenseIcon,
  MiniBusIcon,
  MotorcoachIcon,
  SchoolBusIcon,
  TrolleyIcon,
  VanIcon,
  VehicleGroupIcon,
  palette,
} from "@busie/ui-kit";
import { DriversLicenseType } from "@busie/utils";

import { DecoratedListItem } from "~/settings/shared/ui";

const VEHICLES: { [key: string]: React.ReactNode } = {
  DOUBLE_DECKER: <DoubleDeckerIcon />,
  EXECUTIVE_COACH: <ExecutiveCoachIcon />,
  MINI_BUS: <MiniBusIcon />,
  MOTOR_COACH: <MotorcoachIcon size={24} />,
  ENTERTAINER_COACH: <EntertainerCoachIcon />,
  SCHOOL_BUS: <SchoolBusIcon />,
  TROLLEY: <TrolleyIcon />,
  VAN: <VanIcon />,
  BLACK_CAR: <BlackCarLimousineIcon />,
};

interface Props {
  licenseType: DriversLicenseType;
}

export const ListItem: React.FC<React.PropsWithChildren<Props>> = ({
  licenseType,
}) => {
  const enabledIcon = <CheckmarkIcon />;
  const disabledIcon = <CloseIcon />;

  return (
    <DecoratedListItem
      title={licenseType.name}
      icon={<LicenseIcon color={palette.black.main} />}
    >
      <FlexContainer>
        <FlexContainer direction="column" fullWidth justify="space-between">
          <FlexContainer direction="column" justify="space-between" fullWidth>
            <Icon>
              {licenseType.requiredUpload ? enabledIcon : disabledIcon}
            </Icon>
            <H3 weight={500} color={palette.black.plus30}>
              Upload Required
            </H3>
          </FlexContainer>
          <FlexContainer direction="column" justify="space-between" fullWidth>
            <Icon>
              {licenseType.sendExpirationNotification
                ? enabledIcon
                : disabledIcon}
            </Icon>
            <H3 weight={500} color={palette.black.plus30}>
              Expiration Notifications
            </H3>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer direction="column" fullWidth justify="flex-start">
          <Icon>
            <VehicleGroupIcon />
          </Icon>
          {licenseType.allowedVehicleTypes.map((vt) => (
            <Icon>{VEHICLES[vt] || <CustomIcon />}</Icon>
          ))}
        </FlexContainer>
      </FlexContainer>
    </DecoratedListItem>
  );
};
