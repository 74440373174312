import { styled } from "@mui/system";

import React, { FC } from "react";

const TitleStyled = styled("h1")(({ theme }) => {
  const palette = theme?.palette;
  return {
    "&": {
      color: palette?.black?.main,
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "150%",
      margin: 0,
    },
  };
});

export const Title: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <TitleStyled>{children}</TitleStyled>;
};
