import {
  FlexContainer,
  H3,
  palette,
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  IconButton,
} from "@busie/ui-kit";
import { Icon } from "@mui/material";
import { dayjsExt } from "@busie/utils";
import React from "react";
import { CompanyHoliday } from "@busie/utils";
import { getHolidayDateString } from "../../lib";

interface Props {
  holiday: CompanyHoliday;
  onDelete: () => void;
  onEdit: () => void;
}

export const Holiday: React.FC<Props> = ({ holiday, onDelete, onEdit }) => {
  const [controlsVisible, setControlsVisible] = React.useState(false);

  const dateString = getHolidayDateString(
    dayjsExt(holiday.holidayRangeFrom),
    dayjsExt(holiday.holidayRangeTo),
    holiday.repeat
  );

  return (
    <FlexContainer
      fullWidth
      bordered
      px={2}
      py={1}
      maxW="475px"
      direction="row"
      columnGap={1}
      align="center"
      justify="flex-start"
      sx={{
        borderRadius: "6px",
        ":hover": { backgroundColor: palette.black.plus85 },
      }}
      onMouseEnter={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    >
      <FlexContainer columnGap={1} align="center" fullWidth>
        <CalendarIcon />
        <FlexContainer direction="column" justify="center">
          <H3 margin="0">{holiday.name}</H3>
          <H3 margin="0" color={palette.black.plus30} weight={500}>
            {dateString}
          </H3>
        </FlexContainer>
      </FlexContainer>

      {controlsVisible && (
        <FlexContainer columnGap={2}>
          <IconButton icon={<EditIcon />} onClick={onEdit} />
          <IconButton icon={<DeleteIcon />} onClick={onDelete} />
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
