/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import { useMediaQuery } from "@mui/material";
import { CreateCustomer, SelectGroupMember } from "~/CustomersPage/features";

import {
  Button,
  FlexContainer,
  Loading,
  NumberInput,
  palette,
  Subtitle,
  Text,
  theme,
  ToggleSwitch,
  IconButton,
  PlusIcon,
  Divider as BusieDivider,
  ScrollableFlexContainer,
} from "@busie/ui-kit";
import { useAuth0 } from "@auth0/auth0-react";
import {
  currencyFormatter,
  centsToDollars,
  User,
  metersToMiles,
} from "@busie/utils";
import { Customer } from "@busie/utils";

import { store } from "../model";

export type EditFormType = "quote" | "booking";

interface Props {
  type: EditFormType;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

const formatPhoneNumber = (customer?: Customer) => {
  if (!customer) return "";
  return `+${customer.countryCode} (${
    customer.areaCode
  }) ${customer.phoneNumber?.slice(0, 3)}-${customer.phoneNumber?.slice(3)}`;
};

export const EditQuoteForm: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ type, onSubmit, onClose }) => {
    const { user } = useAuth0<User>();
    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

    const [selectedCustomer, setSelectedCustomer] = useState(
      store.trip?._mainContactId || store.trip?._tripPlannerId
    );
    const [isCreateCustomerVisible, setIsCreateCustomerVisible] =
      useState(false);

    const Divider = () => <BusieDivider margin={isMobile ? 5 : 0} />;

    const getStatusText = (): string => {
      if (!store.quote) return "";

      return (
        // @ts-expect-error no error, obtaining full mapping
        {
          CANCELED: "Canceled",
          PENDING: "Pending",
          CONFIRMED: "Confirmed",
          DECLINED: "Declined",
          PAID: "Paid",
          COMPLETE: "Complete",
        }[store.quote._status] || ""
      );
    };

    const getStatusColor = (): string => {
      return (
        // @ts-expect-error no error, obtaining full mapping
        {
          CANCELED: palette.red.main,
          DECLINED: palette.red.main,
          PENDING: palette.supernova.minus10,
          CONFIRMED: palette.blue.minus10,
          PAID: palette.black.plus10,
          COMPLETE: palette.black.plus10,
        }[store?.quote?._status || ""] || ""
      );
    };

    if (
      user &&
      store.trip &&
      store.quote &&
      store.quoteId &&
      (type === "booking" ? store.booking && store.bookingId : true)
    ) {
      return (
        <>
          <ScrollableFlexContainer
            direction="column"
            rowGap={1}
            fullWidth
            pb={2}
            pr={2}
          >
            <FlexContainer py={1}>
              <Subtitle fontSize="20px">Trip Planner Information</Subtitle>
            </FlexContainer>

            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Full name</Text>
              <Text>{store.customer?.name}</Text>
            </FlexContainer>
            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Group Name</Text>
              <Text>{store.customer?.groupName}</Text>
            </FlexContainer>
            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Email</Text>
              <Text>{store.customer?.email}</Text>
            </FlexContainer>
            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Phone number</Text>
              <Text>{formatPhoneNumber(store.customer)}</Text>
            </FlexContainer>
            <Divider />

            <FlexContainer py={1}>
              <Subtitle fontSize="20px">Main Contact Information</Subtitle>
            </FlexContainer>

            <FlexContainer columnGap={1.25} pt={isMobile ? 1 : 0} fullWidth>
              <FlexContainer align="center" columnGap={2} fullWidth>
                <SelectGroupMember
                  groupId={store.quote._customerGroupId}
                  initialValue={selectedCustomer}
                  onSelect={(profile) => {
                    store.setMainContactId(profile.id);
                    store.setMainContactName(profile.name);
                    store.setMainContactEmail(profile.email);
                  }}
                />
                <IconButton
                  icon={<PlusIcon color="black" />}
                  style={{ marginTop: "8px" }}
                  onClick={() => setIsCreateCustomerVisible(true)}
                />
              </FlexContainer>

              <CreateCustomer
                visible={isCreateCustomerVisible}
                hide={() => setIsCreateCustomerVisible(false)}
                onCreate={(customer) => {
                  setSelectedCustomer(customer.id);
                  store.setMainContactId(customer.id);
                  store.setMainContactName(customer.name);
                  store.setMainContactEmail(customer.email as string);
                }}
                organizationId={store.quote._customerGroupId}
              />
            </FlexContainer>

            <FlexContainer py={1}>
              <Subtitle fontSize="20px">Summary</Subtitle>
            </FlexContainer>

            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Quote status</Text>
              <Text color={getStatusColor()}>{getStatusText()}</Text>
            </FlexContainer>
            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Trip distance</Text>
              <Text>{metersToMiles(store.trip._meters)} mi</Text>
            </FlexContainer>
            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Total live miles</Text>
              <Text>{metersToMiles(store.trip._liveMeters)} mi</Text>
            </FlexContainer>
            <Divider />
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={100}>Total dead head miles</Text>
              <Text>{metersToMiles(store.trip._deadHeadMeters)} mi</Text>
            </FlexContainer>
            <Divider />

            <FlexContainer columnGap={1.25} pt={2} fullWidth>
              <FlexContainer direction="column" w="50%" rowGap={1}>
                <Text>Quote Subtotal</Text>
                <NumberInput
                  value={store.quotePrice}
                  isMoneyField
                  onChange={(event) =>
                    store.setQuotePrice(parseFloat(event.target.value))
                  }
                  allowNegative={false}
                  fullWidth
                  isAllowed={(val) => {
                    const decimal = val.value.split(".")[1];
                    if (!decimal) return true;
                    return decimal.length <= 2;
                  }}
                />
              </FlexContainer>
              <FlexContainer direction="column" w="50%" rowGap={1}>
                <Text>Tax</Text>
                <NumberInput
                  value={store.taxSurcharge}
                  suffix="%"
                  onChange={(event) =>
                    store.setTaxSurcharge(parseFloat(event.target.value))
                  }
                  allowNegative={false}
                  fullWidth
                />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer columnGap={1.25} pt={1} fullWidth>
              <FlexContainer direction="column" w="50%" rowGap={1}>
                <Text>Last Minute Surcharge</Text>
                <NumberInput
                  value={store.lastMinuteSurgeCharge}
                  suffix="%"
                  onChange={(event) =>
                    store.setLastMinuteSurgeCharge(
                      parseFloat(event.target.value)
                    )
                  }
                  allowNegative={false}
                  fullWidth
                />
              </FlexContainer>
              <FlexContainer direction="column" w="50%" rowGap={1}>
                <Text>Fuel Surcharge</Text>
                <NumberInput
                  value={store.fuelSurcharge}
                  suffix="%"
                  onChange={(event) =>
                    store.setFuelSurcharge(parseFloat(event.target.value))
                  }
                  allowNegative={false}
                  fullWidth
                />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer columnGap={1.25} pt={1} fullWidth>
              <Text>Platform Pricing Markup</Text>
              <ToggleSwitch
                checked={store.enablePlatformPricingMarkup}
                onChange={(event) =>
                  store.setEnablePlatformPricingMarkup(
                    !!event.target.checked || false
                  )
                }
              />
            </FlexContainer>
          </ScrollableFlexContainer>

          <Divider />

          <FlexContainer
            direction="row"
            justify="space-between"
            align="center"
            pb={3}
            fullWidth
          >
            <FlexContainer direction="column" rowGap={0.5}>
              <Text>Total price</Text>
              <Text>
                {currencyFormatter(
                  centsToDollars(store.quote?._totalPrice || 0)
                )}
              </Text>
            </FlexContainer>
            <FlexContainer columnGap={1}>
              <Button
                typestyle="secondary"
                onClick={() => {
                  setSelectedCustomer(
                    store.trip?._mainContactId ||
                      store.trip?._tripPlannerId ||
                      ""
                  );
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button typestyle="primary" onClick={onSubmit} async>
                Save
              </Button>
            </FlexContainer>
          </FlexContainer>
        </>
      );
    }

    return <Loading />;
  }
);
