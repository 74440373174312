interface Colors {
  background?: string;
  borderColor?: string;
  color?: string;
}

type colorTypes = "default" | "hover" | "pressed" | "disabled";

type ColorType = {
  [color in colorTypes]: Colors;
};

export const getColorsOfType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  palette?: Record<string, any>,
  type?: string
): ColorType => {
  switch (type) {
    case "secondary":
      return {
        default: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.plus10,
          color: palette?.black?.plus10,
        },
        hover: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.plus20,
          color: palette?.black?.plus20,
        },
        pressed: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.main,
          color: palette?.black?.main,
        },
        disabled: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.plus60,
          color: palette?.black?.plus60,
        },
      };
    case "tertiary":
      return {
        default: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.plus100,
          color: palette?.black?.plus10,
        },
        hover: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.plus20,
          color: palette?.black?.plus20,
        },
        pressed: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.main,
          color: palette?.black?.main,
        },
        disabled: {
          background: palette?.black?.plus100,
          borderColor: palette?.black?.plus60,
          color: palette?.black?.plus60,
        },
      };
    case "accent":
      return {
        default: {
          background: palette?.red?.main,
          borderColor: palette?.red?.main,
          color: palette?.black?.plus100,
        },
        hover: {
          background: palette?.red?.plus10,
          borderColor: palette?.red?.plus10,
          color: palette?.black?.plus100,
        },
        pressed: {
          background: palette?.red?.minus5,
          borderColor: palette?.red?.minus5,
          color: palette?.black?.plus100,
        },
        disabled: {
          background: palette?.black?.plus60,
          borderColor: palette?.black?.plus60,
          color: palette?.black?.plus100,
        },
      };
    default:
      return {
        default: {
          background: palette?.black?.plus10,
          borderColor: palette?.black?.plus10,
          color: palette?.black?.plus100,
        },
        hover: {
          background: palette?.black?.plus20,
          borderColor: palette?.black?.plus20,
          color: palette?.black?.plus100,
        },
        pressed: {
          background: palette?.black?.main,
          borderColor: palette?.black?.main,
          color: palette?.black?.plus100,
        },
        disabled: {
          background: palette?.black?.plus60,
          borderColor: palette?.black?.plus60,
          color: palette?.black?.plus100,
        },
      };
  }
};

export const getPaddingValues = (
  size: string,
  icon?: boolean
): { [key: string]: string } => {
  if (size === "big") {
    return {
      top: icon ? "6px" : "11px",
      right: icon ? "14px" : "14px",
      bottom: icon ? "6px" : "11px",
      left: icon ? "17px" : "14px",
    };
  } else if (size === "small") {
    return {
      top: icon ? "2px" : "7px",
      right: icon ? "14px" : "14px",
      bottom: icon ? "2px" : "7px",
      left: icon ? "6px" : "14px",
    };
  }
  return {
    top: "2px",
    right: icon ? "7px" : "7px",
    bottom: "2px",
    left: icon ? "7px" : "7px",
  };
};
