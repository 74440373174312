import React from "react";

interface Props {
  children: React.ReactNode;
  errorText?: string;
  logError?: (error: Error) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    console.error(error);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error("Uncaught error:", error, errorInfo);
    this.props.logError && this.props.logError(error);
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <h3>{this.props.errorText || "There was an error"}</h3>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
