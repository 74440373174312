import React from "react";
import { Box } from "@mui/system";
import { Button, H3, AddIcon } from "@busie/ui-kit";

interface Props {
  onAdd: () => void;
}

export const NoElements: React.FC<React.PropsWithChildren<Props>> = ({
  onAdd,
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      gap: "40px",
      padding: "150px 0 150px",
    }}
  >
    <H3 sx={{ textAlign: "center" }}>
      You do not have any Dispatch Locations
      <br />
      Add your first one.
    </H3>
    <Button startIcon={<AddIcon />} onClick={onAdd}>
      Add dispatch location
    </Button>
  </Box>
);
