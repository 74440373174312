import React, { useImperativeHandle } from "react";
import PageContainer from "~/rates/RateMenuPage/components/PageContainer";
import { TableHeader } from "~/rates/RateMenuPage/components/CookbooksTableView/TableHeader/TableHeader";
import { usePagination, useRatesAuthToken } from "@busie/core";
import {
  Button,
  FlexContainer,
  Loading,
  NoContentBlock,
  palette,
  TablePagination,
  PlusIcon,
  Text,
} from "@busie/ui-kit";
import CookbookView from "~/rates/RateMenuPage/components/CookbooksTableView/CookbookView/CookbookView";
import PopupContainer from "./CookbookView/PopupContainer";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router";
import { useCookbooks, useMenu } from "~/rates/RateMenuPage/hooks";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";

const COOKBOOKS_PER_PAGE = 10;

export interface CookbooksTableViewRef {
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult>;
}
interface Props {
  onAddRateRecipe: () => void;
}

export const CookbooksTableView = React.forwardRef<
  CookbooksTableViewRef,
  Props
>((props, ref) => {
  const { user } = useAuth0();
  const authToken = useRatesAuthToken();
  const [currentPage, setPage] = usePagination();
  const { search } = useLocation();
  const searchValues = new URLSearchParams(search);
  const [typeFilter, setTypeFilter] = React.useState(
    searchValues.get("type") || "all"
  );
  const [nameFilter, setNameFilter] = React.useState(
    searchValues.get("name") || ""
  );

  const { data: menu } = useMenu(authToken, user);

  const {
    data: cookbooksResponse,
    isLoading,
    isFetched,
    isIdle,
    refetch,
  } = useCookbooks(authToken, nameFilter, typeFilter, currentPage, menu);

  const cookbooks = cookbooksResponse?.resultSet;
  const totalItems = cookbooksResponse?.total || 0;
  const nextOffset = cookbooksResponse?.next || 0;

  useImperativeHandle(ref, () => ({
    refetch,
  }));

  const hasRecipes = React.useMemo(() => {
    return (
      (cookbooksResponse?.total as number) >= 5 ||
      cookbooksResponse?.resultSet.some(
        (cookbook) => cookbook.recipes.length > 0
      )
    );
  }, [cookbooksResponse]);
  const showBlankState = isFetched && !hasRecipes && !nameFilter && !typeFilter;

  if (showBlankState) {
    return (
      <NoContentBlock
        icon={<img src="/icons/RateMenu.svg" alt="" />}
        text="Rate menu is empty. Add your first recipe 👇"
        controls={
          <Button
            onClick={props.onAddRateRecipe}
            startIcon={<PlusIcon color={palette?.black?.plus100} />}
          >
            Add Rate Recipe
          </Button>
        }
      />
    );
  }

  const showLoading = isIdle || isLoading;
  return (
    <PageContainer
      onAddRateRecipe={props.onAddRateRecipe}
      typeFilter={typeFilter}
      onTypeFilterChange={setTypeFilter}
      nameFilter={nameFilter}
      onNameFilterChange={setNameFilter}
    >
      <TableHeader />
      <FlexContainer
        direction="column"
        fullWidth
        noPadding
        borderBetweenY
        sx={{ borderBottom: `1px solid ${palette.black.plus70}` }}
      >
        {showLoading ? (
          <FlexContainer align="center" justify="center" fullWidth>
            <Loading />
          </FlexContainer>
        ) : cookbooks && cookbooks.length > 0 ? (
          cookbooks.map((cookbook) => (
            <CookbookView
              key={cookbook.id}
              authToken={authToken}
              cookbook={cookbook}
            />
          ))
        ) : (
          <FlexContainer p={2}>
            <Text color={palette.black.plus50}>
              No cookbooks match the search criteria
            </Text>
          </FlexContainer>
        )}
        <PopupContainer />
      </FlexContainer>
      {!showLoading && cookbooks && cookbooks.length > 0 && (
        <TablePagination
          itemsTotal={totalItems}
          pageNumber={currentPage}
          perPage={COOKBOOKS_PER_PAGE}
          currentOffset={COOKBOOKS_PER_PAGE * (currentPage - 1)}
          nextOffset={nextOffset || totalItems}
          onNextClick={() => setPage(currentPage + 1)}
          onPrevClick={() => setPage(currentPage - 1)}
          onToEndClick={() =>
            setPage(Math.ceil(totalItems / COOKBOOKS_PER_PAGE))
          }
          onToBeginningClick={() => setPage(1)}
          outlined={false}
        />
      )}
    </PageContainer>
  );
});
