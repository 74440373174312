import { Booking, EnvelopeStatus } from "@busie/utils";
import { FlexContainer } from "@busie/ui-kit";
import React from "react";
import { observer } from "mobx-react";
import { DrawerDetailsBlock as DetailsBlock } from "@busie/ui-kit";
import { ContractStatus } from "./ContractStatus";

export { Form as SendContractForm } from "./SendContractForm";
export { Form as VoidContractForm } from "./VoidContractForm";
export { ContractStatus } from "./ContractStatus";
export { type SendContractParams } from "./model";

interface Props {
  booking: Booking;
  sendContract: React.ReactNode;
  sendContractButton: React.ReactNode;
  voidContract: React.ReactNode;
  voidContractButton: React.ReactNode;
}

export const Contract: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    booking,
    sendContract,
    sendContractButton,
    voidContract,
    voidContractButton,
  }: Props) => {
    return (
      <DetailsBlock collapsible name="Booking E-Contract" className="no-print">
        <FlexContainer
          direction="row"
          columnGap={2}
          justify="space-between"
          align="center"
        >
          {sendContractButton}

          <FlexContainer
            direction="row"
            columnGap={2}
            justify="space-between"
            align="center"
          >
            <ContractStatus status={booking._contractStatus} />

            {!!booking._contractId &&
              ![
                EnvelopeStatus.SIGNED,
                EnvelopeStatus.COMPLETED,
                EnvelopeStatus.VOIDED,
                EnvelopeStatus.DELETED,
              ].includes(booking._contractStatus) &&
              voidContractButton}
          </FlexContainer>
        </FlexContainer>

        {sendContract}

        {voidContract}
      </DetailsBlock>
    );
  }
);
