import React, { useState } from "react";
import {
  Drawer as BusieDrawer,
  DrawerDetailsBlock,
  DrawerHeader,
  DrawerHeaderButton,
  PropertyString,
  DeleteIcon,
  palette,
  EditIcon,
} from "@busie/ui-kit";
import { Driver } from "@busie/utils";
import { EditDriver } from "~/drivers/features";
import { DeleteDrivers } from "~/drivers/features/DeleteDrivers";
import { notificationStore } from "@busie/features";

interface Props {
  driver: Driver;
  onClose: () => void;
}

export const Drawer: React.FC<React.PropsWithChildren<Props>> = ({
  driver,
  onClose,
}) => {
  const [isEditDriverOpen, setIsEditDriverOpen] = useState(false);
  const [isDeleteDriverOpen, setIsDeleteDriverOpen] = useState(false);

  return (
    <BusieDrawer anchor="right" open={!!driver} onClose={onClose}>
      <DrawerHeader title="Driver Info" onClose={onClose}>
        <DrawerHeaderButton
          icon={<EditIcon color={palette.black.plus100} />}
          onClick={() => setIsEditDriverOpen(true)}
        />

        <DrawerHeaderButton
          icon={<DeleteIcon color={palette.black.plus100} />}
          onClick={() => setIsDeleteDriverOpen(true)}
        />
      </DrawerHeader>

      <div>
        <DrawerDetailsBlock noHeader>
          <PropertyString justify="space-between" name="First Name">
            {driver.firstName}
          </PropertyString>

          <PropertyString justify="space-between" name="Last Name">
            {driver.lastName}
          </PropertyString>

          <PropertyString justify="space-between" name="Email">
            {driver.email}
          </PropertyString>

          <PropertyString justify="space-between" name="Phone number">
            {driver.phoneNumber}
          </PropertyString>
        </DrawerDetailsBlock>

        {(Object.keys(driver.metadata).length > 0 && (
          <DrawerDetailsBlock name="Custom Fields" collapsible>
            {Object.keys(driver.metadata).map((key) => (
              <PropertyString key={key} name={key} justify="space-between">
                {driver.metadata[key]}
              </PropertyString>
            ))}
          </DrawerDetailsBlock>
        )) ||
          null}
      </div>

      <EditDriver
        driver={driver}
        isOpen={isEditDriverOpen}
        onClose={() => setIsEditDriverOpen(false)}
        onDriverUpdated={() => {
          notificationStore.setSuccessNotification(
            "Success!",
            `The driver ${driver.firstName} ${driver.lastName} was updated`
          );
          setIsEditDriverOpen(false);
        }}
      />

      <DeleteDrivers
        drivers={[driver]}
        isOpen={isDeleteDriverOpen}
        onClose={() => setIsDeleteDriverOpen(false)}
        onDriverDeleted={() => {
          notificationStore.setSuccessNotification(
            "Success!",
            `The driver ${driver.firstName} ${driver.lastName} was deleted`
          );
          setIsDeleteDriverOpen(false);
          onClose();
        }}
      />
    </BusieDrawer>
  );
};
