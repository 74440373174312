import React from "react";
import { Integration } from "@busie/utils";

import { NotificationBlock } from "./NotificationBlock";
import { NotificationCount } from "./NotificationCount";

import { StackedBlockStyled } from "./styled";

interface Props {
  integrations: Integration[];
  onChange: () => Promise<void>;
}

export const StackedBlock: React.FC<React.PropsWithChildren<Props>> = ({
  integrations,
  onChange,
}) => {
  return (
    <StackedBlockStyled>
      {integrations.slice(0, 3).map((integration, index) => (
        <NotificationBlock
          integration={integration}
          onChange={onChange}
          index={index}
          key={index}
        />
      ))}
      <NotificationCount>{integrations.length}</NotificationCount>
    </StackedBlockStyled>
  );
};
