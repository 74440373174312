import React from "react";
import { observer } from "mobx-react-lite";

import AmenitiesBlockStyled from "./styled/AmenitiesBlockStyled";
import AmenityBlockStyled from "./styled/AmenityBlockStyled";

import store from "../../store";
import { AmenityIcon, mapAmenityToName } from "@busie/core";
import { AmenityValues } from "@busie/utils";

const AmenitiesBlock: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const tripForm = store.tripForm;
    const activeAmenities = tripForm.amenities;

    return (
      <AmenitiesBlockStyled role="listbox" aria-live="polite">
        {AmenityValues.map((amenity, i) => {
          const id = `amenity-btn-${i}`;
          const label = mapAmenityToName(amenity);
          const isSelected = activeAmenities.includes(amenity);
          return (
            <AmenityBlockStyled key={i}>
              <button
                id={id}
                onClick={() => tripForm.toggleAmenity(amenity)}
                className={isSelected ? "active" : ""}
                role="option"
                aria-selected={isSelected}
                aria-label={label}
              >
                <AmenityIcon type={amenity} />
              </button>
              <label htmlFor={id} className="amentity-label" aria-hidden>
                {label}
              </label>
            </AmenityBlockStyled>
          );
        })}
      </AmenitiesBlockStyled>
    );
  }
);

export default AmenitiesBlock;
