import React from "react";

import { CreatePaymentLinkForm } from "~/QuotesAndBookingsPage/entity";
import { CreatePaymentLinkData } from "@busie/api";
import { useCreatePaymentLink } from "./model";
import { Modal } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";

interface Props {
  isOpen: boolean;
  email: string;
  bookingId: string;
  bookingBalance: number;
  onClose: () => void;
  onPaymentLinkCreated: (data: CreatePaymentLinkData) => Promise<void> | void;
}

export const CreatePaymentLink: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  bookingId,
  bookingBalance,
  email,
  onClose,
  onPaymentLinkCreated,
}) => {
  const { mutateAsync: createPaymentLink } = useCreatePaymentLink();

  const onSubmit = async (formValues: CreatePaymentLinkData) => {
    try {
      await createPaymentLink(formValues, {
        onSuccess: async () => {
          onClose();
          await onPaymentLinkCreated(formValues);
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CreatePaymentLinkForm
        contactEmail={email}
        bookingId={bookingId}
        balance={bookingBalance}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    </Modal>
  );
};
