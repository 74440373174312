import React from "react";
import { VehicleIconProps as Props } from ".";

export const VanIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="36" width="4" height="1" rx="0.5" fill="#222222" />
      <path
        d="M20.5 42H34.75M13.25 42H7C5.89543 42 5 41.1046 5 40V34.0702C5 33.6975 5.10416 33.3322 5.30072 33.0155L13.4133 19.9453C13.7781 19.3575 14.4208 19 15.1126 19H48C49.1046 19 50 19.8954 50 21V40C50 41.1046 49.1046 42 48 42H43.375"
        stroke="#FF0018"
      />
      <path
        d="M11 24H17C17.5523 24 18 24.4477 18 25V31C18 31.5523 17.5523 32 17 32H6"
        stroke="#FF0018"
      />
      <circle cx="17" cy="42" r="4" stroke="#222222" />
      <circle cx="39" cy="42" r="4" stroke="#222222" />
    </svg>
  );
};

export default VanIcon;
