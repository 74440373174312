import { InvoiceStatusText } from "@busie/ui-kit";
import { Invoice } from "@busie/utils";
import React from "react";
import { getStatusColor } from "../../model";

interface Props {
  invoice?: Invoice;
}

export const InvoiceStatus: React.FC<React.PropsWithChildren<Props>> = ({
  invoice,
}) => {
  if (!invoice) return null;

  const statusColor = getStatusColor(invoice.status);

  return <InvoiceStatusText color={statusColor} value={invoice.status} />;
};
