import { styled } from "@mui/system";

import MenuItem from "@mui/material/MenuItem";

const MenuItemStyled = styled(MenuItem)(() => {
  return {
    minHeight: "auto",
  };
});

export default MenuItemStyled;
