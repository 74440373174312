import React from "react";
import { styled } from "@mui/system";

import { Text, palette, theme } from "@busie/ui-kit";

import ColumnsStyled from "./styled/ColumnsStyled";
import { useMediaQuery } from "@mui/material";

const ListViewHeaderStyled = styled(ColumnsStyled)(() => {
  return {
    paddingTop: "21px",
    paddingBottom: "21px",
    backgroundColor: palette?.black?.plus80,
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
  };
});

const ListViewHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  return (
    <ListViewHeaderStyled isMobile={isMobile}>
      <Text>Vehicle type</Text>
      <Text>Vehicle ID</Text>
      <Text>Status</Text>
    </ListViewHeaderStyled>
  );
};

export default ListViewHeader;
