import { ChefContract, ChefQuote } from "@busie/api";
import { FlexContainer, H3, H4 } from "@busie/ui-kit";
import { centsToDollars, currencyFormatter } from "@busie/utils";
import React from "react";
import { calculateAcv, calculateLcv } from "./lib";

interface Props {
  quotes?: Omit<ChefQuote & { ID: string }, "_id">[];
  renewals?: ChefContract[];
}

export const Cell: React.FC<Props> = ({ quotes = [], renewals = [] }) => (
  <FlexContainer direction="column" align="flex-start" justify="center">
    <H3 margin="0px">
      {currencyFormatter(centsToDollars(calculateAcv(quotes)))}
    </H3>
    <H4>LCV: {currencyFormatter(centsToDollars(calculateLcv(renewals)))}</H4>
  </FlexContainer>
);
