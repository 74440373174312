import { styled } from "@mui/system";
import { typographyClasses } from "@mui/material/Typography";

import { Theme } from "@mui/system";

interface Props {
  theme?: Theme;
}

const ControlsStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    [`.${typographyClasses.root}`]: {
      fontSize: "12px",
      color: palette?.black?.plus10,
    },
  };
});

export default ControlsStyled;
