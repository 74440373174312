import { Loading } from "@busie/ui-kit";
import store from "~/vehicles/store";
import groupBy from "lodash.groupby";
import { observer } from "mobx-react";
import React from "react";
import Header from "./components/WeekView/Header";
import VehicleTypeBlock from "./components/VehicleTypeBlock";
import { CalendarWeekViewWrapperStyled } from "./styled/CalendarWeekView.styled";

const CalendarWeekView: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const vehicles = store.vehicles;
    const vehiclesByType = groupBy(vehicles, "vehicleType.type");
    const reservationByVehicleType = groupBy(
      store.reservations,
      "vehicle.vehicleType.type"
    );
    if (store.isFetching) {
      return <Loading />;
    }
    return (
      <CalendarWeekViewWrapperStyled>
        <Header />
        {Object.keys(vehiclesByType).map((type) => (
          <VehicleTypeBlock
            key={type}
            type={parseInt(type)}
            vehicles={vehiclesByType[type]}
            reservations={reservationByVehicleType[type] || []}
          />
        ))}
      </CalendarWeekViewWrapperStyled>
    );
  }
);

export default CalendarWeekView;
