import { createTheme, ThemeOptions } from "@mui/material";

import palette from "./palette";

const options: ThemeOptions = {
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    allVariants: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: "0.04em",
    },
  },
  palette,
  breakpoints: {
    unit: "px",
    values: {
      mobile: 0,
      tablet: 769,
      laptop: 1024,
      desktop: 1216,
      widescreen: 1408,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 0.85,
          },
        },
      },
    },
  },
};

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
    widescreen: true;
    up: (key: BreakpointOverrides | number) => string;
    down: (key: BreakpointOverrides | number) => string;
    between: (
      start: BreakpointOverrides | number,
      end: BreakpointOverrides | number
    ) => string;
    only: (key: BreakpointOverrides) => string;
  }
}

const theme = createTheme(options);

export default theme;
