import { styled } from "@mui/system";

const SelectRecipeAssignmentStyled = styled("div")(() => ({
  ".select-block": {
    margin: "5px 0 15px 30px",
    maxWidth: "288px",
  },
}));

export default SelectRecipeAssignmentStyled;
