import React from "react";

import {
  Button,
  FlexContainer,
  NoContentBlock,
  PlusIcon,
  QuotesEmptyState,
  palette,
} from "@busie/ui-kit";

interface Props {
  onAddNewContract: () => void;
  onClearFilter?: () => void;
}

export const NoContent: React.FC<Props> = ({
  onAddNewContract,
  onClearFilter,
}) => {
  return (
    <NoContentBlock
      // TODO: click handler
      icon={<QuotesEmptyState />}
      text="You don't have any Contracts matching the current filter or search query. Change the filter or search input, or add a new Contract below."
      controls={
        <FlexContainer
          direction="row"
          justify="space-between"
          align="center"
          columnGap={2}
        >
          <Button
            typestyle="primary"
            onClick={onAddNewContract}
            startIcon={<PlusIcon color={palette.black.plus100} />}
          >
            Add Contract
          </Button>

          {onClearFilter && (
            <Button size="small" typestyle="tertiary" onClick={onClearFilter}>
              Clear Filter
            </Button>
          )}
        </FlexContainer>
      }
    >
      <p>
        You don't have any Contracts matching the current filter or search
        query.
      </p>
      <p>Change the filter or search input, or add a new Contract below.</p>
    </NoContentBlock>
  );
};
