import { Amenity } from "@busie/utils";

export const mapAmenityToName = (amenity: Amenity): string => {
  return (
    {
      [Amenity.ARMREST]: "Armrest",
      [Amenity.CUSTOM]: "Custom",
      [Amenity.DVD_PLAYER]: "DVD Player",
      [Amenity.FOOTRESTS]: "Footrests",
      [Amenity.HEADRESTS]: "Headrests",
      [Amenity.KNEELING_COACH]: "Kneeling Coach",
      [Amenity.OUTLETS]: "Outlets",
      [Amenity.RECLINING_SEATS]: "Reclining Seats",
      [Amenity.RESTROOM]: "Restroom",
      [Amenity.SEATBELTS]: "Seatbelts",
      [Amenity.USB_PORTS]: "USB Ports",
      [Amenity.WIFI]: "WiFi",
    }[amenity] || ""
  );
};
