import React, { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { ChefBooking, Experience } from "@busie/api";
import {
  EnvelopeStatus,
  Invoice,
  QuoteAndBookingStatus,
  User,
  dayjsExt,
} from "@busie/utils";

import {
  Drawer as BusieDrawer,
  Button,
  DrawerDetailsBlock,
  DrawerHeader,
  FlexContainer,
  H2,
  H3,
  InfoIcon,
  Loading,
  PropertyString,
  palette,
} from "@busie/ui-kit";
import { useAmplitude, usePlan, useUsers } from "@busie/core";

import {
  ContractStatus,
  CustomerContact,
  DrawerOverflowMenu,
  QuoteIntegrationDetails,
  TripList,
} from "~/QuotesAndBookingsPage/entity";
import { useFetchQuoteIntegrations } from "~/QuotesAndBookingsPage/model";
import { Assignee, QnBStatusText } from "~/QuotesAndBookingsPage/shared/ui";
import { InvoiceList } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/Invoice";
import { AdditionalActions, BookingDetails } from "./ui";
import {
  useStatusAction,
  useEContractAction,
  useInvoiceAction,
  useSuggestedDates,
} from "./model";
import {
  CreateInvoice,
  CreatePaymentLink,
  FinalizeInvoice,
  PayInvoice,
  SendContract,
  SendInvoice,
  SendQuote,
  UpdateBookingStatus,
  VoidContract,
  VoidInvoice,
} from "~/QuotesAndBookingsPage/features";
import { notificationStore } from "@busie/features";
import { useDefaultRevenueShareConfig } from "~/QuotesAndBookingsPage/entity/RevenueShareConfig";
import {
  InvoiceDueDate,
  InvoiceOverflowMenu,
  InvoiceStatusText,
  useInvoicePreview,
  useListInvoices,
} from "~/QuotesAndBookingsPage/entity/Invoice";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "@mui/material";

interface Props {
  isOpen: boolean;
  booking?: ChefBooking;
  isLoading?: boolean;
  onAddTrip: () => void;
  onEditBooking: () => void;
  onClose: () => void;
  onTripClicked: (trip: Experience) => void;
  onBookingUpdated: () => void;
}

export const Drawer: React.FC<Props> = ({
  isOpen,
  booking,
  isLoading = false,
  onAddTrip,
  onEditBooking,
  onClose,
  onTripClicked,
  onBookingUpdated,
}) => {
  const { data: usersMap = {}, isLoading: isUsersLoading } = useUsers();
  const { user } = useAuth0<User>();

  const [newStatus, setNewStatus] = useState<QuoteAndBookingStatus>();
  const [isUpdateBookingStatusOpen, setIsUpdateBookingStatusOpen] =
    useState(false);

  const [isSendQuoteOpen, setIsSendQuoteOpen] = useState(false);
  const [isSendEContractOpen, setIsSendEContractOpen] = useState(false);
  const [isVoidEContractOpen, setIsVoidEContractOpen] = useState(false);
  const [isCreateInvoiceOpen, setIsCreateInvoiceOpen] = useState(false);
  const [isFinalizeInvoiceOpen, setIsFinalizeInvoiceOpen] = useState(false);
  const [isSendInvoiceOpen, setIsSendInvoiceOpen] = useState(false);
  const [isVoidInvoiceOpen, setIsVoidInvoiceOpen] = useState(false);
  const [isPayInvoiceOpen, setIsPayInvoiceOpen] = useState(false);
  const [isCreatePaymentLinkOpen, setIsCreatePaymentLinkOpen] = useState(false);

  const statusAction = useStatusAction(booking, {
    onConfirm: useCallback(() => {
      setNewStatus(QuoteAndBookingStatus.CONFIRMED);
      setIsUpdateBookingStatusOpen(true);
    }, []),
    onMarkPaid: useCallback(() => {
      setNewStatus(QuoteAndBookingStatus.PAID);
      setIsUpdateBookingStatusOpen(true);
    }, []),
  });

  const eContractAction = useEContractAction(booking, {
    onSend: useCallback(() => setIsSendEContractOpen(true), []),
    onVoid: useCallback(() => setIsVoidEContractOpen(true), []),
  });

  const invoiceAction = useInvoiceAction(booking, {
    onCreate: useCallback(() => setIsCreateInvoiceOpen(true), []),
    onFinalize: useCallback(() => setIsFinalizeInvoiceOpen(true), []),
    onSend: useCallback(() => setIsSendInvoiceOpen(true), []),
    onVoid: useCallback(() => setIsVoidInvoiceOpen(true), []),
    onMarkPaid: useCallback(() => setIsPayInvoiceOpen(true), []),
  });

  const { data: defaultRevShare } = useDefaultRevenueShareConfig(
    booking?.QUOTE.ORGANIZATION_ID
  );

  const {
    data: invoiceHistory = [],
    isLoading: isInvoiceHistoryLoading,
    isFetching: isInvoiceHistoryFetching,
    // adding the default as an empty string will result in an empty array.
  } = useListInvoices({ bookingId: booking?._id || "" });

  const {
    data: invoicePreviewUrl,
    isLoading: isInvoicePreviewLoading,
    isFetching: isInvoicePreviewFetching,
  } = useInvoicePreview(
    booking?.ACTIVE_INVOICE?._ID,
    booking?.ACTIVE_INVOICE?.STATUS
  );

  const { data: plan, isLoading: isPlanLoading } = usePlan(user?.org_id);

  const [_, setCounter] = useState(0);

  const componentRef = useRef(null);
  const printingRef = useRef(false);

  const handlePrint = () => {
    printingRef.current = true;
    setCounter((c) => c + 1);
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Booking Details",
  });

  useEffect(() => {
    if (printingRef.current === true) {
      printingRef.current = false;
      print();
    }
  }, [printingRef, print]);

  const {
    data: integrations,
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
  } = useFetchQuoteIntegrations();

  const dateSuggestions = useSuggestedDates(booking);

  const { track } = useAmplitude();

  const trackInvoiceEvent = (event: string, invoice: Invoice) =>
    track(event, {
      bookingId: invoice.bookingId,
      invoiceAmount: invoice.amount,
      invoiceId: invoice._id,
      invoicedCustomerGroupId: invoice.customer.groupId,
      invoicedCustomerGroupName: invoice.customer.groupName,
      invoicedCustomerId: invoice.customer.id,
      invoicedCustomerName: invoice.customer.name,
      invoiceStatus: invoice.status,
      invoiceDaysUntilDue: invoice.daysUntilDue,
    });

  return (
    <BusieDrawer anchor="right" open={isOpen} onClose={onClose}>
      <DrawerHeader onClose={onClose} title="Booking Details">
        <DrawerOverflowMenu onAddTrip={onAddTrip} onEdit={onEditBooking} />
      </DrawerHeader>
      {isLoading ? (
        <Loading />
      ) : (
        (!!booking && (
          <>
            <div className="trip-details-wrapper" ref={componentRef}>
              <DrawerDetailsBlock noHeader>
                <FlexContainer direction="column" rowGap={1}>
                  <FlexContainer
                    align="center"
                    justify="space-between"
                    fullWidth
                    direction="row"
                  >
                    <H2 weight={600}>Status</H2>
                    <FlexContainer align="center" columnGap={5}>
                      <QnBStatusText
                        status={booking.STATUS as QuoteAndBookingStatus}
                      />
                      {(statusAction && (
                        <Button
                          typestyle="primary"
                          onClick={statusAction.onClick}
                        >
                          {statusAction.text}
                        </Button>
                      )) ||
                        null}
                    </FlexContainer>
                  </FlexContainer>
                  {![
                    QuoteAndBookingStatus.CANCELED,
                    QuoteAndBookingStatus.PAID,
                    QuoteAndBookingStatus.COMPLETE,
                  ].includes(booking.STATUS as QuoteAndBookingStatus) ? (
                    <>
                      <FlexContainer
                        direction="row"
                        justify="space-between"
                        align="center"
                        fullWidth
                      >
                        <H3 weight={500} color={palette.black.main}>
                          Re-Send Quote
                        </H3>
                        <Button
                          typestyle="secondary"
                          onClick={() => setIsSendQuoteOpen(true)}
                        >
                          Send Quote
                        </Button>
                      </FlexContainer>
                      <FlexContainer
                        direction="row"
                        align="center"
                        justify="space-between"
                        fullWidth
                      >
                        <H3 weight={500} color={palette.red.main}>
                          Cancel Booking
                        </H3>

                        <Button
                          typestyle="accent"
                          onClick={() => {
                            setNewStatus(QuoteAndBookingStatus.CANCELED);
                            setIsUpdateBookingStatusOpen(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </FlexContainer>
                    </>
                  ) : null}
                  <PropertyString
                    justify="space-between"
                    name="Assignee"
                    margin="16px 0 5px"
                  >
                    {isUsersLoading ? (
                      <Loading size={24} padding="0px" />
                    ) : (
                      <Assignee
                        assignee={
                          booking.QUOTE.ASSIGNEE_ID &&
                          usersMap[booking.QUOTE.ASSIGNEE_ID]
                            ? {
                                name: usersMap[booking.QUOTE.ASSIGNEE_ID].name,
                                imgSrc:
                                  usersMap[booking.QUOTE.ASSIGNEE_ID].picture,
                              }
                            : undefined
                        }
                      />
                    )}
                  </PropertyString>
                </FlexContainer>
              </DrawerDetailsBlock>

              {/* E-Contract Block - create a module for this? */}
              <DrawerDetailsBlock
                name="E-Contract"
                className="no-print"
                collapsible
              >
                <FlexContainer direction="column" rowGap={1}>
                  {booking.CONTRACT_STATUS && (
                    <FlexContainer
                      direction="row"
                      align="center"
                      justify="space-between"
                      fullWidth
                    >
                      <H3 weight={600}>E-Contract Status</H3>
                      <ContractStatus
                        status={booking.CONTRACT_STATUS as EnvelopeStatus}
                      />
                    </FlexContainer>
                  )}

                  {eContractAction && (
                    <FlexContainer
                      direction="row"
                      justify="space-between"
                      align="center"
                      fullWidth
                    >
                      <H3 weight={500}>{eContractAction.text} E-Contract</H3>
                      <Button
                        typestyle="primary"
                        onClick={eContractAction.onClick}
                      >
                        {eContractAction.text}
                      </Button>
                    </FlexContainer>
                  )}
                </FlexContainer>
              </DrawerDetailsBlock>

              {(new Set([EnvelopeStatus.COMPLETED, EnvelopeStatus.SIGNED]).has(
                booking.CONTRACT_STATUS as EnvelopeStatus
              ) ||
                booking.STATUS === QuoteAndBookingStatus.CONFIRMED) && (
                <DrawerDetailsBlock
                  collapsible
                  name="Payment"
                  className="no-print"
                >
                  {isPlanLoading ? (
                    <Loading size={24} padding="0px" />
                  ) : (
                    <FlexContainer direction="column" rowGap={1}>
                      {booking.ACTIVE_INVOICE && (
                        <FlexContainer
                          direction="row"
                          justify="space-between"
                          align="center"
                          fullWidth
                        >
                          <H3 weight={600}>Active Invoice</H3>

                          <InvoiceStatusText
                            status={booking.ACTIVE_INVOICE.STATUS}
                          />

                          <InvoiceDueDate
                            createdDate={
                              new Date(booking.ACTIVE_INVOICE.CREATED_AT)
                            }
                            daysUntilDue={booking.ACTIVE_INVOICE.DAYS_UNTIL_DUE}
                          />

                          {isInvoicePreviewLoading ||
                          isInvoicePreviewFetching ? (
                            <Loading size={24} />
                          ) : (
                            <InvoiceOverflowMenu
                              status={booking.ACTIVE_INVOICE.STATUS}
                              onMarkPaid={() => setIsPayInvoiceOpen(true)}
                              onPreview={() =>
                                window.open(invoicePreviewUrl || "#", "_blank")
                              }
                              onVoid={() => setIsVoidInvoiceOpen(true)}
                            />
                          )}
                        </FlexContainer>
                      )}

                      {invoiceAction && (
                        <FlexContainer
                          direction="row"
                          align="center"
                          justify="space-between"
                          fullWidth
                        >
                          <H3 weight={600}>{invoiceAction.text}</H3>
                          <Button
                            typestyle="primary"
                            onClick={invoiceAction.onClick}
                            disabled={plan?.subscriptionType === "FREEMIUM"}
                          >
                            {invoiceAction.text}
                          </Button>
                          {plan?.subscriptionType === "FREEMIUM" && (
                            <Tooltip title="Invoicing is a paid feature. Based on your Organization's plan, you do not have access to invoicing. If you believe this is incorrect, or would like to upgrade to gain access, please contact us at support@getbusie.com">
                              <div>
                                <InfoIcon color={palette.black.plus20} />
                              </div>
                            </Tooltip>
                          )}
                        </FlexContainer>
                      )}

                      {(!booking.ACTIVE_INVOICE ||
                        booking.ACTIVE_INVOICE.STATUS === "VOIDED") && (
                        <FlexContainer
                          direction="row"
                          align="center"
                          justify="space-between"
                          fullWidth
                        >
                          <H3 weight={600}>Payment Link</H3>
                          <Button
                            typestyle="secondary"
                            onClick={() => setIsCreatePaymentLinkOpen(true)}
                          >
                            Create Payment Link
                          </Button>
                        </FlexContainer>
                      )}

                      {/* TODO: we can actually just use booking.INVOICES, but the data model of the InvoiceList won't accept this  */}
                      {isInvoiceHistoryFetching || isInvoiceHistoryLoading ? (
                        <Loading size={24} />
                      ) : (
                        <InvoiceList invoices={invoiceHistory} />
                      )}
                    </FlexContainer>
                  )}
                </DrawerDetailsBlock>
              )}

              <BookingDetails booking={booking} />

              <CustomerContact
                title="Main Contact"
                customer={{
                  NAME:
                    booking.QUOTE.MAIN_CONTACT?.NAME ||
                    booking.QUOTE.CONTACT_NAME,
                  EMAIL:
                    booking.QUOTE.MAIN_CONTACT?.EMAIL ||
                    booking.QUOTE.CONTACT_EMAIL,
                  AREA_CODE: booking.QUOTE.MAIN_CONTACT?.AREA_CODE || "",
                  COUNTRY_CODE: booking.QUOTE.MAIN_CONTACT?.COUNTRY_CODE || "",
                  PHONE_NUMBER: booking.QUOTE.MAIN_CONTACT?.PHONE_NUMBER || "",
                  GROUP: {
                    NAME: booking.QUOTE.CUSTOMER_GROUP.NAME,
                    TYPE:
                      booking.QUOTE.CUSTOMER_GROUP.GROUP_TYPE ||
                      booking.QUOTE.CUSTOMER_GROUP.TYPE,
                  },
                }}
              />

              <TripList
                quote={{ ...booking.QUOTE, _id: booking.QUOTE.ID }}
                onClickTrip={onTripClicked}
              />

              {booking.QUOTE.QUOTE_INTEGRATION_ID &&
              booking.QUOTE.QUOTE_INTEGRATION
                ? ((isIntegrationsLoading || isIntegrationsFetching) && (
                    <Loading />
                  )) || (
                    <QuoteIntegrationDetails
                      sourceId={
                        booking.QUOTE.QUOTE_INTEGRATION.SOURCE_ID as string
                      }
                      destinationId={
                        booking.QUOTE.QUOTE_INTEGRATION.DESTINATION_ID as string
                      }
                      expirationDate={booking.QUOTE.EXPIRATION_DATE as string}
                      integrations={
                        integrations || {
                          organizationLogos: {},
                          organizationNames: {},
                          integrations: [],
                          sourceIntegrations: [],
                          destinationIntegrations: [],
                        }
                      }
                      revenueShareDestinations={
                        booking.QUOTE.QUOTE_INTEGRATION?.REVENUE_SHARE_CONFIG?.DESTINATIONS?.map(
                          (dest) => ({
                            organizationId: dest.ORGANIZATION_ID as string,
                            apportionmentValue:
                              dest.APPORTIONMENT_VALUE as number,
                          })
                        ) || []
                      }
                    />
                  )
                : null}

              <AdditionalActions
                onPrint={handlePrint}
                onCancel={() => {
                  setNewStatus(QuoteAndBookingStatus.CANCELED);
                  setIsUpdateBookingStatusOpen(true);
                }}
              />
            </div>

            {newStatus && (
              <UpdateBookingStatus
                booking={booking}
                isOpen={isUpdateBookingStatusOpen}
                newStatus={newStatus}
                onStatusUpdate={() => {
                  setIsUpdateBookingStatusOpen(false);
                  onBookingUpdated();
                }}
                onClose={() => setIsUpdateBookingStatusOpen(false)}
              />
            )}

            <SendQuote
              quote={{ ...booking.QUOTE, _id: booking.QUOTE.ID }}
              isOpen={isSendQuoteOpen}
              onClose={() => setIsSendQuoteOpen(false)}
              onQuoteSent={() => {
                setIsSendQuoteOpen(false);
                onBookingUpdated();
              }}
            />

            <SendContract
              isOpen={isSendEContractOpen}
              data={{
                bookingId: booking._id,
                email:
                  booking.QUOTE.MAIN_CONTACT?.EMAIL ||
                  booking.QUOTE.CONTACT_EMAIL,
                name:
                  booking.QUOTE.MAIN_CONTACT?.NAME ||
                  booking.QUOTE.CONTACT_NAME,
              }}
              metadata={{
                quoteId: booking.QUOTE.ID,
                balance: booking.BALANCE,
                price: booking.QUOTE.PRICE,
              }}
              onClose={() => setIsSendEContractOpen(false)}
              onSuccess={() => {
                setIsSendEContractOpen(false);
                onBookingUpdated();
              }}
            />

            <VoidContract
              bookingId={booking._id}
              quoteId={booking.QUOTE.ID}
              isOpen={isVoidEContractOpen}
              onClose={() => setIsVoidEContractOpen(false)}
              onSuccess={() => {
                setIsVoidEContractOpen(false);
                onBookingUpdated();
              }}
            />

            {defaultRevShare && (
              <CreateInvoice
                isOpen={isCreateInvoiceOpen}
                organizationId={booking.QUOTE.ORGANIZATION_ID}
                amount={booking.BALANCE}
                bookingId={booking._id}
                mainContactId={
                  booking.QUOTE.MAIN_CONTACT_ID ||
                  booking.QUOTE.EXPERIENCES[0]?.MAIN_CONTACT.ID
                }
                customerGroupId={booking.QUOTE.CUSTOMER_GROUP.ID}
                revenueShareConfigId={
                  booking.QUOTE.QUOTE_INTEGRATION?.REVENUE_SHARE_CONFIG_ID ||
                  defaultRevShare?._id
                }
                dateSuggestions={dateSuggestions}
                onClose={() => setIsCreateInvoiceOpen(false)}
                onInvoiceCreated={(invoice) => {
                  trackInvoiceEvent("invoice created", invoice);

                  notificationStore.setNotification({
                    type: "success",
                    message:
                      "Your invoice is being created. Please allow a few seconds for changes to propogate.",
                    header: "Success!",
                  });

                  onBookingUpdated();
                }}
              />
            )}

            {booking.ACTIVE_INVOICE && (
              <>
                <VoidInvoice
                  isOpen={isVoidInvoiceOpen}
                  email={booking.ACTIVE_INVOICE.CUSTOMER.email}
                  invoiceId={booking.ACTIVE_INVOICE._ID}
                  onClose={() => setIsVoidInvoiceOpen(false)}
                  onInvoiceVoided={(invoice) => {
                    trackInvoiceEvent("invoice voided", invoice);

                    notificationStore.setNotification({
                      type: "success",
                      message:
                        "Your invoice is being voided. Please allow a few seconds for changes to propogate",
                      header: "Success",
                    });

                    onBookingUpdated();
                  }}
                />

                <SendInvoice
                  email={booking.ACTIVE_INVOICE.CUSTOMER.email}
                  invoiceId={booking.ACTIVE_INVOICE._ID}
                  isOpen={isSendInvoiceOpen}
                  onClose={() => setIsSendInvoiceOpen(false)}
                  onInvoiceSent={(invoice) => {
                    trackInvoiceEvent("invoice sent", invoice);

                    notificationStore.setNotification({
                      type: "success",
                      message:
                        "Your invoice is being sent. Please allow a few seconds for changes to propogate.",
                      header: "Success",
                    });

                    onBookingUpdated();
                  }}
                />

                <FinalizeInvoice
                  isOpen={isFinalizeInvoiceOpen}
                  invoiceId={booking.ACTIVE_INVOICE._ID}
                  hasPayouts={
                    !!Object.keys(booking.ACTIVE_INVOICE.PAYOUTS).length
                  }
                  onClose={() => setIsFinalizeInvoiceOpen(false)}
                  onInvoiceFinalized={(invoice) => {
                    trackInvoiceEvent("invoice finalized", invoice);

                    notificationStore.setNotification({
                      type: "success",
                      message:
                        "Your invoice is being finalized. Please allow a few seconds for changes to propogate.",
                      header: "Success",
                    });

                    onBookingUpdated();
                  }}
                />

                <PayInvoice
                  isOpen={isPayInvoiceOpen}
                  onClose={() => setIsPayInvoiceOpen(false)}
                  invoiceId={booking.ACTIVE_INVOICE._ID}
                  onInvoicePaid={(invoice) => {
                    trackInvoiceEvent("invoice paid offline", invoice);

                    notificationStore.setNotification({
                      type: "success",
                      message:
                        "Your invoice is being marked as paid. Please allow a few seconds for changes to propogate",
                      header: "Success",
                    });

                    onBookingUpdated();
                  }}
                />
              </>
            )}

            <CreatePaymentLink
              isOpen={isCreatePaymentLinkOpen}
              bookingId={booking._id}
              bookingBalance={booking.BALANCE}
              email={
                booking.QUOTE.MAIN_CONTACT?.EMAIL || booking.QUOTE.CONTACT_EMAIL
              }
              onClose={() => setIsCreatePaymentLinkOpen(false)}
              onPaymentLinkCreated={(data) => {
                track("payment link sent", {
                  balance: booking.BALANCE,
                  paymentAmount: data.amount,
                  bookingId: booking._id,
                  price: booking.QUOTE.PRICE,
                  quoteId: booking.QUOTE.ID,
                });

                notificationStore.setNotification({
                  type: "success",
                  message:
                    "Your payment link has been created and sent to the customer.",
                  header: "Success",
                });
              }}
            />
          </>
        )) || <H2>No Content...</H2>
      )}
    </BusieDrawer>
  );
};
