import { PayInvoiceData } from "@busie/api";
import { notificationStore } from "@busie/features";
import { Modal } from "@busie/ui-kit";
import { Invoice } from "@busie/utils";
import { PayInvoiceForm } from "~/QuotesAndBookingsPage/entity/Invoice";
import { usePayInvoice } from "./model";

interface Props {
  isOpen: boolean;
  invoiceId: string;
  onClose: () => void;
  onInvoicePaid: (invoice: Invoice) => Promise<void> | void;
}

export const PayInvoice: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  invoiceId,
  onClose,
  onInvoicePaid,
}) => {
  const { mutateAsync: payInvoice } = usePayInvoice();

  const onSubmit = async (payInvoiceData: PayInvoiceData) => {
    try {
      await payInvoice(
        { invoiceId, payInvoiceData },
        {
          onSuccess: async (result) => {
            onClose();
            await onInvoicePaid(result);
          },
          onError: (e) => {
            notificationStore.setNotificationFromError(e);
          },
        }
      );
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <PayInvoiceForm onCancel={onClose} onSubmit={onSubmit} />
    </Modal>
  );
};
