import React from "react";
import { Text, FlexContainer } from "@busie/ui-kit";
import { VehicleTypes } from "@busie/utils";

import { VehicleIcon, mapVehicleTypeToName } from "@busie/core";
interface Props {
  vehicleType: VehicleTypes;
  isMobile?: boolean;
}

const VehicleTypeLabel: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const { vehicleType } = props;
  return (
    <FlexContainer align="center" columnGap={2}>
      {!props.isMobile && <VehicleIcon type={vehicleType} size={30} />}
      <Text>{mapVehicleTypeToName(vehicleType)}</Text>
    </FlexContainer>
  );
};

export default VehicleTypeLabel;
