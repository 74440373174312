import {
  FlexContainer,
  FormContainer,
  InfoIcon,
  Text,
  theme,
  palette,
  ToggleSwitch,
  H3,
  Button,
  AddIcon,
  PlaceFieldHookForm,
  Banner,
} from "@busie/ui-kit";
import { GooglePlace, initialPlaceData } from "@busie/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { Tooltip, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Place, RouteFormData, schema, serializePlaceForForm } from "./model";
import { PlaceError } from "./ui/PlaceError";

interface Props {
  onCancel: () => void;
  onSubmit: (data: RouteFormData) => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  onCancel,
  onSubmit,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const [isRoundTrip, setIsRoundTrip] = useState(true);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<RouteFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      stops: [] as Place[],
      pickup: serializePlaceForForm(initialPlaceData),
      destination: serializePlaceForForm(initialPlaceData),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stops",
  });

  useEffect(() => () => reset(), [reset]);

  useEffect(() => {
    if (isRoundTrip) setValue("destination", getValues("pickup"));

    if (isRoundTrip && getValues("stops").length === 0)
      append(serializePlaceForForm(initialPlaceData));
  }, [isRoundTrip, setValue, getValues, append]);

  const pickupChangeHandler = useCallback(
    (field: ControllerRenderProps<RouteFormData, "pickup">) =>
      (value: GooglePlace) => {
        field.onChange(serializePlaceForForm(value));
        if (isRoundTrip) setValue("destination", serializePlaceForForm(value));
      },
    [isRoundTrip, setValue]
  );

  return (
    <FormContainer title="Route Details">
      <FlexContainer direction="column" fullWidth>
        <Banner
          bordered
          text="Please be sure to include stops in your itinerary so we
            can give you the most accurate price!"
          backgroundColor={palette.supernova.plus40}
          textColor={palette.black.plus10}
          icon={
            <InfoIcon
              size={isMobile ? "24px" : "16px"}
              color={palette.black.plus10 || "#00000"}
            />
          }
        />
      </FlexContainer>

      <form aria-live="polite" onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer direction="row" fullWidth columnGap={2} align="center">
          <H3 color={isRoundTrip ? "grey" : undefined}>One Way</H3>
          <Tooltip
            title="I do not want the Vehicle(s) to bring my group back to our original pickup location."
            placement="top"
          >
            <FlexContainer align="center">
              <InfoIcon size={16} />
            </FlexContainer>
          </Tooltip>

          <ToggleSwitch
            checked={isRoundTrip}
            onChange={() => setIsRoundTrip((val) => !val)}
          />

          <H3 color={!isRoundTrip ? "grey" : undefined}>Round Trip</H3>
          <Tooltip
            title="I want the Vehicle(s) to bring my group back to our original pickup location."
            placement="top"
          >
            <FlexContainer align="center">
              <InfoIcon size={16} />
            </FlexContainer>
          </Tooltip>
        </FlexContainer>

        <FlexContainer direction="column" fullWidth rowGap={3}>
          <FlexContainer direction="column" fullWidth rowGap={0}>
            <Controller
              name="pickup"
              control={control}
              render={({ field }) => {
                return (
                  <PlaceFieldHookForm
                    {...field}
                    label="Pickup Location"
                    placeholder="Enter pickup location"
                    value={{
                      ...field.value,
                      geometry: {
                        location: {
                          lat: () => field.value.geometry.location.lat,
                          lng: () => field.value.geometry.location.lng,
                        },
                      },
                    }}
                    onChange={pickupChangeHandler(field)}
                    width="100%"
                  />
                );
              }}
            />
            <PlaceError error={errors.pickup} />
          </FlexContainer>

          {fields.map((controlField, index) => (
            <FlexContainer
              key={controlField.id}
              fullWidth
              direction="column"
              rowGap={0}
            >
              <Controller
                name={`stops.${index}`}
                control={control}
                render={({ field }) => (
                  <PlaceFieldHookForm
                    {...field}
                    label={`Stop ${index + 1}`}
                    placeholder="Enter stop location"
                    value={{
                      ...field.value,
                      geometry: {
                        location: {
                          lat: () => field.value.geometry.location.lat,
                          lng: () => field.value.geometry.location.lng,
                        },
                      },
                    }}
                    onChange={(value) => {
                      field.onChange(serializePlaceForForm(value));
                    }}
                    onRemove={() => {
                      return !isRoundTrip || fields.length > 1
                        ? remove(index)
                        : undefined;
                    }}
                    width="100%"
                  />
                )}
              />
              <PlaceError error={errors.stops?.[index]} />
            </FlexContainer>
          ))}

          <Button
            onClick={() => {
              append(serializePlaceForForm(initialPlaceData));
            }}
            startIcon={<AddIcon />}
          >
            Add Stop
          </Button>

          <FlexContainer direction="column" rowGap={0} fullWidth>
            <Controller
              name="destination"
              control={control}
              render={({ field }) =>
                isRoundTrip ? (
                  <FlexContainer direction="column" rowGap={1}>
                    <Text weight={600}>Destination Location</Text>
                    <Text weight={500}>{field.value.formatted_address}</Text>
                  </FlexContainer>
                ) : (
                  <PlaceFieldHookForm
                    {...field}
                    label="Final Destination Location"
                    placeholder="Enter destination location"
                    value={{
                      ...field.value,
                      geometry: {
                        location: {
                          lat: () => field.value.geometry.location.lat,
                          lng: () => field.value.geometry.location.lng,
                        },
                      },
                    }}
                    onChange={(value) =>
                      field.onChange(serializePlaceForForm(value))
                    }
                    width="100%"
                  />
                )
              }
            />
            <PlaceError error={errors.destination?.place_id} />
          </FlexContainer>
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
            aria-label="Close the form"
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            aria-label="Continue to the next step of the form"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Next
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
