import React, { useEffect, useState } from "react";

import {
  AddIcon,
  Drawer as BusieDrawer,
  Button,
  Comment,
  DrawerDetailsBlock,
  DrawerHeader,
  FlexContainer,
  H3,
  Loading,
  PropertyString,
  palette,
} from "@busie/ui-kit";

import { useFetchGroup } from "~/CustomersPage/entity/model";
import { useAmplitude, useUsers } from "@busie/core";
import { Group, GroupMetadata, dayjsExt } from "@busie/utils";
import { notificationStore } from "@busie/features";
import {
  AddGroupCustomField,
  AddGroupNote,
  UpdateGroupCustomField,
} from "~/CustomersPage/features";
import { CustomField } from "~/CustomersPage/entity";

interface Props {
  groupId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Drawer: React.FC<Props> = ({ groupId, isOpen, onClose }) => {
  const { data: group, isLoading } = useFetchGroup(groupId);
  const { data: usersMap = {} } = useUsers();

  const [isAddCustomFieldOpen, setIsAddCustomFieldOpen] = useState(false);
  const [isUpdateCustomFieldOpen, setIsUpdateCustomFieldOpen] = useState(false);
  const [updateMetadata, setUpdateMetadata] = useState<GroupMetadata>();

  const { track } = useAmplitude();

  const onNoteAdded = (group: Group) => {
    track("group note added", { ...group });
    notificationStore.setSuccessNotification(
      "Success",
      "Your note was added to the group's profile."
    );
  };

  const onCustomFieldAdded = (group: Group) => {
    track("group custom field added", { ...group });
    notificationStore.setSuccessNotification(
      "Success",
      "Your custom field was added to this group."
    );

    setIsAddCustomFieldOpen(false);
  };

  const onCustomFieldUpdated = (group: Group) => {
    track("group custom field updated", { ...group });
    notificationStore.setSuccessNotification(
      "Success",
      "Your custom field was updated."
    );

    setIsUpdateCustomFieldOpen(false);
  };

  const onFeatureError = (e: unknown) => {
    notificationStore.setNotificationFromError(e);
  };

  const sortedNotes = (group?.notes || []).sort(
    (a, b) => dayjsExt(b.createdAt).unix() - dayjsExt(a.createdAt).unix()
  );

  const onCloseAddCustomField = () => setIsAddCustomFieldOpen(false);

  return (
    <BusieDrawer anchor="right" open={isOpen} onClose={onClose}>
      <DrawerHeader onClose={onClose} title="Group Details" />
      {isLoading ? (
        <Loading />
      ) : !group ? (
        <H3>Group not found</H3>
      ) : (
        <>
          <DrawerDetailsBlock noHeader>
            <PropertyString name="Name">{group.name}</PropertyString>
            <PropertyString name="Type">{group.type}</PropertyString>
            <PropertyString name="Members">
              {group.members.length}
            </PropertyString>

            <FlexContainer direction="column" rowGap={2}>
              <H3 margin="16px 0px 0px">Custom Fields</H3>

              {group.metadata.map(({ id, key, value, ...props }) => (
                <CustomField
                  key={id}
                  name={key}
                  value={value}
                  onEdit={() => {
                    setUpdateMetadata({ id, key, value, ...props });
                    setIsUpdateCustomFieldOpen(true);
                  }}
                  onDelete={() => alert(`delete ${id}`)}
                />
              ))}

              <Button
                typestyle="tertiary"
                size="small"
                startIcon={<AddIcon color={palette.black.main} />}
                onClick={() => setIsAddCustomFieldOpen(true)}
              >
                Add Custom Field
              </Button>
            </FlexContainer>
          </DrawerDetailsBlock>

          <DrawerDetailsBlock name="Notes" collapsible>
            <FlexContainer direction="column" rowGap={2} fullWidth>
              {sortedNotes.map((note) => (
                <Comment
                  key={note.id}
                  text={note.text}
                  imgSrc={usersMap[note.createdBy]?.picture}
                  name={usersMap[note.createdBy]?.name}
                  createdAt={note.createdAt}
                  updatedAt={
                    note.createdAt !== note.updatedAt
                      ? note.updatedAt
                      : undefined
                  }
                />
              ))}

              <AddGroupNote
                groupId={group.id}
                onSuccess={onNoteAdded}
                onError={onFeatureError}
              />
            </FlexContainer>
          </DrawerDetailsBlock>

          <AddGroupCustomField
            groupId={group.id}
            isOpen={isAddCustomFieldOpen}
            onClose={onCloseAddCustomField}
            onSuccess={onCustomFieldAdded}
            onError={onFeatureError}
          />

          {isUpdateCustomFieldOpen && updateMetadata && (
            <UpdateGroupCustomField
              groupId={group.id}
              isOpen={isUpdateCustomFieldOpen}
              onClose={() => setIsUpdateCustomFieldOpen(false)}
              onSuccess={() => {
                setIsUpdateCustomFieldOpen(false);
                setUpdateMetadata(undefined);
              }}
              onError={onFeatureError}
              metadata={updateMetadata}
            />
          )}
        </>
      )}
    </BusieDrawer>
  );
};
