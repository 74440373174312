import { FlexContainer, IconButton, SummaryIcon, palette } from "@busie/ui-kit";
import { Tooltip } from "@mui/material";
import React from "react";

interface Props {
  id: string;
  idShortener?: (id: string) => string;
  type?: string;
}

export const Cell: React.FC<Props> = ({ id, idShortener, type }) => {
  return (
    <FlexContainer
      columnGap={1}
      direction="row"
      align="center"
      justify="flex-start"
    >
      <span style={{ color: palette.red.plus5 }}>
        {(idShortener && idShortener(id)) || id}
      </span>
      <Tooltip title={`Copy full ${type ? type + " " : ""}ID`} placement="top">
        <div>
          <IconButton
            size={24}
            icon={<SummaryIcon size={24} color={palette.black.plus20} />}
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(id);
            }}
          />
        </div>
      </Tooltip>
    </FlexContainer>
  );
};
