import React from "react";
import { Box } from "@mui/system";

import { Text, palette, theme } from "@busie/ui-kit";

const getColor = (a: number) =>
  a >= 66
    ? palette.green.main
    : a >= 33
    ? palette.supernova.main
    : palette.red.main;

interface Props {
  availability: number;
  isMobile?: boolean;
}

const AvailabilityPercentage: React.FC<React.PropsWithChildren<Props>> = ({
  availability,
  isMobile,
}) => {
  return isMobile ? (
    <Box
      sx={{
        height: theme.spacing(1),
        width: theme.spacing(1),
        borderRadius: "100px",
        border: `${getColor(availability || 0)} 1px solid`,
      }}
    />
  ) : (
    <Text
      weight={300}
      sx={{
        fontSize: "12px",
        color: getColor(availability),
      }}
    >
      {(availability || 0).toFixed(0)}% Avail.
    </Text>
  );
};

export default AvailabilityPercentage;
