// TODO: refactor to useQuery and useMutation
import { makeAutoObservable, runInAction } from "mobx";
import {
  Customer,
  QuoteAndBookingStatus,
  QuotesIntegration,
  Integration,
  Trip,
  DispatchLocation,
} from "@busie/utils";
import {
  fetchBookings,
  fetchQuotes,
  fetchQuotesIntegrations,
  QuoteWithAttrs,
  createPayment,
  fetchGroup,
  QuotesResponse,
  fetchCustomersByIds,
  fetchTrips,
  getDispatchLocations,
} from "@busie/api";
import { notificationStore } from "@busie/features";
import {
  getFilterParams,
  collectQuotesWithTrips,
  getQuotesIntegrationFromId,
  quoteIntegrationsToMap,
} from "~/QuotesAndBookingsPage/shared/lib";

import { QUOTES_IN_USE_STATUSES, ITEMS_PER_PAGE } from "../constants";

type AuthTokens = {
  quotes: string;
  trips: string;
  customers: string;
  dispatchLocations: string;
};

class QuotesStore {
  customers: Customer[] = [];
  quotesResponse: QuotesResponse | null = null;
  items: QuoteWithAttrs[] = [];
  dispatchLocations: DispatchLocation[] = [];
  quotesIntegrations: QuotesIntegration | null = null;
  integrationMap: Map<string, Integration> = new Map();
  integrationNames: {
    [key: string]: string;
  } = {};
  integrationLogos: {
    [key: string]: string;
  } = {};
  authTokens: AuthTokens = {
    quotes: "",
    trips: "",
    customers: "",
    dispatchLocations: "",
  };
  isFetching = true;
  isEmpty = false;
  selectedItems: string[] = [];
  paymentRedirect: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    this.addSelectedItems = this.addSelectedItems.bind(this);
    this.removeSelectedItems = this.removeSelectedItems.bind(this);
    this.setItems = this.setItems.bind(this);
  }

  public async setItems(page?: number): Promise<void> {
    this.selectedItems = [];
    this.isFetching = true;
    try {
      const trips = await fetchTrips(this.authTokens.trips, {
        // populateLegs: true,
      });
      const tripIdMap = new Map(trips.map((trip) => [trip._id, trip]));

      const filterParams = getFilterParams();
      if (!this.authTokens.quotes) return;
      const quotesResponse = await fetchQuotes(this.authTokens.quotes, {
        status:
          filterParams.status !== "ALL"
            ? (filterParams.status as QuoteAndBookingStatus)
            : QUOTES_IN_USE_STATUSES,
        locationId:
          filterParams.locationId === "ALL" ? null : filterParams.locationId,
        page,
        pickupDateFrom: filterParams.pickupDateFrom,
        pickupDateTo: filterParams.pickupDateTo,
        itemsPerPage: ITEMS_PER_PAGE,
        [`${filterParams.searchParam}`]: filterParams.searchValue,
      });
      const quotes = quotesResponse.resultSet;
      const filteredTrips = quotes.reduce((acc, quote) => {
        if (tripIdMap.has(quote._experienceId)) {
          acc.push(tripIdMap.get(quote._experienceId) as Trip);
        }
        return acc;
      }, [] as Trip[]);

      const customerIds = filteredTrips.map(
        (trip) => trip._mainContactId || trip._tripPlannerId
      );

      if (!this.authTokens.customers) return;
      const customers = await fetchCustomersByIds(
        this.authTokens.customers,
        customerIds
      );
      if (!this.authTokens.quotes) return;
      const quotesIntegrations = await fetchQuotesIntegrations(
        this.authTokens.quotes
      );
      if (!this.authTokens.quotes) return;
      const bookingsResponse = await fetchBookings(this.authTokens.quotes, {
        itemsPerPage: ITEMS_PER_PAGE,
      });
      const bookings = bookingsResponse.resultSet;

      if (!this.authTokens.dispatchLocations) return;
      const dispatchLocations = await getDispatchLocations(
        this.authTokens.dispatchLocations
      );

      if (!quotes.length && !bookings.length && filterParams.status === "ALL") {
        this.isFetching = false;
        this.isEmpty = true;
        return;
      }
      const quotesWithTrips = await collectQuotesWithTrips(quotes, {
        customers,
        trips: filteredTrips,
      });

      runInAction(() => {
        this.quotesResponse = quotesResponse;
        this.items = quotesWithTrips;
        this.quotesIntegrations = quotesIntegrations;
        this.dispatchLocations = dispatchLocations;
        this.integrationMap = quoteIntegrationsToMap(quotesIntegrations);
        this.integrationNames = quotesIntegrations.organizationNames;
        this.integrationLogos = quotesIntegrations.organizationLogos;
        this.isFetching = false;
      });
    } catch (e: unknown) {
      notificationStore.setNotificationFromError(e);
    }
  }

  public getIntegration = (integrationId: string, organizationId: string) => {
    return getQuotesIntegrationFromId(
      integrationId,
      organizationId,
      this.integrationMap,
      this.integrationNames,
      this.integrationLogos
    );
  };

  public setAuthTokens(authTokens: AuthTokens): void {
    this.authTokens = authTokens;
  }
  public addSelectedItems(items: string[]): void {
    // make array with unique items
    this.selectedItems = [...new Set([...this.selectedItems, ...items])];
  }
  public removeSelectedItems(items: string[]): void {
    const itemSet = new Set(items);
    this.selectedItems = this.selectedItems.filter(
      (item) => !itemSet.has(item)
    );
  }
  public clearSelectedItems(): void {
    this.selectedItems = [];
  }
  public reset(): void {
    this.customers = [];
    this.items = [];
    this.dispatchLocations = [];
    this.authTokens = {
      quotes: "",
      trips: "",
      customers: "",
      dispatchLocations: "",
    };
    this.isFetching = true;
    this.isEmpty = false;
    this.selectedItems = [];
    this.quotesResponse = null;
  }

  public async makePayment(
    paymentAuthToken: string,
    customerAuthToken: string,
    customerGroupId: string,
    sub: string | undefined,
    organizationId: string,
    bookingId: string,
    amount: number
  ): Promise<void> {
    try {
      const group = await fetchGroup(customerAuthToken, customerGroupId);
      const customer = group.members.find((member) => member.userId === sub);
      if (!customer) {
        notificationStore.setNotification({
          type: "failure",
          message: "No customer found!",
        });
        return;
      }
      const data = await createPayment(
        paymentAuthToken,
        customer.id,
        organizationId,
        bookingId,
        amount
      );
      this.paymentRedirect = data.url;
    } catch (e: unknown) {
      notificationStore.setNotificationFromError(e);
    }
  }
}

export const store = new QuotesStore();
