import { styled } from "@mui/system";

import { MenuProps } from "../index";

const MenuStyled = styled("div")(
  (props: Pick<MenuProps, "top" | "bottom" | "right" | "left">) => {
    return {
      position: "relative",
      "& .menu-parent": {
        cursor: "pointer",
      },
      "& .menu-block": {
        "z-index": 55,
        position: "absolute",
        zIndex: 1,
        top: props.top,
        bottom: props.bottom,
        left: props.left,
        right: props.right,
      },
    };
  }
);

export default MenuStyled;
