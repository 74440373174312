import * as yup from "yup";
import { MAX_PASSENGERS, MAX_WHEELCHAIRS } from "./constants";
import { Amenity } from "@busie/utils";

export interface TripDetailsFormData {
  passengers: number;
  wheelchairs: number;
  additionalInfo: string;
  amenities: number[];
  spab: boolean;
}

export const schema = yup.object({
  passengers: yup
    .number()
    .required("Number of passengers is required")
    .lessThan(MAX_PASSENGERS, `Must be less than ${MAX_PASSENGERS}`)
    .moreThan(0, "Must be greater than 0"),
  wheelchairs: yup
    .number()
    .lessThan(MAX_WHEELCHAIRS, `Must be less than ${MAX_WHEELCHAIRS}`),
  additionalInfo: yup.string(),
  amenities: yup
    .array()
    .min(0)
    .max(Object.values(Amenity).length)
    .of(yup.mixed().oneOf(Object.values(Amenity))),
  spab: yup.boolean(),
});
