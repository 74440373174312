import { FlexContainer, IconButton, PlusIcon, palette } from "@busie/ui-kit";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { SelectGroupMember } from "~/CustomersPage/features";

interface Props<T extends FieldValues> {
  groupId: string;
  control: Control<T>;
  name: Path<T>;
  onCreateCustomer: () => void;
}

export const Controlled: <T extends FieldValues>(
  props: Props<T>
) => React.ReactElement = ({ name, control, groupId, onCreateCustomer }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FlexContainer
          direction="row"
          align="center"
          justify="space-between"
          columnGap={2}
          fullWidth
        >
          <SelectGroupMember
            groupId={groupId}
            initialValue={value}
            onSelect={(member) => onChange(member.id)}
          />
          <IconButton
            icon={<PlusIcon color={palette.black.main} />}
            style={{ marginTop: "8px" }}
            onClick={onCreateCustomer}
          />
        </FlexContainer>
      )}
    />
  );
};
