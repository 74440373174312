import React from "react";

import { WarningPopup } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";

import { useDeleteCustomer } from "./model";

interface Props {
  visible: boolean;
  hide(): void;
  customerId: string;
}

export const DeleteCustomer: React.FC<React.PropsWithChildren<Props>> = ({
  visible,
  hide,
  customerId,
}) => {
  const { mutateAsync } = useDeleteCustomer();

  return (
    <WarningPopup
      title="Delete customer?"
      isPopupOpened={visible}
      onClose={hide}
      onConfirm={async () => {
        try {
          await mutateAsync({ id: customerId });
          hide();
        } catch (e) {
          notificationStore.setNotificationFromError(e);
        }
      }}
      confirmText="Delete"
    >
      Are you sure you want to delete this customer?
    </WarningPopup>
  );
};
