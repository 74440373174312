import { styled } from "@mui/styles";
import { FlexContainer, palette, theme } from "@busie/ui-kit";

export const PopupHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "4px",
}));

const ReservationFormPopupStyled = styled("div")(
  ({
    left,
    top,
    isMobile,
  }: {
    left: string;
    top: string;
    isMobile: boolean;
  }) => ({
    backgroundColor: palette?.black?.plus100,
    height: isMobile ? "100%" : "528px",
    width: isMobile ? "100vw" : "414px",
    position: "static", // isMobile ? "static" : "absolute",
    left: isMobile ? 0 : left,
    top: isMobile ? 0 : top,
    boxShadow: "0px 8px 24px rgba(34, 34, 34, 0.15)",
    borderRadius: "4px",
    zIndex: 1,
  })
);

export const ReservationFormFooterStyled = styled(FlexContainer)(() => ({
  width: "100%",
  backgroundColor: palette.black.plus100,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  boxShadow: "0 0 15px rgba(0,0,0,0.3)",
  clipPath: "inset(-15px 0px 0px 0px)",
}));

export default ReservationFormPopupStyled;
