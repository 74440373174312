import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  DateRange,
  dayjsExt,
  DispatchLocation,
  LogicalQuoteState,
  QuoteAndBookingStatus,
} from "@busie/utils";
import {
  FlexContainer,
  Button,
  Select,
  DateRangePicker,
  palette,
  ChevronDown,
  ChevronUp,
  FilterSelectorStyled,
} from "@busie/ui-kit";

import { SearchBlockStyled, SearchFilterBlockStyled } from "./styled";

export interface AcceptedStatus {
  name: string;
  value: QuoteAndBookingStatus | "ALL";
}

interface Props {
  type: LogicalQuoteState;
  acceptedStatuses: AcceptedStatus[];
  dispatchLocations: DispatchLocation[];
}

// ADDED REACT.FC
export const FilterBlock: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  dispatchLocations,
  acceptedStatuses,
}: Props) => {
  const history = useHistory();
  const { search } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const searchParams = new URLSearchParams(search);

  const pickupDateFrom = searchParams.get("pickupDateFrom");
  const pickupDateTo = searchParams.get("pickupDateTo");

  const [isFilterShown, setIsFilterShown] = React.useState<boolean>(false);

  const [values, setValues] = React.useState<{
    status: string;
    pickupDates: DateRange;
    locationId: string;
  }>({
    status: searchParams.get("status") || "ALL",
    locationId: searchParams.get("locationId") || "ALL",
    pickupDates: [
      pickupDateFrom ? dayjsExt(pickupDateFrom) : null,
      pickupDateTo ? dayjsExt(pickupDateTo) : null,
    ],
  });

  const toggleFilterShown = () => {
    setIsFilterShown(!isFilterShown);
  };

  const submitFilterValues = () => {
    searchParams.set("status", values.status);
    searchParams.set("locationId", values.locationId);
    searchParams.set(
      "pickupDateFrom",
      values.pickupDates[0]
        ? dayjsExt(values.pickupDates[0]).format("YYYY-MM-DD")
        : ""
    );
    searchParams.set(
      "pickupDateTo",
      values.pickupDates[1]
        ? dayjsExt(values.pickupDates[1]).format("YYYY-MM-DD")
        : ""
    );
    searchParams.set("page", "1");
    history.push({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <SearchBlockStyled>
      {!isMobile && (
        <Button
          typestyle="secondary"
          onClick={toggleFilterShown}
          size="small"
          endIcon={
            !isFilterShown ? <ChevronDown size={22} /> : <ChevronUp size={22} />
          }
          sx={{
            border: `1px solid ${palette.black.plus70} !important`,
            height: "40px",
          }}
        >
          <span>Filter</span>
        </Button>
      )}
      {isMobile && (
        <FilterSelectorStyled
          onClick={toggleFilterShown}
          sx={{ height: "40px", padding: "3px 10px !important" }}
        >
          •••
        </FilterSelectorStyled>
      )}

      {isFilterShown && (
        <SearchFilterBlockStyled>
          <Paper
            elevation={3}
            sx={{
              padding: "23px",
              width: "270px",
              [theme.breakpoints.down("tablet")]: {
                width: "calc(100vw - 40px)",
              },
            }}
          >
            {type !== "archived quote" && (
              <Select
                label="Status"
                onChange={(e) =>
                  setValues({ ...values, status: e.target.value })
                }
                value={values.status}
                items={acceptedStatuses}
              />
            )}
            <Select
              label="Dispatch Location"
              onChange={(e) =>
                setValues({ ...values, locationId: e.target.value })
              }
              value={values.locationId}
              items={[
                { name: "All Dispatch Locations", value: "ALL" },
                ...dispatchLocations.map(({ name, id }) => ({
                  name,
                  value: id,
                })),
              ]}
            />
            <DateRangePicker
              label="Pickup dates"
              onChange={(value) => setValues({ ...values, pickupDates: value })}
              value={values.pickupDates}
            />
            <FlexContainer fullWidth columnGap={1} pt={2}>
              <Button
                fullWidth
                typestyle="secondary"
                onClick={() => setIsFilterShown(false)}
              >
                Close
              </Button>
              <Button
                fullWidth
                typestyle="primary"
                onClick={submitFilterValues}
              >
                Apply
              </Button>
            </FlexContainer>
          </Paper>
        </SearchFilterBlockStyled>
      )}
    </SearchBlockStyled>
  );
};
