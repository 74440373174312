import React, { FC } from "react";

import busImage from "@busie/ui-kit/assets/icons/bus-large.svg";
import { Button, NoContentBlock } from "@busie/ui-kit";

const VehiclesNoContent: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <NoContentBlock
      icon={
        <img
          src={busImage}
          alt="Vehicle"
          style={{
            marginLeft: "-40px",
          }}
        />
      }
      controls={<Button href="/vehicles/my-vehicles"> My vehicles </Button>}
    >
      You don’t have any vehicle in your fleet yet.
      <br />
      Add your first starship 👇
    </NoContentBlock>
  );
};

export default VehiclesNoContent;
