import React from "react";

import { Button, DrawerDetailsBlock } from "@busie/ui-kit";

import { AdditionalActionsStyled } from "./styled";
import { createBooking, updateQuoteStatus } from "@busie/api";
import { useAmplitude } from "@busie/core";
import { StatusUpdateConfirmation } from "../StatusBlock/QuoteStatusBlock/StatusUpdateConfirmation";
import { Quote, QuoteAndBookingStatus, Trip } from "@busie/utils";
import { notificationStore } from "@busie/features";
import { getTripType } from "~/QuotesAndBookingsPage/shared/lib";

interface Props {
  quotesAuthToken: string;
  trip: Trip;
  quote: Quote;
  onUpdate: () => void;
  onClose: () => void;
  onPrint: () => void;
}

const statusesToDecline = ["PENDING", "CONFIRMED"];

export const AdditionalActions: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  quote,
  quotesAuthToken,
  onUpdate,
  onClose,
  onPrint,
}: Props) => {
  const { track } = useAmplitude();
  const [newStatus, setNewStatus] = React.useState<QuoteAndBookingStatus>();
  const [showStatusChangePopup, setShowStatusChangePopup] =
    React.useState(false);

  if (![...statusesToDecline].includes(quote._status)) return <div />;

  const getEventMetadata = () => {
    const { _destinationLocation: start } = trip._legs[0];
    const { _startLocation: end } = trip._legs[trip._legs.length - 1];

    return {
      numberOfPassengers: trip._passengers,
      quoteId: quote._id,
      tripId: trip._id,
      price: quote._totalPrice,
      isRoundTrip: getTripType(start, end) === "round_trip",
      numberOfLegs: trip._legs.length,
    };
  };

  const handleUpdate = async (
    status: QuoteAndBookingStatus | undefined,
    reason?: string
  ) => {
    if (!status) return;

    try {
      await updateQuoteStatus(quotesAuthToken, quote._id, status, reason);
      notificationStore.setNotification({
        type: "success",
        header: "Success",
        message: "Quote status updated successfully",
      });
    } catch (e) {
      notificationStore.setErrorNotification(
        "Could not update quote status",
        (e as Error).message || "An unknown error occurred"
      );
    }

    switch (status) {
      case QuoteAndBookingStatus.ACCEPTED:
        track("quote accepted", getEventMetadata());
        // TODO: this should be removed when bookings are created asynchronously
        await createBooking(quotesAuthToken, quote._id);
        break;
      case QuoteAndBookingStatus.CONFIRMED:
        track("quote confirmation sent", getEventMetadata());
        break;
      case QuoteAndBookingStatus.DECLINED:
        track("quote declined", getEventMetadata());
        break;
    }

    await onUpdate();
    setShowStatusChangePopup(false);
  };

  const showStatusChangeConfirmation = async (
    status: QuoteAndBookingStatus
  ) => {
    setNewStatus(status);
    setShowStatusChangePopup(true);
  };

  return (
    <DrawerDetailsBlock
      collapsible
      name="Additional actions"
      className="no-print"
    >
      <StatusUpdateConfirmation
        oldStatus={quote._status}
        newStatus={newStatus as QuoteAndBookingStatus}
        onConfirm={async (reason) => await handleUpdate(newStatus, reason)}
        onCancel={() => setShowStatusChangePopup(false)}
        open={showStatusChangePopup}
        isNetworkQuote={!!quote._quotesIntegrationId}
      />
      <AdditionalActionsStyled>
        {statusesToDecline.includes(quote._status) && (
          <Button
            typestyle="secondary"
            async
            onClick={() =>
              showStatusChangeConfirmation(QuoteAndBookingStatus.DECLINED)
            }
          >
            Decline
          </Button>
        )}
        <Button typestyle="secondary" async onClick={onPrint}>
          Print
        </Button>
      </AdditionalActionsStyled>
    </DrawerDetailsBlock>
  );
};
