import React from "react";
import {
  useCustomerAuthToken,
  usePathfinderAuthToken,
  useQuotesAndBookingsAuthToken,
  useRatesAuthToken,
  useTripsAuthToken,
} from "@busie/core";
import { history } from "@busie/utils";
import { observer } from "mobx-react-lite";

import { PageHeader } from "./ui";
import { QuoteForm, QuoteFormStore as store, StepName } from "@busie/features";

export const stepList: StepName[] = [
  "Customer",
  "Trip",
  "Route",
  "Departures",
  "Summary",
];

export const AddQuotePage: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const nav = store.pageNavigation;
    const customersAuthToken = useCustomerAuthToken();
    const pathfinderAuthToken = usePathfinderAuthToken();
    const tripsAuthToken = useTripsAuthToken();
    const quotesAuthToken = useQuotesAndBookingsAuthToken();
    const rateServiceAuthToken = useRatesAuthToken();

    const handlerNavBack = () => {
      nav.currentStep === 0
        ? history.push("/quotes-and-bookings/quotes")
        : nav.goPrev();
    };
    return (
      <div className="add-quote-page">
        <PageHeader title="Add quote" onNavBack={handlerNavBack} />
        <QuoteForm
          customersAuthToken={customersAuthToken}
          pathfinderAuthToken={pathfinderAuthToken}
          tripsAuthToken={tripsAuthToken}
          quotesAuthToken={quotesAuthToken}
          rateServiceAuthToken={rateServiceAuthToken}
          stepList={stepList}
        />
      </div>
    );
  });
