import { styled } from "@mui/system";
import Stepper from "@mui/material/Stepper";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import { stepClasses } from "@mui/material/Step";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { Theme } from "@mui/system";
import defaultPalette from "../theme/palette";

interface Props {
  theme?: Theme;
  ownerState?: { completed?: boolean; active?: boolean };
}

export const StepperStyled = styled(Stepper)<Props>((props: Props) => {
  return {
    width: "100%",
    maxWidth: "870px",
    margin: "auto",

    [`${props.theme?.breakpoints.down(550)}`]: {
      [`.${stepClasses.root}`]: {
        display: "none",
        "&.is-active": {
          display: "block",
        },
      },
    },
  };
});

export const StepLabelStyled = styled(StepLabel)<Props>((props) => {
  const palette = props.theme?.palette;
  return {
    [`.${stepLabelClasses.labelContainer} .${stepLabelClasses.alternativeLabel}`]:
      {
        color: palette?.black?.plus10,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "150%",
        marginTop: "8px",
      },
  };
});
export const ColorlibConnector = styled(StepConnector)((props: Props) => {
  const palette = props.theme?.palette;
  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 23,
    },

    [`& .${stepConnectorClasses.line}`]: {
      height: "1px",
      border: 0,
      backgroundColor: palette?.black?.plus60,
      margin: "0 23px",
      [`${props.theme?.breakpoints.down("tablet")}`]: {
        display: "none",
      },
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: palette?.black?.plus40,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: palette?.black?.plus40,
      },
    },
  };
});

export const ColorlibStepIconRoot = styled("div")<Props>(
  ({ theme, ownerState }: Props) => {
    const palette = theme?.palette;
    return {
      backgroundColor: palette?.black?.plus80,
      width: "48px",
      height: "48px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...(ownerState?.active && {
        backgroundColor:
          palette?.primary?.main &&
          palette?.primary?.main !== defaultPalette.primary.main
            ? palette?.primary?.main
            : palette?.red?.main,
      }),
      ...(ownerState?.completed && {
        backgroundColor: palette?.black?.plus10,
      }),
    };
  }
);
