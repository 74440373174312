import React from "react";
import { FlexContainer, H2, Button, Modal } from "@busie/ui-kit";
import ReservationFormPopupStyled, {
  PopupHeader,
  ReservationFormFooterStyled,
} from "../styled/ReservationFormPopup.styled";
import { Vehicle } from "@busie/utils";
import AvailableVehicleTypesList from "./AvailableVehicleTypesList";
import ReservationFormTabs from "./ReservationFormTabs";
import ReservationForm from "~/vehicles/Vehicles/components/ReservationForm";
import store from "~/vehicles/store";
import { observer } from "mobx-react";
import { DayjsExt } from "@busie/utils";

interface Props {
  date: DayjsExt;
  left: string;
  top: string;
  onClose: () => void;
  availableVehicles: Vehicle[];
  isMobile: boolean;
}

const ReservationFormPopup: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ date, left, top, onClose, availableVehicles, isMobile }) => {
    const [formStep, setFormStep] = React.useState<0 | 1>(0);
    const [selectedTypes, setSelectedTypes] = React.useState<{
      [key: string]: number;
    }>({});

    const handleOnSubmit = async () => {
      try {
        await store.createReservation();
        await store.fetchReservations();
        onClose();
      } catch (e) {
        //pass
      }
    };

    const onPopupClose = () => {
      store.resetReservationForm();
      onClose();
    };

    return (
      <Modal isOpen={true} onClose={onPopupClose}>
        <ReservationFormPopupStyled
          isMobile={isMobile}
          left={left}
          top={top}
          onClick={(e) => e.stopPropagation()}
        >
          <PopupHeader>
            <FlexContainer justify="space-between" sx={{ padding: "16px" }}>
              <H2>Vehicle reservation</H2>
            </FlexContainer>
            <ReservationFormTabs step={formStep} />
          </PopupHeader>
          {formStep ? (
            <ReservationForm
              vehicleTypes={store.reservationPopup.vehicleTypes}
              vehicles={store.reservationPopup.vehicles}
            />
          ) : (
            <AvailableVehicleTypesList
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              availableVehicles={availableVehicles}
            />
          )}
          <ReservationFormFooterStyled justify="flex-end" columnGap={2}>
            <Button
              typestyle="secondary"
              onClick={() => {
                if (formStep === 0) {
                  onPopupClose();
                } else {
                  setFormStep(0);
                }
              }}
            >
              {formStep === 0 ? "Cancel" : "Back"}
            </Button>

            {formStep === 0 ? (
              <Button
                typestyle="primary"
                disabled={
                  !Object.keys(selectedTypes).reduce(
                    (acc, type) => selectedTypes[type] + acc,
                    0
                  )
                }
                onClick={() => {
                  store.setReservationFormDataFromCalendar(
                    Object.keys(selectedTypes).map((typeId) => ({
                      type: store.vehicleTypes.find(
                        (type) => type.id === typeId
                      )?.type,
                      quantity: selectedTypes[typeId],
                    })),
                    date
                  );
                  setFormStep(1);
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                typestyle="primary"
                onClick={handleOnSubmit}
                disabled={!store.isReservationFormValid}
              >
                Reserve
              </Button>
            )}
          </ReservationFormFooterStyled>
        </ReservationFormPopupStyled>
      </Modal>
    );
  }
);

export default ReservationFormPopup;
