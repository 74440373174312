import {
  Button,
  ChevronDown,
  ChevronUp,
  DateRangePicker,
  FilterSelectorStyled,
  FlexContainer,
  Select,
  palette,
} from "@busie/ui-kit";
import { Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ContractStatus, ListContractsQuery } from "~/contracts/entity";

import { DEFAULT_FILTER, STATUS_FILTER_OPTIONS } from "../../model";
// TODO: is there a better way?
import { Cell as ContractStatusCell } from "../ContractStatusCell";

type FilterValues = Omit<ListContractsQuery, "search">;

interface Props {
  isMobile: boolean;
  isTablet: boolean;
  defaultFilter: FilterValues;
  onChange: (value: FilterValues) => void;
}

export const Filter: React.FC<Props> = ({
  isMobile,
  isTablet,
  defaultFilter,
  onChange,
}) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [filter, setFilter] = useState<FilterValues>(defaultFilter);

  const onFilterClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchor((anchor) => (anchor ? null : e.currentTarget));

  const onApplyFilter = () => {
    onChange(filter);
    onCloseFilter();
  };

  const onCloseFilter = () => setAnchor(null);

  useEffect(() => {
    setFilter(defaultFilter);
  }, [defaultFilter]);

  return (
    <>
      {!isMobile ? (
        <Button
          typestyle="secondary"
          size="small"
          sx={{
            width: "100px",
            justifyContent: "space-between",
            border: `1px solid ${palette.black.plus70} !important`,
          }}
          onClick={onFilterClick}
          endIcon={anchor ? <ChevronUp size={22} /> : <ChevronDown size={22} />}
        >
          <span>Filter</span>
        </Button>
      ) : (
        <FilterSelectorStyled
          onClick={(e) =>
            onFilterClick(
              e as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>
            )
          }
          sx={{ height: "40px", padding: "3px 10px !important" }}
        >
          •••
        </FilterSelectorStyled>
      )}

      <Popover
        id="filter"
        open={!!anchor}
        anchorEl={anchor}
        onClose={onCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: "8px", height: "100%" }}
      >
        <FlexContainer direction="column" w="350px" p={3} rowGap={2}>
          <Select
            label="Contract Status"
            items={STATUS_FILTER_OPTIONS}
            onChange={(e) =>
              setFilter((curr) => ({ ...curr, status: e.target.value }))
            }
            value={filter.status}
            renderValue={(selected) => (
              <FlexContainer
                justify="flex-start"
                direction="row"
                wrap="wrap"
                columnGap={1}
              >
                {(selected as ContractStatus[]).map((status) => (
                  <ContractStatusCell
                    key={status}
                    value={status}
                    small={isTablet}
                  />
                ))}
              </FlexContainer>
            )}
            multiple
            fullWidth
            hideErrorSpace
          />
          <DateRangePicker
            label="Created Date Range"
            value={filter.createdDate || [null, null]}
            onChange={(createdDate) =>
              setFilter((filter) => ({ ...filter, createdDate }))
            }
          />

          <DateRangePicker
            label="Start Date Range"
            value={filter.startDate || [null, null]}
            onChange={(startDate) =>
              setFilter((filter) => ({ ...filter, startDate }))
            }
          />

          <DateRangePicker
            label="End Date Range"
            value={filter.endDate || [null, null]}
            onChange={(endDate) =>
              setFilter((filter) => ({ ...filter, endDate }))
            }
          />

          <DateRangePicker
            label="Renewal Date Range"
            value={filter.endDate || [null, null]}
            onChange={(renewalDate) =>
              setFilter((filter) => ({ ...filter, renewalDate }))
            }
          />

          <FlexContainer
            direction="row"
            columnGap={2}
            align="center"
            justify="flex-end"
            fullWidth
          >
            <Button typestyle="secondary" onClick={onCloseFilter}>
              Close
            </Button>

            <Button typestyle="primary" onClick={onApplyFilter}>
              Apply
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Popover>
    </>
  );
};
