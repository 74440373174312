import { FlexContainer, H2 } from "@busie/ui-kit";
import React from "react";
import { Subtitle } from "../Subtitle";

interface Props {
  markups: [React.ReactNode, React.ReactNode][];
}

export const Markups: React.FC<Props> = ({ markups }) => {
  return (
    <FlexContainer
      rowGap={4}
      px={2}
      direction="column"
      align="flex-start"
      fullWidth
    >
      <Subtitle>Surcharges & Markups</Subtitle>

      {markups.map(([title, input], index) => (
        <FlexContainer
          key={`markup-${index}`}
          direction="row"
          align="center"
          justify="space-between"
          fullWidth
        >
          <FlexContainer align="center" columnGap={1} fullWidth>
            {title}
          </FlexContainer>

          {input}
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};
