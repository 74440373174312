import React from "react";

import {
  MenuItem,
  Menu as BusieMenu,
  IconButton,
  MoreIcon,
  palette,
} from "@busie/ui-kit";

interface Props {
  onAddTrip: () => void;
  onEdit: () => void;
}

export const Menu: React.FC<React.PropsWithChildren<Props>> = ({
  onAddTrip,
  onEdit,
}) => {
  const items: MenuItem[] = [
    {
      content: "Edit",
      action: onEdit,
    },
    {
      content: "Add Trip",
      action: onAddTrip,
    },
  ];

  return (
    <BusieMenu
      right="0%"
      items={items}
      toggler={
        <IconButton
          highlightOnHover={false}
          icon={<MoreIcon color={palette.black.plus100} />}
        />
      }
    />
  );
};
