import React from "react";
import { FlexContainer } from "../FlexContainer";
import { Avatar } from "@mui/material";
import { H2, H4, Text } from "../typography";
import { dayjsExt } from "@busie/utils";

interface Props {
  text: string;
  imgSrc: string;
  name: string;
  createdAt: Date | string | number;
  updatedAt?: Date | string | number;
}

export const Comment: React.FC<Props> = ({
  text,
  imgSrc,
  name,
  createdAt,
  updatedAt,
}) => {
  return (
    <FlexContainer direction="row" columnGap={2} fullWidth bordered>
      <Avatar alt={name} src={imgSrc} />
      <FlexContainer direction="column" rowGap={2} fullWidth>
        <FlexContainer direction="column" rowGap={0} fullWidth>
          <H2>{name}</H2>
          <FlexContainer direction="row" columnGap={2}>
            <H4>{dayjsExt(createdAt).format("L LT")}</H4>
            {updatedAt && (
              <H4>Updated: {dayjsExt(updatedAt).format("L LT")}</H4>
            )}
          </FlexContainer>
        </FlexContainer>
        <Text>{text}</Text>
      </FlexContainer>
    </FlexContainer>
  );
};
