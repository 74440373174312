import { makeAutoObservable } from "mobx";
import { Ingredient } from "@busie/utils";
import {
  updateIngredientName,
  updateIngredientType,
  updateIngredientValue,
  createIngredient,
} from "@busie/api";
import { initialIngredient } from "./initialState";
import { centsToDollars } from "@busie/utils";

export class IngredientEdit {
  isModalOpen = false;
  isEdit = false;
  ingredient: Ingredient = initialIngredient;

  constructor() {
    makeAutoObservable(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.openCreateModal = this.openCreateModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateIngredientValue = this.updateIngredientValue.bind(this);
  }

  get valueInDollars(): number {
    return centsToDollars(Number(this.ingredient.value));
  }

  get isSubmitDisabled(): boolean {
    const isDataFilled = this.ingredient?.name && this.ingredient?.type;
    return !isDataFilled;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  setInitialState(): void {
    this.ingredient = initialIngredient;
    this.isEdit = false;
  }

  openEditModal(ingredient: Ingredient): void {
    this.setInitialState();
    this.isModalOpen = true;
    this.isEdit = true;
    this.ingredient = ingredient;
  }

  openCreateModal(recipeId: string): void {
    this.setInitialState();
    this.isModalOpen = true;
    this.ingredient.recipeId = recipeId;
  }

  updateIngredientValue(value: string, type: "name" | "value" | "type"): void {
    this.ingredient = { ...this.ingredient, [type]: value };
  }

  async patchIngredient(
    authToken: string,
    ingredient: Ingredient = this.ingredient
  ): Promise<Ingredient> {
    await Promise.all([
      updateIngredientName(authToken, ingredient.name, ingredient.id),
      updateIngredientValue(
        authToken,
        ingredient.value as number,
        ingredient.id
      ),
      updateIngredientType(authToken, ingredient.type, ingredient.id),
    ]);
    return ingredient;
  }

  async submitData(
    authToken: string,
    ingredient: Ingredient
  ): Promise<Ingredient> {
    let response: Ingredient;
    if (this.isEdit) {
      response = await this.patchIngredient(authToken, ingredient);
      this.ingredient = response;
    } else {
      response = await createIngredient(authToken, {
        recipeId: ingredient.recipeId,
        type: ingredient.type,
        value: ingredient.value,
        name: ingredient.name,
      });
    }
    this.closeModal();
    return response;
  }
}
