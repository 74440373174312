import { useMutation } from "react-query";

import { createGroup, editCustomer } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";

import { customersModel } from "~/CustomersPage/entity";

interface UpdateCustomerParams {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  organization: string;
  shouldCreateGroup: boolean;
}

export const useUpdateCustomer = () => {
  const customersAuthToken = useCustomerAuthToken();

  const mutation = useMutation(
    async ({
      id,
      name,
      email,
      phoneNumber,
      organization,
      shouldCreateGroup,
    }: UpdateCustomerParams) => {
      let orgId = organization;
      if (shouldCreateGroup) {
        const group = await createGroup(customersAuthToken, {
          name: organization,
        });
        orgId = group.id;
      }
      await editCustomer(customersAuthToken, id, {
        name,
        email,
        phoneNumber,
        organization: orgId,
      });
      customersModel.invalidateCustomersQueries();
    }
  );

  return mutation;
};
