import { useQuery, UseQueryResult } from "react-query";

import { fetchAllGroups, fetchCustomer, fetchGroup } from "@busie/api";
import { useCustomerAuthToken, queryClient } from "@busie/core";
import { Group } from "@busie/utils";

export const CUSTOMERS_QUERY_KEY = "customers";
const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

export const invalidateCustomersQueries = () => {
  queryClient.invalidateQueries(CUSTOMERS_QUERY_KEY);
};

interface CustomersOrganizationsParams {
  query?: string;
}

export const useCustomersOrganizations = ({
  query,
}: CustomersOrganizationsParams = {}) => {
  const customersAuthToken = useCustomerAuthToken();

  const result = useQuery(
    [CUSTOMERS_QUERY_KEY, "organizations", query ? query : undefined],
    () =>
      fetchAllGroups(customersAuthToken, {
        search: query ? query : undefined,
      }),
    {
      enabled: !!customersAuthToken,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useCustomersOrganization = (id: string) => {
  const { data: organizations = [] } =
    useCustomersOrganizations() as UseQueryResult<Group[]>;
  return organizations.find((organization) => organization.id === id);
};

export const useCustomer = (id: string) => {
  const customersAuthToken = useCustomerAuthToken();

  const result = useQuery(
    [CUSTOMERS_QUERY_KEY, "customer", id],
    () => fetchCustomer(customersAuthToken, id),
    {
      enabled: !!customersAuthToken && !!id,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useFetchGroup = (id: string) => {
  const authToken = useCustomerAuthToken();

  return useQuery(
    [CUSTOMERS_QUERY_KEY, "group", id],
    async () => fetchGroup(authToken, id),
    {
      enabled: !!authToken && !!id,
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};
