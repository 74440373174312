import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useAuth0 } from "@auth0/auth0-react";
import { createQuote, createQuoteAsGuest, CreateQuoteData } from "@busie/api";
import { history } from "@busie/utils";

import store from "../../store";

import CustomerInformationBlock from "./CustomerInformationBlock";
import TripInformationBlock from "./TripInformationBlock";
import RouteInformationBlock from "./RouteInformationBlock";
import DeparturesBlock from "./DeparturesBlock";
import MapRouteBlock from "./MapRouteBlock";
import OnSubmitMessageBlock from "./OnSubmitMessageBlock";
import FormContainer from "../FormContainer";

import Button from "../Button";
import { useAmplitude } from "@busie/core";
import { TripCreatedBlock } from "./TripCreatedBlock";

interface Props {
  setCurrentStep: (step: number) => void;
  setNextStep: (step: number) => void;
  quotesAuthToken: string;
  rateServiceAuthToken: string;
  guestAuthToken?: string;
  embedded?: boolean;
  embeddedFormOrgId?: string;
  onClose?: () => void;
}

const SummaryBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  (props: Props) => {
    const { track } = useAmplitude();
    const [showOnSubmitMessage, setShowOnSubmitMessage] =
      React.useState<boolean>(false);

    useEffect(() => {
      const legs = store.departuresForm.trip?._legs || [];
      if (!((props.rateServiceAuthToken || props.guestAuthToken) && legs))
        return;

      const updateLegPriceMap = async () => {
        for (const leg of legs) {
          await store.departuresForm.updateLegPriceMap(
            leg,
            {
              rateService: props.rateServiceAuthToken,
              guest: props.guestAuthToken,
            },
            props.embedded
          );
        }
      };
      updateLegPriceMap();

      return () => {
        store.departuresForm.clearLegPriceMap();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.rateServiceAuthToken, props.guestAuthToken]);

    const submitInappForm = async () => {
      store.resetAll();
      history.push("/");
    };

    const submitEmbeddedForm = async () => setShowOnSubmitMessage(true);

    const handlerOnSubmit = async () =>
      props.embedded ? await submitEmbeddedForm() : await submitInappForm();

    if (showOnSubmitMessage) {
      return <OnSubmitMessageBlock />;
    }

    return (
      <>
        <TripCreatedBlock
          price={store.quote?._totalPrice ?? 0}
          onClose={props.onClose}
          showPrice={
            !props.embedded || !store.departuresForm.trip?.hideInstantQuote
          }
          onClickDone={handlerOnSubmit}
          embedded={props.embedded}
        />

        <CustomerInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4);
            props.setCurrentStep(0);
            store.customerForm.setEditMode(true);
            track("trip planning customer detail edit navigation");
          }}
          customerForm={store.customerForm}
        />

        <TripInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4);
            props.setCurrentStep(1);
            store.tripForm.setEditMode(true);
            track("trip planning trip edit navigation");
          }}
          tripForm={store.tripForm}
        />

        <RouteInformationBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4);
            props.setCurrentStep(2);
            track("trip planning route edit navigation");
          }}
          departuresForm={store.departuresForm}
          isEmbedded={props.embedded}
          price={
            store.quote?._totalPrice ?? store.departuresForm.estimatedPrice
          }
        />

        <MapRouteBlock {...props} departuresForm={store.departuresForm} />

        <DeparturesBlock
          {...props}
          onEdit={() => {
            props.setNextStep(4);
            props.setCurrentStep(3);
            track("trip planning departures edit navigation");
          }}
          departuresForm={store.departuresForm}
        />

        {!store.quoteId && (
          <Button fullWidth async onClick={handlerOnSubmit}>
            {props.embedded ? "Next" : "Done"}
          </Button>
        )}
      </>
    );
  }
);

export default SummaryBlock;
