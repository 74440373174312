import { initialFormData, initialIngredient } from "./initialState";
import { makeAutoObservable } from "mobx";
import { isIngredientsFilled, isRateTypeFilled } from "@busie/api";
import {
  CookbookType,
  Ingredient,
  IngredientList,
  IngredientListType,
  Recipe,
} from "@busie/utils";
import { convertRecipeToFormData } from "./helpers";

export class createRecipeModal {
  isModalWindowOpened = false;

  formData = initialFormData;
  constructor() {
    makeAutoObservable(this);
    this.toggleCreateRecipeModal = this.toggleCreateRecipeModal.bind(this);
    this.setRuleType = this.setRuleType.bind(this);
    this.setRateTypeValue = this.setRateTypeValue.bind(this);
  }
  setInitialState(): void {
    this.formData = initialFormData;
  }
  toggleCreateRecipeModal(): void {
    this.isModalWindowOpened = !this.isModalWindowOpened;
  }
  async openModalWithData(authToken: string, recipe: Recipe): Promise<void> {
    this.formData = await convertRecipeToFormData(authToken, recipe);
    this.isModalWindowOpened = true;
  }
  updateRecipeCookbookColor(color: string): void {
    this.formData.cookbookColor = color;
  }
  updateRecipeName(name: string): void {
    this.formData.name = name;
  }
  setRuleType(type: string): void {
    this.formData.ruleType = type;
  }
  setRateTypeValue(rateTypeValue: Date | string | number): void {
    this.formData.ruleTypeValue[this.formData.ruleType] = rateTypeValue;
  }
  toggleIngredientGroup(listName: IngredientListType, index: number): void {
    this.formData.ingredientList[listName][index]["checked"] =
      !this.formData.ingredientList[listName][index]["checked"];
  }
  addIngredient(listName: IngredientListType, index: number): void {
    this.formData.ingredientList[listName][index]["ingredients"].push(
      initialIngredient as Ingredient
    );
  }
  removeIngredient(
    listName: IngredientListType,
    listIndex: number,
    index: number
  ): void {
    // sholud it be array.filter()?
    this.formData.ingredientList[listName][listIndex]["ingredients"].splice(
      index,
      1
    );
  }
  updateIngredientValue<K extends keyof Ingredient, V extends Ingredient[K]>(
    listName: IngredientListType,
    listIndex: number,
    index: number,
    fieldName: K,
    value: V
  ): void {
    const ingredientList = this.formData.ingredientList as IngredientList;
    ingredientList[listName][listIndex].ingredients[index][fieldName] = value;
  }

  isRateTypeAndIngredientsFilled(): boolean {
    return (
      isRateTypeFilled(this.formData) && isIngredientsFilled(this.formData)
    );
  }
  setCookbookAssignmentType(value: CookbookType): void {
    this.formData.cookbookAssignmentType = value;
  }
  setCookbookId(id: string): void {
    this.formData.cookbookId = id;
  }
  clearCookbookId(): void {
    this.formData.cookbookId = null;
  }
}
