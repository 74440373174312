import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SettingsContainer } from "~/settings/shared/ui";
import {
  NEW_HOLIDAY_FORM_DATA,
  schema,
  useCreateHoliday,
  useDeleteHoliday,
  useFetchHolidays,
  useFetchQnbSettings,
  useUpdateHoliday,
  useUpdateSettings,
} from "./model";
import { CompanyHoliday, User, dayjsExt } from "@busie/utils";
import { useAuth0 } from "@auth0/auth0-react";
import { getDefaultValues, onError } from "./lib";
import { FormData } from "./interface";
import { notificationStore } from "@busie/features";
import {
  AddIcon,
  Button,
  Divider,
  FlexContainer,
  FormContainer,
  H1,
  H3,
  Loading,
  NumberInput,
  TextInput,
  ToggleSwitch,
} from "@busie/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DeleteHolidayModal,
  Holiday,
  HolidayForm,
  HolidayFormData,
  SettingContainer,
} from "./ui";
import { useAmplitude } from "@busie/core";

export const Page: React.FC = () => {
  const { user } = useAuth0<User>();

  const { track } = useAmplitude();

  const {
    data: settings,
    isLoading,
    isError,
  } = useFetchQnbSettings(user?.org_id ?? "", onError);

  const { data: holidays = [], isLoading: isHolidaysLoading } =
    useFetchHolidays(user?.org_id ?? "", onError);

  const { mutateAsync: createHoliday } = useCreateHoliday(user?.org_id ?? "");
  const { mutateAsync: updateHoliday } = useUpdateHoliday(user?.org_id ?? "");
  const { mutateAsync: deleteHoliday } = useDeleteHoliday();

  const {
    updateEmail,
    updateDefaultSignerName,
    updateLastMinuteSurge,
    updateFuelSurcharge,
    updateTaxSurcharge,
    updateEnablePlatformPricingMarkup,
    updateEnableAssigneeContractSigner,
  } = useUpdateSettings(settings?.id ?? "");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(settings),
  });

  useEffect(() => {
    reset(getDefaultValues(settings));
    return () => reset();
  }, [reset, settings]);

  const [deleteHolidayId, setDeleteHolidayId] = useState<string>();
  const [editHolidayId, setEditHolidayId] = useState<string>();
  const [isHolidayFormOpen, setIsHolidayFormOpen] = useState(false);
  const [holidayFormState, setHolidayFormState] = useState<"create" | "edit">(
    "create"
  );

  const [holidayFormData, setHolidayFormData] = useState<HolidayFormData>(
    NEW_HOLIDAY_FORM_DATA
  );

  const onClickAddHoliday = () => {
    setHolidayFormState("create");
    setHolidayFormData(NEW_HOLIDAY_FORM_DATA);
    setIsHolidayFormOpen(true);
  };

  const onClickEditHoliday = (holiday: CompanyHoliday) => {
    setHolidayFormState("edit");
    setEditHolidayId(holiday.id);
    setHolidayFormData({
      name: holiday.name,
      holidayRangeFrom: dayjsExt(holiday.holidayRangeFrom).format("MM/DD/YYYY"),
      holidayRangeTo: dayjsExt(holiday.holidayRangeTo).format("MM/DD/YYYY"),
      repeat: holiday.repeat,
    });
    setIsHolidayFormOpen(true);
  };

  const onSubmit = async (data: FormData) => {
    if (settings?.email !== data.email)
      await updateEmail(data.email, {
        onSuccess: () => {
          notificationStore.setSuccessNotification(
            "Success!",
            "The default email was updated."
          );
          track("default email setting updated", { email: data.email });
        },
        onError,
      });
    if (settings?.defaultSignerName !== data.defaultSignerName)
      await updateDefaultSignerName(data.defaultSignerName, {
        onSuccess: () => {
          notificationStore.setSuccessNotification(
            "Success!",
            "The default signer name was updated."
          );
          track("default signer name setting updated", {
            signerName: data.defaultSignerName,
          });
        },
        onError,
      });
    if (settings?.lastMinuteSurge !== data.lastMinuteSurge)
      await updateLastMinuteSurge(data.lastMinuteSurge, {
        onSuccess: () => {
          notificationStore.setSuccessNotification(
            "Success!",
            "The last minute surge was updated."
          );
          track("last minute surge setting updated", {
            lastMinuteSurge: data.lastMinuteSurge,
          });
        },
        onError,
      });
    if (settings?.fuelSurcharge !== data.fuelSurcharge)
      await updateFuelSurcharge(data.fuelSurcharge, {
        onSuccess: () => {
          notificationStore.setSuccessNotification(
            "Success!",
            "The fuel surcharge was updated."
          );
          track("fuel surcharge setting updated", {
            fuelSurcharge: data.fuelSurcharge,
          });
        },
        onError,
      });
    if (settings?.taxSurcharge !== data.taxSurcharge)
      await updateTaxSurcharge(data.taxSurcharge, {
        onSuccess: () => {
          notificationStore.setSuccessNotification(
            "Success!",
            "The tax surcharge was updated."
          );
          track("tax surcharge setting updated", {
            taxSurcharge: data.taxSurcharge,
          });
        },
        onError,
      });
    if (
      settings?.enablePlatformPricingMarkup !== data.enablePlatformPricingMarkup
    )
      await updateEnablePlatformPricingMarkup(
        data.enablePlatformPricingMarkup,
        {
          onSuccess: () => {
            notificationStore.setSuccessNotification(
              "Success!",
              "The platform pricing markup was updated."
            );
            track("platform pricing markup setting updated", {
              enablePlatformPricingMarkup: data.enablePlatformPricingMarkup,
            });
          },
          onError,
        }
      );
    if (
      settings?.enableAssigneeContractSigner !==
      data.enableAssigneeContractSigner
    )
      await updateEnableAssigneeContractSigner(
        data.enableAssigneeContractSigner,
        {
          onSuccess: () => {
            notificationStore.setSuccessNotification(
              "Success!",
              "The assignee contract signer was updated."
            );
            track("assignee contract signer setting updated", {
              enableAssigneeContractSigner: data.enableAssigneeContractSigner,
            });
          },
          onError,
        }
      );
  };

  const onCreateHoliday = async (data: HolidayFormData) => {
    await createHoliday(data, {
      onSuccess: (result: CompanyHoliday) => {
        notificationStore.setSuccessNotification(
          "Success!",
          "Your Company Holiday was created."
        );
        track("holiday created", { holiday: result });
      },
      onError,
    });
  };

  const onUpdateHoliday = async (data: HolidayFormData) => {
    await updateHoliday(
      { ...data, id: editHolidayId ?? "" },
      {
        onSuccess: (result: CompanyHoliday) => {
          notificationStore.setSuccessNotification(
            "Success!",
            "Your Company Holiday was updated."
          );
          track("holiday updated", { holiday: result });
        },
        onError,
      }
    );
  };

  const onDeleteHoliday = async (id: string) => {
    await deleteHoliday(id, {
      onSuccess: () => {
        notificationStore.setSuccessNotification(
          "Success!",
          "Your Company Holiday was deleted."
        );
        track("holiday deleted", { holidayId: id });
        setDeleteHolidayId(undefined);
      },
      onError,
    });
  };

  const onSubmitHolidayForm = async (data: HolidayFormData) => {
    if (holidayFormState === "create") await onCreateHoliday(data);
    else await onUpdateHoliday(data);

    onCloseHolidayForm();
  };

  const onCloseHolidayForm = () => {
    setIsHolidayFormOpen(false);
    setEditHolidayId(undefined);
  };

  return (
    <SettingsContainer value="Quotes and Bookings">
      <FormContainer title="Quotes and Bookings Settings" fullWidth>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <H1>Could not load QnB settings</H1>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FlexContainer direction="column" rowGap={2} fullWidth>
              <SettingContainer
                title="Enable Platform Pricing Markup"
                text="By enabling this setting, the Platform Pricing Markup will be
                  automatically applied to every quote. This includes the
                  Platform Fee, Payment Processing Fees, and Network fees (where
                  applicable)."
                callout="This 'marks up' the platform fees to the customer. You can
                always toggle this for an individual quote in the Edit Quote
                Form."
              >
                <Controller
                  control={control}
                  name="enablePlatformPricingMarkup"
                  render={({ field }) => (
                    <FlexContainer
                      direction="row"
                      fullWidth
                      align="center"
                      columnGap={1}
                    >
                      <ToggleSwitch
                        checked={field.value}
                        onChange={(ev) => field.onChange(ev.target.checked)}
                      />
                      <H3>
                        Platform Pricing Markup:{" "}
                        <em>
                          <b>{field.value ? "Enabled" : "Disabled"}</b>
                        </em>
                      </H3>
                    </FlexContainer>
                  )}
                />
              </SettingContainer>
              <Divider />
              <SettingContainer
                title="Last Minute Quote Surcharge"
                text="% to increase total price by for quotes that come in “last
                  minute”. This would happen when the request is for a Quote
                  less than 72 hours away."
              >
                <Controller
                  control={control}
                  name="lastMinuteSurge"
                  render={({ field }) => (
                    <NumberInput
                      {...field}
                      required
                      suffix="%"
                      sx={{ width: "50%" }}
                      errorMessage={errors.lastMinuteSurge?.message}
                    />
                  )}
                />
              </SettingContainer>
              <Divider />
              <SettingContainer
                title="Fuel Surcharge"
                text="% to increase total price for quotes based on the current
                  price of fuel"
              >
                <Controller
                  control={control}
                  name="fuelSurcharge"
                  render={({ field }) => (
                    <NumberInput
                      {...field}
                      required
                      suffix="%"
                      sx={{ width: "50%" }}
                      errorMessage={errors.fuelSurcharge?.message}
                    />
                  )}
                />
              </SettingContainer>
              <Divider />
              <SettingContainer
                title="Tax Surcharge"
                text="% to increase total price for quotes based on taxes"
              >
                <Controller
                  control={control}
                  name="taxSurcharge"
                  render={({ field }) => (
                    <NumberInput
                      {...field}
                      required
                      suffix="%"
                      sx={{ width: "50%" }}
                      errorMessage={errors.taxSurcharge?.message}
                    />
                  )}
                />
              </SettingContainer>
              <Divider />
              <SettingContainer
                title="Enable Quote Assignee E-Contract Signer"
                text="By enabling this setting, the Quote Assignee will be
                  automatically be sent the E-Contract to sign on behalf of the
                  organization."
              >
                <Controller
                  name="enableAssigneeContractSigner"
                  control={control}
                  render={({ field }) => (
                    <FlexContainer
                      direction="row"
                      fullWidth
                      align="center"
                      columnGap={1}
                    >
                      <ToggleSwitch
                        checked={field.value}
                        onChange={(ev) => field.onChange(ev.target.checked)}
                      />
                      <H3>
                        Automatic Assignee E-Contract Signer:{" "}
                        <em>
                          <b>{field.value ? "Enabled" : "Disabled"}</b>
                        </em>
                      </H3>
                    </FlexContainer>
                  )}
                />
              </SettingContainer>
              <Divider />
              <SettingContainer
                title="Default Email Address"
                text="The default email for e-contracts when someone from your
                  organization needs to sign. Also the default email for
                  responses to automated emails sent on your behalf, such as the
                  Quote Confirmation Email."
              >
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      required
                      fullWidth
                      errorMessage={errors.email?.message}
                    />
                  )}
                />
              </SettingContainer>
              <Divider />
              <SettingContainer
                title="Default Organization Signer Name"
                text="The default signer name for e-contracts when signing on behalf
                  of your organization."
              >
                <Controller
                  control={control}
                  name="defaultSignerName"
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      fullWidth
                      required
                      errorMessage={errors.defaultSignerName?.message}
                    />
                  )}
                />
              </SettingContainer>
              <Divider />
              <SettingContainer
                title="Company Holidays"
                text="A list of dates that the company expects all employees to be
                  out of the office. This setting only has an effect on quote
                  integrations where the associated organization is a
                  destination"
              >
                <FlexContainer fullWidth direction="column" rowGap={2}>
                  {isHolidaysLoading ? (
                    <Loading />
                  ) : (
                    holidays.map((holiday) => (
                      <Holiday
                        holiday={holiday}
                        key={holiday.id}
                        onDelete={() => setDeleteHolidayId(holiday.id)}
                        onEdit={() => onClickEditHoliday(holiday)}
                      />
                    ))
                  )}

                  <Button
                    typestyle="primary"
                    type="button"
                    startIcon={<AddIcon />}
                    onClick={onClickAddHoliday}
                  >
                    Add new Holiday
                  </Button>
                </FlexContainer>
              </SettingContainer>
            </FlexContainer>

            <div className="form-submit-control">
              <Button
                typestyle="primary"
                type="submit"
                disabled={!isValid || !isDirty || isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </FormContainer>

      {!!deleteHolidayId && (
        <DeleteHolidayModal
          isOpen={!!deleteHolidayId}
          onClose={() => setDeleteHolidayId(undefined)}
          onConfirm={() => onDeleteHoliday(deleteHolidayId)}
        />
      )}

      {isHolidayFormOpen && (
        <HolidayForm
          isOpen={isHolidayFormOpen}
          holiday={holidayFormData}
          type={holidayFormState}
          onSubmit={onSubmitHolidayForm}
          onClose={() => setIsHolidayFormOpen(false)}
        />
      )}
    </SettingsContainer>
  );
};
