import React from "react";

import {
  Divider,
  FlexContainer,
  H2,
  H3,
  ScrollableFlexContainer,
} from "@busie/ui-kit";
import { timeFormatter, millisecondsToHours, dayjsExt } from "@busie/utils";
import { Experience } from "@busie/api";

import { Modal } from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  trip: Experience;
  isOpen: boolean;
  onClose: () => void;
}

export const ServiceTimeBreakdown: React.FC<Props> = ({
  trip,
  isOpen,
  onClose,
}) => {
  const dailyServiceBreakdown = trip.DAILY_SERVICE_BREAKDOWN;

  if (!dailyServiceBreakdown)
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <H2>Could not determine Service Breakdown</H2>
      </Modal>
    );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <FlexContainer direction="column" rowGap={1}>
        <H2>Daily Service Breakdown</H2>
        <Divider />
        <ScrollableFlexContainer
          direction="column"
          rowGap={1}
          fullWidth
          pb={2}
          px={1}
        >
          {Object.entries(dailyServiceBreakdown).map(
            ([date, { MILLISECONDS }]) => (
              <FlexContainer
                direction="row"
                align="center"
                fullWidth
                key={date}
                columnGap={2}
              >
                <FlexContainer fullWidth>
                  <H3 weight={600}>{dayjsExt(date).format("dddd, L")}</H3>
                </FlexContainer>
                <FlexContainer fullWidth>
                  {timeFormatter(millisecondsToHours(MILLISECONDS), "hours")}
                </FlexContainer>
              </FlexContainer>
            )
          )}
        </ScrollableFlexContainer>
      </FlexContainer>
    </Modal>
  );
};
