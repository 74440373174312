import * as yup from "yup";

import { INVOICE_VOID_REASON_OPTIONS } from "./constants";
import { InvoiceVoidReason } from "@busie/utils";

export const schema = yup.object({
  voidReason: yup
    .string()
    .oneOf<InvoiceVoidReason>(
      INVOICE_VOID_REASON_OPTIONS,
      `Void reason must be one of: ${INVOICE_VOID_REASON_OPTIONS.join(", ")}`
    )
    .required("Please select a reason"),
  voidReasonDetail: yup.string().when("voidReason", {
    is: "OTHER",
    then: yup.string().required("Please enter a reason"),
  }),
});
