import { CommonTable, FlexContainer, H2, palette } from "@busie/ui-kit";
import { InvoiceStatusText } from "@busie/ui-kit";
import { getTableData } from "./model";
import { COLUMNS } from "./model";
import { useListInvoices } from "../../model";
import { ListInvoicesParams } from "@busie/api";
import { Invoice } from "@busie/utils";

interface Props {
  invoices: Invoice[];
}

export const List: React.FC<React.PropsWithChildren<Props>> = ({
  invoices,
}) => {
  return (
    (invoices.length && (
      <FlexContainer direction="column">
        <H2 weight={600} color={palette.black.plus20}>
          Invoice History
        </H2>

        <CommonTable
          columnNames={COLUMNS}
          data={getTableData(invoices, (color, status) => (
            <InvoiceStatusText color={color} value={status} />
          ))}
        />
      </FlexContainer>
    )) ||
    null
  );
};
