import { palette } from "@busie/ui-kit";

export const getIconColor = (days: number | null) => {
  if (!days && days !== 0) return palette.black.main;

  if (days > 10) {
    return palette.green.main;
  } else if (days > 5) {
    return palette.supernova.main;
  } else {
    return palette.red.main;
  }
};
