import * as yup from "yup";
import { dayjsExt } from "@busie/utils";

export const schema = yup.object({
  departures: yup
    .array()
    .of(
      yup
        .mixed()
        .test("is-dayjs", "Invalid date", (value) => dayjsExt(value).isValid())
    )
    .required("Departures are required"),
});
