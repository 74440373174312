import React from "react";
import { Vehicle } from "@busie/utils";

import VehicleBlock from "./components/VehicleBlock";

import CollapsedViewStyled from "./styled/CollapsedViewStyled";
import { observer } from "mobx-react";
import VehiclesStore from "~/vehicles/store/vehicles.store";

const CollapsedView: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    let vehiclesFilter: (v: Vehicle) => boolean = () => true;
    if (VehiclesStore.reservationsFilter.vehicleTypeId) {
      vehiclesFilter = (v) =>
        v.vehicleType.id === VehiclesStore.reservationsFilter.vehicleTypeId;
    }
    return (
      <CollapsedViewStyled>
        {VehiclesStore.vehicles.filter(vehiclesFilter).map((vehicle, index) => {
          return <VehicleBlock key={index} vehicle={vehicle} />;
        })}
      </CollapsedViewStyled>
    );
  }
);

export default CollapsedView;
