import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { datetimeFormatter as dateFormat } from "@busie/utils";
import { useOnClickOutside } from "./helpers";

import InputLabel from "../InputLabel";
import DatePickerStyled from "./styled/DatePickerStyled";
import DatePickerInputStyled from "./styled/DatePickerInputStyled";

import { CalendarIcon } from "../icons/calendar";

import { createHtmlAttrId } from "../helpers";
import { ErrorMessage } from "../typography";
import { useMediaQuery } from "@mui/material";
import theme from "../theme";
import { DateView } from "@mui/x-date-pickers";

interface Props {
  label?: string;
  maxWidth?: number;
  fixedWidth?: string;
  required?: boolean;
  placeholder?: string;
  value: Date;
  disablePast?: boolean;
  minDate?: Date;
  disabled?: boolean;
  onChange: (newValue: Date) => void;
  views?: DateView[];
  format?: string;
  shouldDisableDate?: (day: Date) => boolean;
  className?: string;
  errorMessage?: string;
  hideErrorSpace?: boolean;
}

const getInputTextValue = (newValue: Date, format?: string) => {
  return dateFormat(newValue, format || "date");
};

const DatePicker: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const [inputValue, setInputValue] = React.useState<string>(
    props.value ? getInputTextValue(props.value, props.format) : ""
  );
  const [isCalendarShown, setCalendarState] = React.useState<boolean>(false);

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  useOnClickOutside(ref, () => setCalendarState(false));

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  React.useEffect(() => {
    setInputValue(
      props.value ? getInputTextValue(props.value, props.format) : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const handleOnChange = (newValue: Date | null) => {
    if (newValue) {
      props.onChange(newValue);
      setInputValue(getInputTextValue(newValue, props.format));
    }
    setCalendarState(false);
  };

  const showCalendar = () => {
    setCalendarState(true);
  };
  const id = `date-picker-${createHtmlAttrId(props.label)}`;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerStyled
        className={props.className}
        withPadding={!!props.label && props.label.length > 0}
        isMobile={isMobile}
        fixedWidth={props.fixedWidth}
      >
        {props.label && (
          <InputLabel htmlFor={id} required={props.required}>
            {props.label}
          </InputLabel>
        )}
        <DatePickerInputStyled maxwidth={props.maxWidth}>
          <input
            id={id}
            value={inputValue}
            onFocus={showCalendar}
            onClick={() => !props.disabled && showCalendar()}
            placeholder={props.placeholder}
            readOnly={true}
            disabled={props.disabled}
          />
          <div
            className="icon"
            onClick={() => !props.disabled && showCalendar()}
          >
            <CalendarIcon color="#6E6E6E" />
          </div>
        </DatePickerInputStyled>
        {isCalendarShown && (
          <div className="calendar-toolbox" ref={ref}>
            <DateCalendar
              views={props.views}
              disablePast={props.disablePast}
              shouldDisableDate={props.shouldDisableDate}
              minDate={props.minDate}
              value={props.value}
              onChange={handleOnChange}
            />
          </div>
        )}
        {props.errorMessage && props.errorMessage.length > 0 && (
          <ErrorMessage>{props.errorMessage}</ErrorMessage>
        )}
      </DatePickerStyled>
    </LocalizationProvider>
  );
};

export default DatePicker;
