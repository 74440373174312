import { useQuery } from "react-query";
import { getInvoicePreviewLink } from "@busie/api";

export const useInvoicePreview = (authToken: string, invoiceId?: string) => {
  return useQuery(
    ["invoicePreview", invoiceId],
    async () => await getInvoicePreviewLink(authToken, invoiceId || ""),
    {
      enabled: !!authToken && !!invoiceId,
    }
  );
};
