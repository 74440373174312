import { Callout, FlexContainer, H2, InputText, palette } from "@busie/ui-kit";
import React from "react";

interface Props {
  title: string;
  text: string;
  children: React.ReactNode;
  callout?: string;
}

export const Container: React.FC<Props> = ({
  title,
  text,
  callout,
  children,
}) => (
  <FlexContainer direction="column" rowGap={0} w="50%">
    <H2 margin="20px 0 0 0">{title}</H2>

    <InputText color={palette.black.plus30}>{text}</InputText>

    {!!callout && (
      <Callout>
        <span role="img" aria-label="info">
          🤓 ☝️
        </span>{" "}
        <em>{callout}</em>
      </Callout>
    )}

    {children}
  </FlexContainer>
);
