import React from "react";
import { Modal } from "@busie/ui-kit";
import { Driver } from "@busie/utils";

import { DriverForm, DriverFormData } from "../../entity";
import { useCreateDriver } from "./model";
import { metadataReducer } from "~/drivers/model";

interface Props {
  isOpen: boolean;
  onDriverCreated: (driver: Driver) => void;
  onClose: () => void;
}

export const CreateDriver: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  onDriverCreated,
}) => {
  const { mutateAsync: createDriver } = useCreateDriver();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <DriverForm
        onCancel={onClose}
        onSubmit={async (formData: DriverFormData) => {
          const metadata = formData.metadata.length
            ? formData.metadata.reduce(metadataReducer, {})
            : {};

          const driver = await createDriver({
            ...formData,
            metadata,
            licenses: [],
            preferredNotificationChannels: [],
            payRates: [],
          });

          onDriverCreated(driver);
        }}
      />
    </Modal>
  );
};
