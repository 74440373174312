import React, { ReactNode } from "react";

import MenuStyled from "./styled/MenuStyled";
import MenuItem from "./styled/MenuItem";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";

export type MenuItem = {
  content: ReactNode | string;
  action?: () => void;
  selected?: boolean;
} | null;

export type MenuProps = React.PropsWithChildren<{
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  toggler: React.ReactNode;
  items: MenuItem[];
}>;

const Menu: React.FC<React.PropsWithChildren<MenuProps>> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MenuStyled
      top={props.top}
      bottom={props.bottom}
      left={props.left}
      right={props.right}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <div className="menu-parent">
          <div onClick={handleToggle}>{props.toggler}</div>
          {open && (
            <Paper className="menu-block">
              <MenuList autoFocusItem={open}>
                {props.items.map((item, index) => {
                  return (
                    item && (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          item.action && item.action();
                          handleClose();
                        }}
                        selected={item.selected}
                      >
                        {item.content}
                      </MenuItem>
                    )
                  );
                })}
              </MenuList>
            </Paper>
          )}
        </div>
      </ClickAwayListener>
    </MenuStyled>
  );
};

export default Menu;
