import { Reservation, ReservationTypes } from "@busie/utils";
import { palette, SettingsIcon } from "@busie/ui-kit";
import { Box } from "@mui/system";
import {
  differenceInMinutes,
  endOfDay,
  endOfHour,
  format,
  isAfter,
  isBefore,
  startOfDay,
  startOfHour,
} from "date-fns";
import { InfoIcon, PathIcon } from "@busie/ui-kit";
import React from "react";
import { ReservationDrawerContext } from "~/vehicles/Vehicles/Vehicles";
import {
  getReservationStatusBadgeContents,
  getReservationTypeBadgeContents,
} from "../helpers";
import { ReservationBarText } from "../styled/CalendarWeekView.styled";

interface Props {
  date: Date;
  reservation: Reservation;
  isDayView: boolean;
}

const ReservationBar: React.FC<React.PropsWithChildren<Props>> = ({
  reservation,
  date,
  isDayView,
}) => {
  const startAt = new Date(reservation.startAt);
  const endAt = new Date(reservation.endAt);
  const status = getReservationStatusBadgeContents(reservation.status);
  const isCharter = reservation.type === ReservationTypes.CHARTER;
  const reservationLength = differenceInMinutes(endAt, startAt);
  const setOpenedReservation = React.useContext(ReservationDrawerContext);
  const getStart = isDayView ? startOfHour : startOfDay;
  const getEnd = isDayView ? endOfHour : endOfDay;
  const minutesInPeriod = isDayView ? 60 : 1440;

  let type: 0 | 1 | 2 | 3 = 0;
  let left = "-1px";
  let right = "0%";
  let borderRadius = "0";
  if (!isBefore(startAt, getStart(date)) && !isBefore(endAt, getEnd(date))) {
    left = `${
      (differenceInMinutes(startAt, getStart(date)) * 100) / minutesInPeriod
    }%`;
    type = 0;
    borderRadius = "4px 0 0 4px";
  }
  if (isBefore(endAt, getEnd(date)) && !isAfter(startAt, getStart(date))) {
    right = `${
      (differenceInMinutes(getEnd(date), endAt) * 100) / minutesInPeriod
    }%`;
    type = 1;
    borderRadius = "0 4px 4px 0";
  }
  if (isBefore(endAt, getEnd(date)) && isAfter(startAt, getStart(date))) {
    left = `${
      (differenceInMinutes(startAt, getStart(date)) * 100) / minutesInPeriod
    }%`;
    right = `${
      (differenceInMinutes(getEnd(date), endAt) * 100) / minutesInPeriod
    }%`;
    type = 2;
    borderRadius = "4px";
  }
  if (!isBefore(endAt, getEnd(date)) && isBefore(startAt, getStart(date))) {
    left = "-1px";
    type = 3;
  }
  let text = (
    <ReservationBarText>
      {type === 0 && isCharter && status.text}
    </ReservationBarText>
  );

  let bgColor = status.background;
  if (reservation.type === ReservationTypes.MAINTENANCE) {
    bgColor = palette.supernova.main;
    text = (
      <>
        <SettingsIcon size={12} />
        <ReservationBarText>Maintenance</ReservationBarText>
      </>
    );
  } else if (reservation.type === ReservationTypes.LINE_RUN) {
    bgColor = palette.green.lineRun;
    text = (
      <>
        <PathIcon size={16} />
        <ReservationBarText>Line Run</ReservationBarText>
      </>
    );
  } else if (reservation.type === ReservationTypes.OTHER) {
    bgColor = palette.black.plus70;
    text = (
      <>
        <InfoIcon size={16} />
        <ReservationBarText>Other</ReservationBarText>
      </>
    );
  }

  return (
    <Box
      sx={{
        height: "32px",
        backgroundColor: bgColor,
        position: "absolute",
        top: "8px",
        left,
        right,
        width:
          type === 3
            ? "calc(100% + 4px)"
            : `calc(calc(100% + 2px) - ${left} - ${right})`,
        borderRadius,
        borderLeft:
          (type === 0 || type === 2) && isCharter
            ? `${status.color} 2px solid`
            : "none",
        overflowY: "visible",
        cursor: "pointer",
      }}
      title={`${
        getReservationStatusBadgeContents(reservation.status).text
      } ${getReservationTypeBadgeContents(
        reservation.type
      ).text.toLowerCase()} reservation`}
      onMouseOver={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.stopPropagation();
        setOpenedReservation && setOpenedReservation(reservation);
      }}
    >
      {(type === 0 || type === 2) &&
        ((isDayView && format(date, "H") !== "23" && reservationLength > 160) ||
          (!isDayView &&
            format(date, "i") !== "6" &&
            reservationLength > 1000)) && (
          <Box
            sx={{
              position: "absolute",
              width: "max-content",
              top: "10%",
              left: "5px",
              zIndex: 2,
              height: "24px",
              display: "flex",
              alignItems: "center",
              columnGap: 1,
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
            }}
          >
            {text}
          </Box>
          // eslint-disable-next-line indent
        )}
    </Box>
  );
};

export default ReservationBar;
