import React from "react";

export const LogoutIcon: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.85999 12H20"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8641 19.981L6.69605 20C5.50105 20.006 4.52905 19.048 4.52905 17.865V6.135C4.52905 4.956 5.49405 4 6.68605 4H11.0001"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 16L20 12L16 8"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
