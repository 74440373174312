import React, { useState } from "react";

import { Customer, Group } from "@busie/utils";
import { notificationStore } from "@busie/features";
import { useAmplitude } from "@busie/core";

import { CustomersOrganizationsList } from "~/CustomersPage/entity";
import {
  CreateCustomer,
  DeleteCustomer,
  UpdateCustomer,
  UpdateGroup,
} from "~/CustomersPage/features";
import { getEventMetadata, getSuccessMessage } from "./lib";

interface Props {
  isLoading: boolean;
  areCustomersNotFound: boolean;
  organizations: Group[];
  onCustomerClick: (customer: Customer) => void;
  onGroupClick: (group: Group) => void;
}

export const Table: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  areCustomersNotFound,
  organizations,
  onCustomerClick,
  onGroupClick,
}) => {
  const [isCreateCustomerVisible, setIsCreateCustomerVisible] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | undefined
  >();
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >();
  const [isUpdateCustomerVisible, setIsUpdateCustomerVisible] = useState(false);
  const [isDeleteCustomerVisible, setIsDeleteCustomerVisible] = useState(false);
  const [isUpdateGroupVisible, setIsUpdateGroupVisible] = useState(false);

  const { track } = useAmplitude();

  const showCreateCustomer = (organizationId?: string) => {
    setSelectedOrganizationId(organizationId);
    setIsCreateCustomerVisible(true);
  };

  const hideCreateCustomer = () => {
    setIsCreateCustomerVisible(false);
    setSelectedOrganizationId(undefined);
  };

  const showUpdateCustomer = (customer: Customer) => {
    setSelectedCustomer(customer);
    setIsUpdateCustomerVisible(true);
  };

  const hideUpdateCustomer = () => {
    setIsUpdateCustomerVisible(false);
    setSelectedCustomer(undefined);
  };

  const showDeleteCustomer = (customer: Customer) => {
    setSelectedCustomer(customer);
    setIsDeleteCustomerVisible(true);
  };

  const hideDeleteCustomer = () => {
    setIsDeleteCustomerVisible(false);
    setSelectedCustomer(undefined);
  };

  const showUpdateGroup = (groupId: string) => {
    setSelectedOrganizationId(groupId);
    setIsUpdateGroupVisible(true);
  };

  const hideUpdateGroup = () => {
    setIsUpdateGroupVisible(false);
    setSelectedOrganizationId(undefined);
  };

  const setErrorNotification = (e: unknown) =>
    notificationStore.setNotificationFromError(e);

  const handleGroupUpdated = (group: Group) => {
    track("group updated", getEventMetadata(group));
    notificationStore.setSuccessNotification(...getSuccessMessage(group));
    hideUpdateGroup();
  };

  return (
    <CustomersOrganizationsList
      isLoading={isLoading}
      areCustomersNotFound={areCustomersNotFound}
      organizations={organizations}
      onCustomerAdd={showCreateCustomer}
      onCustomerEdit={showUpdateCustomer}
      onCustomerDelete={showDeleteCustomer}
      onGroupEdit={showUpdateGroup}
      onCustomerClick={onCustomerClick}
      onGroupClick={onGroupClick}
      createCustomerView={
        <CreateCustomer
          visible={isCreateCustomerVisible}
          hide={hideCreateCustomer}
          organizationId={selectedOrganizationId}
        />
      }
      updateCustomerView={
        <UpdateCustomer
          customer={selectedCustomer as Customer}
          visible={isUpdateCustomerVisible}
          hide={hideUpdateCustomer}
        />
      }
      deleteCustomerView={
        <DeleteCustomer
          customerId={selectedCustomer?.id || ""}
          visible={isDeleteCustomerVisible}
          hide={hideDeleteCustomer}
        />
      }
      updateGroupView={
        <UpdateGroup
          groupId={selectedOrganizationId || ""}
          isOpen={isUpdateGroupVisible}
          onSuccess={handleGroupUpdated}
          onError={setErrorNotification}
          onClose={hideUpdateGroup}
        />
      }
    />
  );
};
