import * as yup from "yup";

export const schema = yup.object({
  amount: yup.number().required("Amount is required"),
  contactEmail: yup
    .string()
    .required("Email is required")
    .email("Invalid email"),
  bookingIds: yup.array().of(yup.string()).length(1).required(),
});
