import { styled } from "@mui/system";

const AmenitiesBlockStyled = styled("div")(() => {
  return {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "8px",
    svg: {
      height: "32px",
      width: "32px",
    },
  };
});

export default AmenitiesBlockStyled;
