import { useMutation } from "react-query";

import { useCustomerAuthToken } from "@busie/core";

import { customersModel } from "~/CustomersPage/entity";
import { UpdateGroupPayload, updateGroup } from "@busie/api";

export const useUpdateGroup = (id: string) => {
  const authToken = useCustomerAuthToken();

  return useMutation(async (data: UpdateGroupPayload) => {
    const group = await updateGroup(authToken, id, data);

    customersModel.invalidateCustomersQueries();

    return group;
  });
};
