import React from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react-lite";

import { theme, H4, Text, Button, FlexContainer } from "@busie/ui-kit";
import { ReservationTypes, Vehicle } from "@busie/utils";
import { VehicleIcon, mapVehicleTypeToName } from "@busie/core";
import { filterByDateRange } from "~/vehicles/store/helpers";
import { useMediaQuery } from "@mui/material";

import store from "~/vehicles/store";

import ColumnsStyled from "../styled/ColumnsStyled";

import ReservationTypeBadge from "~/vehicles/Vehicles/components/ReservationTypeBadge";

const VehicleBlockStyled = styled(FlexContainer)(() => {
  return {
    marginTop: "16px",
  };
});

interface Props {
  vehicle: Vehicle;
}

const VehicleBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ vehicle }: Props) => {
    const badgesContent: { [key in ReservationTypes]?: number } = {};
    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
    const Font = isMobile ? H4 : Text;

    vehicle.reservations?.filter(filterByDateRange).forEach((item) => {
      Object.assign(badgesContent, {
        [item.type]: (badgesContent[item.type] || 0) + 1,
      });
    });
    return (
      <VehicleBlockStyled bordered noPadding direction="column">
        <FlexContainer fullWidth align="center" justify="space-between">
          <ColumnsStyled isMobile={isMobile}>
            <FlexContainer align="center" columnGap={2}>
              <VehicleIcon type={vehicle.vehicleType.type} size={32} />
              {!isMobile && (
                <Text>{mapVehicleTypeToName(vehicle.vehicleType.type)}</Text>
              )}
            </FlexContainer>
            <FlexContainer align="center" columnGap={2}>
              <Font>{vehicle.licensePlate}</Font>
            </FlexContainer>

            <FlexContainer align="center" columnGap={5} wrap="wrap">
              {Object.keys(badgesContent).map((type, index) => {
                return (
                  <ReservationTypeBadge
                    type={Number(type)}
                    key={index}
                    quantity={badgesContent[Number(type) as ReservationTypes]}
                    isMobile={isMobile}
                  />
                );
              })}
            </FlexContainer>
            <FlexContainer align="center" justify="center">
              <Button
                size={isMobile ? "mobile" : "small"}
                onClick={() =>
                  store.openReservationPopupWithVehicles([vehicle])
                }
              >
                Reserve
              </Button>
            </FlexContainer>
          </ColumnsStyled>
        </FlexContainer>
      </VehicleBlockStyled>
    );
  }
);

export default VehicleBlock;
