import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  DatePicker,
  Divider,
  FlexContainer,
  NoteText,
  palette,
  Text,
  FormContainer,
} from "@busie/ui-kit";
import { Booking, Quote, Trip } from "@busie/utils";
import { styled } from "@mui/system";

import { CreateInvoicedCustomerParams, CreateInvoiceParams } from "@busie/api";
import { DEFAULT_INVOICED_CUSTOMER_PARAMS, schema } from "./model";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  quote: Quote;
  booking: Booking;
  trip: Trip;
  revenueShareConfigId: string;
  customer?: CreateInvoicedCustomerParams;
  onSubmit: (values: CreateInvoiceParams) => void;
  onCancel: () => void;
  invoicedCustomerSlot?: React.ReactNode;
}

const DateSuggestion = styled("span")(() => {
  return {
    cursor: "pointer",
    fontWeight: 100,
    color: palette.black.plus10,
    zIndex: 2,
    whiteSpace: "nowrap",
    boxShadow: "-20px 0px 10px -1px rgba(255,255,255,.8)",
    fontSize: "10px",
  };
});

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
  booking,
  trip,
  revenueShareConfigId,
  customer,
  onCancel,
  onSubmit,
  invoicedCustomerSlot,
}) => {
  const today = new Date();
  const minDueDate = new Date(new Date().setDate(today.getDate() + 1));
  const [dueDate, setDueDate] = useState(new Date());

  const createInvoicedCustomerParams: CreateInvoicedCustomerParams = useMemo(
    () => (!!customer && customer) || DEFAULT_INVOICED_CUSTOMER_PARAMS,
    [customer]
  );

  const {
    handleSubmit,
    setValue,
    reset,
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<CreateInvoiceParams>({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      revenueShareConfigId,
      amount: booking._balance,
      bookingId: booking._id,
      customer: createInvoicedCustomerParams,
      organizationId: quote._organizationId,
    },
  });

  useEffect(() => {
    setValue("customer", createInvoicedCustomerParams, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [setValue, createInvoicedCustomerParams]);

  useEffect(() => reset(), [reset]);

  const getDaysUntilDue = (value: Date) =>
    Math.ceil((value.getTime() - new Date().getTime()) / (1000 * 3600 * 24));

  const handleDateSelect = (date: Date) => {
    setDueDate(date);
    setValue("daysUntilDue", getDaysUntilDue(date), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <FormContainer title="Create Invoice">
      <Text>Are you sure you want to create an invoice for this booking?</Text>

      <NoteText>
        You will not be able to send credit card payment links unless this
        invoice is voided.
      </NoteText>

      <NoteText color={palette.red.main}>
        This action cannot be undone.
      </NoteText>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        {invoicedCustomerSlot ? (
          <>
            <Text>
              Select an existing customer in this group as the payment contact
              or create a new one.
            </Text>
            {invoicedCustomerSlot}
            <NoteText color={palette.red.main}>
              {errors.customer ? "select a customer from the list" : ""}
            </NoteText>
          </>
        ) : null}

        <FlexContainer
          align="flex-start"
          columnGap={2}
          direction="column"
          fullWidth
        >
          <Text>Select a due date for this invoice.</Text>
          <DatePicker
            {...register("daysUntilDue")}
            label="Due Date"
            minDate={minDueDate}
            placeholder="Select due date"
            onChange={handleDateSelect}
            value={dueDate}
            fixedWidth="100%"
          />

          <FlexContainer pt={2} justify="flex-start" columnGap={2} wrap="wrap">
            <NoteText color={palette.black.main}>Date Suggestions: </NoteText>
            <DateSuggestion
              onClick={() =>
                handleDateSelect(
                  new Date(new Date().setDate(today.getDate() + 7))
                )
              }
            >
              7 days from Today
            </DateSuggestion>
            <DateSuggestion
              onClick={() =>
                handleDateSelect(
                  new Date(new Date().setDate(today.getDate() + 30))
                )
              }
            >
              30 days from Today
            </DateSuggestion>
            <DateSuggestion
              onClick={() =>
                handleDateSelect(
                  new Date(
                    new Date().setDate(
                      new Date(trip._startDate as string).getDate() + 30
                    )
                  )
                )
              }
            >
              30 days from Trip Start Date
            </DateSuggestion>
            <DateSuggestion
              onClick={() =>
                handleDateSelect(
                  new Date(
                    new Date().setDate(
                      new Date(trip._endDate as string).getDate() + 30
                    )
                  )
                )
              }
            >
              30 days from Trip End Date
            </DateSuggestion>
          </FlexContainer>
          <NoteText color={palette.red.main}>
            {errors.daysUntilDue ? errors.daysUntilDue.message : ""}
          </NoteText>
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Create Invoice
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};

export default Form;
