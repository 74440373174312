import React from "react";

import { Subtitle } from "../typography";

import { FormContainerStyled } from "./styled";

interface Props {
  title: string;
  children: React.ReactNode;
  controls?: React.ReactNode;
  fullWidth?: boolean;
  bordered?: boolean;
}

export const FormContainer: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const { children, title, controls, fullWidth, bordered = true } = props;
  return (
    // @ts-expect-error TS expects theme prop
    <FormContainerStyled
      className={fullWidth ? "fullWidth" : ""}
      bordered={bordered}
    >
      <div className="title-block">
        <Subtitle className="title">{title}</Subtitle>
        <div className="controls">{controls}</div>
      </div>
      <div className="container-content">{children}</div>
    </FormContainerStyled>
  );
};
