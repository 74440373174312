import React from "react";

import { Modal } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";

import { CustomerForm } from "~/CustomersPage/entity";

import { useUpdateCustomer } from "./model";
import { Customer } from "@busie/utils";

interface Props {
  visible: boolean;
  hide(): void;
  customer: Customer;
}

export const UpdateCustomer: React.FC<React.PropsWithChildren<Props>> = ({
  visible,
  hide,
  customer,
}) => {
  const { mutateAsync } = useUpdateCustomer();

  return (
    <Modal isOpen={visible}>
      <CustomerForm
        customer={customer}
        onSubmit={async ({ name, email, phoneNumber, organization }) => {
          try {
            await mutateAsync({
              id: customer.id,
              name,
              email,
              phoneNumber,
              organization: organization.value as string,
              shouldCreateGroup: !organization.value,
            });
            hide();
          } catch (e) {
            notificationStore.setNotificationFromError(e);
          }
        }}
        onCancel={hide}
      />
    </Modal>
  );
};
