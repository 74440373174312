import React from "react";

import { InvoiceStatusText } from "@busie/ui-kit";
import { Invoice, InvoiceStatus } from "@busie/utils";
import { getStatusColor } from "./get-status-color";

interface Props {
  status: InvoiceStatus;
}

export const StatusText: React.FC<React.PropsWithChildren<Props>> = ({
  status,
}) => {
  const statusColor = getStatusColor(status);

  return <InvoiceStatusText color={statusColor} value={status} />;
};
