import React, { useState } from "react";

import {
  ruleTypeOptions,
  weekdayItems,
  monthItems,
} from "~/rates/RateMenuPage/store/constants";

import { Select, SelectChangeEvent } from "@busie/ui-kit";
import { TextInput, TextInputEvent } from "@busie/ui-kit";
import { NumberInput, NumberInputEvent } from "@busie/ui-kit";
import { DatePicker } from "@busie/ui-kit";

interface Props {
  typeValue: string;
  value: string;
  onTypeChange: (type: string) => void;
  onValueChange: (value: string | Date | number) => void;
  defaultValue?: string | Date;
}

const getDefaultValue = (type: string): Date | number | string => {
  if (type === "DATE") {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  if (type === "DAY_OF_WEEK") {
    return weekdayItems[0].value;
  }

  if (type === "MONTH") {
    return monthItems[0].value;
  }

  if (
    [
      "TOTAL_MILES",
      "DAILY_MILES",
      "LEG_MILES",
      "TOTAL_TIME",
      "DAILY_TIME",
      "LEG_TIME",
    ].includes(type)
  ) {
    return 0;
  }

  return "Standard";
};

const ERROR_MESSAGE = "This field is required";

const RateRuleForm: React.FC<React.PropsWithChildren<Props>> = ({
  typeValue,
  value,
  onTypeChange,
  onValueChange,
}) => {
  const [isValueDirty, setIsValueDirty] = useState(false);

  const setRuleTypeValue = (event: SelectChangeEvent) => {
    onTypeChange(event.target.value);
    onValueChange(getDefaultValue(event.target.value));
    setIsValueDirty(false);
  };

  const setRateTypeValue = (rateTypeValue: string | Date) => {
    if (rateTypeValue) setIsValueDirty(true);
    onValueChange(rateTypeValue);
  };

  const getValueFieldLabel = (value: string) => {
    return ruleTypeOptions.find((item) => item.value === value)?.name;
  };

  const valueComponentProps = {
    value,
    label: getValueFieldLabel(typeValue),
    required: true,
    placeholder: "",
    onChange: (e: SelectChangeEvent | TextInputEvent | NumberInputEvent) =>
      setRateTypeValue(e.target.value),
  };
  const getValueComponent = (type: string): React.ReactNode => {
    const errorMessage = isValueDirty && !value ? ERROR_MESSAGE : "";
    switch (type) {
      case "DATE":
        return (
          <DatePicker
            disablePast
            {...valueComponentProps}
            onChange={(value: Date) => {
              value.setHours(0, 0, 0, 0);
              setRateTypeValue(value);
            }}
            value={value as unknown as Date}
          />
        );
      case "TOTAL_MILES":
      case "DAILY_MILES":
      case "LEG_MILES":
        return (
          <NumberInput
            suffix={" miles"}
            errorMessage={errorMessage}
            {...valueComponentProps}
          />
        );
      case "TOTAL_TIME":
      case "DAILY_TIME":
      case "LEG_TIME":
        return (
          <NumberInput
            suffix={" hours"}
            errorMessage={errorMessage}
            {...valueComponentProps}
          />
        );
      case "DAY_OF_WEEK":
        return <Select items={weekdayItems} {...valueComponentProps} />;
      case "MONTH":
        return <Select items={monthItems} {...valueComponentProps} />;
      case "STANDARD":
        return (
          <TextInput
            label={getValueFieldLabel(typeValue)}
            disabled={true}
            value={value}
          />
        );
    }
  };
  const valueComponent: React.ReactNode = getValueComponent(typeValue);

  return (
    <>
      <Select
        value={typeValue}
        label="Rule type"
        required={true}
        items={ruleTypeOptions}
        onChange={setRuleTypeValue}
      />
      {valueComponent}
    </>
  );
};

export default RateRuleForm;
