import { theme } from "@busie/ui-kit";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const NotificationContent = styled(Box)(() => {
  return {
    backgroundColor: "white",
    border: "1px solid #D4D4D4",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    columnGap: "21px",
    padding: theme.spacing(3),
    minWidth: "359px",
    boxShadow: "0px 8px 24px rgba(34, 34, 34, 0.15)",
  };
});

export const NotificationTextWrapper = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "column",
    rowGap: "2px",
  };
});
