import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  Divider,
  FlexContainer,
  palette,
  NoteText,
  Text,
  FormContainer,
  Select,
  capitalize,
} from "@busie/ui-kit";
import { Invoice, InvoicePaymentMethod } from "@busie/utils";
import { PayInvoiceData } from "@busie/api";

import { PAYMENT_METHOD_OPTIONS } from "./constants";
import { schema } from "./schema";

interface Props {
  onSubmit: (payInvoiceData: PayInvoiceData) => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<PayInvoiceData>({
    mode: "onChange",
    defaultValues: {
      paymentMethod: "" as unknown as InvoicePaymentMethod,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Mark Invoice as Paid">
      <Text>Are you sure you want to mark this invoice as paid?</Text>

      <NoteText>
        This will mark both the invoice and the booking as paid in full. You
        will{" "}
        <em>
          <b>not</b>
        </em>{" "}
        be able to void this invoice, send a new one, or send payment links.{" "}
        Your customer will no longer be able to make an online payment.
      </NoteText>

      <br />

      <NoteText color={palette.red.main}>
        This action cannot be undone.
      </NoteText>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer
          align="flex-start"
          direction="column"
          columnGap={2}
          fullWidth
        >
          <Controller
            name="paymentMethod"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label="Payment Method"
                items={PAYMENT_METHOD_OPTIONS.map((option) => ({
                  value: option,
                  name: option === "ACH" ? option : capitalize(option),
                }))}
                errorMessage={errors.paymentMethod?.message}
                fullWidth
              />
            )}
          />
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Mark Invoice Paid
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
