import { useReactToPrint } from "react-to-print";
import React, { useEffect, useState } from "react";

import { ChefQuote, Experience } from "@busie/api";
import { QuoteAndBookingStatus } from "@busie/utils";

import {
  Drawer as BusieDrawer,
  Button,
  DrawerDetailsBlock,
  DrawerHeader,
  FlexContainer,
  H2,
  Loading,
} from "@busie/ui-kit";

import { SendQuote, UpdateQuoteStatus } from "~/QuotesAndBookingsPage/features";
import {
  CustomerContact,
  DrawerOverflowMenu,
  QuoteIntegrationDetails,
  TripList,
} from "~/QuotesAndBookingsPage/entity";
import { BookingRequestedBanner, QuoteInfo, StatusBlock } from "./ui";
import { useFetchQuoteIntegrations } from "~/QuotesAndBookingsPage/model";

interface Props {
  isOpen: boolean;
  quote?: ChefQuote;
  isLoading?: boolean;
  onAddTrip: () => void;
  onEditQuote: () => void;
  onClose: () => void;
  onTripClicked: (trip: Experience) => void;
  onQuoteUpdated: () => void;
}

export const Drawer: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
  isOpen,
  isLoading = false,
  onAddTrip,
  onEditQuote,
  onClose,
  onTripClicked,
  onQuoteUpdated,
}: Props) => {
  const [newStatus, setNewStatus] = React.useState<QuoteAndBookingStatus>();
  const [isUpdateQuoteStatusOpen, setIsUpdateQuoteStatusOpen] = useState(false);
  const [isSendQuoteOpen, setIsSendQuoteOpen] = useState(false);

  const [_, setCounter] = useState(0);

  const componentRef = React.useRef(null);
  const printingRef = React.useRef(false);

  const handlePrint = () => {
    printingRef.current = true;
    setCounter((c) => c + 1);
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Quote Details",
  });

  useEffect(() => {
    if (printingRef.current === true) {
      printingRef.current = false;
      print();
    }
  }, [printingRef, print]);

  const { data: integrations } = useFetchQuoteIntegrations();

  return (
    <BusieDrawer anchor="right" open={isOpen} onClose={onClose}>
      <DrawerHeader onClose={onClose} title="Quote Details">
        <DrawerOverflowMenu onAddTrip={onAddTrip} onEdit={onEditQuote} />
      </DrawerHeader>
      {isLoading ? (
        <Loading />
      ) : (
        (!!quote && (
          <div className="trip-details-wrapper" ref={componentRef}>
            {quote.BOOKING_REQUESTED && <BookingRequestedBanner />}

            <StatusBlock
              quote={quote}
              onClickConfirmQuote={() => {
                setNewStatus(QuoteAndBookingStatus.CONFIRMED);
                setIsUpdateQuoteStatusOpen(true);
              }}
              onClickDeclineQuote={() => {
                setNewStatus(QuoteAndBookingStatus.DECLINED);
                setIsUpdateQuoteStatusOpen(true);
              }}
              onClickAcceptQuote={() => {
                setNewStatus(QuoteAndBookingStatus.ACCEPTED);
                setIsUpdateQuoteStatusOpen(true);
              }}
              onClickSendQuote={() => setIsSendQuoteOpen(true)}
            />

            <QuoteInfo quote={quote} />

            <CustomerContact
              title="Main Contact"
              customer={{
                NAME: quote.MAIN_CONTACT?.NAME || quote.CONTACT_NAME,
                EMAIL: quote.MAIN_CONTACT?.EMAIL || quote.CONTACT_EMAIL,
                AREA_CODE: quote.MAIN_CONTACT?.AREA_CODE || "",
                COUNTRY_CODE: quote.MAIN_CONTACT?.COUNTRY_CODE || "",
                PHONE_NUMBER: quote.MAIN_CONTACT?.PHONE_NUMBER || "",
                GROUP: {
                  NAME: quote.CUSTOMER_GROUP.NAME,
                  TYPE:
                    quote.CUSTOMER_GROUP.GROUP_TYPE ||
                    quote.CUSTOMER_GROUP.TYPE,
                },
              }}
            />

            <TripList quote={quote} onClickTrip={onTripClicked} />

            {quote.QUOTE_INTEGRATION_ID &&
              quote.QUOTE_INTEGRATION &&
              integrations && (
                <QuoteIntegrationDetails
                  sourceId={quote.QUOTE_INTEGRATION.SOURCE_ID as string}
                  destinationId={
                    quote.QUOTE_INTEGRATION.DESTINATION_ID as string
                  }
                  expirationDate={quote.EXPIRATION_DATE as string}
                  integrations={integrations}
                  revenueShareDestinations={
                    quote.QUOTE_INTEGRATION?.REVENUE_SHARE_CONFIG?.DESTINATIONS?.map(
                      (dest) => ({
                        organizationId: dest.ORGANIZATION_ID as string,
                        apportionmentValue: dest.APPORTIONMENT_VALUE as number,
                      })
                    ) || []
                  }
                />
              )}

            <DrawerDetailsBlock
              name="Additional Actions"
              collapsible
              className="no-print"
            >
              <FlexContainer
                fullWidth
                columnGap={2}
                align="center"
                justify="flex-start"
                sx={{ marginBottom: "52px" }}
              >
                <Button typestyle="accent" onClick={handlePrint}>
                  Print
                </Button>

                <Button
                  typestyle="secondary"
                  onClick={() => {
                    setNewStatus(QuoteAndBookingStatus.DECLINED);
                    setIsUpdateQuoteStatusOpen(true);
                  }}
                >
                  Decline Quote
                </Button>
              </FlexContainer>
            </DrawerDetailsBlock>
          </div>
        )) || <H2>No Content...</H2>
      )}

      {newStatus && quote && (
        <UpdateQuoteStatus
          quote={quote}
          newStatus={newStatus}
          isOpen={isUpdateQuoteStatusOpen}
          onClose={() => setIsUpdateQuoteStatusOpen(false)}
          onStatusUpdate={() => {
            setIsUpdateQuoteStatusOpen(false);
            onQuoteUpdated();
          }}
        />
      )}

      {isSendQuoteOpen && quote && (
        <SendQuote
          quote={quote}
          isOpen={isSendQuoteOpen}
          onClose={() => setIsSendQuoteOpen(false)}
          onQuoteSent={() => {
            setIsSendQuoteOpen(false);
            onQuoteUpdated();
          }}
        />
      )}
    </BusieDrawer>
  );
};
