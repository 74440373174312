// TODO: refactor into a queries file instead of using mobx store.
import { makeAutoObservable } from "mobx";

import {
  Customer,
  DispatchLocation,
  QuoteAndBookingStatus,
  Trip,
} from "@busie/utils";
import {
  BookingsResponse,
  BookingWithAttrs,
  fetchBookings,
  fetchCustomersByIds,
  getDispatchLocations,
} from "@busie/api";

import { fetchTrips } from "@busie/api";
import {
  collectBookingsWithTrips,
  getFilterParams,
} from "~/QuotesAndBookingsPage/shared/lib";

import { ARCHIVED_BOOKINGS_STATUSES, ITEMS_PER_PAGE } from "../constants";

type AuthTokens = {
  quotes: string;
  trips: string;
  customers: string;
  dispatchLocations: string;
};

class ArchivedBookingsStore {
  authTokens: AuthTokens = {
    quotes: "",
    trips: "",
    customers: "",
    dispatchLocations: "",
  };
  bookingsResponse: BookingsResponse | null = null;
  customers: Customer[] = [];
  dispatchLocations: DispatchLocation[] = [];
  items: BookingWithAttrs[] = [];
  isFetching = true;
  selectedItems: string[] = [];

  constructor() {
    makeAutoObservable(this);
    this.addSelectedItems = this.addSelectedItems.bind(this);
    this.removeSelectedItems = this.removeSelectedItems.bind(this);
    this.setItems = this.setItems.bind(this);
  }
  public async setItems(page?: number): Promise<void> {
    this.selectedItems = [];
    this.isFetching = true;
    const trips = await fetchTrips(this.authTokens["trips"], {
      // populateLegs: true,
    });
    const tripIdMap = new Map(trips.map((trip) => [trip._id, trip]));

    const filterParams = getFilterParams();

    const bookingsResponse = await fetchBookings(this.authTokens["quotes"], {
      status:
        filterParams.status !== "ALL"
          ? (filterParams.status as QuoteAndBookingStatus)
          : ARCHIVED_BOOKINGS_STATUSES,
      locationId:
        filterParams.locationId === "ALL" ? null : filterParams.locationId,
      page,
      pickupDateFrom: filterParams.pickupDateFrom,
      pickupDateTo: filterParams.pickupDateTo,
      itemsPerPage: ITEMS_PER_PAGE,
      [`${filterParams.searchParam}`]: filterParams.searchValue,
    });
    this.bookingsResponse = bookingsResponse;
    const bookings = bookingsResponse.resultSet;

    const filteredTrips = bookings.reduce((acc, booking) => {
      if (tripIdMap.has(booking._quote.experienceId)) {
        acc.push(tripIdMap.get(booking._quote.experienceId) as Trip);
      }
      return acc;
    }, [] as Trip[]);

    const customerIds = filteredTrips.map((trip) => trip._tripPlannerId);
    const customers = await fetchCustomersByIds(
      this.authTokens["customers"],
      customerIds
    );

    const bookingsWithTrips = await collectBookingsWithTrips(bookings, {
      customers,
      trips: filteredTrips,
    });

    this.dispatchLocations = await getDispatchLocations(
      this.authTokens.dispatchLocations
    );

    this.items = bookingsWithTrips;

    this.isFetching = false;
  }

  public setAuthTokens(authTokens: AuthTokens): void {
    this.authTokens = authTokens;
  }
  public addSelectedItems(items: string[]): void {
    // make array with unique items
    this.selectedItems = [...new Set([...this.selectedItems, ...items])];
  }
  public removeSelectedItems(items: string[]): void {
    const itemSet = new Set(items);
    this.selectedItems = this.selectedItems.filter(
      (item) => !itemSet.has(item)
    );
  }
  public clearSelectedItems(): void {
    this.selectedItems = [];
  }
  public reset(): void {
    this.authTokens = {
      quotes: "",
      trips: "",
      customers: "",
      dispatchLocations: "",
    };
    this.customers = [];
    this.dispatchLocations = [];
    this.items = [];
    this.isFetching = true;
    this.selectedItems = [];
  }
}

export const store = new ArchivedBookingsStore();
