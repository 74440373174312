import { env } from "@busie/utils";
import axios from "axios";
import {
  ChefBooking,
  ChefContract,
  ChefQuote,
  ResultWithTotal,
} from "./interface";

export * from "./interface";

export const fetchChefQuote = async (
  authToken: string,
  quoteId: string
): Promise<ChefQuote> => {
  const { data } = await axios.get<ChefQuote>(
    `${env("BUSIE_CHEF_SERVICE_API_URL")}/chef/quotes/${quoteId}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const listChefQuotes = async (
  authToken: string,
  params: {
    limit: number;
    page: number;
    sort?: string;
    status?: string;
    startDate?: string;
    createdDate?: string;
    dispatchLocations?: string;
    assignee?: string | null | undefined;
    search?: string;
  }
): Promise<ResultWithTotal<ChefQuote>> => {
  const { data } = await axios.get<ResultWithTotal<ChefQuote>>(
    `${env("BUSIE_CHEF_SERVICE_API_URL")}/chef/quotes`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params,
    }
  );

  return data;
};

export const listChefBookings = async (
  authToken: string,
  params: {
    limit: number;
    page: number;
    sort?: string;
    status?: string;
    startDate?: string;
    createdDate?: string;
    dispatchLocations?: string;
    assignee?: string | null | undefined;
    search?: string;
  }
): Promise<ResultWithTotal<ChefBooking>> => {
  const { data } = await axios.get<ResultWithTotal<ChefBooking>>(
    `${env("BUSIE_CHEF_SERVICE_API_URL")}/chef/bookings`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params,
    }
  );

  return data;
};

export const fetchChefBooking = async (
  authToken: string,
  bookingId: string
): Promise<ChefBooking> => {
  const { data } = await axios.get<ChefBooking>(
    `${env("BUSIE_CHEF_SERVICE_API_URL")}/chef/bookings/${bookingId}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const listChefContracts = async (
  authToken: string,
  params: {
    limit: number;
    page: number;
    sort?: string;
    status?: string;
    createdDate?: string;
    startDate?: string;
    endDate?: string;
    renewalDate?: string;
    dispatchLocations?: string;
    assignee?: string;
    search?: string;
  }
): Promise<ResultWithTotal<ChefContract>> => {
  const { data } = await axios.get<ResultWithTotal<ChefContract>>(
    `${env("BUSIE_CHEF_SERVICE_API_URL")}/chef/contracts`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params,
    }
  );

  return data;
};
