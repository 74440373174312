import { dayjsExt } from "@busie/utils";
import { DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  ampm?: boolean;
}

export const Controlled: <T extends FieldValues>(
  props: Props<T>
) => React.ReactElement = ({ control, name, ampm = true }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <DateTimePicker
          {...field}
          value={field.value.toDate()}
          onChange={(value) => field.onChange(value ? dayjsExt(value) : null)}
          label="Departure Date and Time"
          ampm={ampm}
          sx={{ width: "100%" }}
        />
      )}
    />
  );
};
