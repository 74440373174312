import { CreateTripData, Experience, UpdateTripData } from "@busie/api";
import { duplicateTrip } from "~/QuotesAndBookingsPage/entity";

export const splitTrip = (
  trip: Experience,
  splitIndex: number
): [UpdateTripData, CreateTripData] => {
  const createTripCopy = duplicateTrip(trip);
  const updateTripCopy = duplicateTrip(trip);

  return [
    {
      ...updateTripCopy,
      dispatchId: trip.DISPATCH_ID,
      mainContactId: trip.MAIN_CONTACT.ID,
      legs: updateTripCopy.legs.slice(0, splitIndex + 1),
    },
    {
      ...createTripCopy,
      legs: createTripCopy.legs.slice(
        splitIndex,
        createTripCopy.legs.length - 1
      ),
    },
  ];
};
