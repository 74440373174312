import axios from "axios";

import { Customer, env, Group, GroupType } from "@busie/utils";
import getPhoneNumberComponents from "../../helpers/get-phone-number-components";

export interface CreateUpdateMetadataPayload {
  key: string;
  value: string;
}

export interface CreateCustomerFormData {
  name: string;
  organization?: string;
  groupname?: string;
  email: string;
  phoneNumber: string;
}
//CUSTOMERS
export const fetchCustomers = async (
  authToken: string,
  groupName?: string,
  skip = 0,
  take = 20
): Promise<{ nextOffset: number; results: Customer[]; total: number }> => {
  const { data } = await axios.get(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        skip: skip,
        take: take,
        group: groupName,
      },
    }
  );

  return data;
};

export const fetchCustomersByIds = async (
  authToken: string,
  ids: string[]
): Promise<Customer[]> => {
  const { data } = await axios.get(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/multiple`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        id: ids,
      },
    }
  );

  return data || [];
};

export const fetchCustomer = async (
  authToken: string,
  id: string
): Promise<Customer> => {
  const { data } = await axios.get(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/${id}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const createCustomer = async (
  authToken: string,
  formData: CreateCustomerFormData
): Promise<Customer> => {
  const requestBody = {
    name: formData.name,
    email: formData.email,
    groups: [formData.organization],
    rating: 0,
    ...getPhoneNumberComponents(formData.phoneNumber),
  };
  const { data } = await axios.post<Customer>(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/`,
    requestBody,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const deleteCustomer = async (
  authToken: string,
  id: string
): Promise<void> => {
  await axios.delete(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/${id}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
};

export const editCustomer = async (
  authToken: string,
  id: string,
  formData: CreateCustomerFormData
): Promise<void> => {
  const requestBody = {
    name: formData.name,
    email: formData.email,
    groups: [formData.organization],
    rating: 0,
    ...getPhoneNumberComponents(formData.phoneNumber),
  };
  await axios.patch(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/${id}`,
    requestBody,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
};

export const createCustomerNote = async (
  authToken: string,
  id: string,
  payload: { text: string }
) => {
  const { data } = await axios.post<Customer>(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/${id}/note`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const createCustomerMetadata = async (
  authToken: string,
  id: string,
  payload: CreateUpdateMetadataPayload
) => {
  const { data } = await axios.post<Customer>(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/customers/${id}/metadata`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const updateCustomerMetadata = async (
  authToken: string,
  id: string,
  metadataId: string,
  payload: CreateUpdateMetadataPayload
) => {
  const { data } = await axios.patch<Customer>(
    `${env(
      "BUSIE_CUSTOMERS_SERVICE_API_URL"
    )}/customers/${id}/metadata/${metadataId}`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

// GROUPS

export interface FetchAllGroupsParams {
  search?: string;
}

export interface FetchGroupsParams {
  search?: string;
  skip?: number;
  take?: number;
}

export interface PagedGroupsResponse {
  nextOffset: number;
  results: Group[];
  total: number;
}

export interface UpdateGroupPayload {
  name: string;
  type: GroupType;
}

// Using a separate api request to represent non-paged responses which occurs
// when when using this endpoint but `search` and `take` are not used
export const fetchAllGroups = async (
  authToken: string,
  params?: FetchAllGroupsParams
): Promise<Group[]> => {
  const { data } = await axios.get(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params,
    }
  );
  return data;
};

export const searchGroups = async (
  authToken: string,
  params?: FetchAllGroupsParams
): Promise<PagedGroupsResponse> => {
  const { data } = await axios.get(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/`,
    { headers: { Authorization: `Bearer ${authToken}` }, params }
  );

  return data;
};

export const fetchGroups = async (
  authToken: string,
  params?: FetchGroupsParams
): Promise<PagedGroupsResponse> => {
  const { data } = await axios.get(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params,
    }
  );
  return data;
};

export const fetchGroup = async (
  authToken: string,
  groupId: string
): Promise<Group> => {
  const { data } = await axios.get(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/${groupId}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const createGroup = async (
  authToken: string,
  formData: { name: string }
): Promise<Group> => {
  const { data } = await axios.post(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/`,
    formData,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const updateGroup = async (
  authToken: string,
  id: string,
  payload: UpdateGroupPayload
) => {
  const { data } = await axios.patch<Group>(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/${id}`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const createGroupNote = async (
  authToken: string,
  id: string,
  payload: { text: string }
) => {
  const { data } = await axios.post<Group>(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/${id}/note`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const createGroupMetadata = async (
  authToken: string,
  id: string,
  payload: CreateUpdateMetadataPayload
) => {
  const { data } = await axios.post<Group>(
    `${env("BUSIE_CUSTOMERS_SERVICE_API_URL")}/groups/${id}/metadata`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const updateGroupMetadata = async (
  authToken: string,
  id: string,
  metadataId: string,
  payload: CreateUpdateMetadataPayload
) => {
  const { data } = await axios.patch<Group>(
    `${env(
      "BUSIE_CUSTOMERS_SERVICE_API_URL"
    )}/groups/${id}/metadata/${metadataId}`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};
