import { Experience } from "@busie/api";

export const getTripFlatRate = (experience: Experience) =>
  experience.LEGS.reduce(
    (acc, cur) =>
      Math.max(
        acc,
        cur.LEG_PRICE.reduce((a, c) => Math.max(a, c.TRIP_FLAT_RATE), 0)
      ),
    0
  );
