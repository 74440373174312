import { createRecipeModal } from "./createRecipeModal.store";
import { RatesStore } from "./rates.store";
import { IngredientEdit } from "./ingredientEdit.store";
import { RecipeEdit } from "./recipeEdit.store";

const store = {
  createRecipeModal: new createRecipeModal(),
  rates: new RatesStore(),
  ingredientModal: new IngredientEdit(),
  recipeEdit: new RecipeEdit(),
};

export default store;
