import { Ingredient, Cookbook, Menu, IngredientType } from "@busie/utils";

import {
  cookbookColors,
  ruleTypeOptions,
  weekdayItems,
  monthItems,
} from "./constants";

import { RatesFormData } from "@busie/api";

export const initialIngredient: Ingredient = {
  value: "",
  name: "",
  id: "",
  recipeId: "",
  type: "HOURLY",
};

export type IngredientOption = {
  name: string;
  type: IngredientType;
  checked?: boolean;
};

export const baseIngredientsList: IngredientOption[] = [
  {
    name: "Live miles",
    type: "LIVE_MILES",
    checked: true,
  },
  {
    name: "Hourly cost",
    type: "HOURLY",
  },
  {
    name: "Daily minimum",
    type: "DAILY_MINIMUM",
  },
  {
    name: "Leg minimum",
    type: "LEG_MINIMUM",
  },
  {
    name: "Deadhead miles",
    type: "DEAD_HEAD_MILES",
  },
];

export const sideIngredientsList: IngredientOption[] = [
  {
    name: "Per night",
    type: "PER_NIGHT",
  },
  {
    name: "Per day “without travel”",
    type: "PER_DAY_NO_TRAVEL",
  },
  {
    name: "Per extra driver",
    type: "EXTRA_DRIVER_FEE",
  },
  {
    name: "Per leg",
    type: "FLAT_LEG_RATE",
  },
  {
    name: "Per trip",
    type: "FLAT_TRIP_RATE",
  },
  { name: "Per day", type: "FLAT_DAY_RATE" },
];
export const initialFormData: RatesFormData = {
  ruleType: ruleTypeOptions[0].value,
  ruleTypeValue: {
    DATE: null,
    TOTAL_MILES: "",
    DAILY_MILES: "",
    LEG_MILES: "",
    TOTAL_TIME: "",
    DAILY_TIME: "",
    LEG_TIME: "",
    DAY_OF_WEEK: weekdayItems[0].value,
    MONTH: monthItems[0].value,
    STANDARD: "Standard",
  },
  name: "",
  cookbookColor: cookbookColors[0],
  cookbookAssignmentType: "ORGANIZATION",
  cookbookId: "",
  ingredientList: {
    base: baseIngredientsList.map((ingredient) => {
      return {
        name: ingredient.name,
        type: ingredient.type,
        checked: ingredient.checked || false,
        ingredients: [
          {
            ...initialIngredient,
          },
        ],
      };
    }),
    side: sideIngredientsList.map((ingredient) => {
      return {
        name: ingredient.name,
        type: ingredient.type,
        checked: ingredient.checked || false,
        ingredients: [
          {
            ...initialIngredient,
          },
        ],
      };
    }),
  },
};

export const initialMenuState: Menu = { _id: "", _organization: "" };
export const initialCookbooksState: Cookbook[] = [];
