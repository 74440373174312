import { palette } from "@busie/ui-kit";

export const formatRemainingDuration = (duration: number) => {
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  return (
    hrs.toString().padStart(2, "0") +
    ":" +
    mins.toString().padStart(2, "0") +
    ":" +
    secs.toString().padStart(2, "0")
  );
};

export const getProgressColor = (progress: number) => {
  return progress > 50 ? "success" : progress > 25 ? "warning" : "error";
};

export const getPaletteColorFromProgressColor = (
  color: "success" | "warning" | "error"
) =>
  ({
    success: palette.green.main,
    warning: palette.supernova.main,
    error: palette.red.main,
  }[color]);
