import { Reservation, Vehicle } from "@busie/utils";
import { H3, palette } from "@busie/ui-kit";
import { Box } from "@mui/system";
import { VehicleIcon } from "@busie/core";
import store from "~/vehicles/store";
import {
  addHours,
  endOfDay,
  endOfHour,
  isAfter,
  isBefore,
  startOfDay,
  startOfHour,
} from "date-fns";
import { addDays } from "date-fns/esm";
import { observer } from "mobx-react-lite";
import React from "react";
import MediumCalendarCell from "./MediumCalendarCell";

interface Props {
  vehicle: Vehicle;
  reservations: Reservation[];
}

const VehicleRow: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ vehicle, reservations }) => {
    const isDayView = store.calendarViewPeriod === "day";
    const getStart = isDayView ? startOfHour : startOfDay;
    const getEnd = isDayView ? endOfHour : endOfDay;
    return (
      <>
        <Box
          sx={{
            height: "48px",
            backgroundColor: palette.black.plus100,
            display: "flex",
            alignItems: "center",
            columnGap: "15px",
            padding: "13px 16px",
          }}
        >
          <VehicleIcon type={vehicle.vehicleType.type} size={26} />
          <H3>{vehicle.licensePlate}</H3>
        </Box>
        {(isDayView ? [...Array(24).keys()] : [...Array(7).keys()]).map((i) => {
          const date = isDayView
            ? addHours(
                startOfHour(store.reservationsFilter.startAt || new Date()),
                i
              )
            : addDays(
                startOfDay(store.reservationsFilter.startAt || new Date()),
                i
              );
          const localReservations = reservations.filter(
            (el) =>
              (!isAfter(getStart(date), new Date(el.startAt)) &&
                !isBefore(getEnd(date), new Date(el.startAt))) ||
              (!isBefore(getEnd(date), new Date(el.endAt)) &&
                !isAfter(getStart(date), new Date(el.endAt))) ||
              (!isBefore(getStart(date), new Date(el.startAt)) &&
                !isAfter(getEnd(date), new Date(el.endAt)))
          );
          const dayReservations = reservations.filter(
            (el) =>
              (!isAfter(startOfDay(date), new Date(el.startAt)) &&
                !isBefore(endOfDay(date), new Date(el.startAt))) ||
              (!isBefore(endOfDay(date), new Date(el.endAt)) &&
                !isAfter(startOfDay(date), new Date(el.endAt))) ||
              (!isBefore(startOfDay(date), new Date(el.startAt)) &&
                !isAfter(endOfDay(date), new Date(el.endAt)))
          );
          return (
            <MediumCalendarCell
              isHoverable={isAfter(getEnd(date), new Date())}
              reservations={localReservations}
              reservationsForDay={dayReservations}
              key={date.toString()}
              date={date}
              vehicle={vehicle}
            />
          );
        })}
      </>
    );
  }
);

export default VehicleRow;
