import { useEffect, useState } from "react";

import { ChefBooking } from "@busie/api";
import { EnvelopeStatus, QuoteAndBookingStatus, dayjsExt } from "@busie/utils";
import {
  Action,
  BookingStatusHandlers,
  EContractStatusHandlers,
  InvoiceStatusHandlers,
} from "./interface";

export const useStatusAction = (
  booking: ChefBooking | undefined,
  { onConfirm, onMarkPaid }: BookingStatusHandlers
) => {
  const [action, setAction] = useState<Action | null>(null);

  useEffect(() => {
    switch (booking?.STATUS) {
      case QuoteAndBookingStatus.PENDING:
        setAction({
          text: "Confirm",
          onClick: onConfirm,
        });
        break;
      case QuoteAndBookingStatus.CONFIRMED:
        setAction({
          text: "Record Offline Payment",
          onClick: onMarkPaid,
        });
        break;
      default:
        setAction(null);
        break;
    }
  }, [booking, onConfirm, onMarkPaid]);

  return action;
};

export const useEContractAction = (
  booking: ChefBooking | undefined,
  { onSend, onVoid }: EContractStatusHandlers
) => {
  const [action, setAction] = useState<Action | null>(null);

  useEffect(() => {
    switch (booking?.CONTRACT_STATUS) {
      case EnvelopeStatus.SENT:
      case EnvelopeStatus.DELIVERED:
        setAction({
          text: "Void",
          onClick: onVoid,
        });
        break;
      case EnvelopeStatus.VOIDED:
      case EnvelopeStatus.DECLINED:
      case EnvelopeStatus.DELETED:
        setAction({
          text: "Send E-Contract",
          onClick: onSend,
        });
        break;
      case EnvelopeStatus.COMPLETED:
      case EnvelopeStatus.SIGNED:
      default:
        setAction(null);
        break;
    }
  }, [booking, onSend, onVoid]);

  return action;
};

export const useInvoiceAction = (
  booking: ChefBooking | undefined,
  { onCreate, onFinalize, onMarkPaid, onSend, onVoid }: InvoiceStatusHandlers
) => {
  const [action, setAction] = useState<Action | null>(null);

  useEffect(() => {
    switch (booking?.ACTIVE_INVOICE?.STATUS) {
      case "CREATED":
        setAction({
          text: "Finalize Invoice",
          onClick: onFinalize,
        });
        break;
      case "FINALIZED":
        setAction({
          text: "Send Invoice",
          onClick: onSend,
        });
        break;
      case "SENT":
        setAction({
          text: "Mark Invoice Paid",
          onClick: onMarkPaid,
        });
        break;
      case "PAID":
        setAction({
          text: "Create Invoice",
          onClick: onCreate,
        });
        break;
      case "VOIDED":
        setAction({
          text: "Create Invoice",
          onClick: onCreate,
        });
        break;
      case "CREATING":
      case "SENDING":
      case "VOIDING":
      case "FINALIZING":
      case "ATTEMPTING_PAYMENT":
        setAction(null);
        break;
      default:
        setAction({
          text: "Create Invoice",
          onClick: onCreate,
        });
        break;
    }
  }, [booking, onCreate, onFinalize, onMarkPaid, onSend, onVoid]);

  return action;
};

export const useSuggestedDates = (booking: ChefBooking | undefined) => {
  const [suggestedDates, setSuggestedDates] = useState<[string, Date][]>([]);

  useEffect(() => {
    if (booking) {
      setSuggestedDates([
        [
          "30 Days from Today",
          dayjsExt().add(30, "day").startOf("day").toDate(),
        ],
        ["End of this Month", dayjsExt().endOf("month").toDate()],
        [
          "First Trip Start Date",
          dayjsExt(booking.QUOTE.EXPERIENCES[0].START_DATE)
            .startOf("day")
            .toDate(),
        ],
        [
          "30 Days After First Trip",
          dayjsExt(booking.QUOTE.EXPERIENCES[0].START_DATE)
            .add(30, "day")
            .startOf("day")
            .toDate(),
        ],
        ...(booking.QUOTE.EXPERIENCES.length > 1
          ? ([
              [
                "Last Trip Start Date",
                dayjsExt(
                  booking.QUOTE.EXPERIENCES[
                    booking.QUOTE.EXPERIENCES.length - 1
                  ].START_DATE
                )
                  .startOf("day")
                  .toDate(),
              ],
              [
                "30 Days After Last Trip",
                dayjsExt(
                  booking.QUOTE.EXPERIENCES[
                    booking.QUOTE.EXPERIENCES.length - 1
                  ].START_DATE
                )
                  .add(30, "day")
                  .startOf("day")
                  .toDate(),
              ],
            ] as [string, Date][])
          : ([] as [string, Date][])),
      ]);
    } else {
      setSuggestedDates([]);
    }
  }, [booking]);

  return suggestedDates;
};
