import * as yup from "yup";

const MESSAGE = "input must match 'confirm'";

export const schema = yup.object({
  confirm: yup
    .string()
    .required(MESSAGE)
    .matches(/confirm/, MESSAGE),
});
