import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Loading,
  MenuItem,
  DrawerDetailsBlock as DetailsBlock,
  FlexContainer,
  Button,
} from "@busie/ui-kit";
import { Booking, Invoice, Quote, Trip, User } from "@busie/utils";

import {
  InvoiceButton,
  InvoiceList,
} from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/Invoice";
import { InvoiceMenu } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/Invoice/";
import { InvoiceDueDate } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/Invoice/InvoiceDueDate";
import { InvoiceStatus } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/Invoice/InvoiceStatus";
import {
  useGetInvoiceById,
  useListInvoices,
} from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/model";
import { CreateInvoice } from "~/QuotesAndBookingsPage/features";
import { CreatePaymentLink } from "~/QuotesAndBookingsPage/features/CreatePaymentLink";
import { FinalizeInvoice } from "~/QuotesAndBookingsPage/features/FinalizeInvoice";
import { PayInvoice } from "~/QuotesAndBookingsPage/features/PayInvoice";
import { SendInvoice } from "~/QuotesAndBookingsPage/features/SendInvoice";
import { VoidInvoice } from "~/QuotesAndBookingsPage/features/VoidInvoice";
import { useAmplitude, usePlan } from "@busie/core";

import { PaymentLinkButton } from "../../../../entity/DetailsDrawer/Payment";
import { getInvoiceButtonProps } from "../../helpers";
import {
  useActiveInvoice,
  useInvoiceList,
  useIsLoading,
  useShowCreatePaymentLink,
} from "./model";
import { usePaymentsAuthToken } from "@busie/core";
import { useInvoicePreview } from "~/QuotesAndBookingsPage/features/PreviewInvoice/model";
import { notificationStore } from "@busie/features";

interface Props {
  booking: Booking;
  quote: Quote;
  revenueShareConfigId: string;
  trip: Trip;
  onUpdate: () => void | Promise<void>;
}

export const BookingPaymentBlock: React.FC<React.PropsWithChildren<Props>> = ({
  booking,
  quote,
  revenueShareConfigId,
  trip,
  onUpdate,
}) => {
  const { track } = useAmplitude();
  const psAuthToken = usePaymentsAuthToken();

  const { user } = useAuth0<User>();
  const { data: plan } = usePlan(user?.org_id);
  const [isCreateInvoiceOpen, setIsCreateInvoiceOpen] = useState(false);
  const [isFinalizeInvoiceOpen, setIsFinalizeInvoiceOpen] = useState(false);
  const [isSendInvoiceOpen, setIsSendInvoiceOpen] = useState(false);
  const [isVoidInvoiceOpen, setIsVoidInvoiceOpen] = useState(false);
  const [isPayInvoiceOpen, setIsPayInvoiceOpen] = useState(false);
  const [isCreatePaymentLinkOpen, setIsCreatePaymentLinkOpen] = useState(false);

  const {
    data: invoice,
    isLoading: isInvoiceLoading,
    isFetching: isInvoiceFetching,
    refetch: refetchInvoice,
  } = useGetInvoiceById(booking._invoiceId);

  const {
    data: invoices = [],
    isLoading: isInvoiceListLoading,
    isFetching: isInvoiceListFetching,
    refetch: refetchInvoices,
  } = useListInvoices({ bookingId: booking._id });

  const activeInvoice = useActiveInvoice(invoice);
  const invoiceList = useInvoiceList(invoices);
  const showCreatePaymentLinkButton = useShowCreatePaymentLink(activeInvoice);
  const isLoading = useIsLoading(
    isInvoiceLoading,
    isInvoiceFetching,
    isInvoiceListLoading,
    isInvoiceListFetching
  );

  const getPrimaryInvoiceButtonClickHandler = (invoice?: Invoice) => {
    if (!invoice) return () => setIsCreateInvoiceOpen(true);

    switch (invoice.status) {
      case "CREATED":
        return () => setIsFinalizeInvoiceOpen(true);
      case "FINALIZED":
        return () => setIsSendInvoiceOpen(true);
      case "SENT":
        return () => setIsPayInvoiceOpen(true);
      case "VOIDED":
        return () => setIsCreateInvoiceOpen(true);
      default:
        return () => undefined;
    }
  };

  const invoiceSuccessHandler =
    (setFeatureState: (state: boolean) => void) => async (invoice: Invoice) => {
      setFeatureState(false);

      await onUpdate();

      setTimeout(async () => {
        await refetchInvoice();
        await refetchInvoices();
      }, 500);
    };

  const paymentLinkSuccessHandler = (setState: (state: boolean) => void) => {
    return async (data: { amount: number }) => {
      track("payment link sent", {
        balance: booking._balance,
        paymentAmount: data.amount,
        bookingId: booking._id,
        price: quote._totalPrice,
        quoteId: quote._id,
        tripId: quote._experienceId,
      });

      notificationStore.setNotification({
        type: "success",
        message: "Your payment link has been created and sent to the customer.",
        header: "Success",
      });
      setState(false);

      await onUpdate();
    };
  };

  const { data: invoiceUrl, refetch } = useInvoicePreview(
    psAuthToken,
    booking?._invoiceId
  );

  const handlePreviewClick = async () => {
    if (invoiceUrl) {
      return window.open(invoiceUrl, "_blank");
    }

    refetch().then((result) => {
      if (!result?.data) return;

      const url = result.data;
      return window.open(url, "_blank");
    });
  };

  const getInvoiceMenuComponent = (invoice?: Invoice): React.ReactNode => {
    if (!invoice || ["VOIDED"].includes(invoice.status)) return null;

    const items: MenuItem[] = [];

    const { status } = invoice;

    if (status === "FINALIZED" || status === "SENT") {
      items.push({
        content: "Mark as Paid",
        action: () => setIsPayInvoiceOpen(true),
      });
    }

    if (!["PAID"].includes(invoice.status)) {
      items.push({
        content: "Void",
        action: () => setIsVoidInvoiceOpen(true),
      });
    }

    if (
      ["FINALIZED", "SENT", "PAYMENT_FAILED", "PAID"].includes(invoice.status)
    ) {
      console.log("'adding item'");
      items.push({
        content: "Preview",
        action: () => handlePreviewClick(),
      });
    }

    return <InvoiceMenu items={items} />;
  };

  return (
    <DetailsBlock collapsible name="Booking Payment" className="no-print">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <FlexContainer direction="column" rowGap={2} pb={2}>
            <FlexContainer justify="space-between" fullWidth>
              {showCreatePaymentLinkButton && (
                <PaymentLinkButton
                  disabled={!showCreatePaymentLinkButton}
                  onClick={() => setIsCreatePaymentLinkOpen(true)}
                />
              )}
            </FlexContainer>

            {plan && plan.subscriptionType !== "FREEMIUM" && (
              <FlexContainer justify="space-between" align="center" fullWidth>
                <InvoiceButton
                  {...getInvoiceButtonProps(activeInvoice)}
                  onClick={getPrimaryInvoiceButtonClickHandler(activeInvoice)}
                />

                <InvoiceStatus invoice={activeInvoice} />

                <InvoiceDueDate invoice={activeInvoice} />

                {getInvoiceMenuComponent(activeInvoice)}
              </FlexContainer>
            )}
          </FlexContainer>

          <InvoiceList invoices={invoiceList} />

          <CreatePaymentLink
            isOpen={isCreatePaymentLinkOpen}
            bookingId={booking._id}
            bookingBalance={booking._balance}
            email={quote._contactEmail || ""}
            onClose={() => setIsCreatePaymentLinkOpen(false)}
            onPaymentLinkCreated={paymentLinkSuccessHandler(
              setIsCreatePaymentLinkOpen
            )}
          />

          <CreateInvoice
            bookingId={booking._id}
            amount={booking._balance}
            customerGroupId={quote._customerGroupId}
            organizationId={quote._organizationId}
            mainContactId={
              quote._mainContactId || trip._mainContactId || trip._tripPlannerId
            }
            revenueShareConfigId={revenueShareConfigId}
            isOpen={isCreateInvoiceOpen}
            onClose={() => setIsCreateInvoiceOpen(false)}
            onInvoiceCreated={invoiceSuccessHandler(setIsCreateInvoiceOpen)}
          />

          {activeInvoice && (
            <>
              <FinalizeInvoice
                isOpen={isFinalizeInvoiceOpen}
                invoiceId={activeInvoice._id}
                hasPayouts={!!Object.keys(activeInvoice.payouts).length}
                onClose={() => setIsFinalizeInvoiceOpen(false)}
                onInvoiceFinalized={invoiceSuccessHandler(
                  setIsFinalizeInvoiceOpen
                )}
              />
              <SendInvoice
                email={activeInvoice.customer.email}
                invoiceId={activeInvoice._id}
                onInvoiceSent={invoiceSuccessHandler(setIsSendInvoiceOpen)}
                onClose={() => setIsSendInvoiceOpen(false)}
                isOpen={isSendInvoiceOpen}
              />
              <VoidInvoice
                email={activeInvoice.customer.email}
                invoiceId={activeInvoice._id}
                onClose={() => setIsVoidInvoiceOpen(false)}
                isOpen={isVoidInvoiceOpen}
                onInvoiceVoided={invoiceSuccessHandler(setIsVoidInvoiceOpen)}
              />
              <PayInvoice
                invoiceId={activeInvoice._id}
                isOpen={isPayInvoiceOpen}
                onClose={() => setIsPayInvoiceOpen(false)}
                onInvoicePaid={invoiceSuccessHandler(setIsPayInvoiceOpen)}
              />
            </>
          )}
        </>
      )}
    </DetailsBlock>
  );
};
