import React from "react";

interface Props {
  size?: number;
  color?: string;
}

export const ChevronUp: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      style={{ minWidth: size, minHeight: size }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14L12 10L16 14"
        stroke={props.color || "#6E6E6E"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
