import {
  Button,
  Divider,
  FormContainer,
  H2,
  H3,
  NoteText,
  TextInput,
  palette,
} from "@busie/ui-kit";
import { Controller, useForm } from "react-hook-form";
import { schema, useSendQuote } from "./model";
import { ChefQuote } from "@busie/api";
import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAmplitude } from "@busie/core";
import { notificationStore } from "@busie/features";
import { useEffect } from "react";

interface Props {
  isOpen: boolean;
  quote: ChefQuote;
  onClose: () => void;
  onQuoteSent: () => void;
}

interface FormValues {
  confirm: string;
}

export const SendQuote: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  quote,
  onClose,
  onQuoteSent,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      confirm: "",
    },
  });

  const { isLoading, mutateAsync: sendQuote } = useSendQuote(quote._id);

  const { track } = useAmplitude();

  const onSubmit = async () => {
    try {
      await sendQuote(undefined, {
        onSuccess: () => {
          track("Quote Sent", {
            quoteId: quote._id,
            price: quote.PRICE,
            numberOfTrips: quote.EXPERIENCES.length,
          });

          notificationStore.setNotification({
            message: "Quote sent successfully",
            type: "success",
          });

          onQuoteSent();
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  useEffect(() => reset, [reset, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <H2>Send Quote</H2>
      <FormContainer title="Confirm Quote Re-Send">
        <H3>Are you sure you want to send this Quote again?</H3>

        <NoteText>
          This will send a Quote Confirmation Email to the email address set as
          the Quote's "main contact", which includes Trip itinerary details and
          the total quoted price. This action doesn't affect the Quote status.
        </NoteText>

        <NoteText color={palette.red.main}>
          This action cannot be undone
        </NoteText>

        <Divider />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="confirm"
            render={({ field }) => (
              <TextInput
                {...field}
                label="Type 'confirm' to send quote"
                fullWidth
                errorMessage={errors.confirm?.message}
              />
            )}
          />

          <div className="form-submit-control">
            <Button
              typestyle="secondary"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>

            <Button
              typestyle="primary"
              type="submit"
              disabled={!isDirty || !isValid || isSubmitting || isLoading}
            >
              Send Quote
            </Button>
          </div>
        </form>
      </FormContainer>
    </Modal>
  );
};
