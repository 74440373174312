import React from "react";

import { Modal as BaseModal } from "@busie/ui-kit";
import { Styled } from "./styled";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isOpen,
  onClose,
}) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <Styled>{children}</Styled>
    </BaseModal>
  );
};
