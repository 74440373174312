import React, { useState } from "react";
import { Chip, Popover, useMediaQuery } from "@mui/material";
import {
  Button,
  ChevronDown,
  ChevronUp,
  DateRangePicker,
  FilterSelectorStyled,
  FlexContainer,
  Loading,
  OrganizationMemberWithAvatar,
  Select,
  Text,
  palette,
} from "@busie/ui-kit";
import { QuoteAndBookingStatus } from "@busie/utils";
import { StatusBadge } from "~/QuotesAndBookingsPage/shared/ui";
import { useDispatchLocations } from "~/QuotesAndBookingsPage/model";
import { FilterValues, defaultFilter } from "./model";
import { useUsers } from "@busie/core";
import { getAssigneeFilterOptions } from "./lib";
import {
  FilterControls,
  SelectAssignee,
  SelectDispatch,
  SelectStatus,
} from "./ui";

interface Props {
  onChange: (value: FilterValues) => void;
  statusOptions: { name: string; value: QuoteAndBookingStatus }[];
  defaultValues?: FilterValues;
}

export const Filter: React.FC<React.PropsWithChildren<Props>> = ({
  onChange,
  statusOptions,
  defaultValues,
}) => {
  const isMobile = useMediaQuery("@media (max-width: 960px)");
  const isTablet = useMediaQuery("@media (max-width: 1136px)");
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [filter, setFilter] = useState<FilterValues>(
    defaultValues ?? defaultFilter
  );

  const { data: dispatchLocations } = useDispatchLocations();

  const { data: usersMap = {}, isLoading: isUsersLoading } = useUsers();

  return (
    <>
      {!isMobile ? (
        <Button
          typestyle="secondary"
          endIcon={anchor ? <ChevronUp size={22} /> : <ChevronDown size={22} />}
          size="small"
          sx={{
            width: "150px",
            justifyContent: "space-between",
            border: `1px solid ${palette.black.plus70} !important`,
          }}
          onClick={(e) =>
            setAnchor((anchor) => (anchor ? null : e.currentTarget))
          }
        >
          <span>Filter</span>
        </Button>
      ) : (
        <FilterSelectorStyled
          onClick={(e) =>
            setAnchor((anchor) => (anchor ? null : e.currentTarget))
          }
          sx={{ height: "40px", padding: "3px 10px !important" }}
        >
          •••
        </FilterSelectorStyled>
      )}

      <Popover
        id="filters"
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: "8px", height: "100%" }}
      >
        <FlexContainer direction="column" w="350px" p={3} rowGap={2}>
          <SelectStatus
            onChange={(statuses) =>
              setFilter((value) => ({ ...value, statuses }))
            }
            selected={filter.statuses}
            items={statusOptions}
            isTablet={isTablet}
          />

          <SelectAssignee
            users={usersMap}
            selected={filter.assigneeId ?? "ALL"}
            loading={isUsersLoading}
            onChange={(assigneeId) =>
              setFilter((value) => ({ ...value, assigneeId }))
            }
          />

          <SelectDispatch
            onChange={(dispatches) =>
              setFilter((value) => ({ ...value, dispatches }))
            }
            selected={filter.dispatches}
            items={dispatchLocations.map((dispatch) => ({
              name: dispatch.name,
              value: dispatch.id,
            }))}
            getName={(id) =>
              dispatchLocations.find((d) => d.id === id)?.name ?? ""
            }
          />

          <DateRangePicker
            label="Created Date Range"
            value={filter.createdDates}
            onChange={(value) =>
              setFilter((filter) => ({ ...filter, createdDates: value }))
            }
          />

          <DateRangePicker
            label="Start Date Range"
            value={filter.pickupDates}
            onChange={(value) =>
              setFilter((filter) => ({ ...filter, pickupDates: value }))
            }
          />

          <FilterControls
            onApply={() => {
              onChange(filter);
              setAnchor(null);
            }}
            onClose={() => setAnchor(null)}
            onReset={() => setFilter(defaultFilter)}
          />
        </FlexContainer>
      </Popover>
    </>
  );
};
