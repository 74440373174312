import React, { useState } from "react";
// import { useLocation } from "react-router";

import {
  AddIcon,
  Button,
  DriversEmptyState,
  H3,
  Loading,
  NoContentBlock,
} from "@busie/ui-kit";
import { Driver } from "@busie/utils";
// import { useDebounce } from "@busie/core";

import { useFetchDrivers } from "./model";
import { DriversTable, DriverDetailsDrawer } from "./widgets";
import { CreateDriver } from "./features";

export const DriversPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [selectedDriver, setSelectedDriver] = useState<Driver>();
  const [isCreateDriverOpen, setIsCreateDriverOpen] = useState(false);

  // Currently, we do not have any filtering capabilities
  const { data: drivers, isLoading, isFetching } = useFetchDrivers({});

  // const { search } = useLocation();
  // const searchValues = new URLSearchParams(search);
  // const [typesFilter, setTypesFilter] = useState<string[]>(
  //   searchValues.get("types")?.split(",") || ["all"]
  // );
  // const [searchQuery, setSearchQuery] = useState(
  //   searchValues.get("search") || ""
  // );
  // const debouncedSearchValue = useDebounce(searchQuery, 500);
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const onSearchChange = (value: string) => {
  //   setSearchQuery(value);
  // };

  return isLoading || isFetching ? (
    <Loading />
  ) : (
    <>
      {drivers?.length ? (
        <DriversTable
          drivers={drivers}
          onDriverClicked={(driver: Driver) => setSelectedDriver(driver)}
        />
      ) : (
        <NoContentBlock
          icon={<DriversEmptyState />}
          text="You don't have any drivers yet. Add your first one."
          controls={
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsCreateDriverOpen(true)}
            >
              Add driver
            </Button>
          }
        >
          <H3 sx={{ textAlign: "center" }}>You don't have any drivers yet.</H3>
          <H3 weight={500}>Add your first one!</H3>
        </NoContentBlock>
      )}

      {selectedDriver && (
        <DriverDetailsDrawer
          driver={selectedDriver}
          onClose={() => setSelectedDriver(undefined)}
        />
      )}

      <CreateDriver
        isOpen={isCreateDriverOpen}
        onClose={() => setIsCreateDriverOpen(false)}
        onDriverCreated={() => setIsCreateDriverOpen(false)}
      />
    </>
  );
};
