import { styled } from "@mui/system";

export const AdditionalActionsStyled = styled("div")(() => {
  return {
    "&": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "16px",
      marginBottom: "52px",
    },
  };
});
