import { GooglePlace } from "@busie/utils";
import * as yup from "yup";

const GOOGLE_PLACE_SCHEMA = yup.object({
  address_components: yup.array().of(yup.object()),
  formatted_address: yup.string().required(),
  geometry: yup.object({
    location: yup.object({
      lat: yup.number().required(),
      lng: yup.number().required(),
    }),
  }),
  place_id: yup.string(),
  html_attributions: yup.array().of(yup.string()),
});

export interface Place extends Omit<GooglePlace, "geometry"> {
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
}

export interface RouteFormData {
  stops: Place[];
  pickup: Place;
  destination: Place;
}

export const schema = yup.object({
  stops: yup.array().of(GOOGLE_PLACE_SCHEMA),
  pickup: GOOGLE_PLACE_SCHEMA.required(),
  destination: GOOGLE_PLACE_SCHEMA.required(),
});
