import { styled } from "@mui/system";

import { Theme } from "@mui/system";
import { InputLabelDotStyles } from "../../styles";

interface Props {
  theme?: Theme;
}
export const LegBlockStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    marginTop: "20px",
    paddingBottom: "13px",
    boxShadow: `inset 0px -1px 0px ${palette?.black?.plus70}`,
    ".block-header": {
      display: "flex",
      flexWrap: "wrap",
      //   columnGap: "8px",

      justifyContent: "space-between",
      alignItems: "center",

      fontWeight: "600",
      fontSize: "14px",
      marginBottom: "23px",
      span: {
        display: "inline-block",
      },
      ">div": {
        display: "flex",
        alignItems: "center",
      },
      ".index": {
        color: palette?.black?.plus30,
        marginRight: "20px",
      },
      ".distance": {
        color: palette?.black?.main,
      },
      ".price": {
        color: palette?.black?.plus30,
        "&.live": {
          color: palette?.black?.main,
        },
        marginRight: "10px",
      },
      ".icon": {
        height: "24px",
      },
    },
    ".departures": {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: 1,
      color: palette?.black?.main,
      paddingLeft: "20px",
      borderLeft: `2px dashed ${palette?.green?.main}`,
      ".origin": {
        marginBottom: "16px",
        position: "relative",
        "&:before": {
          ...InputLabelDotStyles,
          backgroundColor: palette?.black?.plus100,
          height: "6px",
          width: "6px",
          border: `1px solid ${palette?.green?.main}`,
          left: "-25px",
        },
        "&.start": {
          "&:before": {
            borderColor: palette?.black?.plus50,
          },
        },
      },
      ".destination": {
        position: "relative",
        "&:before": {
          ...InputLabelDotStyles,
          left: "-25px",
          top: "22px",
        },
        "&.end": {},
      },

      "&.start": {
        borderColor: palette?.black?.plus50,
      },
      "&.end": {
        borderColor: palette?.black?.main,
        ".origin": {
          "&:before": {
            backgroundColor: palette?.black?.plus100,
            height: "6px",
            width: "6px",
            border: `1px solid ${palette?.black?.main}`,
          },
        },

        ".destination": {
          "&:before": {
            backgroundColor: palette?.black?.plus50,
          },
        },
      },
      "&.last": {
        ".destination": {
          "&:before": {
            backgroundColor: palette?.black?.main,
          },
        },
      },
      "&.first": {
        ".origin": {
          "&:before": {
            backgroundColor: palette?.black?.plus100,
            border: `1px solid ${palette?.black?.main}`,
          },
        },
      },
    },
  };
});

export default LegBlockStyled;
