import { ChefBooking, ChefQuote } from "@busie/api";
import { FlexContainer, H3, H4 } from "@busie/ui-kit";
import React from "react";

interface Props {
  quotes: Omit<ChefQuote & { ID: string }, "_id">[];
  bookings: Omit<ChefBooking & { ID: string }, "_id">[];
}

export const Cell: React.FC<Props> = ({ bookings, quotes }) => (
  <FlexContainer direction="column" align="flex-start" justify="center">
    <H3 margin="0px">{bookings.length}</H3>
    <H4>Out of {quotes.length} quotes</H4>
  </FlexContainer>
);
