import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface AuthOptions {
  domain: string;
  audience: string;
  clientId: string;
  redirect_uri: string;
}

export const useAuthToken = ({
  domain,
  audience,
  clientId,
  redirect_uri,
}: AuthOptions): string => {
  const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } =
    useAuth0();
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    const getToken = async (): Promise<void> => {
      if (!isAuthenticated) {
        console.error(
          "You must be logged in to access this resource. Please login and try again."
        );
        return;
      }
      try {
        const token = await getAccessTokenSilently({
          domain,
          audience,
          clientId,
          redirect_uri,
        });
        setAuthToken(token);
      } catch (e) {
        console.error(e);

        if ((e as { message: string }).message === "Consent required") {
          /** Note:
           * This should only happen once.
           * Also, it should never happen in production, as we can disable requiring consent
           * for applications when not serving the app at localhost.
           */
          setAuthToken(
            await getAccessTokenWithPopup({ audience, domain, clientId })
          );
        }
      }
    };

    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return authToken;
};
