import React, { useState } from "react";

import { Button, AddIcon } from "@busie/ui-kit";

import { CreateCustomer } from "./features";

export const AddCustomerButton: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [isCreateCustomerVisible, setIsCreateCustomerVisible] = useState(false);

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={() => setIsCreateCustomerVisible(true)}
      >
        Add customer
      </Button>
      <CreateCustomer
        visible={isCreateCustomerVisible}
        hide={() => setIsCreateCustomerVisible(false)}
      />
    </>
  );
};
