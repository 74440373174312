import React from "react";
import { observer } from "mobx-react-lite";

import CustomerDataFieldsStyled from "./styled/CustomerDataFieldsStyled";

import store from "../../store";
import { CustomerFormFieldsKeys } from "../../store/customerForm/store.types";
import { Group } from "@busie/utils";
import {
  FlexContainer,
  NumberInputEvent,
  Select,
  SelectChangeEvent,
  TextInputEvent,
  NumberInput,
  TextInput,
  InfoIcon,
} from "@busie/ui-kit";
import CreateGroupPopupBlock from "./CreateGroupPopupBlock";
import { styled } from "@mui/system";
import { Tooltip } from "@mui/material";
import { useAmplitude } from "@busie/core";

interface Props {
  groups: Group[];
  customersAuthToken: string;
}

const SelectStyled = styled(Select)(() => {
  return {
    maxWidth: "188px",
    width: "100%",
    paddingTop: "8px",
  };
});

const TooltipIconStyled = styled("div")(() => {
  // const palette = props.theme?.palette;
  return {
    display: "flex",
  };
});

const CustomerDataFields: React.FC<React.PropsWithChildren<Props>> = observer(
  (props: Props) => {
    const { track } = useAmplitude();
    const customerForm = store.customerForm;

    const handlerOnChange = (
      e: TextInputEvent | NumberInputEvent | SelectChangeEvent,
      name: CustomerFormFieldsKeys
    ) => customerForm.setCustomerFormValue(name, e.target.value);

    React.useEffect(() => {
      if (customerForm.fields.organization === "" && props.groups.length) {
        customerForm.setCustomerFormValue("organization", props.groups[0].id);
        customerForm.setGroup(props.groups[0]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("errors", customerForm.validation.errors);

    return (
      <CustomerDataFieldsStyled>
        <TextInput
          required
          label="Full Name"
          onChange={(e) => handlerOnChange(e, "name")}
          name="fullName"
          placeholder="Enter full name"
          value={customerForm.fields.name}
          errorMessage={customerForm.validation.errors.name}
        />
        <FlexContainer columnGap={2} align="center" w="240px" h={90}>
          <SelectStyled
            label="Group"
            name="organization"
            placeholder="No group selected"
            items={props.groups}
            onChange={(e) => {
              customerForm.setCustomerFormValue("organization", e.target.value);
              customerForm.setGroup({ id: e.target.value } as Group);
            }}
            getValue={(option) => option.id}
            value={customerForm.fields.organization}
            errorMessage={customerForm.validation.errors.organization}
          />
          <CreateGroupPopupBlock
            authToken={props.customersAuthToken}
            onGroupCreation={(group) => {
              track("trip planning customer group created");
              const groupId = customerForm.addGroup(group);
              customerForm.setCustomerFormValue("organization", groupId);
              customerForm.setGroup(group);
            }}
          />
        </FlexContainer>
        <TextInput
          required
          label="Email"
          onChange={(e) => handlerOnChange(e, "email")}
          name="email"
          placeholder="Enter email"
          value={customerForm.fields.email}
          errorMessage={customerForm.validation.errors.email}
        />
        <FlexContainer columnGap={2} align="center" w="240px" h={90}>
          <NumberInput
            required
            label="Phone number"
            onChange={(e) => handlerOnChange(e, "phoneNumber")}
            name="phoneNumber"
            placeholder="+1 (000) 000-0000"
            isPhoneField={true}
            value={customerForm.fields.phoneNumber}
            errorMessage={customerForm.validation.errors.phoneNumber}
            tooltip="hello"
          />
          <Tooltip
            title={`Please include the country code of your phone number (i.e. '1' for US).
          Do not include special characters (i.e. +()-# etc.).
          If you find your phone number is not supported, please leave a note in the 'Additional Info' section on the next step.
          `}
            placement="top"
          >
            <TooltipIconStyled>
              <InfoIcon size={24} />
            </TooltipIconStyled>
          </Tooltip>
        </FlexContainer>
      </CustomerDataFieldsStyled>
    );
  }
);

export default CustomerDataFields;
