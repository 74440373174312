import React from "react";
import { VoidInvoiceData } from "@busie/api";
import { notificationStore } from "@busie/features";
import { Modal } from "@busie/ui-kit";
import { Invoice } from "@busie/utils";

import { VoidInvoiceForm } from "~/QuotesAndBookingsPage/entity/Invoice";

import { useVoidInvoice } from "./model";
import { useAmplitude } from "@busie/core";

interface Props {
  isOpen: boolean;
  invoiceId: string;
  email: string;
  onClose: () => void;
  onInvoiceVoided: (invoice: Invoice) => Promise<void> | void;
}

export const VoidInvoice: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  invoiceId,
  email,
  onClose,
  onInvoiceVoided,
}) => {
  const { mutateAsync: voidInvoice } = useVoidInvoice();

  const onSubmit = async (voidInvoiceData: VoidInvoiceData) => {
    try {
      await voidInvoice(
        { invoiceId, voidInvoiceData },
        {
          onSuccess: async (result) => {
            onClose();
            await onInvoiceVoided(result);
          },
          onError: (e) => {
            notificationStore.setNotificationFromError(e);
          },
        }
      );
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <VoidInvoiceForm email={email} onCancel={onClose} onSubmit={onSubmit} />
    </Modal>
  );
};
