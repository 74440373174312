import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { useAuth0 } from "@auth0/auth0-react";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import { DefaultPrivacyLevel } from "@datadog/browser-rum";

import {
  AuthFlow,
  DatadogProvider,
  initAmplitude,
  queryClient,
} from "@busie/core";
import { env, history, User } from "@busie/utils";
import { theme } from "@busie/ui-kit";

import { InternalPages } from "../../pages";

export const ApplicationCore: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { user } = useAuth0<User>();

  if (!user) return <h3>You must be logged in to access this application</h3>;

  initAmplitude({ apiKey: env("AMPLITUDE_API_KEY"), user });

  return (
    <DatadogProvider
      user={user}
      enabled={env("ENABLE_RUM") === "true"}
      sessionReplayRecording={env("ENABLE_RUM") === "true"}
      applicationId={env("RUM_APPLICATION_ID")}
      clientToken={env("RUM_CLIENT_TOKEN")}
      site={env("RUM_SITE")}
      service={env("RUM_SERVICE")}
      env={env("RUM_ENV")}
      version={env("RUM_VERSION")}
      sessionSampleRate={parseInt(env("RUM_SAMPLE_RATE") || "100")}
      sessionReplaySampleRate={parseInt(env("RUM_SAMPLE_RATE") || "100")}
      trackUserInteractions={env("RUM_TRACK_INTERACTIONS") === "true"}
      trackResources={env("RUM_TRACK_RESOURCES") === "true"}
      trackLongTasks={env("RUM_TRACK_LONG_TASKS") === "true"}
      trackFrustrations={env("RUM_TRACK_FRUSTRATIONS") === "true"}
      allowedTracingUrls={Array.from(
        new Set(
          [
            env("BUSIE_RATE_SERVICE_API_URL"),
            env("BUSIE_CUSTOMERS_SERVICE_API_URL"),
            env("BUSIE_PATHFINDER_SERVICE_API_URL"),
            env("BUSIE_VEHICLE_SERVICE_API_URL"),
            env("BUSIE_QUOTES_AND_BOOKINGS_SERVICE_API_URL"),
            env("BUSIE_TRIPS_SERVICE_API_URL"),
            env("BUSIE_PAYMENT_SERVICE_API_URL"),
            env("BUSIE_ETP_BFF_SERVICE_API_URL"),
            env("BUSIE_DISPATCH_LOCATION_SERVICE_API_URL"),
            env("BUSIE_PROFILE_SERVICE_API_URL"),
            env("BUSIE_PLANS_SERVICE_API_URL"),
          ].filter((url) => url.length && url.startsWith("https://"))
        )
      )}
      defaultPrivacyLevel={
        (env("RUM_DEFAULT_PRIVACY_LEVEL") || "allow") as DefaultPrivacyLevel
      }
      enableHeatmap={env("RUM_ENABLE_HEATMAP") === "true"}
      enableFeatureFlags={env("FEATURE_FLAGS_ENABLED") === "true"}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <div className="App">
              <Switch>
                <Route path="/login">
                  {/* <AuthFlow /> */}
                  <Redirect to={{ pathname: "/" }} />
                </Route>
                <Route path="/">
                  <InternalPages />
                </Route>
              </Switch>
            </div>
          </Router>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </DatadogProvider>
  );
};
