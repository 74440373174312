import React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";

import Stack from "@mui/material/Stack";

import { Button } from "@busie/ui-kit";

import PopupControlsStyled from "~/rates/RateMenuPage/components/CreateRecipeModal/styled/PopupControlsStyled";

import RateMenuPageStore from "~/rates/RateMenuPage/store";
import { isIngredientsFilled, isRateTypeFilled } from "@busie/api";

interface Props {
  onClose: () => void;
  stepsQty: number;
  currentStep: number;
  onNextStep: () => void;
  onSubmit: () => void;
}

const PopupControls: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ onClose, stepsQty, currentStep, onNextStep, onSubmit }) => {
    const store = RateMenuPageStore.createRecipeModal;
    const isRateTypeAndIngredientsFilled = computed(
      () =>
        isRateTypeFilled(store.formData) && isIngredientsFilled(store.formData)
    );
    const isRateRecipeNameFilled = Boolean(store.formData.name);
    const handleSubmit = async () => {
      await onSubmit();
      await store.setInitialState();
    };
    const handleCancel = () => {
      onClose();
    };

    const submitDisabled =
      !isRateRecipeNameFilled || !store.formData.cookbookId;
    return (
      <PopupControlsStyled>
        <Stack direction="row" spacing={2}>
          <Button typestyle="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {stepsQty > currentStep ? (
            <Button
              onClick={onNextStep}
              disabled={!isRateTypeAndIngredientsFilled.get()}
            >
              Next
            </Button>
          ) : (
            <Button async disabled={submitDisabled} onClick={handleSubmit}>
              Save recipe
            </Button>
          )}
        </Stack>
      </PopupControlsStyled>
    );
  }
);

export default PopupControls;
