import { User } from "@auth0/auth0-spa-js";

import { fetchCookbooks, fetchMenu, PaginatedResponse } from "@busie/api";

import { Cookbook, CookbookType, Menu, RecipesMap } from "@busie/utils";
import { useMediaQuery } from "@mui/material";
import { useQuery, UseQueryResult } from "react-query";
import store from "../store";

export const useMenu = (
  authToken: string,
  user?: User
): UseQueryResult<Menu> => {
  return useQuery<Menu[], Error, Menu>(
    "menus",
    () => fetchMenu({ organization: user?.org_id }, authToken),
    {
      enabled: !!user?.org_id && !!authToken,
      select: (menus) => menus[0],
      staleTime: 1000 * 60 * 60,
    }
  );
};

export const useCookbooks = (
  authToken: string,
  nameFilter: string,
  typeFilter: string,
  currentPage: number,
  menu: Menu | undefined
): UseQueryResult<PaginatedResponse<Cookbook>> => {
  return useQuery<PaginatedResponse<Cookbook>, Error>(
    ["rates", nameFilter, typeFilter, currentPage],
    () =>
      fetchCookbooks(authToken, {
        menuId: menu?._id as string,
        name: nameFilter,
        page: currentPage,
        itemsPerPage: 10,
        ...(typeFilter !== "all" && {
          type: typeFilter as CookbookType,
        }),
      }),
    {
      enabled: !!menu && !!authToken,
      onSuccess: (cookbooksResponse) => {
        const recipesMap = cookbooksResponse.resultSet
          .flatMap((cookbook) => cookbook.recipes)
          .reduce((prev: RecipesMap, curr) => {
            prev[curr.id] = curr;
            return prev;
          }, {});
        store.rates.updateRecipesMap(recipesMap);
      },
    }
  );
};

export const useShowSideIngredients = (): boolean => {
  return useMediaQuery("(min-width:870px)");
};

export const useShowBaseIngredients = (): boolean => {
  return useMediaQuery("(min-width:856px)");
};

export const useShowRules = (): boolean => {
  return useMediaQuery("(min-width:670px)");
};

export const useShowRecipes = (): boolean => {
  return useMediaQuery("(min-width:512px)");
};
