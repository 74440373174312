import { ChefQuoteIntegration, Experience } from "@busie/api";
import { useUsers } from "@busie/core";
import {
  ArrowRight,
  Divider,
  FlexContainer,
  H1,
  H3,
  Loading,
  palette,
} from "@busie/ui-kit";
import { QuoteAndBookingStatus, dayjsExt } from "@busie/utils";
import React from "react";
import { useFetchQuoteIntegrations } from "~/QuotesAndBookingsPage/model";
import {
  OrganizationLogo,
  QnBStatusText,
} from "~/QuotesAndBookingsPage/shared/ui";
import { Subtitle } from "../Subtitle";

interface Props {
  id: string;
  status: QuoteAndBookingStatus;
  experiences: Experience[];
  bookingRequested: boolean;
  createdBy: string;
  integration?: ChefQuoteIntegration;
  expirationDate?: string;
}

export const Summary: React.FC<Props> = ({
  id,
  status,
  experiences,
  bookingRequested,
  createdBy,
  integration,
  expirationDate,
}) => {
  /**
   * TODO:
   * - [BUSIE-1725] As a user that can update a Quote in the Range Operator Portal, I can see the associated revenue shares of a Quote Integration's Source and Destination.
   */
  const {
    data: integrations,
    isLoading: isIntegrationsLoading,
    isFetching: isIntegrationsFetching,
  } = useFetchQuoteIntegrations();

  const {
    data: users = {},
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useUsers();

  const hasIntegration =
    integration && integration.SOURCE_ID && integration.DESTINATION_ID;

  return (
    <FlexContainer direction="column" align="flex-start" fullWidth px={2}>
      <Subtitle>Summary</Subtitle>

      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        fullWidth
      >
        <H3>ID</H3>
        <H3>{id}</H3>
      </FlexContainer>

      <Divider margin={1} />

      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        pt={2}
        fullWidth
      >
        <H3>Status</H3>
        <QnBStatusText status={status} />
      </FlexContainer>

      <Divider margin={1} />

      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        pt={2}
        fullWidth
      >
        <H3>Number of Trips</H3>
        <H3>{experiences.length}</H3>
      </FlexContainer>

      <Divider margin={1} />

      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        pt={2}
        fullWidth
      >
        <H3>Customer Requested Booking</H3>
        <H3 color={bookingRequested ? palette.green.main : palette.red.main}>
          {bookingRequested ? "Yes" : "No"}
        </H3>
      </FlexContainer>

      <Divider margin={1} />

      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        pt={2}
        fullWidth
      >
        <H3>Created By</H3>
        {isUsersFetching || isUsersLoading ? (
          <Loading size={24} padding="0px" />
        ) : (
          <H3>
            {createdBy.split("|")[0] === "auth0"
              ? users[createdBy]?.name ?? "Unknown User"
              : "Embedded Trip Planner"}
          </H3>
        )}
      </FlexContainer>

      <Divider margin={1} />

      {hasIntegration && (
        <>
          <FlexContainer
            direction="row"
            justify="space-between"
            align="center"
            pt={2}
            fullWidth
          >
            <H3>Path</H3>
            {isIntegrationsFetching || isIntegrationsLoading ? (
              <Loading size={24} padding="0px" />
            ) : (
              integrations &&
              ((
                <FlexContainer
                  direction="row"
                  justify="flex-end"
                  columnGap={2}
                  align="center"
                  fullWidth
                >
                  <FlexContainer direction="column" align="center" rowGap={1}>
                    <OrganizationLogo
                      src={
                        integrations?.organizationLogos[
                          integration.SOURCE_ID as string
                        ]
                      }
                    />
                  </FlexContainer>

                  <ArrowRight size={24} color={palette.black.main} />

                  <FlexContainer direction="column" align="center" rowGap={1}>
                    <OrganizationLogo
                      src={
                        integrations?.organizationLogos[
                          integration.DESTINATION_ID as string
                        ]
                      }
                    />
                  </FlexContainer>
                </FlexContainer>
              ) || <H3>Could not fetch integration data</H3>)
            )}
          </FlexContainer>
          <Divider margin={1} />
        </>
      )}

      {expirationDate && (
        <FlexContainer
          direction="row"
          justify="space-between"
          align="center"
          pt={2}
          fullWidth
        >
          <H3>Expiration Date</H3>
          <H3>{dayjsExt(expirationDate).format("dddd, L LT")}</H3>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
