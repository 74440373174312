import React from "react";

import AmountFieldStyled from "./styled/AmountFieldStyled";
import InputLabel from "../InputLabel";

import { MinusIcon, PlusIcon } from "../icons";
import { ErrorMessage } from "../typography";

interface Props {
  id?: string;
  label?: string;
  value?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  step?: number;
  onChange: (value?: number) => void;
  errorMessage?: string;
}

const AmountField: React.FC<React.PropsWithChildren<Props>> = ({
  step = 1,
  ...props
}: Props) => {
  const onIncrease = () => {
    if (props.value === props.maxValue) return;
    props.onChange(props.value ? props.value + step : step);
  };

  const onDecrease = () => {
    if (typeof props.value === "undefined" || props.value === props.minValue)
      return;
    props.onChange(props.value ? props.value - step : -step);
  };

  const handlerOnChange = (stringValue: string) => {
    if (stringValue === "") {
      props.onChange(props.minValue || 0);
    } else {
      if (Number.isInteger(+stringValue)) {
        const value = Number(stringValue);
        if (typeof props.minValue !== "undefined" && value < props.minValue)
          return;
        if (typeof props.maxValue !== "undefined" && value > props.maxValue)
          return;
        props.onChange(value);
      }
    }
  };
  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();
  return (
    <AmountFieldStyled>
      {props.label && <InputLabel htmlFor={props.id}>{props.label}</InputLabel>}
      <div className="field" aria-live="polite">
        <button
          type="button"
          onClick={onDecrease}
          aria-label="Decrease by one"
          aria-controls={props.id}
        >
          <MinusIcon />
        </button>
        <input
          id={props.id}
          type="number"
          step={step}
          value={props.value}
          min={props.minValue}
          max={props.maxValue}
          disabled={props.disabled}
          className="number-input"
          onChange={(e) => handlerOnChange(e.currentTarget.value)}
          onFocus={handleOnFocus}
          placeholder="0"
          aria-invalid={!!props.errorMessage}
        />
        <button
          type="button"
          onClick={onIncrease}
          aria-label="Increase by one"
          aria-controls={props.id}
        >
          <PlusIcon />
        </button>
      </div>
      <div aria-errormessage={props.id}>
        {props.errorMessage && (
          <ErrorMessage>{props.errorMessage}</ErrorMessage>
        )}
      </div>
    </AmountFieldStyled>
  );
};

export default AmountField;
