import { Button as BusieButton } from "@busie/ui-kit";
import React from "react";

interface Props {
  disabled: boolean;
  onClick: () => void;
  text?: string;
}

export const Button: React.FC<React.PropsWithChildren<Props>> = ({
  text = "Invoice Button",
  ...props
}) => (
  // Invoice Button will change depending on the status of an active invoice.
  <BusieButton typestyle="accent" {...props}>
    {text}
  </BusieButton>
);
