import { VehicleTypes, Amenity, VehicleType, Vehicle } from "@busie/utils";
import { makeAutoObservable } from "mobx";
import {
  createVehicle,
  createVehicleType,
  updateVehicle,
  updateVehicleType,
  VehicleFormData,
  VehicleTypeFormData,
} from "@busie/api";
import { FetchingStatus } from "@busie/core";
import { notificationStore } from "@busie/features";
import vehiclesStore from "./vehicles.store";

const DEFAULT_LICENSE_PLATE_NUMBER = "1ABC234";

class VehiclesFormStore {
  editMode = false;
  vehicleToEdit: Vehicle | null = null;
  vehicleType?: VehicleTypes = VehicleTypes.MOTOR_COACH;
  amount = 1;
  minimalTripSeat = 0;
  maximalTripSeat = 1;
  licensePlateNumbers: string[] = [DEFAULT_LICENSE_PLATE_NUMBER];
  amenities: Amenity[] = [];
  vehiclesFormFetchingStatus: FetchingStatus = "notFetched";
  // TODO: dispatchLocationId should become an input of the form, rather than a computed value from the token
  dispatchLocationId = "";
  spabCertifiedArray: boolean[] = [false];
  wheelchairAccessible = false;
  seatsRemovedPerWheelchair = 0;

  setVehicleType(vehicleType: VehicleTypes) {
    this.vehicleType = vehicleType;
  }

  setDispatchLocationId(id: string) {
    this.dispatchLocationId = id;
  }

  setEditMode(editMode: boolean) {
    this.editMode = editMode;
  }

  setVehicleToEdit(vehicle: Vehicle | null) {
    this.vehicleToEdit = vehicle;
  }

  initializeFromVehicleType() {
    if (this.vehicleToEdit && this.editMode) {
      this.setVehicleType(this.vehicleToEdit.vehicleType.type);
      this.setAmount(this.vehicleToEdit.vehicleType.length);
      this.setMaximalTripSeat(this.vehicleToEdit.vehicleType.seatsMax);
      this.setMinimalTripSeat(this.vehicleToEdit.vehicleType.seatsMin);
      this.setAmenities(this.vehicleToEdit.amenities);
    }
  }

  setAmount(amount: number) {
    if (amount < this.amount) {
      this.licensePlateNumbers = this.licensePlateNumbers.slice(0, amount);
      this.spabCertifiedArray = this.spabCertifiedArray.slice(0, amount);
    } else if (amount > this.amount) {
      this.licensePlateNumbers = this.licensePlateNumbers.concat(
        new Array(amount - this.amount).fill(DEFAULT_LICENSE_PLATE_NUMBER)
      );
      this.spabCertifiedArray = this.spabCertifiedArray.concat(
        new Array(amount - this.amount).fill(false)
      );
    }
    this.amount = amount;
  }

  setMinimalTripSeat(minimalTripSeat: number) {
    this.minimalTripSeat = minimalTripSeat;
  }

  setMaximalTripSeat(maximalTripSeat: number) {
    this.maximalTripSeat = maximalTripSeat;
  }

  setLicensePlateNumber(value: string, idx: number) {
    this.licensePlateNumbers[idx] = value;
  }

  setAmenities(amenities: Amenity[]) {
    this.amenities = amenities;
  }

  setLicensePlate(plate: string) {
    if (this.vehicleToEdit) {
      this.vehicleToEdit.licensePlate = plate;
    }
  }

  setSbapCertified(certified: boolean, index?: number) {
    if (this.vehicleToEdit) {
      this.vehicleToEdit.spabCertified = certified;
    } else if (typeof index !== "undefined") {
      this.spabCertifiedArray[index] = certified;
    }
  }

  setSeatsTotal(total: number) {
    if (this.vehicleToEdit) {
      this.vehicleToEdit.seatsTotal = total;
    }
  }

  setWheelchairAccessible(accessible: boolean) {
    if (this.vehicleToEdit) {
      this.vehicleToEdit.wheelchairAccessible = accessible;
    } else {
      this.wheelchairAccessible = accessible;
    }
  }

  setSeatsRemovedPerWheelchair(removed: number) {
    if (this.vehicleToEdit) {
      this.vehicleToEdit.seatsRemovedPerWheelchair = removed;
    } else {
      this.seatsRemovedPerWheelchair = removed;
    }
  }

  setDispatchLocationIdToEdit(locationId: string) {
    if (this.vehicleToEdit) {
      this.vehicleToEdit.dispatchLocationId = locationId;
    } else {
      this.dispatchLocationId = locationId;
    }
  }

  toggleAmenity(amenity: Amenity) {
    const resultArr = this.amenities.filter((a) => a !== amenity);
    if (resultArr.length === this.amenities.length) resultArr.push(amenity);
    this.amenities = resultArr;
  }

  toggleAmenityToEdit(amenity: Amenity) {
    if (this.vehicleToEdit?.amenities) {
      const resultArr = this.vehicleToEdit.amenities.filter(
        (a) => a !== amenity
      );
      if (resultArr.length === this.vehicleToEdit.amenities.length)
        resultArr.push(amenity);
      this.vehicleToEdit.amenities = resultArr;
    }
  }

  resetForm() {
    this.vehicleType = undefined;
    this.amount = 1;
    this.minimalTripSeat = 0;
    this.maximalTripSeat = 1;
    this.licensePlateNumbers = [DEFAULT_LICENSE_PLATE_NUMBER];
    this.amenities = [];
    this.vehiclesFormFetchingStatus = "notFetched";
    this.dispatchLocationId = "";
    this.spabCertifiedArray = [false];
    this.wheelchairAccessible = false;
    this.seatsRemovedPerWheelchair = 0;
  }

  getVehicleTypeFormPayload(): VehicleType {
    // TODO: most of the data here are mock
    // payload object has no id and organizationId fields
    // so we return it AS VehicleType
    return {
      type: this.vehicleType || 0,
      seatsMax: this.maximalTripSeat,
      seatsMin: this.minimalTripSeat,
      axles: 0,
      width: 0,
      height: 0,
      length: this.amount,
    } as VehicleType;
  }

  getVehicleFormPayload(
    licensePlate: string,
    spabCertified: boolean,
    vehicleTypeId: string
  ) {
    // TODO: most of the data here are mock
    return {
      vehicleTypeId,
      licensePlate,
      spabCertified,
      dispatchLocationId: this.dispatchLocationId,
      seatsTotal: this.maximalTripSeat,
      wheelchairAccessible: this.wheelchairAccessible,
      seatsRemovedPerWheelchair: this.seatsRemovedPerWheelchair,
      amenities: this.amenities,
    };
  }

  getVehicleFormPayloadOnEdit(): VehicleFormData | null {
    if (!this.vehicleToEdit) return null;
    return {
      vehicleTypeId: this.vehicleToEdit?.vehicleTypeId,
      licensePlate: this.vehicleToEdit?.licensePlate,
      dispatchLocationId: this.vehicleToEdit?.dispatchLocationId,
      seatsTotal: this.vehicleToEdit?.seatsTotal,
      wheelchairAccessible: this.vehicleToEdit?.wheelchairAccessible,
      spabCertified: this.vehicleToEdit?.spabCertified,
      seatsRemovedPerWheelchair: this.vehicleToEdit?.seatsRemovedPerWheelchair,
      amenities: this.vehicleToEdit?.amenities,
    };
  }

  async getExistingVehicleType(authToken: string): Promise<VehicleType | null> {
    const vehicleTypes = await vehiclesStore.fetchVehicleTypes(authToken);

    if (Array.isArray(vehicleTypes) && Number.isInteger(this.vehicleType)) {
      return (
        vehicleTypes.find((v: VehicleType) => v.type === this.vehicleType) ||
        null
      );
    }

    return null;
  }

  async submitForm(authToken: string) {
    try {
      this.vehiclesFormFetchingStatus = "fetching";

      const existingVehicleType = await this.getExistingVehicleType(authToken);
      let vehicleTypeId = "";

      if (existingVehicleType) {
        const vehicleTypeFormPayload = this.getVehicleTypeFormPayload();
        const needPatch =
          vehicleTypeFormPayload.seatsMax > existingVehicleType?.seatsMax ||
          vehicleTypeFormPayload.seatsMin < existingVehicleType.seatsMin;
        if (needPatch) {
          const requestPayload: Partial<VehicleType> = {
            ...existingVehicleType,
            seatsMax: Math.max(
              vehicleTypeFormPayload.seatsMax,
              existingVehicleType.seatsMax
            ),
            seatsMin: Math.min(
              vehicleTypeFormPayload.seatsMin,
              existingVehicleType.seatsMin
            ),
          };
          delete requestPayload.id;
          delete requestPayload.organizationId;
          await updateVehicleType(
            authToken,
            existingVehicleType.id,
            requestPayload as VehicleTypeFormData
          );
        }
        vehicleTypeId = existingVehicleType.id;
      } else {
        const data = await createVehicleType(
          authToken,
          this.getVehicleTypeFormPayload()
        );
        vehicleTypeId = data.id as string;
      }

      if (this.licensePlateNumbers.length > 0) {
        await Promise.all(
          this.licensePlateNumbers.map((plateNumber, index) => {
            return createVehicle(
              authToken,
              this.getVehicleFormPayload(
                plateNumber,
                this.spabCertifiedArray[index],
                vehicleTypeId
              )
            );
          })
        );
      }

      this.vehiclesFormFetchingStatus = "fetched";
    } catch (e) {
      notificationStore.setNotificationFromError(e);
      this.vehiclesFormFetchingStatus = "failedFetching";
    }
  }

  async submitEditForm(authToken: string, vehicleFormData: VehicleFormData) {
    try {
      this.vehiclesFormFetchingStatus = "fetching";

      if (this.vehicleToEdit) {
        await updateVehicle(authToken, this.vehicleToEdit?.id, vehicleFormData);
        vehiclesStore.updateVehicle(this.vehicleToEdit?.id, vehicleFormData);
        this.vehiclesFormFetchingStatus = "fetched";
      } else {
        this.vehiclesFormFetchingStatus = "failedFetching";
      }
    } catch (e) {
      notificationStore.setNotificationFromError(e);
      this.vehiclesFormFetchingStatus = "failedFetching";
    }
  }

  getMaximalTripError() {
    if (this.maximalTripSeat === undefined) {
      return "Maximal trip seat is required";
    }
    if (this.minimalTripSeat > this.maximalTripSeat) {
      return "Maximal trip seat cannot be less than minimal!";
    }
    if (this.maximalTripSeat < 1) {
      return "Maximal trip seat cannot be less than 1!";
    }
    return null;
  }

  getMinimalTripValid() {
    return (
      this.minimalTripSeat !== undefined &&
      this.minimalTripSeat <= this.maximalTripSeat &&
      this.minimalTripSeat >= 0
    );
  }

  getVehiclesAmountValid() {
    return Number.isInteger(this.amount) && this.amount > 0;
  }

  getVehicleTypeValid() {
    return this.vehicleType !== undefined;
  }

  getLicensePlateNumbersValid() {
    return this.licensePlateNumbers.reduce(
      (acc, number) => acc && !!number,
      true
    );
  }

  getFormIsValid() {
    return (
      !this.getFormIsSubmitting() &&
      this.getVehiclesAmountValid() &&
      this.getMinimalTripValid() &&
      this.getMaximalTripError() === null &&
      this.getVehicleTypeValid() &&
      this.getLicensePlateNumbersValid()
    );
  }

  getEditFormIsValid() {
    return this.vehicleToEdit && this.vehicleToEdit?.licensePlate;
  }

  getFormIsSubmitting() {
    return this.vehiclesFormFetchingStatus === "fetching";
  }

  getFormIsSubmitted() {
    return this.vehiclesFormFetchingStatus === "fetched";
  }

  constructor() {
    makeAutoObservable(this);
    this.submitForm = this.submitForm.bind(this);
  }
}

const vehiclesFormStore = new VehiclesFormStore();

export default vehiclesFormStore;
