import { styled } from "@mui/system";

import { FlexContainer } from "@busie/ui-kit";

export const NotificationBlockStyled = styled(FlexContainer)((props) => {
  const palette = props.theme.palette;
  const { index = 0 } = props;
  return {
    position: "absolute",
    top: 0,
    left: 0,
    background: palette.black.plus85,
    border: `1px solid ${palette.black.plus70}`,
    boxSizing: "border-box",
    borderRadius: "4px",
    margin: "20px 0 15px",
    width: "95%",
    transform: `translate(${index * 10}px, ${index * 10}px)`,
    zIndex: `${100 - index}`,
  };
});

export const Icon = styled("img")(() => {
  return {
    width: "auto",
    maxWidth: "156px",
    height: "auto",
    maxHeight: "42px",
  };
});

export const MessageBlock = styled("div")(() => {
  return {};
});

export const Controls = styled(FlexContainer)(() => {
  return {};
});
