import { Button } from "@busie/ui-kit";

interface Props {
  onClick: () => void;
  disabled: boolean;
}
export const SendContractButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  disabled,
}) => (
  <Button typestyle="primary" onClick={onClick} disabled={disabled}>
    Send E-Contract
  </Button>
);
