import { dayjsExt } from "@busie/utils";

export const getInvoiceDaysUntilDue = (
  createdAt: Date,
  daysUntilDue: number
) => {
  const today = new Date();
  const invoiceCreationDate = dayjsExt(createdAt);
  const dueDate = invoiceCreationDate.add(daysUntilDue, "day");
  return dueDate.diff(today, "day");
};
