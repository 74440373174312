import { DatePicker } from "@busie/ui-kit";
import store from "~/vehicles/store";
import { dayjsExt } from "@busie/utils";
import { observer } from "mobx-react";
import React from "react";

const CalendarDatePicker: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const calendarPeriod = store.calendarViewPeriod;
    if (calendarPeriod === "month") {
      return (
        <DatePicker
          format={"year-month"}
          minDate={dayjsExt().subtract(5, "year").toDate()}
          views={["month", "year"]}
          value={store.selectedCalendarMonth}
          onChange={(e) => {
            store.setSelectedCalendarMonth(e);
          }}
          hideErrorSpace
          disablePast
        />
      );
    }
    if (calendarPeriod === "day") {
      return (
        <DatePicker
          format={"date"}
          minDate={dayjsExt().subtract(5, "year").toDate()}
          views={["day", "month", "year"]}
          value={store.selectedCalendarDay}
          onChange={(e) => {
            store.setSelectedCalendarDay(e);
          }}
          hideErrorSpace
          disablePast
        />
      );
    }
    if (calendarPeriod === "week") {
      return (
        <DatePicker
          format={"year-month"}
          minDate={dayjsExt().subtract(5, "year").toDate()}
          views={["day", "month", "year"]}
          value={store.selectedCalendarWeek}
          onChange={(e) => {
            store.setSelectedCalendarWeek(e);
          }}
          hideErrorSpace
          disablePast
        />
      );
    }
    return null;
  }
);

export default CalendarDatePicker;
