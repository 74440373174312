import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  Divider,
  FlexContainer,
  palette,
  NoteText,
  Text,
  TextInput,
  FormContainer,
} from "@busie/ui-kit";

import { schema } from "./schema";

interface Props {
  email: string;
  onSubmit: () => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  email,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<{ confirm?: string }>({
    mode: "onChange",
    defaultValues: {
      confirm: undefined,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Send Invoice">
      <Text>Are you sure you want to send this invoice?</Text>
      <NoteText>
        This will automatically send an email to the customer associated with
        the invoice{" "}
        <em>
          <b>({email})</b>
        </em>{" "}
        with a link to the Hosted Invoice Page. The customer will have the
        option to pay the invoice via ACH or Card. You will also have the option
        to mark this invoice as "Paid Offline".
      </NoteText>

      <br />

      <NoteText color={palette.red.main}>
        This action cannot be undone.
      </NoteText>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer
          align="flex-start"
          direction="column"
          columnGap={2}
          fullWidth
        >
          <Controller
            name="confirm"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Type 'confirm' to continue"
                fullWidth
                errorMessage={errors.confirm?.message}
              />
            )}
          />
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Send Invoice
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
