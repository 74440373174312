import React, { useState } from "react";
import { Icon } from "@mui/material";
import { Styled } from "./styled";
import { DeleteIcon, EditIcon, FlexContainer, H3 } from "@busie/ui-kit";

interface Props {
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export const DecoratedListItem: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  title,
  children,
}) => {
  const [controlsVisible, setControlsVisible] = useState(false);

  return (
    <Styled
      onMouseEnter={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
    >
      <FlexContainer columnGap={1.5} align="center" sx={{ maxWidth: "70%" }}>
        {icon}
        <FlexContainer direction="column" justify="center">
          <H3 margin="0">{title}</H3>
          {children}
        </FlexContainer>
      </FlexContainer>
      {controlsVisible && (
        <FlexContainer columnGap={2}>
          <Icon sx={{ cursor: "pointer" }} onClick={() => undefined}>
            <EditIcon />
          </Icon>
          <Icon sx={{ cursor: "pointer" }} onClick={() => undefined}>
            <DeleteIcon />
          </Icon>
        </FlexContainer>
      )}
    </Styled>
  );
};
