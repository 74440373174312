import { Experience } from "@busie/api";
import { DrawerDetailsBlock, Text, palette } from "@busie/ui-kit";
import React, { useState } from "react";

interface Props {
  trip: Experience;
}

const COLLAPSED_MAX_LENGTH = 500;

export const AdditionalInfo: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
}) => {
  const [expanded, setExpanded] = useState(false);

  const showExpand = trip.ADDITIONAL_INFORMATION
    ? trip.ADDITIONAL_INFORMATION.length > COLLAPSED_MAX_LENGTH
    : false;

  return trip.ADDITIONAL_INFORMATION ? (
    <DrawerDetailsBlock
      name="Customer Provided Additional Information"
      collapsible
    >
      <Text weight={400} color={palette.black.main}>
        {expanded
          ? trip.ADDITIONAL_INFORMATION
          : trip.ADDITIONAL_INFORMATION.slice(0, COLLAPSED_MAX_LENGTH - 1)}

        {showExpand && (
          <span
            style={{
              cursor: "pointer",
              fontWeight: 700,
              color: palette.black.plus40,
              zIndex: 2,
              whiteSpace: "nowrap",
              boxShadow: expanded
                ? ""
                : "-20px 0px 10px -1px rgba(255,255,255,.8)",
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? " Show less" : "...Show more"}
          </span>
        )}
      </Text>
    </DrawerDetailsBlock>
  ) : null;
};
