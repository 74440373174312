import { H3, palette } from "@busie/ui-kit";
import {
  addDays,
  differenceInMinutes,
  format,
  isToday,
  startOfDay,
  startOfWeek,
} from "date-fns";
import React from "react";
import { DayHeaderStyled } from "~/vehicles/Vehicles/CalendarView/styled/CalendarWeekView.styled";
import DayNumberStyled from "~/vehicles/Vehicles/CalendarView/styled/dayNumber.styled";
import AvailabilityPercentage from "../AvailabilityPercentage";

interface Props {
  i: number;
  selectedWeek: Date;
  availability: number;
  onClick: () => void;
}

const HeaderCell: React.FC<React.PropsWithChildren<Props>> = ({
  i,
  selectedWeek,
  availability,
  onClick,
}) => {
  const day = addDays(startOfWeek(selectedWeek), i);
  const isDateToday = isToday(day);
  const [percentagePassed, setPercentagePassed] = React.useState(
    differenceInMinutes(new Date(), startOfDay(day))
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      // update the progress dot every minute
      setPercentagePassed(differenceInMinutes(new Date(), startOfDay(day)));
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DayHeaderStyled
      date={day}
      percentagePassed={percentagePassed}
      onClick={onClick}
    >
      <H3 margin="0" color={palette.black.plus40}>
        {format(day, "EEE")}
      </H3>
      <DayNumberStyled isToday={isDateToday}>
        {format(day, "d")}
      </DayNumberStyled>
      <AvailabilityPercentage availability={availability} isMobile={false} />
    </DayHeaderStyled>
  );
};

export default HeaderCell;
