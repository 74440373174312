import React from "react";

import { Button, FlexContainer, H2, Text } from "@busie/ui-kit";
import { centsToDollars, currencyFormatter } from "@busie/utils";

interface Props {
  price: number;
  controls: React.ReactNode;
}

/**
 *
 * @param props.price: price of the trip in USD cents
 * @param props.controls: controls to be rendered in the footer
 * @returns
 */
export const Footer: React.FC<Props> = ({ controls, price }) => {
  return (
    <FlexContainer direction="row" justify="space-between" pt={2} fullWidth>
      <FlexContainer direction="column" rowGap={0} align="flex-start" fullWidth>
        <Text fontSize="14px" weight={500}>
          Trip Subtotal
        </Text>
        <H2 weight={600}>{currencyFormatter(centsToDollars(price))}</H2>
      </FlexContainer>

      <FlexContainer direction="row" columnGap={2} justify="flex-end" fullWidth>
        {controls}
      </FlexContainer>
    </FlexContainer>
  );
};
