import { palette } from "@busie/ui-kit";
import React from "react";

interface Props {
  value: string;
}

export const Cell: React.FC<Props> = ({ value }) => {
  return <span style={{ color: palette.red.plus5 }}>{value}</span>;
};
