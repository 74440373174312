import { updateCustomerMetadata } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";
import { useMutation } from "react-query";
import { customersModel } from "~/CustomersPage/entity";

export const useUpdateCustomerMetadata = (id: string, metadataId: string) => {
  const token = useCustomerAuthToken();

  return useMutation(async (data: { key: string; value: string }) => {
    const result = await updateCustomerMetadata(token, id, metadataId, data);

    customersModel.invalidateCustomersQueries();
    return result;
  });
};
