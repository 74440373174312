import React from "react";
import Autocomplete, {
  ReactGoogleAutocompleteInputProps,
} from "react-google-autocomplete";
import { FieldError, RefCallBack } from "react-hook-form";

import { env, GooglePlace } from "@busie/utils";

import PlaceFieldBlockStyled from "./PlaceFieldBlockStyled";
import InputLabel from "../InputLabel";
import IconButton from "../IconButton";
import { ErrorMessage } from "../typography";
import { DeleteIcon } from "../icons";

interface Props extends Omit<ReactGoogleAutocompleteInputProps, "onChange"> {
  label?: string;
  placeholder?: string;
  onRemove?: () => void;
  startAdornment?: React.ReactNode;
  error?: FieldError;
  value?: GooglePlace;
  onChange: (value: GooglePlace) => void;
  ref: RefCallBack;
  width?: string;
  disabled?: boolean;
}

const PlaceFieldHookForm: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  placeholder,
  onRemove,
  startAdornment,
  error,
  value,
  onChange,
  ref,
  width = "100%",
  disabled = false,
}) => {
  const apiKey = env("BUSIE_GOOGLE_MAPS_API_KEY");
  return (
    <PlaceFieldBlockStyled margin="0" width={width}>
      {label && <InputLabel>{label}</InputLabel>}
      <div className="field-block">
        <div className="input-block">
          {startAdornment && (
            <div className="start-adornment">{startAdornment}</div>
          )}
          <Autocomplete
            ref={ref}
            apiKey={apiKey}
            placeholder={placeholder}
            disabled={disabled}
            options={{
              types: [],
              fields: [
                "address_components",
                "geometry.location",
                "place_id",
                "formatted_address",
              ],
              componentRestrictions: { country: ["us", "ca", "mx"] },
            }}
            style={{
              paddingLeft: startAdornment ? "27px" : "",
            }}
            defaultValue={value?.formatted_address}
            onPlaceSelected={onChange}
          />
        </div>
        {onRemove && (
          <IconButton onClick={onRemove} type="button" icon={<DeleteIcon />} />
        )}
      </div>
      <ErrorMessage>{error?.message}</ErrorMessage>
    </PlaceFieldBlockStyled>
  );
};

export default PlaceFieldHookForm;
