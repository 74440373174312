import { styled } from "@mui/system";

import { FlexContainer } from "@busie/ui-kit";

export const StackedBlockStyled = styled(FlexContainer)(() => {
  return {
    position: "relative",
    width: "100%",
    height: "180px",
  };
});
