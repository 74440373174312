import { Box } from "@mui/system";
import React from "react";
import { FlexContainer } from "../../FlexContainer";
import theme from "../../theme";
import { StatusTextStyled } from "./StatusTextStyled";

export const StatusTextWithDot: React.FC<
  React.PropsWithChildren<{
    color: string;
    status: string;
  }>
> = ({ color, status }) => {
  return (
    <FlexContainer align="center" columnGap={1}>
      <Box
        sx={{
          backgroundColor: color,
          border: `1px solid ${color}`,
          borderRadius: "44px",
          height: theme?.spacing(1),
          width: theme?.spacing(1),
          minWidth: theme?.spacing(1),
        }}
      />
      <StatusTextStyled color={color}>{status}</StatusTextStyled>
    </FlexContainer>
  );
};
