import { CreateUpdateMetadataPayload, updateGroupMetadata } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";
import { useMutation } from "react-query";
import { customersModel } from "~/CustomersPage/entity";

export const useUpdateGroupMetadata = (groupId: string, metadataId: string) => {
  const token = useCustomerAuthToken();

  return useMutation(async (data: CreateUpdateMetadataPayload) => {
    const result = await updateGroupMetadata(token, groupId, metadataId, data);

    customersModel.invalidateCustomersQueries();

    return result;
  });
};
