import React, { SyntheticEvent } from "react";

import { TableProps } from "@mui/material/Table";
import { DeleteIcon, EditIcon, FlexContainer } from "@busie/ui-kit";
import { Box, Icon } from "@mui/material";
import { Driver } from "@busie/utils";
import { ItemContainer, Title, Value } from "./ui";

interface Props extends TableProps {
  drivers: Driver[];
  onDriverClicked: (driver: Driver) => void;
  onDeleteDriver: (driver: Driver) => void;
  onEditDriver: (driver: Driver) => void;
}

export const MobileList: React.FC<React.PropsWithChildren<Props>> = ({
  drivers,
  onDriverClicked,
  onEditDriver,
  onDeleteDriver,
}) => {
  return (
    <FlexContainer direction="column" rowGap={1.5} sx={{ margin: "13px 0px" }}>
      {drivers.map((driver) => {
        return (
          <ItemContainer onClick={() => onDriverClicked(driver)}>
            <FlexContainer justify="space-between" align="stretch">
              <FlexContainer direction="column" justify="space-between">
                <Box>
                  <Title>Name</Title>
                  <Value>
                    {driver.firstName} {driver.lastName}
                  </Value>
                </Box>
                <Box>
                  <Title>Email</Title>
                  <Value>{driver.email || "-"}</Value>
                </Box>
                <Box>
                  <Title>Phone Number</Title>
                  <Value>{driver.phoneNumber || "-"}</Value>
                </Box>
              </FlexContainer>
              <FlexContainer direction="column" justify="space-between">
                <Icon
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    onEditDriver(driver);
                  }}
                >
                  <EditIcon />
                </Icon>
                <Icon
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    onDeleteDriver(driver);
                  }}
                >
                  <DeleteIcon />
                </Icon>
              </FlexContainer>
            </FlexContainer>
          </ItemContainer>
        );
      })}
    </FlexContainer>
  );
};
