import React, { useEffect } from "react";
import PhoneInput, {
  DefaultInputComponentProps,
} from "react-phone-number-input/react-hook-form-input";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { Driver } from "@busie/utils";
import {
  DeleteIcon,
  FlexContainer,
  FormContainer,
  IconButton,
  PlusIcon,
} from "@busie/ui-kit";
import { Button, palette, TextInput, theme } from "@busie/ui-kit";
import { DriverFormData, DEFAULT_FORM_VALUES, schema } from "./model";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props {
  onCancel: () => void;
  onSubmit: (data: DriverFormData) => void;
  driver?: Driver;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  driver,
  onCancel,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<DriverFormData>({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "metadata",
  });

  useEffect(() => {
    if (driver) {
      const { firstName, lastName, email, phoneNumber, metadata } = driver;

      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
      setValue("phoneNumber", phoneNumber);
      setValue(
        "metadata",
        Object.entries(metadata).map(([name, value]) => ({ name, value }))
      );
    }
  }, [driver, setValue]);

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title={`${driver ? "Edit" : "Add"} driver`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="firstName"
          rules={{
            required: "This field is required!",
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label="First Name"
              required
              sx={{
                margin: 0,
                marginTop: theme?.spacing(1),
                ".MuiOutlinedInput-root": {
                  height: 40,
                },
                fieldset: {
                  borderColor: palette?.black?.plus70,
                },
              }}
              errorMessage={errors.firstName?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          rules={{
            required: "This field is required!",
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Last Name"
              required
              sx={{
                margin: 0,
                marginTop: theme?.spacing(1),
                ".MuiOutlinedInput-root": {
                  height: 40,
                },
                fieldset: {
                  borderColor: palette?.black?.plus70,
                },
              }}
              errorMessage={errors.lastName?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={{
            required: "This field is required!",
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Email"
              required
              sx={{
                margin: 0,
                marginTop: theme?.spacing(1),
                ".MuiOutlinedInput-root": {
                  height: 40,
                },
                fieldset: {
                  borderColor: palette?.black?.plus70,
                },
              }}
              errorMessage={errors.email?.message}
            />
          )}
        />

        <PhoneInput
          defaultCountry="US"
          control={control}
          label="Phone Number"
          name="phoneNumber"
          rules={{ required: true }}
          inputComponent={
            TextInput as DefaultInputComponentProps["inputComponent"]
          }
          errorMessage={errors.phoneNumber?.message}
          required
        />

        {fields.map((field, index) => (
          <FlexContainer
            key={field.id}
            direction="row"
            justify="space-between"
            align="center"
          >
            <TextInput
              {...register(`metadata.${index}.name`, { required: true })}
              label="Name"
              required
              errorMessage={errors.metadata?.[index]?.name?.message}
            />
            <TextInput
              {...register(`metadata.${index}.value`, { required: true })}
              label="Value"
              errorMessage={errors.metadata?.[index]?.value?.message}
              required
            />
            <IconButton icon={<DeleteIcon />} onClick={() => remove(index)} />
          </FlexContainer>
        ))}

        <Button
          typestyle="tertiary"
          startIcon={<PlusIcon />}
          size="small"
          onClick={() => append({ name: "", value: "" })}
        >
          Add Custom Field
        </Button>

        <div className="form-submit-control">
          <Button typestyle="secondary" type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" disabled={!(isDirty && isValid)}>
            Save
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
