import React, { useEffect, useState } from "react";

import {
  Menu as BusieMenu,
  IconButton,
  MenuItem,
  MoreIcon,
} from "@busie/ui-kit";
import { InvoiceStatus } from "@busie/utils";
import {
  MARK_PAID_INVOICE_SET,
  PREVIEW_INVOICE_SET,
  VOID_INVOICE_SET,
} from "./constants";

interface Props {
  status: InvoiceStatus;
  onMarkPaid: () => void;
  onPreview: () => void;
  onVoid: () => void;
}

export const OverflowMenu: React.FC<Props> = ({
  status,
  onMarkPaid,
  onPreview,
  onVoid,
}) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    const items: MenuItem[] = [];
    if (MARK_PAID_INVOICE_SET.has(status)) {
      items.push({ content: "Mark As Paid", action: onMarkPaid });
    }

    if (PREVIEW_INVOICE_SET.has(status)) {
      items.push({ content: "Preview", action: onPreview });
    }

    if (VOID_INVOICE_SET.has(status)) {
      items.push({ content: "Void", action: onVoid });
    }

    setMenuItems(items);
  }, [status, onMarkPaid, onPreview, onVoid]);

  return (
    <BusieMenu
      right="0%"
      items={menuItems}
      toggler={<IconButton icon={<MoreIcon />} />}
    />
  );
};
