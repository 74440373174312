import { QuoteAndBookingStatus } from "@busie/utils";
import {
  ACCEPT_QUOTE_MODAL_COPY,
  DECLINE_QUOTE_MODAL_COPY,
  INVALID_ACTION_MODAL_COPY,
  REOPEN_QUOTE_MODAL_COPY,
  SEND_QUOTE_MODAL_COPY,
} from "./constants";

export const getModalCopy = (
  newStatus: QuoteAndBookingStatus
): { title: string; text: string; note: string; button: string } => {
  let result = INVALID_ACTION_MODAL_COPY;

  switch (newStatus) {
    case QuoteAndBookingStatus.CONFIRMED:
      result = SEND_QUOTE_MODAL_COPY;
      break;
    case QuoteAndBookingStatus.DECLINED:
      result = DECLINE_QUOTE_MODAL_COPY;
      break;
    case QuoteAndBookingStatus.ACCEPTED:
      result = ACCEPT_QUOTE_MODAL_COPY;
      break;
    case QuoteAndBookingStatus.PENDING:
      result = REOPEN_QUOTE_MODAL_COPY;
      break;
    default:
      break;
  }

  return result;
};
