import React from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { useUsers } from "@busie/core";
import {
  FlexContainer,
  TextInput,
  Button,
  SendIcon,
  IconButton,
  palette,
} from "@busie/ui-kit";
import { Comment } from "@busie/api";
import { Avatar } from "@mui/material";
import ReservationComment from "./ReservationComment";

interface Props {
  comments: Comment[];
  onAddComment: (text: string) => void;
}

const CommentsBlock: React.FC<React.PropsWithChildren<Props>> = ({
  comments,
  onAddComment,
}) => {
  const [isCommentsExpanded, setIsCommentsExpanded] = React.useState(false);
  const [newComment, setNewComment] = React.useState("");
  const { user } = useAuth0<User>();
  const { data: usersMap = {} } = useUsers();

  const sortedComments = comments.sort(
    (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
  );
  const firstThreeComments = sortedComments.slice(0, 3);
  const commentsToShow = isCommentsExpanded
    ? sortedComments
    : firstThreeComments;

  const addComment = () => {
    onAddComment(newComment);
    setNewComment("");
  };
  return (
    <>
      {commentsToShow.map((comment) => (
        <ReservationComment
          key={comment.id}
          {...comment}
          username={usersMap[comment.userId]?.name || "User"}
          picture={usersMap[comment.userId]?.picture}
        />
      ))}
      {sortedComments.length > 3 && (
        <FlexContainer
          sx={{ margin: "0 0 15px -16px", width: "calc(100% + 16px)" }}
        >
          <Button
            typestyle="secondary"
            sx={{ flexGrow: 1 }}
            onClick={() => setIsCommentsExpanded((value) => !value)}
          >
            Show {isCommentsExpanded ? "Less" : "More"}
          </Button>
        </FlexContainer>
      )}
      <FlexContainer columnGap={2} sx={{ marginLeft: "-16px" }}>
        <Avatar alt={user?.name} src={user?.picture} />
        <TextInput
          fullWidth
          placeholder="Your comment"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          endAdornment={
            <IconButton
              onClick={addComment}
              icon={<SendIcon color={palette.black.plus30} />}
            />
          }
          sx={{ paddingRight: 0 }}
        />
      </FlexContainer>
    </>
  );
};

export default CommentsBlock;
