import React from "react";
import { observer } from "mobx-react-lite";
import pluralize from "pluralize";

import { ListControlsButtons } from "./ListControlsButtons";

import { ConfirmationModal } from "@busie/ui-kit";
import { getStatusQueryParam } from "~/QuotesAndBookingsPage/shared/lib";

interface Props {
  // TODO: probably pass selected items and all items as a prop, along with controls for select/deselect all and onListUpdate
  store: any;
}

export const ListControls: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ store }) => {
    const selectedItemsQty = store.selectedItems.length;
    const handlerSelectAll = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      e.preventDefault();
      store.addSelectedItems(store.items.map((item: any) => item._id));
    };

    const handlerDeselectAll = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      e.preventDefault();
      store.clearSelectedItems();
    };

    if (!selectedItemsQty) return null;

    const bookingStatus = getStatusQueryParam();
    return (
      <ConfirmationModal>
        <div className="content-block">
          <div className="items-count-label">
            {`${selectedItemsQty} ${pluralize(
              "item",
              selectedItemsQty
            )} selected`}
          </div>
          <div className="controls">
            <a href="#select-all" onClick={(e) => handlerSelectAll(e)}>
              Select all
            </a>
            <a
              href="#deselect-all"
              className="danger"
              onClick={(e) => handlerDeselectAll(e)}
            >
              Deselect all
            </a>
          </div>
        </div>
        <div className="controls-block">
          <ListControlsButtons
            onListUpdate={() => store.setItems()}
            quotesAuthToken={store.authTokens["quotes"]}
            selectedIds={store.selectedItems}
            bookingStatus={`${bookingStatus}`}
          />
        </div>
      </ConfirmationModal>
    );
  }
);
