import React from "react";
import cx from "classnames";

import { FlexContainer, H2, H3, H4 } from "@busie/ui-kit";

import { BlockContainer } from "~/QuotesAndBookingsPage/shared/ui";
import { dayjsExt } from "@busie/utils";

interface Props {
  title: string;
  addresses: [React.ReactNode, React.ReactNode];
  departureDatetime?: string;
  arrivalDatetime?: string;
}

const DATE_CUSTOM_FORMAT = "dddd, L [at] LT";

export const Container: React.FC<Props> = ({
  addresses,
  title,
  departureDatetime,
  arrivalDatetime,
}) => {
  /**
   * TODO: [BUSIE-1738] Consolidate Leg Container component with similar components
   * e.g. apps/fe-main/src/pages/QuotesAndBookingsPage/widgets/TripDetailsDrawer/ui/Legs/index.tsx
   */
  return (
    <BlockContainer sx={{ width: "100%" }}>
      <FlexContainer align="flex-start" direction="column" fullWidth>
        <H3>{title}</H3>

        <div
          className={cx({
            departures: true,
            end: false,
            start: true,
            last: false,
            first: true,
          })}
          style={{ width: "100%" }}
        >
          <FlexContainer
            direction="column"
            align="flex-start"
            rowGap={0}
            className={cx({
              origin: true,
              start: true,
            })}
            fullWidth
          >
            {addresses[0]}
            {departureDatetime && (
              <H4>
                Departing:{" "}
                {dayjsExt(departureDatetime).format(DATE_CUSTOM_FORMAT)}
              </H4>
            )}
          </FlexContainer>

          <div
            className={cx({
              destination: true,
              end: true,
            })}
          >
            <FlexContainer
              direction="column"
              align="flex-start"
              rowGap={0}
              fullWidth
            >
              {addresses[1]}
              {arrivalDatetime && (
                <H4>
                  Estimated Arrival:{" "}
                  {dayjsExt(arrivalDatetime).format(DATE_CUSTOM_FORMAT)}
                </H4>
              )}
            </FlexContainer>
          </div>
        </div>
      </FlexContainer>
    </BlockContainer>
  );
};
