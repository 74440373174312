import { OrganizationMemberWithAvatar, Text, palette } from "@busie/ui-kit";
import React from "react";

interface Props {
  assignee: { name: string; imgSrc: string } | undefined;
}

export const Assignee: React.FC<Props> = ({ assignee }) =>
  assignee ? (
    <OrganizationMemberWithAvatar
      imgSrc={assignee.imgSrc}
      name={assignee.name}
    />
  ) : (
    <Text color={palette.red.main}>Unassigned</Text>
  );
