import React from "react";

interface Props {
  color?: string;
  className?: string;
}

export const SendIcon: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.32263 19.8781L19.7516 13.1791C20.7496 12.7161 20.7496 11.2821 19.7516 10.8191L5.32263 4.12205C4.28763 3.64205 3.19263 4.66005 3.57963 5.74305L5.81263 11.9971L3.57963 18.2581C3.19263 19.3401 4.28763 20.3581 5.32263 19.8781Z"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M5.80957 12H20.4996"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
