import { SchoolIcon, TooltipIcon } from "@busie/ui-kit";
import { Tooltip } from "@mui/material";

export const SPAB = () => {
  return (
    <Tooltip
      title="One or more of the Trips contained in this quote are transporting K-12 School Pupils"
      placement="top"
    >
      <TooltipIcon>
        <SchoolIcon size={24} />
      </TooltipIcon>
    </Tooltip>
  );
};
