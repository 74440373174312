import { styled } from "@mui/material/styles";

import { Theme } from "@mui/system";

import {
  default as MuiTableCell,
  tableCellClasses,
  TableCellProps,
} from "@mui/material/TableCell";

interface Props extends TableCellProps {
  theme?: Theme;
}

const TableCellStyled = styled(MuiTableCell)((props: Props) => {
  const palette = props.theme?.palette;
  return {
    "&": {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "100%",
      padding: "11px 16px 12px 0",
    },
    "&.has-action": {
      cursor: "pointer",
    },
    [`&.${tableCellClasses.paddingCheckbox}`]: {
      padding: "0 0 0 17px",
    },
    [`&.${tableCellClasses.head}`]: {
      color: palette?.black?.main,
    },
    [`&.${tableCellClasses.body}`]: {
      color: palette?.black?.plus10,
    },
  };
});

export default TableCellStyled;
