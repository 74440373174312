import { styled } from "@mui/system";

// import { Theme } from "@mui/system";

// interface Props {
//   theme?: Theme;
// }

export const StatusBlockStyled = styled("div")(() => {
  // const palette = props.theme?.palette;
  return {
    "&": {},
  };
});
