import { FetchingStatus } from "@busie/core";
import { makeAutoObservable, runInAction } from "mobx";
import {
  dayjsExt,
  DayjsExt,
  DateRange,
  Vehicle,
  VehicleType,
  VehicleStat,
  Reservation,
  VehiclesFilter,
} from "@busie/utils";
import {
  VehiclesPageViewType,
  ReservationPopup,
  fetchVehicleTypes,
  fetchVehicles,
  fetchReservations,
  createReservation as createReservationAction,
  ReservationVehicleType,
  deleteVehicleType,
  deleteVehicle,
  VehicleFormData,
  getAvailabilityPercentage,
  editReservation,
} from "@busie/api";

import { reservationPopupInitialState } from "./initialState";
import { validateReservationForm } from "./helpers";
import { vehicleStatsMock } from "./vehicles.mock";
import { notificationStore } from "@busie/features";
import { VEHICLES_PER_PAGE } from "./constants";
import {
  addDays,
  addMonths,
  addWeeks,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";

export interface VehiclesStoreType {
  vehicles: Vehicle[];
}

interface ReservationsFilter {
  status?: string;
  vehicleTypeId?: string;
  startAt?: Date;
  endAt?: Date;
}

class VehiclesStore implements VehiclesStoreType {
  fetchingStatus: FetchingStatus = "notFetched";
  authToken = "";
  vehicles: Vehicle[] = [];
  currentFleetItems: Vehicle[] = [];
  vehiclesTotal = 0;
  vehiclesNextOffset: number | null = null;
  vehicleTypes: VehicleType[] = [];
  viewMode: VehiclesPageViewType = "calendar";
  selectedCalendarMonth: Date = new Date();
  selectedCalendarWeek: Date = new Date();
  selectedCalendarDay: Date = new Date();
  reservations: Reservation[] = [];
  reservationsFetchingStatus: FetchingStatus = "notFetched";
  reservationPopup: ReservationPopup = reservationPopupInitialState;
  vehicleStats: VehicleStat[] = vehicleStatsMock;
  reservationsFilter: ReservationsFilter = {};
  vehiclesFilter: Record<string, unknown> = {};
  availabilityPercentage: number[] = [];
  reservationEditingFetchingStatus: FetchingStatus = "notFetched";
  calendarViewPeriod: "month" | "week" | "day" = "month";

  constructor() {
    makeAutoObservable(this);
    this.setSelectedCalendarMonth = this.setSelectedCalendarMonth.bind(this);
    this.setSelectedDateRange = this.setSelectedDateRange.bind(this);
    this.resetReservationForm = this.resetReservationForm.bind(this);
    this.createReservation = this.createReservation.bind(this);
    this.closeReservationPopup = this.closeReservationPopup.bind(this);
    this.fetchAvailabilityPercentage =
      this.fetchAvailabilityPercentage.bind(this);
  }

  get sortedVehicles() {
    return [...this.currentFleetItems].sort(
      (a, b) => a.vehicleType.type - b.vehicleType.type
    );
  }

  get selectedDateRange(): DateRange {
    return [
      dayjsExt(this.reservationsFilter.startAt) || null,
      dayjsExt(this.reservationsFilter.endAt) || null,
    ];
  }

  setAuthToken(token: string): void {
    this.authToken = token;
  }

  async setVehicles(): Promise<void> {
    this.fetchingStatus = "fetching";
    try {
      const res = await fetchVehicles(this.authToken);
      this.vehicles = res.resultSet;
      this.vehiclesTotal = res.total;
      this.vehiclesNextOffset = res.next;
      this.fetchingStatus = "fetched";
    } catch (e) {
      this.fetchingStatus = "failedFetching";
      notificationStore.setNotificationFromError(e);
    }
  }

  setReservationsFilter({
    status,
    vehicleTypeId,
    startAt,
    endAt,
  }: Partial<ReservationsFilter> = {}) {
    const filter = {
      ...this.reservationsFilter,
      startAt,
      endAt,
    };

    if (status != null) {
      filter.status = status === "all" ? undefined : status;
    }

    if (vehicleTypeId != null) {
      filter.vehicleTypeId =
        vehicleTypeId === "all" ? undefined : vehicleTypeId;
    }

    this.reservationsFilter = { ...this.reservationsFilter, ...filter };

    this.fetchReservations();
    this.fetchAvailabilityPercentage();
  }

  removeVehicles() {
    this.vehicles = [];
  }

  setView(viewMode: VehiclesPageViewType) {
    this.viewMode = viewMode;
    if (viewMode === "collapsed" || viewMode === "list") {
      this.setSelectedDateRange([null, null]);
    }
  }

  setSelecetedCalendarPeriod(date: Date) {
    if (this.calendarViewPeriod === "month") {
      this.setSelectedCalendarMonth(date);
    } else if (this.calendarViewPeriod === "week") {
      this.setSelectedCalendarWeek(date);
    } else {
      this.setSelectedCalendarDay(date);
    }
  }
  addToCalendarPeriod(amount: number) {
    if (this.calendarViewPeriod === "month") {
      this.setSelectedCalendarMonth(
        addMonths(this.selectedCalendarMonth, amount)
      );
    } else if (this.calendarViewPeriod === "week") {
      this.setSelectedCalendarWeek(addWeeks(this.selectedCalendarWeek, amount));
    } else {
      this.setSelectedCalendarDay(addDays(this.selectedCalendarDay, amount));
    }
  }
  setSelectedCalendarMonth(month: Date) {
    this.selectedCalendarMonth = month;
    this.setSelectedDateRange([
      dayjsExt(month).startOf("month"),
      dayjsExt(month).endOf("month"),
    ]);
  }
  setSelectedCalendarWeek(week: Date) {
    this.selectedCalendarWeek = week;
    this.setSelectedDateRange([
      dayjsExt(week).startOf("week"),
      dayjsExt(week).endOf("week"),
    ]);
  }
  setSelectedCalendarDay(day: Date) {
    this.selectedCalendarDay = day;
    this.setSelectedDateRange([
      dayjsExt(day).startOf("day"),
      dayjsExt(day).endOf("day"),
    ]);
  }
  setCalendarViewPeriod(period: "month" | "week" | "day") {
    this.calendarViewPeriod = period;
    if (period === "month") {
      this.setSelectedDateRange([
        dayjsExt(this.selectedCalendarMonth).startOf("month"),
        dayjsExt(this.selectedCalendarMonth).endOf("month"),
      ]);
    } else if (period === "week") {
      this.setSelectedDateRange([
        dayjsExt(this.selectedCalendarWeek).startOf("week"),
        dayjsExt(this.selectedCalendarWeek).endOf("week"),
      ]);
    } else {
      this.setSelectedDateRange([
        dayjsExt(this.selectedCalendarDay).startOf("day"),
        dayjsExt(this.selectedCalendarDay).endOf("day"),
      ]);
    }
  }

  setSelectedDateRange(dateRange: DateRange) {
    if (dateRange[0] && dateRange[1]) {
      this.setReservationsFilter({
        startAt: dayjsExt(dateRange[0]).startOf("day").toDate(),
        endAt: dayjsExt(dateRange[1]).endOf("day").toDate(),
      });
    } else {
      this.setReservationsFilter({
        startAt: undefined,
        endAt: undefined,
      });
    }
  }

  setVehiclesFilter(authToken: string, filters: VehiclesFilter) {
    this.vehiclesFilter = {
      "dispatch-location-id":
        filters.dispatchLocationId === "all"
          ? undefined
          : filters.dispatchLocationId,
      amenities: filters.amenities?.filter((v) => v !== -1),
      "spab-certified":
        filters.spab === "not specified" ? undefined : filters.spab,
      "wheelchair-accessible":
        filters.wheelchairAccessibility === "not specified"
          ? undefined
          : filters.wheelchairAccessibility,
      "vehicle-type-id":
        filters.vehicleTypeId === "all" ? undefined : filters.vehicleTypeId,
    };
    this.fetchVehicles(authToken, 1);
  }

  // We fetch one extra week of reservations before and after the date range to ensure
  // that calendar view displays stuff for past and future weeks
  getFormattedReservationFilter() {
    return {
      ...this.reservationsFilter,
      ...(this.reservationsFilter.startAt && {
        startAt: subDays(this.reservationsFilter.startAt, 6),
      }),
      ...(this.reservationsFilter.endAt && {
        endAt: addDays(this.reservationsFilter.endAt, 6),
      }),
    };
  }

  async fetchVehicles(authToken: string, page: number) {
    try {
      const res = await fetchVehicles(
        authToken,
        page,
        VEHICLES_PER_PAGE,
        this.vehiclesFilter
      );
      this.currentFleetItems = res.resultSet;
      this.vehiclesTotal = res.total;
      this.vehiclesNextOffset = res.next;
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  }

  async fetchVehicleTypes(authToken: string): Promise<VehicleType[] | null> {
    try {
      this.vehicleTypes = await fetchVehicleTypes(authToken);
      return this.vehicleTypes;
    } catch (e) {
      notificationStore.setNotificationFromError(e);
      return null;
    }
  }

  async fetchReservations() {
    if (!this.authToken) return;
    runInAction(() => {
      this.reservations = [];
      this.reservationsFetchingStatus = "fetching";
    });
    try {
      const params = this.shouldFetchExtraWeeks
        ? this.getFormattedReservationFilter()
        : this.reservationsFilter;
      const data = await fetchReservations(this.authToken, params);
      runInAction(() => {
        this.reservations = data;
        this.reservationsFetchingStatus = "fetched";
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
      runInAction(() => {
        this.reservationsFetchingStatus = "failedFetching";
      });
    }
  }

  async fetchAvailabilityPercentage() {
    if (!this.authToken) return;
    try {
      let startAt =
        store.reservationsFilter.startAt || startOfMonth(new Date());
      let endAt = startOfMonth(
        addMonths(store.reservationsFilter.endAt || new Date(), 1)
      );
      if (this.calendarViewPeriod === "week") {
        startAt = store.reservationsFilter.startAt || startOfWeek(new Date());
        endAt = startOfWeek(
          addWeeks(store.reservationsFilter.endAt || new Date(), 1)
        );
      }
      if (this.calendarViewPeriod === "day") {
        startAt = store.reservationsFilter.startAt || startOfDay(new Date());
        endAt = startOfDay(
          addDays(store.reservationsFilter.endAt || new Date(), 1)
        );
      }

      this.availabilityPercentage = await getAvailabilityPercentage(
        this.authToken,
        startAt,
        endAt,
        86400000
      );
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  }

  private get shouldFetchExtraWeeks() {
    return this.viewMode === "calendar" && this.calendarViewPeriod === "month";
  }

  resetReservationForm() {
    this.reservationPopup = reservationPopupInitialState;
    this.reservationPopup.vehicles = [];
    this.reservationPopup.vehicleTypes = [];
  }

  closeReservationPopup() {
    this.resetReservationForm();
    this.reservationPopup.isOpen = false;
  }

  openReservationPopupWithVehicles(vehicles: Vehicle[]) {
    this.reservationPopup.isOpen = true;
    this.reservationPopup.vehicles = vehicles;
    this.reservationPopup.form.selectedVehicles = vehicles;
    this.reservationPopup.form.selectedVehicleReservation =
      this.reservations.filter((res) => {
        if (vehicles.reduce((acc, vehicle) => acc && vehicle !== null, true)) {
          return vehicles.some((vehicle) => vehicle.id === res.vehicleId);
        } else {
          return false;
        }
      });
  }
  setReservationFormDataFromCalendar(
    reservationVehicleTypes: ReservationVehicleType[],
    startDate: DayjsExt
  ) {
    const vehicleTypes = reservationVehicleTypes
      ?.map((item) => {
        if (item.type !== undefined)
          return Array(item.quantity).fill(item.type);
        return undefined;
      })
      .flat();
    this.reservationPopup.vehicleTypes = vehicleTypes;
    // hack to allow mobx set array item by index
    this.reservationPopup.form.selectedVehicles = Array(
      vehicleTypes.length
    ).fill(null);
    const now = new Date();
    const startAt = startDate.set("h", 9).isAfter(now)
      ? startDate.set("h", 9)
      : dayjsExt(now);
    this.reservationPopup.form.startAt = startAt;
    this.reservationPopup.form.endAt = startAt.add(1, "hour");
    this.reservationPopup.form.reason = 0;
  }

  selectVehicleToReserve(index: number, vehicle: Vehicle) {
    this.reservationPopup.form.selectedVehicles[index] = vehicle;
    this.reservationPopup.form.selectedVehicleReservation =
      this.reservations.filter((res) => {
        if (
          this.reservationPopup.form.selectedVehicles.reduce(
            (acc, vehicle) => acc && vehicle !== null,
            true
          )
        ) {
          return this.reservationPopup.form.selectedVehicles.some(
            (vehicle) => vehicle.id === res.vehicleId
          );
        } else {
          return false;
        }
      });
  }

  setEditingReservationFormData(reservation: Reservation) {
    this.reservationPopup.form.startAt = dayjsExt(reservation.startAt);
    this.reservationPopup.form.endAt = dayjsExt(reservation.endAt);
    this.reservationPopup.form.reason = reservation.type;
    this.reservationPopup.form.description = reservation.description;
    this.reservationPopup.reservationId = reservation.id;
    this.reservationPopup.vehicleId = reservation.vehicleId;
    this.reservationPopup.form.driverId = reservation.driverId;
  }

  setReservationReason(value: string | number) {
    this.reservationPopup.form.reason = value;
  }
  setReservationDate(type: "startAt" | "endAt", value: Date) {
    this.reservationPopup.form[type] = this.reservationPopup.form[type]
      .set("date", value.getDate())
      .set("month", value.getMonth())
      .set("year", value.getFullYear());
  }
  setReservationTime(type: "startAt" | "endAt", value: Date | null) {
    const newHour = dayjsExt(value)?.get("h") || 0;
    const newMinutes = dayjsExt(value)?.get("m") || 0;

    this.reservationPopup.form[type] = this.reservationPopup.form[type]
      .set("h", newHour)
      .set("m", newMinutes)
      .local();
  }
  updateReservationDescription(value: string) {
    this.reservationPopup.form.description = value;
  }
  async createReservation() {
    try {
      const parsedStartAtDate = this.reservationPopup.form.startAt.toJSON();
      const parsedEndAtDate = this.reservationPopup.form.endAt.toJSON();
      const reservations = this.reservationPopup.form.selectedVehicles.map(
        (vehicle) => {
          if (vehicle) {
            return createReservationAction(this.authToken, {
              type: Number(this.reservationPopup.form.reason),
              startAt: parsedStartAtDate,
              endAt: parsedEndAtDate,
              description: this.reservationPopup.form.description,
              vehicleId: vehicle.id,
            });
          }
          return undefined;
        }
      );
      await Promise.all(reservations).then(() => {
        this.setVehicles();
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
      throw e;
    }
  }

  async editReservation() {
    try {
      this.reservationEditingFetchingStatus = "fetching";
      const parsedStartAtDate = this.reservationPopup.form.startAt.toJSON();
      const parsedEndAtDate = this.reservationPopup.form.endAt.toJSON();
      const type = Number(this.reservationPopup.form.reason);
      await editReservation(
        this.authToken,
        this.reservationPopup.reservationId as string,
        parsedStartAtDate,
        parsedEndAtDate,
        type,
        this.reservationPopup.vehicleId as string,
        this.reservationPopup.form.description,
        this.reservationPopup.form.driverId
      );
      this.reservationEditingFetchingStatus = "fetched";
    } catch (e) {
      notificationStore.setNotificationFromError(e);
      this.reservationEditingFetchingStatus = "failedFetching";
    }
  }

  get isReservationFormValid() {
    return validateReservationForm(this.reservationPopup.form);
  }
  get reservationsAreFetched() {
    return this.reservationsFetchingStatus === "fetched";
  }
  get isFetching() {
    return this.fetchingStatus === "fetching";
  }
  get isFetched() {
    return this.fetchingStatus === "fetched";
  }

  async deleteVehicleType(
    authToken: string,
    vehicleTypeId: string
  ): Promise<boolean> {
    try {
      const res = await deleteVehicleType(authToken, vehicleTypeId);
      notificationStore.setNotification({
        type: "success",
        message: "Successfully deleted!",
      });
      return res;
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
    return false;
  }

  async deleteVehicle(authToken: string, vehicleId: string): Promise<boolean> {
    try {
      const res = await deleteVehicle(authToken, vehicleId);
      notificationStore.setNotification({
        type: "success",
        message: "Successfully deleted!",
      });
      return res;
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
    return false;
  }

  updateVehicle(vehicleId: string, formData: VehicleFormData) {
    this.currentFleetItems = this.currentFleetItems.map((v) => {
      if (v.id === vehicleId) return { ...v, ...formData };
      return v;
    });
  }
}

const store = new VehiclesStore();

export default store;
