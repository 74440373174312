import { styled } from "@mui/material";
import { FlexContainer, palette } from "@busie/ui-kit";

export const ContainerStyled = styled(FlexContainer, {
  shouldForwardProp: (prop) => prop !== "expanded",
})((props: { expanded: boolean }) => {
  return {
    backgroundColor: props.expanded ? palette.black.plus85 : "#fff",
    borderBottom: `1px ${palette.black.plus70} solid`,
    width: "100%",
  };
});
