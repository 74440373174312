import { Location } from "@busie/utils";

export type tripType = "one_way" | "round_trip";

export function getTripType(
  start: Location,
  dest: Location
): "one_way" | "round_trip" {
  return start._id === dest._id || start.address === dest.address
    ? "round_trip"
    : "one_way";
}
