import React from "react";
import { styled } from "@mui/styles";

import { FlexContainer } from "@busie/ui-kit";
import { ContainerStyled } from "./styled";

interface Props {
  children: React.ReactNode;
}

export const Container: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  return (
    <ContainerStyled maxW="1200px" bordered direction="column" rowGap={1}>
      {children}
    </ContainerStyled>
  );
};
