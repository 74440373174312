import React from "react";
import {
  Button,
  FlexContainer,
  IconButton,
  Popup,
  TextInput,
  PlusIcon,
} from "@busie/ui-kit";
import { createGroup } from "@busie/api";
import { Group } from "@busie/utils";
import { Controller, useForm } from "react-hook-form";

interface Props {
  authToken: string;
  onGroupCreation: (group: Group) => void;
}

const CreateGroupPopupBlock: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      groupName: "",
    },
    mode: "onChange",
  });

  const openPopup = () => {
    setIsOpen(true);
  };
  const closePopup = () => {
    setIsOpen(false);
  };
  const submit = async (data: { groupName: string }) => {
    const group = await createGroup(props.authToken, { name: data.groupName });
    props.onGroupCreation(group);
    closePopup();
  };
  return (
    <>
      <IconButton
        className="plus-icon-btn"
        icon={<PlusIcon />}
        onClick={openPopup}
      />
      <Popup
        isPopupOpened={isOpen}
        onClose={closePopup}
        title="Create new group"
        PaperProps={{ sx: { margin: "auto" } }}
      >
        <form onSubmit={handleSubmit(submit)}>
          <FlexContainer
            pl={5}
            pr={4}
            pt={2}
            pb={3}
            rowGap={2}
            direction="column"
            align="flex-end"
            w="400px"
          >
            <Controller
              control={control}
              name="groupName"
              rules={{
                required: "Group name is required",
                minLength: {
                  value: 5,
                  message: "The name must be at least 5 characters long",
                },
                pattern: {
                  value: /\w/, ///^[A-Za-z0-9 ()@#-{}[\]_,.<>\\/|*!$]+$/,
                  message: "No special characters allowed",
                },
              }}
              render={({ field }) => (
                <TextInput
                  fullWidth
                  {...field}
                  placeholder="Enter group name"
                  sx={{ width: "100%" }}
                  error
                  errorMessage={errors.groupName?.message}
                />
              )}
            />
            <Button disabled={!isValid} type="submit">
              Create
            </Button>
          </FlexContainer>
        </form>
      </Popup>
    </>
  );
};

export default CreateGroupPopupBlock;
