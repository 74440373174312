import { duplicate as duplicateLocation } from "./duplicate-location";

import { Leg, CreateTripLegData } from "@busie/api";

export const duplicate = ({
  START_LOCATION,
  DESTINATION_LOCATION,
  DEPARTURE_DATETIME,
  ARRIVAL_DATETIME,
  METERS,
  HOURS,
}: Leg): CreateTripLegData => ({
  startLocation: duplicateLocation(START_LOCATION),
  destinationLocation: duplicateLocation(DESTINATION_LOCATION),
  departureDatetime: DEPARTURE_DATETIME,
  arrivalDatetime: ARRIVAL_DATETIME,
  meters: METERS,
  hours: HOURS,
});
