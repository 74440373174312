import { Button, FlexContainer, H1, H3 } from "@busie/ui-kit";
import React from "react";
import { Icon } from "./icon";
import { history } from "@busie/utils";
import { useAmplitude } from "@busie/core";

export const Page: React.FC = () => {
  const { track } = useAmplitude();

  const { pathname } = history.location;

  track("upgrade page viewed", { pathname });

  return (
    <FlexContainer fullWidth align="center" justify="center">
      <FlexContainer
        direction="column"
        rowGap={2}
        justify="center"
        align="center"
        sx={{ marginTop: "20px", maxWidth: "1360px" }}
        py={4}
        bordered
        fullWidth
      >
        <H1>You need to be on a paid plan to access this page.</H1>
        <Icon />
        <H3>Don't wait! Upgrade your plan now!</H3>
        <FlexContainer direction="row" columnGap={2} align="center">
          <Button
            typestyle="primary"
            onClick={() => history.push("/organization-settings/plan")}
          >
            Go To Plan Page
          </Button>
          <Button
            typestyle="tertiary"
            onClick={(e) => {
              window.location.href = "mailto:support@getbusie.com";
              e.preventDefault();
            }}
          >
            Contact Us
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
