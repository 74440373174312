import React from "react";

import LoadingBlockStyled from "./styled/LoadingBlockStyled";

interface Props {
  margin?: string;
  title?: string;
  subtitle?: string;
}

const LoadingBlock: React.FC<React.PropsWithChildren<Props>> = ({
  margin,
  title = "Calculating...",
  subtitle = "Determining optimal trip route",
}: Props) => {
  return (
    <LoadingBlockStyled margin={margin}>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <div className="loading-bar">
        <div className="progress-bar"></div>
      </div>
    </LoadingBlockStyled>
  );
};

export default LoadingBlock;
