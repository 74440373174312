import {
  FlexContainer,
  IconButton,
  Loading,
  SendIcon,
  TextInput,
  palette,
} from "@busie/ui-kit";
import { Avatar } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

interface FormValues {
  text: string;
}

interface Props {
  name: string;
  imgSrc: string;
  onSubmit: (data: FormValues) => void;
}

export const Form: React.FC<Props> = ({ name, imgSrc, onSubmit }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      text: "",
    },
  });

  const _onSubmit = (data: FormValues) => {
    onSubmit(data);
    reset();
  };

  return (
    <FlexContainer columnGap={2} direction="row" fullWidth>
      <Avatar alt={name} src={imgSrc} />
      <form style={{ width: "100%" }} onSubmit={handleSubmit(_onSubmit)}>
        <Controller
          name="text"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              fullWidth
              placeholder="Add a note..."
              endAdornment={
                <IconButton
                  type="submit"
                  disabled={!isDirty || isSubmitting}
                  icon={
                    isSubmitting ? (
                      <Loading size={24} padding="0px" />
                    ) : (
                      <SendIcon color={palette.black.plus30} />
                    )
                  }
                />
              }
              sx={{ paddingRight: 0 }}
            />
          )}
        />
      </form>
    </FlexContainer>
  );
};
