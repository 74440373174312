import React from "react";
import {
  Banner,
  FlexContainer,
  FormContainer,
  InfoIcon,
  palette,
} from "@busie/ui-kit";

interface Props {
  passengers: React.ReactNode;
  wheelchairs: React.ReactNode;
}

export const Container: React.FC<Props> = ({ passengers, wheelchairs }) => {
  return (
    <FlexContainer
      rowGap={1}
      direction="column"
      align="flex-start"
      px={2}
      fullWidth
    >
      <FormContainer title="Passenger Information">
        <FlexContainer direction="column" rowGap={2} fullWidth>
          <Banner
            fontSize="12px"
            backgroundColor={palette.black.plus80}
            text="Updating the following information will cause an automatic re-calculation of price"
            icon={<InfoIcon size={24} color={palette.black.main} />}
          />
          <FlexContainer direction="row" columnGap={0}>
            <FlexContainer direction="column" rowGap={0}>
              {passengers}
            </FlexContainer>

            <FlexContainer direction="column" rowGap={0}>
              {wheelchairs}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FormContainer>
    </FlexContainer>
  );
};
