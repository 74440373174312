import axios from "axios";

import { env, LegPrice, Trip } from "@busie/utils";

import {
  CreateTripFormData,
  RouteRequest,
  RouteResponse,
  EmbeddedTripPlannerSettings,
} from "../../dataTypes";
import { CreateCustomerFormData } from "../customers";
import { handleTripCreatedData } from "../../helpers/handle-trip-created-data";
import getPhoneNumberComponents from "../../helpers/get-phone-number-components";
import { CreateQuoteData } from "../quotesAndBookings";

interface CustomerProfileWithGroup {
  name: string;
  email: string;
  countryCode: string;
  areaCode: string;
  phoneNumber: string;
  group: { name: string };
}

export interface UpdateEmbeddedTripPlannerSettingsParams {
  domain: string;
  hideInstantQuote: boolean;
  requestToBook: boolean;
}

const ETP_BFF_SERVICE_API_URL = `${env(
  "BUSIE_ETP_BFF_SERVICE_API_URL"
)}/etp-bff`;

export const createOrganizationAsGuest = async (
  org_id: string,
  domain: string
): Promise<EmbeddedTripPlannerSettings> => {
  const { data } = await axios.post(
    `${ETP_BFF_SERVICE_API_URL}/organizations/`,
    {
      id: org_id,
      domain,
    }
  );

  return data;
};

export const triggerLocalVisitorIdHook = async (
  linkedId: string,
  url: string,
  visitorId: string
): Promise<void> => {
  const { data } = await axios.post(
    `${ETP_BFF_SERVICE_API_URL}/auth/_fp`,
    {
      linkedId,
      url,
      visitorId,
    },
    {
      auth: {
        username: "somesupersecretusername",
        password: "somesupersecretpassword",
      },
    }
  );
  return data;
};

export const authenticateGuestUser = async (
  organizationApiKey: string,
  organizationDomain: string,
  visitorId: string
): Promise<string> => {
  const { data } = await axios.post(`${ETP_BFF_SERVICE_API_URL}/auth/login`, {
    username: `${organizationApiKey}:${organizationDomain}`,
    password: visitorId,
  });
  return data.access_token || "";
};

export const getOrganizationAsGuest = async (
  authToken: string,
  organizationApiKey: string
): Promise<EmbeddedTripPlannerSettings> => {
  const { data } = await axios.get(
    `${ETP_BFF_SERVICE_API_URL}/organizations?apiKey=${organizationApiKey}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const createQuoteAsGuest = async (
  authToken: string,
  formData: CreateQuoteData
): Promise<unknown> => {
  const { data } = await axios.post(
    `${ETP_BFF_SERVICE_API_URL}/quotes`,
    formData,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const createTripAsGuest = async (
  authToken: string,
  formData: CreateTripFormData
): Promise<Trip> => {
  if (!authToken) return {} as Trip;
  const { data } = await axios.post(
    `${ETP_BFF_SERVICE_API_URL}/trips`,
    formData,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  const trip = handleTripCreatedData(data);
  return trip;
};

export const createRouteRequestAsGuest = async (
  authToken: string,
  formData: RouteRequest
): Promise<RouteResponse> => {
  const { data } = await axios.post(
    `${ETP_BFF_SERVICE_API_URL}/routes`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const createCustomerAsGuest = async (
  authToken: string,
  formData: CreateCustomerFormData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ [key: string]: any }> => {
  const requestBody: CustomerProfileWithGroup = {
    name: formData.name.trim(),
    email: formData.email.trim(),
    group: { name: formData.groupname?.trim() || "" },
    ...getPhoneNumberComponents(formData.phoneNumber),
  };
  const { data } = await axios.post(
    `${ETP_BFF_SERVICE_API_URL}/customers/`,
    requestBody,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const fetchLegPriceAsGuest = async (
  id: string,
  authToken: string
): Promise<LegPrice> => {
  const { data } = await axios.get(
    `${ETP_BFF_SERVICE_API_URL}/leg-prices/${id}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const getEmbeddedTripPlannerSettings = async (
  authToken: string,
  organizationId: string
): Promise<EmbeddedTripPlannerSettings> => {
  const { data } = await axios.get<EmbeddedTripPlannerSettings>(
    `${ETP_BFF_SERVICE_API_URL}/organizations/${organizationId}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const updateEmbeddedTripPlannerSettings = async (
  authToken: string,
  organizationId: string,
  params: UpdateEmbeddedTripPlannerSettingsParams
) => {
  const { data } = await axios.put<EmbeddedTripPlannerSettings>(
    `${ETP_BFF_SERVICE_API_URL}/organizations/${organizationId}`,
    params,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};
