import React from "react";

import { Button as BusieButton } from "@busie/ui-kit";

interface Props {
  disabled: boolean;
  onClick: () => void;
}

export const Button: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  onClick,
}) => (
  <BusieButton typestyle="primary" disabled={disabled} onClick={onClick}>
    Create Payment Link
  </BusieButton>
);
