import {
  IconButton,
  Menu as BusieMenu,
  MoreIcon,
  MenuItem,
} from "@busie/ui-kit";
import React from "react";

interface Props {
  items: MenuItem[];
}

export const Menu: React.FC<React.PropsWithChildren<Props>> = ({ items }) => {
  return (
    <BusieMenu
      right="0%"
      items={items}
      toggler={<IconButton icon={<MoreIcon />} />}
    />
  );
};
