import { H3, H4, palette } from "@busie/ui-kit";
import { differenceInMinutes, format, isToday, startOfHour } from "date-fns";
import React from "react";
import { HourHeaderStyled } from "~/vehicles/Vehicles/CalendarView/styled/CalendarDayView.styled";
import DayNumberStyled from "~/vehicles/Vehicles/CalendarView/styled/dayNumber.styled";

interface Props {
  hour: Date;
  i: number;
  selectedDay: Date;
}

const HeaderCell: React.FC<React.PropsWithChildren<Props>> = ({
  hour,
  i,
  selectedDay,
}) => {
  const isDateToday = isToday(selectedDay);
  const [percentagePassed, setPercentagePassed] = React.useState(
    differenceInMinutes(new Date(), startOfHour(hour))
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      // update the progress dot every minute
      setPercentagePassed(differenceInMinutes(new Date(), startOfHour(hour)));
    }, 60000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HourHeaderStyled date={hour} percentagePassed={percentagePassed}>
      {i === 0 && (
        <>
          <H3 margin="0" color={palette.black.plus40}>
            {format(selectedDay, "EEE")}
          </H3>
          <DayNumberStyled isToday={isDateToday}>
            {format(selectedDay, "d")}
          </DayNumberStyled>
        </>
      )}
      <H4 color={palette.black.plus40}>{format(hour, "kk:mm")}</H4>
    </HourHeaderStyled>
  );
};

export default HeaderCell;
