import React from "react";

import {
  NoteText,
  Button,
  FlexContainer,
  palette,
  Select,
} from "@busie/ui-kit";
import { mapVehicleTypeToName } from "@busie/core";
import VehiclesStore from "~/vehicles/store/vehicles.store";
import { observer } from "mobx-react";

const statusFilterItems = [
  {
    name: "All statuses",
    value: "all",
  },
  { name: "Pending", value: 0 },
  { name: "Confirmed", value: 1 },
  { name: "In Progress", value: 2 },
  { name: "Complete", value: 3 },
  { name: "Declined", value: 4 },
];

const ReservationsFilter: React.FC<
  React.PropsWithChildren<{ onClose?: () => void }>
> = observer(({ onClose }) => {
  const [filters, setFilters] = React.useState<{
    status?: string;
    vehicleTypeId?: string;
    startAt?: Date;
    endAt?: Date;
  }>({
    status: VehiclesStore.reservationsFilter.status ?? "all",
    vehicleTypeId: VehiclesStore.reservationsFilter.vehicleTypeId ?? "all",
    startAt: VehiclesStore.reservationsFilter.startAt,
    endAt: VehiclesStore.reservationsFilter.endAt,
  });

  const vehicleTypeFilterItems = [
    { name: "All vehicles", value: "all" },
    ...VehiclesStore.vehicleTypes.map((vehicleType) => {
      return {
        value: vehicleType.id,
        name: mapVehicleTypeToName(vehicleType.type),
      };
    }),
  ];

  const setFilterValue = (key: "vehicleTypeId" | "status", value: string) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const applyFilters = () => {
    VehiclesStore.setReservationsFilter(filters);
    onClose && onClose();
  };

  const clearFilters = () => {
    setFilters({
      status: "all",
      vehicleTypeId: "all",
    });
    VehiclesStore.setReservationsFilter({
      status: "all",
      vehicleTypeId: "all",
      startAt: VehiclesStore.reservationsFilter.startAt,
      endAt: VehiclesStore.reservationsFilter.endAt,
    });
    onClose && onClose();
  };

  return (
    <FlexContainer direction="column" rowGap={1} p={3} w={250}>
      <NoteText color={palette.black.plus30}>Vehicles</NoteText>
      <Select
        onChange={(e) => setFilterValue("vehicleTypeId", e.target.value)}
        value={filters.vehicleTypeId ?? "all"}
        items={vehicleTypeFilterItems}
        fullWidth
        hideErrorSpace
      />
      {VehiclesStore.viewMode !== "collapsed" && (
        <>
          <NoteText color={palette.black.plus30}>Status</NoteText>
          <Select
            onChange={(e) => setFilterValue("status", e.target.value)}
            value={filters.status ?? "all"}
            items={statusFilterItems}
            fullWidth
            hideErrorSpace
          />
        </>
      )}
      <FlexContainer
        direction="row"
        columnGap={1}
        align="center"
        justify="space-between"
        fullWidth
      >
        <Button typestyle="secondary" onClick={clearFilters}>
          Clear
        </Button>
        <Button onClick={applyFilters}>Apply</Button>
      </FlexContainer>
    </FlexContainer>
  );
});

export default ReservationsFilter;
