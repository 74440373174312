import {
  init,
  track,
  Identify,
  identify,
  add,
} from "@amplitude/analytics-browser";
import {
  AmplitudeReturn,
  BrowserConfig,
  EnrichmentPlugin,
  Event,
  PluginType,
  Result,
  Plugin,
} from "@amplitude/analytics-types";
import { User } from "@busie/utils";

export interface AmplitudeClient {
  track: typeof track;
  identify: typeof identify;
  add: typeof add;
}

export class AddCommonEventPropertiesPlugin implements EnrichmentPlugin {
  public readonly name = "add-common-event-properties";
  public readonly type = PluginType.ENRICHMENT as const;
  public properties: Record<string, unknown>;
  public config?: BrowserConfig;

  constructor(properties: Record<string, unknown>) {
    this.properties = properties;
  }

  async setup(config: BrowserConfig) {
    this.config = config;
  }

  async execute(event: Event): Promise<Event> {
    event.event_properties = { ...event.event_properties, ...this.properties };
    return event;
  }
}

let amplitude: AmplitudeClient;

export const useAmplitude = (): AmplitudeClient => {
  if (!amplitude) throw new Error("Amplitude not initialized");

  return amplitude;
};

const stub = (...args: unknown[]): AmplitudeReturn<Result> => {
  console.log("amplitude function stub for track and identify", args);

  return {
    promise: Promise.resolve({
      event: { event_type: "Stubbed Event" },
      code: Math.random(),
      message: "stub",
    }),
  };
};

const addStub = (plugin: Plugin): AmplitudeReturn<void> => {
  console.log("added plugin", plugin);

  return { promise: Promise.resolve() };
};

const amplitudeStub = {
  track: stub,
  identify: stub,
  add: addStub,
};

export const initAmplitudeAnonymous = (apiKey: string): void => {
  if (!apiKey) {
    console.log("amplitude not enabled");
    amplitude = amplitudeStub;
    return;
  }

  init(apiKey);

  amplitude = { track, identify, add };
};

interface initOptions {
  apiKey: string;
  user: User;
}

export const initAmplitude = ({ apiKey, user }: initOptions): void => {
  if (!apiKey) {
    console.log("amplitude not enabled");
    amplitude = amplitudeStub;
    return;
  }

  init(apiKey, user.sub);

  const amplitudeUserIdentification = new Identify();
  if (user.email) {
    amplitudeUserIdentification.set("email", user.email);
  }

  amplitudeUserIdentification.setOnce("organizationId", user.org_id);

  identify(amplitudeUserIdentification);

  amplitude = {
    track,
    identify,
    add,
  };
};
