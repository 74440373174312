import React from "react";

interface Props {
  size?: number;
}

export const RoundTripIcon: React.FC<React.PropsWithChildren<Props>> = ({
  size = 24,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.19775 10.7066C4.80859 11.1028 4.17132 11.1028 3.78216 10.7066C2.76273 9.66819 1 7.59233 1 5.64852C1 3.63368 2.56265 2 4.49045 2C6.41826 2 7.98091 3.63368 7.98091 5.64852C7.98091 7.59233 6.21817 9.66819 5.19775 10.7066Z"
      stroke="#3B3B3B"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.48947 5.34045C4.35141 5.34045 4.24036 5.45249 4.24036 5.59055C4.24036 5.72761 4.35241 5.83966 4.48947 5.83966C4.62752 5.83966 4.73857 5.72761 4.73857 5.59055C4.73957 5.45249 4.62752 5.34045 4.48947 5.34045"
      stroke="#3B3B3B"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.1672 17.5595L14.277 19.2692C13.9158 19.4833 13.9158 20.0065 14.277 20.2196L17.1672 21.9293C17.5383 22.1494 18.0075 21.8813 18.0075 21.4501V18.0387C18.0075 17.6075 17.5383 17.3394 17.1672 17.5595Z"
      stroke="#3B3B3B"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0041 19.7054H6.00205C4.89659 19.7054 4.00122 18.81 4.00122 17.7046C4.00122 16.5991 4.89659 15.7037 6.00205 15.7037H11.6554C12.9529 15.7037 14.0054 14.6513 14.0054 13.3538C14.0054 12.0562 12.9529 11.0038 11.6554 11.0038H9.0033"
      stroke="#3B3B3B"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5 19.9999C20.5 19.9999 22.0714 18.4999 22.5 15.5C22.9286 12.5 21.5 8.99996 18.5 6.99995C15.7885 5.19229 11.5 5.99991 11.5 5.99991"
      stroke="#6E6E6E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
