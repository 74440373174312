import { styled } from "@mui/system";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import { typographyClasses } from "@mui/material/Typography";

export interface Props extends FormControlLabelProps {
  color?: string;
  fontWeight?: number | string;
}

const FormControlLabelStyled = styled(FormControlLabel)<Props>(
  ({ theme, color, fontWeight }) => {
    return {
      [`.${typographyClasses.root}`]: {
        lineHeight: "150%",
        color: color || theme.palette.black.main,
        fontWeight: fontWeight || 500,
      },
    };
  }
);

export default FormControlLabelStyled;
