import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ChefBooking } from "@busie/api";
import {
  Button,
  Divider,
  FlexContainer,
  FormContainer,
  H2,
  NoteText,
  TextInput,
  palette,
} from "@busie/ui-kit";
import { QuoteAndBookingStatus } from "@busie/utils";
import { notificationStore } from "@busie/features";
import { useAmplitude } from "@busie/core";

import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import {
  FormValues,
  getModalCopy,
  schema,
  useUpdateBookingStatus,
} from "./model";

interface Props {
  booking: ChefBooking;
  newStatus: QuoteAndBookingStatus;
  isOpen: boolean;
  onClose: () => void;
  onStatusUpdate: () => void;
}

export const UpdateStatus: React.FC<Props> = ({
  booking,
  newStatus,
  isOpen,
  onClose,
  onStatusUpdate,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      confirm: "",
    },
  });

  const { mutateAsync: updateBookingStatus, isLoading } =
    useUpdateBookingStatus(booking._id, newStatus);

  const { track } = useAmplitude();

  useEffect(() => reset, [reset, isOpen]);

  const { title, text, note, button } = getModalCopy(newStatus);

  const onSubmit = async () => {
    try {
      await updateBookingStatus(undefined, {
        onSuccess: () => {
          let trackEvent = "";

          switch (newStatus) {
            case QuoteAndBookingStatus.CONFIRMED:
              trackEvent = "booking manually confirmed";
              break;
            case QuoteAndBookingStatus.PAID:
              trackEvent = "booking paid offline";
              break;
            case QuoteAndBookingStatus.CANCELED:
              trackEvent = "booking canceled";
              break;
            case QuoteAndBookingStatus.COMPLETE:
              trackEvent = "booking completed";
              break;
            default:
              break;
          }

          trackEvent.length &&
            track(trackEvent, {
              bookingId: booking._id,
              price: booking.QUOTE.PRICE,
              balance: booking.BALANCE,
              numberOfTrips: booking.QUOTE.EXPERIENCES.length,
            });

          notificationStore.setNotification({
            type: "success",
            header: "Success",
            message: "Booking status updated",
          });

          onStatusUpdate();
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <H2>Update Booking Status</H2>
      <FormContainer title={title}>
        <H2>{text}</H2>
        <NoteText>{note}</NoteText>
        <NoteText color={palette.red.main}>
          This action cannot be undone
        </NoteText>

        <Divider />

        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer
            align="flex-start"
            direction="column"
            columnGap={2}
            fullWidth
          >
            <Controller
              name="confirm"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Type `confirm` to continue"
                  fullWidth
                  errorMessage={errors.confirm?.message}
                />
              )}
            />
          </FlexContainer>

          <div className="form-submit-control">
            <Button
              typestyle="secondary"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>

            <Button
              typestyle="primary"
              type="submit"
              disabled={!isDirty || !isValid || isSubmitting || isLoading}
            >
              {button}
            </Button>
          </div>
        </form>
      </FormContainer>
    </Modal>
  );
};
