import { QuoteAndBookingStatus } from "@busie/utils";

export const FILTER_TYPE_OPTIONS = [
  {
    name: "quotes",
    link: "/quotes-and-bookings/quotes",
  },
  {
    name: "bookings",
    link: "/quotes-and-bookings/bookings",
  },
  {
    name: "archived quotes",
    link: "/quotes-and-bookings/archived/quotes",
  },
  {
    name: "archived bookings",
    link: "/quotes-and-bookings/archived/bookings",
  },
];

export const ACCEPTED_STATUSES = [
  {
    name: "All statuses",
    value: "ALL" as const,
  },
  {
    name: "Declined",
    value: QuoteAndBookingStatus.DECLINED,
  },
];
