import { useAuth0 } from "@auth0/auth0-react";
import {
  FlexContainer,
  IconButton,
  Loading,
  SendIcon,
  TextInput,
  palette,
} from "@busie/ui-kit";
import { Group, User } from "@busie/utils";
import React from "react";
import { useCreateGroupNote } from "./model";
import { CreateNoteForm } from "~/CustomersPage/entity";

interface Props {
  groupId: string;
  onSuccess: (group: Group) => void;
  onError: (e: unknown) => void;
}

export const AddNote: React.FC<Props> = ({ groupId, onSuccess, onError }) => {
  const { user } = useAuth0<User>();

  const { mutateAsync: createNote } = useCreateGroupNote(groupId);

  const onSubmit = async ({ text }: { text: string }) =>
    createNote({ text }, { onSuccess, onError });

  return (
    <CreateNoteForm
      name={user?.name || ""}
      imgSrc={user?.picture || ""}
      onSubmit={onSubmit}
    />
  );
};
