import { track as amplitudeTrack } from "@amplitude/analytics-browser";
import { UseFieldArrayAppend, UseFieldArrayInsert } from "react-hook-form";

import { NotificationStore } from "@busie/features";
import {
  DayjsExt,
  DispatchLocation,
  GooglePlace,
  Trip,
  dayjsExt,
} from "@busie/utils";
import {
  CreateTripLegData,
  Experience,
  RouteRequest,
  RouteResponse,
  UpdateTripData,
  toHours,
} from "@busie/api";

import { FormValues, Place } from "../model";

export const serializePlaceForForm: (place: GooglePlace) => Place = (place) => {
  const {
    geometry: {
      location: { lat, lng },
    },
  } = place;
  return {
    ...place,
    geometry: { location: { lat: lat(), lng: lng() } },
  };
};

export const getGooglePlace: (place: Place) => GooglePlace = ({
  geometry: {
    location: { lat, lng },
  },
  ...place
}) => ({
  ...place,
  geometry: { location: { lat: () => lat, lng: () => lng } },
});

export const createDefaultFormValues = (
  trip: Experience,
  route?: RouteResponse
): FormValues => ({
  mainContact: trip.MAIN_CONTACT.ID,
  additionalInformation: trip.ADDITIONAL_INFORMATION || "",
  dispatchId: trip.DISPATCH_ID,
  passengers: trip.PASSENGERS,
  wheelchairs: trip.WHEELCHAIRS,
  spab: trip.SPAB,
  stops: trip.LEGS.slice(0, -1).map(
    ({
      DESTINATION_LOCATION: {
        ADDRESS,
        CITY,
        STREET,
        STATE,
        COUNTRY,
        LATITUDE: lat,
        LONGITUDE: lng,
      },
    }) => ({
      formatted_address: ADDRESS ?? `${STREET}, ${CITY} ${STATE}, ${COUNTRY}`,
      geometry: { location: { lat, lng } },
    })
  ) as Place[],
  departures: trip.LEGS.slice(1, -1).map(({ DEPARTURE_DATETIME }) =>
    dayjsExt(new Date(DEPARTURE_DATETIME))
  ),
  route: route ?? ({} as RouteResponse),
});

type AppendStops = UseFieldArrayAppend<FormValues, "stops">;
type AppendDepartures = UseFieldArrayAppend<FormValues, "departures">;
type InsertStops = UseFieldArrayInsert<FormValues, "stops">;
type InsertDepartures = UseFieldArrayInsert<FormValues, "departures">;

export const addStopHandlerFactory = (
  appendStop: AppendStops,
  appendDeparture: AppendDepartures,
  insertStop: InsertStops,
  insertDeparture: InsertDepartures
) => {
  return (
    currentStop: Place,
    currentDeparture: DayjsExt,
    index: number,
    arr: Place[]
  ) => {
    const newStop = duplicateStop(currentStop);
    if (index === arr.length - 1) {
      appendStop(newStop, { shouldFocus: true });
      appendDeparture(dayjsExt(currentDeparture));
    } else {
      insertStop(index + 1, newStop, { shouldFocus: true });
      insertDeparture(index, dayjsExt(currentDeparture));
    }
  };
};

const duplicateStop = ({
  formatted_address,
  place_id,
  address_components,
  html_attributions,
  geometry: {
    location: { lat, lng },
  },
}: Place): Place => ({
  formatted_address,
  place_id,
  html_attributions,
  geometry: { location: { lat, lng } },
  address_components: address_components?.map(
    ({ long_name, short_name, types }) => ({ long_name, short_name, types })
  ),
});

export const getUpdateTripPayload = (data: FormValues): UpdateTripData => {
  const {
    stops,
    departures,
    wheelchairs,
    passengers,
    additionalInformation,
    dispatchId,
    route,
    mainContact: mainContactId,
    spab,
  } = data;

  const legs = createTripLegDataFactory(route, departures, stops);

  return {
    passengers,
    wheelchairs,
    additionalInformation,
    dispatchId,
    mainContactId,
    legs,
    spab,
  };
};

export const createTripRoutePayload = (
  stops: Place[],
  dispatchLocation: DispatchLocation,
  vehicleType: string | undefined
): RouteRequest => ({
  vehicleType,
  start: `${dispatchLocation.latitude},${dispatchLocation.longitude}`,
  end: `${dispatchLocation.latitude},${dispatchLocation.longitude}`,
  waypoints: stops.map(
    ({
      geometry: {
        location: { lat, lng },
      },
    }) => `${lat},${lng}`
  ),
  routeType:
    stops[0].formatted_address === stops[stops.length - 1].formatted_address
      ? "ROUND_TRIP"
      : "ONE_WAY",
});

export const successHandlerFactory = (
  notificationStore: NotificationStore,
  track: typeof amplitudeTrack,
  onSuccess: () => void
) => {
  return (result: Trip) => {
    const {
      _id: id,
      _passengers: passengers,
      _wheelchairs: wheelchairs,
      _legs: legs,
      _mainContactId: mainContactId,
      _additionalInformation: additionalInformation,
      _dispatchId: dispatchId,
    } = result;

    notificationStore.setNotification({
      type: "success",
      header: "Trip updated!",
      message: "Please allow a few seconds for these changes to be visible.",
    });

    const { _destinationLocation: pickup } = legs[0];
    const { _startLocation: destination } = legs[legs.length - 1];

    const isRoundTrip =
      `${pickup.latitude},${pickup.longitude}` ===
      `${destination.latitude},${destination.longitude}`;

    track("trip updated", {
      id,
      passengers,
      wheelchairs,
      mainContactId,
      additionalInformation,
      dispatchId,
      numLegs: legs.length,
      tripType: isRoundTrip ? "round trip" : "one way",
      pickupAddress: pickup.address,
      destinationAddress: destination.address,
    });

    onSuccess();
  };
};

/**
 * @note backend expects dead legs to be included in the legs array, but doesn't use them...
 */
const createTripLegDataFactory = (
  route: RouteResponse,
  departures: DayjsExt[],
  stops: Place[]
): CreateTripLegData[] => [
  {
    startLocation: {
      latitude: route.legs[0].start.position.latitude,
      longitude: route.legs[0].start.position.longitude,
    },
    departureDatetime: dayjsExt(departures[0]).toISOString(),
    destinationLocation: {
      latitude: route.legs[0].end.position.latitude,
      longitude: route.legs[0].end.position.longitude,
    },
    meters: route.legs[0].distance,
    hours: toHours(route.legs[0].estimateTravelTime),
  },
  ...stops.slice(0, -1).map(
    (
      {
        formatted_address: address,
        geometry: {
          location: { lat: latitude, lng: longitude },
        },
      },
      index
    ) => ({
      startLocation: { address, latitude, longitude },
      destinationLocation: {
        address: stops[index + 1].formatted_address,
        latitude: stops[index + 1].geometry.location.lat,
        longitude: stops[index + 1].geometry.location.lng,
      },
      departureDatetime: dayjsExt(departures[index]).toISOString(),
      arrivalDatetime: dayjsExt(departures[index])
        .add(route.legs[index + 1].estimateTravelTime, "seconds")
        .toISOString(),
      meters: route.legs[index + 1].distance,
      hours: toHours(route.legs[index + 1].estimateTravelTime),
    })
  ),
  {
    startLocation: {
      latitude: route.legs[route.legs.length - 1].start.position.latitude,
      longitude: route.legs[route.legs.length - 1].start.position.longitude,
    },
    departureDatetime: dayjsExt(departures[0]).toISOString(),
    destinationLocation: {
      latitude: route.legs[route.legs.length - 1].end.position.latitude,
      longitude: route.legs[route.legs.length - 1].end.position.longitude,
    },
    meters: route.legs[0].distance,
    hours: toHours(route.legs[route.legs.length - 1].estimateTravelTime),
  },
];
