import { Integration } from "@busie/utils";

export const getQuotesIntegrationFromId = (
  integrationId: string,
  organizationId: string,
  integrationMap: Map<string, Integration>,
  integrationNames: {
    [key: string]: string;
  },
  integrationLogos: {
    [key: string]: string;
  }
) => {
  if (!integrationMap.has(integrationId)) return null;

  const integration = integrationMap.get(integrationId);

  if (integration) {
    integration.integrationType =
      organizationId === integration.sourceId ? "SOURCE" : "DESTINATION";

    const otherOrgId =
      integration.integrationType === "SOURCE"
        ? integration.destinationId
        : integration.sourceId;

    integration.logo = integrationLogos[otherOrgId];
    integration.organizationName = integrationNames[otherOrgId];

    return integration;
  }

  return null;
};
