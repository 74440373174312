import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  AmountField,
  BigIconSelect,
  Button,
  FlexContainer,
  FormContainer,
  H3,
  InfoIcon,
  TextArea,
  ToggleSwitch,
  TooltipIcon,
  palette,
} from "@busie/ui-kit";
import {
  MAX_PASSENGERS,
  MAX_WHEELCHAIRS,
  TripDetailsFormData,
  getActiveAmenities,
  schema,
} from "./model";
import { yupResolver } from "@hookform/resolvers/yup";
import { AmenityValues } from "@busie/utils";
import { AmenityIcon, mapAmenityToName } from "@busie/core";
import { Tooltip } from "@mui/material";

interface Props {
  onCancel: () => void;
  onSubmit: (data: TripDetailsFormData) => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  onCancel,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<TripDetailsFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      passengers: 0,
      wheelchairs: 0,
      additionalInfo: "",
      amenities: [],
      spab: false,
    },
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Trip Details">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer direction="column" rowGap={2}>
          <FlexContainer justify="space-evenly">
            <Controller
              name="passengers"
              control={control}
              render={({ field }) => (
                <AmountField
                  {...field}
                  id="passengers"
                  label="Passengers"
                  minValue={getValues().wheelchairs || 0}
                  maxValue={MAX_PASSENGERS}
                  errorMessage={errors.passengers?.message}
                />
              )}
            />

            <Controller
              name="wheelchairs"
              control={control}
              render={({ field }) => (
                <AmountField
                  {...field}
                  id="accessible-seating"
                  label="Accessible Seating"
                  minValue={0}
                  maxValue={MAX_WHEELCHAIRS}
                  errorMessage={errors.wheelchairs?.message}
                />
              )}
            />
          </FlexContainer>

          <Controller
            name="spab"
            control={control}
            render={({ field }) => (
              <FlexContainer
                direction="row"
                columnGap={1}
                align="center"
                justify="space-between"
              >
                <H3 color={palette.black.plus30}>
                  {field.value ? "T" : "Not t"}ransporting K-12 School Pupils
                </H3>

                <ToggleSwitch
                  checked={field.value}
                  onChange={(ev) => field.onChange(ev.target.checked)}
                />

                <Tooltip
                  title="In certain jurisdictions the transportation of K-12 School Pupils requires additional certifications for Drivers and Vehicles."
                  placement="top"
                >
                  <TooltipIcon>
                    <InfoIcon size={24} color={palette.black.plus30} />
                  </TooltipIcon>
                </Tooltip>
              </FlexContainer>
            )}
          />

          <FlexContainer direction="column" fullWidth>
            <Controller
              name="additionalInfo"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  placeholder="We'll collect the route information in the next step, but feel free to add any additional information here."
                  id="additional-information"
                  label="Additional Information"
                  sx={{
                    width: "100%",
                    resize: "none",
                    height: "100px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                />
              )}
            />
          </FlexContainer>

          <FlexContainer direction="column" fullWidth rowGap={1}>
            <H3>Amenities</H3>
            <Controller
              name="amenities"
              control={control}
              render={({ field }) => (
                <FlexContainer
                  columnGap={2}
                  rowGap={2.5}
                  fullWidth
                  wrap="wrap"
                  justify="flex-start"
                >
                  {AmenityValues.map((amenity, index) => {
                    return (
                      <BigIconSelect
                        title={mapAmenityToName(amenity)}
                        key={index}
                        isActive={new Set(getValues("amenities")).has(amenity)}
                        onClick={() => {
                          const activeAmenities = getActiveAmenities(
                            getValues("amenities"),
                            amenity
                          );

                          field.onChange(activeAmenities);
                        }}
                      >
                        <AmenityIcon type={amenity} />
                      </BigIconSelect>
                    );
                  })}
                </FlexContainer>
              )}
            />
          </FlexContainer>
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
            aria-label="Close the form"
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            aria-label="Continue to the next step of the form"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Next
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
