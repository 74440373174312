import {
  AcceptedStatusText,
  CanceledStatusText,
  CompletedStatusText,
  ConfirmedStatusText,
  DeclinedStatusText,
  PaidStatusText,
  PendingStatusText,
} from "@busie/ui-kit";
import { QuoteAndBookingStatus } from "@busie/utils";
import React from "react";

interface Props {
  status: QuoteAndBookingStatus;
}

export const QnBStatusText: React.FC<Props> = ({ status }) => {
  const STATUS_TEXT = {
    [QuoteAndBookingStatus.PENDING]: <PendingStatusText />,
    [QuoteAndBookingStatus.CONFIRMED]: <ConfirmedStatusText />,
    [QuoteAndBookingStatus.ACCEPTED]: <AcceptedStatusText />,
    [QuoteAndBookingStatus.DECLINED]: <DeclinedStatusText />,
    [QuoteAndBookingStatus.CANCELED]: <CanceledStatusText />,
    [QuoteAndBookingStatus.COMPLETE]: <CompletedStatusText />,
    [QuoteAndBookingStatus.PAID]: <PaidStatusText />,
  };
  return STATUS_TEXT[status];
};
