import { QueryKey, useQuery } from "react-query";

import { getRevenueShareConfig, listRevenueShareConfigs } from "@busie/api";
import { usePaymentsAuthToken, queryClient } from "@busie/core";

const REVENUE_SHARE_CONFIG_QUERY_KEY: QueryKey = "REVENUE_SHARE_CONFIG";
const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

export const invalidateRevenueShareConfigQueries = () => {
  queryClient.invalidateQueries(REVENUE_SHARE_CONFIG_QUERY_KEY);
};

export const useGetRevenueShareConfig = (id = "") => {
  const paymentsAuthToken = usePaymentsAuthToken();

  const result = useQuery(
    [REVENUE_SHARE_CONFIG_QUERY_KEY, id],
    () => getRevenueShareConfig(paymentsAuthToken, id),
    {
      enabled: !!paymentsAuthToken && !!id,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useDefaultRevenueShareConfig = (organizationId = "") => {
  const paymentsAuthToken = usePaymentsAuthToken();

  const result = useQuery(
    [REVENUE_SHARE_CONFIG_QUERY_KEY, organizationId],
    () => listRevenueShareConfigs(paymentsAuthToken, organizationId),
    {
      enabled: !!paymentsAuthToken && !!organizationId,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return {
    ...result,
    data: (result.data || []).find((rs) => rs.configType === "DEFAULT"),
  };
};
