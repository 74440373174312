import { styled } from "@mui/material/styles";

// import { Theme } from "@mui/system";

import {
  default as MuiTableRow,
  // TableRowProps
} from "@mui/material/TableRow";

// interface Props extends TableRowProps {
//   theme?: Theme;
// }

const TableRowStyled = styled(MuiTableRow)(() => {
  // const palette = props.theme?.palette;
  return {
    height: 50,
  };
});

export default TableRowStyled;
