import React from "react";

import { Customer, CustomerMetadata } from "@busie/utils";
import { Modal } from "@busie/ui-kit";
import { CustomFieldForm } from "~/CustomersPage/entity";
import { useUpdateCustomerMetadata } from "./model";

interface Props {
  customerId: string;
  isOpen: boolean;
  metadata: CustomerMetadata;
  onClose: () => void;
  onSuccess: (customer: Customer) => void;
  onError: (e: unknown) => void;
}

export const UpdateCustomField: React.FC<Props> = ({
  isOpen,
  customerId,
  metadata,
  onClose,
  onSuccess,
  onError,
}) => {
  const { mutateAsync: updateMetadata } = useUpdateCustomerMetadata(
    customerId,
    metadata.id
  );

  const onSubmit = async (data: { key: string; value: string }) =>
    updateMetadata(data, { onSuccess, onError });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CustomFieldForm
        mode="edit"
        onClose={onClose}
        onSubmit={onSubmit}
        name={metadata.key}
        value={metadata.value}
      />
    </Modal>
  );
};
