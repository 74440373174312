import { FlexContainer } from "@busie/ui-kit";
import { styled } from "@mui/styles";

export const ContainerStyled = styled(FlexContainer)(() => {
  return {
    margin: "16px auto",
    padding: "42px 62px",
    "& .title": {
      marginBottom: "8px",
    },
  };
});
