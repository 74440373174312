import { styled } from "@mui/system";
import Tabs from "@mui/material/Tabs";

const TabsContainer = styled(Tabs)(() => {
  return {
    "&.MuiButton-root": {
      minHeight: "34px",
    },

    ".MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "flex-start",
      flexWrap: "nowrap",
      maxWidth: "100%",
    },
    ".MuiTabs-indicator": {
      display: "none",
    },
  };
});

export default TabsContainer;
