import React, { FC, useEffect, useState } from "react";
import { useRatesAuthToken } from "@busie/core";
import { Cookbook, COOKBOOK_TYPE_LABELS, CookbookType } from "@busie/utils";
import { Text, Loading, palette, Select } from "@busie/ui-kit";
import { fetchCookbooks } from "@busie/api";
import { useAuth0 } from "@auth0/auth0-react";
import { useMenu } from "~/rates/RateMenuPage/hooks";

interface Props {
  type: CookbookType;
  initialValue?: string;
  onSelect: (cookbookId: string) => void;
}

const CookbookTypeSelector: FC<React.PropsWithChildren<Props>> = ({
  type,
  onSelect,
  initialValue,
}) => {
  const [loading, setLoading] = useState(false);
  const [hasCookbooks, setHasCookbooks] = useState(false);
  const [cookbooks, setCookbooks] = useState<Cookbook[]>([]);
  const authToken = useRatesAuthToken();
  const { user } = useAuth0();
  const { data: menu } = useMenu(authToken, user);

  const cookbookTypeLabel = COOKBOOK_TYPE_LABELS[type];

  useEffect(() => {
    const getCookbooks = async () => {
      setLoading(true);
      const cookbooks = await fetchCookbooks(authToken, {
        type,
        menuId: menu?._id as string,
      }).then((response) => response.resultSet);

      setLoading(false);

      setHasCookbooks(cookbooks.length > 0);
      setCookbooks(cookbooks);
    };
    authToken && menu && getCookbooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, menu]);

  return (
    <div className="select-block">
      {loading && <Loading size={24} padding="5px 0" />}
      {!loading && hasCookbooks && (
        <Select
          disabled={loading}
          onChange={(e) => onSelect(e.target.value)}
          value={initialValue}
          items={cookbooks}
          getName={(item) => item.name}
          getValue={(item) => item.id}
        />
      )}
      {!loading && !hasCookbooks && (
        <Text color={palette.black.plus60}>
          No {cookbookTypeLabel} Cookbooks Available
        </Text>
      )}
    </div>
  );
};

export default CookbookTypeSelector;
