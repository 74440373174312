import React from "react";

import {
  DeleteIcon,
  EditIcon,
  FlexContainer,
  IconButton,
  Text,
  palette,
} from "@busie/ui-kit";

interface Props {
  name: string;
  value: string;
  onEdit: () => void;
  onDelete: () => void;
}

export const CustomField: React.FC<Props> = ({
  name,
  value,
  onEdit,
  onDelete,
}) => {
  const [isMouseover, setIsMouseover] = React.useState(false);

  return (
    <FlexContainer
      direction="row"
      fullWidth
      justify="flex-start"
      align="center"
      columnGap={1}
      onMouseEnter={() => setIsMouseover(true)}
      onMouseLeave={() => setIsMouseover(false)}
    >
      <FlexContainer
        direction="row"
        justify="flex-start"
        fullWidth
        columnGap={1}
      >
        <FlexContainer justify="flex-start" fullWidth>
          <Text sx={{ overflowX: "auto" }} color={palette.black.plus30}>
            {name}
          </Text>
        </FlexContainer>

        <FlexContainer justify="flex-end" fullWidth>
          <Text sx={{ overflowX: "auto" }} color={palette.black.main}>
            {value}
          </Text>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        direction="row"
        justify="flex-end"
        align="center"
        w={100}
        h={32}
      >
        {isMouseover && (
          <>
            <IconButton size={30} icon={<EditIcon />} onClick={onEdit} />
            {/* TODO: [BUSIE-1788]  */}
            {/* <IconButton
              size={30}
              icon={<DeleteIcon color={palette.red.main} />}
              onClick={onDelete}
            /> */}
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
