import { CreateTripData, Experience } from "@busie/api";
import { duplicate as duplicateAmenities } from "./duplicate-amenities";
import { duplicate as duplicateTripLeg } from "./duplicate-trip-leg";

export const duplicate = ({
  PASSENGERS,
  WHEELCHAIRS,
  ADDITIONAL_INFORMATION,
  MAIN_CONTACT,
  ORGANIZATION_ID,
  TRIP_PLANNER,
  GROUP,
  LEGS,
  AMENITIES,
  METERS,
  HOURS,
  SPAB,
}: Experience): CreateTripData => ({
  passengers: PASSENGERS,
  wheelchairs: WHEELCHAIRS,
  additionalInformation: ADDITIONAL_INFORMATION,
  mainContactId: MAIN_CONTACT.ID,
  organizationId: ORGANIZATION_ID,
  tripPlannerId: TRIP_PLANNER.ID,
  tripPlannerGroupId: GROUP.ID,
  legs: LEGS.map(duplicateTripLeg),
  amenities: duplicateAmenities(AMENITIES),
  meters: METERS,
  hours: HOURS,
  spab: SPAB,
});
