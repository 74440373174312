import React from "react";

import { Experience } from "@busie/api";
import {
  ArrowRight,
  Button,
  Divider,
  FlexContainer,
  H2,
  H3,
  H4,
  Loading,
  PlusIcon,
  palette,
} from "@busie/ui-kit";
import { centsToDollars, currencyFormatter } from "@busie/utils";
import { getDestinationAddress, getPickupAddress } from "../../lib";
import { Subtitle } from "../Subtitle";

interface Props {
  experiences: Experience[];
  subtotalInput: React.ReactNode;
  onAddTrip: () => void;
}

export const Experiences: React.FC<Props> = ({
  experiences,
  subtotalInput,
  onAddTrip,
}) => {
  return (
    <FlexContainer
      px={2}
      direction="column"
      align="flex-start"
      rowGap={1.25}
      fullWidth
    >
      <Subtitle>Trips</Subtitle>
      {experiences.map((experience) => (
        <FlexContainer direction="column" fullWidth>
          <FlexContainer
            key={experience._ID}
            direction="row"
            align="center"
            justify="space-between"
            fullWidth
          >
            {experience.LEGS.length ? (
              <FlexContainer
                direction="row"
                columnGap={1}
                justify="flex-start"
                align="center"
                fullWidth
              >
                <H4>{getPickupAddress(experience)}</H4>
                <ArrowRight color={palette.black.main} size={16} />

                <H4>{getDestinationAddress(experience)}</H4>
              </FlexContainer>
            ) : (
              <Loading size={10} padding="0px" />
            )}

            <H3>{currencyFormatter(centsToDollars(experience.PRICE))}</H3>
          </FlexContainer>
          <Divider margin={1} />
        </FlexContainer>
      ))}

      <Button
        startIcon={<PlusIcon />}
        typestyle="tertiary"
        size="small"
        onClick={onAddTrip}
      >
        Add Trip
      </Button>

      <FlexContainer
        py={1.5}
        direction="row"
        align="center"
        justify="space-between"
        fullWidth
      >
        <FlexContainer fullWidth>
          <H2 weight={600}>Subtotal</H2>
        </FlexContainer>

        {subtotalInput}
      </FlexContainer>
    </FlexContainer>
  );
};
