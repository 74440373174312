import React from "react";
import { observer } from "mobx-react-lite";
import { styled } from "@mui/system";

import {
  Button,
  Popup,
  FlexContainer,
  PlaceFieldHookForm,
  TextInput,
  LocationIcon,
  Title,
} from "@busie/ui-kit";
import { submitLocationStore } from "~/settings/store/dispatch";
import { FormData } from "~/settings/store/dispatch/submit";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

const FormWrapper = styled("form")(() => ({
  width: "100%",
}));

export const SubmitLocationPopup: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<ReturnType<SubmitHandler<FormData>>>({
      mode: "onChange",
      defaultValues: submitLocationStore.formData,
    });

    return (
      <Popup
        title={
          <Title>
            {submitLocationStore.isEdit ? "Edit location" : "Create location"}
          </Title>
        }
        isPopupOpened={submitLocationStore.isModalOpen}
        onClose={() => {
          reset();
          submitLocationStore.closeCreateModal();
        }}
        zIndex="999"
      >
        <FlexContainer w={480} px={5} pb={5} rowGap={3} direction="column">
          <FormWrapper
            onSubmit={handleSubmit(submitLocationStore.submitDispatchLocation)}
          >
            <Controller
              name="name"
              control={control}
              rules={{
                required: "The name is required",
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  required={true}
                  label="Name"
                  placeholder="e.g. Home"
                  errorMessage={errors.name?.message}
                />
              )}
            />

            <Controller
              name="location"
              control={control}
              rules={{
                validate: (v) =>
                  (!!v.formatted_address && v.address_components.length >= 5) ||
                  "The address must include a street address",
              }}
              render={({ field }) => (
                <PlaceFieldHookForm
                  {...field}
                  label="Select address"
                  placeholder="Dispatch location"
                  startAdornment={<LocationIcon />}
                  error={errors.location}
                />
              )}
            />
            <FlexContainer justify="flex-end" columnGap={2} fullWidth>
              <Button
                typestyle="secondary"
                onClick={() => {
                  reset();
                  submitLocationStore.closeCreateModal();
                }}
              >
                Cancel
              </Button>
              <Button
                typestyle="primary"
                type="submit"
                loading={submitLocationStore.isSubmitting}
              >
                Save
              </Button>
            </FlexContainer>
          </FormWrapper>
        </FlexContainer>
      </Popup>
    );
  });
