import React, { useEffect, useState } from "react";
import {
  Controller,
  Resolver,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { Tooltip, useMediaQuery } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { yupResolver } from "@hookform/resolvers/yup";

import { Experience } from "@busie/api";
import {
  H2,
  ScrollableFlexContainer,
  theme,
  Button,
  SplitIcon,
  PlusIcon,
  palette,
  FlexContainer,
  H3,
  ToggleSwitch,
  TooltipIcon,
  InfoIcon,
} from "@busie/ui-kit";
import { DispatchLocation } from "@busie/utils";
import { notificationStore } from "@busie/features";
import { useAmplitude } from "@busie/core";

import { CreateCustomer } from "~/CustomersPage/features";
import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import {
  invalidateQnbQueries,
  useDispatchLocations,
  useTripRoute,
} from "~/QuotesAndBookingsPage/model";

import { FormValues, MAX_PASSENGERS, MAX_WHEELCHAIRS, schema } from "./model";
import {
  addStopHandlerFactory,
  createDefaultFormValues,
  createTripRoutePayload,
  getUpdateTripPayload,
  serializePlaceForForm,
  successHandlerFactory,
} from "./lib";
import {
  AdditionalInformationContainer,
  ControlledAmountField,
  ControlledDatetimePicker,
  ControlledDispatchSelect,
  ControlledMainContactSelect,
  ControlledPlaceField,
  ControlledTextArea,
  DepartureDatetimeText,
  Footer,
  LegContainer,
  MainContactContainer,
  PassengerInformationContainer,
  RouteInformationContainer,
  StopAddress,
} from "./ui";
import { SplitTrip } from "~/QuotesAndBookingsPage/features";
import { useUpdateTrip } from "~/QuotesAndBookingsPage/entity";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface Props {
  isOpen: boolean;
  trip: Experience;
  onClose: () => void;
  onSuccess: () => void;
}

export const Widget: React.FC<Props> = ({
  isOpen,
  trip,
  onClose,
  onSuccess,
}) => {
  const { track } = useAmplitude();
  const isMobile = useMediaQuery("@media (max-width: 950px)");
  const [isCreateCustomerOpen, setIsCreateCustomerOpen] = useState(false);
  const [isSplitTripOpen, setIsSplitTripOpen] = useState(false);
  const [isSplitDisabled, setIsSplitDisabled] = useState(false);

  const [splitIndex, setSplitIndex] = useState<number>();

  const [dispatchLocationMap, setDispatchLocationMap] = useState<{
    [id: string]: DispatchLocation;
  }>({});

  const { data: dispatchLocations, isLoading: isDispatchLocationsLoading } =
    useDispatchLocations();

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    register,
    reset,
    formState: { isValid, isDirty, isSubmitting, errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: createDefaultFormValues(trip),
    resolver: yupResolver(schema) as Resolver<FormValues>,
  });

  const {
    fields: stops,
    append: appendStop,
    remove: removeStop,
    insert: insertStop,
    update: updateStop,
  } = useFieldArray({
    control,
    name: "stops",
  });

  const {
    fields: departures,
    append: appendDeparture,
    remove: removeDeparture,
    insert: insertDeparture,
  } = useFieldArray({ control, name: "departures" });

  useEffect(() => {
    setDispatchLocationMap(
      dispatchLocations.reduce((acc, cv) => ({ ...acc, [cv.id]: cv }), {})
    );
  }, [dispatchLocations]);

  const [{ MAIN_VEHICLE_TYPE }] = trip.LEGS;

  const defaultDispatchData = {
    latitude: trip.LEGS[0].START_LOCATION.LATITUDE,
    longitude: trip.LEGS[0].START_LOCATION.LONGITUDE,
  };

  const { data: route, isLoading: isRouteLoading } = useTripRoute(
    createTripRoutePayload(
      stops,
      dispatchLocationMap[getValues("dispatchId")] || defaultDispatchData,
      MAIN_VEHICLE_TYPE || "MOTOR_COACH"
    )
  );

  const { mutateAsync: updateTrip } = useUpdateTrip(trip._ID);

  const [count, setCount] = useState(0);

  // A hack to get the route to update when the form is opened/closed.
  // Otherwise, there is a race condition between the form setting the default values,
  // and the route being fetched.
  // unfortunately, this does cause the form to "dirty" itself, but it's the best solution for now.
  useEffect(() => {
    setCount((c) => c + 1);
  }, [isOpen]);

  // Disable the split button if the user has changed the stops
  useEffect(() => {
    const defaults = createDefaultFormValues(trip);

    const addresses = new Set(defaults.stops.map((s) => s.formatted_address));

    if (stops.some((s) => !addresses.has(s.formatted_address))) {
      setIsSplitDisabled(true);
    }

    return () => setIsSplitDisabled(false);
  }, [stops, trip]);

  useEffect(() => {
    if (route) setValue("route", route, { shouldValidate: true });
  }, [route, setValue, count]);

  useEffect(
    () => () => reset(createDefaultFormValues(trip)),
    [reset, isOpen, trip]
  );

  const onAddStop = addStopHandlerFactory(
    appendStop,
    appendDeparture,
    insertStop,
    insertDeparture
  );

  const handleSuccess = successHandlerFactory(
    notificationStore,
    track,
    onSuccess
  );

  const onSubmit = async (data: FormValues) => {
    await updateTrip(getUpdateTripPayload(data), {
      onSuccess: (data) => {
        invalidateQnbQueries();
        handleSuccess(data);
      },
      onError: (e) => notificationStore.setNotificationFromError(e),
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FormValues>)}>
          <H2>Edit Trip</H2>

          <ScrollableFlexContainer
            direction="column"
            rowGap={isMobile ? 2 : 3}
            minW={isMobile ? "375px" : "500px"}
            maxW="740px"
            py={4}
            sx={{
              margin: isMobile ? 0 : "16px auto 0",
              [theme.breakpoints.down("tablet")]: {
                padding: "15px 25px",
              },
            }}
            align="flex-start"
          >
            <MainContactContainer>
              <ControlledMainContactSelect
                control={control}
                name="mainContact"
                groupId={trip.GROUP.ID}
                onCreateCustomer={() => setIsCreateCustomerOpen(true)}
              />
            </MainContactContainer>

            <AdditionalInformationContainer>
              <ControlledTextArea
                control={control}
                label="Additional Information"
                name="additionalInformation"
              />
              <Controller
                name="spab"
                control={control}
                render={({ field }) => (
                  <FlexContainer
                    direction="column"
                    rowGap={1}
                    align="flex-start"
                  >
                    <H3 color={palette.black.plus30}>
                      {field.value ? "T" : "Not t"}ransporting K-12 School
                      Pupils
                    </H3>

                    <FlexContainer
                      direction="row"
                      align="center"
                      justify="flex-start"
                      columnGap={2}
                    >
                      <ToggleSwitch
                        checked={field.value}
                        onChange={(ev) => field.onChange(ev.target.checked)}
                      />

                      <Tooltip
                        title="In certain jurisdictions the transportation of K-12 School Pupils requires additional certifications for Drivers and Vehicles."
                        placement="top"
                      >
                        <TooltipIcon>
                          <InfoIcon size={24} color={palette.black.plus30} />
                        </TooltipIcon>
                      </Tooltip>
                    </FlexContainer>
                  </FlexContainer>
                )}
              />
            </AdditionalInformationContainer>

            <PassengerInformationContainer
              passengers={
                <ControlledAmountField
                  name="passengers"
                  label="Passengers"
                  control={control}
                  min={getValues("wheelchairs") || 0}
                  max={MAX_PASSENGERS}
                  errorMessage={errors.passengers?.message}
                />
              }
              wheelchairs={
                <ControlledAmountField
                  control={control}
                  name="wheelchairs"
                  label="Accessible Seating Requirements"
                  min={0}
                  max={MAX_WHEELCHAIRS}
                  errorMessage={errors.wheelchairs?.message}
                />
              }
            />

            <RouteInformationContainer isLoading={isDispatchLocationsLoading}>
              {stops.map(({ id, ...place }, index, arr) => (
                <LegContainer
                  key={id}
                  start={
                    index === 0 ? (
                      <ControlledDispatchSelect
                        name="dispatchId"
                        control={control}
                        dispatchLocations={dispatchLocations}
                        disabled={isRouteLoading}
                      />
                    ) : (
                      <StopAddress
                        address={
                          getValues(`stops.${index - 1}`).formatted_address
                        }
                      />
                    )
                  }
                  departure={
                    index === 0 ? (
                      <DepartureDatetimeText date={trip.START_DATE} />
                    ) : (
                      <ControlledDatetimePicker
                        name={`departures.${index - 1}`}
                        control={control}
                        ampm={true}
                      />
                    )
                  }
                  destination={
                    <ControlledPlaceField
                      place={place}
                      name={`stops.${index}`}
                      register={register}
                      label={`Stop ${index + 1}`}
                      disabled={isRouteLoading}
                      onChange={(value) =>
                        updateStop(index, serializePlaceForForm(value))
                      }
                    />
                  }
                  onDelete={
                    index === 0 || index === stops.length - 1
                      ? undefined
                      : () => {
                          removeStop(index);
                          removeDeparture(index - 1);
                        }
                  }
                  actions={[
                    <Button
                      startIcon={<PlusIcon color={palette.black.plus100} />}
                      typestyle="primary"
                      onClick={() =>
                        onAddStop(place, departures[index - 1], index, arr)
                      }
                    >
                      Add Stop
                    </Button>,
                    index > 0 && index <= trip.LEGS.length - 3 && (
                      <FlexContainer direction="row" columnGap={1}>
                        <Button
                          startIcon={
                            <SplitIcon
                              color={
                                isSplitDisabled
                                  ? palette.black.plus60
                                  : palette.black.main
                              }
                            />
                          }
                          typestyle="secondary"
                          disabled={isSplitDisabled}
                          onClick={() => {
                            setSplitIndex(index + 1);
                            setIsSplitTripOpen(true);
                          }}
                        >
                          Split
                        </Button>
                      </FlexContainer>
                    ),
                  ]}
                />
              ))}

              <LegContainer
                start={
                  <StopAddress
                    address={
                      getValues(`stops.${stops.length - 1}`).formatted_address
                    }
                  />
                }
                departure={
                  <DepartureDatetimeText
                    date={trip.LEGS[trip.LEGS.length - 1].DEPARTURE_DATETIME}
                  />
                }
                destination={dispatchLocationMap[getValues("dispatchId")]?.name}
              />
            </RouteInformationContainer>
          </ScrollableFlexContainer>

          <Footer
            price={trip.PRICE}
            controls={
              <>
                <Button onClick={onClose} typestyle="secondary">
                  Cancel
                </Button>

                <Button
                  disabled={
                    !isDirty || !isValid || isRouteLoading || isSubmitting
                  }
                  type="submit"
                  typestyle="primary"
                  loading={isSubmitting || isRouteLoading}
                >
                  Save
                </Button>
              </>
            }
          />
        </form>
      </Modal>

      <CreateCustomer
        visible={isCreateCustomerOpen}
        hide={() => setIsCreateCustomerOpen(false)}
        onCreate={(customer) => setValue("mainContact", customer.id)}
        organizationId={trip.GROUP.ID}
      />

      {splitIndex && (
        <SplitTrip
          isOpen={isSplitTripOpen}
          onClose={() => setIsSplitTripOpen(false)}
          onSuccess={() => {
            setSplitIndex(undefined);
            setIsSplitTripOpen(false);
            onSuccess();
          }}
          trip={trip}
          splitIndex={splitIndex}
        />
      )}
    </LocalizationProvider>
  );
};
