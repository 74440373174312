import React from "react";
import { CalculateIcon, FlexContainer, IconButton } from "@busie/ui-kit";
import { centsToDollars, currencyFormatter, Quote, Trip } from "@busie/utils";
import { Experience } from "@busie/api";

interface Props {
  trip: Experience;
  onClick: () => void;
}

export const TripPrice: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  onClick,
}: Props) => {
  const price = currencyFormatter(centsToDollars(Number(trip.PRICE)));

  return (
    <FlexContainer align="center" columnGap={2}>
      {price}
      <IconButton icon={<CalculateIcon color="black" />} onClick={onClick} />
    </FlexContainer>
  );
};
