import { styled } from "@mui/system";

import { Theme } from "@mui/system";

interface Props {
  theme: Theme;
  fullwidth?: boolean;
}

export const LegDateTimeStyled = styled("div")((props: Props) => {
  const palette = props.theme.palette;
  return {
    marginTop: "4px",
    color: palette?.black?.plus30,
    fontWeight: 500,
    fontSize: "10px",
  };
});
