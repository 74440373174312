import React from "react";

import { Modal } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";

import { CustomerForm } from "~/CustomersPage/entity";

import { useCreateCustomer } from "./model";
import { Customer } from "@busie/utils";

interface Props {
  visible: boolean;
  hide(): void;
  organizationId?: string;
  onCreate?(customer: Customer): void;
}

const CREATE_GROUP_RGX = /^Create ".+"$/;

export const CreateCustomer: React.FC<React.PropsWithChildren<Props>> = ({
  visible,
  hide,
  onCreate,
  organizationId,
}) => {
  const { mutateAsync } = useCreateCustomer();
  return (
    <Modal isOpen={visible}>
      <CustomerForm
        onSubmit={async ({ name, email, phoneNumber, organization }) => {
          try {
            const shouldCreateGroup = CREATE_GROUP_RGX.test(organization.label);
            const customer = await mutateAsync({
              name,
              email,
              phoneNumber,
              organization: organization.value || organization.label,
              // HOTFIX: BUSIE-1353
              shouldCreateGroup,
            });

            !!onCreate && onCreate(customer);
            notificationStore.setSuccessNotification(
              "Success",
              `Customer ${name} was created. ${
                shouldCreateGroup
                  ? `Organization ${organization.label.replace(
                      "Create ",
                      ""
                    )} was created.`
                  : ""
              }`
            );
            hide();
          } catch (e) {
            notificationStore.setNotificationFromError(e);
          }
        }}
        onCancel={hide}
        organizationId={organizationId}
      />
    </Modal>
  );
};
