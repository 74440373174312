import { styled } from "@mui/system";

import { Theme } from "@mui/system";
interface Props {
  theme?: Theme;
}

const RateRuleFormStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    padding: "0 40px 24px",
    display: "flex",
    columnGap: "24px",
    justifyContent: "space-between",
    background: palette?.black?.plus80,
    "> div": {
      display: "block",
      width: "100%",
      // maxWidth: props.itemsmaxwidth,
    },
  };
});

export default RateRuleFormStyled;
