import {
  editReservation,
  createReservation,
  createReservationComment,
  getDispatchLocation,
  getReservationComments,
  Comment,
  fetchTrip,
} from "@busie/api";
import { DispatchLocation, Reservation, Trip } from "@busie/utils";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";

export const useComments = (
  vehiclesAuthToken: string,
  reservation: Reservation | null
): UseQueryResult<Comment[], Error> => {
  return useQuery<Comment[], Error>(
    ["comments", reservation?.id],
    () => getReservationComments(vehiclesAuthToken, reservation?.id as string),
    {
      enabled: !!vehiclesAuthToken && !!reservation,
    }
  );
};

export const useDispatchLocation = (
  dispatchLocationAuthToken: string,
  dispatchLocationId: string | undefined
): UseQueryResult<DispatchLocation> => {
  return useQuery(
    ["dispatchLocation", dispatchLocationId],
    () =>
      getDispatchLocation(
        dispatchLocationId as string,
        dispatchLocationAuthToken
      ),
    {
      enabled: !!dispatchLocationId && !!dispatchLocationAuthToken,
    }
  );
};

export const useTrip = (
  tripsAuthToken: string,
  tripId: string | undefined
): UseQueryResult<Trip> => {
  return useQuery(
    ["trip", tripId],
    () => fetchTrip(tripsAuthToken, tripId as string, { populateLegs: true }),
    {
      enabled: !!tripsAuthToken && !!tripId,
    }
  );
};

export const useCommentsMutation = (
  vehiclesAuthToken: string,
  reservationId: string,
  onSuccess: (comment: Comment) => void
): UseMutationResult<Comment, unknown, string, unknown> => {
  return useMutation(
    (newComment: string) =>
      createReservationComment(vehiclesAuthToken, reservationId, newComment),
    {
      onSuccess,
    }
  );
};

export const useReservationMutation = (
  vehiclesAuthToken: string,
  onSuccess: (reservation: Reservation) => void
) => {
  return useMutation(
    (reservation: Reservation) => {
      const {
        id,
        startAt,
        endAt,
        type,
        vehicleId,
        description,
        driverId,
        status,
      } = reservation;
      if (!reservation.id) {
        return createReservation(vehiclesAuthToken, reservation);
      } else {
        return editReservation(
          vehiclesAuthToken,
          id,
          startAt,
          endAt,
          type,
          vehicleId,
          description,
          driverId,
          status
        );
      }
    },
    {
      onSuccess,
    }
  );
};
