import { styled } from "@mui/system";

const Container = styled("div")(({ theme }) => {
  return {
    padding: "0 40px 20px",
    height: "fit-content",
    [theme.breakpoints.down("tablet")]: {
      padding: "0 8px",
    },
  };
});

export default Container;
