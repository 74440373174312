import React, { useState } from "react";

import { H2, InputText, palette, Button, FlexContainer } from "@busie/ui-kit";
import { Integration } from "@busie/utils";

import { QuoteIntegrationsStore } from "~/settings/store";

import {
  NotificationBlockStyled,
  Icon,
  MessageBlock,
  Controls,
} from "./styled";

interface Props {
  integration: Integration;
  onChange: () => Promise<void>;
  index: number;
}

export const NotificationBlock: React.FC<React.PropsWithChildren<Props>> = ({
  integration,
  onChange,
  index,
}) => {
  const [loading, setLoading] = useState(false);

  const onAccept = async () => {
    setLoading(true);
    await QuoteIntegrationsStore.approveIntegration(integration.id);
    await onChange();
  };

  const onReject = async () => {
    setLoading(true);
    await QuoteIntegrationsStore.rejectIntegration(integration.id);
    await onChange();
  };

  return (
    <NotificationBlockStyled
      p={5}
      columnGap={5}
      fullWidth
      align="center"
      index={index}
    >
      <FlexContainer columnGap={5} fullWidth>
        <Icon src={integration.logo || ""} />
        <MessageBlock>
          <H2 weight={600}>New request</H2>
          <InputText margin={"3px 0 0"} color={palette.black.plus50}>
            Your received new request from {integration.organizationName}{" "}
            organization, you need to approve it to enable
          </InputText>
        </MessageBlock>
      </FlexContainer>
      <Controls columnGap={2}>
        <Button onClick={onAccept} disabled={loading} async>
          Accept
        </Button>
        <Button
          typestyle="secondary"
          onClick={onReject}
          disabled={loading}
          async
        >
          Cancel
        </Button>
      </Controls>
    </NotificationBlockStyled>
  );
};
