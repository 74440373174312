import { palette } from "@busie/ui-kit";
import { styled } from "@mui/system";

export const TripTypeStyled = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "13.33px 14.33px",
    columnGap: "9.33px",
    borderRadius: "4px",
    backgroundColor: palette.supernova.plus40 || "#F1F1F1",
    color: palette.blue.minus10 || "#00000",
  };
});
