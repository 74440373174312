/* eslint-disable indent */
import React from "react";
import get from "lodash.get";
import { observer } from "mobx-react-lite";
import { useLocation, useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  datetimeFormatter as dateFormat,
  currencyFormatter,
  centsToDollars,
  metersToMiles,
  DispatchLocation,
} from "@busie/utils";
import {
  palette,
  CommonTable,
  TableContainer,
  Loading,
  TablePagination,
  FlexContainer,
  DocumentsIcon,
} from "@busie/ui-kit";
import { ITEMS_PER_PAGE, bookingsStore } from "~/QuotesAndBookingsPage/model";
import {
  StatusBadge,
  TableControls,
  TTLDonut,
  MobileList,
  OrganizationLogo,
} from "~/QuotesAndBookingsPage/shared/ui";
import { BookingsResponse, BookingWithAttrs } from "@busie/api";

import { ACCEPTED_STATUSES, FILTER_TYPE_OPTIONS } from "./model";

interface Props {
  bookings: BookingWithAttrs[];
  bookingsResponse: BookingsResponse | null;
  dispatchLocations: DispatchLocation[];
  currentPage: number;
  setPage: (page: number) => void;
}

export const Table: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    bookings,
    bookingsResponse,
    dispatchLocations,
    currentPage,
    setPage,
  }: Props) => {
    const { search } = useLocation();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

    const searchParams = new URLSearchParams(search);
    const filterStatus = searchParams.get("status");

    const handlerOnSetTrip = (id: string) => {
      searchParams.set("bookingId", id);
      searchParams.set("page", currentPage.toString());

      history.replace({
        search: `?${searchParams.toString()}`,
      });
    };

    React.useEffect(() => {
      return () => {
        bookingsStore.reset();
      };
    }, []);
    const tableConfig = {
      columnNames: [
        { id: "booking-id", name: "Booking ID", ordered: false },
        { id: "customer", name: "Customer", ordered: false },
        { id: "passengers", name: "Passengers", ordered: false },
        { id: "trip-distance", name: "Trip Distance", ordered: false },
        { id: "pickup-date", name: "Pick Up Date", ordered: false },
        { id: "total-price", name: "Total Price", ordered: false },
        { id: "created", name: "Created", ordered: false },
        { id: "information", name: "Information", ordered: false },
        { id: "status", name: "Status", ordered: false },
      ],
      checkboxKeys: bookings.map((item) => {
        return item["_id"];
      }),
      data: bookings.map((item) => {
        const trip = item.trip;
        const integration = bookingsStore.getIntegration(
          item._quote.quotesIntegrationId || "",
          item._quote.organizationId
        );

        if (!trip) return {};

        return {
          id: (
            <span style={{ color: palette.red?.plus5 }}>
              {item["_id"].split("-")[0]}
            </span>
          ),
          customer: item.tripPlanner?.name,
          passengers: trip._passengers,
          miles: `${metersToMiles(trip._meters) || 0} mi`,
          departureDateTime: dateFormat(
            new Date(trip._startDate as string),
            "date"
          ),
          price: currencyFormatter(centsToDollars(item._quote.totalPrice || 0)),
          createdAt: item["_createdAt"]
            ? dateFormat(new Date(item["_createdAt"]), "datetime")
            : "",
          information:
            integration ||
            item._quote.quotesIntegrationId ||
            item._quote.bookingRequested ? (
              <FlexContainer direction="row" columnGap={2} align="center">
                {integration ? (
                  <OrganizationLogo src={integration.logo} />
                ) : null}
                {item._quote.quotesIntegrationId ? (
                  <TTLDonut expirationDate={item._quote.expirationDate} />
                ) : null}
                {item._quote.bookingRequested ? <DocumentsIcon /> : null}
              </FlexContainer>
            ) : null,
          status: <StatusBadge type={item["_status"]} />,
        };
      }),
    };

    const TableComponent = isMobile ? MobileList : CommonTable;

    return (
      <TableContainer sx={{ marginTop: "16px" }} isMobile={isMobile}>
        <div>
          <TableControls
            filterTypeOptions={FILTER_TYPE_OPTIONS}
            type="booking"
            dispatchLocations={dispatchLocations}
            acceptedStatuses={ACCEPTED_STATUSES}
          />
          <div className="table-container">
            {bookingsStore.isFetching ? (
              <Loading />
            ) : (
              <TableComponent
                columnNames={tableConfig.columnNames}
                data={tableConfig.data}
                withCheckbox={!!filterStatus && filterStatus !== "ALL"}
                selectedRows={bookingsStore.selectedItems}
                checkboxKeys={tableConfig.checkboxKeys}
                onRowClick={(id: string) => handlerOnSetTrip(id)}
                onRowsSelect={(rows: string[]) => {
                  bookingsStore.addSelectedItems(rows);
                }}
                onRowsRemove={(rows: string[]) => {
                  bookingsStore.removeSelectedItems(rows);
                }}
              />
            )}
          </div>
        </div>
        {bookingsResponse && (
          <TablePagination
            itemsTotal={bookingsResponse.total}
            pageNumber={currentPage}
            perPage={ITEMS_PER_PAGE}
            currentOffset={ITEMS_PER_PAGE * (currentPage - 1)}
            nextOffset={bookingsResponse.next || bookingsResponse.total}
            onNextClick={() => setPage(currentPage + 1)}
            onPrevClick={() => setPage(currentPage - 1)}
            onToEndClick={() =>
              setPage(Math.ceil(bookingsResponse.total / ITEMS_PER_PAGE))
            }
            onToBeginningClick={() => setPage(1)}
          />
        )}
      </TableContainer>
    );
  }
);

export default Table;
