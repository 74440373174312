import { dayjsExt } from "@busie/utils";

interface DonutStyleProps {
  value: number;
  size: number;
  color:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
}

export const getExpirationDateDonutProps = (
  expirationDateString?: string
): DonutStyleProps => {
  if (!expirationDateString) {
    return {
      value: 25,
      color: "error",
      size: 100,
    };
  }

  const expirationDate = dayjsExt(expirationDateString);
  const now = dayjsExt();

  if (expirationDate.diff(now, "hour") <= 12) {
    return {
      value: 25,
      color: "error",
      size: 100,
    };
  } else if (expirationDate.diff(now, "hour") <= 16) {
    return {
      value: 50,
      color: "warning",
      size: 100,
    };
  } else {
    return {
      value: 75,
      color: "success",
      size: 100,
    };
  }
};
