import React from "react";
import { styled } from "@mui/system";

import {
  palette,
  MenuHeader,
  Button,
  FlexContainer,
  StatisticsBlock,
  theme,
  Text,
} from "@busie/ui-kit";
import { mapVehicleTypeToName, VehicleIcon } from "@busie/core";
import { dayjsExt } from "@busie/utils";
import store from "~/vehicles/store";
import groupBy from "lodash.groupby";
import { useMediaQuery } from "@mui/material";

const Proportion = styled("div")(() => {
  return {
    "&": {
      color: palette?.black?.plus50,
      ".black": {
        color: palette?.black?.main,
      },
    },
  };
});

const PageHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const vehicles = store.vehicles;
  const availableVehicles = vehicles.filter(
    (vehicle) =>
      !vehicle.reservations?.some(
        (res) =>
          dayjsExt(res.startAt).isBefore(dayjsExt()) &&
          dayjsExt(res.endAt).isAfter(dayjsExt())
      )
  );
  const vehiclesPerType = groupBy(vehicles, "vehicleType.type");
  const isTablet = useMediaQuery("@media (max-width: 1124px)");

  const stats = [
    {
      text: "Total vehicle",
      value: vehicles.length,
      startIcon: undefined,
    },
    {
      text: "Total available",
      value: availableVehicles.length,
      startIcon: undefined,
    },
    ...Object.keys(vehiclesPerType)
      .filter(() => !isTablet)
      .map((vehicleType) => {
        const type = parseInt(vehicleType);
        const availableVehiclesOfType = availableVehicles.filter(
          (v) => v.vehicleType.type === type
        );
        return {
          text: mapVehicleTypeToName(type),
          value: (
            <Proportion>
              <span className="black">{availableVehiclesOfType.length}</span>/
              {vehiclesPerType[vehicleType].length}
            </Proportion>
          ),
          startIcon: <VehicleIcon type={type} />,
        };
      }),
  ];

  return (
    <MenuHeader
      sx={{
        paddingBottom: theme.spacing(2),
      }}
    >
      <FlexContainer columnGap={isTablet ? 3 : 5}>
        {stats.map((item, index) => {
          if (index > 6) return null;
          return (
            <StatisticsBlock
              key={index}
              text={item.text}
              value={item.value}
              startIcon={item.startIcon}
            />
          );
        })}
      </FlexContainer>
      <FlexContainer
        justify="space-between"
        columnGap={0}
        align="center"
        w={256}
      >
        {(stats.length > 7 || isTablet) && (
          <Text color={palette?.black?.plus20}>
            Full fleet here{" "}
            <span role="img" aria-label="here">
              👉
            </span>
          </Text>
        )}
        <div>
          <Button href="/vehicles/my-vehicles" size="small">
            My vehicles
          </Button>
        </div>
      </FlexContainer>
    </MenuHeader>
  );
};

export default PageHeader;
