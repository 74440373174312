import { User } from "@auth0/auth0-react";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import { useEffect } from "react";

interface Props extends RumInitConfiguration {
  enabled?: boolean | undefined;
  sessionReplayRecording?: boolean | undefined;
  user?: User | undefined;
  enableHeatmap?: boolean | undefined;
  enableFeatureFlags?: boolean | undefined;
}

export const useDatadog = ({
  applicationId,
  clientToken,
  service,
  env,
  version,
  sampleRate,
  trackInteractions,
  defaultPrivacyLevel,
  site = "datadoghq.com",
  enabled = true,
  sessionReplayRecording = true,
  user = {},
  enableHeatmap = false,
  enableFeatureFlags = false,
  ...restInitProps
}: Readonly<Props>): void => {
  useEffect(() => {
    if (!enabled) return;

    const enableExperimentalFeatures = [];
    if (enableHeatmap) enableExperimentalFeatures.push("clickmap");
    if (enableFeatureFlags) enableExperimentalFeatures.push("feature_flags");

    datadogRum.init({
      applicationId,
      clientToken,
      site,
      service,
      env,
      version,
      sampleRate,
      trackInteractions,
      defaultPrivacyLevel,
      enableExperimentalFeatures,
      ...restInitProps,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationId,
    clientToken,
    site,
    service,
    env,
    version,
    sampleRate,
    trackInteractions,
    defaultPrivacyLevel,
  ]);

  useEffect((): VoidFunction | undefined => {
    if (!enabled || !sessionReplayRecording) return;

    datadogRum.startSessionReplayRecording();

    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  }, [enabled, sessionReplayRecording]);

  // allow useEffect to check for deep equality
  const stringifiedUser = JSON.stringify(user);
  useEffect((): VoidFunction | undefined => {
    if (!enabled) return;

    // don't add user to datadog session if every user property is undefined.
    if (Object.values(user).every((v) => v === undefined)) return;

    datadogRum.setUser({ ...user });

    return () => {
      datadogRum.removeUser();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedUser]);
};
