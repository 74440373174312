import React from "react";

import { TableControlsStyled } from "./styled";

interface Props {
  children: React.ReactNode;
  isMobile?: boolean;
}

export const TableControls: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isMobile = false,
}) => {
  return (
    <TableControlsStyled isMobile={isMobile}>{children}</TableControlsStyled>
  );
};
