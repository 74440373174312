import React from "react";
import { dayjsExt, DayjsExt } from "@busie/utils";
import { Box } from "@mui/material";
import { PageLoader, Panel } from "@busie/ui-kit";
import { FlexContainer } from "@busie/ui-kit";
import { theme } from "@busie/ui-kit";
import { palette } from "@busie/ui-kit";
import WeekDayStyled from "./styled/weekDay.styled";
import VehiclesStore from "~/vehicles/store";
import { observer } from "mobx-react";
import useCalendarCells from "./hooks/useCalendarCells";
import { endOfMonth, startOfMonth } from "date-fns";

const CalendarMonthView: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const weekdaysShort = dayjsExt.weekdaysShort();
    const weeksInMonth = 5;

    const store = VehiclesStore;
    const reservationsAreFetched = store.reservationsAreFetched;
    const reservations = store.reservations;
    const vehicles = store.vehicles;
    const availability = store.availabilityPercentage;
    const [activeDay, setActiveDay] = React.useState<DayjsExt>();

    // Passing values straight from the store to the hook causes it to run infinitely
    const [startDate] = React.useState<Date>(
      store.reservationsFilter.startAt || startOfMonth(new Date())
    );
    const [endDate] = React.useState<Date>(
      store.reservationsFilter.endAt || endOfMonth(new Date())
    );

    const totalSlots = useCalendarCells(
      startDate,
      endDate,
      reservations,
      vehicles,
      setActiveDay,
      availability,
      activeDay
    );

    if (!reservationsAreFetched) return <PageLoader />;

    return (
      <Box sx={{ width: "100%", paddingBottom: "40px" }}>
        <Panel padding="0" direction="column">
          <FlexContainer
            w="100%"
            sx={{
              marginBottom: theme?.spacing(1),
              backgroundColor: palette?.black?.plus80,
              padding: `0 ${theme?.spacing(1)}`,
            }}
          >
            {weekdaysShort.map((day) => {
              return <WeekDayStyled key={day}>{day}</WeekDayStyled>;
            })}
          </FlexContainer>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(7, 1fr)",
              gridTemplateRows: `repeat(${weeksInMonth}, 1fr)`,
              backgroundColor: palette?.black?.plus70,
              gap: "1px",
              paddingTop: "1px",
            }}
          >
            {totalSlots}
          </Box>
        </Panel>
      </Box>
    );
  }
);

CalendarMonthView.displayName = "Calendar View";
export default CalendarMonthView;
