import { CalloutStyled } from "./styled";

interface Props {
  children: React.ReactNode;
  color?: string;
}
export const Callout: React.FC<React.PropsWithChildren<Props>> = ({
  color,
  children,
}) => {
  return <CalloutStyled color={color}>{children}</CalloutStyled>;
};
