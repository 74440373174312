/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReservationTypes, Vehicle } from "@busie/utils";
import {
  Button,
  CloseIcon,
  FlexContainer,
  H2,
  H4,
  IconButton,
  Modal,
  Select,
  palette,
  VehicleGroupIcon,
} from "@busie/ui-kit";
import { Box, styled } from "@mui/system";
import store from "~/vehicles/store";
import DatesFields from "~/vehicles/Vehicles/components/ReservationForm/DatesFields";
import DescriptionField from "~/vehicles/Vehicles/components/ReservationForm/DescriptionField";
import ReasonField from "~/vehicles/Vehicles/components/ReservationForm/ReasonField";
import { observer } from "mobx-react";
import React from "react";
import { DriverSelect } from "~/drivers/widgets";
import { mapVehicleTypeToName } from "@busie/core";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const SelectStyled = styled(Select)(() => {
  return {
    width: "100%",
    textAlign: "left",
    border: "none",
  };
});

const EditReservationForm: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ isOpen, onClose, onSubmit }) => {
    const isSubmitting = store.reservationEditingFetchingStatus === "fetching";
    const { startAt, endAt } = store.reservationPopup.form;
    const vehicleToEdit = store.vehicles.find(
      (v) => v.id === store.reservationPopup.vehicleId
    );
    const vehicles = [...store.vehicles].sort((a, b) =>
      a.vehicleType.type < b.vehicleType.type ? -1 : 1
    );

    const onVehicleChange = (vehicle: Vehicle) => {
      store.reservationPopup.vehicleId = vehicle.id;
      store.reservationPopup.form.selectedVehicles = [vehicle];
    };

    return (
      <Modal isOpen={isOpen}>
        <Box
          sx={{
            backgroundColor: palette.black.plus100,
            width: "414px",
            borderRadius: "8px",
            padding: "24px",
          }}
        >
          <FlexContainer justify="space-between" align="center" pb={2}>
            <H2>Edit reservation</H2>
            <IconButton
              onClick={onClose}
              icon={<CloseIcon color={palette.black.plus30} />}
            />
          </FlexContainer>

          <FlexContainer direction="column" rowGap={3}>
            <FlexContainer
              align="center"
              columnGap={1}
              w={"100%"}
              justify="center"
            >
              <SelectStyled
                label={`Vehicle (${mapVehicleTypeToName(
                  vehicleToEdit?.vehicleType.type || 0
                )})`}
                items={vehicles}
                defaultValue={vehicleToEdit}
                value={vehicleToEdit}
                onChange={(e) => onVehicleChange(e.target.value as Vehicle)}
                getName={(item) =>
                  `(${mapVehicleTypeToName(item.vehicleType.type)}) ${
                    item.licensePlate
                  }`
                }
                getValue={(item) => item}
              />
            </FlexContainer>
          </FlexContainer>

          <FlexContainer direction="column" rowGap={3}>
            <DatesFields />
            <ReasonField />
            {store.reservationPopup.form.reason === ReservationTypes.OTHER && (
              <DescriptionField />
            )}
            {store.reservationPopup.form.reason ===
              ReservationTypes.CHARTER && (
              <DriverSelect
                defaultValue={store.reservationPopup.form.driverId}
                onDriverSelect={(driverId) =>
                  (store.reservationPopup.form.driverId = driverId)
                }
              />
            )}

            <FlexContainer justify="flex-end" columnGap={2} w="100%">
              <Button
                typestyle="secondary"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                typestyle="primary"
                onClick={onSubmit}
                loading={isSubmitting}
                disabled={startAt.isAfter(endAt)}
              >
                Save
              </Button>
            </FlexContainer>
          </FlexContainer>
        </Box>
      </Modal>
    );
  }
);

EditReservationForm.displayName = "EditReservationForm";
export default EditReservationForm;
