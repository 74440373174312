import { useMutation } from "react-query";

import { useQuotesAndBookingsAuthToken } from "@busie/core";
import { QuoteAndBookingStatus } from "@busie/utils";
import { invalidateQnbQueries } from "~/QuotesAndBookingsPage/model";
import { updateBookingStatus } from "@busie/api";

export const useUpdateBookingStatus = (
  id: string,
  status: QuoteAndBookingStatus
) => {
  const authToken = useQuotesAndBookingsAuthToken();

  const result = useMutation(async () => {
    await updateBookingStatus(authToken, id, status);
    invalidateQnbQueries();
  });

  return result;
};
