import React from "react";
import HeaderLinkStyled from "./styled/HeaderLinkStyled";
import { NavLinkProps } from "react-router-dom";
export type HeaderLinkProps = NavLinkProps;

const HeaderLink: React.FC<React.PropsWithChildren<HeaderLinkProps>> = (
  props: HeaderLinkProps
) => {
  return <HeaderLinkStyled {...props} activeClassName="selected" />;
};

export default HeaderLink;
