import { nanoid } from "nanoid";
import { makeAutoObservable } from "mobx";

import { RouteStop } from "@busie/utils";
import { initialRouteStop } from "./initialValues";
import { validateForm } from "./helpers";

import store from "../index";

import {
  createRouteRequest,
  createRouteRequestAsGuest,
  RouteRequest,
  RouteResponse,
} from "@busie/api";

class RouteForm {
  isLoading = false;
  isChanged = false;
  isRoundTrip = true;
  start: RouteStop | null = null;
  waypoints: RouteStop[] = [];
  end: RouteStop | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  private setDefaultNextStep() {
    this.isChanged = true;
    store.pageNavigation.setNextStep(store.pageNavigation.currentStep + 1);
  }
  public toggleRoundTrip() {
    this.isRoundTrip = !this.isRoundTrip;
    if (this.isRoundTrip) {
      if (this.end) {
        this.waypoints.push({ ...this.end, id: nanoid() });
      } else {
        if (!this.waypoints.length) {
          this.addRouteStop();
        }
      }
      if (this.start) this.end = this.start;
    } else {
      if (this.waypoints.length) {
        this.end = this.waypoints[this.waypoints.length - 1];
        this.waypoints.pop(); // removes last element
      } else {
        this.end = null;
      }
    }
    this.setDefaultNextStep();
  }
  public setTripStart(value: RouteStop) {
    this.start = value;
    if (this.isRoundTrip) {
      this.end = value;
    }
    this.setDefaultNextStep();
  }
  public setTripLastStop(value: RouteStop) {
    this.end = value;
    this.setDefaultNextStep();
  }
  public setTripIntermediateStop(index: number, value: RouteStop) {
    this.waypoints[index] = {
      ...value,
      id: nanoid(),
    };
    this.setDefaultNextStep();
  }
  public addRouteStop() {
    const routeInitalObj = { id: nanoid(), ...initialRouteStop };
    this.waypoints.push(routeInitalObj);
    this.setDefaultNextStep();
  }
  public removeRouteStop(index: number) {
    this.waypoints?.splice(index, 1);
    this.setDefaultNextStep();
  }
  public validateForm() {
    return validateForm(this);
  }
  public toggleRouteFormLoading() {
    this.isLoading = !this.isLoading;
  }

  public setFormIsNotChanged() {
    this.isChanged = false;
  }

  public async submitForm(
    tokens: { guestAuthToken?: string; pathfinderAuthToken?: string },
    embedded?: boolean
  ): Promise<RouteResponse> {
    const waypoints = this.waypoints?.map((waypoint) => {
      return `${waypoint.geometry.location.lat()},${waypoint.geometry.location.lng()}`;
    });
    const routeRequestBody: RouteRequest = {
      // this is not a 'default' vehicleType value,
      // it's almost mock data to make a request
      vehicleType: "MOTOR_COACH",
      start: `${this.start?.geometry.location.lat()},${this.start?.geometry.location.lng()}`,
      end: `${this.end?.geometry.location.lat()},${this.end?.geometry.location.lng()}`,
      routeType: this.isRoundTrip ? "ROUND_TRIP" : "ONE_WAY",
      waypoints: waypoints || [],
    };
    const routeResponse = embedded
      ? await createRouteRequestAsGuest(
          tokens.guestAuthToken || "",
          routeRequestBody
        )
      : await createRouteRequest(
          tokens.pathfinderAuthToken || "",
          routeRequestBody
        );
    return routeResponse;
  }

  public reset() {
    this.isLoading = false;
    this.isChanged = false;
    this.isRoundTrip = false;
    this.start = null;
    this.waypoints = [];
    this.end = null;
  }
}

export default RouteForm;
