import React from "react";

// import DetailsBlock from "../../TripDetails/components/DetailsBlock";
import { Trip, Quote, Booking, datetimeFormatter } from "@busie/utils";
import { currencyFormatter, centsToDollars, metersToMiles } from "@busie/utils";
import { DrawerDetailsBlock } from "@busie/ui-kit";
import {
  PrimaryPropertyString,
  SecondaryPropertyString,
} from "~/QuotesAndBookingsPage/shared/ui";
import { propertyTuple } from "~/QuotesAndBookingsPage/shared/lib";

import { getTripStartDate } from "~/QuotesAndBookingsPage/shared/lib";
import { getTripEndDate } from "~/QuotesAndBookingsPage/shared/lib";
import { QuotePrice } from "../Price";

interface Props {
  trip: Trip;
  quote: Quote;
  booking: Booking;
}

export const BookingInfo: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  quote,
  booking,
}: Props) => {
  const startDate = getTripStartDate(trip);
  const endDate = getTripEndDate(trip);

  const primaryProperties: propertyTuple[] = [
    ["ID", quote._id.split("-")[0]],
    ["Price", <QuotePrice trip={trip} quote={quote} />],
    ["Remaining Balance", currencyFormatter(centsToDollars(booking._balance))],
  ];
  const secondaryProperties: propertyTuple[] = [
    ["Start Date", datetimeFormatter(startDate, "departure-arrival")],
    ["End Date", datetimeFormatter(endDate, "departure-arrival")],
    ["Passengers", trip._passengers],
    ["Accessible Seats Requirement", trip._wheelchairs],
    ["Total Distance", `${metersToMiles(trip._meters)} mi`],
    ["Total Live Miles", `${metersToMiles(trip._liveMeters)} mi`],
    ["Total Dead Head Miles", `${metersToMiles(trip._deadHeadMeters)} mi`],
    ["Fuel Surcharge", `${Number(quote._fuelSurcharge || 0)}%`],
    ["Tax Surcharge", `${Number(quote._taxSurcharge || 0)}%`],
    ["Last Minute Surcharge", `${Number(quote._lastMinuteSurgeCharge || 0)}%`],
  ];
  return (
    <DrawerDetailsBlock collapsible name="Quote info">
      {primaryProperties.map(([name, value]) => (
        <PrimaryPropertyString name={name} value={value} key={name as string} />
      ))}
      {secondaryProperties.map(([name, value]) => (
        <SecondaryPropertyString
          name={name}
          value={value}
          key={name as string}
        />
      ))}
    </DrawerDetailsBlock>
  );
};

export default BookingInfo;
