import React from "react";

import { TableProps } from "@mui/material/Table";
import { styled } from "@mui/system";
import { FlexContainer } from "@busie/ui-kit";
import { Box, Typography } from "@mui/material";

interface Props extends TableProps {
  // required props to render table
  columnNames: {
    name: string;
    ordered?: boolean;
    width?: string;
  }[];
  data: {
    [key: string]: string | React.ReactNode;
  }[];

  //optional props for select row functionality
  withCheckbox?: boolean;
  checkboxKeys?: string[];
  rowTypes?: string[];
  selectedRows?: string[];
  onRowClick?: (rowSelector: string, type?: string) => void;
  onRowsSelect?: (rows: string[]) => void;
  onRowsRemove?: (rows: string[]) => void;
}

const ItemContainer = styled("div")(({ theme }) => ({
  background: "white",
  width: "100%",
  border: `1px solid ${theme.palette.black.plus70}`,
  borderRadius: "6px",
  padding: "24px 18px",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  cursor: "pointer",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.plus30,
  fontSize: "10px",
}));

const Value = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.plus10,
  fontSize: "14px",
  paddingTop: "8px",
  fontWeight: 600,
}));

export const MobileList: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <FlexContainer direction="column" rowGap={1.5} sx={{ margin: "13px 0px" }}>
      {props.data.map((row, rowIndex) => {
        return (
          <ItemContainer
            onClick={() =>
              props.onRowClick &&
              props.onRowClick(
                (props.checkboxKeys && props.checkboxKeys[rowIndex]) || "",
                (props.rowTypes && props.rowTypes[rowIndex]) || ""
              )
            }
          >
            <FlexContainer justify="space-between" align="stretch">
              <FlexContainer direction="column" justify="space-between">
                <Box>
                  <Title>Trip ID</Title>
                  <Value>{row.id}</Value>
                </Box>
                <Box>
                  <Title>Information</Title>
                  <Value lineHeight={1}>{row.information || "-"}</Value>
                </Box>
                <Box>
                  <Title>Price</Title>
                  <Value>{row.price}</Value>
                </Box>
              </FlexContainer>
              <FlexContainer direction="column" justify="space-between">
                <Box>
                  <Title>Trip Planner</Title>
                  <Value>{row.tripPlanner || "-"}</Value>
                </Box>
                <Box>
                  <Title>Pick Up Date</Title>
                  <Value>{row.departureDateTime}</Value>
                </Box>
                <Box>
                  <Value>{row.status}</Value>
                </Box>
              </FlexContainer>
            </FlexContainer>
          </ItemContainer>
        );
      })}
    </FlexContainer>
  );
};

export default MobileList;
