import { Loading } from "@busie/ui-kit";
import store from "~/vehicles/store";
import groupBy from "lodash.groupby";
import React from "react";
import Header from "./components/DayView/Header";
import VehicleTypeBlock from "./components/VehicleTypeBlock";
import { CalendarDayViewWrapperStyled } from "./styled/CalendarDayView.styled";

const CalendarDayView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const vehicles = store.vehicles;
  const vehiclesByType = groupBy(vehicles, "vehicleType.type");
  const reservationByVehicleType = groupBy(
    store.reservations,
    "vehicle.vehicleType.type"
  );
  if (store.isFetching) {
    return <Loading />;
  }
  return (
    <CalendarDayViewWrapperStyled>
      <Header />
      {Object.keys(vehiclesByType).map((type) => (
        <VehicleTypeBlock
          key={type}
          type={parseInt(type)}
          vehicles={vehiclesByType[type]}
          reservations={reservationByVehicleType[type] || []}
        />
      ))}
    </CalendarDayViewWrapperStyled>
  );
};

export default CalendarDayView;
