import { createGroupNote } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";
import { useMutation } from "react-query";
import { customersModel } from "~/CustomersPage/entity";

export const useCreateGroupNote = (id: string) => {
  const token = useCustomerAuthToken();
  return useMutation(async (payload: { text: string }) => {
    const result = await createGroupNote(token, id, payload);
    customersModel.invalidateCustomersQueries();
    return result;
  });
};
