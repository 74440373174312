import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import {
  useTripVehicleFlatDayRates,
  useTripVehicleFlatTripRate,
  useTripVehiclePricing,
  useTripVehicles,
} from "@busie/core";
import {
  CommonTable,
  Divider,
  FlexContainer,
  H2,
  Popup,
  Recipe,
  ScrollableFlexContainer,
  Select,
  Text,
  theme,
} from "@busie/ui-kit";
import { centsToDollars, currencyFormatter, Quote, Trip } from "@busie/utils";

interface Props {
  trip: Trip;
  quote: Quote;
  isOpen: boolean;
  onClose: () => void;
}

export const TripPriceBreakdown: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  quote,
  isOpen,
  onClose,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState<string>(
    trip._vehicleIds[0]
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const vehicles = useTripVehicles(trip);
  const vehiclePricing = useTripVehiclePricing(trip, selectedVehicle);
  const flatTripRate = useTripVehicleFlatTripRate(vehiclePricing);
  const flatDayRates = useTripVehicleFlatDayRates(vehiclePricing);

  const getPlatformSurchargeText = (quote: Quote): string =>
    surchargeFormatter(
      quote._platformPercentageSurcharge,
      centsToDollars(quote._platformFixedSurcharge || 0)
    );

  const getPaymentSurchargeText = (quote: Quote): string =>
    surchargeFormatter(
      quote._paymentPercentageSurcharge,
      centsToDollars(quote._paymentFixedSurcharge || 0)
    );

  const getNetworkSurchargeText = (quote: Quote): string =>
    surchargeFormatter(
      quote._networkPercentageSurcharge,
      centsToDollars(quote._networkFixedSurcharge || 0)
    );

  const showNetworkSurcharge = (quote: Quote): boolean => {
    const { _networkPercentageSurcharge, _networkFixedSurcharge } = quote;
    if (!quote._enablePlatformPricingMarkup) return false;

    return !!_networkPercentageSurcharge || !!_networkFixedSurcharge;
  };

  const surchargeFormatter = (
    percentageSurcharge?: number,
    fixedSurcharge?: number
  ) => {
    const strBuilder = [];
    if (fixedSurcharge) {
      strBuilder.push(currencyFormatter(fixedSurcharge));
    }
    if (percentageSurcharge) {
      strBuilder.push(`${percentageSurcharge}%`);
    }
    return strBuilder.join(" + ");
  };

  return (
    <Popup
      isPopupOpened={isOpen}
      title="Trip Price Breakdown"
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: isMobile ? "100vw" : "600px",
          maxWidth: isMobile ? "100vw" : "600px",
          margin: isMobile ? 0 : "initial",
        },
      }}
      // @ts-expect-error no error
      style={
        !isMobile
          ? undefined
          : {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
            }
      }
    >
      <FlexContainer direction="column" rowGap={1} px={2}>
        <ScrollableFlexContainer direction="column" rowGap={1} fullWidth pb={2}>
          <FlexContainer justify="space-between" fullWidth>
            <Select
              label="Vehicle"
              onChange={(e) => setSelectedVehicle(e.target.value)}
              value={selectedVehicle}
              items={vehicles.map((vehicle) => ({
                value: vehicle.id,
                name: vehicle.licensePlate,
              }))}
              fullWidth
            />
          </FlexContainer>
          <FlexContainer justify="space-between" fullWidth>
            <H2 weight={600}>Total Price</H2>
            <H2 weight={600}>
              {currencyFormatter(centsToDollars(Number(quote._totalPrice)))}
            </H2>
          </FlexContainer>
          <FlexContainer justify="space-between" fullWidth>
            <Text weight={500}>Tax Surcharge</Text>
            <Text weight={500}>{quote._taxSurcharge}%</Text>
          </FlexContainer>
          <FlexContainer justify="space-between" fullWidth>
            <Text weight={500}>Fuel Surcharge</Text>
            <Text weight={500}>{quote._fuelSurcharge}%</Text>
          </FlexContainer>
          <FlexContainer justify="space-between" fullWidth>
            <Text weight={500}>Last Minute Surcharge</Text>
            <Text weight={500}>{quote._lastMinuteSurgeCharge}%</Text>
          </FlexContainer>
          {quote._enablePlatformPricingMarkup && (
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={500}>Platform Surcharge</Text>
              <Text weight={500}>{getPlatformSurchargeText(quote)}</Text>
            </FlexContainer>
          )}
          {quote._enablePlatformPricingMarkup && (
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={500}>Payment Surcharge</Text>
              <Text weight={500}>{getPaymentSurchargeText(quote)}</Text>
            </FlexContainer>
          )}
          {showNetworkSurcharge(quote) && (
            <FlexContainer justify="space-between" fullWidth>
              <Text weight={500}>network Surcharge</Text>
              <Text weight={500}>{getNetworkSurchargeText(quote)}</Text>
            </FlexContainer>
          )}
          <FlexContainer justify="space-between" fullWidth>
            <Text weight={500}>Flat Trip Rate</Text>
            <Text weight={500}>
              {currencyFormatter(centsToDollars(Number(flatTripRate)))}
            </Text>
          </FlexContainer>
          <Divider margin={isMobile ? 5 : 0} />
          <FlexContainer justify="space-between" fullWidth>
            <H2 weight={600}>Flat Day Rates</H2>
          </FlexContainer>
          {Array.from(flatDayRates.entries()).map(([dateMS, val]) => (
            <FlexContainer justify="space-between" fullWidth key={dateMS}>
              <Text weight={500}>{new Date(dateMS).toLocaleDateString()}</Text>
              <Text weight={500}>
                {currencyFormatter(centsToDollars(Number(val || 0)))}
              </Text>
            </FlexContainer>
          ))}
          <Divider margin={isMobile ? 5 : 0} />
          <FlexContainer>
            <H2 weight={600}>Leg Subtotals</H2>
          </FlexContainer>
          {vehiclePricing.length && (
            <CommonTable
              columnNames={[
                // best way i could think of to create even spacing between the columns
                // with current table implementation
                { id: "leg", name: "Leg", width: "295px" },
                { id: "subtotal", name: "Subtotal", width: "295px" },
                { id: "recipe", name: "Recipe", width: "10px" },
              ]}
              data={vehiclePricing.map((legPrice, index) => ({
                Leg: `Leg ${index + 1}`,
                Subtotal: currencyFormatter(
                  centsToDollars(Number(legPrice.subtotal))
                ),
                Recipe: (
                  <Link
                    to={`/rates?recipeId=${legPrice.legPriceData.recipeId}`}
                  >
                    <Recipe color="black" />
                  </Link>
                ),
              }))}
            />
          )}
        </ScrollableFlexContainer>
      </FlexContainer>
    </Popup>
  );
};

export default TripPriceBreakdown;
