import axios from "axios";
import { env, Plan } from "@busie/utils";

const BASE_URL = `${env("BUSIE_PLANS_SERVICE_API_URL")}/plans`;

export const getPlan = async (token: string, orgId: string): Promise<Plan> => {
  const { data } = await axios.get<Plan>(`${BASE_URL}/${orgId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};
