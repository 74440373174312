import React from "react";
import { observer } from "mobx-react";

import { Integration } from "@busie/utils";

import { IntegrationCard } from "./IntegrationCard";
import { Styled } from "./styled";

interface Props {
  integrations: Integration[];
  revenueShares: Map<string, number>;
  onClick: (integration: Integration) => void;
}

export const Grid: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ integrations, revenueShares, onClick }) => {
    return (
      <Styled>
        {integrations.map((integration, id) => (
          <IntegrationCard
            integration={integration}
            revenueShare={
              revenueShares.get(integration.revenueShareConfigId) || null
            }
            key={id}
            onClick={() => onClick(integration)}
          />
        ))}
      </Styled>
    );
  }
);
