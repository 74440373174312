import React, { useRef } from "react";
import { styled } from "@mui/system";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { palette } from "@busie/ui-kit";

import { Select, CloseIcon, SearchIcon } from "@busie/ui-kit";

import { InputAdornment, TextField } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { useDebouncedCallback } from "@busie/core";

const CookbooksFilterStyled = styled("div")(() => {
  return {
    display: "grid",
    gridTemplateColumns: "160px 160px 160px",
    columnGap: "16px",
    [`.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: `${palette?.black?.plus70}`,
    },
  };
});

interface Props {
  typeFilter: string;
  onTypeFilterChange: (value: string) => void;
  nameFilter: string;
  onNameFilterChange: (value: string) => void;
}

const CookbooksFilter: React.FC<React.PropsWithChildren<Props>> = ({
  typeFilter,
  onTypeFilterChange,
  nameFilter,
  onNameFilterChange,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchValues = new URLSearchParams(search);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = (value: string) => {
    onNameFilterChange(value);
    updateSearchParams("name", value);
  };

  const updateSearchParams = useDebouncedCallback((param, value) => {
    searchValues.set(param, value);
    searchValues.set("page", "1");
    history.push({
      search: `?${searchValues.toString()}`,
    });
  }, 300);

  return (
    <CookbooksFilterStyled>
      <Select
        onChange={(e) => {
          onTypeFilterChange(e.target.value);
          updateSearchParams("type", e.target.value);
        }}
        value={typeFilter}
        items={[
          { name: "All cookbooks", value: "all" },
          { name: "Customer", value: "CUSTOMER" },
          { name: "Vehicle", value: "VEHICLE_TYPE" },
          { name: "Dispatch Location", value: "DISPATCH_LOCATION" },
          { name: "Organization", value: "ORGANIZATION" },
        ]}
        getName={(item) => item.name}
        getValue={(item) => item.value}
      />
      <TextField
        ref={inputRef}
        sx={{
          height: "40px",
          minWidth: "300px",
          input: {
            padding: "10px 0",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color={palette.black.plus30} />
            </InputAdornment>
          ),
          endAdornment: nameFilter && (
            <InputAdornment
              position="end"
              onClick={() => {
                handleSearchChange("");
              }}
            >
              <CloseIcon color={palette.black.plus30} />
            </InputAdornment>
          ),
        }}
        value={nameFilter}
        onChange={(e) => {
          handleSearchChange(e.target.value);
        }}
        placeholder="Search by name"
      />
    </CookbooksFilterStyled>
  );
};

CookbooksFilter.displayName = "CookbooksFilter";
export default CookbooksFilter;
