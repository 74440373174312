import { TimeIcon, TooltipIcon } from "@busie/ui-kit";

import { Tooltip } from "@mui/material";
import { getInvoiceDaysUntilDue } from "./get-invoice-days-until-due";
import { getIconColor } from "./get-icon-color";

interface Props {
  createdDate: Date;
  daysUntilDue: number;
}

export const DueDate: React.FC<React.PropsWithChildren<Props>> = ({
  createdDate,
  daysUntilDue,
}) => {
  const daysTilDue = getInvoiceDaysUntilDue(createdDate, daysUntilDue);

  return (
    <Tooltip title={`Invoice due in ${daysTilDue} days`} placement="top">
      <TooltipIcon>
        <TimeIcon color={getIconColor(daysTilDue)} />
      </TooltipIcon>
    </Tooltip>
  );
};
