import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

import DateRangePickerStyled from "./styled/DateRangePickerStyled";
import DateRangePickerInputStyled from "./styled/DateRangePickerInputStyled";

import { CalendarIcon } from "../icons/calendar";
import { TextInput } from "../TextInput";
import { CloseIcon } from "../icons";
import palette from "../theme/palette";

import { getInputTextValue } from "./helpers";
import InputLabel from "../InputLabel";
import { DateRange } from "@busie/utils";

interface Props {
  label?: string | JSX.Element;
  required?: boolean;
  maxWidth?: number;
  value: DateRange;
  disablePast?: boolean;
  onChange: (newValue: DateRange) => void;
}

export const DateRangePicker: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const [inputValue, setInputValue] = React.useState<string>(
    getInputTextValue(props.value)
  );

  const handleOnChange = (newValue: DateRange) => {
    props.onChange(newValue);
    setInputValue(getInputTextValue(newValue));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ width: "100%" }}>
        {props.label && (
          <InputLabel required={props.required}>{props.label}</InputLabel>
        )}
        <DateRangePickerStyled
          value={props.value}
          disablePast={props.disablePast}
          onChange={(newValue) => handleOnChange(newValue as DateRange)}
          slots={{ field: SingleInputDateRangeField }}
        />
      </div>
    </LocalizationProvider>
  );
};
