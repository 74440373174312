import { useState, useEffect } from "react";

import { fetchGroup, fetchCustomers, searchGroups } from "@busie/api";
import { Customer, Group } from "@busie/utils";
import debounce from "lodash.debounce";

export const useGroup = (authToken: string, groupId = ""): Group => {
  const [group, setGroup] = useState<Group>();

  useEffect(() => {
    const getGroup = async (): Promise<void> => {
      if (!authToken) return;
      const response = await fetchGroup(authToken, groupId);

      setGroup(response);
    };

    getGroup();
  }, [authToken, groupId]);

  if (!group?.id)
    return {
      name: "No organization",
    } as Group;
  return group;
};

export const useGroups = (
  authToken: string,
  searchValue: string
): [Group[], boolean] => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (searchValue) setIsLoading(true);
    const getGroups = debounce(async (): Promise<void> => {
      if (!authToken || !searchValue) return;
      const response = await searchGroups(authToken, {
        search: searchValue,
      });
      setIsLoading(false);
      setGroups(response.results);
    }, 500);

    getGroups();

    return () => getGroups.cancel();
  }, [authToken, searchValue]);

  return [groups, isLoading];
};

export const useCustomers = (
  authToken: string,
  filterQuery: string
): Customer[] => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    const getCustomers = async (): Promise<void> => {
      if (!authToken) return;
      const response = await fetchCustomers(authToken, filterQuery);

      setCustomers(response.results);
    };

    getCustomers();
  }, [authToken, filterQuery]);

  return customers;
};
