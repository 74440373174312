import { makeAutoObservable } from "mobx";

import { StepName } from "../../stepName";

class PageNavigation {
  currentStep = 0;
  nextStep = 1;
  stepList: StepName[] = [];

  constructor() {
    makeAutoObservable(this);
    this.setCurrentStep = this.setCurrentStep.bind(this);
    this.setNextStep = this.setNextStep.bind(this);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
  }

  public setCurrentStep(index: number) {
    if (index > this.stepList.length - 1 || index < 0) return;
    this.currentStep = index;
  }

  public setNextStep(index: number) {
    if (index > this.stepList.length - 1 || index < 0) return;
    this.nextStep = index;
  }

  public goNext() {
    this.setCurrentStep(this.nextStep);
    this.setNextStep(this.nextStep + 1);
  }

  public goPrev() {
    this.setNextStep(this.currentStep);
    this.setCurrentStep(this.currentStep - 1);
  }

  public reset() {
    this.currentStep = 0;
    this.nextStep = 1;
  }
}

export default PageNavigation;
