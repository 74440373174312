import React from "react";

interface Props {
  color?: string;
  size?: number;
}
const KneelingCoachIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = "#222",
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="kneeling coach"
    >
      <circle cx="42" cy="39" r="6" stroke={color} strokeWidth="6"></circle>
      <path
        stroke={color}
        d="M53 39c0-6.075-4.925-11-11-11s-11 4.925-11 11"
      ></path>
      <path
        fill={color}
        d="M9 37l2-1h14l2 1v2H9v-2zM7 41l2-1h18l2 1v2H7v-2zM11 33l2-1h10l2 1v2H11v-2z"
      ></path>
      <path fill={color} d="M2 38H8V39H2z"></path>
      <path fill={color} d="M7 7H29V8H7z"></path>
      <path fill={color} d="M7 7H8V39H7z"></path>
      <path fill={color} d="M28 7H29V39H28z"></path>
      <path fill={color} d="M28 38H31V39H28z"></path>
      <path fill={color} d="M53 38H54V39H53z"></path>
      <circle cx="42" cy="39" r="1" fill={color}></circle>
    </svg>
  );
};

export default KneelingCoachIcon;
