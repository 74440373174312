import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  AppState,
  Auth0Provider,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { enableLogging } from "mobx-logger";

import { history } from "@busie/utils";
import { Loading } from "@busie/ui-kit";
import { env } from "@busie/utils";
import { ApplicationCore } from "./core";

import "../shared/styles/index.css";
import { useFeatureFlags } from "@busie/core";

const onRedirectCallback = (appState: AppState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

//enable mobx logging
process.env.NODE_ENV === "development" &&
  enableLogging({
    action: true,
    reaction: true,
    transaction: true,
    compute: true,
  });

export const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const qs = window.location.search;
  const urlParams = new URLSearchParams(qs);
  const invitation = urlParams.get("invitation") || undefined;
  const organization = urlParams.get("organization") || undefined;

  const CoreWithFeatureFlags = useFeatureFlags(ApplicationCore, {
    enabled: env("FEATURE_FLAGS_ENABLED")?.toLowerCase() === "true",
    clientSideID: env("LAUNCH_DARKLY_CLIENT_ID"),
    streaming: env("LAUNCH_DARKLY_ENABLE_STREAMING")?.toLowerCase() === "true",
  });

  const ProtectedCore = withAuthenticationRequired(CoreWithFeatureFlags, {
    onRedirecting: () => <Loading />,
    loginOptions: {
      invitation,
      organization,
    },
  });

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={env("BUSIE_AUTH_DOMAIN")}
        clientId={env("BUSIE_CLIENT_ID")}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <ProtectedCore />
      </Auth0Provider>
    </BrowserRouter>
  );
};
