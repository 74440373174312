import React from "react";

import { datetimeFormatter as dateFormat, dayjsExt } from "@busie/utils";

import EditButton from "./EditButton";
import PropertyString from "../PropertyString";

import FormContainer from "../FormContainer";

import DeparturesForm from "../../store/departuresForm";

interface Props {
  setNextStep: (step: number) => void;
  setCurrentStep: (step: number) => void;
  onEdit: () => void;
  departuresForm: DeparturesForm;
}

const DeparturesBlock: React.FC<React.PropsWithChildren<Props>> = ({
  onEdit,
  departuresForm,
}: Props) => {
  const departures = departuresForm.departures;

  return (
    <FormContainer
      title="Departures"
      controls={<EditButton onClick={onEdit} aria-label="edit departures" />}
    >
      {departures.map(
        (departure, i) =>
          departure.date &&
          !departure.isLast && (
            <PropertyString key={i} size="large" name={departure.name}>
              {`${dateFormat(departure.date, "ddd, MMM DD, YYYY")}, ${dayjsExt(
                departure.time
              ).format("hh:mm a")}`}
            </PropertyString>
          )
      )}
    </FormContainer>
  );
};

export default DeparturesBlock;
