import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { FlexContainer } from "@busie/ui-kit";
import Vehicles from "~/vehicles/Vehicles";
import VehiclesList from "~/vehicles/VehiclesList";
import VehiclesForm from "~/vehicles/VehiclesForm";
import VehiclesStats from "~/vehicles/VehiclesStats";

export const VehiclesRoutes: FC<React.PropsWithChildren<unknown>> = () => {
  const { path } = useRouteMatch();

  return (
    <FlexContainer direction="column" align="center">
      <Switch>
        <Route exact path={`${path}`} component={Vehicles} />
        <Route exact path={`${path}/my-vehicles`} component={VehiclesList} />
        <Route
          exact
          path={`${path}/my-vehicles/stats`}
          component={VehiclesStats}
        />
        <Route exact path={`${path}/new-vehicle`} component={VehiclesForm} />
      </Switch>
    </FlexContainer>
  );
};
