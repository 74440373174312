import React from "react";
import { styled } from "@mui/system";

interface TabContentProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabContentStyled = styled("div")(() => {
  return {
    background: "#fff",
    padding: "25px 40px 40px",
  };
});

function TabContent(props: TabContentProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <TabContentStyled role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </TabContentStyled>
  );
}
export default TabContent;
