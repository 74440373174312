import React from "react";
import { styled } from "@mui/system";

import { Text, Button, FlexContainer, theme } from "@busie/ui-kit";
import { Reservation, Vehicle } from "@busie/utils";
import { VehicleIcon, mapVehicleTypeToName } from "@busie/core";

import store from "~/vehicles/store";

import ColumnsStyled from "../styled/ColumnsStyled";
import VehicleBooking from "./VehicleBooking";
import { useMediaQuery } from "@mui/material";

const VehicleBlockStyled = styled(FlexContainer)(() => {
  return {
    marginTop: "16px",
  };
});

interface Props {
  vehicle: Vehicle;
  reservations: Reservation[];
}

const VehicleBlock: React.FC<React.PropsWithChildren<Props>> = ({
  vehicle,
  reservations,
}: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  return (
    <VehicleBlockStyled bordered noPadding direction="column">
      <ColumnsStyled isMobile={isMobile}>
        <FlexContainer align="center" columnGap={2}>
          <VehicleIcon type={vehicle.vehicleType.type} size={32} />
          <Text>{mapVehicleTypeToName(vehicle.vehicleType.type)}</Text>
        </FlexContainer>
        <FlexContainer align="center" columnGap={2}>
          <Text>{vehicle.licensePlate}</Text>
        </FlexContainer>
        <div> </div>
        <div>
          <Button
            size="small"
            onClick={() => store.openReservationPopupWithVehicles([vehicle])}
          >
            Reserve
          </Button>
        </div>
      </ColumnsStyled>
      {reservations?.map((reservation, index) => {
        return (
          <VehicleBooking
            key={index}
            type={reservation.type}
            status={reservation.status}
            vehicleTypeName={mapVehicleTypeToName(vehicle.vehicleType.type)}
            reservationId={reservation.id}
            dateRange={[
              new Date(reservation.startAt),
              new Date(reservation.endAt),
            ]}
          />
        );
      })}
    </VehicleBlockStyled>
  );
};

export default VehicleBlock;
