import { styled } from "@mui/system";

import { Theme } from "@mui/system";

interface Props {
  theme?: Theme;
  padding?: string;
}

const DetailsBlockStyled = styled("div")<Props>((props) => {
  const palette = props.theme?.palette;
  return {
    "&": {
      width: "100%",
      color: palette?.black?.plus10,
      ".header-block": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px 10px 24px",
        background: palette?.black?.plus85,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "150%",
        letterSpacing: "0.04em",
        boxShadow: `inset 0px 0px 0px ${palette?.black?.plus70}`,
        height: 60,
      },
      ".content-block": {
        background: palette?.black?.plus100,
        padding: props.padding || "24px 40px",
      },
    },
  };
});

export default DetailsBlockStyled;
