import React from "react";
import Collapse from "@mui/material/Collapse";

import DetailsBlockStyled from "./styled/DetailsBlockStyled";
import { FlexContainer } from "../FlexContainer";
import IconButton from "../IconButton";
import { ChevronDown, ChevronUp } from "../icons";
import palette from "../theme/palette";

interface Props {
  noHeader?: boolean;
  collapsible?: boolean;
  name?: string;
  children: React.ReactNode;
  padding?: string;
  menu?: React.ReactNode;
  minHeight?: number;
  className?: string;
}

const DetailsBlock: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);
  const toggleIsCollapsed = () => {
    return setIsCollapsed((prev) => !prev);
  };
  return (
    <DetailsBlockStyled padding={props.padding} className={props.className}>
      {!props.noHeader && (
        <div className="header-block">
          <span className="title">{props.name || ""}</span>

          <FlexContainer>
            {props.menu}
            {props.collapsible && (
              <IconButton
                type="button"
                icon={
                  isCollapsed ? (
                    <ChevronUp color={palette?.black?.plus10} />
                  ) : (
                    <ChevronDown color={palette?.black?.plus10} />
                  )
                }
                onClick={toggleIsCollapsed}
              />
            )}
          </FlexContainer>
        </div>
      )}
      <Collapse in={!isCollapsed}>
        <div className="content-block">{props.children}</div>
      </Collapse>
    </DetailsBlockStyled>
  );
};

export default DetailsBlock;
