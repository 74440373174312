import { styled } from "@mui/material/styles";

export const SearchBlockStyled = styled("div")(() => {
  return {
    position: "relative",
  };
});

export const SearchFilterBlockStyled = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    left: 0,
    right: 0,
    top: "45px",
    zIndex: 1,
    [theme.breakpoints.down("tablet")]: {
      position: "fixed",
      left: "20px",
      top: "initial",
    },
  };
});
