// TODO: refactor to use useQuery and useMutation hooks
import { makeAutoObservable, runInAction } from "mobx";

import {
  Customer,
  QuoteAndBookingStatus,
  Trip,
  Integration,
  QuotesIntegration,
  DispatchLocation,
} from "@busie/utils";
import { FetchingStatus } from "@busie/core";
import {
  BookingsResponse,
  BookingWithAttrs,
  fetchBookings,
  fetchCustomersByIds,
  createPaymentLink,
  fetchQuotesIntegrations,
  fetchTrips,
  getDispatchLocations,
} from "@busie/api";
import {
  collectBookingsWithTrips,
  getFilterParams,
  getQuotesIntegrationFromId,
  quoteIntegrationsToMap,
} from "~/QuotesAndBookingsPage/shared/lib";
import { notificationStore } from "@busie/features";

import { BOOKING_IN_USE_STATUSES, ITEMS_PER_PAGE } from "../constants";

type AuthTokens = {
  quotes: string;
  trips: string;
  customers: string;
  dispatchLocations: string;
  payments: string;
};

class BookingStore {
  authTokens: AuthTokens = {
    quotes: "",
    trips: "",
    customers: "",
    dispatchLocations: "",
    payments: "",
  };
  bookingsResponse: BookingsResponse | null = null;
  customers: Customer[] = [];
  items: BookingWithAttrs[] = [];
  quotesIntegrations: QuotesIntegration | null = null;
  dispatchLocations: DispatchLocation[] = [];
  integrationMap: Map<string, Integration> = new Map();
  integrationNames: {
    [key: string]: string;
  } = {};
  integrationLogos: {
    [key: string]: string;
  } = {};
  isFetching = true;
  selectedItems: string[] = [];
  confirmingContractFetchingStatus: FetchingStatus = "notFetched";
  voidingContractFetchingStatus: FetchingStatus = "notFetched";
  paymentReminderFetchingStatus: FetchingStatus = "notFetched";

  constructor() {
    makeAutoObservable(this);
    this.addSelectedItems = this.addSelectedItems.bind(this);
    this.removeSelectedItems = this.removeSelectedItems.bind(this);
    this.setItems = this.setItems.bind(this);
  }

  public async setItems(page?: number): Promise<void> {
    this.selectedItems = [];
    this.isFetching = true;
    try {
      if (!this.authTokens.trips) return;
      const trips = await fetchTrips(this.authTokens.trips, {
        // populateLegs: true,
      });
      const tripIdMap = new Map(trips.map((trip) => [trip._id, trip]));
      const filterParams = getFilterParams();
      if (!this.authTokens.quotes) return;
      const bookingsResponse = await fetchBookings(this.authTokens.quotes, {
        status:
          filterParams.status !== "ALL"
            ? (filterParams.status as QuoteAndBookingStatus)
            : BOOKING_IN_USE_STATUSES,
        locationId:
          filterParams.locationId === "ALL" ? null : filterParams.locationId,
        page,
        pickupDateFrom: filterParams.pickupDateFrom,
        pickupDateTo: filterParams.pickupDateTo,
        itemsPerPage: ITEMS_PER_PAGE,
        [`${filterParams.searchParam}`]: filterParams.searchValue,
      });
      this.bookingsResponse = bookingsResponse;
      const bookings = bookingsResponse.resultSet;

      const filteredTrips = bookings.reduce((acc, booking) => {
        if (tripIdMap.has(booking._quote.experienceId)) {
          acc.push(tripIdMap.get(booking._quote.experienceId) as Trip);
        }
        return acc;
      }, [] as Trip[]);

      const customerIds = filteredTrips.map(
        (trip) => trip._mainContactId || trip._tripPlannerId
      );
      if (!this.authTokens.customers) return;
      const customers = await fetchCustomersByIds(
        this.authTokens.customers,
        customerIds
      );
      if (!this.authTokens.quotes) return;
      const quotesIntegrations = await fetchQuotesIntegrations(
        this.authTokens.quotes
      );

      if (!this.authTokens.dispatchLocations) return;
      const dispatchLocations = await getDispatchLocations(
        this.authTokens.dispatchLocations
      );

      const bookingsWithTrips = await collectBookingsWithTrips(bookings, {
        customers,
        trips: filteredTrips,
      });
      runInAction(() => {
        this.items = bookingsWithTrips;
        this.quotesIntegrations = quotesIntegrations;
        this.dispatchLocations = dispatchLocations;
        this.integrationMap = quoteIntegrationsToMap(quotesIntegrations);
        this.integrationNames = quotesIntegrations.organizationNames;
        this.integrationLogos = quotesIntegrations.organizationLogos;
        this.isFetching = false;
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  }

  public getIntegration = (integrationId: string, organizationId: string) => {
    return getQuotesIntegrationFromId(
      integrationId,
      organizationId,
      this.integrationMap,
      this.integrationNames,
      this.integrationLogos
    );
  };

  public setAuthTokens(authTokens: AuthTokens): void {
    this.authTokens = authTokens;
  }
  public addSelectedItems(items: string[]): void {
    // make array with unique items
    this.selectedItems = [...new Set([...this.selectedItems, ...items])];
  }
  public removeSelectedItems(items: string[]): void {
    const itemSet = new Set(items);
    this.selectedItems = this.selectedItems.filter(
      (item) => !itemSet.has(item)
    );
  }
  public clearSelectedItems(): void {
    this.selectedItems = [];
  }
  public reset(): void {
    this.authTokens = {
      quotes: "",
      trips: "",
      customers: "",
      dispatchLocations: "",
      payments: "",
    };
    this.customers = [];
    this.dispatchLocations = [];
    this.items = [];
    this.isFetching = true;
    this.selectedItems = [];
  }

  async sendPaymentReminder(
    amount: number,
    contactEmail: string,
    bookingId: string
  ) {
    this.paymentReminderFetchingStatus = "fetching";
    try {
      await createPaymentLink(this.authTokens.quotes, {
        amount,
        contactEmail,
        bookingIds: [bookingId],
      });
      this.paymentReminderFetchingStatus = "fetched";
    } catch (e) {
      this.paymentReminderFetchingStatus = "failedFetching";
      throw e;
    }
  }
}

export const store = new BookingStore();
