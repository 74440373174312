import React from "react";
import { Text, FlexContainer } from "@busie/ui-kit";
import { VehicleIcon, mapVehicleTypeToName } from "@busie/core";
import { VehicleTypes } from "@busie/utils";

interface Props {
  vehicleType: VehicleTypes;
}

const VehicleTypeLabel: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const { vehicleType } = props;
  return (
    <FlexContainer align="center" columnGap={2}>
      <VehicleIcon type={vehicleType} size={30} />
      <Text>{mapVehicleTypeToName(vehicleType)}</Text>
    </FlexContainer>
  );
};

export default VehicleTypeLabel;
