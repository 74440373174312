import React, { useCallback } from "react";
import {
  AmountFieldStyled,
  BigIconSelect,
  Button,
  ErrorMessage,
  Select,
  H1,
  H3,
  H4,
  theme,
  ChevronPrev,
  MinusIcon,
  PlusIcon,
  TooltipIcon,
  InfoIcon,
} from "@busie/ui-kit";
import { useHistory } from "react-router";
import Header from "~/vehicles/styled/Header";
import { Box, TextField, Tooltip, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react";
import vehiclesFormStore from "~/vehicles/store/vehiclesForm.store";
import { palette, NumberInput, FlexContainer } from "@busie/ui-kit";
import VehicleFormBlock from "~/vehicles/VehiclesForm/styled/VehicleFormBlock";
import { useVehiclesAuthToken } from "@busie/core";
import { dispatchLocationStore } from "~/settings/store/dispatch";
import { useDispatchLocationsAuthToken } from "@busie/core";
import {
  AmenityIcon,
  mapAmenityToName,
  VehicleIcon,
  mapVehicleTypeToName,
} from "@busie/core";
import {
  VehicleTypes,
  Amenity,
  AmenityValues,
  VehicleTypesValues,
  DispatchLocation,
} from "@busie/utils";
import Checkbox from "./styled/CheckboxStyled";

const MINIMUM_VEHICLES_AMOUNT = 1;

const VehiclesForm: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const store = vehiclesFormStore;
    const vehiclesAuthToken = useVehiclesAuthToken();
    const dispatchLocationsAuthToken = useDispatchLocationsAuthToken();
    const history = useHistory();

    const onBackClick = useCallback(() => {
      history.goBack();
    }, [history]);

    React.useEffect(() => {
      if (!dispatchLocationStore.authToken) {
        dispatchLocationStore.setAuthToken(dispatchLocationsAuthToken);
        dispatchLocationStore.getDispatchLocations();
      }
    }, [dispatchLocationsAuthToken]);

    React.useEffect(() => {
      if (store.getFormIsSubmitted()) {
        store.resetForm();
        history.goBack();
      }
    }, [history, store, store.vehiclesFormFetchingStatus]);

    const isMobile = useMediaQuery("@media (max-width: 750px)");

    React.useEffect(() => {
      if (store.editMode && store.vehicleToEdit) {
        store.initializeFromVehicleType();
      } else {
        store.resetForm();
      }

      return () => {
        store.setEditMode(false);
        store.vehicleToEdit = null;
      };
    }, [store, store.editMode, store.vehicleToEdit]);

    return (
      <Box
        sx={{
          width: "100%",
          marginBottom: 5,
        }}
      >
        <Header>
          <Button
            typestyle="tertiary"
            startIcon={<ChevronPrev />}
            onClick={onBackClick}
          >
            Back
          </Button>

          {store.editMode ? (
            <H1>Edit vehicle</H1>
          ) : (
            <H1 sx={{ textAlign: isMobile ? "center" : "start" }}>
              Add new vehicle to your fleet
            </H1>
          )}

          <span />
        </Header>
        <FlexContainer
          w="100%"
          direction="column"
          justify="center"
          align="center"
        >
          <VehicleFormBlock>
            <FlexContainer w="100%" direction="column">
              <H3>Vehicles Type</H3>
              <FlexContainer
                w="100%"
                justify="flex-start"
                wrap="wrap"
                columnGap={1.5}
                rowGap={3}
              >
                {VehicleTypesValues.map((type, index) => {
                  return (
                    <BigIconSelect
                      key={index}
                      title={mapVehicleTypeToName(type as VehicleTypes)}
                      isActive={store.vehicleType === type}
                      onClick={() => store.setVehicleType(type as VehicleTypes)}
                    >
                      <VehicleIcon size={56} type={type} />
                    </BigIconSelect>
                  );
                })}
              </FlexContainer>

              {store.vehicleType == null && (
                <ErrorMessage>Please select a vehicle type</ErrorMessage>
              )}
            </FlexContainer>

            <FlexContainer w="100%" wrap="wrap" align="center">
              <Box sx={{ minWidth: "300px" }}>
                <H3>Vehicles Amount</H3>
              </Box>
              <AmountFieldStyled>
                <div className="field">
                  <button
                    type="button"
                    onClick={() =>
                      store.setAmount(
                        Math.max(store.amount - 1, MINIMUM_VEHICLES_AMOUNT)
                      )
                    }
                  >
                    <MinusIcon />
                  </button>
                  <NumberInput
                    value={store.amount}
                    isAllowed={({ value }) => parseInt(value) <= 100}
                    allowNegative={false}
                    onChange={(e) => {
                      if (e.target.value) {
                        store.setAmount(
                          parseInt(e.target.value) || MINIMUM_VEHICLES_AMOUNT
                        );
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => store.setAmount(store.amount + 1)}
                  >
                    <PlusIcon />
                  </button>
                  <Tooltip
                    placement="top"
                    title="If you need to create more than 100 vehicles, please contact us at support@getbusie.com"
                    arrow
                  >
                    <TooltipIcon>
                      <InfoIcon size={24} color={palette.supernova.minus10} />
                    </TooltipIcon>
                  </Tooltip>
                </div>
              </AmountFieldStyled>
            </FlexContainer>

            {store.licensePlateNumbers.length > 0 && (
              <>
                {store.licensePlateNumbers.map((license, idx) => (
                  <FlexContainer w="100%" key={idx} align="center" wrap="wrap">
                    <Box sx={{ minWidth: "300px" }}>
                      <H3>{idx === 0 ? "License plate number" : ""}</H3>
                    </Box>
                    <FlexContainer align="center">
                      <H3 sx={{ minWidth: "32px" }}>#{idx + 1}</H3>
                      <TextField
                        variant="outlined"
                        value={license}
                        onChange={(e) =>
                          store.setLicensePlateNumber(e.target.value, idx)
                        }
                        sx={{
                          margin: 0,
                          marginTop: theme.spacing(1),
                          ".MuiOutlinedInput-root": {
                            height: 40,
                          },
                          fieldset: {
                            borderColor: palette.black.plus70,
                          },
                          paddingRight: "41px",
                        }}
                      />
                      <Checkbox
                        name="SPAB Certified"
                        labelPlacement="end"
                        checked={!!store.spabCertifiedArray[idx]}
                        onChange={() =>
                          store.setSbapCertified(
                            !store.spabCertifiedArray[idx],
                            idx
                          )
                        }
                      />
                    </FlexContainer>
                  </FlexContainer>
                ))}
                <Box
                  sx={{
                    textAlign: "left",
                    backgroundColor: palette.red?.plus80,
                    borderRadius: "4px",
                    padding: "8px 16px",
                    marginTop: "20px",
                  }}
                >
                  <H4 color={palette.black.plus30}>
                    <span role="img" aria-label="note">
                      ☝️
                    </span>{" "}
                    License plate number could be different based on your
                    vehicle region registration.
                  </H4>
                  <H4 color={palette.black.plus30}>
                    <span role="img" aria-label="note">
                      ☝️☝️️️
                    </span>{" "}
                    If you don’t have the license plate number, no worries. You
                    can add it later. Placeholder will be temporarily used.
                  </H4>
                </Box>
              </>
            )}

            <FlexContainer w="100%" wrap="wrap">
              <Box sx={{ minWidth: "300px" }}>
                <H3>Minimal trip seat amount</H3>
              </Box>
              <AmountFieldStyled>
                <div className="field">
                  <button
                    type="button"
                    onClick={() =>
                      store.setMinimalTripSeat(
                        Math.max(store.minimalTripSeat - 1, 0)
                      )
                    }
                  >
                    <MinusIcon />
                  </button>
                  <NumberInput
                    value={store.minimalTripSeat}
                    onChange={(e) => {
                      store.setMinimalTripSeat(parseInt(e.target.value));
                      if (store.maximalTripSeat < store.minimalTripSeat) {
                        store.setMaximalTripSeat(store.minimalTripSeat);
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      store.setMinimalTripSeat(store.minimalTripSeat + 1);
                      if (store.maximalTripSeat < store.minimalTripSeat) {
                        store.setMaximalTripSeat(store.minimalTripSeat);
                      }
                    }}
                  >
                    <PlusIcon />
                  </button>
                </div>
              </AmountFieldStyled>
            </FlexContainer>

            <FlexContainer w="100%" wrap="wrap">
              <Box sx={{ minWidth: "300px" }}>
                <H3>Maximal trip seat amount</H3>
              </Box>
              <AmountFieldStyled>
                <div className="field">
                  <button
                    type="button"
                    onClick={() => {
                      store.setMaximalTripSeat(
                        Math.max(store.maximalTripSeat - 1, 1)
                      );
                      if (store.maximalTripSeat < store.minimalTripSeat) {
                        store.setMinimalTripSeat(store.maximalTripSeat);
                      }
                    }}
                  >
                    <MinusIcon />
                  </button>
                  <NumberInput
                    value={store.maximalTripSeat}
                    onChange={(e) =>
                      store.setMaximalTripSeat(parseInt(e.target.value))
                    }
                  />
                  <button
                    type="button"
                    onClick={() =>
                      store.setMaximalTripSeat(store.maximalTripSeat + 1)
                    }
                  >
                    <PlusIcon />
                  </button>
                </div>
                <ErrorMessage>{store.getMaximalTripError()}</ErrorMessage>
              </AmountFieldStyled>
            </FlexContainer>

            <FlexContainer w="100%" wrap="wrap">
              <Checkbox
                name="Wheelchair accessibility"
                labelPlacement="end"
                checked={store.wheelchairAccessible}
                onChange={() =>
                  store.setWheelchairAccessible(!store.wheelchairAccessible)
                }
              />
            </FlexContainer>

            {store.wheelchairAccessible && (
              <FlexContainer w="100%" align="center" wrap="wrap">
                <H3
                  color={palette.black.main}
                  sx={{ minWidth: "300px", paddingLeft: "32px" }}
                >
                  Seats removed per wheelchair
                </H3>
                <AmountFieldStyled>
                  <div className="field">
                    <button
                      type="button"
                      onClick={() =>
                        store.setSeatsRemovedPerWheelchair(
                          Math.max(store.seatsRemovedPerWheelchair - 1, 0)
                        )
                      }
                    >
                      <MinusIcon />
                    </button>
                    <NumberInput
                      value={store.seatsRemovedPerWheelchair}
                      onChange={(e) => {
                        if (e.target.value) {
                          store.setSeatsRemovedPerWheelchair(
                            parseInt(e.target.value) || 0
                          );
                        }
                      }}
                    />
                    <button
                      type="button"
                      onClick={() =>
                        store.setSeatsRemovedPerWheelchair(
                          store.seatsRemovedPerWheelchair + 1
                        )
                      }
                    >
                      <PlusIcon />
                    </button>
                  </div>
                </AmountFieldStyled>
              </FlexContainer>
            )}

            <FlexContainer w="100%" align="center" wrap="wrap">
              <H3 color={palette.black.main} sx={{ minWidth: "300px" }}>
                Dispatch location
              </H3>
              <Select
                onChange={(e) =>
                  store.setDispatchLocationIdToEdit(e.target.value || "")
                }
                sx={{
                  width: theme.spacing(28),
                }}
                value={store.dispatchLocationId}
                items={
                  dispatchLocationStore?.dispatchLocations?.map(
                    (d: DispatchLocation) => ({
                      name: d.name,
                      value: d.id,
                    })
                  ) || []
                }
              />
            </FlexContainer>
          </VehicleFormBlock>

          <VehicleFormBlock>
            <FlexContainer w="100%" direction="column">
              <H3>Amenities</H3>
              <FlexContainer
                w="100%"
                justify="flex-start"
                wrap="wrap"
                columnGap={1.5}
                rowGap={3}
              >
                {AmenityValues.map((amenity, index) => {
                  return (
                    <BigIconSelect
                      title={mapAmenityToName(amenity)}
                      key={index}
                      isActive={store.amenities.some(
                        (a: Amenity) => a === amenity
                      )}
                      onClick={() => store.toggleAmenity(amenity)}
                    >
                      <AmenityIcon type={amenity} />
                    </BigIconSelect>
                  );
                })}
              </FlexContainer>
            </FlexContainer>
          </VehicleFormBlock>
          <Button
            typestyle="primary"
            onClick={() => store.submitForm(vehiclesAuthToken)}
            disabled={!store.getFormIsValid()}
            async
          >
            Save vehicle
          </Button>
        </FlexContainer>
      </Box>
    );
  }
);

export default VehiclesForm;
