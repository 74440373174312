import { palette } from "@busie/ui-kit";
import { styled } from "@mui/system";

const RadioGroupStyled = styled("div")((props) => {
  return {
    display: "grid",
    maxWidth: "500px",
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "12px",
    [props.theme.breakpoints.down("tablet")]: {
      gridTemplateColumns: "1fr",
    },
    ".plus-icon-btn": {
      marginTop: "15px",
    },
    label: {
      fontSize: "10px",
      span: {
        color: palette?.red?.main,
      },
    },
  };
});

export default RadioGroupStyled;
