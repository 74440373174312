import React from "react";

import { QuoteAndBookingStatus } from "@busie/utils";
import { Select as BaseSelect, FlexContainer } from "@busie/ui-kit";
import { StatusBadge } from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  selected: QuoteAndBookingStatus[];
  items: { name: string; value: QuoteAndBookingStatus }[];
  isTablet: boolean;
  onChange: (value: QuoteAndBookingStatus[]) => void;
}

const renderValue = (selected: QuoteAndBookingStatus[], isTablet: boolean) => (
  <FlexContainer
    justify="flex-start"
    direction="row"
    wrap="wrap"
    columnGap={1}
    rowGap={1}
  >
    {selected.map((status) => (
      <StatusBadge key={status} type={status} isTablet={isTablet} />
    ))}
  </FlexContainer>
);

export const Select: React.FC<Props> = ({
  selected,
  items,
  onChange,
  isTablet,
}) => (
  <BaseSelect
    label="Quote Status"
    hideErrorSpace
    onChange={(e) => onChange(e.target.value as QuoteAndBookingStatus[])}
    value={selected}
    renderValue={(selected) =>
      renderValue(selected as QuoteAndBookingStatus[], isTablet)
    }
    items={items}
    multiple
    fullWidth
  />
);
