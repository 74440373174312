import React, { useEffect, useState } from "react";

import { Loading, Select } from "@busie/ui-kit";

import { Customer } from "@busie/utils";
import { useFetchGroup, GroupMember } from "./model";
import { SelectChangeEvent } from "@mui/material";

interface Props {
  groupId: string;
  onSelect(customer: GroupMember): void;
  initialValue?: string;
}

export const SelectGroupMember: React.FC<React.PropsWithChildren<Props>> = ({
  groupId,
  onSelect,
  initialValue,
}) => {
  const { data: group, isLoading } = useFetchGroup({ id: groupId });
  const [groupMembers, setGroupMembers] = useState<Map<string, GroupMember>>(
    new Map()
  );
  const [membersWithEmail, setMembersWithEmail] = useState<Customer[]>([]);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (isLoading) {
      setGroupMembers(new Map());
      return;
    }

    const membersWithEmail: (Customer & { email: string })[] = [];
    const members = (group?.members || []).reduce((acc, customer) => {
      if (customer.email) {
        acc.set(customer.id, {
          ...customer,
          email: customer.email,
          groupName: group?.name || "",
        });
        membersWithEmail.push({ ...customer, email: customer.email });
      }
      return acc;
    }, new Map<string, GroupMember>());

    setGroupMembers(members);
    setMembersWithEmail(membersWithEmail);
  }, [group, isLoading]);

  useEffect(() => {
    if (initialValue && groupMembers.has(initialValue) && !selected) {
      setSelected(initialValue);
      onSelect(groupMembers.get(initialValue) as GroupMember);
    }
  }, [setSelected, initialValue, groupMembers, onSelect, selected]);

  const handleSelect = (e: SelectChangeEvent) => {
    setSelected(e.target.value);
    const member = groupMembers.get(e.target.value);
    if (!member) {
      return onSelect({} as GroupMember);
    }

    onSelect(member);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Select
      onChange={(e) => handleSelect(e)}
      label={
        group?.name
          ? `Select a member of ${group?.name}`
          : "Select a group member"
      }
      value={selected}
      fullWidth
      items={[
        ...membersWithEmail.map((member) => ({
          name: member.name,
          value: member.id,
        })),
        { name: "", value: "" },
      ]}
    />
  );
};
