import {
  Banner,
  DrawerDetailsBlock,
  PathIcon,
  palette,
  theme,
} from "@busie/ui-kit";
import { useMediaQuery } from "@mui/material";
import { tripType } from "~/QuotesAndBookingsPage/shared/lib";

type Props = { tripType: tripType | undefined };

export const TripType: React.FC<React.PropsWithChildren<Props>> = ({
  tripType,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const tripTypeCopy =
    tripType
      ?.split("_")
      .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ") || undefined;

  return (
    <DrawerDetailsBlock noHeader padding="16px 18px 8px 18px">
      <Banner
        bordered
        backgroundColor={palette.supernova.plus40}
        textColor={palette.blue.minus10}
        text={
          tripTypeCopy
            ? `This is a ${tripTypeCopy}`
            : "Unable to determine Trip Type"
        }
        icon={
          <PathIcon
            size={isMobile ? "24px" : "16px"}
            color={palette.blue.minus10}
          />
        }
      />
    </DrawerDetailsBlock>
  );
};
