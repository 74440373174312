import React from "react";
import { styled } from "@mui/system";

import {
  DrawerDetailsBlock,
  palette,
  InputText,
  NoteText,
  Button,
  PropertyString,
  Popup,
  FlexContainer,
} from "@busie/ui-kit";
import { Booking, Trip } from "@busie/utils";
import { updateBookingStatus } from "@busie/api";

import { StatusBlockStyled } from "./styled";

interface Props {
  trip: Trip;
  booking: Booking;
  quotesAuthToken: string;
  onUpdate: () => void;
}

const PendingValueStyled = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
    columnGap: "40px",
  };
});

const ModalContent = styled("div")(({ theme }) => {
  return {
    width: "480px",
    padding: theme?.spacing(5),
    paddingTop: 0,
  };
});

export const BookingStatusBlock: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const [newStatus, setNewStatus] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);

  const handleUpdate = async (status: string) => {
    await updateBookingStatus(props.quotesAuthToken, props.booking._id, status);
    await props.onUpdate();
    setShowPopup(false);
  };

  const showModal = async (status: string) => {
    setNewStatus(status);
    setShowPopup(true);
  };

  return (
    <DrawerDetailsBlock noHeader>
      <Popup
        isPopupOpened={showPopup}
        title="Confirm"
        onClose={() => setShowPopup(false)}
      >
        <ModalContent>
          <InputText>
            Are you sure you want to transition this Booking from{" "}
            {props.booking._status} → {newStatus}?
          </InputText>
          <br />
          <NoteText>This action cannot be undone.</NoteText>
          <FlexContainer justify="flex-end" align="center" columnGap={2} pt={4}>
            <Button typestyle="secondary" onClick={() => setShowPopup(false)}>
              Cancel
            </Button>
            <Button async onClick={async () => await handleUpdate(newStatus)}>
              Save
            </Button>
          </FlexContainer>
        </ModalContent>
      </Popup>
      <StatusBlockStyled>
        <PropertyString justify="space-between" name="Status">
          {props.booking._status === "CANCELED" && (
            <PendingValueStyled>
              <span style={{ color: palette?.red?.main }}>Cancelled</span>
            </PendingValueStyled>
          )}
          {props.booking._status === "PENDING" && (
            <PendingValueStyled>
              <span style={{ color: palette?.supernova?.minus10 }}>
                Pending
              </span>
              <Button onClick={() => showModal("CONFIRMED")}>Confirm</Button>
            </PendingValueStyled>
          )}
          {props.booking._status === "CONFIRMED" && (
            <PendingValueStyled>
              <span style={{ color: palette?.blue?.minus10 }}>Confirmed</span>
              {/* TODO: payment block */}
              {/* <Button>
                Pay
              </Button> */}
            </PendingValueStyled>
          )}
          {props.booking._status === "PAID" && (
            <div>
              <span style={{ color: palette?.green?.minus5 }}>Paid</span>
            </div>
          )}
          {props.booking._status === "COMPLETE" && (
            <div>
              <span style={{ color: palette?.black?.plus10 }}>Complete</span>
            </div>
          )}
        </PropertyString>
        {/* {!["PENDING", "CANCELED"].includes(props.booking._status) && !isCustomer && (
          <PropertyString
            justify="space-between"
            margin="16px 0 5px"
            name="Quote confirmation"
          >
            <Button>Send email</Button>
          </PropertyString>
        )} */}
      </StatusBlockStyled>
    </DrawerDetailsBlock>
  );
};

export default BookingStatusBlock;
