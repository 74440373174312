import {
  H2,
  H3,
  palette,
  NotificationFailureIcon,
  NotificationSuccessIcon,
} from "@busie/ui-kit";
import { Snackbar } from "@mui/material";
import React from "react";
import { notificationStore } from "./store";
import {
  NotificationContent,
  NotificationTextWrapper,
} from "./styled/NotificationContent";

export type NotificationType = "success" | "failure";

interface Props {
  type: NotificationType;
  message?: string;
  header?: string;
  duration?: number;
}

export const Notification: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  message,
  header,
  duration,
}) => {
  const headerText =
    header || (type === "success" ? "Congratulations" : "Oops!");
  const messageText =
    message ||
    (type === "success" ? "Action was successful" : "Something went wrong...");
  return (
    <Snackbar
      open={true}
      autoHideDuration={duration || 7000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={notificationStore.clearNotification}
    >
      <NotificationContent>
        {type === "success" ? (
          <NotificationSuccessIcon />
        ) : (
          <NotificationFailureIcon />
        )}
        <NotificationTextWrapper>
          <H2 weight={600}>{headerText}</H2>
          <H3 color={palette.black.plus30} weight={500} margin="0">
            {messageText}
          </H3>
        </NotificationTextWrapper>
      </NotificationContent>
    </Snackbar>
  );
};
