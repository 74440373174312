import React from "react";
import { styled } from "@mui/system";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  noPadding?: boolean;
}

const IntegrationIconStyled = styled("div")<Props>((props) => {
  return {
    width: "170px",
    margin: "auto",
    height: "79px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: props.noPadding ? "0px" : "34px",
    boxSizing: "content-box",
    img: {
      width: "auto",
      maxWidth: "100%",
      margin: "auto",
      height: "auto",
    },
  };
});

export const IntegrationIcon: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  return (
    <IntegrationIconStyled noPadding={props.noPadding}>
      <img alt="" {...props} />
    </IntegrationIconStyled>
  );
};
