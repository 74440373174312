import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { isOrganizationSettingsAllowed, User } from "@busie/utils";

import { H1, Hr, Loading, Text } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";

import { SettingsContainer } from "~/settings/shared/ui";

import { Form } from "./ui";
import { useTripsSettings, useUpdateTripsSettings } from "./model";

export const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, isLoading } = useAuth0<User>();
  const history = useHistory();

  const {
    data: settings,
    isFetching,
    isError,
  } = useTripsSettings(user?.org_id);

  const { mutateAsync: updateTripSettings, isLoading: isUpdating } =
    useUpdateTripsSettings(settings?.id);

  React.useEffect(() => {
    if (isLoading || !user) return;

    if (!isOrganizationSettingsAllowed(user)) history.push("/");
  }, [isLoading, user, history]);

  if (isError)
    return (
      <SettingsContainer value="Trip">
        <Text>
          We couldn't fetch your organization's Trip settings. Are you sure you
          have access?
        </Text>
      </SettingsContainer>
    );

  const showLoading = isLoading || isFetching || isUpdating || !settings;

  if (showLoading)
    return (
      <SettingsContainer value="Trip">
        <Loading />
      </SettingsContainer>
    );

  return (
    <SettingsContainer value="Trip">
      <div className="new-settings-content">
        <H1>Trip</H1>

        <Hr />

        <Form
          settings={settings}
          onSubmit={async (data) => {
            await updateTripSettings(data, {
              onSuccess: () => {
                notificationStore.setNotification({
                  type: "success",
                  message: "Trip settings updated successfully",
                });
              },
              onError: (e) => {
                notificationStore.setNotificationFromError(e);
              },
            });
          }}
        />
      </div>
    </SettingsContainer>
  );
};
