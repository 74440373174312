import { H3 } from "@busie/ui-kit";
import { Box } from "@mui/material";
import React from "react";
import ReservationFormTabStyled from "../styled/ReservationFormTab.styled";

interface Props {
  step: 0 | 1;
}

const ReservationFormTabs: React.FC<React.PropsWithChildren<Props>> = ({
  step,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "0 16px",
      }}
    >
      <ReservationFormTabStyled isActive={step === 0} isPast={step === 1}>
        <Box>{step ? "✓" : "1"}</Box>
        <H3>SELECT TRANSPORT</H3>
      </ReservationFormTabStyled>
      <ReservationFormTabStyled isActive={step === 1}>
        <Box>2</Box>
        <H3>RESERVATION DETAILS</H3>
      </ReservationFormTabStyled>
    </Box>
  );
};

export default ReservationFormTabs;
