import {
  UpdateQuoteMainContactData,
  patchQuoteFuelSurcharge,
  patchQuoteLastMinuteSurgeCharge,
  patchQuotePrice,
  patchQuoteTaxSurcharge,
  updateQuoteAssignee,
  updateQuoteEnablePlatformPricingMarkup,
  updateQuoteMainContact,
} from "@busie/api";
import { useQuotesAndBookingsAuthToken } from "@busie/core";
import { useMutation } from "react-query";

export const useUpdateQuoteMainContact = (id: string) => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(async (data: UpdateQuoteMainContactData) =>
    updateQuoteMainContact(authToken, id, data)
  );
};

export const useUpdateQuotePrice = (id: string) => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(async (price: number) =>
    patchQuotePrice(authToken, id, price)
  );
};

export const useUpdateQuoteFuelSurcharge = (id: string) => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(async (fuelSurcharge: number) =>
    patchQuoteFuelSurcharge(authToken, id, fuelSurcharge)
  );
};

export const useUpdateQuoteLastMinuteSurcharge = (id: string) => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(async (lastMinuteSurcharge: number) =>
    patchQuoteLastMinuteSurgeCharge(authToken, id, lastMinuteSurcharge)
  );
};

export const useUpdateQuoteTaxSurcharge = (id: string) => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(async (tax: number) =>
    patchQuoteTaxSurcharge(authToken, id, tax)
  );
};

export const useUpdateQuoteEnablePlatformPricingMarkup = (id: string) => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(async (enableMarkups: boolean) =>
    updateQuoteEnablePlatformPricingMarkup(authToken, id, enableMarkups)
  );
};

export const useUpdateQuoteAssignee = (id: string) => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(async (assigneeId: string | null) =>
    updateQuoteAssignee(authToken, id, { assigneeId })
  );
};
