import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { ChefQuote } from "@busie/api";
import {
  Button,
  Divider,
  FlexContainer,
  FormContainer,
  H2,
  NoteText,
  palette,
} from "@busie/ui-kit";
import { QuoteAndBookingStatus } from "@busie/utils";
import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import {
  confirmSchema,
  getModalCopy,
  reasonSchema,
  useUpdateQuoteStatus,
} from "./model";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "./ui";
import { notificationStore } from "@busie/features";
import { useAmplitude } from "@busie/core";

interface FormValues {
  confirm: string;
}

interface Props {
  quote: ChefQuote;
  newStatus: QuoteAndBookingStatus;
  isOpen: boolean;
  onClose: () => void;
  onStatusUpdate: () => void;
}

export const UpdateQuoteStatus: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
  newStatus,
  isOpen,
  onClose,
  onStatusUpdate,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      confirm: "",
    },
    resolver: yupResolver(
      newStatus === QuoteAndBookingStatus.DECLINED
        ? reasonSchema
        : confirmSchema
    ),
  });

  const { mutateAsync: updateQuoteStatus, isLoading } = useUpdateQuoteStatus(
    quote._id,
    newStatus
  );

  const { track } = useAmplitude();

  const { title, text, note, button } = getModalCopy(newStatus);

  const onSubmit = async ({ confirm }: FormValues) => {
    const reason =
      newStatus === QuoteAndBookingStatus.DECLINED ? confirm : undefined;

    try {
      await updateQuoteStatus(reason, {
        onSuccess: async () => {
          let trackEvent = "";
          switch (newStatus) {
            case QuoteAndBookingStatus.ACCEPTED:
              trackEvent = "quote accepted";
              break;
            case QuoteAndBookingStatus.DECLINED:
              trackEvent = "quote declined";
              break;
            case QuoteAndBookingStatus.CONFIRMED:
              trackEvent = "quote confirmation sent";
              break;
            default:
              break;
          }

          trackEvent.length &&
            track(trackEvent, {
              quoteId: quote._id,
              price: quote.PRICE,
              numberOfTrips: quote.EXPERIENCES.length,
            });

          notificationStore.setNotification({
            type: "success",
            header: "Success",
            message: "Quote status updated successfully",
          });

          onStatusUpdate();
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  useEffect(() => reset, [reset, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <H2>Update Quote Status</H2>
      <FormContainer title={title}>
        <H2>{text}</H2>
        <NoteText>
          {note +
            (quote.QUOTE_INTEGRATION_ID && newStatus === "DECLINED"
              ? " Since this is a network quote, you may lose visibility into some of the data associated with this quote."
              : "")}
        </NoteText>

        <NoteText color={palette.red.main}>
          This action cannot be undone
        </NoteText>

        <Divider />

        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer
            align="flex-start"
            direction="column"
            columnGap={2}
            fullWidth
          >
            <Controller
              name="confirm"
              control={control}
              render={({ field }) => (
                <FormInput
                  field={field}
                  errorMessage={errors.confirm?.message}
                  status={newStatus}
                />
              )}
            />
          </FlexContainer>

          <div className="form-submit-control">
            <Button
              typestyle="secondary"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </Button>

            <Button
              typestyle="primary"
              type="submit"
              disabled={!isDirty || !isValid || isSubmitting || isLoading}
            >
              {button}
            </Button>
          </div>
        </form>
      </FormContainer>
    </Modal>
  );
};
