import React from "react";

import { TableRowProps } from "@mui/material/TableRow";

import TableRowStyled from "./styled/TableRowStyled";

const TableRow: React.FC<React.PropsWithChildren<TableRowProps>> = (
  props: TableRowProps
) => {
  return <TableRowStyled {...props} />;
};

export default TableRow;
