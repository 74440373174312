import React from "react";
import { styled } from "@mui/system";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { observer } from "mobx-react";
import {
  DateRangePicker,
  palette,
  FlexContainer,
  Button,
  Select,
  ChevronNext,
  ChevronPrev,
  InputText,
} from "@busie/ui-kit";
import ViewModeSwitch from "./ViewModeSwitch";
import VehiclesStore from "~/vehicles/store";
import FilterBlock from "./FilterBlock";
import { getFormattedDate } from "~/vehicles/store/helpers";
import { useMediaQuery } from "@mui/material";
import CalendarDatePicker from "../CalendarView/components/CalendarDatePicker";
import ReservationsFilter from "./ReservationsFilter";

const Clickable = styled("div")`
  cursor: pointer;
`;

const VehiclesFilterStyled = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "row",
    columnGap: "16px",
    padding: "12px 16px",
    textAlign: "left",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [`.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}, input`]:
      {
        borderColor: `${palette?.black?.plus70}`,
      },
  };
});

const VehiclesFilter: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const viewMode = VehiclesStore.viewMode;
    const setDateRange = VehiclesStore.setSelectedDateRange;
    const isTablet = useMediaQuery("@media (max-width: 850px)");
    const ReservationsFilterChildren = (onClose: () => void) => (
      <ReservationsFilter onClose={onClose} />
    );

    return (
      <VehiclesFilterStyled>
        <FlexContainer direction="row" columnGap={1}>
          <ViewModeSwitch />
          <FilterBlock>{ReservationsFilterChildren}</FilterBlock>
          <Select
            value={VehiclesStore.calendarViewPeriod}
            items={[
              {
                name: "Month",
                value: "month",
              },
              {
                name: "Week",
                value: "week",
              },
              {
                name: "Day",
                value: "day",
              },
            ]}
            onChange={(e) =>
              VehiclesStore.setCalendarViewPeriod(e.target.value)
            }
          />
          {viewMode === "calendar" ? (
            <CalendarDatePicker />
          ) : (
            <DateRangePicker
              value={VehiclesStore.selectedDateRange}
              disablePast={false}
              onChange={setDateRange}
              maxWidth={2000}
            />
          )}
        </FlexContainer>

        <FlexContainer
          direction={isTablet ? "column-reverse" : "row"}
          columnGap={2}
          align="center"
        >
          <FlexContainer>
            <Clickable
              style={{
                lineHeight: "0",
              }}
              onClick={() => VehiclesStore.addToCalendarPeriod(-1)}
            >
              <ChevronPrev color={palette.black.main} />
            </Clickable>
            <Clickable
              style={{
                lineHeight: "0",
              }}
              onClick={() => {
                VehiclesStore.addToCalendarPeriod(1);
              }}
            >
              <ChevronNext color={palette.black.main} />
            </Clickable>
          </FlexContainer>

          <InputText
            sx={{
              fontSize: "1rem",
              fontWeight: "600",
              textAlign: "center",
              minWidth: "125px",
            }}
          >
            {getFormattedDate(
              VehiclesStore.reservationsFilter.startAt,
              VehiclesStore.reservationsFilter.endAt,
              VehiclesStore.calendarViewPeriod,
              isTablet
            )}
          </InputText>
          {!isTablet && (
            <Button
              typestyle="secondary"
              onClick={() => {
                VehiclesStore.setSelecetedCalendarPeriod(new Date());
              }}
            >
              Today
            </Button>
          )}
        </FlexContainer>
      </VehiclesFilterStyled>
    );
  }
);

export default VehiclesFilter;
