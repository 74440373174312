import React, { FC, useMemo } from "react";
import { Box, styled, Theme } from "@mui/system";
import { observer } from "mobx-react-lite";

import { usePagination } from "@busie/core";
import { Recipe } from "@busie/utils";
import {
  palette,
  IconButton,
  FlexContainer,
  IngredientIcon,
  Recipe as RecipeIcon,
  RuleIcon,
  EditIcon,
  H3,
  NoteText,
} from "@busie/ui-kit";

import store from "~/rates/RateMenuPage/store";

import { ruleTypes } from "~/rates/RateMenuPage/store/constants";
import { baseIngredientTypes } from "~/rates/RateMenuPage/store/constants";
import { prettifyRuleValue } from "./helpers";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

interface Props {
  recipe: Recipe;
  onExpandToggle?: () => void;
  onDeletePopupOpen?: () => void;
  detailed?: boolean;
}

const RecipeHeaderStyled = styled(Box)(() => {
  return {
    width: "100%",
    minHeight: "60px",
    backgroundColor: "white",
    color: palette?.black?.plus10,
    border: "1px solid",
    borderColor: palette.black.plus70,
    borderRadius: "4px",
    position: "relative",
    display: "grid",
    gridTemplateColumns: "4fr 1fr 1fr 1fr 1fr 30px",
    gridTemplateAreas:
      "'recipe rule value base-ingredients side-ingredients edit'",
    padding: 8,
    cursor: "pointer",
    "& > div:last-child": {
      display: "none",
    },
    "&:hover > div:last-child": {
      display: "flex",
    },
    "@media (max-width: 1000px)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      gridTemplateRows: "30px 1fr",
      gridTemplateAreas:
        "'recipe recipe recipe edit' 'rule value base-ingredients side-ingredients'",
      rowGap: "16px",
    },
    "@media (max-width: 600px)": {
      gridTemplateColumns: "1fr 1fr 1fr 30px",
      gridTemplateRows: "30px 1fr 1fr",
      gridTemplateAreas:
        "'recipe recipe recipe edit' 'rule rule value value' 'base-ingredients base-ingredients side-ingredients side-ingredients'",
      rowGap: "16px",
    },
  };
});

const RecipeView: FC<React.PropsWithChildren<Props>> = observer(
  ({ recipe }) => {
    const { search } = useLocation();
    const history = useHistory();
    const [currentPage] = usePagination();

    const searchParams = new URLSearchParams(search);
    const isMobile = useMediaQuery("@media (max-width: 600px)");

    const handleClick = () => {
      searchParams.set("recipeId", recipe.id);
      searchParams.set("page", currentPage.toString());

      store.rates.setActiveRecipe(recipe);
      history.replace({
        search: `?${searchParams.toString()}`,
      });
    };

    const baseIngredientsCount = useMemo(() => {
      return recipe.ingredients.filter((ingredient) =>
        baseIngredientTypes.includes(ingredient.type)
      ).length;
    }, [recipe]);
    const sideIngredientsCount = useMemo(() => {
      return recipe.ingredients.filter(
        (ingredient) => !baseIngredientTypes.includes(ingredient.type)
      ).length;
    }, [recipe]);

    return (
      <RecipeHeaderStyled onClick={handleClick}>
        <FlexContainer
          columnGap={0.5}
          align="center"
          sx={{
            gridArea: "recipe",
          }}
        >
          {!isMobile && <RecipeIcon />}
          <FlexContainer direction="column">
            <NoteText>Recipe</NoteText>
            <H3 margin="0">{recipe.name || "No name"}</H3>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          columnGap={0.5}
          align="center"
          sx={{
            gridArea: "rule",
          }}
        >
          {!isMobile && <RuleIcon />}
          <FlexContainer direction="column">
            <NoteText>Rule</NoteText>
            <H3 margin="0">{ruleTypes[recipe.rule.type]}</H3>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          columnGap={0.5}
          align="center"
          sx={{
            gridArea: "value",
          }}
        >
          {!isMobile && <RuleIcon />}
          <FlexContainer direction="column">
            <NoteText>Value</NoteText>
            <H3 margin="0">{prettifyRuleValue(recipe.rule)}</H3>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          direction="row"
          align="center"
          columnGap={1}
          sx={{
            gridArea: "base-ingredients",
          }}
        >
          {!isMobile && <IngredientIcon />}
          <FlexContainer direction="column">
            <NoteText>Base ingredients</NoteText>
            <H3 margin="0">{baseIngredientsCount}</H3>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          direction="row"
          align="center"
          columnGap={1}
          sx={{
            gridArea: "side-ingredients",
          }}
        >
          {!isMobile && <IngredientIcon />}
          <FlexContainer direction="column">
            <NoteText>Side ingredients</NoteText>
            <H3 margin="0">{sideIngredientsCount}</H3>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          justify="flex-end"
          fullWidth
          sx={{
            gridArea: "edit",
          }}
        >
          <IconButton
            icon={<EditIcon />}
            type="button"
            highlightOnHover={false}
          />
        </FlexContainer>
      </RecipeHeaderStyled>
    );
  }
);

export default RecipeView;
