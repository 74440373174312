import { H3, SendIcon, palette } from "@busie/ui-kit";
import { PayClaimSubmissionNotificationRecipient } from "@busie/utils";
import React from "react";
import { DecoratedListItem } from "~/settings/shared/ui";

interface Props {
  recipient: PayClaimSubmissionNotificationRecipient;
}

export const ListItem: React.FC<React.PropsWithChildren<Props>> = ({
  recipient,
}) => {
  return (
    <DecoratedListItem
      title={recipient.name}
      icon={<SendIcon color={palette.black.main} />}
    >
      <H3 weight={500}>{recipient.email}</H3>
    </DecoratedListItem>
  );
};
