import React from "react";

import { Button, Menu, MoreIcon, IconButton } from "@busie/ui-kit";

import { ListControlsButtonsStyled } from "./styled";

import { updateBookingStatus } from "@busie/api";

interface Props {
  bookingStatus: string;
  quotesAuthToken: string;
  selectedIds: string[];
  onListUpdate: () => void;
}

export const ListControlsButtons: React.FC<React.PropsWithChildren<Props>> = ({
  bookingStatus,
  quotesAuthToken,
  selectedIds,
  onListUpdate,
}: Props) => {
  const hanlderUpdate = async (status: string) => {
    await Promise.all(
      selectedIds.map((id) => updateBookingStatus(quotesAuthToken, id, status))
    );
    await onListUpdate();
  };
  const getActions = (
    status: string
  ): {
    mainAction?: {
      content: string;
      action: () => void;
    };
    secondaryActions?: {
      content: string;
      action: () => void;
    }[];
  } => {
    switch (status) {
      case "PENDING":
        return {
          mainAction: {
            content: "Booking confirmation",
            action: async () => await hanlderUpdate("CONFIRMED"),
          },

          secondaryActions: [
            {
              content: "Cancel bookings",
              action: async () => await hanlderUpdate("CANCELED"),
            },
          ],
        };
      case "CONFIRMED":
        return {
          mainAction: {
            content: "Cancel bookings",
            action: async () => await hanlderUpdate("CANCELED"),
          },
        };

      default:
        return {};
    }
  };

  const actions = getActions(bookingStatus);

  return (
    <>
      {actions.mainAction && (
        <Button
          onClick={actions.mainAction.action}
          async
          typestyle={"secondary"}
        >
          {actions.mainAction.content}
        </Button>
      )}
      {actions.secondaryActions?.length && (
        <ListControlsButtonsStyled>
          <Menu
            bottom="100%"
            toggler={<IconButton icon={<MoreIcon />} />}
            items={actions.secondaryActions}
          />
        </ListControlsButtonsStyled>
      )}
    </>
  );
};
