import React from "react";
import { Styled } from "./styled";

interface Props {
  text: string;
  onClick: () => void;
}

export const DateSuggestion: React.FC<Props> = ({ text, onClick }) => {
  return <Styled onClick={onClick}>{text}</Styled>;
};
