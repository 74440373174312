import React from "react";
import { Modal } from "@busie/ui-kit";
import { Driver } from "@busie/utils";

import { DriverForm, DriverFormData } from "~/drivers/entity";

import { useEditDriver } from "./model";
import { metadataReducer } from "~/drivers/model";

interface Props {
  isOpen: boolean;
  driver: Driver;
  onDriverUpdated: (driver: Driver) => void;
  onClose: () => void;
}

export const EditDriver: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  driver,
  onDriverUpdated,
  onClose,
}) => {
  const { mutateAsync: editDriver } = useEditDriver(driver._id);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <DriverForm
        onCancel={onClose}
        onSubmit={async (formData: DriverFormData) => {
          const metadata = formData.metadata.length
            ? formData.metadata.reduce(metadataReducer, {})
            : {};

          const updatedDriver = await editDriver({
            ...formData,
            metadata,
            licenses: [],
            preferredNotificationChannels: [],
            payRates: [],
            createdAt: driver.createdAt,
            createdBy: driver.createdBy,
          });

          onDriverUpdated(updatedDriver);
        }}
        driver={driver}
      />
    </Modal>
  );
};
