import React from "react";

import { Integration, Quote } from "@busie/utils";
import { DrawerDetailsBlock, PropertyString } from "@busie/ui-kit";
import { OrganizationLogo } from "~/QuotesAndBookingsPage/shared/ui";
import { datetimeFormatter as dateFormat } from "@busie/utils";
import { getRevenueShareConfig } from "@busie/api";
import { usePaymentsAuthToken } from "@busie/core";

interface Props {
  quote: Quote;
  integration: Integration;
}

export const TripIntegrationInfo: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
  integration,
}) => {
  const [revenueShare, setRevenueShare] = React.useState(0);
  const paymentAuthToken = usePaymentsAuthToken();

  React.useEffect(() => {
    if (!paymentAuthToken) return;

    getRevenueShareConfig(
      paymentAuthToken,
      integration.revenueShareConfigId
    ).then((data) => {
      const destination = data.destinations.find(
        (dest) => dest.organizationId === integration.sourceId
      );
      destination && setRevenueShare(destination.apportionmentValue);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAuthToken]);

  return (
    <DrawerDetailsBlock collapsible name="Integration Info">
      <PropertyString name="Logo">
        <OrganizationLogo src={integration.logo} />
      </PropertyString>
      <PropertyString name="Organization">
        {integration.organizationName}
      </PropertyString>
      <PropertyString name="Integration type">
        {integration.integrationType === "DESTINATION"
          ? "Destination"
          : "Source"}
      </PropertyString>
      <PropertyString name="Revenue share %">{revenueShare}%</PropertyString>
      {quote._expirationDate && (
        <PropertyString name="Expiration date">
          {dateFormat(new Date(quote._expirationDate), "datetime")}
        </PropertyString>
      )}
    </DrawerDetailsBlock>
  );
};
