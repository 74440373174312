import React from "react";

interface Props {
  size?: number;
}

export const DriverIcon: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const size = props.size || 24;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_17468_67954"
        mask="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <circle cx="12" cy="12" r="12" fill="#FAFAFA" />
      </mask>
      <g mask="url(#mask0_17468_67954)">
        <g filter="url(#filter0_i_17468_67954)">
          <rect
            x="5.2063"
            y="13.9683"
            width="13.4603"
            height="12.3175"
            rx="3"
            fill="#C5BEC6"
          />
        </g>
        <g filter="url(#filter1_i_17468_67954)">
          <rect
            x="7.61902"
            y="5.96826"
            width="8.38095"
            height="12.6984"
            rx="4.19048"
            fill="#C67A60"
          />
        </g>
        <path
          d="M6.62501 18.6947C6.64813 17.5153 7.61076 16.5714 8.7903 16.5714C9.22676 16.5714 9.65303 16.7033 10.0132 16.9497L10.4945 17.279C11.3438 17.8601 12.4433 17.9291 13.3586 17.4588L14.9239 16.6544C15.7063 16.2523 16.6602 16.6744 16.8889 17.5238L17.2063 19.4285L17.7778 23.3016H6.66664L6.60315 19.8095L6.62501 18.6947Z"
          fill="#FFF0F1"
        />
        <path
          d="M4.69836 21.5873L6.73011 17.5238L8.06344 23.3016H6.66662L4.69836 21.5873Z"
          fill="#FF5F6E"
        />
        <path
          d="M19.1746 21.7143L16.8888 17.5238L15.746 23.0476L17.0158 23.3016L19.1746 21.7143Z"
          fill="#FF7683"
        />
        <path
          d="M10.6666 19.5199L11.9658 18.1588L12.4607 18.7421L12.77 19.131C12.8112 20.0168 12.8937 21.8013 12.8937 21.8532C12.8937 21.905 13.0175 23.2575 13.0793 23.9273L10.8522 24.381L10.7904 22.955L10.6666 19.5199Z"
          fill="#FFF0F1"
        />
        <path
          d="M4.69836 24.1905L8.3809 21.9683L9.14281 20.1905L4.69836 21.5873V24.1905Z"
          fill="url(#paint0_linear_17468_67954)"
        />
        <path
          d="M19.1746 23.9365L15.492 21.9683L14.9206 20.254L19.1746 21.6508V23.9365Z"
          fill="url(#paint1_linear_17468_67954)"
        />
        <path
          d="M10.4127 16.8254V15.3651H13.2699V16.6984L11.7461 18.3492L10.4127 16.8254Z"
          fill="#FFCEBF"
        />
        <path
          d="M13.3178 15.8894L11.949 18.0519C11.9034 18.124 11.9086 18.2171 11.9619 18.2838L12.3807 18.8073L12.7261 19.239C12.8081 19.3416 12.9651 19.3385 13.0432 19.2328L14.7238 16.9558C14.7947 16.8597 14.7662 16.7233 14.6627 16.6637L13.3178 15.8894Z"
          fill="#FF7683"
        />
        <path
          d="M14.0953 17.093L12.8889 16.5714L13.2699 15.873L14.7937 16.6349L12.7619 19.5692L12.4445 19.1882L14.0953 17.093Z"
          fill="#FF5C6B"
        />
        <path
          d="M9.95844 16.1319L9.26264 16.4798C9.16214 16.53 9.12274 16.6532 9.17542 16.7525L10.2861 18.8449C10.4437 19.1419 10.8436 19.2018 11.0813 18.9641L11.5801 18.4653C11.75 18.2954 11.775 18.0287 11.6397 17.8301L10.5952 16.2975C10.4543 16.0908 10.1822 16.02 9.95844 16.1319Z"
          fill="#FF7683"
          stroke="url(#paint2_linear_17468_67954)"
        />
        <path
          d="M14.8572 16.6349L8.88892 23.5556H10.1588L16.127 16.5079L14.8572 16.6349Z"
          fill="#444444"
          stroke="#444444"
          stroke-linejoin="round"
        />
        <circle cx="11.9745" cy="22.4762" r="3.18254" stroke="#C77E67" />
        <path
          d="M5.0791 23.894L6.71951 23.4081C8.36933 22.9193 10.0787 22.6607 11.7993 22.6396C13.7412 22.6157 15.6749 22.8945 17.5309 23.4661L18.9204 23.894V25.5242H5.0791V23.894Z"
          fill="#8C818F"
        />
      </g>
      <path
        d="M9.77169 21.6719C9.49166 22.1569 8.87146 22.3231 8.38644 22.043C7.90142 21.763 8.25852 21.3963 8.53854 20.9113C8.81857 20.4263 8.91549 20.0066 9.40051 20.2866C9.88553 20.5666 10.0517 21.1868 9.77169 21.6719Z"
        fill="#FFCEBF"
      />
      <path
        d="M14.2212 21.6719C14.5013 22.1569 15.1215 22.3231 15.6065 22.043C16.0915 21.763 15.7344 21.3963 15.4544 20.9113C15.1743 20.4263 15.0774 20.0066 14.5924 20.2866C14.1074 20.5666 13.9412 21.1868 14.2212 21.6719Z"
        fill="#FFCEBF"
      />
      <g filter="url(#filter2_i_17468_67954)">
        <path
          d="M12.1231 8.88892C11.3395 9.6886 10.2473 10.1848 9.03923 10.1848C8.83876 10.1848 8.64149 10.1711 8.44829 10.1447C8.43464 10.1428 8.42245 10.1534 8.42245 10.1672C8.42245 10.179 8.41327 10.1888 8.40149 10.1899C7.82004 10.2448 7.36511 10.7344 7.36511 11.3302C7.36511 11.8715 7.74051 12.3251 8.24515 12.4448C8.34371 12.4682 8.42245 12.5506 8.42245 12.6519C8.42245 14.5011 9.92151 16.0001 11.7707 16.0001C13.6199 16.0001 15.1189 14.5011 15.1189 12.6519C15.1189 12.5307 15.2039 12.4282 15.3149 12.3795C15.7183 12.2022 16 11.7991 16 11.3302C16 10.7935 15.6309 10.343 15.1326 10.2188C15.1246 10.2168 15.1189 10.2096 15.1189 10.2013C15.1189 10.1917 15.1111 10.1837 15.1014 10.1835C13.9354 10.1555 12.8839 9.66523 12.1231 8.88892Z"
          fill="#FFCEBF"
        />
      </g>
      <rect
        x="10.049"
        y="11.8061"
        width="0.583441"
        height="0.933505"
        rx="0.29172"
        fill="#C67A60"
      />
      <rect
        x="12.9662"
        y="11.8061"
        width="0.583441"
        height="0.933505"
        rx="0.29172"
        fill="#C67A60"
      />
      <path
        d="M12.4994 13.5565C12.4994 14.0076 12.1598 14.3733 11.7409 14.3733C11.322 14.3733 10.9824 14.0076 10.9824 13.5565"
        stroke="#F97878"
        stroke-width="0.5"
        stroke-linecap="round"
      />
      <g filter="url(#filter3_i_17468_67954)">
        <path
          d="M7.04586 8.14811C6.76613 7.74604 6.50358 7.05991 7.04586 6.83604L11.5053 4.99503C11.6429 4.93818 11.7941 4.93818 11.9318 4.99503L16.3912 6.83604C16.9334 7.05992 16.6709 7.74604 16.3912 8.14811L15.909 9.01588C15.87 9.79654 15.4008 10.491 14.6911 10.8185L14.6343 10.8447C14.3447 10.9784 14.0295 11.0476 13.7106 11.0476H9.83208C9.52654 11.0476 9.21277 11.0294 8.93122 10.9107C8.8711 10.8854 8.80736 10.8576 8.74158 10.8283C8.03241 10.5126 7.5668 9.79121 7.52804 9.01588L7.04586 8.14811Z"
          fill="url(#paint3_linear_17468_67954)"
        />
      </g>
      <mask
        id="mask1_17468_67954"
        mask="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="6"
        y="4"
        width="11"
        height="8"
      >
        <path
          d="M7.04586 8.14811C6.76613 7.74604 6.50358 7.05991 7.04586 6.83604L11.5053 4.99503C11.6429 4.93818 11.7941 4.93818 11.9318 4.99503L16.3912 6.83604C16.9334 7.05992 16.6709 7.74604 16.3912 8.14811L15.909 9.01588C15.87 9.79654 15.4008 10.491 14.6911 10.8185L14.6343 10.8447C14.3447 10.9784 14.0295 11.0476 13.7106 11.0476H9.83208C9.52654 11.0476 9.21277 11.0294 8.93122 10.9107C8.8711 10.8854 8.80736 10.8576 8.74158 10.8283C8.03241 10.5126 7.5668 9.79121 7.52804 9.01588L7.04586 8.14811Z"
          fill="url(#paint4_linear_17468_67954)"
        />
      </mask>
      <g mask="url(#mask1_17468_67954)">
        <g filter="url(#filter4_d_17468_67954)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.7198 14.2725C16.7112 13.6147 17.1428 12.8332 17.1428 12.127C17.1428 11.4208 16.7112 10.6392 15.7198 9.9815C14.737 9.32941 13.3129 8.88889 11.6825 8.88889C10.0522 8.88889 8.62806 9.32941 7.6452 9.9815C6.65383 10.6392 6.2222 11.4208 6.2222 12.127C6.2222 12.8332 6.65383 13.6147 7.6452 14.2725C8.62806 14.9246 10.0522 15.3651 11.6825 15.3651C13.3129 15.3651 14.737 14.9246 15.7198 14.2725ZM11.6825 16.5079C15.3294 16.5079 18.2857 14.5465 18.2857 12.127C18.2857 9.70745 15.3294 7.74603 11.6825 7.74603C8.03569 7.74603 5.07935 9.70745 5.07935 12.127C5.07935 14.5465 8.03569 16.5079 11.6825 16.5079Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.4"
          d="M15.0475 9.0794C14.9967 8.87622 13.5449 8.23284 12.8253 7.87305C13.6888 7.97463 15.2803 8.5503 15.9999 8.82543L16.8253 9.77781L16.3809 11.1111L14.4126 11.3016L13.5872 10.9207L15.0475 9.90479C15.0687 9.71432 15.0983 9.28257 15.0475 9.0794Z"
          fill="white"
        />
      </g>
      <circle cx="11.746" cy="8.31749" r="0.952381" fill="#FAFAFA" />
      <g clip-path="url(#clip0_17468_67954)">
        <path
          d="M12.2029 8.11155C12.2729 8.03977 12.2729 7.9234 12.2029 7.85162C12.1329 7.77985 12.0194 7.77985 11.9495 7.85162C11.8795 7.9234 11.8795 8.03977 11.9495 8.11155L12.0287 8.19277C12.0549 8.21969 12.0974 8.21969 12.1237 8.19277L12.2029 8.11155Z"
          fill="#FF1A2F"
          stroke="#FAFAFA"
          stroke-width="0.075"
        />
        <path
          d="M11.5309 8.11155C11.6009 8.03977 11.6009 7.9234 11.5309 7.85162C11.4609 7.77985 11.3474 7.77985 11.2775 7.85162C11.2075 7.9234 11.2075 8.03977 11.2775 8.11155L11.3567 8.19277C11.3829 8.21969 11.4254 8.21969 11.4517 8.19277L11.5309 8.11155ZM11.42 7.99783C11.4113 8.0068 11.3971 8.0068 11.3883 7.99783C11.3796 7.98886 11.3796 7.97431 11.3883 7.96534C11.3971 7.95637 11.4113 7.95637 11.42 7.96534C11.4288 7.97431 11.4288 7.98886 11.42 7.99783Z"
          fill="#FF1A2F"
          stroke="#FAFAFA"
          stroke-width="0.075"
        />
        <path
          d="M11.2698 8.19816C11.2698 8.18792 11.2818 8.1828 11.2889 8.19003L11.3883 8.29202C11.3971 8.301 11.4113 8.301 11.42 8.29202L11.5195 8.19003C11.5265 8.1828 11.5386 8.18792 11.5386 8.19816V8.50997C11.5386 8.62417 11.6288 8.71674 11.7402 8.71674C11.8515 8.71674 11.9418 8.62417 11.9418 8.50997V8.19816C11.9418 8.18792 11.9538 8.1828 11.9609 8.19003L12.0603 8.29202C12.0691 8.301 12.0833 8.301 12.092 8.29202L12.1915 8.19003C12.1985 8.1828 12.2106 8.18792 12.2106 8.19816V8.50997C12.2106 8.77643 12 8.99244 11.7402 8.99244C11.4804 8.99244 11.2698 8.77643 11.2698 8.50997V8.19816Z"
          fill="#FF1A2F"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_17468_67954"
          x="5.2063"
          y="13.9683"
          width="13.4603"
          height="12.3174"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_17468_67954"
          />
        </filter>
        <filter
          id="filter1_i_17468_67954"
          x="7.61902"
          y="5.96826"
          width="8.38098"
          height="12.6984"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-7" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_17468_67954"
          />
        </filter>
        <filter
          id="filter2_i_17468_67954"
          x="7.36511"
          y="8.88892"
          width="8.63489"
          height="7.11121"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_17468_67954"
          />
        </filter>
        <filter
          id="filter3_i_17468_67954"
          x="6.7301"
          y="4.95239"
          width="9.97681"
          height="6.09521"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-8" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_17468_67954"
          />
        </filter>
        <filter
          id="filter4_d_17468_67954"
          x="1.07935"
          y="7.74603"
          width="21.2063"
          height="16.7619"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17468_67954"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17468_67954"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_17468_67954"
          x1="6.22217"
          y1="21.6508"
          x2="9.14281"
          y2="20.9524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF3F51" />
          <stop offset="1" stop-color="#FF192F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_17468_67954"
          x1="17.7161"
          y1="21.5984"
          x2="14.9328"
          y2="20.9064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF3F51" />
          <stop offset="1" stop-color="#FF192F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_17468_67954"
          x1="11.9365"
          y1="21.8413"
          x2="8.88887"
          y2="16.3175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7683" />
          <stop offset="1" stop-color="#FF7A86" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_17468_67954"
          x1="11.7185"
          y1="4.95239"
          x2="11.6825"
          y2="16.5714"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-color="#3B3B3B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_17468_67954"
          x1="11.7185"
          y1="4.95239"
          x2="11.7185"
          y2="11.0507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF0018" />
          <stop offset="0.96875" stop-color="#FF0018" stop-opacity="0.03125" />
          <stop offset="1" stop-color="#FF0018" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_17468_67954">
          <rect
            width="1.14286"
            height="1.26984"
            fill="white"
            transform="translate(11.1746 7.74603)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
