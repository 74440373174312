import { styled } from "@mui/system";

import { Theme } from "@mui/system";
interface Props {
  theme?: Theme;
}

const PopupControlsStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    background: palette?.black?.plus100,
    padding: "0 40px 40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    ".forward-controls": {},
  };
});

export default PopupControlsStyled;
