import { FlexContainer, H3, H4, Text } from "@busie/ui-kit";
import { dayjsExt } from "@busie/utils";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
  formatRemainingDuration,
  getPaletteColorFromProgressColor,
  getProgressColor,
} from "./lib";

interface Props {
  expirationDate: string;
  createdAt: string;
}

export const TTLProgress: React.FC<Props> = ({
  expirationDate: expirationDatetimeString,
  createdAt: createdDatetimeString,
}) => {
  const createdAt = dayjsExt(createdDatetimeString);
  const expiresAt = dayjsExt(expirationDatetimeString);
  const now = dayjsExt();

  const totalDurationSeconds = expiresAt.diff(createdAt, "second");
  const [remainingDurationSeconds, setRemainingDurationSeconds] = useState(
    expiresAt.diff(now, "second")
  );
  const [progress, setProgress] = useState(
    (remainingDurationSeconds / totalDurationSeconds) * 100
  );

  useEffect(() => {
    console.log("effect running");
    setInterval(() => {
      const now = dayjsExt();
      setRemainingDurationSeconds(expiresAt.diff(now, "second"));
      setProgress((prev) => prev - 1 / totalDurationSeconds);
    }, 800);
    // eslint-disable-next-line
  }, []);

  return (
    <FlexContainer align="center" justify="center" direction="column">
      <CircularProgress
        variant="determinate"
        value={progress}
        size={24}
        color={getProgressColor(progress)}
      />
      <H4 color={getPaletteColorFromProgressColor(getProgressColor(progress))}>
        {formatRemainingDuration(remainingDurationSeconds)}
      </H4>
    </FlexContainer>
  );
};
