import React from "react";
import cx from "classnames";

import { DeleteIcon, FlexContainer, IconButton } from "@busie/ui-kit";

import { BlockContainer } from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  start: React.ReactNode;
  departure: React.ReactNode;
  destination: React.ReactNode;
  actions?: React.ReactNode[];
  onDelete?: () => void;
}

export const Container: React.FC<Props> = ({
  start,
  departure,
  destination,
  actions,
  onDelete,
}) => {
  return (
    <BlockContainer sx={{ width: "100%" }}>
      {/* TODO: [BUSIE-1728] */}
      {/* <div className="block-header">
        <FlexContainer justify="flex-end" w="100%">
          {onDelete && <IconButton icon={<DeleteIcon />} onClick={onDelete} />}
        </FlexContainer>
      </div> */}
      <FlexContainer
        align="center"
        justify="space-between"
        fullWidth
        columnGap={2}
      >
        <div
          className={cx({
            departures: true,
            end: false,
            start: true,
            last: false,
            first: true,
          })}
          style={{ width: "100%" }}
        >
          <FlexContainer
            direction="row"
            align="center"
            justify="flex-start"
            columnGap={2}
            className={cx({
              origin: true,
              start: true,
            })}
            fullWidth
          >
            {start}

            {departure}

            {onDelete && (
              <IconButton icon={<DeleteIcon />} onClick={onDelete} />
            )}
          </FlexContainer>

          <div
            className={cx({
              destination: true,
              end: true,
            })}
          >
            {destination}
          </div>
        </div>
      </FlexContainer>

      <FlexContainer justify="flex-start" columnGap={2} pt={2}>
        {actions}
      </FlexContainer>
    </BlockContainer>
  );
};
