import { datetimeFormatter, Invoice } from "@busie/utils";
import {
  COLUMN_NAME_CREATION_DATE,
  COLUMN_NAME_STATUS,
  COLUMN_NAME_UPDATED_DATE,
} from "./constants";
import { getStatusColor } from "../../../model";

export const getTableData = (
  invoices: Invoice[],
  getStatusComponent: (color: string, status: string) => React.ReactNode
) =>
  invoices.map((invoice) => ({
    [COLUMN_NAME_CREATION_DATE]: datetimeFormatter(
      new Date(invoice.createdAt),
      "date"
    ),
    [COLUMN_NAME_UPDATED_DATE]: datetimeFormatter(
      new Date(invoice.updatedAt),
      "date"
    ),
    [COLUMN_NAME_STATUS]: getStatusComponent(
      getStatusColor(invoice.status),
      invoice.status
    ),
  }));
