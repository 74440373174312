import { FlexContainer, H3, H4 } from "@busie/ui-kit";
import { datetimeFormatter, dayjsExt } from "@busie/utils";
import React from "react";

interface Props {
  value: string;
}

export const Cell: React.FC<Props> = ({ value }) => {
  return (
    <FlexContainer direction="column" justify="center" align="flex-start">
      <H3 margin="0px">{datetimeFormatter(dayjsExt(value), "L")}</H3>
      <H4>{datetimeFormatter(dayjsExt(value), "LT")}</H4>
    </FlexContainer>
  );
};
