import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { Popup } from "@busie/ui-kit";

import store from "~/rates/RateMenuPage/store";

import RateRuleAndIngredientsBlock from "~/rates/RateMenuPage/components/CreateRecipeModal/RateRuleAndIngredientsBlock";
import RecipeInCookbookForm from "~/rates/RateMenuPage/components/CreateRecipeModal/RecipeInCookbookForm";
import PopupControls from "~/rates/RateMenuPage/components/CreateRecipeModal/PopupControls";

import { createRecipe } from "@busie/api";
import { styled } from "@mui/system";
import { palette } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";

interface Props {
  handleClose: () => void;
  onSuccess: () => void;
  isPopupOpened: boolean;
  authToken: string;
}

const CreateRecipeModal: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ handleClose, onSuccess, isPopupOpened, authToken }) => {
    const [activeStep, setActiveStep] = useState(0);

    const onClose = () => {
      handleClose();
      setTimeout(() => {
        setActiveStep(0);
        store.createRecipeModal.setInitialState();
      }, 0);
    };

    const submitData = async () => {
      await createRecipe(store.createRecipeModal.formData, authToken);
      const recipeName = store.createRecipeModal.formData.name;
      notificationStore.setSuccessNotification(
        "Success!",
        `The ${recipeName} recipe was created`
      );
      await onSuccess();
      await onClose();
    };

    const stepsComponents = [
      <RateRuleAndIngredientsBlock key={0} />,
      <RecipeInCookbookForm key={1} />,
    ];

    const onBack =
      activeStep > 0 ? () => setActiveStep(activeStep - 1) : undefined;

    return (
      <Popup
        onClose={onClose}
        onBack={onBack}
        isPopupOpened={isPopupOpened}
        title="New rate recipe"
        bgcolor={
          activeStep === 0 ? palette?.black?.plus80 : palette?.black?.plus100
        }
        subtitle={`Step ${activeStep + 1} of ${stepsComponents.length}`}
      >
        <div>
          {stepsComponents[activeStep]}
          <PopupControls
            onClose={onClose}
            currentStep={activeStep + 1}
            stepsQty={stepsComponents.length}
            onNextStep={() => setActiveStep(activeStep + 1)}
            onSubmit={submitData}
          />
        </div>
      </Popup>
    );
  }
);

export default CreateRecipeModal;
