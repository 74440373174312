import { Text, palette } from "@busie/ui-kit";
import { styled } from "@mui/system";
import { isToday } from "date-fns";

export const CalendarWeekViewWrapperStyled = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
  rowGap: "1px",
  columnGap: "1px",
  padding: "1px",
  paddingTop: "0",
  backgroundColor: palette.black.plus70,
}));

export const DayHeaderStyled = styled("div")(
  ({ date, percentagePassed }: { date: Date; percentagePassed: number }) => {
    const isDateToday = isToday(date);
    return {
      height: "73px",
      backgroundColor: palette.black.plus85,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      cursor: "pointer",

      // dot at the bottom
      "&:after": isDateToday
        ? {
            content: "''",
            height: "6px",
            width: "6px",
            backgroundColor: palette.black.plus60,
            borderRadius: "100px",
            position: "absolute",
            top: "70px",
            left: `calc(${(percentagePassed * 100) / 1440}% - 3px)`,
            zIndex: 300,
          }
        : {},
    };
  }
);

export const ReservationBarText = styled(Text)(() => ({
  fontSize: "12px",
  fontWeight: 500,
}));
