import { GooglePlace } from "@busie/utils";
import { Place } from "./schema";

export const serializePlaceForForm: (place: GooglePlace) => Place = (place) => {
  const {
    geometry: {
      location: { lat, lng },
    },
  } = place;
  return {
    ...place,
    geometry: { location: { lat: lat(), lng: lng() } },
  };
};
