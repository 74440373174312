import { palette } from "@busie/ui-kit";
import React, { FC } from "react";

/*
const url = 'http://localhost:4200';
const src = url + '?'+ new URLSearchParams({
  organizationApiKey: "d057817cb27b733bdb823ba48e774555",
  visitorId: "visitorId",
  domain: "acme.com",
}).toString()
console.log(src)
*/

export const EmbeddedFormContainer: FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <iframe
      title="embedded-form"
      src="http://localhost:4200?organizationApiKey=d057817cb27b733bdb823ba48e774555&visitorId=visitorId&domain=acme.com"
      style={{
        width: "100%",
        height: 800,
        margin: "5vh auto",
        display: "block",
        border: `1px solid ${palette?.black?.plus70}`,
      }}
    ></iframe>
  );
};
