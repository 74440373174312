import React from "react";
import { Checkbox, palette } from "@busie/ui-kit";

interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
  name?: string;
  indeterminate?: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  labelColor?: string;
  labelWeight?: number | string;
}

const CheckboxStyled: React.FC<React.PropsWithChildren<CheckboxProps>> = (
  props: CheckboxProps
) => {
  return (
    <Checkbox
      {...props}
      labelColor={palette.black.plus20}
      labelWeight={"600"}
    />
  );
};
export default CheckboxStyled;
