import { QueryKey, useQuery } from "react-query";

import {
  ListDriversParams,
  fetchDriver,
  fetchDrivers,
  fetchDriversSettings,
} from "@busie/api";
import { useDriversAuthToken, queryClient } from "@busie/core";

export const DRIVERS_QUERY_KEY: QueryKey = "DRIVERS";
export const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

export const invalidateDriversQueries = () => {
  queryClient.invalidateQueries(DRIVERS_QUERY_KEY);
};

export const useDriversSettings = (organizationId?: string) => {
  const driversAuthToken = useDriversAuthToken();

  const result = useQuery(
    [DRIVERS_QUERY_KEY, "settings", organizationId],
    () => fetchDriversSettings(driversAuthToken, organizationId as string),
    {
      enabled: !!driversAuthToken && !!organizationId,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useFetchDrivers = (params: ListDriversParams) => {
  const driversAuthToken = useDriversAuthToken();

  const result = useQuery(
    [DRIVERS_QUERY_KEY, "drivers", params],
    () => fetchDrivers(driversAuthToken, params),
    {
      enabled: !!driversAuthToken,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useFetchDriver = (driverId?: string) => {
  const driversAuthToken = useDriversAuthToken();

  const result = useQuery(
    [DRIVERS_QUERY_KEY, "driver", driverId],
    () => fetchDriver(driversAuthToken, driverId as string),
    {
      enabled: !!driversAuthToken && !!driverId,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};
