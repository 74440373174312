import { styled } from "@mui/system";
import { FlexContainerProps } from "../FlexContainer/FlexContainer";
import palette from "../theme/palette";
import { FlexContainer } from "../FlexContainer";

interface Props extends FlexContainerProps {
  padding?: string;
}

const Panel: React.FC<React.PropsWithChildren<Props>> = styled(FlexContainer)(
  (props: Props) => {
    return {
      padding: props.padding || "20px",
      border: `1px solid ${palette?.black?.plus70}`,
      borderRadius: "4px",
    };
  }
);

export default Panel;
