import { DriverFormData } from "./interface";

// TODO Adjust driver form data to omit some fields.
export const DEFAULT_FORM_VALUES: DriverFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  metadata: [],
};
