import * as yup from "yup";

export interface FormValues {
  confirm: string;
}

const MUST_MATCH_CONFIRM_MESSAGE = "input must match 'confirm'";

export const schema = yup.object({
  confirm: yup
    .string()
    .required(MUST_MATCH_CONFIRM_MESSAGE)
    .matches(/confirm/, MUST_MATCH_CONFIRM_MESSAGE),
});
