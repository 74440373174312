import { useReactToPrint } from "react-to-print";
import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { fetchQuote, fetchTrip } from "@busie/api";
import { Quote, Trip } from "@busie/utils";
import { useAmplitude } from "@busie/core";
import {
  Drawer as BusieDrawer,
  Menu as BusieMenu,
  DrawerHeader,
  IconButton,
  Loading,
  MenuItem,
  MoreIcon,
  palette,
  Popup,
  theme,
} from "@busie/ui-kit";
import { getTripType, tripType } from "~/QuotesAndBookingsPage/shared/lib";
import { quotesStore } from "~/QuotesAndBookingsPage/model";
import { StatusBlock } from "../entity/DetailsDrawer/StatusBlock";
import { TripInfo } from "../entity/DetailsDrawer/TripInfo";
import { TripRoute } from "../entity/DetailsDrawer/TripRoute";
import { TripPlanner } from "../entity/DetailsDrawer/TripPlanner";
import { TripIntegrationInfo } from "../entity/DetailsDrawer/TripIntegrationInfo";
import { AdditionalActions } from "../entity/DetailsDrawer/QuoteAdditionalActions";
import { AdditionalInfo } from "../entity/DetailsDrawer/TripAdditionalInfo";
import { TripType } from "../entity/DetailsDrawer/TripType";
import { QuoteInfo } from "../entity/DetailsDrawer/QuoteInfo";
import { Vehicles } from "../entity/DetailsDrawer/Vehicles";
import { EditForm } from "../entity/DetailsDrawer/EditForm";
import { CustomerProfileDetails } from "../../shared/CustomerProfileDetails";
import { BookingRequested } from "../entity/DetailsDrawer/BookingRequested";

interface Props {
  authTokens: {
    quotes: string;
    customers: string;
    trips: string;
  };
  // onAddTrip: () => void;
  onListUpdate: () => void;
  isCustomerView?: boolean;
}

export const Drawer: React.FC<React.PropsWithChildren<Props>> = ({
  authTokens,
  onListUpdate,
  isCustomerView,
}: // onAddTrip,
Props) => {
  const [trip, setTrip] = useState<Trip>({} as Trip);
  const [tripType, setTripType] = useState<tripType>();
  const [quote, setQuote] = useState<Quote>({} as Quote);
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const [dialogStatus, setDialogStatus] = useState<{
    open: boolean;
    mode: "view" | "edit" | "add-trip";
  }>({
    open: false,
    mode: "view",
  });

  const [loading, setLoading] = useState<boolean>(true);

  const { search } = useLocation();
  const { track } = useAmplitude();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const [infoExpanded, setInfoExpanded] = useState(false);
  const [updatesCount, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const handlerUpdateQuoteAndTrip = async () => {
    const quote = await fetchQuote(
      authTokens["quotes"],
      searchParams.get("quoteId") || ""
    );
    setQuote(quote);

    const trip = await fetchTrip(authTokens["trips"], quote._experienceId, {
      populateLegs: true,
    });
    setTrip(trip);
    setTripType(
      getTripType(
        // first leg destination is the pickup
        trip._legs[0]._destinationLocation,
        // last leg start location is the destination
        trip._legs[trip._legs.length - 1]._startLocation
      )
    );
    setLoading(false);
    track("quote viewed", {
      quoteId: quote._id,
      tripId: trip._id,
      price: quote._totalPrice,
      numberOfLegs: trip._legs.length,
      numberOfPassengers: trip._passengers,
      tripType:
        getTripType(
          trip._legs[0]._destinationLocation,
          trip._legs[trip._legs.length - 1]._startLocation
        ) === "round_trip",
    });
  };

  React.useEffect(() => {
    if (searchParams.has("quoteId")) {
      if (searchParams.get("mode") === "edit") {
        setDialogStatus({ open: true, mode: "edit" });
      } else if (searchParams.get("mode") === "add-trip") {
        setDialogStatus({ open: true, mode: "add-trip" });
      } else {
        setDialogStatus({ open: true, mode: "view" });
      }
    } else {
      return;
    }
    authTokens["quotes"] && authTokens["trips"] && handlerUpdateQuoteAndTrip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authTokens["quotes"], authTokens["trips"]]);

  const handlerOnClose = () => {
    searchParams.delete("quoteId");
    history.replace({
      search: `?${searchParams.toString()}`,
    });

    setDialogStatus({ open: false, mode: "view" });
  };

  const handlerOnUpdate = async () => {
    await handlerUpdateQuoteAndTrip();
    await onListUpdate();
  };

  const integration = quotesStore.getIntegration(
    quote._quotesIntegrationId || "",
    quote._organizationId
  );

  const componentRef = React.useRef(null);
  const printingRef = React.useRef(false);
  const handlePrint = () => {
    printingRef.current = true;
    if (infoExpanded) {
      forceUpdate();
    } else {
      setInfoExpanded(true);
    }
  };

  React.useEffect(() => {
    if (printingRef.current === true) {
      printingRef.current = false;
      print();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printingRef.current, updatesCount]);

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Trip Details",
  });

  const handleClose = () => {
    searchParams.delete("mode");
    history.replace({
      search: `?${searchParams.toString()}`,
    });
  };

  const getQuoteMenuComponent = (): React.ReactNode => {
    const items: MenuItem[] = [];

    items.push({
      content: "Edit",
      action: () => {
        setDialogStatus({ open: true, mode: "edit" });
        searchParams.set("mode", "edit");
        history.replace({
          search: `?${searchParams.toString()}`,
        });
      },
    });

    // items.push({
    //   content: "Add Trip",
    //   action: onAddTrip,
    // });

    return (
      <BusieMenu
        right="0%"
        items={items}
        toggler={
          <IconButton
            highlightOnHover={false}
            icon={<MoreIcon color={palette.black.plus100} />}
          />
        }
      />
    );
  };

  return (
    <>
      <BusieDrawer
        anchor="right"
        open={dialogStatus.open && dialogStatus.mode === "view"}
        onClose={handlerOnClose}
      >
        <DrawerHeader onClose={handlerOnClose} title="Quote Details">
          {getQuoteMenuComponent()}
        </DrawerHeader>
        {loading ? (
          <Loading />
        ) : (
          <div className="trip-details-wrapper" ref={componentRef}>
            <TripType tripType={tripType} />
            {quote._bookingRequested && <BookingRequested />}
            <StatusBlock
              quotesAuthToken={authTokens["quotes"]}
              trip={trip}
              quote={quote}
              onUpdate={handlerOnUpdate}
            />
            <QuoteInfo trip={trip} quote={quote} />
            {trip && (trip._mainContactId || trip._tripPlannerId) && (
              <CustomerProfileDetails
                title="Main Contact Info"
                collapsible={true}
                customerId={trip._mainContactId || trip._tripPlannerId}
              />
            )}
            {trip._additionalInformation && (
              <AdditionalInfo
                trip={trip}
                expanded={infoExpanded}
                setExpanded={setInfoExpanded}
              />
            )}
            <Vehicles trip={trip} />
            <TripInfo trip={trip} showPriceData={true} />
            <TripRoute trip={trip} quote={quote} />
            <TripPlanner trip={trip} quote={quote} />
            {integration && (
              <TripIntegrationInfo quote={quote} integration={integration} />
            )}
            {!isCustomerView && (
              <AdditionalActions
                onUpdate={handlerOnUpdate}
                onClose={handlerOnClose}
                onPrint={handlePrint}
                quote={quote}
                trip={trip}
                quotesAuthToken={authTokens["quotes"]}
              />
            )}
          </div>
        )}
      </BusieDrawer>
      {/* TODO: consolidate with the popup component in Booking details */}
      <Popup
        isPopupOpened={dialogStatus.open && dialogStatus.mode !== "view"}
        title={"Edit quote"}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: isMobile ? "100vw" : "800px",
            maxWidth: isMobile ? "100vw" : "800px",
            margin: isMobile ? 0 : "initial",
          },
        }}
        // @ts-expect-error no error
        style={
          !isMobile
            ? undefined
            : {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
              }
        }
      >
        <EditForm type="quote" id={quote._id} onClose={handleClose} />
      </Popup>
    </>
  );
};
