import React from "react";

import { FlexContainer, MenuHeader, Button, AddIcon } from "@busie/ui-kit";
// import {StatisticsBlock } from "@busie/ui-kit";
// import { ArrowUp } from "@busie/ui-kit";

export const PageHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <MenuHeader>
      <FlexContainer columnGap={5}>
        {/* <StatisticsBlock text="All time quotes" value="1534" />
        <StatisticsBlock text="Total daily quotes" value="27" />
        <StatisticsBlock text="Total daily booking" value="19" />
        <StatisticsBlock
          text="Total quotes conversion"
          value="83%"
          valueIcon={<ArrowUp color={palette?.green?.main} size={18} />}
        /> */}
      </FlexContainer>
      <div>
        <Button startIcon={<AddIcon />} href={"/quotes-and-bookings/add-quote"}>
          Add new quote
        </Button>
      </div>
    </MenuHeader>
  );
};

export default PageHeader;
