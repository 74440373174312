import { Reservation, Vehicle } from "@busie/utils";
import { ChevronDown, ChevronUp, H3, palette } from "@busie/ui-kit";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { mapVehicleTypeToName } from "@busie/core";
import store from "~/vehicles/store";
import { addDays, addHours, startOfDay, startOfHour } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import MediumCalendarCell from "./MediumCalendarCell";
import VehicleRow from "./VehicleRow";

interface Props {
  type: number;
  vehicles: Vehicle[];
  reservations: Reservation[];
}

const VehicleTypeBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ type, vehicles, reservations }) => {
    const [isExpanded, setIsExpanded] = React.useState(reservations.length > 0);
    const isDayView = store.calendarViewPeriod === "day";
    const vehiclesWithReservations: {
      vehicle: Vehicle;
      reservations: Reservation[];
    }[] = vehicles.map((vehicle) => ({
      vehicle,
      reservations: reservations.filter((res) => res.vehicleId === vehicle.id),
    }));

    return (
      <>
        <Box
          sx={{
            height: "48px",
            backgroundColor: palette.black.plus85,
            display: "flex",
            alignItems: "center",
            padding: "13px 16px",
          }}
        >
          <IconButton onClick={() => setIsExpanded((val) => !val)}>
            {isExpanded ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
          <H3 margin="0" color={palette.black.plus10} weight={500}>
            {" "}
            {mapVehicleTypeToName(type)} ({vehicles.length}){" "}
          </H3>
        </Box>
        {(isDayView ? [...Array(24).keys()] : [...Array(7).keys()]).map((i) => {
          const date = isDayView
            ? addHours(
                startOfHour(store.reservationsFilter.startAt || new Date()),
                i
              )
            : addDays(
                startOfDay(store.reservationsFilter.startAt || new Date()),
                i
              );
          return <MediumCalendarCell date={date} key={i} />;
        })}
        {isExpanded &&
          vehiclesWithReservations.map((vehicle) => (
            <VehicleRow key={vehicle.vehicle.id} {...vehicle} />
          ))}
      </>
    );
  }
);

export default VehicleTypeBlock;
