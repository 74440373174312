import { styled, Theme } from "@mui/system";
interface Props {
  color?: string;
  theme?: Theme;
  weight?: number;
  margin?: string;
}

const H3 = styled("h3")<Props>((props: Props) => {
  const palette = props.theme?.palette;
  return {
    "&": {
      color: props.color || palette?.black.main,
      fontWeight: props.weight || 600,
      fontSize: "14px",
      lineHeight: "150%",
      margin: props.margin || "14px 0",
    },
  };
});

export default H3;
