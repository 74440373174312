import { Box } from "@mui/system";
import React from "react";
import theme from "../theme";
import palette from "../theme/palette";
import { Icon } from "@mui/material";

interface Props {
  text?: string;
  icon?: React.ReactNode;
  onClick: () => void;
}

const HeaderButton: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  icon,
  onClick,
}) => {
  if (icon) {
    return (
      <Icon onClick={onClick} sx={{ cursor: "pointer" }}>
        {icon}
      </Icon>
    );
  }

  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundColor: "transparent",
        color: palette.black.plus100,
        fontSize: theme.spacing(2),
      }}
      onClick={onClick}
    >
      {text || "Click Me!"}
    </Box>
  );
};

export default HeaderButton;
