import { QueryKey, useMutation, useQuery } from "react-query";

import {
  queryClient,
  useCustomerAuthToken,
  usePathfinderAuthToken,
} from "@busie/core";
import { RouteRequest, createRouteRequest, fetchCustomer } from "@busie/api";

const CREATE_TRIP_QUERY_KEY: QueryKey = "CREATE_TRIP";

const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

export const invalidateCreateTripQueries = () => {
  queryClient.invalidateQueries(CREATE_TRIP_QUERY_KEY);
};

export const useTripMainContact = (id: string) => {
  const authToken = useCustomerAuthToken();

  const result = useQuery(
    [CREATE_TRIP_QUERY_KEY, "customer", id],
    () => fetchCustomer(authToken, id),
    {
      enabled: !!id && !!authToken,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useCreateTripRoute = () => {
  const authToken = usePathfinderAuthToken();

  const mutation = useMutation(async (data: RouteRequest) => {
    const result = await createRouteRequest(authToken, data);

    return result;
  });

  return mutation;
};
