import { styled } from "@mui/system";
import Tab from "@mui/material/Tab";

const TabStyled = styled(Tab)(
  ({ backgroundColor }: { backgroundColor?: string }) => {
    return {
      "&.MuiButtonBase-root": {
        width: "100%",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "100%",
        letterSpacing: "0.04em",
        maxWidth: "50%",
        textTransform: "none",
        color: "#a1a1a1",
        "&.Mui-selected": {
          background: backgroundColor || "#fff",
          borderTopLeftRadius: "2px",
          borderTopRightRadius: "2px",
          color: "#222222",
        },
      },
    };
  }
);

export default TabStyled;
