import React from "react";

interface Props {
  color?: string;
  size?: string;
}

export const LocationIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = "#3B3B3B",
  size = 16,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.66663V8.66663C6.89533 8.66663 6 7.77129 6 6.66663V6.66663C6 5.56196 6.89533 4.66663 8 4.66663V4.66663C9.10467 4.66663 10 5.56196 10 6.66663V6.66663C10 7.77129 9.10467 8.66663 8 8.66663Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 14C7.99998 14 3.33331 10.1667 3.33331 6.66667C3.33331 4.08933 5.42265 2 7.99998 2C10.5773 2 12.6666 4.08933 12.6666 6.66667C12.6666 10.1667 7.99998 14 7.99998 14Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
