import { DateRange } from "../dateRange";

export type LogicalQuoteState =
  // Quote with status PENDING or CONFIRMED
  | "quote"
  // Booking with status PENDING, CONFIRMED, or PAID
  | "booking"
  // a quote with status DECLINED
  | "archived quote"
  // a booking with status CANCELLED or COMPLETED
  | "archived booking";

export type Quote = {
  _createdAt: string;
  _createdBy: string;
  _contactName: string;
  _customerGroupId: string;
  _deletedAt?: string;
  _deletedBy?: string;
  _experienceId: string;
  _id: string;
  _isLastMinute: boolean;
  _locationId: string;
  _organizationId: string;
  _startDate: string;
  _status: QuoteAndBookingStatus;
  _price?: number;
  _totalPrice?: number;
  _updatedAt: string;
  _updatedBy?: string;
  _contactEmail?: string;
  _quotesIntegrationId?: string;
  _expirationDate?: string;
  _fuelSurcharge: number;
  _lastMinuteSurgeCharge: number;
  _taxSurcharge: number;
  _platformFixedSurcharge: number;
  _platformPercentageSurcharge: number;
  _paymentFixedSurcharge: number;
  _paymentPercentageSurcharge: number;
  _networkFixedSurcharge: number;
  _networkPercentageSurcharge: number;
  _reasonForDecline?: string;
  _enablePlatformPricingMarkup: boolean;
  _bookingRequested: boolean;
  _mainContactId?: string;
};

export type Integration = {
  id: string;
  sourceId: string;
  destinationId: string;
  revenueShareConfigId: string;
  ttl: number;
  ttlWeekend: number;
  status: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;
  integrationType?: string;
  organizationName?: string;
  logo?: string;
};

export type QuotesIntegration = {
  organizationLogos: {
    [key: string]: string;
  };
  organizationNames: {
    [key: string]: string;
  };
  integrations: Integration[];
  sourceIntegrations: Integration[];
  destinationIntegrations: Integration[];
};

export type QuoteNestedInBooking = {
  createdAt: string;
  createdBy: string;
  customerGroupId: string;
  deletedAt?: string;
  deletedBy?: string;
  experienceId: string;
  id: string;
  isLastMinute: boolean;
  locationId: string;
  organizationId: string;
  startDate: string;
  status: QuoteAndBookingStatus;
  price?: number;
  totalPrice?: number;
  updatedAt: string;
  updatedBy?: string;
  contactEmail?: string;
  quotesIntegrationId?: string;
  expirationDate?: string;
  bookingRequested: boolean;
};

export type QuotesAndBookingsFilter = {
  // status: QuoteAndBookingStatus[];
  dateRange: DateRange;
};

export enum QuoteAndBookingStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  PAID = "PAID",
  CANCELED = "CANCELED",
  COMPLETE = "COMPLETE",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export enum EnvelopeStatus {
  COMPLETED = "completed",
  SIGNED = "signed",
  SENT = "sent",
  DELIVERED = "delivered",
  VOIDED = "voided",
  DECLINED = "declined",
  DELETED = "deleted",
}

export enum ReasonForDecline {
  VEHICLE_AVAILABILITY = "Vehicle Availability",
  DRIVER_AVAILABILITY = "Driver Availability",
  CUSTOMER_UNRESPONSIVE = "Customer Unresponsive",
  OTHER = "Other",
}

// export type QuoteAndBookingStatus =
//   | "PENDING"
//   | "CONFIRMED"
//   | "PAID"
//   // | "IN_PROGRESS"
//   | "CANCELED"
//   | "COMPLETE"
//   | "ACCEPTED"
//   | "DECLINED";

export type Booking = {
  _createdAt: string;
  _createdBy: string;
  _updatedAt: string;
  _updatedBy?: string;
  _deletedAt?: string;
  _deletedBy?: string;
  _id: string;
  _quote: QuoteNestedInBooking;
  _quoteId: string;
  _contractId?: string;
  _payments: unknown[];
  _balance: number;
  _status: QuoteAndBookingStatus;
  _reasonForCancel?: string;
  _contractSigned: boolean;
  _contractExpired: boolean;
  _contractStatus: EnvelopeStatus;
  _invoiceId?: string;
};

export type CompanyHolidayRepeat = "ANNUALLY" | "NONE" | "MONTHLY";

export type CompanyHoliday = {
  id: string;
  name: string;
  organizationId: string;
  locationId: string;
  holidayRangeFrom: string;
  holidayRangeTo: string;
  repeat: CompanyHolidayRepeat;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type QuotesAndBookingsSettings = {
  id: string;
  organizationId: string;
  email: string;
  defaultSignerName: string;
  lastMinuteSurge: number;
  fuelSurcharge: number;
  taxSurcharge: number;
  enableAssigneeContractSigner: boolean;
  enablePlatformPricingMarkup?: boolean;
};

export type CustomerContactInfo = {
  contactEmail: string;
  contactName?: string;
};
export type AdminContactInfo = { email?: string; name?: string };
export type SendContractDto = {
  contactInfo?: CustomerContactInfo;
  adminInfo?: AdminContactInfo;
};
export type VoidedContractInfo = {
  reason: string;
};
