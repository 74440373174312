import qs from "qs";

import { dayjsExt, history } from "@busie/utils";

export const getFilterParams = (): {
  status: string;
  searchParam: string;
  searchValue: string;
  locationId: string | "ALL";
  pickupDateFrom: Date | null;
  pickupDateTo: Date | null;
} => {
  const searchParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  return {
    status: `${searchParams.status || "ALL"}`,
    locationId: `${searchParams.locationId || "ALL"}`,
    searchParam: `${searchParams.searchParam}` || "",
    searchValue: `${searchParams.searchValue}` || "",
    pickupDateFrom: searchParams.pickupDateFrom
      ? dayjsExt(`${searchParams.pickupDateFrom}`).toDate()
      : null,
    pickupDateTo: searchParams.pickupDateTo
      ? dayjsExt(`${searchParams.pickupDateTo}`).toDate()
      : null,
  };
};
