import { DispatchLocationFormData } from "@busie/api";
import { GooglePlace } from "@busie/utils";

interface ConvertedAddress {
  country: string;
  state: string;
  city: string;
  street: string;
  postalCode: string;
  route: string;
  streetNumber: string;
}

const MAP_ADDRESS: Record<string, keyof ConvertedAddress> = {
  country: "country",
  administrative_area_level_1: "state",
  locality: "city",
  street_address: "street",
  postal_code: "postalCode",
  route: "route",
  street_number: "streetNumber",
};

export const convertAddress = (data: GooglePlace): ConvertedAddress =>
  data.address_components.reduce(
    (result, address) => {
      const requiredField = address.types.find((type) =>
        Object.keys(MAP_ADDRESS).includes(type)
      );

      if (requiredField) {
        const mapKey = MAP_ADDRESS[requiredField];
        result[mapKey] = address.long_name;
      }

      return result;
    },
    {
      country: "",
      state: "",
      city: "",
      street: "",
      postalCode: "",
      route: "",
      streetNumber: "",
    }
  );

export const getAddress = (
  data: GooglePlace
): Partial<DispatchLocationFormData> => {
  const address = convertAddress(data);

  return {
    country: address.country,
    state: address.state,
    city: address.city,
    postalCode: address.postalCode,
    street: address.street
      ? address.street
      : `${address.streetNumber} ${address.route}`,
  };
};
