import { styled } from "@mui/material";
import { theme } from "@busie/ui-kit";
import { palette } from "@busie/ui-kit";

interface Props {
  isToday?: boolean;
}

const DayNumberStyled = styled("div")(({ isToday }: Props) => {
  return {
    height: theme?.spacing(3),
    width: theme?.spacing(3),
    backgroundColor: isToday ? palette?.red.main : "transparent",
    color: isToday ? palette?.black?.plus100 : "inherit",
    borderRadius: "100px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export default DayNumberStyled;
