import React from "react";
import { palette, PropertyString } from "@busie/ui-kit";

import { propertyValue } from "../../lib";

interface Props {
  name: propertyValue;
  value: propertyValue;
}

const primaryNameColor = palette.black.plus20;
const primaryValueColor = palette.black.main;
const secondaryNameColor = palette.black.plus40;
const secondaryValueColor = palette.black.plus30;

export const PrimaryPropertyString: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <PropertyString
      name={props.name}
      nameColor={primaryNameColor}
      valueColor={primaryValueColor}
    >
      <>{props.value}</>
    </PropertyString>
  );
};

export const SecondaryPropertyString: React.FC<
  React.PropsWithChildren<Props>
> = (props: Props) => {
  return (
    <PropertyString
      name={props.name}
      nameColor={secondaryNameColor}
      valueColor={secondaryValueColor}
    >
      <>{props.value}</>
    </PropertyString>
  );
};
