import {
  Banner,
  DocumentsIcon,
  DrawerDetailsBlock,
  palette,
} from "@busie/ui-kit";
import React from "react";

export const BookingRequestedBanner: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <DrawerDetailsBlock noHeader padding="16px 18px 8px 18px">
      <Banner
        bordered
        backgroundColor={palette.supernova.plus40}
        textColor={palette.green.minus5}
        icon={<DocumentsIcon color={palette.green.minus5} />}
        text="Customer requested booking!"
      />
    </DrawerDetailsBlock>
  );
};
