import { RouteStop } from "@busie/utils";

export const initialRouteStop: RouteStop = {
  address_components: [{ long_name: "", short_name: "", types: [""] }],
  formatted_address: "",
  geometry: {
    location: {
      lat: () => 0,
      lng: () => 0,
    },
  },
  place_id: "",
  html_attributions: [""],
};
