import { VehicleIcon } from "@busie/core";
import {
  CompletedStatusText,
  ConfirmedStatusText,
  DeclinedStatusText,
  DrawerDetailsBlock,
  DriverIcon,
  FlexContainer,
  IconButton,
  InProgressStatusText,
  Loading,
  PendingStatusText,
  ReservationIcon,
  Text,
  palette,
} from "@busie/ui-kit";
import { Reservation, ReservationStatuses, VehicleTypes } from "@busie/utils";
import React from "react";

interface Props {
  reservations: Reservation[];
  isLoading: boolean;
  onClick: (id: string) => void;
}

const getReservationStatusText = (status: ReservationStatuses) => {
  return {
    [ReservationStatuses.PENDING]: <PendingStatusText />,
    [ReservationStatuses.CONFIRMED]: <ConfirmedStatusText />,
    [ReservationStatuses.IN_PROGRESS]: <InProgressStatusText />,
    [ReservationStatuses.COMPLETE]: <CompletedStatusText />,
    [ReservationStatuses.DECLINED]: <DeclinedStatusText />,
  }[status];
};

export const Reservations: React.FC<React.PropsWithChildren<Props>> = ({
  reservations,
  isLoading,
  onClick,
}) => {
  return (
    <DrawerDetailsBlock name="Reservations" collapsible>
      {isLoading ? (
        <Loading size={24} padding="0" />
      ) : (
        reservations.map((data, index) => (
          <FlexContainer
            direction="row"
            justify="space-between"
            align="center"
            key={`reservation-${index}`}
          >
            <FlexContainer direction="row" columnGap={1} align="center">
              <VehicleIcon
                type={(data?.vehicle?.vehicleType.type as VehicleTypes) || 0}
                size={24}
              />
              <Text>{data?.vehicle?.licensePlate}</Text>
            </FlexContainer>
            <FlexContainer align="center" columnGap={1}>
              <DriverIcon size={24} />
              <Text
                color={
                  (data?.driverId && palette.black.main) || palette.red.main
                }
              >
                {data?.driverId ? data.driverId : "No Driver Assigned!"}
              </Text>
            </FlexContainer>
            {data?.status || data?.status === 0
              ? getReservationStatusText(data.status)
              : null}
            <IconButton
              icon={<ReservationIcon />}
              onClick={() => onClick(data.id)}
            />
          </FlexContainer>
        ))
      )}
    </DrawerDetailsBlock>
  );
};
