import { ReservationStatuses, ReservationTypes } from "@busie/utils";
import { palette } from "@busie/ui-kit";

type ReservationTextAndColors = {
  text: string;
  background: string;
  color: string;
};

export const getReservationStatusBadgeContents = (
  status: ReservationStatuses
): ReservationTextAndColors => {
  switch (status) {
    case 0:
      return {
        text: "Pending",
        background: palette?.supernova?.plus40,
        color: palette?.supernova?.minus10,
      };
    case 1:
      return {
        text: "Confirmed",
        background: palette?.blue?.plus40,
        color: palette?.blue?.minus10,
      };
    case 2:
      return {
        text: "In Progress",
        background: palette?.supernova?.plus40,
        color: palette?.supernova?.minus10,
      };
    case 3:
      return {
        text: "Completed",
        background: palette?.black?.plus80,
        color: palette?.black?.main,
      };
    case 4:
    default:
      return {
        text: "Declined",
        background: palette?.red?.plus80,
        color: palette?.red?.main,
      };
  }
};

export const getReservationTypeBadgeContents = (
  type: ReservationTypes
): ReservationTextAndColors => {
  switch (type) {
    case 0:
      return {
        text: "Charter",
        background: palette?.blue?.plus40,
        color: palette?.blue?.minus10,
      };
    case 1:
      return {
        text: "Line Run",
        background: palette?.green?.plus55,
        color: palette?.green?.minus5,
      };
    case 2:
      return {
        text: "Maintenance",
        background: palette?.red?.plus45,
        color: palette?.red?.plus5,
      };
    default:
      return {
        text: "Other",
        background: palette?.black?.plus80,
        color: palette?.black?.plus40,
      };
  }
};
