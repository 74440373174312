import React from "react";
import {
  FilterSelector,
  FlexContainer,
  TableControls as TableControlsContainer,
  theme,
} from "@busie/ui-kit";
import { useMediaQuery } from "@mui/material";

interface Props {
  activeView: "bookings" | "quotes";
  filterSlot: React.ReactNode;
  addQuoteSlot: React.ReactNode;
  searchSlot: React.ReactNode;
}
export const TableControls: React.FC<Props> = ({
  activeView,
  filterSlot,
  addQuoteSlot,
  searchSlot,
}) => {
  const isMobile = useMediaQuery("@media (max-width: 960px)");

  return (
    <TableControlsContainer isMobile={isMobile}>
      <FlexContainer
        direction="row"
        justify="flex-start"
        align="center"
        columnGap={2}
        fullWidth
        wrap={isMobile ? "wrap" : "nowrap"}
        sx={{
          [theme.breakpoints.down("tablet")]: {
            padding: "0px",
            gap: "8px",
          },
        }}
        className="filter-controls"
      >
        <FilterSelector
          value={activeView}
          options={[
            { name: "quotes", link: "/quotes-and-bookings/quotes" },
            { name: "bookings", link: "/quotes-and-bookings/bookings" },
          ]}
        />

        {filterSlot}

        {isMobile && addQuoteSlot}

        {searchSlot}
      </FlexContainer>

      {!isMobile && addQuoteSlot}
    </TableControlsContainer>
  );
};
