import React, { useState } from "react";

import { Select, TextInput } from "@busie/ui-kit";
import { QuoteAndBookingStatus, ReasonForDecline } from "@busie/utils";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import { FormValues } from "../model";

interface Props {
  status: string;
  field: ControllerRenderProps<FormValues, "confirm">;
  errorMessage?: string;
}

export const FormInput: React.FC<React.PropsWithChildren<Props>> = ({
  field,
  status,
  errorMessage,
}) => {
  const [isInputReason, setIsInputReason] = useState(false);
  const [selectValue, setSelectValue] = useState(field.value);

  return status !== QuoteAndBookingStatus.DECLINED ? (
    <TextInput
      {...field}
      label="Type `confirm` to continue"
      fullWidth
      errorMessage={errorMessage}
    />
  ) : (
    <>
      <Select
        {...field}
        items={Object.values(ReasonForDecline).map((value) => ({
          value,
          name: value,
        }))}
        value={selectValue}
        onChange={(e) => {
          setSelectValue(e.target.value);
          if (e.target.value === ReasonForDecline.OTHER) {
            setIsInputReason(true);
            field.onChange("");
          } else {
            field.onChange(e.target.value);
            setIsInputReason(false);
          }
        }}
        label="Select a reason for decline"
        fullWidth
      />
      {isInputReason && (
        <TextInput
          {...field}
          type="text"
          label="Enter the reason for decline"
          fullWidth
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
