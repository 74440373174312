import { palette } from "@busie/ui-kit";

export const getStatusColor = (status: string) => {
  switch (status) {
    case "CREATING":
    case "SENDING":
    case "VOIDING":
    case "FINALIZING":
      return palette.black.plus40;
    case "CREATED":
      return palette.purple.main;
    case "FINALIZED":
      return palette.blue.main;
    case "SENT":
      return palette.supernova.main;
    case "ATTEMPTING_PAYMENT":
      return palette.green.main;
    case "PAID":
      return palette.green.minus5;
    case "VOIDED":
      return palette.red.main;
    default:
      return palette.black.main;
  }
};
