import { Invoice } from "@busie/utils";
import { useState, useEffect } from "react";

export const useActiveInvoice = (invoice?: Invoice) => {
  const [activeInvoice, setActiveInvoice] = useState<Invoice | undefined>();

  useEffect(() => {
    if (invoice) return setActiveInvoice(invoice);

    setActiveInvoice(undefined);
  }, [invoice]);

  return activeInvoice;
};

export const useInvoiceList = (invoices: Invoice[]) => {
  const [invoiceList, setInvoiceList] = useState<Invoice[]>([]);

  useEffect(() => {
    if (invoices.length) return setInvoiceList(invoices.slice().reverse());
  }, [invoices]);

  return invoiceList;
};

export const useShowCreatePaymentLink = (invoice?: Invoice) => {
  const [showCreatePaymentLink, setShowCreatePaymentLink] = useState(true);

  useEffect(() => {
    if (invoice && invoice.status !== "VOIDED") setShowCreatePaymentLink(false);
  }, [invoice]);

  return showCreatePaymentLink;
};

export const useIsLoading = (...loadingOperations: boolean[]) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!loadingOperations.every((operation) => !operation))
      return setIsLoading(true);

    setIsLoading(false);
  }, [loadingOperations]);

  return isLoading;
};
