import React from "react";
import { observer } from "mobx-react-lite";

import {
  centsToDollars,
  currencyFormatter,
  metersToKilometers,
  metersToMiles,
} from "@busie/utils";

import EditButton from "./EditButton";
import PropertyString from "../PropertyString";

import FormContainer from "../FormContainer";

import DeparturesForm from "../../store/departuresForm";
import { TripLegsDetails } from "../../../TripLegsDetails";
import { Subtitle } from "@busie/ui-kit";

interface Props {
  onEdit: () => void;
  departuresForm: DeparturesForm;
  price: number;
  useMetricSystem?: boolean;
  // TODO: REFACTOR
  isEmbedded?: boolean;
}
const RouteInformationBlock: React.FC<React.PropsWithChildren<Props>> =
  observer(
    ({
      departuresForm,
      onEdit,
      isEmbedded,
      price,
      useMetricSystem = false,
    }: Props) => {
      const form = departuresForm;
      if (!form.route) return <div />;

      return (
        <FormContainer
          title="Route information"
          controls={
            <EditButton onClick={onEdit} aria-label="edit route information" />
          }
        >
          {form.trip?._id && (
            <>
              <PropertyString name="Trip distance">
                {useMetricSystem
                  ? metersToKilometers(form.trip._meters).toFixed(2)
                  : metersToMiles(form.trip._meters) || 0}{" "}
                {useMetricSystem ? "km" : "mi"}
              </PropertyString>
              <PropertyString name="Total live distance">
                {useMetricSystem
                  ? metersToKilometers(form.trip._liveMeters).toFixed(2)
                  : metersToMiles(form.trip._liveMeters) || 0}{" "}
                {useMetricSystem ? "km" : "mi"}
              </PropertyString>
              <PropertyString name="Total dead head distance">
                {useMetricSystem
                  ? metersToKilometers(form.trip._deadHeadMeters).toFixed(2)
                  : metersToMiles(form.trip._deadHeadMeters) || 0}{" "}
                {useMetricSystem ? "km" : "mi"}
              </PropertyString>
            </>
          )}
          <PropertyString name="Quoted price">
            {!isEmbedded || !form.trip?.hideInstantQuote
              ? currencyFormatter(centsToDollars(Math.ceil(Number(price))))
              : "We cannot provide an instant quote at this time, but we've captured your trip details and will contact you shortly."}
          </PropertyString>

          <Subtitle margin="40px 0 0 ">Legs information</Subtitle>
          {form.trip && (
            <TripLegsDetails trip={form.trip} isEmbedded={isEmbedded} />
          )}
        </FormContainer>
      );
    }
  );
export default RouteInformationBlock;
