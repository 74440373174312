import React from "react";
import { Popover } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  palette,
  ChevronDown,
  ChevronUp,
  FilterSelectorStyled,
} from "@busie/ui-kit";
import { SearchBlockStyled } from "~/vehicles/styled/SearchBlockStyled";

// import ReservationsFilter from "./ReservationsFilter";

interface Props {
  children: (onClose: () => void) => React.ReactNode;
}

// const SearchBlockStyled = styled("div")(() => {
//   return {
//     position: "relative",
//   };
// });

// TODO: [URGENT] FilterBlock is shared in multiple pages. should likely be moved to ui-kit or merged
// with existing FilterBlock in ui-kit if applicable. Otherwise, circular dependencies will arise.
const FilterBlock: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <SearchBlockStyled>
      {!isMobile && (
        <Button
          typestyle="secondary"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          aria-describedby="filtes"
          size="small"
          endIcon={!open ? <ChevronDown size={22} /> : <ChevronUp size={22} />}
          sx={{
            border: `1px solid ${palette.black.plus70} !important`,
            height: "40px",
          }}
        >
          <span>Filter</span>
        </Button>
      )}
      {isMobile && (
        <FilterSelectorStyled
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ height: "40px", padding: "3px 10px !important" }}
        >
          •••
        </FilterSelectorStyled>
      )}

      <Popover
        id="filters"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {children(() => setAnchorEl(null))}
      </Popover>
    </SearchBlockStyled>
  );
};

export default FilterBlock;
