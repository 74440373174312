import { styled } from "@mui/system";

export const TableControlsStyled = styled("div")(({ theme }) => {
  return {
    "&": {
      padding: "0 16px 12px",
      display: "flex",
      flexWrap: "wrap",
      gap: "16px",
      [theme.breakpoints.down("tablet")]: {
        padding: "0px",
        gap: "8px",
      },
    },
  };
});
