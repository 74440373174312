import { Group } from "@busie/utils";

const EVENT_METADATA_KEYS = new Set<keyof Group>([
  "id",
  "organizationId",
  "name",
  "type",
]);

export const getEventMetadata = (group: Group) => ({
  ...Object.keys(group).reduce(
    (acc, key) => ({
      ...(EVENT_METADATA_KEYS.has(key as keyof Group)
        ? { [key]: group[key as keyof Group] }
        : {}),
      ...acc,
    }),
    {}
  ),
});

export const getSuccessMessage = ({ name }: Group): [string, string] => [
  "Success!",
  `Group ${name} was successfully updated.`,
];
