import { Invoice } from "@busie/utils";

export const getInvoiceButtonProps = (
  invoice?: Invoice
): {
  text: string;
  disabled: boolean;
} => {
  if (!invoice)
    return {
      text: "Create Invoice",
      disabled: false,
    };

  switch (invoice.status) {
    case "CREATED":
      return {
        text: "Finalize Invoice",
        disabled: false,
      };
    case "FINALIZED":
      return {
        text: "Send Invoice",
        disabled: false,
      };
    case "SENT":
      return {
        text: "Mark as Paid",
        disabled: false,
      };
    case "VOIDED":
      return {
        text: "Create Invoice",
        disabled: false,
      };
    default:
      return {
        text: "Create Invoice",
        disabled: true,
      };
  }
};
