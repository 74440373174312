import cx from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import {
  Button,
  FlexContainer,
  FormContainer,
  H3,
  TimeIcon,
  palette,
} from "@busie/ui-kit";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { dayjsExt } from "@busie/utils";
import { Dayjs } from "dayjs";
import { BlockContainer } from "~/QuotesAndBookingsPage/shared/ui";
import {
  DeparturesFormData,
  LegData,
  legUpdaterFactory,
  schema,
} from "./model";

interface Props {
  legData: Omit<LegData, "earliestDeparture" | "departure">[];
  onSubmit: (data: DeparturesFormData) => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  legData,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<DeparturesFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      departures: legData.map(() => null),
    },
  });

  const defaultMinimum = dayjsExt().add(1, "day").startOf("day");

  const [legs, setLegs] = useState<LegData[]>(
    legData.map((data) => ({
      ...data,
      earliestDeparture: defaultMinimum,
      departure: defaultMinimum,
    }))
  );

  useEffect(() => reset(), [reset]);

  const { betaMilitaryTime } = useFlags<{ betaMilitaryTime: boolean }>();

  const getTravelTimeDisplay = (ms: number) => {
    return ms < 1000 * 60 * 60
      ? `${Math.ceil(ms / (1000 * 60))} mins`
      : `${(ms / (1000 * 60 * 60)).toFixed(1)} hrs`;
  };

  const handleChange = (
    field: ControllerRenderProps<DeparturesFormData, `departures.${number}`>,
    value: Dayjs,
    changeIndex: number
  ) => {
    field.onChange(value);
    setLegs(legUpdaterFactory(changeIndex, value, defaultMinimum));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormContainer title="Add Departure Dates and Times">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer direction="column" rowGap={3}>
            {legs.map((item, index) => (
              <FlexContainer
                key={`row-${index + 1}`}
                justify="space-evenly"
                align="center"
                fullWidth
              >
                <BlockContainer sx={{ width: "100%" }}>
                  <FlexContainer
                    direction="row"
                    justify="space-between"
                    wrap="wrap"
                    fullWidth
                    align="center"
                    sx={{ marginBottom: "13px" }}
                  >
                    <H3 color={palette.black.plus30}>{`Leg ${index + 1}`}</H3>

                    <FlexContainer direction="row" align="center" columnGap={1}>
                      <TimeIcon />
                      <H3>{getTravelTimeDisplay(item.travelTime)}</H3>
                    </FlexContainer>

                    <FlexContainer>
                      <H3
                        color={
                          item.departure < item.earliestDeparture
                            ? palette.red.plus5
                            : palette.black.plus50
                        }
                      >
                        {`Earliest Departure: ${item.earliestDeparture.format(
                          "L LT"
                        )}`}
                      </H3>
                    </FlexContainer>
                  </FlexContainer>

                  <FlexContainer justify="space-evenly" columnGap={2}>
                    <div
                      className={cx({
                        departures: true,
                        end: false,
                        start: true,
                        last: false,
                        first: true,
                      })}
                      style={{ width: "100%" }}
                    >
                      <div className={cx({ origin: true, start: true })}>
                        {item.start}
                      </div>
                      <div className={cx({ destination: true, end: false })}>
                        {item.destination}
                      </div>
                    </div>

                    <Controller
                      name={`departures.${index}`}
                      control={control}
                      render={({ field }) => (
                        <DateTimePicker
                          {...field}
                          ampm={!betaMilitaryTime}
                          sx={{ width: "100%" }}
                          minDateTime={item.earliestDeparture}
                          onChange={(v) => handleChange(field, v, index)}
                        />
                      )}
                    />
                  </FlexContainer>
                </BlockContainer>
              </FlexContainer>
            ))}
          </FlexContainer>

          <div className="form-submit-control">
            <Button
              typestyle="secondary"
              onClick={onCancel}
              disabled={isSubmitting}
              aria-label="Close the form"
            >
              Cancel
            </Button>
            <Button
              typestyle="primary"
              type="submit"
              aria-label="Continue to the next step of the form"
              disabled={!isDirty || !isValid || isSubmitting}
            >
              Create Trip
            </Button>
          </div>
        </form>
      </FormContainer>
    </LocalizationProvider>
  );
};
