import React from "react";

import {
  Banner,
  FlexContainer,
  FormContainer,
  InfoIcon,
  Loading,
  palette,
} from "@busie/ui-kit";

interface Props {
  isLoading: boolean;
  children: React.ReactNode[];
}

export const Container: React.FC<Props> = ({ isLoading, children }) => {
  return (
    <FlexContainer
      rowGap={1}
      direction="column"
      align="flex-start"
      px={2}
      fullWidth
    >
      <FormContainer title="Route Information">
        <FlexContainer
          rowGap={1}
          direction="column"
          align="flex-start"
          fullWidth
        >
          <Banner
            fontSize="12px"
            backgroundColor={palette.black.plus80}
            text="Updating the following information will cause an automatic re-calculation of price"
            icon={<InfoIcon size={24} color={palette.black.main} />}
          />

          {isLoading ? <Loading size={24} padding="0px" /> : children}
        </FlexContainer>
      </FormContainer>
    </FlexContainer>
  );
};
