import React from "react";

interface Props {
  color: string;
}

export const DuplicateIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7H3C1.89543 7 1 7.89543 1 9V23C1 24.1046 1.89543 25 3 25H17C18.1046 25 19 24.1046 19 23V9C19 7.89543 18.1046 7 17 7Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 7V6C5 4.89543 5.89543 4 7 4H21C22.1046 4 23 4.89543 23 6V20C23 21.1046 22.1046 22 21 22H19"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 4V3C9 1.89543 9.89543 1 11 1H25C26.1046 1 27 1.89543 27 3V17C27 18.1046 26.1046 19 25 19H23"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
