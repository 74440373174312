import { palette } from "@busie/ui-kit";
import { Box } from "@mui/system";
import store from "~/vehicles/store";
import { addDays, startOfWeek } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import HeaderCell from "./HeaderCell";

const Header: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  const vehiclesTotal = store.vehicles.length;
  const dayHeaders: JSX.Element[] = [];
  for (let i = 0; i < 7; i++) {
    const availability = store.availabilityPercentage[i];
    const onClick = () => {
      store.setSelectedCalendarDay(
        addDays(startOfWeek(store.selectedCalendarWeek), i)
      );
      store.setCalendarViewPeriod("day");
    };
    dayHeaders.push(
      <HeaderCell
        i={i}
        selectedWeek={store.selectedCalendarWeek}
        availability={availability}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "73px",
          backgroundColor: palette.black.plus85,
          display: "flex",
          alignItems: "flex-end",
          padding: "13px 16px",
        }}
      >
        Total vehicles ({vehiclesTotal})
      </Box>
      {dayHeaders}
    </>
  );
});

export default Header;
