import { styled } from "@mui/system";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { ContentBlockStyles } from "./ContentBlock";

import { palette } from "@busie/ui-kit";
import { InputLabelDotStyles } from "../../../../styles";

const LastStopBlock = styled("div")(() => {
  return {
    ...ContentBlockStyles,
    maxWidth: "450px",
    borderColor: "transparent",
    [`.${inputLabelClasses.root}`]: {
      overflow: "initial",
      marginTop: "0",
      height: "12px",
      lineHeight: "1",
      "&:before": {
        ...InputLabelDotStyles,
        background: palette?.black?.main,
      },
    },
    ".field-block": {
      "input.pac-target-input": {
        marginBottom: "0",
      },
    },
  };
});

export default LastStopBlock;
