import React from "react";
import { styled } from "@mui/system";
import { FlexContainer } from "@busie/ui-kit";
import busImage from "@busie/ui-kit/assets/icons/bus-large.svg";

const BusImageContainer = styled(FlexContainer)(() => ({
  marginTop: "130px",
}));

const VehiclesBlankState: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <BusImageContainer align="center" justify="center">
      <img src={busImage} alt="" />
    </BusImageContainer>
  );
};

export default VehiclesBlankState;
