import React from "react";
import { styled } from "@mui/system";

import {
  Button,
  Popup,
  FlexContainer,
  TextInput,
  TextInputEvent,
  Title,
} from "@busie/ui-kit";
import RateRuleForm from "~/rates/RateMenuPage/components/CreateRecipeModal/RateRuleForm";
import { RecipeEditData } from "~/rates/RateMenuPage/store/recipeEdit.store";
import { useMediaQuery } from "@mui/material";

interface Props {
  isPopupOpened: boolean;
  isNameEdit: boolean;
  onClose: () => void;
  onUpdate: (
    value: string | Date | number,
    type: "name" | "value" | "type"
  ) => void;
  data: RecipeEditData;
  submitDisabled: boolean;
  onSubmit: () => void;
}

const FormWrapper = styled("div")(() => ({
  width: "100%",
}));

const EditRecipePopup: React.FC<React.PropsWithChildren<Props>> = ({
  isPopupOpened,
  onClose,
  data,
  onUpdate,
  submitDisabled,
  onSubmit,
  isNameEdit,
}: Props) => {
  const isMobile = useMediaQuery("@media (max-width: 800px)");
  return (
    <Popup
      title={<Title>{isNameEdit ? "Rename recipe" : "Recipe rule"}</Title>}
      isPopupOpened={isPopupOpened}
      onClose={onClose}
    >
      <FlexContainer
        w={isMobile ? "auto" : 480}
        px={5}
        pb={5}
        rowGap={3}
        direction="column"
      >
        <FormWrapper>
          {isNameEdit && (
            <TextInput
              required={true}
              label="Recipe name"
              placeholder="e.g. Overtime"
              value={data.name}
              onChange={(e: TextInputEvent) => onUpdate(e.target.value, "name")}
            />
          )}
          {!isNameEdit && (
            <RateRuleForm
              value={data.value as string}
              typeValue={data.type}
              onValueChange={(value) => onUpdate(value, "value")}
              onTypeChange={(type) => onUpdate(type, "type")}
            />
          )}
        </FormWrapper>
        <FlexContainer justify="flex-end" columnGap={2} fullWidth>
          <Button typestyle="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            typestyle="primary"
            async
            onClick={onSubmit}
            disabled={submitDisabled}
          >
            Save
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Popup>
  );
};

export default EditRecipePopup;
