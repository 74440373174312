import React from "react";
import { styled } from "@mui/system";
import { FlexContainer, H3 } from "@busie/ui-kit";
import { Amenity } from "@busie/utils";
import { AmenityIcon } from "@busie/core";

const Container = styled(FlexContainer)(() => ({
  gap: "15px",
  alignItems: "center",
  flexWrap: "wrap",
}));

interface Props {
  amenities: Amenity[];
}

const VehicleAmenitiesRow: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const { amenities = [] } = props;

  if (!props.amenities.length) {
    return <H3 margin="0">No amenities</H3>;
  }

  return (
    <Container>
      {Object.values(Amenity)
        .filter((amen: string | Amenity) => {
          return amenities.includes(amen as Amenity);
        })
        .map((amen: string | Amenity, index: number) => {
          return <AmenityIcon key={index} size={40} type={amen as Amenity} />;
        })}
    </Container>
  );
};

export default VehicleAmenitiesRow;
