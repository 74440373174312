import { useMutation } from "react-query";

import { usePaymentsAuthToken } from "@busie/core";
import { voidInvoice, VoidInvoiceData } from "@busie/api";
import { invalidatePaymentQueries } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/model";

export const useVoidInvoice = () => {
  const paymentsAuthToken = usePaymentsAuthToken();

  const mutation = useMutation(
    async ({
      invoiceId,
      voidInvoiceData,
    }: {
      invoiceId: string;
      voidInvoiceData: VoidInvoiceData;
    }) => {
      const invoice = await voidInvoice(
        paymentsAuthToken,
        invoiceId,
        voidInvoiceData
      );

      invalidatePaymentQueries();

      return invoice;
    }
  );

  return mutation;
};
