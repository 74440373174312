import React, { useEffect, useState } from "react";

import {
  Button,
  FlexContainer,
  FormContainer,
  IconButton,
  PlusIcon,
} from "@busie/ui-kit";
import { CreateCustomer, SelectGroupMember } from "~/CustomersPage/features";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./model";

export interface SelectCustomerFormData {
  id: string;
}

interface Props {
  groupId: string;
  defaultValue: string;
  onSubmit: (data: SelectCustomerFormData) => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  groupId,
  defaultValue,
  onCancel,
  onSubmit,
}) => {
  const [isCreateCustomerOpen, setIsCreateCustomerOpen] = useState(false);

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm<SelectCustomerFormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("id", defaultValue, { shouldDirty: true, shouldValidate: true });
    return () => reset();
  }, [reset, setValue, defaultValue]);

  return (
    <>
      <FormContainer title="Select Trip Main Contact">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer align="center" columnGap={2}>
            <SelectGroupMember
              groupId={groupId}
              onSelect={(profile) => {
                setValue("id", profile.id, {
                  shouldDirty: true,
                });
              }}
              initialValue={defaultValue}
            />
            <IconButton
              icon={<PlusIcon color="black" />}
              style={{ marginTop: "8px" }}
              onClick={() => setIsCreateCustomerOpen(true)}
            />
          </FlexContainer>

          <div className="form-submit-control">
            <Button
              typestyle="secondary"
              onClick={onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              typestyle="primary"
              type="submit"
              disabled={!isDirty || !isValid || isSubmitting}
            >
              Next
            </Button>
          </div>
        </form>
      </FormContainer>

      <CreateCustomer
        visible={isCreateCustomerOpen}
        hide={() => setIsCreateCustomerOpen(false)}
        onCreate={(customer) =>
          setValue("id", customer.id, { shouldDirty: true })
        }
        organizationId={groupId}
      />
    </>
  );
};
