import { useMutation } from "react-query";

import { useQuotesAndBookingsAuthToken } from "@busie/core";
import { VoidedContractInfo } from "@busie/utils";
import { voidContract } from "@busie/api";

interface MutationPayload {
  payload: VoidedContractInfo;
  bookingId: string;
}

export const useVoidContract = () => {
  const qnbAuthToken = useQuotesAndBookingsAuthToken();

  const mutation = useMutation(
    async ({ payload, bookingId }: MutationPayload) => {
      await voidContract(qnbAuthToken, payload, bookingId);
      // TODO: invalidate qnb queries..
    }
  );

  return mutation;
};
