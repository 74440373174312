import {
  Banner as BusieBanner,
  DrawerDetailsBlock,
  SchoolIcon,
  palette,
  theme,
} from "@busie/ui-kit";
import { useMediaQuery } from "@mui/material";
import React from "react";

export const Banner: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  return (
    <DrawerDetailsBlock noHeader padding="16px 18px 8px 18px">
      <BusieBanner
        bordered
        backgroundColor={palette.purple.plus40}
        textColor={palette.black.plus10}
        text="This Trip is Transporting K-12 School Pupils"
        icon={<SchoolIcon size={isMobile ? 24 : 16} />}
      />
    </DrawerDetailsBlock>
  );
};
