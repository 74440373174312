import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ChefQuote } from "@busie/api";
import { useAmplitude } from "@busie/core";
import {
  Button,
  Divider,
  FlexContainer,
  H2,
  H4,
  NumberInput,
  OrganizationMemberWithAvatar,
  ScrollableFlexContainer,
  Select,
  Text,
  ToggleSwitch,
  palette,
  theme,
} from "@busie/ui-kit";
import { notificationStore } from "@busie/features";
import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import { QuoteAndBookingStatus, dollarsToCents } from "@busie/utils";
import { CreateCustomer } from "~/CustomersPage/features";
import {
  Summary,
  Footer,
  MainContact,
  Experiences,
  FlatFees,
  Markups,
  Assignee,
} from "./ui";
import {
  FormValues,
  schema,
  useUpdateQuoteAssignee,
  useUpdateQuoteEnablePlatformPricingMarkup,
  useUpdateQuoteFuelSurcharge,
  useUpdateQuoteLastMinuteSurcharge,
  useUpdateQuoteMainContact,
  useUpdateQuotePrice,
  useUpdateQuoteTaxSurcharge,
} from "./model";
import {
  createDefaultFormValues,
  shouldUpdate,
  successHandlerFactory,
} from "./lib";
import { invalidateQnbQueries } from "~/QuotesAndBookingsPage/model";

interface Props {
  quote: ChefQuote;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onAddTrip: () => void;
}

export const Widget: React.FC<Props> = ({
  isOpen,
  quote,
  onSuccess,
  onClose,
  onAddTrip,
}) => {
  const { track } = useAmplitude();
  const isMobile = useMediaQuery("@media (max-width: 950px)");

  const [isCreateCustomerVisible, setIsCreateCustomerVisible] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: createDefaultFormValues(quote),
  });

  const { mutateAsync: updateMainContact } = useUpdateQuoteMainContact(
    quote._id
  );

  const { mutateAsync: updateQuotePrice } = useUpdateQuotePrice(quote._id);

  const { mutateAsync: updateFuelSurcharge } = useUpdateQuoteFuelSurcharge(
    quote._id
  );

  const { mutateAsync: updateQuoteLastMinuteSurcharge } =
    useUpdateQuoteLastMinuteSurcharge(quote._id);

  const { mutateAsync: updateQuoteTaxSurcharge } = useUpdateQuoteTaxSurcharge(
    quote._id
  );

  const { mutateAsync: updateMarkupPlatformFees } =
    useUpdateQuoteEnablePlatformPricingMarkup(quote._id);

  const { mutateAsync: updateQuoteAssignee } = useUpdateQuoteAssignee(
    quote._id
  );

  useEffect(
    () => reset(createDefaultFormValues(quote)),
    [reset, isOpen, quote]
  );

  const onSubmit = async (data: FormValues) => {
    const {
      MAIN_CONTACT_ID,
      SURCHARGES,
      SUBTOTAL,
      ENABLE_PLATFORM_PRICING_MARKUP,
      ASSIGNEE_ID,
    } = quote;

    const {
      contactId,
      contactName,
      contactEmail,
      fuelSurcharge,
      taxSurcharge,
      lastMinuteSurcharge,
      subtotal,
      markupPlatformFees,
      assigneeId,
    } = data;

    let isErrorState = false;

    const onError = (e: unknown) => {
      notificationStore.setNotificationFromError(e);
      isErrorState = true;
    };

    const successHandler = successHandlerFactory(notificationStore, track);

    const contactData = { contactId, contactEmail, contactName };

    if (shouldUpdate(MAIN_CONTACT_ID, contactId))
      await updateMainContact(contactData, {
        onSuccess: () =>
          successHandler("quote main contact updated", {
            id: quote._id,
            ...contactData,
          }),
        onError,
      });

    if (shouldUpdate(SUBTOTAL, dollarsToCents(subtotal)))
      await updateQuotePrice(dollarsToCents(subtotal), {
        onSuccess: () =>
          successHandler("quote subtotal updated", {
            id: quote._id,
            subtotal: dollarsToCents(subtotal),
          }),
        onError,
      });

    if (shouldUpdate(SURCHARGES.FUEL_SURCHARGE, fuelSurcharge))
      await updateFuelSurcharge(fuelSurcharge, {
        onSuccess: () =>
          successHandler("quote fuel surcharge updated", {
            id: quote._id,
            fuelSurcharge,
          }),
        onError,
      });

    if (shouldUpdate(SURCHARGES.LAST_MINUTE_SURCHARGE, lastMinuteSurcharge))
      await updateQuoteLastMinuteSurcharge(lastMinuteSurcharge, {
        onSuccess: () =>
          successHandler("quote last minute surcharge updated", {
            id: quote._id,
            lastMinuteSurcharge,
          }),
        onError,
      });

    if (shouldUpdate(SURCHARGES.TAX_SURCHARGE, taxSurcharge))
      await updateQuoteTaxSurcharge(data.taxSurcharge, {
        onSuccess: () =>
          successHandler("quote tax surcharge updated", {
            id: quote._id,
            taxSurcharge,
          }),
        onError,
      });

    if (shouldUpdate(ENABLE_PLATFORM_PRICING_MARKUP, markupPlatformFees))
      await updateMarkupPlatformFees(markupPlatformFees, {
        onSuccess: () =>
          successHandler("enable quote platform fees updated", {
            id: quote._id,
            enablePlatformFees: markupPlatformFees,
          }),
        onError,
      });

    const getCleanAssignee = (assigneeId: string) =>
      assigneeId === "UNASSIGNED" ? null : assigneeId;

    if (shouldUpdate(ASSIGNEE_ID, getCleanAssignee(assigneeId)))
      await updateQuoteAssignee(getCleanAssignee(assigneeId), {
        onSuccess: () =>
          successHandler("quote assignee updated", {
            id: quote._id,
            assigneeId: getCleanAssignee(assigneeId) ?? "UNASSIGNED",
          }),
      });

    !isErrorState && invalidateQnbQueries();

    !isErrorState
      ? onSuccess()
      : notificationStore.setErrorNotification(
          "Error",
          "One or more of the updates failed"
        );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onSuccess}>
        <H2>Edit Quote</H2>

        <Divider />

        <form onSubmit={handleSubmit(onSubmit)}>
          <ScrollableFlexContainer
            direction="column"
            rowGap={isMobile ? 2 : 3}
            minW={isMobile ? "375px" : "500px"}
            maxW="740px"
            py={4}
            sx={{
              margin: isMobile ? 0 : "16px auto 0",
              [theme.breakpoints.down("tablet")]: {
                padding: "15px 25px",
              },
            }}
            align="flex-start"
          >
            <Summary
              id={quote._id}
              status={quote.STATUS as QuoteAndBookingStatus}
              experiences={quote.EXPERIENCES}
              bookingRequested={quote.BOOKING_REQUESTED}
              createdBy={quote.CREATED_BY}
              integration={quote.QUOTE_INTEGRATION}
              expirationDate={quote.EXPIRATION_DATE}
            />

            <Controller
              name="assigneeId"
              control={control}
              render={({ field }) => (
                <Assignee
                  assigneeId={field.value}
                  onSelect={(assigneeId) => field.onChange(assigneeId)}
                />
              )}
            />

            <FlexContainer direction="column" columnGap={1} fullWidth>
              <Controller
                name="contactId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <MainContact
                    groupId={quote.CUSTOMER_GROUP_ID}
                    selectedContactId={value}
                    onSelect={(profile) => {
                      onChange(profile.id);
                      setValue("contactEmail", profile.email);
                      setValue("contactName", profile.name);
                    }}
                    onCreate={() => setIsCreateCustomerVisible(true)}
                  />
                )}
              />
              {errors.contactId && (
                <FlexContainer px={2}>
                  <Text fontSize="12px" color={palette.red.main}>
                    Please select a contact from the group
                  </Text>
                </FlexContainer>
              )}
            </FlexContainer>

            <Experiences
              experiences={quote.EXPERIENCES}
              onAddTrip={onAddTrip}
              subtotalInput={
                <Controller
                  name="subtotal"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      {...field}
                      allowNegative={false}
                      errorMessage={errors.subtotal?.message}
                      isAllowed={(val) => {
                        const decimal = val.value.split(".")[1];
                        if (!decimal) return true;
                        return decimal.length <= 2;
                      }}
                      isMoneyField
                      fullWidth
                    />
                  )}
                />
              }
            />

            <FlatFees />

            <Markups
              markups={[
                [
                  <H2 weight={600}>Fuel Surcharge</H2>,
                  <Controller
                    name="fuelSurcharge"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        allowNegative={false}
                        suffix="%"
                        errorMessage={errors.fuelSurcharge?.message}
                        fullWidth
                      />
                    )}
                  />,
                ],
                [
                  <>
                    <H2 weight={600} color={palette.black.plus40}>
                      Driver Gratuity
                    </H2>
                    <H4 color={palette.black.plus40}>Coming Soon!</H4>
                  </>,
                  <NumberInput
                    disabled
                    allowNegative={false}
                    onChange={() => undefined}
                    value={0}
                    suffix="%"
                    fullWidth
                  />,
                ],
                [
                  <H2 weight={600}>Last Minute Surcharge</H2>,
                  <Controller
                    name="lastMinuteSurcharge"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        allowNegative={false}
                        suffix="%"
                        errorMessage={errors.lastMinuteSurcharge?.message}
                        fullWidth
                      />
                    )}
                  />,
                ],
                [
                  <H2 weight={600}>Tax</H2>,
                  <Controller
                    name="taxSurcharge"
                    control={control}
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        allowNegative={false}
                        errorMessage={errors.taxSurcharge?.message}
                        suffix="%"
                        fullWidth
                      />
                    )}
                  />,
                ],
                [
                  <H2 weight={600}>Markup Platform Fees</H2>,
                  <Controller
                    name="markupPlatformFees"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ToggleSwitch
                        checked={value}
                        onChange={(ev) => onChange(ev.target.checked)}
                      />
                    )}
                  />,
                ],
              ]}
            />
          </ScrollableFlexContainer>

          <Divider margin={1} />

          <Footer
            price={quote.PRICE}
            controls={
              <>
                <Button
                  onClick={onClose}
                  typestyle="secondary"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>

                <Button
                  disabled={!isDirty || !isValid || isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                  typestyle="primary"
                >
                  Save
                </Button>
              </>
            }
          />
        </form>
      </Modal>

      <CreateCustomer
        visible={isCreateCustomerVisible}
        hide={() => setIsCreateCustomerVisible(false)}
        onCreate={(customer) => {
          setValue("contactId", customer.id);
          setValue("contactEmail", customer.email as string);
          setValue("contactName", customer.name);
        }}
        organizationId={quote.CUSTOMER_GROUP_ID}
      />
    </>
  );
};
