import {
  createCompanyHoliday,
  deleteCompanyHoliday,
  fetchCompanyHolidays,
  fetchQnBSettings,
  patchCompanyHoliday,
  patchDefaultSignerName,
  patchEmail,
  patchEnablePlatformPricingMarkup,
  patchFuelSurcharge,
  patchLastMinuteSurge,
  patchTaxSurcharge,
  updateEnableAssigneeContractSigner,
} from "@busie/api";
import { queryClient, useQuotesAndBookingsAuthToken } from "@busie/core";
import { QueryKey, useMutation, useQuery } from "react-query";
import { HolidayFormData } from "../ui";

export const QNB_SETTINGS_QUERY_KEY: QueryKey = "QNB_SETTINGS";

const DEFAULT_STALE_TIME = 5 * 60 * 1000; // 5 minutes

const invalidateQueries = () => {
  queryClient.invalidateQueries(QNB_SETTINGS_QUERY_KEY);
};

export const useFetchQnbSettings = (
  id: string,
  onError: (error: unknown) => void = () => undefined
) => {
  const authToken = useQuotesAndBookingsAuthToken();
  return useQuery(
    [QNB_SETTINGS_QUERY_KEY, id],
    () => fetchQnBSettings(authToken, id),
    {
      enabled: !!authToken && !!id,
      staleTime: DEFAULT_STALE_TIME,
      onError,
    }
  );
};

export const useFetchHolidays = (
  id: string,
  onError: (error: unknown) => void = () => undefined
) => {
  const authToken = useQuotesAndBookingsAuthToken();
  return useQuery(
    [QNB_SETTINGS_QUERY_KEY, "HOLIDAYS", id],
    () => fetchCompanyHolidays(authToken, id),
    {
      enabled: !!authToken && !!id,
      staleTime: DEFAULT_STALE_TIME,
      onError,
    }
  );
};

export const useCreateHoliday = (organizationId: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (data: HolidayFormData) => {
    const result = await createCompanyHoliday(token, organizationId, data);

    invalidateQueries();

    return result;
  });
};

export const useUpdateHoliday = (organizationId: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (data: HolidayFormData & { id: string }) => {
    const result = await patchCompanyHoliday(token, organizationId, data);

    invalidateQueries();

    return result;
  });
};

export const useDeleteHoliday = () => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (id: string) => {
    await deleteCompanyHoliday(token, id);

    invalidateQueries();
  });
};

export const useUpdateEmail = (id: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (email: string) => {
    await patchEmail(token, id, email);
    invalidateQueries();
  });
};

export const useUpdateDefaultSignerName = (id: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (name: string) => {
    await patchDefaultSignerName(token, id, name);
    invalidateQueries();
  });
};

export const useUpdateLastMinuteSurge = (id: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (value: number) => {
    await patchLastMinuteSurge(token, id, value);
    invalidateQueries();
  });
};

export const useUpdateFuelSurcharge = (id: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (value: number) => {
    await patchFuelSurcharge(token, id, value);
    invalidateQueries();
  });
};

export const useUpdateTaxSurcharge = (id: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (value: number) => {
    await patchTaxSurcharge(token, id, value);
    invalidateQueries();
  });
};

export const useUpdateEnablePlatformPricingMarkup = (id: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (enabled: boolean) => {
    await patchEnablePlatformPricingMarkup(token, id, enabled);
    invalidateQueries();
  });
};

export const useUpdateEnableAssigneeContractSigner = (id: string) => {
  const token = useQuotesAndBookingsAuthToken();

  return useMutation(async (enabled: boolean) => {
    await updateEnableAssigneeContractSigner(token, id, enabled);
    invalidateQueries();
  });
};

export const useUpdateSettings = (id: string) => {
  const { mutateAsync: updateEmail } = useUpdateEmail(id);

  const { mutateAsync: updateDefaultSignerName } =
    useUpdateDefaultSignerName(id);

  const { mutateAsync: updateLastMinuteSurge } = useUpdateLastMinuteSurge(id);

  const { mutateAsync: updateFuelSurcharge } = useUpdateFuelSurcharge(id);

  const { mutateAsync: updateTaxSurcharge } = useUpdateTaxSurcharge(id);

  const { mutateAsync: updateEnablePlatformPricingMarkup } =
    useUpdateEnablePlatformPricingMarkup(id);

  const { mutateAsync: updateEnableAssigneeContractSigner } =
    useUpdateEnableAssigneeContractSigner(id);

  return {
    updateEmail,
    updateDefaultSignerName,
    updateLastMinuteSurge,
    updateFuelSurcharge,
    updateTaxSurcharge,
    updateEnablePlatformPricingMarkup,
    updateEnableAssigneeContractSigner,
  };
};
