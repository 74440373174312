import React from "react";

import { InfoBlockStyled } from "./styled";
import { useMediaQuery } from "@mui/material";
import { theme, WarningIcon, NoteText } from "@busie/ui-kit";

export const InfoBlock: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  return (
    <InfoBlockStyled>
      <WarningIcon size={isMobile ? "24px" : "13.33px"} />
      <NoteText style={{ fontSize: "12px" }}>
        Updating this information will cause an automatic re-calculation of
        price
      </NoteText>
    </InfoBlockStyled>
  );
};
