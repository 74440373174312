import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import {
  FormLabel,
  RadioButton,
  RadioButtonGroup,
  RadioButtonChangeEvent,
} from "@busie/ui-kit";

import SelectRecipeAssignmentStyled from "~/rates/RateMenuPage/components/CreateRecipeModal/styled/SelectRecipeAssignmentStyled";

import pageStore from "~/rates/RateMenuPage/store";
import { Cookbook, CookbookType } from "@busie/utils";
import { useRatesAuthToken } from "@busie/core";
import { fetchCookbooks } from "@busie/api";
import CookbookTypeSelector from "~/rates/RateMenuPage/components/CreateRecipeModal/CookbookTypeSelector";
import { useAuth0 } from "@auth0/auth0-react";
import { useMenu } from "~/rates/RateMenuPage/hooks";

const SelectRecipeAssignment: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const authToken = useRatesAuthToken();
    const store = pageStore.createRecipeModal;
    const [organizationCookbook, setOrganizationCookbook] =
      React.useState<Cookbook>();

    const { user } = useAuth0();
    const { data: menu } = useMenu(authToken, user);

    useEffect(() => {
      const getCookbooks = async () => {
        if (!authToken || !menu) return;
        const cookbooks = await fetchCookbooks(authToken, {
          type: "ORGANIZATION",
          menuId: menu._id,
        }).then((response) => response.resultSet);

        if (cookbooks.length === 0) {
          throw new Error(
            "This organization does not have any cookbooks and is not fully onboarded."
          );
        }
        setOrganizationCookbook(cookbooks[0]);
        handleSelectCookbook(cookbooks[0].id);
      };

      store.clearCookbookId();

      if (store.formData.cookbookAssignmentType === "ORGANIZATION") {
        organizationCookbook
          ? handleSelectCookbook(organizationCookbook.id)
          : getCookbooks();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, menu, store.formData.cookbookAssignmentType]);

    const handleSelectCookbook = (id: string) => {
      store.setCookbookId(id);
    };

    const handleChangeAssignment = (e: RadioButtonChangeEvent) => {
      const value = e.target.value as CookbookType;
      store.setCookbookAssignmentType(value);
    };

    return (
      <SelectRecipeAssignmentStyled>
        <FormLabel>Assign to cookbook</FormLabel>
        <RadioButtonGroup
          value={store.formData.cookbookAssignmentType}
          name="recipe-assignment"
          onChange={handleChangeAssignment}
        >
          <RadioButton value="CUSTOMER" label="Customer" />
          {store.formData.cookbookAssignmentType === "CUSTOMER" && (
            <CookbookTypeSelector
              type="CUSTOMER"
              onSelect={handleSelectCookbook}
              initialValue={store.formData.cookbookId as string}
            />
          )}

          <RadioButton value="VEHICLE_TYPE" label="Vehicle" />
          {store.formData.cookbookAssignmentType === "VEHICLE_TYPE" && (
            <CookbookTypeSelector
              type="VEHICLE_TYPE"
              onSelect={handleSelectCookbook}
              initialValue={store.formData.cookbookId as string}
            />
          )}

          <RadioButton value="DISPATCH_LOCATION" label="Dispatch Location" />
          {store.formData.cookbookAssignmentType === "DISPATCH_LOCATION" && (
            <CookbookTypeSelector
              type="DISPATCH_LOCATION"
              onSelect={handleSelectCookbook}
              initialValue={store.formData.cookbookId as string}
            />
          )}

          <RadioButton value="ORGANIZATION" label="Organization" />
        </RadioButtonGroup>
      </SelectRecipeAssignmentStyled>
    );
  });

export default SelectRecipeAssignment;
