import { Reservation, VehicleTypes } from "@busie/utils";
import { mapVehicleTypeToName } from "@busie/core";
import React from "react";
import { ReservationDrawerContext } from "~/vehicles/Vehicles/Vehicles";
import {
  getReservationStatusBadgeContents,
  getReservationTypeBadgeContents,
} from "../helpers";
import ReservationBadgeStyled, {
  ReservationStatusIndicatorStyled,
} from "../styled/ReservationBadge.styled";

interface Props {
  reservation: Reservation;
  isPast?: boolean;
  isMobile?: boolean;
  onClick?: () => void;
}

const ReservationBadge: React.FC<React.PropsWithChildren<Props>> = ({
  reservation,
  isPast,
  isMobile,
  onClick,
}) => {
  const setOpenedReservation = React.useContext(ReservationDrawerContext);
  return (
    <ReservationBadgeStyled
      title={`${
        getReservationStatusBadgeContents(reservation.status).text
      } ${getReservationTypeBadgeContents(
        reservation.type
      ).text.toLowerCase()} reservation`}
      type={reservation.type}
      isPast={isPast}
      isMobile={isMobile}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick();
        setOpenedReservation && setOpenedReservation(reservation);
      }}
    >
      <ReservationStatusIndicatorStyled
        status={reservation.status}
        isPast={isPast}
      />
      {mapVehicleTypeToName(
        reservation.vehicle?.vehicleType.type as VehicleTypes
      )}
    </ReservationBadgeStyled>
  );
};

export default ReservationBadge;
