import { styled } from "@mui/system";

import { LoadingBlockProps } from "../Loading";

const LoadingStyled = styled("div")((props: LoadingBlockProps) => {
  return {
    padding: props.padding || "15vh 0",
    textAlign: "center",
  };
});

export default LoadingStyled;
