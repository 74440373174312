import axios from "axios";
import qs from "qs";
import {
  ReservationFormData,
  VehicleFormData,
  VehicleTypeFormData,
} from "../../dataTypes";
import {
  env,
  Vehicle,
  VehicleType,
  Reservation,
  ReservationStatuses,
} from "@busie/utils";
import { PagedResponse } from "@busieinc/types";

export const fetchVehicleTypes = async (
  authToken: string
): Promise<VehicleType[]> => {
  const { data } = await axios.get(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/vehicle-types`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data as VehicleType[];
};

export const createVehicleType = async (
  authToken: string,
  formData: VehicleTypeFormData
): Promise<{ [key: string]: string }> => {
  const { data } = await axios.post(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/vehicle-types`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const updateVehicle = async (
  authToken: string,
  vehicleId: string,
  formData: VehicleFormData | null
): Promise<boolean> => {
  const response = await axios.put(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/vehicles/${vehicleId}`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return response.status === 200;
};

export const updateVehicleType = async (
  authToken: string,
  vehicleTypeId: string,
  formData: VehicleTypeFormData
): Promise<boolean> => {
  const response = await axios.put(
    `${env(
      "BUSIE_VEHICLE_SERVICE_API_URL"
    )}/vnr/vehicle-types/${vehicleTypeId}`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return response.status === 200;
};

export const createVehicle = async (
  authToken: string,
  formData: VehicleFormData
): Promise<string> => {
  const { data } = await axios.post(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/vehicles`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const fetchVehicle = async (
  authToken: string,
  vehicleId: string
): Promise<Vehicle> => {
  const { data } = await axios.get(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/vehicles/${vehicleId}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const fetchVehicles = async (
  authToken: string,
  page?: number,
  itemsPerPage?: number,
  filters?: Record<string, unknown>
): Promise<PagedResponse<Vehicle>> => {
  const queryParams = qs.stringify(
    {
      take: itemsPerPage,
      skip: itemsPerPage && page && (page - 1) * itemsPerPage,
      ...filters,
    },
    { addQueryPrefix: true, arrayFormat: "repeat" }
  );
  const { data } = await axios.get(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/vehicles${queryParams}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const deleteReservation = async (
  authToken: string,
  id: string
): Promise<void> => {
  await axios.delete(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/reservations/${id}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
};

export const fetchReservations = async (
  authToken: string,
  params?: {
    vehicleTypeId?: string;
    status?: string;
    startAt?: Date;
    endAt?: Date;
  }
): Promise<Reservation[]> => {
  const formattedParams = params
    ? {
        "vehicle-type-id": params.vehicleTypeId,
        status: params.status,
        "start-at": params.startAt?.toISOString(),
        "end-at": params.endAt?.toISOString(),
      }
    : {};
  const queryParams = qs.stringify(formattedParams);
  const { data } = await axios.get(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/reservations?${queryParams}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const fetchReservation = async (
  authToken: string,
  id: string
): Promise<Reservation> => {
  const { data } = await axios.get(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/reservations/${id}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const createReservation = async (
  authToken: string,
  formData: ReservationFormData
): Promise<Reservation> => {
  const { data } = await axios.post(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/reservations`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const editReservation = async (
  authToken: string,
  id: string,
  startAt: string,
  endAt: string,
  type: number,
  vehicleId: string,
  description: string,
  driverId?: string,
  status?: ReservationStatuses
): Promise<Reservation> => {
  const { data } = await axios.put(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/reservations/${id}`,
    {
      startAt,
      endAt,
      type,
      vehicleId,
      description,
      driverId,
      status,
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const deleteVehicleType = async (
  authToken: string,
  vehicleTypeId: string
): Promise<boolean> => {
  const response = await axios.delete(
    `${env(
      "BUSIE_VEHICLE_SERVICE_API_URL"
    )}/vnr/vehicle-types/${vehicleTypeId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  return response.status === 200;
};

export const deleteVehicle = async (
  authToken: string,
  vehicleId: string
): Promise<boolean> => {
  const response = await axios.delete(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/vehicles/${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return response.status === 200;
};

export const getAvailabilityPercentage = async (
  authToken: string,
  startAt: Date,
  endAt: Date,
  interval: number
): Promise<number[]> => {
  const { data } = await axios.get(
    `${env("BUSIE_VEHICLE_SERVICE_API_URL")}/vnr/availability-percentage`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        "start-at": startAt,
        "end-at": endAt,
        interval,
      },
    }
  );
  return data;
};

export const getReservationComments = async (
  authToken: string,
  reservationId: string
) => {
  const { data } = await axios.get(
    `${env(
      "BUSIE_VEHICLE_SERVICE_API_URL"
    )}/vnr/reservations/${reservationId}/comments`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const createReservationComment = async (
  authToken: string,
  reservationId: string,
  body: string
) => {
  const { data } = await axios.post(
    `${env(
      "BUSIE_VEHICLE_SERVICE_API_URL"
    )}/vnr/reservations/${reservationId}/comments`,
    { body },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};
