import React from "react";

interface Props {
  color?: string;
  size?: number;
}

export const CloseIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = "currentColor",
  size = 24,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.63565 5.63601C5.24515 6.02651 5.24515 6.65973 5.63565 7.05023L10.5854 12L5.63565 16.9497C5.24515 17.3402 5.24515 17.9734 5.63565 18.3639C6.02614 18.7544 6.65936 18.7544 7.04986 18.3639L11.9996 13.4142L16.9494 18.3639C17.3399 18.7544 17.9731 18.7544 18.3636 18.3639C18.7541 17.9734 18.7541 17.3402 18.3636 16.9497L13.4138 12L18.3636 7.05023C18.7541 6.65973 18.7541 6.02651 18.3636 5.63601C17.9731 5.24552 17.3399 5.24552 16.9494 5.63601L11.9996 10.5858L7.04986 5.63601C6.65936 5.24552 6.02614 5.24552 5.63565 5.63601Z"
        fill={color}
      />
    </svg>
  );
};
