import React from "react";
import { useForm } from "react-hook-form";

import { Experience } from "@busie/api";
import {
  Button,
  Divider,
  FlexContainer,
  FormContainer,
  H1,
  NoteText,
  ScrollableFlexContainer,
  Text,
  theme,
} from "@busie/ui-kit";
import { useMediaQuery } from "@mui/material";
import { notificationStore } from "@busie/features";
import { useAmplitude } from "@busie/core";

import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import {
  LegContainer,
  useCreateTrip,
  useUpdateTrip,
} from "~/QuotesAndBookingsPage/entity";
import { invalidateQnbQueries } from "~/QuotesAndBookingsPage/model";

import { FormValues } from "./model";
import { splitTrip } from "./lib";

interface Props {
  isOpen: boolean;
  trip: Experience;
  splitIndex: number;
  onClose: () => void;
  onSuccess: () => void;
}

export const Split: React.FC<Props> = ({
  isOpen,
  trip,
  splitIndex,
  onClose,
  onSuccess,
}) => {
  /**
   * TODO: [BUSIE-1735] Visually separate the two trips into tabs
   * TODO: [BUSIE-1736] Include a map, either to the right of the route display, or underneath it.
   * TODO: [BUSIE-1737] Fetch the dispatch location from the API and display its name instead of the generic "Dispatch"
   * TODO: [BUSIE-1739] Allow user to specify if they want to create a new quote for the new trip.
   * TODO: [BUSIE-1740] Allow user to change/add stops to either trip.
   */
  const isMobile = useMediaQuery("@media (max-width: 950px)");

  const [updateTripPayload, createTripPayload] = splitTrip(trip, splitIndex);

  const { mutateAsync: updateTrip } = useUpdateTrip(trip._ID);
  const { mutateAsync: createTrip } = useCreateTrip();

  const { track } = useAmplitude();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      updateTripPayload,
      createTripPayload,
      createNewQuote: false,
    },
  });

  const onSubmit = async (data: FormValues) => {
    const { updateTripPayload, createTripPayload, createNewQuote } = data;

    const updatedTrip = await updateTrip(updateTripPayload, {
      onError: (e) => notificationStore.setNotificationFromError(e),
    });

    const newTrip = await createTrip(
      {
        ...createTripPayload,
        quoteId: createNewQuote ? undefined : trip.QUOTE_ID,
      },
      {
        onError: (e) => notificationStore.setNotificationFromError(e),
      }
    );

    notificationStore.setNotification({
      type: "success",
      header: "Success!",
      message: "Trip split successfully",
    });

    invalidateQnbQueries();

    track("trip split", {
      quoteId: trip.QUOTE_ID,
      newTripId: newTrip._id,
      updatedTripId: updatedTrip._id,
      newQuoteCreated: createNewQuote,
    });

    onSuccess();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <H1>Split Trip</H1>

      <Text>Are you sure you want to split this trip?</Text>

      <NoteText>
        This will update the existing trip and create a new trip as follows:
      </NoteText>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollableFlexContainer
          direction="column"
          rowGap={isMobile ? 2 : 3}
          minW={isMobile ? "375px" : "500px"}
          maxW="740px"
          py={4}
          sx={{
            margin: isMobile ? 0 : "16px auto 0",
            [theme.breakpoints.down("tablet")]: {
              padding: "15px 25px",
            },
          }}
          align="flex-start"
        >
          <FlexContainer direction="column" rowGap={0} px={2} fullWidth>
            <FormContainer title="Trip 1">
              <FlexContainer direction="column" rowGap={0}>
                {updateTripPayload.legs.map((leg, index, arr) => (
                  <LegContainer
                    key={`first-trip-${index}`}
                    title={`Leg ${index + 1}`}
                    departureDatetime={leg.departureDatetime}
                    arrivalDatetime={leg.arrivalDatetime as string}
                    addresses={[
                      index === 0
                        ? "Dispatch"
                        : (leg.startLocation.address as string),
                      index === arr.length - 1
                        ? "Dispatch"
                        : (leg.destinationLocation.address as string),
                    ]}
                  />
                ))}
              </FlexContainer>
            </FormContainer>

            <Divider />

            <FormContainer title="Trip 2">
              <FlexContainer direction="column" rowGap={0}>
                <LegContainer
                  title="Leg 1"
                  departureDatetime=""
                  arrivalDatetime=""
                  addresses={[
                    "Dispatch",
                    createTripPayload.legs[0].startLocation.address as string,
                  ]}
                />

                {createTripPayload.legs.map((leg, index, arr) => (
                  <LegContainer
                    key={`second-trip-${index}`}
                    title={`Leg ${index + 2}`}
                    departureDatetime={leg.departureDatetime}
                    arrivalDatetime={leg.arrivalDatetime as string}
                    addresses={[
                      leg.startLocation.address as string,
                      leg.destinationLocation.address as string,
                    ]}
                  />
                ))}

                <LegContainer
                  title={`Leg ${createTripPayload.legs.length + 2}`}
                  departureDatetime=""
                  arrivalDatetime=""
                  addresses={[
                    createTripPayload.legs[createTripPayload.legs.length - 1]
                      .destinationLocation.address as string,
                    "Dispatch",
                  ]}
                />
              </FlexContainer>
            </FormContainer>
          </FlexContainer>
        </ScrollableFlexContainer>

        <FlexContainer direction="row" columnGap={2} pt={1} justify="flex-end">
          <Button
            typestyle="secondary"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
            Split
          </Button>
        </FlexContainer>
      </form>
    </Modal>
  );
};
