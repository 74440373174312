import React, { useEffect, useState } from "react";

import { useReactToPrint } from "react-to-print";
import {
  Drawer as BusieDrawer,
  DrawerHeader,
  Loading,
  IconButton,
  EditIcon,
  DrawerDetailsBlock,
  FlexContainer,
  Button,
  palette,
  DuplicateIcon,
  ErrorBoundary,
} from "@busie/ui-kit";
import { Experience } from "@busie/api";
import {
  Reservation,
  Vehicle,
  dayjsExt,
  metersToKilometers,
  metersToMiles,
} from "@busie/utils";
import { getTripType, propertyTuple } from "~/QuotesAndBookingsPage/shared/lib";
import { DrawerObjectDetails } from "~/QuotesAndBookingsPage/shared/ui";

import {
  useExperienceDispatchLocation,
  useExperienceReservations,
  useTripRoute,
} from "~/QuotesAndBookingsPage/model";
import { CustomerContact } from "~/QuotesAndBookingsPage/entity";

import {
  TripType,
  TripPrice,
  TripPriceBreakdown,
  AdditionalInfo,
  Reservations,
  Legs,
  SPABBanner,
  ServiceTime,
  ServiceTimeBreakdown,
} from "./ui";
import { useHistory } from "react-router";
import { DATE_CUSTOM_FORMAT } from "./model";
import { TripRoute } from "./ui/TripRoute";
import { DuplicateTrip } from "~/QuotesAndBookingsPage/features";
import { useAmplitude } from "@busie/core";
import { useFlags } from "launchdarkly-react-client-sdk";

interface Props {
  trip: Experience;
  isOpen: boolean;
  onClose: () => void;
  onEditTrip: () => void;
}

export const Drawer: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  isOpen,
  onClose,
  onEditTrip,
}) => {
  const { useMetricSystem } = useFlags<{ useMetricSystem: boolean }>();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [showTripPriceBreakdown, setShowTripPriceBreakdown] = useState(false);
  const [showServiceBreakdown, setShowServiceBreakdown] = useState(false);
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false);

  const reservations = useExperienceReservations(trip.RESERVATION_IDS);
  const { data: dispatchLocation, isLoading: isDispatchLocationLoading } =
    useExperienceDispatchLocation(trip.DISPATCH_ID);

  const firstLeg = trip.LEGS[0];
  const lastLeg = trip.LEGS[trip.LEGS.length - 1];

  const pickup = firstLeg.DESTINATION_LOCATION;
  const destination = lastLeg.START_LOCATION;

  const tripType = getTripType(
    {
      _id: pickup._ID,
      id: pickup._ID,
      latitude: pickup.LATITUDE,
      longitude: pickup.LONGITUDE,
      address: pickup.ADDRESS,
    },
    {
      _id: destination._ID,
      id: destination._ID,
      latitude: destination.LATITUDE,
      longitude: destination.LONGITUDE,
      address: destination.ADDRESS,
    }
  );

  const { data: route, isLoading: isRouteLoading } = useTripRoute({
    vehicleType: firstLeg.MAIN_VEHICLE_TYPE || "MOTOR_COACH",
    start: `${firstLeg.START_LOCATION.LATITUDE},${firstLeg.START_LOCATION.LONGITUDE}`,
    end: `${lastLeg.DESTINATION_LOCATION.LATITUDE},${lastLeg.DESTINATION_LOCATION.LONGITUDE}`,
    waypoints: trip.LEGS.slice(0, -1).map(
      ({ DESTINATION_LOCATION: { LATITUDE, LONGITUDE } }) =>
        `${LATITUDE},${LONGITUDE}`
    ),
    routeType: tripType.toUpperCase() as "ONE_WAY" | "ROUND_TRIP",
  });

  const primaryProperties: propertyTuple[] = [
    ["ID", trip._ID],
    [
      "Trip Subtotal",
      reservations.some(({ isLoading }) => !!isLoading) ? (
        <Loading size={24} padding="0px" />
      ) : (
        <TripPrice
          trip={trip}
          onClick={() => setShowTripPriceBreakdown(true)}
        />
      ),
    ],
  ];

  const unit = useMetricSystem ? "km" : "mi";
  const totalDistance = useMetricSystem
    ? metersToKilometers(trip.METERS).toFixed(2)
    : metersToMiles(trip.METERS);

  const liveDistance = useMetricSystem
    ? metersToKilometers(trip.LIVE_METERS).toFixed(2)
    : metersToMiles(trip.LIVE_METERS);

  const deadHeadDistance = useMetricSystem
    ? metersToKilometers(trip.DEAD_HEAD_METERS).toFixed(2)
    : metersToMiles(trip.DEAD_HEAD_METERS);

  const secondaryProperties: propertyTuple[] = [
    ["Start Date", dayjsExt(trip.START_DATE).format(DATE_CUSTOM_FORMAT)],
    ["End Date", dayjsExt(trip.END_DATE).format(DATE_CUSTOM_FORMAT)],
    ["Passengers", trip.PASSENGERS],
    [
      "Service Time",
      <ServiceTime trip={trip} onClick={() => setShowServiceBreakdown(true)} />,
    ],
    ["Total Distance", `${totalDistance} ${unit}`],
    ["Total Live Miles", `${liveDistance} ${unit}`],
    ["Total Dead Head Miles", `${deadHeadDistance} ${unit}`],
  ];

  const componentRef = React.useRef(null);
  const printingRef = React.useRef(false);
  // counter state will force re-render, causing the below useEffect to run
  const [counter, setCounter] = useState(0);
  const handlePrint = () => {
    printingRef.current = true;
    setCounter((ct) => ct + 1);
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Trip Details",
  });

  useEffect(() => {
    if (printingRef.current) {
      printingRef.current = false;
      print();
    }
  }, [printingRef, print]);

  useEffect(() => {
    if (
      reservations.some(({ isLoading }) => !!isLoading) ||
      isDispatchLocationLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isDispatchLocationLoading, reservations]);

  const { track } = useAmplitude();

  useEffect(() => {
    track("trip details viewed", { tripId: trip._ID });
  });

  return (
    <BusieDrawer anchor="left" open={isOpen} onClose={onClose}>
      <ErrorBoundary
        errorText="Something went wrong. Try re-opening the drawer or refreshing the page. If the problem persists, contact us at support@getbusie.com"
        logError={(error) =>
          track("unhandled trip details drawer error", {
            errorMessage: error.message,
            stackTrace: error.stack,
            name: error.name,
          })
        }
      >
        <DrawerHeader title="Trip Details" onClose={onClose}>
          <IconButton
            icon={<EditIcon color={palette.black.plus100} />}
            highlightOnHover={false}
            onClick={onEditTrip}
          />
          <IconButton
            highlightOnHover={false}
            icon={<DuplicateIcon color={palette.black.plus100} />}
            onClick={() => setIsDuplicateOpen(true)}
          />
        </DrawerHeader>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="trip-details-wrapper" ref={componentRef}>
            <TripType tripType={tripType} />
            {trip.SPAB ? <SPABBanner /> : null}
            {/* TODO: <StatusBlock /> -- right now trip statuses don't change so it doesn't make sense to take the time to display this info right now. */}
            <DrawerObjectDetails
              name="Trip Info"
              primaryProperties={primaryProperties}
              secondaryProperties={secondaryProperties}
            />

            <CustomerContact
              title="Main Contact"
              customer={{
                ...trip.MAIN_CONTACT,
                GROUP: trip.GROUP,
              }}
            />

            <AdditionalInfo trip={trip} />

            <Reservations
              isLoading={reservations.some(({ isLoading }) => !!isLoading)}
              reservations={
                reservations.some(({ isLoading }) => !!isLoading)
                  ? []
                  : reservations.map(({ data }) => data as Reservation)
              }
              onClick={(id) =>
                history.push(`/reservations?reservationId=${id}`)
              }
            />

            <Legs
              legs={trip.LEGS}
              dispatchLocation={dispatchLocation}
              isLoading={isDispatchLocationLoading}
            />

            <TripRoute
              route={route}
              isLoading={isRouteLoading}
              legs={trip.LEGS}
            />

            <CustomerContact
              title="Trip Planner"
              customer={{
                ...trip.TRIP_PLANNER,
                GROUP: trip.GROUP,
              }}
            />

            <DrawerDetailsBlock
              name="Additional Actions"
              collapsible
              className="no-print"
            >
              <FlexContainer
                fullWidth
                columnGap={2}
                align="center"
                justify="space-between"
                sx={{ marginBottom: "52px" }}
              >
                <Button typestyle="accent" onClick={handlePrint}>
                  Print
                </Button>
              </FlexContainer>
            </DrawerDetailsBlock>
          </div>
        )}

        <TripPriceBreakdown
          trip={trip}
          vehicles={
            reservations.some(({ isLoading }) => !!isLoading) ||
            reservations.some(({ data }) => !data?.vehicle)
              ? []
              : reservations.map(
                  ({ data: reservation }) => reservation?.vehicle as Vehicle
                )
          }
          isOpen={showTripPriceBreakdown}
          onClose={() => setShowTripPriceBreakdown(false)}
          onClickRecipe={(recipeId: string) =>
            history.push(`/rates?recipeId=${recipeId}`)
          }
        />

        <ServiceTimeBreakdown
          trip={trip}
          onClose={() => setShowServiceBreakdown(false)}
          isOpen={showServiceBreakdown}
        />

        <DuplicateTrip
          trip={trip}
          isOpen={isDuplicateOpen}
          onClose={() => setIsDuplicateOpen(false)}
          onSuccess={() => setIsDuplicateOpen(false)}
        />
      </ErrorBoundary>
    </BusieDrawer>
  );
};
