import { SxProps, Theme } from "@mui/system";
import React from "react";

import MenuHeaderStyled from "./styled/MenuHeaderStyled";

interface Props {
  children: React.ReactNode;
  isFullWidth?: boolean;
  outlined?: boolean;
  sx?: SxProps<Theme>;
}

const MenuHeader: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  return <MenuHeaderStyled sx={props.sx} {...props} />;
};

export default MenuHeader;
