import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";

import {
  Loading,
  TableContainer,
  TableControls,
  FlexContainer,
  theme,
  TablePagination,
  SearchInput,
  Button,
  PlusIcon,
  palette,
  IconButton,
  AddIcon,
} from "@busie/ui-kit";

import {
  ListContractsQuery,
  SortContractsQuery,
  useListChefContracts,
} from "~/contracts/entity";

import { NoContent, BaseTable, Filter } from "./ui";
import {
  DEFAULT_FILTER,
  DEFAULT_SORT,
  ITEMS_PER_PAGE,
  columnNames,
} from "./model";
import { getTableData } from "./lib";
import { useDebouncedCallback } from "@busie/core";
import { getTableOrder } from "./lib/get-table-order";

export const Table: React.FC = () => {
  const isMobile = useMediaQuery("@media (max-width: 960px)");
  const isTablet = useMediaQuery("@media (max-width: 1136px)");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<ListContractsQuery>(DEFAULT_FILTER);
  const [sort, setSort] = useState<SortContractsQuery>(DEFAULT_SORT);

  const { data: contracts, isLoading } = useListChefContracts(page, sort, {
    ...filter,
    search,
  });

  const debouncedSearch = useDebouncedCallback(
    (search: string) => setSearch(search),
    700
  );

  const onSort = (id: string) =>
    setSort((curr) => ({
      [id]: curr[id] ? (curr[id] === "desc" && "asc") || "desc" : "desc",
    }));

  const onRowClick = () => alert("on row click");

  return (
    <TableContainer isMobile={isMobile} sx={{ marginTop: "16px" }}>
      <TableControls isMobile={isMobile}>
        <FlexContainer
          direction="row"
          justify="flex-start"
          align="center"
          columnGap={2}
          fullWidth
          wrap={isMobile ? "wrap" : "nowrap"}
          sx={{
            [theme.breakpoints.down("tablet")]: {
              padding: "0px",
              gap: "8px",
            },
          }}
          className="filter-controls"
        >
          <Filter
            defaultFilter={filter}
            isMobile={isMobile}
            isTablet={isTablet}
            onChange={(changed) =>
              setFilter((current) => ({ ...current, ...changed }))
            }
          />

          {isMobile && (
            <IconButton
              icon={<AddIcon color={palette.black.main} />}
              highlightOnHover={true}
              style={{
                backgroundColor: palette.black.plus100,
                border: `1px solid ${palette.black.plus70}`,
                borderRadius: "6px",
              }}
              onClick={() => alert("on add contract")}
            />
          )}

          <SearchInput
            placeholder="Find the needle in the haystack"
            search={debouncedSearch}
            fullWidth
          />
        </FlexContainer>
        {!isMobile && (
          <FlexContainer
            direction="row"
            justify="flex-end"
            align="center"
            fullWidth
          >
            <Button
              typestyle="primary"
              onClick={() => alert("on add contract")}
              startIcon={<PlusIcon color={palette.black.plus100} />}
            >
              Add new Contract
            </Button>
          </FlexContainer>
        )}
      </TableControls>

      <div className="table-container">
        <BaseTable
          isMobile={isMobile}
          columns={columnNames}
          data={getTableData(contracts.data, isMobile || isTablet)}
          order={getTableOrder(sort)}
          onRowClick={onRowClick}
          onSort={onSort}
        />

        {!contracts.total &&
          (isLoading ? (
            <Loading />
          ) : (
            <NoContent
              onAddNewContract={() => alert("on add new contract")}
              onClearFilter={
                filter === DEFAULT_FILTER
                  ? undefined
                  : () => setFilter(DEFAULT_FILTER)
              }
            />
          ))}
      </div>

      <TablePagination
        outlined
        itemsTotal={contracts.total}
        pageNumber={page}
        perPage={ITEMS_PER_PAGE}
        currentOffset={ITEMS_PER_PAGE * (page - 1)}
        nextOffset={Math.min(page * ITEMS_PER_PAGE, contracts.total)}
        onNextClick={() => setPage((page) => page + 1)}
        onPrevClick={() => page > 1 && setPage((page) => page - 1)}
        onToEndClick={() =>
          setPage(Math.ceil(contracts.total / ITEMS_PER_PAGE))
        }
        onToBeginningClick={() => setPage(1)}
      />
    </TableContainer>
  );
};
