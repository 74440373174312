import { Reservation, Vehicle } from "@busie/utils";
import { palette, Popup } from "@busie/ui-kit";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import {
  addMinutes,
  differenceInMinutes,
  endOfDay,
  endOfHour,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  startOfDay,
  startOfHour,
} from "date-fns";
import React from "react";
import ReservationBar from "./ReservationBar";
import ReservationForm from "~/vehicles/Vehicles/components/ReservationForm";
import { observer } from "mobx-react";
import store from "~/vehicles/store";

const DayCellStyled = styled(Box)(
  ({
    date,
    isHoverable,
    isHovered,
    isDayView,
    percentagePassed,
  }: {
    date?: Date;
    isHoverable?: boolean;
    isHovered?: boolean;
    isDayView: boolean;
    percentagePassed: number;
  }) => {
    if (!date) {
      return {
        backgroundColor: palette.black.plus100,
        height: "48px",
      };
    }
    const isCurrentDate = isDayView
      ? isEqual(date, startOfHour(new Date()))
      : isToday(date);
    return {
      backgroundColor: palette.black.plus100,
      height: "48px",
      position: "relative",
      border: isHovered
        ? `1px ${isHoverable ? palette?.black?.plus60 : "transparent"} solid`
        : "1px transparent solid",
      cursor: isHoverable ? "pointer" : "auto",

      "&:after": isCurrentDate
        ? {
            content: "''",
            height: "calc(100% + 2px)",
            width: "1px",
            backgroundColor: palette.black.plus60,
            borderRadius: "100px",
            position: "absolute",
            top: "-1px",
            left: `calc(${
              (percentagePassed * 100) / (isDayView ? 60 : 1440)
            }% - 0.5px)`,
            zIndex: 300,
          }
        : {},
    };
  }
);

interface Props {
  date: Date;
  reservations?: Reservation[];
  reservationsForDay?: Reservation[];
  vehicle?: Vehicle;
  isHoverable?: boolean;
}

const MediumCalendarCell: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    date,
    reservations: unsortedResrvation,
    reservationsForDay: unsortedReservationsForDay,
    vehicle,
    isHoverable = true,
  }) => {
    const reservations = unsortedResrvation
      ?.map((res) => ({
        ...res,
        startAt: new Date(res.startAt),
        endAt: new Date(res.endAt),
      }))
      .sort((a, b) => (isAfter(a.startAt, b.startAt) ? 1 : -1));
    const reservationsForDay = unsortedReservationsForDay
      ?.map((res) => ({
        ...res,
        startAt: new Date(res.startAt),
        endAt: new Date(res.endAt),
      }))
      .sort((a, b) => (isAfter(a.startAt, b.startAt) ? 1 : -1));
    const [isHovered, setIsHovered] = React.useState(false);
    const [isFormOpen, setIsFormOpen] = React.useState(false);
    const isDayView = store.calendarViewPeriod === "day";
    const getStart = isDayView ? startOfHour : startOfDay;
    const getEnd = isDayView ? endOfHour : endOfDay;
    const [percentagePassed, setPercentagePassed] = React.useState(
      differenceInMinutes(new Date(), getStart(date))
    );

    React.useEffect(() => {
      const interval = setInterval(() => {
        // update the progress line every minute
        setPercentagePassed(differenceInMinutes(new Date(), getStart(date)));
      }, 60000);

      return () => clearInterval(interval);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFull = React.useMemo(() => {
      if (reservations) {
        if (reservations.length === 1) {
          if (
            isBefore(reservations[0].startAt, getStart(date)) &&
            isAfter(reservations[0].endAt, getEnd(date))
          ) {
            return true;
          } else {
            return false;
          }
        } else if (reservations.length > 1) {
          for (let i = 1; i < reservations.length; i++) {
            if (
              differenceInMinutes(
                reservations[i].startAt,
                reservations[i - 1].endAt
              ) > 15
            ) {
              return false;
            }
          }
          return true;
        }
      }
      return false;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservations]);

    const isInteractable = isHoverable ? !isFull : false;

    const onFormOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (isInteractable) {
        let gapInTimeStart: Date = getStart(date);
        let gapInTimeEnd: Date = endOfDay(date);
        if (reservationsForDay && reservationsForDay.length > 0) {
          if (isAfter(reservationsForDay[0].startAt, startOfDay(date))) {
            gapInTimeEnd = addMinutes(reservationsForDay[0].startAt, -1);
          } else {
            if (!isDayView && reservationsForDay.length === 1) {
              gapInTimeStart = addMinutes(reservationsForDay[0].endAt, 1);
            } else {
              for (let i = 1; i < reservationsForDay.length; i++) {
                if (
                  differenceInMinutes(
                    reservationsForDay[i].startAt,
                    reservationsForDay[i - 1].endAt
                  ) > 15
                ) {
                  gapInTimeStart = addMinutes(
                    reservationsForDay[i - 1].endAt,
                    1
                  );
                  gapInTimeEnd = addMinutes(reservationsForDay[i].startAt, -1);
                  break;
                }
              }
            }
          }
        }
        store.setReservationDate("startAt", date);
        store.setReservationDate("endAt", date);
        store.setReservationTime("startAt", gapInTimeStart);
        store.setReservationTime("endAt", gapInTimeEnd);
        if (vehicle) {
          store.openReservationPopupWithVehicles([vehicle]);
        }
      }
    };

    if (!unsortedResrvation) {
      return (
        <DayCellStyled
          date={date}
          isHoverable={false}
          isDayView={isDayView}
          percentagePassed={percentagePassed}
        />
      );
    }
    return (
      <>
        <DayCellStyled
          percentagePassed={percentagePassed}
          isDayView={isDayView}
          date={date}
          isHoverable={isInteractable}
          isHovered={isHovered}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
          onClick={onFormOpen}
        >
          {unsortedResrvation.map((res) => (
            <ReservationBar
              key={res.id}
              reservation={res}
              date={date}
              isDayView={isDayView}
            />
          ))}
        </DayCellStyled>
        <Popup
          isPopupOpened={isFormOpen}
          title="Vehicle reservation"
          onClose={() => setIsFormOpen(false)}
        >
          <ReservationForm isFixedHeight={false} />
        </Popup>
      </>
    );
  }
);

export default MediumCalendarCell;
