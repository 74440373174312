import { WarningPopup } from "@busie/ui-kit";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const Modal: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => (
  <WarningPopup
    title="Delete holiday?"
    isPopupOpened={isOpen}
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText="Delete"
  >
    Are you sure you want to delete this holiday?
  </WarningPopup>
);
