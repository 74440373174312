import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm, Controller } from "react-hook-form";

import { FormContainer } from "@busie/ui-kit";
import { Button, DatePicker, Modal, Select, TextInput } from "@busie/ui-kit";
import { CompanyHolidayRepeat, dayjsExt } from "@busie/utils";

export interface FormData {
  name: string;
  holidayRangeFrom: any;
  holidayRangeTo: any;
  repeat: CompanyHolidayRepeat;
}

interface Props {
  type: "create" | "edit";
  holiday: FormData;
  isOpen: boolean;
  onSubmit: (data: FormData) => void;
  onClose: () => void;
}

export const Form: React.FC<Props> = ({
  type,
  holiday,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
    reset,
    getValues,
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(
      yup.object({
        name: yup.string().required(),
        holidayRangeFrom: yup.string().required(),
        holidayRangeTo: yup.string().required(),
        repeat: yup.string().oneOf(["NONE", "ANNUALLY", "MONTHLY"]).required(),
      })
    ),
    defaultValues: {
      name: holiday.name,
      holidayRangeFrom: holiday.holidayRangeFrom,
      holidayRangeTo: holiday.holidayRangeTo,
      repeat: holiday.repeat,
    },
  });

  useEffect(() => () => reset(), [reset, isOpen]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal isOpen={isOpen}>
        <FormContainer
          title={type === "create" ? "Add Holiday" : "Update Holiday"}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Name"
                  required
                  errorMessage={errors.name?.message}
                />
              )}
            />
            <Controller
              name="holidayRangeFrom"
              control={control}
              rules={{ required: "This field is required!" }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Date from"
                  errorMessage={errors.holidayRangeFrom?.message}
                />
              )}
            />

            <Controller
              name="holidayRangeTo"
              control={control}
              rules={{
                required: "This field is required!",
                validate: {
                  afterFrom: (v) =>
                    !dayjsExt(v).isBefore(getValues("holidayRangeFrom")) ||
                    "Date To should not be before Date From!",
                },
              }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Date to"
                  errorMessage={errors.holidayRangeTo?.message}
                />
              )}
            />

            <Controller
              name="repeat"
              control={control}
              rules={{ required: "This field is required!" }}
              render={({ field }) => (
                <Select
                  items={[
                    { value: "NONE", name: "None" },
                    { value: "ANNUALLY", name: "Annualy" },
                    { value: "MONTHLY", name: "Monthly" },
                  ]}
                  label="Repeat"
                  {...field}
                />
              )}
            />

            <div className="form-submit-control">
              <Button typestyle="secondary" type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                typestyle="primary"
                disabled={!isDirty || !isValid || isSubmitting}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </FormContainer>
      </Modal>
    </LocalizationProvider>
  );
};
