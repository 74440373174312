import { styled } from "@mui/system";
import { FlexContainer, FlexContainerProps } from "./FlexContainer";

interface Props {
  maxHeight?: string;
  width?: string;
}

export const ScrollableFlexContainer = styled<
  React.FC<FlexContainerProps & Props>
>(FlexContainer)(({ maxHeight, width }) => ({
  maxHeight: maxHeight || "65vh",
  overflowY: "scroll",
  width: width || "100%",
}));
