import { dayjsExt } from "@busie/utils";
import { LegData } from "./interface";
import { Dayjs } from "dayjs";

export const getEarliestDeparture = (
  index: number,
  previous: LegData,
  defaultMinimum: Dayjs
) =>
  index === 0
    ? defaultMinimum
    : dayjsExt(previous.departure).add(previous.travelTime, "ms");

export const legUpdaterFactory =
  (index: number, departure: Dayjs, minimum: Dayjs) => (prev: LegData[]) => {
    let lastUpdated: LegData;

    return prev.map((leg, legIndex) => {
      const res = {
        ...leg,
        earliestDeparture: getEarliestDeparture(legIndex, lastUpdated, minimum),
        departure: index === legIndex ? (departure as Dayjs) : leg.departure,
      };
      lastUpdated = res;
      return res;
    });
  };
