import { Button, FormContainer, TextInput, capitalize } from "@busie/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { schema } from "./schema";

interface FormValues {
  key: string;
  value: string;
}

interface Props {
  mode: "create" | "edit";
  name?: string;
  value?: string;
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
}

export const Form: React.FC<Props> = ({
  mode,
  onClose,
  onSubmit,
  name = "",
  value = "",
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid, errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: { key: name, value },
    resolver: yupResolver(schema),
  });

  return (
    <FormContainer title={`${capitalize(mode)} Custom Field`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="key"
          render={({ field }) => (
            <TextInput
              {...field}
              placeholder="e.g. Address"
              label="Field Name"
              errorMessage={errors.key?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="value"
          render={({ field }) => (
            <TextInput
              {...field}
              placeholder="e.g. 8 North Front Street, Kingston NY"
              label="Field Value"
              errorMessage={errors.value?.message}
            />
          )}
        />

        <div className="form-submit-control">
          <Button
            type="button"
            typestyle="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Close
          </Button>
          <Button
            type="submit"
            typestyle="primary"
            disabled={isSubmitting || !isDirty || !isValid}
            loading={isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
