import { makeAutoObservable } from "mobx";
import { getDispatchLocations } from "@busie/api";
import { FetchingStatus } from "@busie/core";
import { notificationStore } from "@busie/features";
import { DispatchLocation } from "@busie/utils";

class DispatchLocationStore {
  authToken: string | null = null;

  dispatchLocationsFetchStatus: FetchingStatus = "notFetched";
  dispatchLocations: DispatchLocation[] = [];

  constructor() {
    makeAutoObservable(this);

    this.getDispatchLocations = this.getDispatchLocations.bind(this);
    this.setAuthToken = this.setAuthToken.bind(this);
  }

  setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  async getDispatchLocations(): Promise<void> {
    if (!this.authToken) {
      return;
    }

    this.dispatchLocationsFetchStatus = "fetching";

    try {
      this.dispatchLocations = await getDispatchLocations(this.authToken);
      this.dispatchLocationsFetchStatus = "fetched";
    } catch (e) {
      this.dispatchLocationsFetchStatus = "failedFetching";
      notificationStore.setNotificationFromError(e);
    }
  }

  get isDataFetching() {
    return ["notFetched", "fetching"].includes(
      this.dispatchLocationsFetchStatus
    );
  }

  get isNoLocations() {
    return this.dispatchLocations?.length === 0;
  }
}

export default new DispatchLocationStore();
