import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { Driver } from "@busie/utils";
import {
  Button,
  Divider,
  FlexContainer,
  palette,
  NoteText,
  Text,
  TextInput,
  FormContainer,
} from "@busie/ui-kit";

import { schema } from "./model";

interface Props {
  drivers: Driver[];
  onSubmit: () => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  drivers,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<{ confirm?: string }>({
    mode: "onChange",
    defaultValues: {
      confirm: undefined,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Delete Drivers">
      <Text> Are you sure you want to delete the following drivers?</Text>

      <br />

      <NoteText>Drivers to Delete:</NoteText>

      <ul>
        {drivers.map((driver) => (
          <NoteText key={driver._id}>
            <li>
              {driver.firstName} {driver.lastName}
            </li>
          </NoteText>
        ))}
      </ul>

      <br />

      <NoteText>
        Note that this will remove the driver reference to any Reservations that
        these drivers are assigned to.
      </NoteText>

      <NoteText color={palette.red.main}>
        This action cannot be undone.
      </NoteText>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer
          align="flex-start"
          direction="column"
          columnGap={2}
          fullWidth
        >
          <Controller
            name="confirm"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                fullWidth
                label={
                  "Type 'confirm' to confirm deletion of the drivers listed above."
                }
                errorMessage={errors.confirm?.message}
              />
            )}
          />
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Delete Drivers
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
