import { styled } from "@mui/system";

import { Theme } from "@mui/system";
import palette from "../../theme/palette";

interface Props {
  theme?: Theme;
  size?: "large" | "short";
  justify:
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly"
    | "center";
  margin?: string;
  name?: { color?: string };
  value?: { color?: string };
}

const PropertyStringStyled = styled("div")((props: Props) => {
  // const palette = props.theme?.palette;
  const nameColor = props.name?.color || palette?.black?.plus30;
  const valueColor = props.value?.color || palette?.black.main;
  return {
    "&": {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      columnGap: "20px",
      justifyContent: props.justify,
      fontSize: "14px",
      fontWeight: "600",
      margin: props.margin || "0 0 5px 0",
      minHeight: "32px",
      alignItems: "center",
      ".name": {
        maxWidth: props.size === "large" ? "390px" : "265px",
        width: "100%",
        color: nameColor,
      },
      ".value": {
        color: valueColor,
        maxWidth: "265px",
        whiteSpace: "nowrap",
        overflow: "auto",
        a: {
          color: palette?.red?.main,
          textDecoration: "none",
          "&:hover,&:focus": {
            color: palette?.red?.minus5,
          },
        },
      },
    },
  };
});

export default PropertyStringStyled;
