import React from "react";

import { SelectChangeEvent as SelectChangeEventType } from "@mui/material/Select";

import InputLabel from "../InputLabel";
import SelectStyled from "./styled/SelectStyled";
import MenuItemStyled from "./styled/MenuItemStyled";

import { createHtmlAttrId } from "../helpers";
import { SxProps, Theme } from "@mui/system";
import { ErrorMessage } from "../typography";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectChangeEvent = SelectChangeEventType<any>;

export interface SelectItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface Props {
  value?: SelectItem | string | number | boolean;
  defaultValue?: unknown;
  onChange: (e: SelectChangeEvent) => void;
  getName?: (item: SelectItem) => string | number;
  getValue?: (item: SelectItem) => string | number | SelectItem;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  className?: string;
  items: SelectItem[];
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  errorMessage?: string;
  multiple?: boolean;
  displayEmpty?: boolean;
  hideErrorSpace?: boolean;
  renderValue?: (
    value: SelectItem | string | number | boolean | unknown
  ) => React.ReactNode;
}

export const Select: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <div
      className={props.className}
      style={{
        paddingTop: props.label ? "8px" : 0,
        height: props.label && !props.hideErrorSpace ? "90px" : "auto",
        width: props.fullWidth ? "100%" : undefined,
      }}
    >
      {props.label && (
        <InputLabel required={props.required}>{props.label}</InputLabel>
      )}
      <SelectStyled
        id={`select-${createHtmlAttrId(props.label)}`}
        defaultValue={props.defaultValue}
        value={props.value}
        disabled={props.disabled}
        name={props.name}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e)}
        multiple={props.multiple}
        displayEmpty={props.displayEmpty}
        renderValue={props.renderValue}
        sx={props.sx}
      >
        {props.items.map((item, i) => {
          return (
            <MenuItemStyled
              key={i}
              value={props.getValue ? props.getValue(item) : item.value}
            >
              {props.getName ? props.getName(item) : item.name}
            </MenuItemStyled>
          );
        })}
      </SelectStyled>
      {props.errorMessage && props.errorMessage.length > 0 && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </div>
  );
};
