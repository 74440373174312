import { makeAutoObservable } from "mobx";
import {
  Cookbook,
  CookbookType,
  Ingredient,
  Menu,
  Recipe,
  RecipesMap,
} from "@busie/utils";
import { FetchingStatus } from "@busie/core";

import { fetchMenu, PaginatedResponse } from "@busie/api";

import { initialMenuState } from "./initialState";
import { notificationStore } from "@busie/features";
import { baseIngredientTypes } from "~/rates/RateMenuPage/store/constants";

export const COOKBOOKS_PER_PAGE = 10;

export class RatesStore {
  isFetching = true;
  authToken = "";
  organizationId = "";
  filteredCookbooks: Cookbook[] = [];
  menu: Menu = initialMenuState;
  cookbookSearchValue = "";
  cookbookType: CookbookType | string = "all";
  isFetchingCookbooks: FetchingStatus = "notFetched";
  cookbooksResponse: PaginatedResponse<Cookbook> | null = null;
  activeRecipe: Recipe | null = null;
  organizationHasRecipes = false;
  recipesMap: RecipesMap = {};

  constructor() {
    makeAutoObservable(this);
    this.reset = this.reset.bind(this);
  }

  setAuthToken(token: string): void {
    this.authToken = token;
  }
  setOrganizationId(id: string): void {
    this.organizationId = id;
  }
  setActiveRecipe(recipe: Recipe): void {
    this.activeRecipe = recipe;
  }
  clearActiveRecipe(): void {
    this.activeRecipe = null;
  }
  upsertActiveRecipeIngredient(ingredient: Ingredient): void {
    if (!this.activeRecipe) return;
    const oldIngredientIndex = this.activeRecipe.ingredients.findIndex(
      (i) => i.id === ingredient.id
    );
    if (oldIngredientIndex && oldIngredientIndex >= 0) {
      this.activeRecipe.ingredients = this.activeRecipe.ingredients.map((i) => {
        return i.id === ingredient.id ? ingredient : i;
      });
    } else {
      this.activeRecipe.ingredients = [
        ...this.activeRecipe.ingredients,
        ingredient,
      ];
    }
  }

  get sortedActiveRecipeIngredients(): Ingredient[] {
    //sorting by type, base types are first
    if (!this.activeRecipe) return [];
    return [...this.activeRecipe.ingredients].sort((a, b) => {
      if (
        !baseIngredientTypes.includes(a.type) &&
        baseIngredientTypes.includes(b.type)
      )
        return 1;
      if (
        baseIngredientTypes.includes(a.type) &&
        !baseIngredientTypes.includes(b.type)
      )
        return -1;
      return 0;
    });
  }

  deleteActiveRecipeIngredient(id: string): void {
    if (!this.activeRecipe) return;
    this.activeRecipe.ingredients = this.activeRecipe.ingredients.filter(
      (i) => i.id !== id
    );
  }
  async setMenu(): Promise<boolean> {
    if (!this.organizationId) {
      notificationStore.setNotification({
        type: "failure",
      });
      return false;
    }
    try {
      const menus = await fetchMenu(
        { organization: this.organizationId },
        this.authToken
      );
      if (menus.length === 0) {
        notificationStore.setNotification({
          type: "failure",
          header: "Your organization is not yet onboarded",
        });
        return false;
      }
      this.menu = menus[0];
      return true;
    } catch (e) {
      notificationStore.setNotificationFromError(e);
      return false;
    }
  }

  setCookbooksSearchValue(search: string): void {
    this.cookbookSearchValue = search;
  }

  setCookbookType(cookbookType: CookbookType | string): void {
    this.cookbookType = cookbookType;
  }

  updateRecipesMap(recipes: RecipesMap): void {
    this.recipesMap = {
      ...this.recipesMap,
      ...recipes,
    };
  }

  public reset(): void {
    this.isFetching = true;
    this.authToken = "";
    this.organizationId = "";
    this.filteredCookbooks = [];
    this.menu = initialMenuState;
    this.cookbookSearchValue = "";
    this.cookbookType = "all";
    this.activeRecipe = null;
  }
}
