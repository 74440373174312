import {
  BlackCarLimousineIcon,
  DoubleDeckerIcon,
  EntertainerCoachIcon,
  ExecutiveCoachIcon,
  MiniBusIcon,
  MotorcoachIcon,
  SchoolBusIcon,
  TrolleyIcon,
  VanIcon,
} from "@busie/ui-kit";
import React from "react";

import { VehicleLabelStyled } from "./styled";

const VEHICLES: { [key: string]: { name: string; icon?: React.ReactNode } } = {
  DOUBLE_DECKER: {
    name: "Double Decker",
    icon: <DoubleDeckerIcon />,
  },
  EXECUTIVE_COACH: {
    name: "Executive Coach",
    icon: <ExecutiveCoachIcon />,
  },
  MINI_BUS: {
    name: "Mini Bus",
    icon: <MiniBusIcon />,
  },
  MOTOR_COACH: {
    name: "Motor Coach",
    icon: <MotorcoachIcon size={24} />,
  },
  ENTERTAINER_COACH: {
    name: "Entertainer Coach",
    icon: <EntertainerCoachIcon />,
  },
  SCHOOL_BUS: {
    name: "School Bus",
    icon: <SchoolBusIcon />,
  },
  TROLLEY: {
    name: "Trolley",
    icon: <TrolleyIcon />,
  },
  VAN: {
    name: "Van",
    icon: <VanIcon />,
  },
  BLACK_CAR: {
    name: "Black Car",
    icon: <BlackCarLimousineIcon />,
  },
};

interface Props {
  type: string;
}

export const VehicleLabel: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <VehicleLabelStyled>
      {VEHICLES[props.type]["icon"] && (
        <span className="icon">{VEHICLES[props.type]["icon"]}</span>
      )}

      <span className="text">{VEHICLES[props.type]["name"]}</span>
    </VehicleLabelStyled>
  );
};

export default VehicleLabel;
