import axios from "axios";
import {
  env,
  Invoice,
  InvoicePaymentMethod,
  InvoiceStatus,
  InvoiceVoidReason,
} from "@busie/utils";

const BASE_URL = `${env("BUSIE_PAYMENT_SERVICE_API_URL")}/ps`;

export interface Account {
  _id: string;
  organizationId: string;
  connectedAccountId: string;
  onboardCompleted: boolean;
  currentPlatformFee: number;
  publicIntegrationKey: string;
}
export interface AccountLinkResponse {
  accountId: string;
  connectedAccountId: string;
  url: string;
  expiresAt: string;
  onboardCompleted: boolean;
}
export interface PendingPayout {
  id: string;
  revenueShareConfigId: string;
  customerId: string;
  organizationId: string;
  transferGroup: string;
  amount: number;
  paymentStatus: string;
  referenceMonth: number;
  referenceYear: number;
  checkoutDate?: Date;
}
export interface MonthCompletePayout {
  id: string;
  revenueShareConfigId: string;
  customerId: string;
  organizationId: string;
  transferGroup: string;
  amount: number;
  paymentStatus: string;
  referenceMonth: number;
  referenceYear: number;
}
export interface DashboardResponse {
  dashboardUrl: string;
  pendingPayouts: PendingPayout[];
  monthCompletePayouts: MonthCompletePayout[];
  upcomingPayoutsTotal: number;
  monthlyPayout: number;
}

export interface Destination {
  apportionmentType: string;
  organizationId: string;
  apportionmentValue: number;
  organizationType: string;
  connectedAccountId: string;
  publicIntegrationKey?: string;
}

export interface RevenueShareConfig {
  _id: string;
  isActive: boolean;
  createdAt: Date;
  createdBy: string;
  organizationId: string;
  platformApportionmentType: string;
  platformApportionmentValue: number;
  destinations: Destination[];
  configType: string;
}

export interface CreateInvoicedCustomerParams {
  id: string;
  groupId: string;
  groupName: string;
  name: string;
  email: string;
}

export interface CreateInvoiceParams {
  organizationId: string;
  bookingId: string;
  amount: number;
  customer: CreateInvoicedCustomerParams;
  revenueShareConfigId: string;
  daysUntilDue: number;
}

export interface ListInvoicesParams {
  bookingId?: string;
  customer?: {
    id?: string;
    email?: string;
    groupId?: string;
    name?: string;
    groupName?: string;
  };
  status?: InvoiceStatus;
}

export interface VoidInvoiceData {
  voidReason: InvoiceVoidReason;
  voidReasonDetail: string;
}

export interface PayInvoiceData {
  paymentMethod: InvoicePaymentMethod;
}

export const listInvoices = async (
  token: string,
  params: ListInvoicesParams
): Promise<Invoice[]> => {
  const { data } = await axios.get<Invoice[]>(`${BASE_URL}/invoices`, {
    params,
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

export const getInvoiceById = async (
  token: string,
  invoiceId: string
): Promise<Invoice> => {
  const { data } = await axios.get<Invoice>(
    `${BASE_URL}/invoices/${invoiceId}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return data;
};

export const getInvoicePreviewLink = async (
  authToken: string,
  invoiceId: string
): Promise<string> => {
  const { data } = await axios.get(
    `${BASE_URL}/invoices/${invoiceId}/preview`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const createInvoice = async (
  token: string,
  createInvoiceData: CreateInvoiceParams
): Promise<Invoice> => {
  const { data } = await axios.post<Invoice>(
    `${BASE_URL}/invoices`,
    createInvoiceData,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const finalizeInvoice = async (
  token: string,
  invoiceId: string
): Promise<Invoice> => {
  const { data } = await axios.post<Invoice>(
    `${BASE_URL}/invoices/${invoiceId}/finalize`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const sendInvoice = async (
  token: string,
  invoiceId: string
): Promise<Invoice> => {
  const { data } = await axios.post<Invoice>(
    `${BASE_URL}/invoices/${invoiceId}/send`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const voidInvoice = async (
  token: string,
  invoiceId: string,
  voidInvoiceData: VoidInvoiceData
) => {
  const { data } = await axios.post(
    `${BASE_URL}/invoices/${invoiceId}/void`,
    voidInvoiceData,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const payInvoice = async (
  token: string,
  invoiceId: string,
  payInvoiceData: PayInvoiceData
) => {
  const { data } = await axios.post(
    `${BASE_URL}/invoices/${invoiceId}/pay`,
    payInvoiceData,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return data;
};

export const listRevenueShareConfigs = async (
  authToken: string,
  organizationId: string
): Promise<RevenueShareConfig[]> => {
  const { data } = await axios.get(`${BASE_URL}/revenue-share`, {
    headers: { Authorization: `Bearer ${authToken}` },
    params: {
      organizationId,
    },
  });
  return data;
};

export const getRevenueShareConfig = async (
  authToken: string,
  revenueShareConfigId: string
): Promise<RevenueShareConfig> => {
  const { data } = await axios.get(
    `${BASE_URL}/revenue-share/${revenueShareConfigId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  return data;
};

export const getMultipleRevenueShareConfigs = async (
  authToken: string,
  revenueShareConfigIds: string[]
): Promise<RevenueShareConfig[]> => {
  const { data } = await axios.get(`${BASE_URL}/revenue-share/multiple`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      ids: revenueShareConfigIds,
    },
  });

  return data;
};

/**
 * @deprecated
 */
export const createPayment = async (
  authToken: string,
  customerId: string,
  organizationId: string,
  bookingId: string,
  amount: number
) => {
  const revenueShareConfigId = await listRevenueShareConfigs(
    authToken,
    organizationId
  );
  const { data } = await axios.post(
    `${BASE_URL}/payment`,
    {
      customerId,
      organizationId,
      bookingId,
      revenueShareConfigId,
      amount,
    },
    { headers: { Authorization: `Bearer ${authToken}` } }
  );
  return data;
};

export const linkAccount = async (
  authToken: string,
  organizationId: string
): Promise<{ url: string }> => {
  const { data } = await axios.post(
    `${BASE_URL}/accounts/`,
    { organizationId },
    { headers: { Authorization: `Bearer ${authToken}` } }
  );

  return data;
};

export const getStripeDashboard = async (
  authToken: string
): Promise<DashboardResponse> => {
  const { data } = await axios.get(`${BASE_URL}/accounts/dashboard`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return data;
};

export const getAccounts = async (
  authToken: string,
  organizationId: string
): Promise<Account[]> => {
  const { data } = await axios.get(`${BASE_URL}/accounts/`, {
    params: { organizationId },
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return data;
};

export const getAccountOnboardingStatus = async (
  authToken: string,
  organizationId: string
): Promise<Account> => {
  const { data } = await axios.get(
    `${BASE_URL}/accounts/${organizationId}/is-onboarded`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
  return data;
};

export const getAccountLink = async (
  authToken: string,
  accountId: string
): Promise<AccountLinkResponse> => {
  const { data } = await axios.get(`${BASE_URL}/accounts/${accountId}/link`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return data;
};

export const createRevenueShare = async (
  authToken: string,
  organizationId: string,
  platformFee: number,
  percentage: number,
  publicIntegrationKey: string
) => {
  const apportionmentType = "PERCENTAGE";
  const { data } = await axios.post(
    `${BASE_URL}/revenue-share`,
    {
      organizationId,
      platformApportionmentType: apportionmentType,
      platformApportionmentValue: platformFee,
      destinations: [
        {
          apportionmentType,
          apportionmentValue: percentage,
          organizationId: organizationId,
        },
        {
          apportionmentType,
          publicIntegrationKey,
          apportionmentValue: 100 - platformFee - percentage,
        },
      ],
      configType: "INTEGRATION",
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};

export const updateRevenueShare = async (
  authToken: string,
  organizationId: string,
  otherOrganizationId: string,
  revenueShareConfigId: string,
  platformFee: number,
  percentage: number
) => {
  const apportionmentType = "PERCENTAGE";
  const { data } = await axios.patch(
    `${BASE_URL}/revenue-share/${revenueShareConfigId}`,
    {
      organizationId,
      platformApportionmentType: apportionmentType,
      platformApportionmentValue: platformFee,
      destinations: [
        {
          apportionmentType,
          apportionmentValue: percentage,
          organizationId: organizationId,
        },
        {
          apportionmentType,
          apportionmentValue: 100 - platformFee - percentage,
          organizationId: otherOrganizationId,
        },
      ],
      configType: "INTEGRATION",
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};
