import { makeAutoObservable } from "mobx";
import { deleteDispatchLocation } from "@busie/api";
import { FetchingStatus } from "@busie/core";
import dispatchLocationStore from "~/settings/store/dispatch/dispatch";
import { notificationStore } from "@busie/features";

class DeleteDispatchLocationStore {
  isDeleteModalOpen = false;
  locationsToDelete: string[] = [];
  deleteFetchStatus: FetchingStatus = "notFetched";

  constructor() {
    makeAutoObservable(this);

    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.deleteSelectedLocations = this.deleteSelectedLocations.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }

  closeDeleteModal() {
    this.isDeleteModalOpen = false;
    this.locationsToDelete = [];
  }

  openDeleteModal(locationsToDelete: string[]) {
    this.locationsToDelete = locationsToDelete;
    this.isDeleteModalOpen = true;
  }

  async deleteSelectedLocations(): Promise<void> {
    if (!dispatchLocationStore.authToken) return;

    this.deleteFetchStatus = "fetching";

    try {
      await Promise.all(
        this.locationsToDelete.map((id) =>
          deleteDispatchLocation(id, dispatchLocationStore.authToken as string)
        )
      );
      this.deleteFetchStatus = "fetched";
      this.closeDeleteModal();
      notificationStore.setNotification({
        type: "success",
        header: "Success",
        message: "Dispatch locations successfully deleted",
      });
      await dispatchLocationStore.getDispatchLocations();
    } catch (e) {
      this.deleteFetchStatus = "failedFetching";
      notificationStore.setNotificationFromError(e);
    }
  }

  get isDeleting() {
    return this.deleteFetchStatus === "fetching";
  }
}

export default new DeleteDispatchLocationStore();
