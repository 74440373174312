import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

import FormContainer from "../../FormContainer";
import CreateCustomerDataFields, {
  CustomerDataRefHandle,
  CustomerInputRefs,
} from "./CreateCustomerDataFields";
import Button from "../../Button";

import store from "../../../store";

import { createCustomerAsGuest } from "@busie/api";
import { Customer, Group } from "@busie/utils";
import { InputText } from "@busie/ui-kit";
import { ErrorMessage } from "../../../store/customerForm/helpers";
import { notificationStore } from "../../../../../Notifications/store";
import { useAmplitude } from "@busie/core";
import { Identify } from "@amplitude/analytics-browser";

interface Props {
  guestAuthToken?: string;
  embedded?: boolean;
  embeddedFormOrgId?: string;
  children?: React.ReactNode;
  goNext: () => void;
}

const CustomerFormEmbedded: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ guestAuthToken = "", ...props }: Props) => {
    const customerForm = store.customerForm;
    const { track, identify } = useAmplitude();

    useEffect(() => {
      customerForm.setCustomerSelectionMode("create_new_customer");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const customerDataFieldsRef = useRef<CustomerDataRefHandle>(null);

    const updateTrip = async (): Promise<void> => {
      const data = store.getTripFormData(props.embeddedFormOrgId);
      if (!data.legs.length) return;
      await store.departuresForm.submitForm(
        data,
        {
          guestAuthToken: guestAuthToken,
        },
        props.embedded
      );
    };

    const handleSubmit = async () => {
      await customerForm.validateEmbeddedForm();

      if (!customerForm.validation.valid) {
        const {
          validation: { errors },
        } = customerForm;
        const firstFieldWithError = Object.keys(errors).find(
          (fieldName) => !!errors[fieldName as keyof ErrorMessage]
        );
        customerDataFieldsRef.current?.focusField(
          firstFieldWithError as keyof CustomerInputRefs
        );
        return;
      }

      if (customerForm.isFormUpdated) {
        try {
          const newCustomer = await createCustomerAsGuest(
            guestAuthToken,
            customerForm.fields
          );

          const newCustomerWithName = {
            ...newCustomer.customer,
            groupIds: [newCustomer.group.id],
            groupName: newCustomer.group.name,
          };
          await customerForm.setCustomer(newCustomerWithName as Customer);
          customerForm.setGroup(newCustomer.group as Group);
          const identifyObj = new Identify();
          identifyObj.set("email", newCustomer.customer.email);
          identify(identifyObj);
          track("trip planning customer group created");
          track("trip planning customer created");
          if (customerForm.editMode) await updateTrip();

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          // notify the user that the session expired.
          if (e.response && e.response.data?.statusCode === 401) {
            return notificationStore.setErrorNotification(
              "Session Expired",
              "Your 30 minute session expired. Please refresh the page and try again."
            );
          }
          return notificationStore.setNotificationFromError(e);
        }
      }
      await props.goNext();
      customerForm.isFormUpdated = false;
    };

    return (
      <>
        <FormContainer title="Customer Information">
          <InputText sx={{ margin: "0 0 16px", fontSize: "15px" }}>
            Create new customer
          </InputText>
          <CreateCustomerDataFields ref={customerDataFieldsRef} />
        </FormContainer>
        <Button
          fullWidth
          async
          aria-label="Continue to next step of this form"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </>
    );
  }
);

export default CustomerFormEmbedded;
