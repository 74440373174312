import { palette, theme } from "@busie/ui-kit";
import { styled } from "@mui/system";

export const Styled = styled("div")(() => ({
  border: `1px solid ${palette.black.plus70}`,
  borderRadius: "6px",
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  ":hover": {
    backgroundColor: palette.black.plus85,
  },
  maxWidth: "475px",
  minHeight: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
