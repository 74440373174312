import { QuoteAndBookingStatus } from "@busie/utils";

export const FILTER_TYPE_OPTIONS = [
  {
    name: "quotes",
    link: "/quotes-and-bookings/quotes",
  },
  {
    name: "bookings",
    link: "/quotes-and-bookings/bookings",
  },
  {
    name: "archived",
    link: "/quotes-and-bookings/archived/quotes",
  },
];

export const ACCEPTED_STATUSES = [
  {
    name: "All statuses",
    value: "ALL" as const,
  },
  {
    name: "Pending",
    value: QuoteAndBookingStatus.PENDING,
  },
  {
    name: "Confirmed",
    value: QuoteAndBookingStatus.CONFIRMED,
  },
  {
    name: "Paid",
    value: QuoteAndBookingStatus.PAID,
  },
  // {
  //   name: "In progress",
  //   value: "IN_PROGRESS",
  // },
];
