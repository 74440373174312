import React from "react";
import { Collapse } from "@mui/material";

import {
  FlexContainer,
  palette,
  AddIcon,
  ChevronDown,
  AccordeonHeaderStyled,
  Subtitle,
  ChevronNext,
  H3,
  EditIcon,
  IconButton,
} from "@busie/ui-kit";
import { Customer, Group } from "@busie/utils";

import { ContainerStyled } from "./styled";
import { CustomerRow } from "./CustomerRow";

interface Props {
  organization: Group;
  onCustomerAdd(organizationId: string): void;
  onCustomerEdit: (customer: Customer) => void;
  onCustomerClick: (customer: Customer) => void;
  onGroupClick: (group: Group) => void;
  onCustomerDelete: (customer: Customer) => void;
  onGroupEdit(id: string): void;
}

export const CustomersOrganizationRow: React.FC<
  React.PropsWithChildren<Props>
> = ({
  organization,
  onCustomerAdd,
  onCustomerEdit,
  onCustomerDelete,
  onCustomerClick,
  onGroupClick,
  onGroupEdit,
}) => {
  const customers = organization.members;
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <ContainerStyled direction="column" fullWidth expanded={isExpanded}>
      <AccordeonHeaderStyled
        direction="row"
        fullWidth
        justify="space-between"
        align="center"
        px={0}
        py={2}
        onClick={() => onGroupClick(organization)}
        expanded={isExpanded}
      >
        <FlexContainer
          direction="row"
          justify="flex-start"
          fullWidth
          align="center"
          px={3}
          columnGap={2}
        >
          <FlexContainer
            direction="row"
            columnGap={0}
            align="center"
            justify="flex-start"
          >
            <IconButton
              size={30}
              highlightOnHover={true}
              onClick={(e: Event) => {
                e.stopPropagation();
                setIsExpanded((prev) => !prev);
              }}
              icon={
                isExpanded ? (
                  <ChevronDown color={palette.black.main} />
                ) : (
                  <ChevronNext color={palette.black.main} />
                )
              }
            />
          </FlexContainer>

          <FlexContainer
            direction="row"
            align="center"
            justify="flex-start"
            fullWidth
          >
            <Subtitle>{organization.name}</Subtitle>
          </FlexContainer>

          <FlexContainer
            direction="row"
            fullWidth
            align="center"
            justify="flex-start"
          >
            <Subtitle>{organization.type}</Subtitle>
          </FlexContainer>

          <FlexContainer
            direction="row"
            fullWidth
            align="center"
            justify="flex-start"
          >
            <Subtitle>{customers.length}</Subtitle>
          </FlexContainer>

          <FlexContainer
            direction="row"
            columnGap={0}
            justify="flex-end"
            align="center"
            fullWidth
          >
            <IconButton
              className="hover-icon"
              size={30}
              onClick={(e: Event) => {
                e.stopPropagation();
                onGroupEdit(organization.id);
              }}
              icon={<EditIcon color={palette.black.main} />}
            />

            <IconButton
              size={30}
              className="hover-icon"
              onClick={(e: Event) => {
                e.stopPropagation();
                onCustomerAdd(organization.id);
              }}
              icon={<AddIcon color={palette.black.main} />}
            />
          </FlexContainer>
        </FlexContainer>
      </AccordeonHeaderStyled>

      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{ width: "100%" }}
      >
        {customers.length ? (
          <FlexContainer p={2} direction="column" rowGap={1}>
            {customers.map((customer) => (
              <CustomerRow
                key={customer.id}
                customer={{ ...customer, groups: [organization] }}
                onEdit={() => onCustomerEdit(customer)}
                onDelete={() => onCustomerDelete(customer)}
                onClick={() => onCustomerClick(customer)}
              />
            ))}
          </FlexContainer>
        ) : (
          <H3 margin="0 0 10px 30px">No Customers</H3>
        )}
      </Collapse>
    </ContainerStyled>
  );
};
