import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";

import { isOrganizationSettingsAllowed, User } from "@busie/utils";
import {
  AddIcon,
  Button,
  FlexContainer,
  H1,
  H2,
  H3,
  H4,
  Hr,
  Loading,
  Select,
  ToggleSwitch,
  palette,
} from "@busie/ui-kit";
import { useDriversSettings } from "~/drivers/model";
import { SettingsContainer } from "~/settings/shared/ui";

import { LicenseTypeListItem, PayRateListItem, RecipientListItem } from "./ui";

export const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, isLoading: isUserLoading } = useAuth0<User>();
  const history = useHistory();

  const { data: settings, isLoading: isSettingsLoading } = useDriversSettings(
    user?.org_id
  );

  useEffect(() => {
    if (isUserLoading || !user) return;

    if (!isOrganizationSettingsAllowed(user)) history.goBack();
  }, [history, user, isUserLoading]);

  if (isSettingsLoading || isUserLoading || !settings) return <Loading />;

  return (
    <SettingsContainer value="Drivers">
      <H1>Drivers</H1>
      <Hr />

      <FlexContainer direction="column" fullWidth rowGap={0} pb={4}>
        <H2>Allowed License Types</H2>
        <H3 color={palette.black.plus30} weight={500}>
          Which licenses are relevant to your business?
        </H3>
        {settings.allowedLicenseTypes.map((licenseType, index) => (
          <LicenseTypeListItem
            key={"license-type-" + index}
            licenseType={licenseType}
          />
        ))}
        <Button
          typestyle="primary"
          size="small"
          type="button"
          startIcon={<AddIcon />}
          disabled={true}
          onClick={() => undefined}
        >
          Add Allowed License Type
        </Button>
      </FlexContainer>

      <FlexContainer direction="column" fullWidth rowGap={0} pb={4}>
        <H2>Require Driver Physical Forms Upload</H2>
        <H3 color={palette.black.plus30} weight={500}>
          Require that your drivers upload their physicals.
        </H3>
        <ToggleSwitch
          disabled={true}
          checked={settings.driverPhysicalsUpload}
          onChange={() => undefined}
        />
      </FlexContainer>

      <FlexContainer direction="column" fullWidth rowGap={0} pb={4}>
        <H2>Pay Claim Submission Notifications</H2>
        <H3 color={palette.black.plus30} weight={500}>
          Allow certain users to be notified when a pay claim is submitted.
        </H3>
        <ToggleSwitch
          disabled={true}
          checked={settings.payClaimSubmissionNotifications}
          onChange={() => undefined}
        />
        {settings.payClaimSubmissionNotifications && (
          <FlexContainer>
            <H3 color={palette.black.plus20}>Recipients</H3>
            <H4 color={palette.black.plus30}>
              Who should receive a notification and a PDF report when a Driver
              submits a Pay Claim?
            </H4>
            {settings.payClaimSubmissionNotificationRecipients.map(
              (recipient, index) => (
                <RecipientListItem
                  recipient={recipient}
                  key={"pay-claim-submission-recipient-" + index}
                />
              )
            )}
            <Button
              typestyle="primary"
              size="small"
              type="button"
              startIcon={<AddIcon />}
              disabled={true}
              onClick={() => undefined}
            >
              Add Recipient
            </Button>
          </FlexContainer>
        )}
      </FlexContainer>

      <FlexContainer direction="column" fullWidth rowGap={0} pb={4}>
        <H2>Pay Claim Reminder Notifications</H2>
        <H3 color={palette.black.plus30} weight={500}>
          Automatically remind your drivers when it's time to submit their Pay
          Claims.
        </H3>
        <ToggleSwitch
          disabled={true}
          checked={settings.payClaimReminderNotifications}
          onChange={() => undefined}
        />
      </FlexContainer>

      <FlexContainer direction="column" fullWidth rowGap={0} pb={4}>
        <H2>Upcoming Physical Notifications</H2>
        <H3 color={palette.black.plus30} weight={500}>
          Automatically notify your drivers when they have an upcoming Physical.
        </H3>
        <ToggleSwitch
          disabled={true}
          checked={settings.upcomingPhysicalNotifications}
          onChange={() => undefined}
        />
      </FlexContainer>

      <FlexContainer direction="column" fullWidth rowGap={0} pb={4}>
        <H2>Default Pay Rates</H2>
        <H3 color={palette.black.plus30} weight={500}>
          Which Pay Rates should be included by default when a new Driver is
          added to the team?
        </H3>
        {settings.defaultPayRates.map((payRate) => (
          <PayRateListItem payRate={payRate} />
        ))}
        <Button
          typestyle="primary"
          size="small"
          type="button"
          startIcon={<AddIcon />}
          disabled={true}
          onClick={() => undefined}
        >
          Add Default Pay Rate
        </Button>
      </FlexContainer>

      <FlexContainer direction="column" fullWidth rowGap={0} pb={4}>
        <H2>Currency</H2>
        <H3 color={palette.black.plus30} weight={500}>
          What is the default currency your organization should use for Driver
          Pay Rates?
        </H3>
        <Select
          value="USD"
          items={[{ name: "USD", value: "USD" }]}
          disabled={true}
          onChange={() => undefined}
        />
      </FlexContainer>
    </SettingsContainer>
  );
};
