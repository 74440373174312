import { FlexContainer, H3, palette, RadioButton } from "@busie/ui-kit";

export const PaymentAmountOption: React.FC<
  React.PropsWithChildren<{
    value: number;
    label: string;
    amount: string;
    disabled?: boolean;
  }>
> = ({ value, label, amount, disabled = false }) => (
  <FlexContainer justify="space-between">
    <RadioButton
      label={label}
      value={value}
      radioProps={{ disabled }}
      sx={{
        ".MuiFormControlLabel-label": {
          color: palette?.black?.plus30,
        },
        width: "60%",
      }}
    />
    <H3>{amount}</H3>
  </FlexContainer>
);
