import React from "react";

import { Waypoint } from "./Waypoint";
import { AddIcon, Button, Loading } from "@busie/ui-kit";
import { observer } from "mobx-react";
import { TripLeg } from "@busie/utils";

import { RouteBlockStyled } from "./styled";

interface Props {
  isAdmin?: boolean;
  legs: TripLeg[] | undefined;
  onAddLeg: (currentLeg: TripLeg, index: number) => void;
  onRemoveLeg: (index: number) => void;
}

export const RouteBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ isAdmin, legs, onAddLeg, onRemoveLeg }) => {
    if (!legs) {
      return <Loading />;
    }

    if (Array.isArray(legs) && !legs.length) {
      return null;
    }

    const AddStopButton = ({ onClick }: { onClick: () => void }) => (
      <Button
        onClick={onClick}
        startIcon={<AddIcon />}
        sx={{ marginBottom: "20px", marginLeft: "20px" }}
      >
        Add Stop
      </Button>
    );

    return (
      <RouteBlockStyled>
        <Waypoint
          type="dispatch"
          title="Dispatch"
          data={legs[0]}
          isAdmin={isAdmin}
        />

        <AddStopButton onClick={() => onAddLeg(legs[0], 0)} />

        {legs.slice(1).map((waypoint, index) => {
          return (
            <>
              <Waypoint
                type={
                  index === legs.slice(1).length - 1 ? "laststop" : "waypoint"
                }
                key={index}
                index={index}
                title={
                  index === 0
                    ? "Pickup"
                    : index === legs.slice(1).length - 1
                    ? "Last stop"
                    : `Leg ${index}`
                }
                data={waypoint}
                isAdmin={isAdmin}
                onRemove={onRemoveLeg}
              />
              <AddStopButton
                onClick={() => onAddLeg({ ...waypoint }, index + 1)}
              />
            </>
          );
        })}

        <Waypoint
          type="dispatch"
          title="Dispatch"
          data={legs[legs.length - 1]}
          lastStop
        />
      </RouteBlockStyled>
    );
  }
);
