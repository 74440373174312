import { styled, Theme } from "@mui/system";
import { buttonClasses } from "@mui/material";

interface Props {
  theme?: Theme;
}

export const Styled = styled("div")<Props>(({ theme }) => ({
  padding: "24px",
  width: "800px",
  height: "100%",
  margin: "auto",
  backgroundColor: theme?.palette?.black.plus85,
  borderRadius: "8px",
  [theme.breakpoints.down("tablet")]: {
    width: "100vw",
    height: "100%",
    padding: "0 0 30px 0",
    [`.${buttonClasses.root}`]: {
      width: "100%",
      maxWidth: "none !important",
    },
  },
}));
