import {
  CloseIcon,
  FlexContainer,
  H2,
  IconButton,
  palette,
  Modal,
  Button,
  PlusIcon,
} from "@busie/ui-kit";
import { useMediaQuery } from "@mui/material";
import { Vehicle, Reservation } from "@busie/utils";
import React from "react";
import CalendarCellStyled, {
  PresentReservationsIndicatorStyled,
  ReservationsPopupStyled,
  ShowReservationsStyled,
} from "~/vehicles/Vehicles/CalendarView/styled/calendarCell.styled";
import ReservationFormPopup from "../ReservationFormPopup";
import ReservationBadge from "../ReservationBadge";
import { ReservationsWrapperStyled } from "~/vehicles/Vehicles/CalendarView/styled/ReservationBadge.styled";
import OutsideClickHandler from "react-outside-click-handler";
import AvailabilityPercentage from "../AvailabilityPercentage";
import { dayjsExt, DayjsExt } from "@busie/utils";

interface Props {
  date: DayjsExt;
  isOtherMonth?: boolean;
  isFuture?: boolean;
  availableVehicles: Vehicle[];
  reservations: Reservation[];
  availability?: number;
  isActive?: boolean;
  setActiveDay?: (date?: DayjsExt) => void;
}
const CalendarCell: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  isOtherMonth,
  isFuture,
  availableVehicles,
  reservations,
  availability,
  isActive,
  setActiveDay,
  children,
}) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const positionL = elementRef.current?.offsetLeft;
  const width = elementRef.current?.clientWidth;
  const [reservationsVisible, setReservationsVisible] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const isMobile = useMediaQuery("@media (max-width: 877px)");
  const isRightSide = parseInt(date.format("d")) < 3;
  const reservationFormWidth = 414;
  const left = "0px";
  // const left = `${
  //   isRightSide
  //     ? (positionL || 0) + (width || 0)
  //     : (positionL || 0) - reservationFormWidth
  // }px`;
  const top = "0px";
  const now = dayjsExt();

  const onDeactivate = () => {
    if (setActiveDay) {
      setActiveDay(undefined);
      setIsHovered(false);
    }
  };
  const previewReservations = reservations.slice(0, 2);

  return (
    <div
      onMouseEnter={() => {
        if (isFuture && !reservationsVisible) setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (isFuture) setIsHovered(false);
      }}
    >
      <CalendarCellStyled
        ref={elementRef}
        isFuture={isFuture}
        isBlank={isOtherMonth}
        onClick={() => {
          if (setActiveDay && isFuture && !isMobile) {
            setActiveDay(date);
          } else if (reservations.length > 0 && isMobile) {
            setReservationsVisible(true);
          }
        }}
        isActive={isActive}
        isHovered={isHovered}
        isMobile={isMobile}
      >
        <FlexContainer justify="space-between" align="center">
          {children}
          {availability && (
            <AvailabilityPercentage
              availability={availability}
              isMobile={isMobile}
            />
          )}
        </FlexContainer>

        {!isMobile && (
          <ReservationsWrapperStyled>
            {previewReservations.map((res) => (
              <ReservationBadge
                isMobile={false}
                isPast={now.isAfter(res.startAt)}
                key={res.id}
                reservation={res}
              />
            ))}
          </ReservationsWrapperStyled>
        )}
        {reservations.length > 2 && !isMobile && (
          <ShowReservationsStyled
            onClick={(e) => {
              setIsHovered(false);
              setReservationsVisible(true);
              e.stopPropagation();
            }}
          >
            {`${reservations.length - 2} more...`}
          </ShowReservationsStyled>
        )}

        {isMobile && reservations.length > 0 && (
          <PresentReservationsIndicatorStyled isFuture={isFuture} />
        )}

        {isMobile && setActiveDay && (
          <Modal isOpen={reservationsVisible}>
            <ReservationsPopupStyled
              isMobile={isMobile}
              onClick={(e) => e.stopPropagation()}
            >
              <FlexContainer
                align="center"
                justify="space-between"
                sx={{
                  paddingBottom: "12px",
                }}
              >
                <H2>{date.format("D MMM, dddd")}</H2>
                <IconButton
                  icon={<CloseIcon color="black" />}
                  onClick={() => {
                    setIsHovered(false);
                    setReservationsVisible(false);
                  }}
                />
              </FlexContainer>
              <ReservationsWrapperStyled isMobile={isMobile}>
                {reservations.map((res) => (
                  <ReservationBadge
                    isMobile={isMobile}
                    isPast={now.isAfter(res.startAt)}
                    key={res.id}
                    reservation={res}
                    onClick={() => setReservationsVisible(false)}
                  />
                ))}
              </ReservationsWrapperStyled>
              {isFuture && (
                <Button
                  typestyle="secondary"
                  onClick={() => {
                    setReservationsVisible(false);
                    setActiveDay(date);
                  }}
                  startIcon={<PlusIcon color={palette.black.main} />}
                  sx={{
                    width: "70%",
                    alignSelf: "center",
                    marginTop: "15px",
                  }}
                  size="small"
                >
                  Create new reservation
                </Button>
              )}
            </ReservationsPopupStyled>
          </Modal>
        )}
        {reservationsVisible && !isMobile && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsHovered(false);
              setReservationsVisible(false);
            }}
          >
            <ReservationsPopupStyled onClick={(e) => e.stopPropagation()}>
              <FlexContainer
                align="center"
                justify="space-between"
                sx={{
                  paddingBottom: "12px",
                }}
              >
                <H2>{date.format("D MMM, dddd")}</H2>
                <IconButton
                  icon={<CloseIcon color="black" />}
                  onClick={() => {
                    setIsHovered(false);
                    setReservationsVisible(false);
                  }}
                />
              </FlexContainer>
              <ReservationsWrapperStyled>
                {reservations.map((res) => (
                  <ReservationBadge
                    isPast={now.isAfter(res.startAt)}
                    key={res.id}
                    reservation={res}
                  />
                ))}
              </ReservationsWrapperStyled>
            </ReservationsPopupStyled>
          </OutsideClickHandler>
        )}
      </CalendarCellStyled>
      {isActive && (
        <ReservationFormPopup
          isMobile={isMobile}
          date={date}
          left={left}
          top={top}
          onClose={onDeactivate}
          availableVehicles={availableVehicles}
        />
      )}
    </div>
  );
};

export default CalendarCell;
