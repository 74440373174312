import { IconButton, palette } from "@busie/ui-kit";
import { styled } from "@mui/system";

export const AddQuoteButtonStyled = styled(IconButton)(() => ({
  height: "40px",
  backgroundColor: palette.black.plus100,
  width: "40px",
  border: `1px solid ${palette?.black?.plus70}`,
  borderRadius: "6px",
}));
