import React from "react";
import { Subtitle as BaseSubtitle } from "@busie/ui-kit";

interface Props {
  children: React.ReactNode;
  color?: string;
}

export const Subtitle: React.FC<Props> = ({ children, color }) => {
  return (
    <BaseSubtitle color={color} lineHeight="150%" fontSize="24px">
      {children}
    </BaseSubtitle>
  );
};
