import { useMutation } from "react-query";

import { createCustomer, createGroup } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";

import { customersModel } from "~/CustomersPage/entity";

interface CreateCustomerParams {
  name: string;
  email: string;
  phoneNumber: string;
  organization: string;
  shouldCreateGroup: boolean;
}

export const useCreateCustomer = () => {
  const customersAuthToken = useCustomerAuthToken();

  const mutation = useMutation(
    async ({
      name,
      email,
      phoneNumber,
      organization,
      shouldCreateGroup,
    }: CreateCustomerParams) => {
      let orgId = organization;
      if (shouldCreateGroup) {
        const group = await createGroup(customersAuthToken, {
          name: organization,
        });
        orgId = group.id;
      }
      const customer = await createCustomer(customersAuthToken, {
        name,
        email,
        phoneNumber,
        organization: orgId,
      });
      customersModel.invalidateCustomersQueries();
      return customer;
    }
  );

  return mutation;
};
