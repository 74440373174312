import { styled } from "@mui/system";
import { FlexContainer } from "@busie/ui-kit";

export const Styled = styled(FlexContainer)((props) => {
  return {
    padding: 0,
    maxWidth: "1360px",
    margin: "25px auto",

    ".settings-controls": {
      padding: "13px 20px",
      width: "100%",
      display: "flex",
      borderBottom: `1px solid ${props.theme.palette.black.plus70}`,
      justifyContent: "space-between",
    },

    ".content": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    ".content .dashboard-link": {
      width: "475px",
      height: "auto",
      border: "1px solid #d4d4d4",
      boxSizing: "border-box",
      borderRadius: "6px",
      marginTop: "28px",
      padding: "18px  0 18px 27px",

      h3: {
        margin: 0,
      },

      button: {
        marginTop: "17px",
      },
    },

    ".content:not(:has(.pricing-table))": {
      padding: "37px 62px",

      h1: {
        paddingBottom: "8px",
      },

      p: {
        // note: we want to get rid of this margin eventually as the settings page is refactored.
        // see .content:has(.new-settings-content) below
        margin: "11px 0 20px",
        color: props.theme.palette.black.plus30,
        fontWeight: 500,
        letterSpacing: "0.04em",
      },
    },
    ".content:has(.new-settings-content)": {
      p: {
        margin: "0px",
        color: props.theme.palette.black.main,
      },
    },
  };
});
