import * as yup from "yup";

export const KEY_MIN_LENGTH = 2;
export const KEY_MAX_LENGTH = 50;
export const VALUE_MIN_LENGTH = 1;
export const VALUE_MAX_LENGTH = 256;

export const schema = yup.object({
  key: yup
    .string()
    .required("Field Name is required")
    .min(
      KEY_MIN_LENGTH,
      `Field Name must be more than ${KEY_MIN_LENGTH} characters`
    )
    .max(
      KEY_MAX_LENGTH,
      `Field Name cannot exceed ${KEY_MAX_LENGTH} characters`
    ),
  value: yup
    .string()
    .required("Field Value is required")
    .min(
      VALUE_MIN_LENGTH,
      `Field Value must be more than ${VALUE_MIN_LENGTH} character`
    )
    .max(
      VALUE_MAX_LENGTH,
      `Field Value cannot exceed ${VALUE_MAX_LENGTH} characters`
    ),
});
