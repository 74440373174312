import axios from "axios";

import { RouteRequest, RouteResponse } from "../../dataTypes/routes.data-type";
import { env } from "@busie/utils";

export const createRouteRequest = async (
  authToken: string,
  formData: RouteRequest
): Promise<RouteResponse> => {
  const { data } = await axios.post(
    `${env("BUSIE_PATHFINDER_SERVICE_API_URL")}/routes`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );

  return data;
};
