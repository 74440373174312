import React from "react";
import { observer } from "mobx-react-lite";

import { Checkbox } from "@busie/ui-kit";

import IngredientGroupForm from "~/rates/RateMenuPage/components/CreateRecipeModal/IngredientGroupForm";

import {
  IngredientGroup as IIngredientGroup,
  IngredientListType,
} from "@busie/utils";

interface Props {
  group: IIngredientGroup;
  groupName: IngredientListType;
  groupIndex: number;
  toggle: () => void;
  disabled?: boolean;
}

const IngredientGroup: React.FC<React.PropsWithChildren<Props>> = observer(
  (props) => {
    return (
      <div>
        <Checkbox
          checked={props.group.checked}
          onChange={props.toggle}
          name={props.group.name}
          disabled={props.disabled || false}
        />
        {props.group.checked && (
          <IngredientGroupForm
            groupName={props.groupName}
            groupIndex={props.groupIndex}
            group={props.group}
          />
        )}
      </div>
    );
  }
);

export default IngredientGroup;
