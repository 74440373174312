import { styled } from "@mui/system";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { formGroupClasses } from "@mui/material/FormGroup";
import { Theme } from "@mui/system";
import { palette } from "@busie/ui-kit";

interface Props {
  theme?: Theme;
  waypointType: string;
  isLastStop: boolean;
  isMobile: boolean;
}

const getBackgroundColor = (
  waypointType: string,
  isLastStop: boolean
): string => {
  if (waypointType === "laststop") {
    return palette.black.plus60;
  } else if (waypointType === "waypoint") {
    return palette.black.main;
  }
  return palette.green.main;
};

export const WaypointStyled = styled("div")<Props>(
  ({ theme, waypointType, isLastStop, isMobile }: Props) => {
    const addressHeight = isMobile ? "310px" : "125px";
    const palette = theme?.palette;
    return {
      display: "grid",
      gridTemplateColumns: "10px 1fr",
      columnGap: "16px",
      height: addressHeight,
      maxHeight: addressHeight,
      minHeight: addressHeight,
      ".title-block": {
        borderRadius: "4px",
        background: palette?.green?.main,
        color: palette?.black?.plus100,
        textAlign: "center",
        padding: "4px 0",
        "&.dispatch": {
          background: palette?.black?.plus50,
        },
        "&.laststop": {
          background: palette?.black?.main,
        },
      },
      ".arrow.show-arrow": {
        height: addressHeight,
        position: "relative",
        width: "0px",
        margin: "auto",
        transform: "translateY(7px)",
        "&:after": {
          content: "''",
          width: "8px",
          height: "8px",
          backgroundColor: palette?.black?.plus60,
          borderRadius: "50%",
          position: "absolute",
          opacity: waypointType === "dispatch" ? 1 : 0,
          display: waypointType === "dispatch" ? "block" : "none",
          left: "-5px",
          zIndex: 4,
        },
        "&:before": {
          content: "''",
          width: "8px",
          height: "8px",
          backgroundColor: getBackgroundColor(waypointType, isLastStop),
          borderRadius: "50%",
          position: "absolute",
          bottom: "-10px",
          left: "-5px",
          zIndex: 2,
        },
        "& .progress": {
          position: "absolute",
          top: "18px",
          left: "-2px",
          width: "0px",
          height: "100px",
          borderLeft: `2px dotted ${palette?.green?.main}`,
        },
      },
      ".content-block": {
        padding: "16px",
        ".grid": {
          display: "grid",
          gridTemplateColumns: "303px 155px 135px",
          gridTemplateRows: "1fr 1fr",
          gap: "16px",
          width: "100%",
          [`.${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}, .${formGroupClasses.root} input`]:
            {
              borderColor: palette?.black?.plus70,
            },
        },
        ".stats-block": {
          gridColumnStart: "2",
          gridColumnEnd: "4",
          textAlign: "right",
          ".stats": {
            display: "inline-block",
            textAlign: "left",
            "+.stats": {
              marginLeft: "16px",
            },
            ".value": {
              padding: "13px 8px",
              borderRadius: "8px",
              lineHeight: 1,
              textAlign: "center",
              background: palette?.supernova?.plus40,
            },
          },
        },
      },
    };
  }
);
