import { centsToDollars, currencyFormatter } from "@busie/utils";

export const getCurrencyFormat: (amount: number) => string = (amount: number) =>
  currencyFormatter(centsToDollars(amount));

export const isDisabled = (amount: number) => amount < 10000;

export const getPaymentOptions = (balance: number) => [
  {
    label: "Remaining Balance",
    value: balance,
    amount: getCurrencyFormat(balance || 0),
    disabled: false,
  },
  {
    label: "25% Partial Payment",
    value: Math.ceil((balance || 0) * 0.25),
    amount: getCurrencyFormat(Math.ceil((balance || 0) * 0.25)),
    disabled: isDisabled(balance),
  },
  {
    label: "20% Partial Payment",
    value: Math.ceil((balance || 0) * 0.2),
    amount: getCurrencyFormat(Math.ceil((balance || 0) * 0.2)),
    disabled: isDisabled(balance),
  },
  {
    label: "10% Partial Payment",
    value: Math.ceil((balance || 0) * 0.1),
    amount: getCurrencyFormat(Math.ceil((balance || 0) * 0.1)),
    disabled: isDisabled(balance),
  },
];
