import React from "react";
import { Control, FieldValues, Path, UseFormRegister } from "react-hook-form";

import { PlaceFieldHookForm } from "@busie/ui-kit";
import { GooglePlace } from "@busie/utils";

import { getGooglePlace } from "../../lib";
import { Place } from "../../model";

interface Props<T extends FieldValues> {
  name: Path<T>;
  register: UseFormRegister<T>;
  label: string;
  onChange: (value: GooglePlace) => void;
  place: Place;
  disabled?: boolean;
}

export const Controlled: <T extends FieldValues>(
  props: Props<T>
) => React.ReactElement = ({
  name,
  register,
  label,
  place,
  onChange,
  disabled = false,
}) => {
  return (
    <PlaceFieldHookForm
      {...register(name, {
        required: true,
      })}
      disabled={disabled}
      label={label}
      placeholder="Enter stop location"
      width="100%"
      value={getGooglePlace(place)}
      onChange={onChange}
    />
  );
};
