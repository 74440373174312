import { makeAutoObservable } from "mobx";
import {
  createDispatchLocation,
  updateDispatchLocation,
  DispatchLocationFormData,
} from "@busie/api";
import { FetchingStatus } from "@busie/core";
import { GooglePlace, initialPlaceData } from "@busie/utils";
import dispatchLocationStore from "~/settings/store/dispatch/dispatch";
import { notificationStore } from "@busie/features";

import { getAddress } from "./utils";

export interface FormData {
  name: string;
  location: GooglePlace;
}

const initialFormData: FormData = {
  name: "",
  location: initialPlaceData,
};

class SubmitDispatchLocationStore {
  itemId = "";
  isModalOpen = false;
  submitFetchStatus: FetchingStatus = "notFetched";
  formData: FormData = initialFormData;
  isEdit = false;

  constructor() {
    makeAutoObservable(this);

    this.openCreateModal = this.openCreateModal.bind(this);
    this.submitDispatchLocation = this.submitDispatchLocation.bind(this);
    this.closeCreateModal = this.closeCreateModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
  }

  openEditModal(id: string, data: FormData) {
    this.itemId = id;
    this.formData = data;
    this.isEdit = true;
    this.isModalOpen = true;
  }

  openCreateModal() {
    this.formData = initialFormData;
    this.isEdit = false;
    this.isModalOpen = true;
  }

  closeCreateModal() {
    this.isModalOpen = false;
  }

  async submitDispatchLocation(data: FormData): Promise<void> {
    if (!dispatchLocationStore.authToken) return;

    this.submitFetchStatus = "fetching";

    const formData: DispatchLocationFormData = {
      name: data.name,
      latitude: data.location.geometry.location.lat().toString(),
      longitude: data.location.geometry.location.lng().toString(),
      ...getAddress(data.location),
    } as DispatchLocationFormData;

    try {
      if (this.isEdit) {
        await updateDispatchLocation(
          this.itemId,
          formData,
          dispatchLocationStore.authToken
        );
      } else {
        await createDispatchLocation(formData, dispatchLocationStore.authToken);
      }

      this.submitFetchStatus = "fetched";
      this.closeCreateModal();
      notificationStore.setNotification({
        type: "success",
        header: "Success",
        message: `Dispatch location successfully ${
          this.isEdit ? "updated" : "created"
        }`,
      });
      await dispatchLocationStore.getDispatchLocations();
    } catch (e) {
      this.submitFetchStatus = "failedFetching";
      notificationStore.setNotificationFromError(e);
    }
  }

  get isSubmitDisabled() {
    return !this.formData.name || !this.formData.location.formatted_address;
  }

  get isSubmitting() {
    return this.submitFetchStatus === "fetching";
  }
}

export default new SubmitDispatchLocationStore();
