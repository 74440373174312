import { payInvoice, PayInvoiceData } from "@busie/api";
import { usePaymentsAuthToken } from "@busie/core";
import { useMutation } from "react-query";
import { invalidatePaymentQueries } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/Payment/model";

export const usePayInvoice = () => {
  const paymentsAuthToken = usePaymentsAuthToken();

  const mutation = useMutation(
    async ({
      invoiceId,
      payInvoiceData,
    }: {
      invoiceId: string;
      payInvoiceData: PayInvoiceData;
    }) => {
      const invoice = await payInvoice(
        paymentsAuthToken,
        invoiceId,
        payInvoiceData
      );
      // TODO: invalidate qnb queries
      invalidatePaymentQueries();

      return invoice;
    }
  );

  return mutation;
};
