import React from "react";
import { observer } from "mobx-react-lite";
import { FlexContainer } from "@busie/ui-kit";
import { ReservationTypes, Vehicle, VehicleTypes } from "@busie/utils";
import store from "~/vehicles/store";

import VehicleSelectionField from "./VehicleSelectionField";
import DatesFields from "./DatesFields";
import ReasonField from "./ReasonField";
import DescriptionField from "./DescriptionField";

interface Props {
  vehicles?: Vehicle[];
  vehicleTypes?: VehicleTypes[];
  isFixedHeight?: boolean;
}

const ReservationForm: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ vehicles, vehicleTypes, isFixedHeight = true }: Props) => {
    return (
      <div>
        <FlexContainer
          w={416}
          px={5}
          pt={3}
          pb={3}
          rowGap={1}
          h={isFixedHeight ? 350 : "auto"}
          direction="column"
          sx={{
            overflowY: "scroll",
          }}
        >
          <VehicleSelectionField
            vehicles={vehicles}
            vehicleTypes={vehicleTypes}
          />
          <DatesFields />
          <ReasonField />
          {store.reservationPopup.form.reason === ReservationTypes.OTHER && (
            <DescriptionField />
          )}
        </FlexContainer>
      </div>
    );
  }
);

export default ReservationForm;
