import { QuoteAndBookingStatus } from "@busie/utils";

export const ITEMS_PER_PAGE = 10;

export const QUOTES_IN_USE_STATUSES: QuoteAndBookingStatus[] = [
  // "ACCEPTED",
  QuoteAndBookingStatus.CONFIRMED,
  QuoteAndBookingStatus.PENDING,
];

export const BOOKING_IN_USE_STATUSES: QuoteAndBookingStatus[] = [
  QuoteAndBookingStatus.CONFIRMED,
  QuoteAndBookingStatus.PAID,
  QuoteAndBookingStatus.PENDING,
];

export const ARCHIVED_QUOTES_STATUSES: QuoteAndBookingStatus[] = [
  QuoteAndBookingStatus.DECLINED,
];

export const ARCHIVED_BOOKINGS_STATUSES: QuoteAndBookingStatus[] = [
  QuoteAndBookingStatus.CANCELED,
  QuoteAndBookingStatus.COMPLETE,
];
