import React from "react";
import { useMediaQuery } from "@mui/material";

import {
  theme,
  DrawerDetailsBlock,
  Text,
  palette,
  DocumentsIcon,
} from "@busie/ui-kit";
import { BookingRequestedStyled } from "./styled";

export const BookingRequested: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <DrawerDetailsBlock noHeader padding="16px 18px 8px 18px">
      <BookingRequestedStyled>
        <DocumentsIcon color={palette.green.minus5} />
        <Text color={palette.green.minus5}>Customer Requested Booking</Text>
      </BookingRequestedStyled>
    </DrawerDetailsBlock>
  );
};

export default BookingRequested;
