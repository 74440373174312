import React, { useState } from "react";

import {
  AddIcon,
  Drawer as BusieDrawer,
  Button,
  Comment,
  DrawerDetailsBlock,
  DrawerHeader,
  FlexContainer,
  H3,
  Loading,
  PropertyString,
  palette,
} from "@busie/ui-kit";
import { useCustomer } from "~/CustomersPage/entity/model";
import { useAmplitude, useUsers } from "@busie/core";
import { Customer, CustomerMetadata, dayjsExt } from "@busie/utils";
import {
  AddProfileCustomField,
  AddProfileNote,
  UpdateProfileCustomField,
} from "~/CustomersPage/features";
import { notificationStore } from "@busie/features";
import { CustomField } from "~/CustomersPage/entity";
import { formatPhoneNumber } from "react-phone-number-input";
import { getCountryCode } from "~/CustomersPage/entity/lib";

interface Props {
  customerId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Drawer: React.FC<Props> = ({ customerId, isOpen, onClose }) => {
  const { data: customer, isLoading } = useCustomer(customerId);
  const { data: usersMap = {} } = useUsers();

  const [isAddCustomFieldOpen, setIsAddCustomFieldOpen] = useState(false);
  const [isEditCustomFieldOpen, setIsEditCustomFieldOpen] = useState(false);
  const [updateMetadata, setUpdateMetadata] = useState<CustomerMetadata>();

  const { track } = useAmplitude();

  const onNoteAdded = () => {
    track("profile note added", { ...customer });
    notificationStore.setSuccessNotification(
      "Success",
      "Your note was added to the customer's profile."
    );
  };

  const onFeatureError = (e: unknown) => {
    notificationStore.setNotificationFromError(e);
  };

  const onCustomFieldAdded = (customer: Customer) => {
    track("profile custom field added", { ...customer });
    notificationStore.setSuccessNotification(
      "Success",
      "The custom field was added to the customer's profile."
    );

    setIsAddCustomFieldOpen(false);
  };

  const onCustomFieldUpdated = (customer: Customer) => {
    track("profile custom field updated", { ...customer });
    notificationStore.setSuccessNotification(
      "Success",
      "The custom field was updated."
    );

    setIsEditCustomFieldOpen(false);
  };

  const onCloseEditCustomField = () => {
    setIsEditCustomFieldOpen(false);
    setUpdateMetadata(undefined);
  };

  const onCloseAddCustomField = () => setIsAddCustomFieldOpen(false);

  const sortedNotes = (customer?.notes || []).sort(
    (a, b) => dayjsExt(b.createdAt).unix() - dayjsExt(a.createdAt).unix()
  );

  return (
    <BusieDrawer anchor="right" open={isOpen} onClose={onClose}>
      <DrawerHeader onClose={onClose} title="Profile Details" />
      {isLoading ? (
        <Loading />
      ) : !customer ? (
        <H3>Customer not found</H3>
      ) : (
        <>
          <DrawerDetailsBlock noHeader>
            <PropertyString name="Name">{customer.name}</PropertyString>
            <PropertyString name="Email">{customer.email}</PropertyString>
            <PropertyString name="Phone Number">
              {formatPhoneNumber(
                `${getCountryCode(customer.countryCode)}${customer.areaCode}${
                  customer.phoneNumber
                }`
              )}
            </PropertyString>

            <FlexContainer direction="column" rowGap={2}>
              <H3 margin="16px 0px 0px">Custom Fields</H3>
              {customer.metadata.map((metadata) => (
                <CustomField
                  key={metadata.id}
                  name={metadata.key}
                  value={metadata.value}
                  onEdit={() => {
                    setUpdateMetadata(metadata);
                    setIsEditCustomFieldOpen(true);
                  }}
                  onDelete={() => alert(`delete ${metadata.id}`)}
                />
              ))}

              <Button
                typestyle="tertiary"
                size="small"
                startIcon={<AddIcon color={palette.black.main} />}
                onClick={() => setIsAddCustomFieldOpen(true)}
              >
                Add Custom Field
              </Button>
            </FlexContainer>
          </DrawerDetailsBlock>

          <DrawerDetailsBlock name="Notes" collapsible>
            <FlexContainer direction="column" rowGap={2} fullWidth>
              {sortedNotes.map((note) => (
                <Comment
                  text={note.text}
                  imgSrc={usersMap[note.createdBy]?.picture}
                  name={usersMap[note.createdBy]?.name}
                  createdAt={note.createdAt}
                  updatedAt={
                    note.updatedAt && note.createdAt !== note.updatedAt
                      ? note.updatedAt
                      : undefined
                  }
                />
              ))}

              <AddProfileNote
                customerId={customer.id}
                onSuccess={onNoteAdded}
                onError={onFeatureError}
              />
            </FlexContainer>
          </DrawerDetailsBlock>

          <AddProfileCustomField
            isOpen={isAddCustomFieldOpen}
            customerId={customer.id}
            onSuccess={onCustomFieldAdded}
            onError={onFeatureError}
            onClose={onCloseAddCustomField}
          />

          {updateMetadata && (
            <UpdateProfileCustomField
              isOpen={isEditCustomFieldOpen}
              metadata={updateMetadata}
              customerId={customer.id}
              onSuccess={onCustomFieldUpdated}
              onError={onFeatureError}
              onClose={onCloseEditCustomField}
            />
          )}
        </>
      )}
    </BusieDrawer>
  );
};
