import { styled } from "@mui/system";
import { palette, theme } from "@busie/ui-kit";

interface Props {
  isFuture?: boolean;
  isBlank?: boolean;
  isActive?: boolean;
  isHovered?: boolean;
  isMobile?: boolean;
}

const CalendarCellStyled = styled("div")(
  ({ isFuture, isBlank, isActive, isHovered, isMobile }: Props) => {
    const bgColor = isBlank
      ? palette?.black?.plus80
      : isActive
      ? palette?.red?.plus80
      : isHovered
      ? palette?.black?.plus85
      : palette?.black?.plus100;

    return {
      border: isActive
        ? `1px ${palette?.red?.main} solid`
        : isHovered
        ? `1px ${palette?.black?.plus60} solid`
        : "none",
      padding: isActive || isHovered ? "7px" : "8px",
      color: isFuture ? palette?.black?.plus10 : palette?.black?.plus60,
      backgroundColor: bgColor,
      height: isMobile ? "90px" : "128px",
      display: "flex",
      position: "relative",
      justifyContent: isMobile ? "space-between" : "unset",
      flexDirection: "column",
      rowGap: "10px",
      cursor: isFuture && !isBlank && !isActive ? "pointer" : "auto",
    };
  }
);

export const ShowReservationsStyled = styled("div")(() => ({
  color: palette?.black?.plus30,
  fontSize: "12px",
  width: "fit-content",
  padding: "0 4px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: palette?.black?.plus80,
    cursor: "pointer",
  },
}));

export const ReservationsPopupStyled = styled("div")(
  ({ isMobile }: { isMobile?: boolean }) => ({
    position: isMobile ? "static" : "absolute",
    width: isMobile ? "90vw" : "250px",
    maxHeight: isMobile ? "80vh" : "250px",
    padding: "17px",
    backgroundColor: palette?.black?.plus100,
    zIndex: 300,
    left: "-15%",
    top: "-15%",
    boxShadow: "0px 10px 30px rgba(34, 34, 34, 0.15)",
    borderRadius: "4px",
    cursor: "auto",
    display: "flex",
    flexDirection: "column",
  })
);

export const PresentReservationsIndicatorStyled = styled("div")(
  ({ isFuture }: { isFuture?: boolean }) => ({
    height: theme?.spacing(2),
    width: theme?.spacing(2),
    borderRadius: "100px",
    border: `${
      isFuture ? palette?.red?.main : palette?.black?.plus10
    } 1px solid`,
    backgroundColor: isFuture ? palette?.red?.plus80 : palette?.black?.plus80,
    alignSelf: "flex-end",
  })
);

export default CalendarCellStyled;
