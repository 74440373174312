import { styled, Theme } from "@mui/system";
interface Props {
  theme: Theme;
}

const NoContentBlockStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "5% 0",
    ".icon-block": {
      marginTop: "0px",
    },
    ".text-block": {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "150%",
      textAlign: "center",
      letterSpacing: "0.04em",
      color: palette?.black?.plus20,
      maxWidth: "500px",
      wordBreak: "no",
    },
    ".controls-block": {
      marginTop: "40px",
    },
  };
});

export default NoContentBlockStyled;
