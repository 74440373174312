import React, { useEffect } from "react";
import {
  Button,
  Divider,
  FormContainer,
  NoteText,
  Subtitle,
  TextInput,
  Text,
  palette,
} from "@busie/ui-kit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./model";
import { VoidedContractInfo } from "@busie/utils";

interface Props {
  onSubmit: (data: VoidedContractInfo) => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm<VoidedContractInfo>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: { reason: "" },
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Void E-Contract">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text>
          Are you sure you want to void the E-Contract for this Booking?
        </Text>

        <NoteText>
          Everyone who interacted in the E-Contract signing flow will be
          notified that the contract has been voided.
        </NoteText>

        <NoteText color={palette.red.main}>
          This action cannot be undone.
        </NoteText>

        <Divider />

        <Subtitle fontSize="14px">Reason for voiding the E-Contract</Subtitle>

        <NoteText>
          Please enter the reason you are voiding this E-Contract. This
          information will be present in the E-Contract Voided notification
          email.
        </NoteText>

        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Void Reason"
              errorMessage={errors.reason?.message}
            />
          )}
        />

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Void E-Contract
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
