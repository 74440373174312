import { styled } from "@mui/system";

interface Props {
  isMobile?: boolean;
}

const ColumnsStyled = styled("div")(({ isMobile }: Props) => {
  return {
    display: "grid",
    gridTemplateColumns: `1fr 1fr ${isMobile ? 3 : 5}fr 1fr`,
    columnGap: "16px",
    width: "100%",
    textAlign: "left",
    padding: "14px",
  };
});

export default ColumnsStyled;
