import { styled } from "@mui/system";

const Hr = styled("hr")((props) => {
  const palette = props.theme.palette;
  return {
    width: "100%",
    border: 0,
    borderTop: `1px solid ${palette.black.plus70}`,
  };
});

export default Hr;
