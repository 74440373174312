import { useMutation, useQuery } from "react-query";

import {
  UpdateTripSettingsParams,
  getTripsSettings,
  updateTripSettings,
} from "@busie/api";
import { useTripsAuthToken, queryClient } from "@busie/core";

const TRIPS_SETTINGS_QUERY_KEY = "TRIPS_SETTINGS";
export const DEFAULT_STALE_TIME = 2 * 60 * 1000; // 2 minutes

export const invalidateTripsSettingsQueries = () => {
  queryClient.invalidateQueries(TRIPS_SETTINGS_QUERY_KEY);
};

export const useTripsSettings = (organizationId?: string) => {
  const tripsAuthToken = useTripsAuthToken();

  const result = useQuery(
    [TRIPS_SETTINGS_QUERY_KEY],
    () => getTripsSettings(tripsAuthToken, organizationId as string),
    {
      enabled: !!tripsAuthToken && !!organizationId,
      staleTime: DEFAULT_STALE_TIME,
    }
  );

  return result;
};

export const useUpdateTripsSettings = (settingsId?: string) => {
  const tripsAuthToken = useTripsAuthToken();

  const result = useMutation(
    (params: UpdateTripSettingsParams) =>
      updateTripSettings(tripsAuthToken, settingsId as string, params),
    {
      onSuccess: () => {
        invalidateTripsSettingsQueries();
      },
    }
  );

  return result;
};
