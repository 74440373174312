import { Button, DrawerDetailsBlock, FlexContainer } from "@busie/ui-kit";

interface Props {
  onPrint: () => void;
  onCancel: () => void;
}
export const AdditionalActions: React.FC<Props> = ({ onPrint, onCancel }) => {
  return (
    <DrawerDetailsBlock
      name="Additional Actions"
      collapsible
      className="no-print"
    >
      <FlexContainer
        direction="row"
        columnGap={2}
        align="center"
        justify="flex-start"
        fullWidth
        sx={{ marginBottom: "52px" }}
      >
        <Button typestyle="primary" onClick={onPrint}>
          Print
        </Button>

        <Button typestyle="accent" onClick={onCancel}>
          Cancel Booking
        </Button>
      </FlexContainer>
    </DrawerDetailsBlock>
  );
};
