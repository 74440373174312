import React from "react";
import {
  Table,
  TableCell,
  TableCellSelectAll,
  TableHead,
  TableRow,
} from "@busie/ui-kit";
import { Driver } from "@busie/utils";
import { DriversListItem } from "./ui";

interface Props {
  drivers: Driver[];
  selectedDrivers: string[];
  isMobile?: boolean;
  onEditDriver: (driver: Driver) => void;
  onDeleteDriver: (driver: Driver) => void;
  onDriverClick: (driver: Driver) => void;
  onToggleDriver: (driver: Driver) => void;
  onSelectAllDrivers: () => void;
  onDeselectAllDrivers: () => void;
}

export const List: React.FC<React.PropsWithChildren<Props>> = ({
  drivers,
  selectedDrivers,
  onEditDriver,
  onDeleteDriver,
  onDriverClick,
  onToggleDriver,
  onSelectAllDrivers,
  onDeselectAllDrivers,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellSelectAll
            selectedRowsLength={selectedDrivers.length}
            dataLength={drivers.length}
            onRowsSelect={onSelectAllDrivers}
            onRowsRemove={onDeselectAllDrivers}
          />
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone number</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <tbody>
        {drivers.map((driver) => (
          <DriversListItem
            key={driver._id}
            driver={driver}
            isSelected={selectedDrivers.some(
              (driverId) => driverId === driver._id
            )}
            isSelectable={true}
            onDriverToggle={onToggleDriver}
            onEdit={() => onEditDriver(driver)}
            onDelete={() => onDeleteDriver(driver)}
            onClick={() => onDriverClick(driver)}
          />
        ))}
      </tbody>
    </Table>
  );
};
