import { Modal } from "@busie/ui-kit";
import { Driver } from "@busie/utils";
import React from "react";
import { useDeleteDriver } from "./model";
import { DeleteDriversForm } from "~/drivers/entity";

interface Props {
  isOpen: boolean;
  drivers: Driver[];
  onClose: () => void;
  onDriverDeleted: () => void;
}

export const DeleteDrivers: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  drivers,
  onClose,
  onDriverDeleted,
}) => {
  const { mutateAsync: deleteDriver } = useDeleteDriver();

  const recursivelyDelete = async (drivers: Driver[]): Promise<void> => {
    if (!drivers.length) {
      return;
    }

    const [driver, ...rest] = drivers;
    await deleteDriver(driver._id);

    return recursivelyDelete(rest);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <DeleteDriversForm
        onCancel={onClose}
        drivers={drivers}
        onSubmit={async () => {
          await recursivelyDelete(drivers);
          onDriverDeleted();
        }}
      />
    </Modal>
  );
};
