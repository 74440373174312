import { DispatchLocation } from "@busie/utils";

export const getTravelTimeDisplay = (hours: number) => {
  const minutes = hours * 60;
  const hoursRounded = Math.floor(hours);
  const minutesRounded = Math.round(minutes % 60);
  const hoursDisplay = hoursRounded > 0 ? `${hoursRounded} hours` : "";
  const minutesDisplay = minutesRounded > 0 ? `${minutesRounded} minutes` : "";

  return `${hoursDisplay} ${minutesDisplay}`;
};

export const getLegAddressDisplay = (
  address: string | undefined,
  dispatch: DispatchLocation,
  useDispatch: boolean
) => {
  if (useDispatch) return dispatch.name;

  return address;
};
