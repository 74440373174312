import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import ButtonStyled from "./styled/ButtonStyled";
import LinkStyled from "./styled/LinkStyled";

import { createHtmlAttrId } from "../helpers";
import { SxProps, Theme } from "@mui/system";

export interface ButtonProps {
  children?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  typestyle?: "primary" | "secondary" | "tertiary" | "accent";
  size?: "big" | "small" | "mobile";
  disabled?: boolean;
  fullWidth?: boolean;
  async?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
  href?: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  async,
  children,
  startIcon,
  endIcon,
  size,
  href,
  disabled,
  fullWidth,
  loading,
  sx,
  ...props
}) => {
  const typestyle = props.typestyle || "primary";

  const [isLoadingState, setIsLoadingState] = React.useState<boolean>(false);
  const [isMount, setIsMount] = React.useState<boolean>(true);

  React.useEffect(() => {
    return () => {
      setIsMount(false);
    };
  }, []);

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (!props.onClick) return;
    if (async) {
      setIsLoadingState(true);
      try {
        await props.onClick(e);
      } finally {
        if (isMount) setIsLoadingState(false);
      }
    } else {
      props.onClick(e);
    }
  };

  const isLoading = isLoadingState || !!loading;

  const buttonComponent = (
    <ButtonStyled
      id={`button-${createHtmlAttrId((typestyle + "-" + children) as string)}`}
      startIcon={startIcon}
      endIcon={endIcon}
      variant="contained"
      typestyle={typestyle}
      typesize={size || "big"}
      className={isLoading ? "loading" : ""}
      type={props.type}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={isLoading || disabled}
      disableRipple
      sx={sx}
    >
      <div className="button-content">{children}</div>
      {isLoading && (
        <div className="loading-content">
          <CircularProgress size={14} />
        </div>
      )}
    </ButtonStyled>
  );
  if (href) return <LinkStyled to={href}>{buttonComponent}</LinkStyled>;
  return buttonComponent;
};
