import React from "react";

import { InputText, Subtitle } from "../typography";
import palette from "../theme/palette";

import StatisticsBlockStyled from "./styled/StatisticsBlockStyled";
import { FlexContainer } from "../FlexContainer";

interface Props {
  text: string;
  value: string | number | React.ReactNode;
  startIcon?: React.ReactNode;
  valueIcon?: React.ReactNode;
}

const StatisticsBlock: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <StatisticsBlockStyled>
      <div className="icon-block">{props.startIcon}</div>
      <div className="content-block">
        <InputText color={palette?.black?.plus20} className="text-block">
          {props.text}
        </InputText>
        <FlexContainer align="center" justify="flex-start" columnGap={1}>
          <Subtitle className="value-block">{props.value}</Subtitle>
          {props.valueIcon}
        </FlexContainer>
      </div>
    </StatisticsBlockStyled>
  );
};

export default StatisticsBlock;
