/* eslint-disable indent */
import React from "react";
import get from "lodash.get";
import { observer } from "mobx-react-lite";
import { useLocation, useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";

import {
  datetimeFormatter as dateFormat,
  centsToDollars,
  currencyFormatter,
  metersToMiles,
  DispatchLocation,
} from "@busie/utils";
import {
  palette,
  CommonTable,
  TableContainer,
  Loading,
  TablePagination,
  DocumentsIcon,
  FlexContainer,
} from "@busie/ui-kit";
import { QuotesResponse, QuoteWithAttrs } from "@busie/api";
import {
  StatusBadge,
  TableControls,
  TTLDonut,
  OrganizationLogo,
  MobileList,
  // TODO: see if list controls can be used here instead of the parent component.
  // ListControls
} from "~/QuotesAndBookingsPage/shared/ui";
import { ITEMS_PER_PAGE, quotesStore } from "~/QuotesAndBookingsPage/model";

import { ACCEPTED_STATUSES, FILTER_TYPE_OPTIONS } from "./model";

interface Props {
  quotes: QuoteWithAttrs[];
  quotesResponse: QuotesResponse | null;
  dispatchLocations: DispatchLocation[];
  currentPage: number;
  setPage: (page: number) => void;
}

export const Table: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    quotes,
    quotesResponse,
    dispatchLocations,
    currentPage,
    setPage,
  }: Props) => {
    const { search } = useLocation();
    const history = useHistory();
    const isMobile = useMediaQuery("@media (max-width: 960px)");
    const isTablet = useMediaQuery("@media (max-width: 1136px)");

    const searchParams = new URLSearchParams(search);
    const filterStatus = searchParams.get("status");
    const handlerOnSetTrip = (id: string) => {
      searchParams.set("quoteId", id);
      searchParams.set("page", currentPage.toString());

      history.replace({
        search: `?${searchParams.toString()}`,
      });
    };

    const tableConfig = {
      columnNames: [
        { id: "quote-id", name: "Quote ID", ordered: false },
        { id: "customer", name: "Customer", ordered: false },
        { id: "passengers", name: "Passengers", ordered: false },
        { id: "trip-distance", name: "Trip Distance", ordered: false },
        { id: "pickup-date", name: "Pick Up Date", ordered: false },
        { id: "total-price", name: "Total Price", ordered: false },
        { id: "created", name: "Created", ordered: false },
        { id: "information", name: "Information", ordered: false },
        { id: "status", name: "Status", ordered: false },
      ],
      checkboxKeys: quotes.map((item) => {
        return item["_id"];
      }),
      data: quotes.map((item) => {
        const trip = item.trip;
        const integration = quotesStore.getIntegration(
          item._quotesIntegrationId || "",
          item._organizationId
        );

        if (!trip) return {};

        return {
          id: (
            <span style={{ color: palette.red?.plus5 }}>
              {item["_id"].split("-")[0]}
            </span>
          ),
          customer: item.tripPlanner?.name,
          passengers: trip._passengers,
          miles: `${metersToMiles(trip._meters)} mi`,
          departureDateTime: dateFormat(
            new Date(trip._startDate as string),
            "date"
          ),
          price: currencyFormatter(centsToDollars(item?._totalPrice || 0)),
          createdAt: item["_createdAt"]
            ? dateFormat(new Date(item["_createdAt"]), "datetime")
            : "",
          information:
            integration ||
            item._quotesIntegrationId ||
            item._bookingRequested ? (
              <FlexContainer direction="row" columnGap={2} align="center">
                {integration ? (
                  <OrganizationLogo src={integration.logo} />
                ) : null}
                {item._quotesIntegrationId ? (
                  <TTLDonut expirationDate={item._expirationDate} />
                ) : null}
                {item._bookingRequested ? <DocumentsIcon /> : null}
              </FlexContainer>
            ) : null,
          status: (
            <StatusBadge
              type={item["_status"]}
              isTablet={isTablet && !isMobile}
            />
          ),
        };
      }),
    };

    const TableComponent = isMobile ? MobileList : CommonTable;

    return (
      <TableContainer sx={{ marginTop: "16px" }} isMobile={isMobile}>
        <Box>
          <TableControls
            type="quote"
            dispatchLocations={dispatchLocations}
            filterTypeOptions={FILTER_TYPE_OPTIONS}
            acceptedStatuses={ACCEPTED_STATUSES}
          />
          <div className="table-container">
            {quotesStore.isFetching ? (
              <Loading />
            ) : (
              <TableComponent
                columnNames={tableConfig.columnNames}
                data={tableConfig.data}
                withCheckbox={!!filterStatus && filterStatus !== "ALL"}
                selectedRows={quotesStore.selectedItems}
                checkboxKeys={tableConfig.checkboxKeys}
                onRowClick={(id: string) => handlerOnSetTrip(id)}
                onRowsSelect={(rows: string[]) => {
                  quotesStore.addSelectedItems(rows);
                }}
                onRowsRemove={(rows: string[]) => {
                  quotesStore.removeSelectedItems(rows);
                }}
                hideColumns={isTablet}
                columnsToHide={[6]}
              />
            )}
          </div>
        </Box>
        {quotesResponse && (
          <TablePagination
            itemsTotal={quotesResponse.total}
            pageNumber={currentPage}
            perPage={ITEMS_PER_PAGE}
            currentOffset={ITEMS_PER_PAGE * (currentPage - 1)}
            nextOffset={quotesResponse.next || quotesResponse.total}
            onNextClick={() => setPage(currentPage + 1)}
            onPrevClick={() => setPage(currentPage - 1)}
            onToEndClick={() =>
              setPage(Math.ceil(quotesResponse.total / ITEMS_PER_PAGE))
            }
            onToBeginningClick={() => setPage(1)}
          />
        )}
      </TableContainer>
    );
  }
);

export default Table;
