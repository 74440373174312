import { styled } from "@mui/material/styles";
import OutlinedInput, {
  outlinedInputClasses,
} from "@mui/material/OutlinedInput";
import { inputBaseClasses } from "@mui/material/InputBase";

import palette from "../../theme/palette";
import { inputStyles } from "../../shared/inputStyles";

const TextInputStyled = styled(OutlinedInput)(() => {
  return {
    width: "100%",

    [`& .${outlinedInputClasses.input}`]: {
      ...inputStyles,
      borderColor: palette?.black?.plus70,
    },
    [`&.${inputBaseClasses.adornedEnd}`]: {
      // background: "red",
      paddingRight: "8px",
      [`.${outlinedInputClasses.input}`]: {
        borderRight: "0",
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
      [`.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: palette?.black?.plus10,
      },
    },
    [`.${inputBaseClasses.inputAdornedEnd}`]: {
      border: 0,
      padding: "9px 2px 10px 14px",
    },

    [`&.${inputBaseClasses.adornedEnd}`]: {
      [`.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: palette?.black?.plus70,
      },
    },
    [`& .${outlinedInputClasses.disabled}`]: {
      ...inputStyles,
      borderColor: palette?.black?.plus80,
      background: palette?.black?.plus85,
    },
  };
});

export default TextInputStyled;
