import { ContractStatus, ListContractsQuery } from "~/contracts/entity";

export const columnNames = [
  { id: "_ID", name: "Contract Number", ordered: false },
  { id: "CLIENT", name: "Client", ordered: false },
  { id: "BILLING_CYCLES", name: "Billing Cycles", ordered: false },
  { id: "ACV", name: "ACV", ordered: false },
  { id: "CREATED_AT", name: "Created", ordered: true },
  { id: "START_DATE", name: "Start Date", ordered: true },
  { id: "END_DATE", name: "End Date", ordered: true },
  { id: "RENEWAL_DATE", name: "Renewal Date", ordered: true },
  { id: "STATUS", name: "Status", ordered: false },
];

export const ITEMS_PER_PAGE = 10;

export const DEFAULT_SORT: { [key: string]: "asc" | "desc" } = {
  CREATED_AT: "desc",
};

export const DEFAULT_FILTER: ListContractsQuery = {
  status: ["PENDING", "ACTIVE"],
  createdDate: [null, null],
  startDate: [null, null],
  endDate: [null, null],
};

export const STATUS_FILTER_OPTIONS: { name: string; value: ContractStatus }[] =
  [
    { name: "Pending", value: "PENDING" },
    { name: "Active", value: "ACTIVE" },
    { name: "Expired", value: "EXPIRED" },
    { name: "Suspended", value: "SUSPENDED" },
    { name: "Canceled", value: "CANCELED" },
  ];
