import { makeAutoObservable } from "mobx";
import { Integration, QuoteIntegrationStatus } from "@busie/utils";
import { fetchQuotesIntegrations, updateIntegrationStatus } from "@busie/api";
import {
  quoteIntegrationsToMap,
  getQuotesIntegrationFromId,
} from "~/QuotesAndBookingsPage/shared/lib";

class QuoteIntegrationsStore {
  authToken?: string;
  integrations: Integration[] = [];
  pendingIntegrations: Integration[] = [];
  integrationMap: Map<string, Integration> = new Map();
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  async getIntegrations(organizationId: string): Promise<void> {
    this.loading = true;
    const response = await fetchQuotesIntegrations(this.authToken as string);
    this.integrationMap = quoteIntegrationsToMap(response);

    const integrations = response.integrations
      .map((integration) => {
        return getQuotesIntegrationFromId(
          integration.id,
          organizationId,
          this.integrationMap,
          response.organizationNames,
          response.organizationLogos
        );
      })
      .filter((x) => x != null) as Integration[];

    this.integrations = integrations.filter((i) => i.status !== "PENDING");
    this.pendingIntegrations = integrations.filter(
      (i) => i.status === "PENDING" && i.integrationType !== "SOURCE"
    );
    this.loading = false;
  }

  async approveIntegration(
    id: string,
    newStatus: QuoteIntegrationStatus = "ACTIVE"
  ): Promise<void> {
    await updateIntegrationStatus(this.authToken as string, id, newStatus);
    const integration = this.integrations.find((i) => i.id === id);
    if (integration) {
      this.integrations.splice(this.integrations.indexOf(integration), 1, {
        ...integration,
        status: newStatus,
      });
    }
  }

  async rejectIntegration(id: string): Promise<void> {
    updateIntegrationStatus(this.authToken as string, id, "DEACTIVATED");
    const integration = this.integrations.find((i) => i.id === id);
    if (integration) {
      this.integrations.splice(this.integrations.indexOf(integration), 1, {
        ...integration,
        status: "DEACTIVATED",
      });
    }
  }
}

export default new QuoteIntegrationsStore();
