import { styled } from "@mui/material/styles";

// import { Theme } from "@mui/system";

import {
  default as MuiTableHead,
  // TableHeadProps,
} from "@mui/material/TableHead";

// interface Props extends TableHeadProps {
//   theme?: Theme;
// }

const TableHeadStyled = styled(MuiTableHead)(() => {
  // const palette = props.theme?.palette;
  return {};
});

export default TableHeadStyled;
