import React, { FC, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { DrawerHeader, Drawer } from "@busie/ui-kit";
import RecipeInfoBlock from "~/rates/RateMenuPage/components/CookbookDetailsDrawer/RecipeInfoBlock";
import IngredientsList from "~/rates/RateMenuPage/components/CookbookDetailsDrawer/IngredientsList";
import store from "~/rates/RateMenuPage/store";
import { observer } from "mobx-react";
import { useRecipe } from "@busie/core";

interface Props {
  authToken: string;
}

const RECIPE_URL_KEY = "recipeId";

const RecipeDetailsDrawer: FC<React.PropsWithChildren<Props>> = observer(
  ({ authToken }) => {
    const { search } = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(search);
    const { activeRecipe, sortedActiveRecipeIngredients } = store.rates;

    const { data: recipe } = useRecipe(
      authToken,
      searchParams.get(RECIPE_URL_KEY) as string
    );

    useEffect(() => {
      if (!recipe) return;
      store.rates.setActiveRecipe(recipe);
    }, [recipe]);

    const handlerOnClose = () => {
      searchParams.delete(RECIPE_URL_KEY);
      history.replace({
        search: `?${searchParams.toString()}`,
      });
      store.rates.clearActiveRecipe();
    };

    return (
      <Drawer
        anchor="right"
        open={!!activeRecipe}
        onClose={handlerOnClose}
        width={700}
      >
        <DrawerHeader title="Details" onClose={handlerOnClose} />
        {activeRecipe && (
          <>
            <RecipeInfoBlock recipe={activeRecipe} />
            <IngredientsList ingredients={sortedActiveRecipeIngredients} />
          </>
        )}
      </Drawer>
    );
  }
);

export default RecipeDetailsDrawer;
