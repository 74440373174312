import React from "react";

import { Loading, Select } from "@busie/ui-kit";

import { useFetchDrivers } from "~/drivers/model";

interface Props {
  defaultValue?: string;
  onDriverSelect: (driver: string) => void;
}

export const DriverSelect: React.FC<React.PropsWithChildren<Props>> = ({
  onDriverSelect,
  defaultValue = "",
}) => {
  const { data: drivers = [], isFetching, isLoading } = useFetchDrivers({});

  if (isFetching || isLoading) {
    return <Loading size={24} padding="0px" />;
  }

  return (
    <Select
      items={drivers.map((driver) => ({
        name: `${driver.firstName} ${driver.lastName}`,
        value: driver._id,
      }))}
      label="Driver"
      placeholder="Select a driver.."
      onChange={(e) => onDriverSelect(e.target.value)}
      value={defaultValue}
      fullWidth
    />
  );
};
