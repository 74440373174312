import { Avatar } from "@mui/material";
import React from "react";
import { H3 } from "../typography";
import { FlexContainer } from "../FlexContainer";
import palette from "../theme/palette";

interface Props {
  name: string;
  imgSrc: string;
  size?: number;
  color?: string;
}

export const OrganizationMemberWithAvatar: React.FC<Props> = ({
  name,
  imgSrc,
  size = 32,
  color = palette.black.main,
}) => (
  <FlexContainer direction="row" columnGap={2} align="center" fullWidth>
    <Avatar alt={name} src={imgSrc} sx={{ height: size, width: size }} />
    <H3 color={color} margin="0">
      {name}
    </H3>
  </FlexContainer>
);
