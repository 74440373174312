import { styled } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";

import { Theme } from "@mui/system";
interface Props {
  theme?: Theme;
}

const RadioButtonStyled = styled(FormControlLabel)((props: Props) => {
  const palette = props.theme?.palette;
  return {
    ".MuiFormControlLabel-label": {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "150%",
      letterSpacing: "0.04em",
      color: palette?.black?.main,
    },
  };
});

export default RadioButtonStyled;
