import React from "react";
import { styled } from "@mui/system";

import {
  DrawerDetailsBlock,
  Button,
  PropertyString,
  ConfirmedStatusText,
  DeclinedStatusText,
  PendingStatusText,
  AcceptedStatusText,
  NoteText,
  FlexContainer,
  Text,
  Subtitle,
} from "@busie/ui-kit";
import { Quote, QuoteAndBookingStatus, Trip } from "@busie/utils";
import { createBooking, sendQuote, updateQuoteStatus } from "@busie/api";
import { useAmplitude } from "@busie/core";
import { notificationStore } from "@busie/features";
import { getTripType } from "~/QuotesAndBookingsPage/shared/lib";

import { StatusBlockStyled } from "./styled";
import { StatusUpdateConfirmation } from "./StatusUpdateConfirmation";
import { SendQuoteConfirmation } from "./SendQuoteConfirmation";

interface Props {
  trip: Trip;
  quote: Quote;
  quotesAuthToken: string;
  onUpdate: () => void;
  isCustomerView?: boolean;
}

const PendingValueStyled = styled("div")(() => {
  return {
    display: "flex",
    alignItems: "center",
    columnGap: "40px",
  };
});

export const QuoteStatusBlock: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const [newStatus, setNewStatus] = React.useState<QuoteAndBookingStatus>();
  const [showStatusChangePopup, setShowStatusChangePopup] =
    React.useState(false);
  const [showSendQuotePopup, setShowSendQuotePopup] = React.useState(false);
  const { track } = useAmplitude();

  const getEventMetadata = () => {
    const { trip, quote } = props;
    const { _destinationLocation: start } = trip._legs[0];
    const { _startLocation: end } = trip._legs[trip._legs.length - 1];

    return {
      numberOfPassengers: trip._passengers,
      quoteId: quote._id,
      tripId: trip._id,
      price: quote._totalPrice,
      isRoundTrip: getTripType(start, end) === "round_trip",
      numberOfLegs: trip._legs.length,
    };
  };

  const handleUpdate = async (
    status: QuoteAndBookingStatus | undefined,
    reason?: string
  ) => {
    if (!status) return;

    try {
      await updateQuoteStatus(
        props.quotesAuthToken,
        props.quote._id,
        status,
        reason
      );
      notificationStore.setNotification({
        type: "success",
        header: "Success",
        message: "Quote status updated successfully",
      });
    } catch (e) {
      notificationStore.setErrorNotification(
        "Could not update quote status",
        (e as Error).message || "An unknown error occured"
      );
    }

    switch (status) {
      case QuoteAndBookingStatus.ACCEPTED:
        track("quote accepted", getEventMetadata());
        // TODO: this should be removed when bookings are created asynchronously
        await createBooking(props.quotesAuthToken, props.quote._id);
        break;
      case QuoteAndBookingStatus.CONFIRMED:
        track("quote confirmation sent", getEventMetadata());
        break;
      case QuoteAndBookingStatus.DECLINED:
        track("quote declined", getEventMetadata());
        break;
    }

    await props.onUpdate();
    setShowStatusChangePopup(false);
  };

  const handleSendQuote = async () => {
    try {
      await sendQuote(props.quotesAuthToken, props.quote._id);

      track("quote sent", getEventMetadata());

      setShowSendQuotePopup(false);

      notificationStore.setNotification({
        type: "success",
        header: "Success",
        message: "Quote sent successfully",
      });
    } catch (e) {
      notificationStore.setErrorNotification(
        "Could not send quote",
        (e as Error).message || "An unknown error occurred"
      );
    }
  };

  const showStatusChangeConfirmation = async (
    status: QuoteAndBookingStatus
  ) => {
    setNewStatus(status);
    setShowStatusChangePopup(true);
  };

  const AcceptButton = () => (
    <Button
      async
      onClick={() =>
        showStatusChangeConfirmation(QuoteAndBookingStatus.ACCEPTED)
      }
    >
      Accept
    </Button>
  );

  const SendQuoteButton = () => (
    <Button
      onClick={() =>
        showStatusChangeConfirmation(QuoteAndBookingStatus.CONFIRMED)
      }
    >
      Send quote
    </Button>
  );

  const getStatusBlock = (status: QuoteAndBookingStatus) => {
    switch (status) {
      case QuoteAndBookingStatus.PENDING:
        return (
          <>
            <PendingStatusText />
            <SendQuoteButton />
          </>
        );
      case QuoteAndBookingStatus.CONFIRMED:
        return (
          <>
            <ConfirmedStatusText />
            <AcceptButton />
          </>
        );
      case QuoteAndBookingStatus.ACCEPTED:
        return <AcceptedStatusText />;
      case QuoteAndBookingStatus.DECLINED:
        return <DeclinedStatusText />;
      default:
        return <span>Could not determine status...</span>;
    }
  };

  return (
    <DrawerDetailsBlock noHeader>
      <StatusUpdateConfirmation
        oldStatus={props.quote._status}
        newStatus={newStatus as QuoteAndBookingStatus}
        onConfirm={async (reason) => await handleUpdate(newStatus, reason)}
        onCancel={() => setShowStatusChangePopup(false)}
        open={showStatusChangePopup}
        isNetworkQuote={!!props.quote._quotesIntegrationId}
      />
      <SendQuoteConfirmation
        open={showSendQuotePopup}
        onCancel={() => setShowSendQuotePopup(false)}
        onConfirm={async () => await handleSendQuote()}
      />
      <StatusBlockStyled>
        <FlexContainer direction="row" justify="space-between">
          <Subtitle>Status</Subtitle>
          <PendingValueStyled>
            {getStatusBlock(props.quote._status)}
          </PendingValueStyled>
        </FlexContainer>
        {![
          QuoteAndBookingStatus.PENDING,
          QuoteAndBookingStatus.CANCELED,
          QuoteAndBookingStatus.DECLINED,
        ].includes(props.quote._status) && (
          <PropertyString
            justify="space-between"
            margin="16px 0 5px"
            name="Quote confirmation email"
          >
            <Button
              typestyle="accent"
              onClick={() => setShowSendQuotePopup(true)}
            >
              Send Quote
            </Button>
          </PropertyString>
        )}
        {![
          QuoteAndBookingStatus.DECLINED,
          QuoteAndBookingStatus.CANCELED,
        ].includes(props.quote._status) && (
          <PropertyString
            justify="space-between"
            name="Decline Quote"
            margin="16px 0 5px"
          >
            <Button
              typestyle="secondary"
              async
              onClick={() =>
                showStatusChangeConfirmation(QuoteAndBookingStatus.DECLINED)
              }
            >
              Decline
            </Button>
          </PropertyString>
        )}
        {props.quote._reasonForDecline && props.quote._status === "DECLINED" && (
          <FlexContainer direction="column">
            <PropertyString
              justify="space-between"
              name="Reason For Decline"
              margin="16px 0 5px"
            >
              {props.quote._reasonForDecline}
            </PropertyString>
          </FlexContainer>
        )}
      </StatusBlockStyled>
    </DrawerDetailsBlock>
  );
};

export default QuoteStatusBlock;
