import React from "react";
import { Header, HeaderItem } from "./Header";

import Container from "./styled/Container";
import LayoutStyled from "./styled/LayoutStyled";

interface Props {
  children: React.ReactNode;
  isCustomer?: boolean;
  headerLinks?: HeaderItem[];
}

export const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isCustomer,
  headerLinks,
}) => {
  return (
    <LayoutStyled>
      <Header isCustomer={isCustomer} headerLinks={headerLinks} />
      <Container>{children}</Container>
    </LayoutStyled>
  );
};
