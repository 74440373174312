import { CreateQuoteData, createQuote } from "@busie/api";
import { useQuotesAndBookingsAuthToken } from "@busie/core";
import { Quote } from "@busie/utils";
import { useMutation } from "react-query";

export const useCreateQuote = () => {
  const authToken = useQuotesAndBookingsAuthToken();

  return useMutation(
    async (data: CreateQuoteData): Promise<Quote> =>
      createQuote(authToken, data)
  );
};
