import React, { SyntheticEvent, useState } from "react";
import { Checkbox, Icon } from "@mui/material";
import {
  FlexContainer,
  TableCell,
  TableRow,
  DeleteIcon,
  EditIcon,
} from "@busie/ui-kit";
import { Driver } from "@busie/utils";

interface Props {
  driver: Driver;
  isSelected: boolean;
  isSelectable: boolean;
  onDriverToggle?: (driver: Driver) => void;
  onEdit: () => void;
  onDelete: () => void;
  onClick: () => void;
}

export const DriversListItem: React.FC<React.PropsWithChildren<Props>> = ({
  driver,
  isSelected,
  isSelectable = true,
  onDriverToggle,
  onEdit,
  onDelete,
  onClick,
}) => {
  const [areButtonsVisible, setAreButtonsVisible] = useState(false);

  const handleEdit = (e: SyntheticEvent) => {
    e.stopPropagation();
    onEdit();
  };

  const handleDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <TableRow
      hover
      onMouseEnter={() => setAreButtonsVisible(true)}
      onMouseLeave={() => setAreButtonsVisible(false)}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      {isSelectable && (
        <TableCell>
          <Checkbox
            sx={{
              padding: 0,
            }}
            checked={!!isSelected}
            onClick={(e: SyntheticEvent) => e.stopPropagation()}
            onChange={() => onDriverToggle && onDriverToggle(driver)}
          />
        </TableCell>
      )}

      <TableCell>{`${driver.firstName} ${driver.lastName}`}</TableCell>

      <TableCell>{driver.email}</TableCell>

      <TableCell>{driver.phoneNumber}</TableCell>

      <TableCell width={130}>
        {areButtonsVisible && (
          <FlexContainer columnGap={2} justify="center" align="center">
            <Icon
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={handleEdit}
            >
              <EditIcon />
            </Icon>
            <Icon
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={handleDelete}
            >
              <DeleteIcon />
            </Icon>
          </FlexContainer>
        )}
      </TableCell>
    </TableRow>
  );
};
