import { BookingWithAttrs } from "@busie/api";
import { Booking, Customer, Trip } from "@busie/utils";

export const collectBookingsWithTrips = (
  bookings: Booking[],
  data: {
    trips: Trip[];
    customers: Customer[];
  }
): BookingWithAttrs[] => {
  const { trips, customers } = data;
  const tripIdMap = new Map(trips.map((trip) => [trip._id, trip]));
  const customerIdMap = new Map(
    customers.map((customer) => [customer.id, customer])
  );

  const items = bookings.reduce((items: BookingWithAttrs[], booking) => {
    const trip = tripIdMap.get(booking._quote.experienceId);

    if (trip) {
      const customer = customerIdMap.get(
        trip._mainContactId || trip._tripPlannerId
      );
      items.push({ trip, tripPlanner: customer, ...booking });
    }
    return items;
  }, []);
  return items;
};
