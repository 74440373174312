import React from "react";
import { VehicleIconProps as Props } from ".";

export const BlackCarLimousineIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 32C23 31.4477 23.4477 31 24 31H26C26.5523 31 27 31.4477 27 32V34C27 34.5523 26.5523 35 26 35H24C23.4477 35 23 34.5523 23 34V32Z"
        stroke="#222222"
      />
      <path
        d="M29 32C29 31.4477 29.4477 31 30 31H32C32.5523 31 33 31.4477 33 32V34C33 34.5523 32.5523 35 32 35H30C29.4477 35 29 34.5523 29 34V32Z"
        stroke="#222222"
      />
      <path
        d="M35 32C35 31.4477 35.4477 31 36 31H38C38.5523 31 39 31.4477 39 32V34C39 34.5523 38.5523 35 38 35H36C35.4477 35 35 34.5523 35 34V32Z"
        stroke="#222222"
      />
      <path
        d="M41 32C41 31.4477 41.4477 31 42 31H44.382C44.7607 31 45.107 31.214 45.2764 31.5528L46.2764 33.5528C46.6088 34.2177 46.1253 35 45.382 35H42C41.4477 35 41 34.5523 41 34V32Z"
        stroke="#222222"
      />
      <path
        d="M15.7261 31.2191C15.9034 31.0773 16.1237 31 16.3508 31H20C20.5523 31 21 31.4477 21 32V34C21 34.5523 20.5523 35 20 35H13.8508C12.9055 35 12.4879 33.8096 13.2261 33.2191L15.7261 31.2191Z"
        stroke="#222222"
      />
      <rect x="2" y="38" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="50" y="38" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="16" y="38" width="25" height="1" rx="0.5" fill="#222222" />
      <path
        d="M7.25 42H4C2.89543 42 2 41.1046 2 40V37.2346C2 36.2393 2.73186 35.3954 3.71716 35.2547L8.46899 34.5759C8.81685 34.5262 9.14548 34.3857 9.42179 34.1686L14.9122 29.8547C15.6169 29.301 16.4872 29 17.3835 29H26H44.3014C45.3552 29 46.3317 29.5529 46.8739 30.4565L48.5448 33.2413C48.8347 33.7245 49.315 34.063 49.8676 34.1735L52.3922 34.6784C53.3271 34.8654 54 35.6862 54 36.6396V40C54 41.1046 53.1046 42 52 42H47.75M13.875 42H27.5H41.125"
        stroke="#222222"
      />
      <circle cx="10.5" cy="42.5" r="3.5" stroke="#222222" />
      <circle cx="44.5" cy="42.5" r="3.5" stroke="#222222" />
    </svg>
  );
};

export default BlackCarLimousineIcon;
