import React, { useState } from "react";

import {
  Table,
  TableCellSelectAll,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  MultipleSelectModal,
} from "@busie/ui-kit";
import { DispatchLocation, GooglePlace } from "@busie/utils";

import { DispatchListItem } from "./DispatchListItem";
import xor from "lodash.xor";

interface Props {
  onDelete: (ids: string[]) => void;
  locations: DispatchLocation[];
  onEdit: (id: string, data: { name: string; location: GooglePlace }) => void;
}

export const DispatchLocationsList: React.FC<
  React.PropsWithChildren<Props>
> = ({ onDelete, locations, onEdit }) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const toggleLocation = (id: string) => {
    setSelectedRows((prev) => xor(prev, [id]));
  };

  return (
    <>
      <TableContainer fullwidth>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellSelectAll
                selectedRowsLength={selectedRows.length}
                dataLength={locations.length}
                onRowsSelect={() => {
                  setSelectedRows(locations.map((c) => c.id));
                }}
                onRowsRemove={() => {
                  setSelectedRows([]);
                }}
              />
              <TableCell>Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Created</TableCell>
              <TableCell width={120} />
            </TableRow>
          </TableHead>
          <tbody>
            {locations.map((location) => (
              <DispatchListItem
                key={location.id}
                location={location}
                isSelected={selectedRows.some(
                  (customerId) => customerId === location.id
                )}
                onToggle={toggleLocation}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ))}
          </tbody>
        </Table>
      </TableContainer>
      {selectedRows.length > 0 && (
        <MultipleSelectModal
          onDelete={() => onDelete(selectedRows)}
          onSelectAll={() => setSelectedRows(locations.map((l) => l.id))}
          onDeselectAll={() => setSelectedRows([])}
          selectedItemsCount={selectedRows.length}
        />
      )}
    </>
  );
};
