import { styled } from "@mui/system";

export const NotificationCount = styled("span")((props) => {
  return {
    position: "absolute",
    top: "8px",
    left: "-12px",
    width: "24px",
    height: "24px",
    background: props.theme.palette.red.main,
    borderRadius: "50%",
    zIndex: 101,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "10px",
  };
});
