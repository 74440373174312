import React from "react";

import { TableBodyProps } from "@mui/material/TableBody";

import TableBodyStyled from "./styled/TableBodyStyled";

const TableBody: React.FC<React.PropsWithChildren<TableBodyProps>> = (
  props: TableBodyProps
) => {
  return <TableBodyStyled {...props} />;
};

export default TableBody;
