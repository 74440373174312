import { Controller, useForm } from "react-hook-form";
import React, { useEffect } from "react";

import { CreateTripData, Experience } from "@busie/api";
import {
  Button,
  FlexContainer,
  FormContainer,
  H1,
  H3,
  NoteText,
  PropertyString,
  ScrollableFlexContainer,
  Text,
  ToggleSwitch,
  palette,
  theme,
} from "@busie/ui-kit";
import { useMediaQuery } from "@mui/material";

import { Modal } from "~/QuotesAndBookingsPage/shared/ui";

import { FormValues } from "./model";
import {
  LegContainer,
  duplicateTrip,
  useCreateQuote,
  useCreateTrip,
} from "~/QuotesAndBookingsPage/entity";
import {
  invalidateQnbQueries,
  useExperienceDispatchLocation,
} from "~/QuotesAndBookingsPage/model";
import { useCustomer } from "~/CustomersPage/entity/model";
import { dayjsExt } from "@busie/utils";
import { notificationStore } from "@busie/features";
import { useAmplitude } from "@busie/core";

interface Props {
  trip: Experience;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const Duplicate: React.FC<Props> = ({
  trip,
  isOpen,
  onClose,
  onSuccess,
}) => {
  /**
   * TODO: [BUSIE-1745] Add Map to Duplicate Trip feature.
   * TODO: [BUSIE-1746] Allow user to select main contact for quote when creating new quote for Duplicate Trip feature.
   * TODO: [BUSIE-1747] As a Charter User, I can change details about a trip when duplicating it before i submit the form.
   */
  const isMobile = useMediaQuery("@media (max-width: 950px)");

  const duplicatedTrip = duplicateTrip(trip);

  const createTripData: CreateTripData = {
    ...duplicatedTrip,
    legs: duplicatedTrip.legs.slice(1, -1),
  };

  const { data: dispatchLocation } = useExperienceDispatchLocation(
    trip.DISPATCH_ID
  );

  const { data: mainContact } = useCustomer(
    createTripData.mainContactId ?? createTripData.tripPlannerId
  );

  const { mutateAsync: createTrip } = useCreateTrip();
  const { mutateAsync: createQuote, isLoading: isCreatingNewQuote } =
    useCreateQuote();

  const { track } = useAmplitude();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      createTripData,
      createNewQuote: false,
    },
  });

  useEffect(() => () => reset(), [isOpen, reset]);

  const onSubmit = async (data: FormValues) => {
    const { createNewQuote, createTripData } = data;
    await createTrip(
      {
        ...createTripData,
        quoteId: createNewQuote ? undefined : trip.QUOTE_ID,
      },
      {
        onSuccess: async (result) => {
          if (createNewQuote)
            await createQuote({
              experienceId: result._id,
              locationId: result._dispatchId,
              contactId: result._mainContactId,
              startDate: result._startDate as string,
              organizationId: result._organizationId,
              customerGroupId: trip.GROUP.ID,
              customerGroupName: trip.GROUP.NAME,
              price: result._price as number,
              sourceId: trip.NETWORK_ID,
              quotesIntegrationId: trip.INTEGRATION_ID,
            });

          const notificationMessage = createNewQuote
            ? "Trip was duplicated for a new quote successfully."
            : "Trip was duplicated successfully.";

          notificationStore.setSuccessNotification(
            "Success!",
            notificationMessage +
              " Please allow a few seconds for these changes to appear."
          );

          track("trip duplicated", {
            newTripId: result._id,
            duplicatedTripId: trip._ID,
            quoteId: createNewQuote ? result._quoteId : trip.QUOTE_ID,
            createdNewQuote: createNewQuote,
          });

          invalidateQnbQueries();
          onSuccess();
        },
        onError: (e) => notificationStore.setNotificationFromError(e),
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <H1>Duplicate Trip</H1>
      <Text>Are you sure you want to duplicate this trip?</Text>

      <NoteText>
        A new trip will be created using the following details:
      </NoteText>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollableFlexContainer
          direction="column"
          rowGap={isMobile ? 2 : 3}
          minW={isMobile ? "375px" : "500px"}
          maxW="740px"
          py={4}
          sx={{
            margin: isMobile ? 0 : "16px auto 0",
            [theme.breakpoints.down("tablet")]: {
              padding: "15px 25px",
            },
          }}
          align="flex-start"
        >
          <FlexContainer direction="column" rowGap={0} px={2} fullWidth>
            <FormContainer title="Quote Settings">
              <Controller
                name="createNewQuote"
                control={control}
                render={({ field }) => (
                  <FlexContainer
                    direction="row"
                    columnGap={2}
                    align="center"
                    fullWidth
                  >
                    <H3
                      color={
                        field.value ? palette.black.plus60 : palette.black.main
                      }
                    >
                      Same Quote
                    </H3>

                    <ToggleSwitch
                      checked={field.value}
                      onChange={(ev) => field.onChange(ev.target.checked)}
                    />
                    <H3
                      color={
                        field.value ? palette.black.main : palette.black.plus60
                      }
                    >
                      Create new Quote
                    </H3>
                  </FlexContainer>
                )}
              />
            </FormContainer>

            <FormContainer title="Main Contact" fullWidth>
              <PropertyString name="Name">{mainContact?.name}</PropertyString>
              <PropertyString name="Email">
                <a href={`mailto:${mainContact?.email}`}>
                  {mainContact?.email}
                </a>
              </PropertyString>
              <PropertyString name="Phone number">
                +{mainContact?.countryCode} ({mainContact?.areaCode}){" "}
                {mainContact?.phoneNumber}
              </PropertyString>
            </FormContainer>

            <FormContainer title="Trip Information" fullWidth>
              <PropertyString name="Passengers amount">
                {createTripData.passengers}
              </PropertyString>
              <PropertyString name="Accessible seating">
                {createTripData.wheelchairs}
              </PropertyString>
              {createTripData.amenities.length ? (
                <PropertyString name="Amenities">
                  {createTripData.amenities.map((amenity) => (
                    <span>{amenity}</span>
                  ))}
                </PropertyString>
              ) : (
                ""
              )}
            </FormContainer>

            <FormContainer title="Route Information">
              <FlexContainer direction="column" rowGap={0}>
                <LegContainer
                  title="Leg 1"
                  departureDatetime=""
                  arrivalDatetime={dayjsExt(
                    createTripData.legs[0].departureDatetime
                  )
                    .add(15, "minutes")
                    .toISOString()}
                  addresses={[
                    dispatchLocation?.name ?? "Dispatch",
                    createTripData.legs[0].startLocation.address as string,
                  ]}
                />

                {createTripData.legs.map((leg, index) => (
                  <LegContainer
                    key={`leg-${index}`}
                    title={`Leg ${index + 2}`}
                    departureDatetime={leg.departureDatetime}
                    arrivalDatetime={leg.arrivalDatetime as string}
                    addresses={[
                      leg.startLocation.address as string,
                      leg.destinationLocation.address as string,
                    ]}
                  />
                ))}

                <LegContainer
                  title={`Leg ${createTripData.legs.length + 2}`}
                  departureDatetime={dayjsExt(
                    createTripData.legs[createTripData.legs.length - 1]
                      .arrivalDatetime
                  )
                    .add(15, "minutes")
                    .toISOString()}
                  arrivalDatetime=""
                  addresses={[
                    createTripData.legs[createTripData.legs.length - 1]
                      .destinationLocation.address as string,
                    dispatchLocation?.name ?? "Dispatch",
                  ]}
                />
              </FlexContainer>
            </FormContainer>
          </FlexContainer>
        </ScrollableFlexContainer>

        <FlexContainer direction="row" columnGap={2} pt={1} justify="flex-end">
          <Button
            typestyle="secondary"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={isSubmitting || isCreatingNewQuote}
            loading={isSubmitting || isCreatingNewQuote}
          >
            Duplicate
          </Button>
        </FlexContainer>
      </form>
    </Modal>
  );
};
