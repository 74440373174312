import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import IngredientCard from "./IngredientCard/IngredientCard";
import { palette, FlexContainer, Text } from "@busie/ui-kit";
import { styled } from "@mui/system";
import { Ingredient } from "@busie/utils";

interface Props {
  ingredients: Ingredient[];
}

const IngredientsTableHeader = styled(FlexContainer)(() => {
  return `
    width: 100%;
    background-color: ${palette?.black?.plus85};
  `;
});

const IngredientsListContainer = styled(FlexContainer)(
  () => `
  background-color: ${palette?.black?.plus80};
  p {
    text-align: left;
  }
  .ingredient-column {
    width: 120px;
  }
  .name-column {
    width: 245px;
  }
  .type-column {
    width: 150px;
  }
  .value-column {
    width: 110px;
  }
  .actions-column {
    opacity: 0;
    visibility: 'hidden';
    transition: opacity .2s ease;
  }
`
);

const IngredientContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  "&:hover .actions-column": { opacity: 1, visibility: "visible" },
}));

const IngredientsList: FC<React.PropsWithChildren<Props>> = observer(
  ({ ingredients }) => {
    return (
      <IngredientsListContainer direction="column" fullWidth>
        <IngredientsTableHeader px={3} py={2} align="center">
          <Text className="ingredient-column">Ingredient</Text>
          <Text className="name-column">Name</Text>
          <Text className="type-column">Type</Text>
          <Text className="value-column">Value</Text>
        </IngredientsTableHeader>
        <FlexContainer borderBetweenY direction="column" fullWidth>
          {ingredients.map((ingredient) => (
            <IngredientContainer key={ingredient.id}>
              <IngredientCard ingredient={ingredient} />
            </IngredientContainer>
          ))}
        </FlexContainer>
      </IngredientsListContainer>
    );
  }
);

export default IngredientsList;
