import { InvoiceVoidReason } from "@busie/utils";
import * as yup from "yup";

export const invoiceVoidReasonOptions: InvoiceVoidReason[] = [
  "CANCELLED_BOOKING",
  "CUSTOMER_CHANGE",
  "OTHER",
  "PRICE_CHANGE",
];

export const schema = yup.object({
  voidReason: yup
    .string()
    .oneOf<InvoiceVoidReason>(
      invoiceVoidReasonOptions,
      `Void reason must be one of: ${invoiceVoidReasonOptions.join(", ")}`
    )
    .required("Please select a reason"),
  voidReasonDetail: yup.string().when("voidReason", {
    is: "OTHER",
    then: yup.string().required("Please enter a reason"),
  }),
});
