import { styled } from "@mui/material/styles";

// import { Theme } from "@mui/system";

import {
  default as MuiTableBody,
  // TableBodyProps,
} from "@mui/material/TableBody";

// interface Props extends TableBodyProps {
//   theme?: Theme;
// }

const TableBodyStyled = styled(MuiTableBody)(() => {
  // const palette = props.theme?.palette;
  return {};
});

export default TableBodyStyled;
