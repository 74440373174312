import React from "react";

interface Props {
  color?: string;
}

export const DocumentsIcon: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const color = props.color || "#3B3B3B";
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 15V7.828C19.5 7.298 19.289 6.789 18.914 6.414L16.086 3.586C15.711 3.211 15.202 3 14.672 3H7.5C6.395 3 5.5 3.895 5.5 5V19C5.5 20.105 6.395 21 7.5 21H13.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.5 18L18.5 21L16.5 19"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 11H14.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 14H14.5"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 17H12.83"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 8H15.5C14.948 8 14.5 7.552 14.5 7V3"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
