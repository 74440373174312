import {
  // InputAdornment,
  // TextField,
  useMediaQuery,
} from "@mui/material";
// import { styled } from "@mui/system";
import xor from "lodash.xor";
import React, { useState } from "react";
// import { useLocation } from "react-router";

// import { useDebounce } from "@busie/core";
import {
  AddIcon,
  Button,
  FlexContainer,
  MultipleSelectModal,
  // SearchIcon,
  TableContainer,
  TableControls,
  // TablePagination,
  // palette,
} from "@busie/ui-kit";
import { Driver } from "@busie/utils";
import { notificationStore } from "@busie/features";

import { DriversList, DriversMobileList } from "~/drivers/entity";
import { CreateDriver, EditDriver } from "~/drivers/features";
import { DeleteDrivers } from "~/drivers/features/DeleteDrivers";
// import DriversFilter from "~/drivers/entity/DriversList/DriversFilter";
// import FilterBlock from "~/vehicles/Vehicles/components/FilterBlock";

interface Props {
  drivers: Driver[];
  onDriverClicked: (driver: Driver) => void;
}

// const TextFieldStyled = styled(TextField)(() => ({
//   minWidth: "250px",
//   input: {
//     paddingTop: "10px",
//     paddingBottom: "10px",
//   },
// }));

export const Table: React.FC<React.PropsWithChildren<Props>> = ({
  drivers,
  onDriverClicked,
}) => {
  const isMobile = useMediaQuery("@media (max-width: 960px)");
  const isTablet = useMediaQuery("@media (max-width: 1136px)");

  const [isCreateDriverOpen, setIsCreateDriverOpen] = useState(false);
  const [selectedDrivers, setSelectedDrivers] = useState<Driver[]>([]);
  const [driverToEdit, setDriverToEdit] = useState<Driver>();
  const [driversToDelete, setDriversToDelete] = useState<Driver[]>([]);

  const onToggleDriver = (driver: Driver) =>
    setSelectedDrivers((prev) => xor(prev, [driver]));

  const onSelectAllDrivers = () => setSelectedDrivers(drivers);

  const onDeselectAllDrivers = () => setSelectedDrivers([]);

  const onEditDriver = (driver: Driver) => setDriverToEdit(driver);

  const onDeleteDriver = (driver: Driver) => setDriversToDelete([driver]);

  const onDeleteDrivers = (drivers: Driver[]) => setDriversToDelete(drivers);

  // TOOD: this information should be passed from the parent component
  // const { search } = useLocation();
  // const searchValues = new URLSearchParams(search);

  // const [searchQuery, setSearchQuery] = useState(
  //   searchValues.get("search") || ""
  // );

  // const debouncedSearchValue = useDebounce(searchQuery, 500);

  // const [currentPage, setCurrentPage] = React.useState(1);
  // const skip = DRIVERS_PER_PAGE * (currentPage - 1);

  // const totalItems = driversData?.length || 0;

  // const onSearchChange = (value: string) => {
  //   setSearchQuery(value);
  // };

  return (
    <TableContainer fullwidth isMobile={isMobile} sx={{ marginTop: "16px" }}>
      <TableControls>
        {/* when filters and search are added: justify=space-between */}
        <FlexContainer columnGap={2} justify="flex-end" fullWidth>
          {/* <FilterBlock>
            {(onClose: () => void) => (
              <DriversFilter
                onClose={onClose}
                filters={{}}
                // TODO
                applyFilters={
                  () => undefined
                  // setTypesFilter(vehicleTypeIds)
                }
              />
            )}
          </FilterBlock> */}
          {/* TODO: Search is not implemented in the backend */}
          {/* <TextFieldStyled
            variant="outlined"
            placeholder="Search driver"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color={palette?.black?.plus30} />
                </InputAdornment>
              ),
            }}
          /> */}
          <Button
            startIcon={<AddIcon />}
            onClick={() => setIsCreateDriverOpen(true)}
          >
            Add driver
          </Button>
        </FlexContainer>
      </TableControls>

      {((isMobile || isTablet) && (
        <DriversMobileList
          drivers={drivers}
          onDriverClicked={onDriverClicked}
          onDeleteDriver={onDeleteDriver}
          onEditDriver={onEditDriver}
        />
      )) || (
        <DriversList
          drivers={drivers}
          selectedDrivers={selectedDrivers.map((driver) => driver._id)}
          onEditDriver={onEditDriver}
          onDeleteDriver={onDeleteDriver}
          onDriverClick={onDriverClicked}
          onToggleDriver={onToggleDriver}
          onSelectAllDrivers={onSelectAllDrivers}
          onDeselectAllDrivers={onDeselectAllDrivers}
        />
      )}

      {/* TODO: Pagination is not implemented in the backend */}
      {/* <TablePagination
        itemsTotal={totalItems}
        pageNumber={currentPage}
        perPage={DRIVERS_PER_PAGE}
        currentOffset={DRIVERS_PER_PAGE * (currentPage - 1)}
        nextOffset={driversData?.next || totalItems}
        onNextClick={() => setCurrentPage(currentPage + 1)}
        onPrevClick={() => setCurrentPage(currentPage - 1)}
        onToEndClick={() =>
          setCurrentPage(Math.ceil(totalItems / DRIVERS_PER_PAGE))
        }
        onToBeginningClick={() => setCurrentPage(1)}
      /> */}

      <CreateDriver
        isOpen={isCreateDriverOpen}
        onDriverCreated={(driver) => {
          notificationStore.setSuccessNotification(
            "Success!",
            `The driver ${driver.firstName} ${driver.lastName} was created`
          );
          setIsCreateDriverOpen(false);
        }}
        onClose={() => setIsCreateDriverOpen(false)}
      />

      {driverToEdit && (
        <EditDriver
          isOpen={!!driverToEdit}
          driver={driverToEdit}
          onClose={() => setDriverToEdit(undefined)}
          onDriverUpdated={(driver: Driver) => {
            // TODO: open the driver details drawer when driver is updated.
            //   -- blocked by the fact that the driver details drawer does not fetch the driver
            // onDriverClicked(driverToEdit);
            notificationStore.setSuccessNotification(
              "Success!",
              `The driver ${driver.firstName} ${driver.lastName} was updated`
            );
            setDriverToEdit(undefined);
          }}
        />
      )}

      {!!driversToDelete.length && (
        <DeleteDrivers
          isOpen={!!driversToDelete.length}
          drivers={driversToDelete}
          onClose={() => setDriversToDelete([])}
          onDriverDeleted={() => {
            notificationStore.setSuccessNotification(
              "Success!",
              `The driver${
                driversToDelete.length > 1 ? "s" : ""
              } ${driversToDelete
                .map((driver) => `${driver.firstName} ${driver.lastName}`)
                .join(", ")} ${
                driversToDelete.length > 1 ? "were" : "was"
              } deleted`
            );
            setDriversToDelete([]);
          }}
        />
      )}

      {(drivers.length && selectedDrivers.length > 0 && (
        <MultipleSelectModal
          onDelete={() => onDeleteDrivers(selectedDrivers)}
          onSelectAll={onSelectAllDrivers}
          onDeselectAll={onDeselectAllDrivers}
          selectedItemsCount={selectedDrivers.length}
        />
      )) ||
        null}
    </TableContainer>
  );
};
