import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const LinkStyled = styled(Link)(() => {
  return {
    textDecoration: "none",
  };
});

export default LinkStyled;
