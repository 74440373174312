import React, { useEffect } from "react";
import {
  Button,
  Divider,
  FlexContainer,
  palette,
  NoteText,
  Text,
  TextInput,
  FormContainer,
} from "@busie/ui-kit";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";

interface Props {
  hasPayouts: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  hasPayouts,
  onSubmit,
  onCancel,
}) => {
  // TODO: Display the payout structure based on a prop
  // NOTE: "Network" will not always be valid, but currently it is the only scenario
  const payoutOrg = hasPayouts ? "Network" : "Busie";
  const payoutTerms = hasPayouts ? "Revenue Share" : "'Offline Payment Fee'";

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<{ confirm?: string }>({
    mode: "onChange",
    defaultValues: {
      confirm: undefined,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Finalize Invoice">
      <Text>Are you sure you want to finalize this invoice?</Text>

      <NoteText>
        Note that by finalizing this invoice you are confirming the booking, and
        as such your organization will receive an invoice from {payoutOrg} based
        on the {payoutTerms} terms in your agreement with {payoutOrg}, with a
        due date 30 days after this invoice is due.
      </NoteText>

      <br />
      <NoteText>
        If the customer makes an online payment, through the hosted invoice page
        via Card or ACH, {payoutOrg}'s invoice to your organization will be
        voided.
      </NoteText>

      <br />
      <NoteText>
        If this invoice is{" "}
        <em>
          <b>voided</b>
        </em>
        , the invoice from {payoutOrg} will be voided as well.
      </NoteText>

      <br />
      <NoteText color={palette.red.main}>This action cannot be undone</NoteText>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer
          align="flex-start"
          direction="column"
          columnGap={2}
          fullWidth
        >
          <Controller
            name="confirm"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                fullWidth
                label="Type 'confirm' to continue"
                errorMessage={errors.confirm?.message}
              />
            )}
          />
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Finalize Invoice
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
