import * as yup from "yup";

export interface FormValues {
  contactId: string;
  contactName: string;
  contactEmail: string;
  subtotal: number;
  taxSurcharge: number;
  lastMinuteSurcharge: number;
  fuelSurcharge: number;
  markupPlatformFees: boolean;
  assigneeId: string;
}

export const schema = yup.object({
  contactId: yup.string().required(),
  contactName: yup.string().required(),
  contactEmail: yup.string().required(),
  subtotal: yup.number().min(0).required(),
  taxSurcharge: yup.number().min(0).required(),
  lastMinuteSurcharge: yup.number().min(0).required(),
  fuelSurcharge: yup.number().min(0).required(),
  markupPlatformFees: yup.boolean().required(),
  assigneeId: yup.string().required(),
});
