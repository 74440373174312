import { UsersMap } from "@busie/core";
import { DEFAULT_ASSIGNEE_FILTERS } from "../model";

const transform = (map: UsersMap) =>
  Object.entries(map).map(([id, user]) => ({
    value: id,
    name: user.name,
  }));

export const getFilterOptions = (map: UsersMap) =>
  DEFAULT_ASSIGNEE_FILTERS.concat(transform(map));
