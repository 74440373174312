import { VehicleTypes } from "@busie/utils";

export const mapVehicleTypeToName = (vehicleType: VehicleTypes): string => {
  return (
    {
      [VehicleTypes.BLACK_CAR]: "Black car",
      [VehicleTypes.DOUBLE_DECKER]: "Double decker",
      [VehicleTypes.ENTERTAINER_COACH]: "Entertainer coach",
      [VehicleTypes.EXECUTIVE_COACH]: "Executive coach",
      // [VehicleTypes.LIMOUSINE]: "Limousine",
      [VehicleTypes.MINI_BUS]: "Mini bus",
      [VehicleTypes.MOTOR_COACH]: "Motorcoach",
      [VehicleTypes.SCHOOL_BUS]: "School bus",
      [VehicleTypes.SLEEPER_COACH]: "Sleeper coach",
      [VehicleTypes.TROLLEY]: "Trolley",
      [VehicleTypes.VAN]: "Van",
    }[vehicleType] || ""
  );
};
