import * as yup from "yup";

const IS_REQUIRED = "is required";

const requiredString = () => yup.string().required(IS_REQUIRED);

export const schema = yup.object({
  email: requiredString().email("must be a valid email"),
  name: requiredString(),
  adminEmail: yup.string().email("must be a valid email").optional(),
  adminName: yup.string().optional(),
});
