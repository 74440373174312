import { styled } from "@mui/system";

interface Props {
  color?: string;
  weight?: number;
  margin?: string;
  // theme?: Theme;
}

const H2 = styled("h2")<Props>((props) => {
  const palette = props.theme.palette;
  return {
    "&": {
      color: props.color || palette.black.main,
      fontWeight: props.weight || "500",
      fontSize: "16px",
      lineHeight: "150%",
      margin: props.margin || "0",
    },
  };
});

export default H2;
