import axios from "axios";
import { User, env } from "@busie/utils";

export interface Member {
  email: string;
  name: string;
  picture: string;
  user_id: string;
}
export interface Profile {
  members: Member[];
}

const PROFILE_SERVICE_URL = `${env(
  "BUSIE_PROFILE_SERVICE_API_URL"
)}/user/profile`;

export const updateUserProfile = async (
  authToken: string,
  params: { enableQCN: boolean; name: string }
): Promise<User> => {
  const { data } = await axios.put(PROFILE_SERVICE_URL, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return data;
};

export const fetchUsers = async (authToken: string): Promise<Profile> => {
  const { data } = await axios.get(PROFILE_SERVICE_URL, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return data;
};
