import React from "react";
import { palette, theme } from "@busie/ui-kit";
import { Box, useMediaQuery } from "@mui/material";

const VehicleFormBlock: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const isMobile = useMediaQuery("@media (max-width: 1000px)");
  return (
    <Box
      sx={{
        backgroundColor: palette?.black?.plus100,
        border: "1px solid #D4D4D4",
        borderRadius: "4px",
        padding: "40px",
        width: isMobile ? "100%" : "63%",
        display: "flex",
        flexDirection: "column",
        gap: theme?.spacing(3),
        alignItems: "flex-start",
        marginBottom: theme?.spacing(2),
      }}
    >
      {children}
    </Box>
  );
};

export default VehicleFormBlock;
