import { styled, Theme } from "@mui/system";

interface Props {
  theme?: Theme;
}

const TableContainer = styled("div")((props: Props) => {
  const palette = props.theme?.palette;

  return {
    padding: 0,
    boxShadow: `inset 0px -1px 0px ${palette?.black?.plus70}, inset -1px 0px 0px ${palette?.black?.plus70}, inset 0px 1px 0px ${palette?.black?.plus70}, inset 1px 0px 0px ${palette?.black?.plus70}`,
    borderRadius: "0px 0px 4px 4px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "1360px",
    margin: "0 auto",
    ".table-container": {
      minHeight: "603px",
    },
    thead: {
      background: palette?.black?.plus80,
      tr: {
        border: `1px solid ${palette?.black?.plus70}`,
      },
      th: {
        padding: "8px 20px",
      },
    },
    tbody: {
      tr: {
        maxHeight: "56px",
        height: "56px",
      },
      td: {
        padding: "8px 20px",
      },
    },
    ".MuiTableRow-root:hover": {
      span: {
        opacity: 1,
      },
    },
  };
});

export default TableContainer;
