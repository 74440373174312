import {
  fetchCompanyHolidays,
  fetchQnBSettings,
  createCompanyHoliday,
  patchCompanyHoliday,
  deleteCompanyHoliday,
  createIntegration,
  patchQnBSetting,
} from "@busie/api";
import { FetchingStatus } from "@busie/core";
import { notificationStore } from "@busie/features";
import { CompanyHoliday } from "@busie/utils";
import { remove } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";

export interface SettingsFormData {
  lastMinuteSurge: number;
  fuelSurcharge: number;
  taxSurcharge: number;
  email: string;
  defaultSignerName: string;
  enablePlatformPricingMarkup: boolean;
}

class QnBSettingsStore {
  authToken: string | undefined = undefined;
  id: string | undefined = undefined;
  organizationId: string | undefined = undefined;
  email: string | undefined = undefined;
  defaultSignerName: string | undefined = undefined;
  companyHolidays: CompanyHoliday[] = [];
  lastMinuteSurge: number | undefined = undefined;
  fuelSurcharge: number | undefined = undefined;
  taxSurcharge: number | undefined = undefined;
  settingsFetchingStatus: FetchingStatus = "notFetched";
  holidayFormFetchingStatus: FetchingStatus = "notFetched";
  submittingStatus: FetchingStatus = "notFetched";
  enablePlatformPricingMarkup = false;

  constructor() {
    makeAutoObservable(this);
    this.submit = this.submit.bind(this);
  }

  setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  async fetchSettings(id: string) {
    if (!this.authToken) return;
    this.settingsFetchingStatus = "fetching";
    try {
      const settings = await fetchQnBSettings(this.authToken, id);
      this.id = settings.id;
      this.organizationId = settings.organizationId;
      this.email = settings.email;
      this.defaultSignerName = settings.defaultSignerName;
      this.companyHolidays = await fetchCompanyHolidays(this.authToken, id);
      this.lastMinuteSurge = settings.lastMinuteSurge;
      this.fuelSurcharge = settings.fuelSurcharge;
      this.taxSurcharge = settings.taxSurcharge;
      this.enablePlatformPricingMarkup =
        settings.enablePlatformPricingMarkup || false;
      this.settingsFetchingStatus = "fetched";
    } catch (e) {
      this.settingsFetchingStatus = "failedFetching";
      notificationStore.setNotificationFromError(e);
    }
  }

  resetHolidayFormFetchingStatus() {
    this.holidayFormFetchingStatus = "notFetched";
  }

  async addHoliday(holiday: CompanyHoliday) {
    if (!this.authToken || !this.id) return;
    this.holidayFormFetchingStatus = "fetching";
    try {
      await createCompanyHoliday(this.authToken, this.id, holiday);
      runInAction(() => {
        this.companyHolidays.push(holiday);
      });
      this.holidayFormFetchingStatus = "fetched";
    } catch (e) {
      this.holidayFormFetchingStatus = "failedFetching";
      notificationStore.setNotificationFromError(e);
    }
  }
  async editHoliday(holiday: CompanyHoliday) {
    if (!this.authToken || !this.id) return;
    try {
      await patchCompanyHoliday(this.authToken, this.id, holiday);
      runInAction(() => {
        this.companyHolidays = this.companyHolidays.map((h) =>
          h.id === holiday.id ? holiday : h
        );
      });
      notificationStore.setNotification({
        type: "success",
        header: "Done",
        message: `Successfully edited ${holiday.name}!`,
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  }
  async removeHoliday(holiday: CompanyHoliday) {
    if (!this.authToken) return;
    try {
      await deleteCompanyHoliday(this.authToken, holiday.id);
      runInAction(() => {
        remove(this.companyHolidays, (h) => h === holiday);
      });
      notificationStore.setNotification({
        type: "success",
        header: "Done",
        message: `Successfully deleted ${holiday.name}!`,
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  }

  async submit(data: SettingsFormData) {
    if (!this.authToken) return;
    try {
      let updated = false;
      this.submittingStatus = "fetching";
      const patchRequests = [];
      for (const key of Object.keys(data) as (keyof SettingsFormData)[]) {
        if (data[key] !== this[key]) {
          patchRequests.push(
            patchQnBSetting(key, this.authToken, this.id || "", data[key])
          );
          updated = true;
        }
      }
      await Promise.all(patchRequests);
      this.submittingStatus = "fetched";
      if (updated) {
        notificationStore.setNotification({
          type: "success",
          message: "Successfully updated the settings!",
        });
        await this.fetchSettings(this.organizationId || "");
      }
    } catch (e) {
      this.submittingStatus = "failedFetching";
      notificationStore.setNotificationFromError(e);
    }
  }

  async createIntegration(destinationId: string, revenueShareConfigId: string) {
    if (!this.authToken) {
      return;
    }

    try {
      const integration = await createIntegration(
        this.authToken,
        destinationId,
        revenueShareConfigId
      );
      return integration;
    } catch (e) {
      console.log(e);
    }
    return null;
  }
}

export default new QnBSettingsStore();
