import React from "react";

interface Props {
  color?: string;
  size?: number;
}
const RecliningSeatsIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = "#222",
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="reclining seats"
    >
      <path
        fill={color}
        stroke={color}
        d="M40.162 18.144a1 1 0 011.144-.342l.992.361a2 2 0 011.122 2.739l-7.617 16.013a1 1 0 01-1.463.4l-1.982-1.34a1 1 0 01-.439-.885l.245-4.249c.015-.26.081-.516.195-.752l1.925-3.997c.055-.115.122-.225.198-.328l5.68-7.62z"
      ></path>
      <path
        fill={color}
        stroke={color}
        d="M19.667 34.5a1 1 0 01.931-.44L30 35l4.38.876a1 1 0 01.773.738l.536 2.144A1 1 0 0134.72 40H22.333a1 1 0 01-.6-.2l-2.976-2.232a1 1 0 01-.232-1.355l1.142-1.713z"
      ></path>
      <circle cx="33.5" cy="36.5" r="1.5" fill="#fff" stroke={color}></circle>
      <path stroke={color} strokeLinecap="round" d="M42 18l.5-2"></path>
      <path
        fill={color}
        stroke={color}
        d="M41.684 9.949a1 1 0 011.265-.633l1.24.414a1 1 0 01.67 1.113l-.72 4.321a1 1 0 01-.986.836H42a1 1 0 01-1-1v-2.35c0-.43.07-.858.205-1.266l.479-1.435z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke={color}
        d="M36.26 17.34a2.5 2.5 0 01.9 3.42l-6.664 11.422A3.5 3.5 0 0127 33.887l-7.377-1.006a1 1 0 00-1.093.702l-2.262 7.5a3.5 3.5 0 01-3.866 2.45l-3.77-.56a2.5 2.5 0 11.735-4.946l1.634.243a1 1 0 001.104-.7l2.255-7.475a3.5 3.5 0 013.823-2.457l7.837 1.069a1 1 0 00.999-.487l5.821-9.98a2.5 2.5 0 013.42-.9z"
        clipRule="evenodd"
      ></path>
      <circle cx="36.5" cy="12.5" r="3.5" fill="#fff" stroke={color}></circle>
    </svg>
  );
};

export default RecliningSeatsIcon;
