import React from "react";
import palette from "../theme/palette";

interface Props {
  size?: number;
  color?: string;
}

export const ChevronDown: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const size = props.size || "24";
  return (
    <svg
      width={size}
      height={size}
      style={{ minWidth: size, minHeight: size }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10L12 14L16 10"
        stroke={props.color || palette.black.plus30}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
