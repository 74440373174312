import { styled } from "@mui/system";

const TimeFieldStyled = styled("div")(() => {
  return {
    width: "100%",
    maxWidth: "130px",
  };
});

export default TimeFieldStyled;
