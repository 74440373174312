import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import RateMenuPageStore from "~/rates/RateMenuPage/store";
import { IngredientListType } from "@busie/utils";

import TabsContainer from "~/rates/RateMenuPage/components/CreateRecipeModal/tabs/TabsContainer";
import TabContent from "~/rates/RateMenuPage/components/CreateRecipeModal/tabs/TabContent";
import Tab from "~/rates/RateMenuPage/components/CreateRecipeModal/tabs/Tab";

import IngredientsSelectionFormStyled from "~/rates/RateMenuPage/components/CreateRecipeModal/styled/IngredientsSelectionFormStyled";

import IngredientGroup from "~/rates/RateMenuPage/components/CreateRecipeModal/IngredientGroup";
import { useFlags } from "launchdarkly-react-client-sdk";

const IngredientsSelectionForm: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const { rolloutMinimumDayRate } =
      useFlags<{ rolloutMinimumDayRate: boolean }>();
    const store = RateMenuPageStore.createRecipeModal;

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setActiveStep(newValue);
    };

    const toggleIngredientGroup = (
      listName: IngredientListType,
      index: number
    ) => {
      store.toggleIngredientGroup(listName, index);
    };

    return (
      <IngredientsSelectionFormStyled>
        <TabsContainer value={activeStep} onChange={handleTabChange}>
          <Tab label="Base ingredients" />
          <Tab label="Side ingredients" />
        </TabsContainer>

        <TabContent value={activeStep} index={0}>
          {store.formData.ingredientList.base.map((item, i) => {
            return (
              <IngredientGroup
                key={i}
                group={item}
                groupName={"base"}
                groupIndex={i}
                toggle={() => toggleIngredientGroup("base", i)}
                disabled={
                  item.type === "DAILY_MINIMUM" && !rolloutMinimumDayRate
                }
              />
            );
          })}
        </TabContent>
        <TabContent value={activeStep} index={1}>
          {store.formData.ingredientList.side.map((item, i) => {
            return (
              <IngredientGroup
                key={i}
                group={item}
                groupName={"side"}
                groupIndex={i}
                toggle={() => toggleIngredientGroup("side", i)}
              />
            );
          })}
        </TabContent>
      </IngredientsSelectionFormStyled>
    );
  });

export default IngredientsSelectionForm;
