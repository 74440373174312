import React, { ReactElement } from "react";

import { FilterSelector } from "@busie/ui-kit";

import { SettingsContainerStyled } from "./styled";

const SettingsOptions = [
  {
    name: "Dispatch Locations",
    link: "/organization-settings/dispatch",
  },
  {
    name: "Drivers",
    link: "/organization-settings/drivers",
  },
  {
    name: "Trip",
    link: "/organization-settings/trip",
  },
  {
    name: "Embedded Trip Planner",
    link: "/organization-settings/embedded-trip-planner",
  },
  {
    name: "Quotes and Bookings",
    link: "/organization-settings/qnb",
  },
  {
    name: "Payments",
    link: "/organization-settings/payments",
  },
  {
    name: "Integration",
    link: "/organization-settings/integration",
  },
  {
    name: "Plan",
    link: "/organization-settings/plan",
  },
];

interface ContainerProps {
  value: string;
  controls?: ReactElement;
}

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  value,
  controls,
}) => {
  return (
    <SettingsContainerStyled bordered direction="column">
      <div className="settings-controls">
        <FilterSelector options={SettingsOptions} value={value} />
        {controls}
      </div>
      <div className="content">{children}</div>
    </SettingsContainerStyled>
  );
};
