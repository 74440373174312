import React, { useImperativeHandle, useRef, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import CustomerDataFieldsStyled from "../styled/CustomerDataFieldsStyled";

import store from "../../../store";
import { CustomerFormFieldsKeys } from "../../../store/customerForm/store.types";
import {
  NumberInputEvent,
  SelectChangeEvent,
  TextInputEvent,
  NumberInput,
  TextInput,
  FlexContainer,
  InfoIcon,
} from "@busie/ui-kit";
import TooltipIconStyled from "../../styled/TooltipIconStyled";
import { Tooltip } from "@mui/material";
import { useAmplitude } from "@busie/core";

export interface CustomerInputRefs {
  name: React.MutableRefObject<HTMLInputElement | undefined>;
  groupname: React.MutableRefObject<HTMLInputElement | undefined>;
  email: React.MutableRefObject<HTMLInputElement | undefined>;
  phoneNumber: React.MutableRefObject<HTMLInputElement | undefined>;
}
export interface CustomerDataRefHandle {
  focusField: (fieldName: keyof CustomerInputRefs) => void;
}

const CustomerDataFields = observer(
  (
    _: { [key: string]: string | number },
    ref: React.Ref<CustomerDataRefHandle>
  ) => {
    const customerForm = store.customerForm;

    const [initialInteraction, setInitialInteraction] = useState(false);
    const { track } = useAmplitude();

    const handlerOnChange = (
      e: TextInputEvent | NumberInputEvent | SelectChangeEvent,
      name: CustomerFormFieldsKeys
    ) => {
      if (!initialInteraction && !customerForm.editMode) {
        track("embedded trip planner initial interaction");
        setInitialInteraction(true);
      }

      customerForm.setCustomerFormValue(name, e.target.value);
    };

    const inputRefs: CustomerInputRefs = {
      name: useRef<HTMLInputElement>(),
      groupname: useRef<HTMLInputElement>(),
      email: useRef<HTMLInputElement>(),
      phoneNumber: useRef<HTMLInputElement>(),
    };

    useEffect(() => {
      inputRefs.name.current?.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      focusField: (fieldName) => {
        inputRefs[fieldName].current?.focus();
      },
    }));

    return (
      <CustomerDataFieldsStyled>
        <TextInput
          id="fullName"
          name="fullName"
          label="Full Name"
          inputRef={inputRefs.name}
          onChange={(e) => handlerOnChange(e, "name")}
          placeholder="Enter full name"
          value={customerForm.fields.name}
          errorMessage={customerForm.validation.errors.name}
          required
        />
        <TextInput
          id="groupname"
          name="groupname"
          label="Group name"
          inputRef={inputRefs.groupname}
          onChange={(e) => handlerOnChange(e, "groupname")}
          placeholder="Enter group name"
          value={customerForm.fields.groupname}
          errorMessage={customerForm.validation.errors.groupname}
          required
        />
        <TextInput
          id="Email"
          name="email"
          label="Email"
          inputRef={inputRefs.email}
          onChange={(e) => handlerOnChange(e, "email")}
          placeholder="Enter email"
          value={customerForm.fields.email}
          errorMessage={customerForm.validation.errors.email}
          required
        />
        <FlexContainer columnGap={2} align="center" w="240px" h={90}>
          <NumberInput
            id="phoneNumber"
            name="phoneNumber"
            label="Phone number"
            inputRef={inputRefs.phoneNumber}
            onChange={(e) => handlerOnChange(e, "phoneNumber")}
            placeholder="+1 (000) 000-0000"
            isPhoneField={true}
            value={customerForm.fields.phoneNumber}
            errorMessage={customerForm.validation.errors.phoneNumber}
            required
          />
          <Tooltip
            title={`Please include the country code of your phone number (i.e. '1' for US).
          Do not include special characters (i.e. +()-# etc.).
          If you find your phone number is not supported, please leave a note in the 'Additional Info' section on the next step.
          `}
            placement="top"
          >
            <TooltipIconStyled>
              <InfoIcon size={24} />
            </TooltipIconStyled>
          </Tooltip>
        </FlexContainer>
        <label>
          Required fields are marked with <span>*</span>
        </label>
      </CustomerDataFieldsStyled>
    );
  },
  {
    forwardRef: true,
  }
);

export default CustomerDataFields;
