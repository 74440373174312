import { env } from "@busie/utils";
import { useAuthToken } from "../use-auth-token";

const defaultAuthTokenConf = {
  domain: env("BUSIE_AUTH_DOMAIN"),
  clientId: env("BUSIE_CLIENT_ID"),
  redirect_uri: window.location.origin,
};

export const useCustomerAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://customers.getbusie.com",
  });
};

export const useDispatchLocationsAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://dispatch-locations.getbusie.com",
  });
};

export const usePaymentsAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://payments.getbusie.com",
  });
};

export const usePlansAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://plans.getbusie.com",
  });
};

export const useProfileAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://profile.getbusie.com",
  });
};

export const useQuotesAndBookingsAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://quotes-and-bookings.getbusie.com",
  });
};

export const useRatesAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://rates.getbusie.com",
  });
};

export const useVehiclesAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://vehicles.getbusie.com",
  });
};

export const useTripPlannerAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://trip-planner.getbusie.com",
  });
};

export const usePathfinderAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://pathfinder.getbusie.com",
  });
};

export const useTripsAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://trips.getbusie.com",
  });
};

export const useDriversAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://drivers.getbusie.com",
  });
};

export const useChefAuthToken = (): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: "https://chef.getbusie.com",
  });
};
