import { useMutation } from "react-query";

import { useQuotesAndBookingsAuthToken } from "@busie/core";
import { SendContractDto } from "@busie/utils";
import { sendContract } from "@busie/api";

export const useSendContract = () => {
  const qnbAuthToken = useQuotesAndBookingsAuthToken();

  const mutation = useMutation(
    async (data: { sendContractData: SendContractDto; bookingId: string }) => {
      await sendContract(qnbAuthToken, data.sendContractData, data.bookingId);
      // TODO: invalidate qnb queries.
    }
  );

  return mutation;
};
