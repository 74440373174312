import * as yup from "yup";

export const validationSchema = yup.object({
  organization: yup.object({
    value: yup.string().required("is required"),
  }),
  name: yup
    .string()
    .required("is required")
    .test({
      test: (value) => {
        const names = (value || "").trim().split(" ");
        return names.length >= 2;
      },
      message: "Specify both the first and the last name",
    })
    .test({
      test: (value) => {
        const names = (value || "").trim().split(" ");
        return (
          names[0].length >= 2 && (names[names.length - 1]?.length || 0) >= 2
        );
      },
      message: "The first and the last name should have at least 2 characters",
    }),
  email: yup
    .string()
    .required("is required")
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      { message: "Email is invalid" }
    ),
  phoneNumber: yup
    .string()
    .nullable()
    .required("is required")
    .matches(/^\+[0-9]+$/, {
      message: "Phone number must contain only digits",
    })
    .min(6, "Phone number must be at least 6 digits long"),
});
