import { styled } from "@mui/system";
import { palette } from "@busie/ui-kit";

const WeekDayStyled = styled("div")(() => {
  return {
    height: "40px",
    backgroundColor: "transparent",
    flexGrow: 1,
    color: palette?.black?.plus20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});

export default WeekDayStyled;
