export const Icon: React.FC = () => {
  return (
    <svg
      width="224"
      height="224"
      viewBox="0 0 224 224"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="224" height="224" fill="#C4C4C4" />
      <ellipse
        cx="140.429"
        cy="93.5715"
        rx="25.7143"
        ry="0.857143"
        fill="#D4D4D4"
      />
      <path
        d="M114.714 65.8572C114.714 64.5949 115.738 63.5715 117 63.5715H160.198C161.188 63.5715 162.065 64.208 162.371 65.1486L166.03 76.369C166.105 76.5978 166.143 76.837 166.143 77.0776V87.5715C166.143 88.8339 165.12 89.8572 163.857 89.8572H117C115.738 89.8572 114.714 88.8339 114.714 87.5715V65.8572Z"
        fill="#222222"
        stroke="#222222"
        stroke-width="0.857143"
      />
      <rect
        width="9.14286"
        height="9.14286"
        rx="1.14286"
        transform="matrix(-1 0 0 1 151.286 69.2859)"
        fill="white"
        stroke="#FF0018"
        stroke-width="0.857143"
      />
      <path
        d="M163.714 69.2859H157C156.369 69.2859 155.857 69.7976 155.857 70.4287V77.2859C155.857 77.9171 156.369 78.4287 157 78.4287H166.143V77.0786C166.143 76.8374 166.105 76.5976 166.03 76.3683L163.714 69.2859Z"
        fill="white"
        stroke="#FF0018"
        stroke-width="0.857143"
      />
      <rect
        width="9.14286"
        height="9.14286"
        rx="1.14286"
        transform="matrix(-1 0 0 1 139.858 69.2859)"
        fill="white"
        stroke="#FF0018"
        stroke-width="0.857143"
      />
      <rect
        width="9.14286"
        height="9.14286"
        rx="1.14286"
        transform="matrix(-1 0 0 1 128.429 69.2859)"
        fill="white"
        stroke="#FF0018"
        stroke-width="0.857143"
      />
      <path
        d="M114.286 81.1298C114.286 81.0975 114.327 81.0816 114.35 81.1043C114.451 81.2038 114.595 81.3187 114.712 81.3187C114.834 81.3187 114.967 81.2092 115.07 81.1023C115.093 81.0789 115.133 81.0944 115.133 81.127V82.16C115.133 82.3681 115.185 82.5283 115.287 82.6406C115.389 82.7529 115.54 82.8091 115.738 82.8091C115.936 82.8091 116.088 82.7529 116.194 82.6406C116.299 82.5283 116.352 82.3681 116.352 82.16V81.1257C116.352 81.0933 116.393 81.0774 116.416 81.1003C116.517 81.2007 116.664 81.3187 116.783 81.3187C116.903 81.3187 117.034 81.2122 117.136 81.1067C117.159 81.0833 117.2 81.0989 117.2 81.1314V82.155C117.2 82.4655 117.134 82.7282 117.001 82.9429C116.869 83.1576 116.691 83.3194 116.466 83.4284C116.245 83.5375 115.997 83.592 115.723 83.592C115.449 83.592 115.203 83.5391 114.985 83.4334C114.77 83.3244 114.6 83.1625 114.474 82.9478C114.349 82.7298 114.286 82.4655 114.286 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M118.417 81.1298C118.417 81.0975 118.457 81.0816 118.48 81.1043C118.581 81.2038 118.725 81.3187 118.843 81.3187C118.965 81.3187 119.098 81.2092 119.201 81.1023C119.223 81.0789 119.264 81.0944 119.264 81.127V82.16C119.264 82.3681 119.315 82.5283 119.418 82.6406C119.52 82.7529 119.67 82.8091 119.868 82.8091C120.067 82.8091 120.219 82.7529 120.324 82.6406C120.43 82.5283 120.483 82.3681 120.483 82.16V81.1257C120.483 81.0933 120.524 81.0774 120.547 81.1003C120.648 81.2007 120.794 81.3187 120.913 81.3187C121.034 81.3187 121.164 81.2122 121.267 81.1067C121.29 81.0833 121.33 81.0989 121.33 81.1314V82.155C121.33 82.4655 121.264 82.7282 121.132 82.9429C121 83.1576 120.821 83.3194 120.597 83.4284C120.376 83.5375 120.128 83.592 119.854 83.592C119.579 83.592 119.333 83.5391 119.115 83.4334C118.901 83.3244 118.73 83.1625 118.605 82.9478C118.479 82.7298 118.417 82.4655 118.417 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M122.547 81.1298C122.547 81.0975 122.588 81.0816 122.611 81.1043C122.712 81.2038 122.856 81.3187 122.973 81.3187C123.095 81.3187 123.228 81.2092 123.331 81.1023C123.354 81.0789 123.394 81.0944 123.394 81.127V82.16C123.394 82.3681 123.446 82.5283 123.548 82.6406C123.65 82.7529 123.801 82.8091 123.999 82.8091C124.197 82.8091 124.349 82.7529 124.455 82.6406C124.561 82.5283 124.613 82.3681 124.613 82.16V81.1257C124.613 81.0933 124.654 81.0774 124.677 81.1003C124.778 81.2007 124.925 81.3187 125.044 81.3187C125.164 81.3187 125.295 81.2122 125.397 81.1067C125.42 81.0833 125.461 81.0989 125.461 81.1314V82.155C125.461 82.4655 125.395 82.7282 125.262 82.9429C125.13 83.1576 124.952 83.3194 124.727 83.4284C124.506 83.5375 124.258 83.592 123.984 83.592C123.71 83.592 123.464 83.5391 123.246 83.4334C123.031 83.3244 122.861 83.1625 122.735 82.9478C122.61 82.7298 122.547 82.4655 122.547 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M126.659 81.1298C126.659 81.0975 126.699 81.0816 126.722 81.1043C126.823 81.2038 126.967 81.3187 127.084 81.3187C127.207 81.3187 127.339 81.2092 127.442 81.1023C127.465 81.0789 127.506 81.0944 127.506 81.127V82.16C127.506 82.3681 127.557 82.5283 127.659 82.6406C127.762 82.7529 127.912 82.8091 128.11 82.8091C128.308 82.8091 128.46 82.7529 128.566 82.6406C128.672 82.5283 128.725 82.3681 128.725 82.16V81.1257C128.725 81.0933 128.765 81.0774 128.788 81.1003C128.89 81.2007 129.036 81.3187 129.155 81.3187C129.275 81.3187 129.406 81.2122 129.509 81.1067C129.531 81.0833 129.572 81.0989 129.572 81.1314V82.155C129.572 82.4655 129.506 82.7282 129.374 82.9429C129.242 83.1576 129.063 83.3194 128.839 83.4284C128.617 83.5375 128.37 83.592 128.095 83.592C127.821 83.592 127.575 83.5391 127.357 83.4334C127.142 83.3244 126.972 83.1625 126.847 82.9478C126.721 82.7298 126.659 82.4655 126.659 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M130.77 81.1298C130.77 81.0975 130.81 81.0816 130.833 81.1043C130.934 81.2038 131.078 81.3187 131.195 81.3187C131.318 81.3187 131.45 81.2092 131.553 81.1023C131.576 81.0789 131.617 81.0944 131.617 81.127V82.16C131.617 82.3681 131.668 82.5283 131.77 82.6406C131.873 82.7529 132.023 82.8091 132.221 82.8091C132.42 82.8091 132.571 82.7529 132.677 82.6406C132.783 82.5283 132.836 82.3681 132.836 82.16V81.1257C132.836 81.0933 132.876 81.0774 132.899 81.1003C133.001 81.2007 133.147 81.3187 133.266 81.3187C133.387 81.3187 133.517 81.2122 133.62 81.1067C133.642 81.0833 133.683 81.0989 133.683 81.1314V82.155C133.683 82.4655 133.617 82.7282 133.485 82.9429C133.353 83.1576 133.174 83.3194 132.95 83.4284C132.728 83.5375 132.481 83.592 132.206 83.592C131.932 83.592 131.686 83.5391 131.468 83.4334C131.253 83.3244 131.083 83.1625 130.958 82.9478C130.832 82.7298 130.77 82.4655 130.77 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M134.881 81.1298C134.881 81.0975 134.921 81.0816 134.944 81.1043C135.045 81.2038 135.189 81.3187 135.306 81.3187C135.429 81.3187 135.561 81.2092 135.665 81.1023C135.687 81.0789 135.728 81.0944 135.728 81.127V82.16C135.728 82.3681 135.779 82.5283 135.881 82.6406C135.984 82.7529 136.134 82.8091 136.332 82.8091C136.531 82.8091 136.682 82.7529 136.788 82.6406C136.894 82.5283 136.947 82.3681 136.947 82.16V81.1257C136.947 81.0933 136.987 81.0774 137.01 81.1003C137.112 81.2007 137.258 81.3187 137.377 81.3187C137.498 81.3187 137.628 81.2122 137.731 81.1067C137.753 81.0833 137.794 81.0989 137.794 81.1314V82.155C137.794 82.4655 137.728 82.7282 137.596 82.9429C137.464 83.1576 137.285 83.3194 137.061 83.4284C136.839 83.5375 136.592 83.592 136.317 83.592C136.043 83.592 135.797 83.5391 135.579 83.4334C135.364 83.3244 135.194 83.1625 135.069 82.9478C134.943 82.7298 134.881 82.4655 134.881 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M138.992 81.1298C138.992 81.0975 139.032 81.0816 139.055 81.1043C139.156 81.2038 139.301 81.3187 139.418 81.3187C139.54 81.3187 139.673 81.2092 139.776 81.1023C139.798 81.0789 139.839 81.0944 139.839 81.127V82.16C139.839 82.3681 139.89 82.5283 139.993 82.6406C140.095 82.7529 140.245 82.8091 140.444 82.8091C140.642 82.8091 140.794 82.7529 140.9 82.6406C141.005 82.5283 141.058 82.3681 141.058 82.16V81.1257C141.058 81.0933 141.099 81.0774 141.122 81.1003C141.223 81.2007 141.37 81.3187 141.488 81.3187C141.609 81.3187 141.74 81.2122 141.842 81.1067C141.865 81.0833 141.905 81.0989 141.905 81.1314V82.155C141.905 82.4655 141.839 82.7282 141.707 82.9429C141.575 83.1576 141.397 83.3194 141.172 83.4284C140.951 83.5375 140.703 83.592 140.429 83.592C140.155 83.592 139.909 83.5391 139.691 83.4334C139.476 83.3244 139.306 83.1625 139.18 82.9478C139.055 82.7298 138.992 82.4655 138.992 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M143.103 81.1298C143.103 81.0975 143.144 81.0816 143.167 81.1043C143.268 81.2038 143.412 81.3187 143.529 81.3187C143.651 81.3187 143.784 81.2092 143.887 81.1023C143.91 81.0789 143.951 81.0944 143.951 81.127V82.16C143.951 82.3681 144.002 82.5283 144.104 82.6406C144.207 82.7529 144.357 82.8091 144.555 82.8091C144.753 82.8091 144.905 82.7529 145.011 82.6406C145.117 82.5283 145.169 82.3681 145.169 82.16V81.1257C145.169 81.0933 145.21 81.0774 145.233 81.1003C145.334 81.2007 145.481 81.3187 145.6 81.3187C145.72 81.3187 145.851 81.2122 145.953 81.1067C145.976 81.0833 146.017 81.0989 146.017 81.1314V82.155C146.017 82.4655 145.951 82.7282 145.819 82.9429C145.686 83.1576 145.508 83.3194 145.283 83.4284C145.062 83.5375 144.814 83.592 144.54 83.592C144.266 83.592 144.02 83.5391 143.802 83.4334C143.587 83.3244 143.417 83.1625 143.291 82.9478C143.166 82.7298 143.103 82.4655 143.103 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M147.214 81.1298C147.214 81.0975 147.255 81.0816 147.278 81.1043C147.379 81.2038 147.523 81.3187 147.64 81.3187C147.762 81.3187 147.895 81.2092 147.998 81.1023C148.021 81.0789 148.061 81.0944 148.061 81.127V82.16C148.061 82.3681 148.113 82.5283 148.215 82.6406C148.317 82.7529 148.468 82.8091 148.666 82.8091C148.864 82.8091 149.016 82.7529 149.122 82.6406C149.228 82.5283 149.28 82.3681 149.28 82.16V81.1257C149.28 81.0933 149.321 81.0774 149.344 81.1003C149.445 81.2007 149.592 81.3187 149.711 81.3187C149.831 81.3187 149.962 81.2122 150.064 81.1067C150.087 81.0833 150.128 81.0989 150.128 81.1314V82.155C150.128 82.4655 150.062 82.7282 149.929 82.9429C149.797 83.1576 149.619 83.3194 149.394 83.4284C149.173 83.5375 148.925 83.592 148.651 83.592C148.377 83.592 148.131 83.5391 147.913 83.4334C147.698 83.3244 147.528 83.1625 147.402 82.9478C147.277 82.7298 147.214 82.4655 147.214 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M151.325 81.1298C151.325 81.0975 151.365 81.0816 151.388 81.1043C151.489 81.2038 151.634 81.3187 151.751 81.3187C151.873 81.3187 152.006 81.2092 152.109 81.1023C152.131 81.0789 152.172 81.0944 152.172 81.127V82.16C152.172 82.3681 152.223 82.5283 152.326 82.6406C152.428 82.7529 152.578 82.8091 152.777 82.8091C152.975 82.8091 153.127 82.7529 153.233 82.6406C153.338 82.5283 153.391 82.3681 153.391 82.16V81.1257C153.391 81.0933 153.432 81.0774 153.455 81.1003C153.556 81.2007 153.703 81.3187 153.821 81.3187C153.942 81.3187 154.073 81.2122 154.175 81.1067C154.198 81.0833 154.238 81.0989 154.238 81.1314V82.155C154.238 82.4655 154.172 82.7282 154.04 82.9429C153.908 83.1576 153.73 83.3194 153.505 83.4284C153.284 83.5375 153.036 83.592 152.762 83.592C152.488 83.592 152.242 83.5391 152.024 83.4334C151.809 83.3244 151.639 83.1625 151.513 82.9478C151.388 82.7298 151.325 82.4655 151.325 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M155.436 81.1298C155.436 81.0975 155.477 81.0816 155.5 81.1043C155.601 81.2038 155.745 81.3187 155.862 81.3187C155.984 81.3187 156.117 81.2092 156.22 81.1023C156.243 81.0789 156.284 81.0944 156.284 81.127V82.16C156.284 82.3681 156.335 82.5283 156.437 82.6406C156.54 82.7529 156.69 82.8091 156.888 82.8091C157.086 82.8091 157.238 82.7529 157.344 82.6406C157.45 82.5283 157.502 82.3681 157.502 82.16V81.1257C157.502 81.0933 157.543 81.0774 157.566 81.1003C157.667 81.2007 157.814 81.3187 157.933 81.3187C158.053 81.3187 158.184 81.2122 158.286 81.1067C158.309 81.0833 158.35 81.0989 158.35 81.1314V82.155C158.35 82.4655 158.284 82.7282 158.152 82.9429C158.019 83.1576 157.841 83.3194 157.616 83.4284C157.395 83.5375 157.147 83.592 156.873 83.592C156.599 83.592 156.353 83.5391 156.135 83.4334C155.92 83.3244 155.75 83.1625 155.625 82.9478C155.499 82.7298 155.436 82.4655 155.436 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M159.547 81.1298C159.547 81.0975 159.588 81.0816 159.611 81.1043C159.712 81.2038 159.856 81.3187 159.973 81.3187C160.095 81.3187 160.228 81.2092 160.331 81.1023C160.354 81.0789 160.394 81.0944 160.394 81.127V82.16C160.394 82.3681 160.446 82.5283 160.548 82.6406C160.65 82.7529 160.801 82.8091 160.999 82.8091C161.197 82.8091 161.349 82.7529 161.455 82.6406C161.561 82.5283 161.613 82.3681 161.613 82.16V81.1257C161.613 81.0933 161.654 81.0774 161.677 81.1003C161.778 81.2007 161.925 81.3187 162.044 81.3187C162.164 81.3187 162.295 81.2122 162.397 81.1067C162.42 81.0833 162.461 81.0989 162.461 81.1314V82.155C162.461 82.4655 162.395 82.7282 162.262 82.9429C162.13 83.1576 161.952 83.3194 161.727 83.4284C161.506 83.5375 161.258 83.592 160.984 83.592C160.71 83.592 160.464 83.5391 160.246 83.4334C160.031 83.3244 159.861 83.1625 159.735 82.9478C159.61 82.7298 159.547 82.4655 159.547 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M163.659 81.1298C163.659 81.0975 163.699 81.0816 163.722 81.1043C163.823 81.2038 163.967 81.3187 164.084 81.3187C164.207 81.3187 164.339 81.2092 164.442 81.1023C164.465 81.0789 164.506 81.0944 164.506 81.127V82.16C164.506 82.3681 164.557 82.5283 164.659 82.6406C164.762 82.7529 164.912 82.8091 165.11 82.8091C165.308 82.8091 165.46 82.7529 165.566 82.6406C165.672 82.5283 165.725 82.3681 165.725 82.16V81.1257C165.725 81.0933 165.765 81.0774 165.788 81.1003C165.89 81.2007 166.036 81.3187 166.155 81.3187C166.275 81.3187 166.406 81.2122 166.509 81.1067C166.531 81.0833 166.572 81.0989 166.572 81.1314V82.155C166.572 82.4655 166.506 82.7282 166.374 82.9429C166.242 83.1576 166.063 83.3194 165.839 83.4284C165.617 83.5375 165.37 83.592 165.095 83.592C164.821 83.592 164.575 83.5391 164.357 83.4334C164.142 83.3244 163.972 83.1625 163.847 82.9478C163.721 82.7298 163.659 82.4655 163.659 82.155V81.1298Z"
        fill="#FF0018"
      />
      <path
        d="M116.385 82.1995C116.387 82.2311 116.425 82.245 116.447 82.2217C116.538 82.1218 116.657 82.0054 116.775 82.0054C116.898 82.0054 117.031 82.1149 117.134 82.2217C117.157 82.2451 117.198 82.2295 117.198 82.197V81.287C117.198 81.0792 117.249 80.9193 117.351 80.8071C117.454 80.695 117.605 80.6389 117.803 80.6389C118.002 80.6389 118.154 80.695 118.26 80.8071C118.366 80.9193 118.419 81.0792 118.419 81.287V82.1983C118.419 82.2307 118.46 82.2465 118.483 82.2238C118.584 82.1235 118.731 82.0054 118.85 82.0054C118.971 82.0054 119.102 82.1119 119.205 82.2174C119.228 82.2406 119.268 82.225 119.268 82.1926V81.292C119.268 80.9819 119.202 80.7197 119.07 80.5053C118.937 80.2909 118.759 80.1293 118.533 80.0204C118.312 79.9116 118.063 79.8571 117.788 79.8571C117.514 79.8571 117.267 79.9099 117.049 80.0155C116.833 80.1243 116.663 80.2859 116.537 80.5003C116.411 80.7181 116.348 80.9819 116.348 81.292L116.385 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M120.516 82.1995C120.517 82.2311 120.556 82.245 120.577 82.2217C120.668 82.1218 120.787 82.0054 120.906 82.0054C121.028 82.0054 121.161 82.1149 121.265 82.2217C121.287 82.2451 121.328 82.2295 121.328 82.197V81.287C121.328 81.0792 121.379 80.9193 121.482 80.8071C121.585 80.695 121.735 80.6389 121.934 80.6389C122.133 80.6389 122.285 80.695 122.391 80.8071C122.497 80.9193 122.55 81.0792 122.55 81.287V82.1983C122.55 82.2307 122.59 82.2465 122.613 82.2238C122.715 82.1235 122.862 82.0054 122.981 82.0054C123.102 82.0054 123.233 82.1119 123.336 82.2174C123.358 82.2406 123.399 82.225 123.399 82.1926V81.292C123.399 80.9819 123.333 80.7197 123.2 80.5053C123.068 80.2909 122.889 80.1293 122.664 80.0204C122.442 79.9116 122.194 79.8571 121.919 79.8571C121.644 79.8571 121.398 79.9099 121.179 80.0155C120.964 80.1243 120.793 80.2859 120.668 80.5003C120.542 80.7181 120.479 80.9819 120.479 81.292L120.516 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M124.627 82.1995C124.628 82.2311 124.667 82.245 124.689 82.2217C124.78 82.1218 124.899 82.0054 125.017 82.0054C125.14 82.0054 125.273 82.1149 125.376 82.2217C125.399 82.2451 125.439 82.2295 125.439 82.197V81.287C125.439 81.0792 125.491 80.9193 125.593 80.8071C125.696 80.695 125.847 80.6389 126.045 80.6389C126.244 80.6389 126.396 80.695 126.502 80.8071C126.608 80.9193 126.661 81.0792 126.661 81.287V82.1983C126.661 82.2307 126.702 82.2465 126.725 82.2238C126.826 82.1235 126.973 82.0054 127.092 82.0054C127.213 82.0054 127.344 82.1119 127.447 82.2174C127.47 82.2406 127.51 82.225 127.51 82.1926V81.292C127.51 80.9819 127.444 80.7197 127.312 80.5053C127.179 80.2909 127 80.1293 126.775 80.0204C126.553 79.9116 126.305 79.8571 126.03 79.8571C125.756 79.8571 125.509 79.9099 125.29 80.0155C125.075 80.1243 124.905 80.2859 124.779 80.5003C124.653 80.7181 124.59 80.9819 124.59 81.292L124.627 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M128.738 82.1995C128.739 82.2311 128.778 82.245 128.8 82.2217C128.891 82.1218 129.01 82.0054 129.128 82.0054C129.251 82.0054 129.384 82.1149 129.487 82.2217C129.51 82.2451 129.55 82.2295 129.55 82.197V81.287C129.55 81.0792 129.602 80.9193 129.704 80.8071C129.807 80.695 129.958 80.6389 130.156 80.6389C130.355 80.6389 130.507 80.695 130.613 80.8071C130.719 80.9193 130.772 81.0792 130.772 81.287V82.1983C130.772 82.2307 130.813 82.2465 130.836 82.2238C130.937 82.1235 131.084 82.0054 131.203 82.0054C131.324 82.0054 131.455 82.1119 131.558 82.2174C131.581 82.2406 131.621 82.225 131.621 82.1926V81.292C131.621 80.9819 131.555 80.7197 131.423 80.5053C131.29 80.2909 131.111 80.1293 130.886 80.0204C130.664 79.9116 130.416 79.8571 130.141 79.8571C129.867 79.8571 129.62 79.9099 129.401 80.0155C129.186 80.1243 129.016 80.2859 128.89 80.5003C128.764 80.7181 128.701 80.9819 128.701 81.292L128.738 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M132.849 82.1995C132.851 82.2311 132.889 82.245 132.911 82.2217C133.002 82.1218 133.121 82.0054 133.239 82.0054C133.362 82.0054 133.495 82.1149 133.598 82.2217C133.621 82.2451 133.661 82.2295 133.661 82.197V81.287C133.661 81.0792 133.713 80.9193 133.815 80.8071C133.918 80.695 134.069 80.6389 134.267 80.6389C134.466 80.6389 134.618 80.695 134.724 80.8071C134.83 80.9193 134.883 81.0792 134.883 81.287V82.1983C134.883 82.2307 134.924 82.2465 134.947 82.2238C135.048 82.1235 135.195 82.0054 135.314 82.0054C135.435 82.0054 135.566 82.1119 135.669 82.2174C135.692 82.2406 135.732 82.225 135.732 82.1926V81.292C135.732 80.9819 135.666 80.7197 135.534 80.5053C135.401 80.2909 135.222 80.1293 134.997 80.0204C134.775 79.9116 134.527 79.8571 134.252 79.8571C133.978 79.8571 133.731 79.9099 133.512 80.0155C133.297 80.1243 133.127 80.2859 133.001 80.5003C132.875 80.7181 132.812 80.9819 132.812 81.292L132.849 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M136.961 82.1995C136.962 82.2311 137.001 82.245 137.022 82.2217C137.113 82.1218 137.232 82.0054 137.35 82.0054C137.473 82.0054 137.606 82.1149 137.709 82.2217C137.732 82.2451 137.773 82.2295 137.773 82.197V81.287C137.773 81.0792 137.824 80.9193 137.927 80.8071C138.029 80.695 138.18 80.6389 138.379 80.6389C138.577 80.6389 138.73 80.695 138.835 80.8071C138.941 80.9193 138.994 81.0792 138.994 81.287V82.1983C138.994 82.2307 139.035 82.2465 139.058 82.2238C139.159 82.1235 139.306 82.0054 139.426 82.0054C139.546 82.0054 139.678 82.1119 139.78 82.2174C139.803 82.2406 139.844 82.225 139.844 82.1926V81.292C139.844 80.9819 139.777 80.7197 139.645 80.5053C139.513 80.2909 139.334 80.1293 139.109 80.0204C138.887 79.9116 138.638 79.8571 138.364 79.8571C138.089 79.8571 137.842 79.9099 137.624 80.0155C137.409 80.1243 137.238 80.2859 137.112 80.5003C136.986 80.7181 136.924 80.9819 136.924 81.292L136.961 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M141.072 82.1995C141.073 82.2311 141.112 82.245 141.133 82.2217C141.224 82.1218 141.343 82.0054 141.461 82.0054C141.584 82.0054 141.717 82.1149 141.82 82.2217C141.843 82.2451 141.884 82.2295 141.884 82.197V81.287C141.884 81.0792 141.935 80.9193 142.038 80.8071C142.14 80.695 142.291 80.6389 142.49 80.6389C142.688 80.6389 142.84 80.695 142.946 80.8071C143.052 80.9193 143.105 81.0792 143.105 81.287V82.1983C143.105 82.2307 143.146 82.2465 143.169 82.2238C143.27 82.1235 143.417 82.0054 143.537 82.0054C143.657 82.0054 143.789 82.1119 143.891 82.2174C143.914 82.2406 143.955 82.225 143.955 82.1926V81.292C143.955 80.9819 143.888 80.7197 143.756 80.5053C143.623 80.2909 143.445 80.1293 143.22 80.0204C142.998 79.9116 142.749 79.8571 142.475 79.8571C142.2 79.8571 141.953 79.9099 141.735 80.0155C141.52 80.1243 141.349 80.2859 141.223 80.5003C141.097 80.7181 141.034 80.9819 141.034 81.292L141.072 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M145.183 82.1995C145.184 82.2311 145.223 82.245 145.244 82.2217C145.335 82.1218 145.454 82.0054 145.573 82.0054C145.695 82.0054 145.828 82.1149 145.932 82.2217C145.954 82.2451 145.995 82.2295 145.995 82.197V81.287C145.995 81.0792 146.046 80.9193 146.149 80.8071C146.252 80.695 146.402 80.6389 146.601 80.6389C146.8 80.6389 146.952 80.695 147.058 80.8071C147.164 80.9193 147.217 81.0792 147.217 81.287V82.1983C147.217 82.2307 147.257 82.2465 147.28 82.2238C147.382 82.1235 147.529 82.0054 147.648 82.0054C147.769 82.0054 147.9 82.1119 148.003 82.2174C148.025 82.2406 148.066 82.225 148.066 82.1926V81.292C148.066 80.9819 148 80.7197 147.867 80.5053C147.735 80.2909 147.556 80.1293 147.331 80.0204C147.109 79.9116 146.861 79.8571 146.586 79.8571C146.311 79.8571 146.065 79.9099 145.846 80.0155C145.631 80.1243 145.46 80.2859 145.335 80.5003C145.209 80.7181 145.146 80.9819 145.146 81.292L145.183 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M149.294 82.1995C149.295 82.2311 149.334 82.245 149.355 82.2217C149.446 82.1218 149.565 82.0054 149.683 82.0054C149.806 82.0054 149.939 82.1149 150.042 82.2217C150.065 82.2451 150.106 82.2295 150.106 82.197V81.287C150.106 81.0792 150.157 80.9193 150.26 80.8071C150.362 80.695 150.513 80.6389 150.712 80.6389C150.91 80.6389 151.063 80.695 151.168 80.8071C151.274 80.9193 151.327 81.0792 151.327 81.287V82.1983C151.327 82.2307 151.368 82.2465 151.391 82.2238C151.492 82.1235 151.639 82.0054 151.759 82.0054C151.879 82.0054 152.011 82.1119 152.113 82.2174C152.136 82.2406 152.177 82.225 152.177 82.1926V81.292C152.177 80.9819 152.11 80.7197 151.978 80.5053C151.846 80.2909 151.667 80.1293 151.442 80.0204C151.22 79.9116 150.971 79.8571 150.697 79.8571C150.422 79.8571 150.175 79.9099 149.957 80.0155C149.742 80.1243 149.571 80.2859 149.445 80.5003C149.319 80.7181 149.257 80.9819 149.257 81.292L149.294 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M153.405 82.1995C153.406 82.2311 153.445 82.245 153.466 82.2217C153.558 82.1218 153.676 82.0054 153.795 82.0054C153.917 82.0054 154.05 82.1149 154.154 82.2217C154.176 82.2451 154.217 82.2295 154.217 82.197V81.287C154.217 81.0792 154.268 80.9193 154.371 80.8071C154.474 80.695 154.624 80.6389 154.823 80.6389C155.022 80.6389 155.174 80.695 155.28 80.8071C155.386 80.9193 155.439 81.0792 155.439 81.287V82.1983C155.439 82.2307 155.479 82.2465 155.502 82.2238C155.604 82.1235 155.751 82.0054 155.87 82.0054C155.991 82.0054 156.122 82.1119 156.225 82.2174C156.247 82.2406 156.288 82.225 156.288 82.1926V81.292C156.288 80.9819 156.222 80.7197 156.089 80.5053C155.957 80.2909 155.778 80.1293 155.553 80.0204C155.331 79.9116 155.083 79.8571 154.808 79.8571C154.533 79.8571 154.287 79.9099 154.068 80.0155C153.853 80.1243 153.682 80.2859 153.557 80.5003C153.431 80.7181 153.368 80.9819 153.368 81.292L153.405 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M157.516 82.1995C157.517 82.2311 157.556 82.245 157.577 82.2217C157.668 82.1218 157.787 82.0054 157.906 82.0054C158.028 82.0054 158.161 82.1149 158.265 82.2217C158.287 82.2451 158.328 82.2295 158.328 82.197V81.287C158.328 81.0792 158.379 80.9193 158.482 80.8071C158.585 80.695 158.735 80.6389 158.934 80.6389C159.133 80.6389 159.285 80.695 159.391 80.8071C159.497 80.9193 159.55 81.0792 159.55 81.287V82.1983C159.55 82.2307 159.59 82.2465 159.613 82.2238C159.715 82.1235 159.862 82.0054 159.981 82.0054C160.102 82.0054 160.233 82.1119 160.336 82.2174C160.358 82.2406 160.399 82.225 160.399 82.1926V81.292C160.399 80.9819 160.333 80.7197 160.2 80.5053C160.068 80.2909 159.889 80.1293 159.664 80.0204C159.442 79.9116 159.194 79.8571 158.919 79.8571C158.644 79.8571 158.398 79.9099 158.179 80.0155C157.964 80.1243 157.793 80.2859 157.668 80.5003C157.542 80.7181 157.479 80.9819 157.479 81.292L157.516 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M161.627 82.1995C161.628 82.2311 161.667 82.245 161.689 82.2217C161.78 82.1218 161.899 82.0054 162.017 82.0054C162.14 82.0054 162.273 82.1149 162.376 82.2217C162.399 82.2451 162.439 82.2295 162.439 82.197V81.287C162.439 81.0792 162.491 80.9193 162.593 80.8071C162.696 80.695 162.847 80.6389 163.045 80.6389C163.244 80.6389 163.396 80.695 163.502 80.8071C163.608 80.9193 163.661 81.0792 163.661 81.287V82.1983C163.661 82.2307 163.702 82.2465 163.725 82.2238C163.826 82.1235 163.973 82.0054 164.092 82.0054C164.213 82.0054 164.344 82.1119 164.447 82.2174C164.47 82.2406 164.51 82.225 164.51 82.1926V81.292C164.51 80.9819 164.444 80.7197 164.312 80.5053C164.179 80.2909 164 80.1293 163.775 80.0204C163.553 79.9116 163.305 79.8571 163.03 79.8571C162.756 79.8571 162.509 79.9099 162.29 80.0155C162.075 80.1243 161.905 80.2859 161.779 80.5003C161.653 80.7181 161.59 80.9819 161.59 81.292L161.627 82.1995Z"
        fill="#FF0018"
      />
      <path
        d="M114.714 81.5715L115.202 82.9178C115.386 83.4233 116.102 83.4189 116.279 82.9112L117.141 80.4398C117.315 79.9411 118.014 79.925 118.211 80.4151L119.278 83.0732C119.471 83.5558 120.157 83.5499 120.342 83.0639L121.384 80.3299C121.58 79.8154 122.319 79.8487 122.468 80.3787L123.184 82.9224C123.328 83.4353 124.034 83.4891 124.254 83.0039L125.482 80.2997C125.695 79.8302 126.372 79.8609 126.541 80.3478L127.481 83.043C127.653 83.5349 128.339 83.5595 128.545 83.0811L129.79 80.1946C130.003 79.7003 130.719 79.748 130.865 80.2662L131.612 82.9224C131.756 83.4353 132.462 83.4891 132.683 83.0039L133.867 80.3952C134.085 79.9135 134.785 79.9619 134.935 80.4692L135.672 82.9595C135.822 83.4668 136.522 83.5152 136.741 83.0335L137.982 80.2997C138.195 79.8302 138.872 79.8609 139.041 80.3478L139.949 82.9499C140.124 83.4532 140.832 83.4634 141.022 82.9653L142.071 80.214C142.263 79.7117 142.979 79.7276 143.148 80.2378L144.031 82.9052C144.2 83.4154 144.916 83.4313 145.108 82.929L146.143 80.214C146.334 79.7117 147.05 79.7276 147.219 80.2378L148.103 82.9052C148.272 83.4154 148.987 83.4313 149.179 82.929L150.214 80.214C150.406 79.7117 151.121 79.7276 151.29 80.2378L152.174 82.9052C152.343 83.4154 153.059 83.4313 153.25 82.929L154.285 80.214C154.477 79.7117 155.193 79.7276 155.362 80.2378L156.245 82.9052C156.414 83.4154 157.13 83.4313 157.322 82.929L158.357 80.214C158.548 79.7117 159.264 79.7276 159.433 80.2378L160.33 82.9461C160.498 83.4516 161.205 83.4735 161.403 82.9792L162.47 80.3215C162.662 79.8434 163.339 79.8434 163.53 80.3215L164.602 82.9918C164.8 83.4845 165.504 83.4647 165.674 82.9617L166.143 81.5715V87.8572C166.143 88.9618 165.247 89.8572 164.143 89.8572H116.714C115.61 89.8572 114.714 88.9618 114.714 87.8572V81.5715Z"
        fill="#FF0018"
      />
      <path
        d="M165.714 83.8572L162.143 83.8572C161.827 83.8572 161.571 84.113 161.571 84.4286C161.571 84.7442 161.827 85 162.143 85H165.714V83.8572Z"
        fill="white"
      />
      <path
        d="M115.286 88.4287L112.715 88.4287V89.5716H115.286V88.4287Z"
        fill="#222222"
      />
      <path
        d="M114.714 65.8572C114.714 64.5949 115.738 63.5715 117 63.5715H160.198C161.188 63.5715 162.065 64.208 162.371 65.1486L166.03 76.369C166.105 76.5978 166.143 76.837 166.143 77.0776V87.5715C166.143 88.8339 165.12 89.8572 163.857 89.8572H117C115.738 89.8572 114.714 88.8339 114.714 87.5715V65.8572Z"
        stroke="#222222"
        stroke-width="0.857143"
      />
      <circle
        cx="4.57143"
        cy="4.57143"
        r="4.57143"
        transform="matrix(-1 0 0 1 157 84.1431)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="0.857143"
      />
      <circle
        cx="1.14286"
        cy="1.14286"
        r="1.14286"
        transform="matrix(-1 0 0 1 153.572 87.5715)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="0.857143"
      />
      <circle
        cx="4.57143"
        cy="4.57143"
        r="4.57143"
        transform="matrix(-1 0 0 1 131.858 84.1431)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="0.857143"
      />
      <circle
        cx="1.14286"
        cy="1.14286"
        r="1.14286"
        transform="matrix(-1 0 0 1 128.429 87.5715)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="0.857143"
      />
      <path
        d="M108.224 89.5715H111.053H112.429C112.174 89.3126 111.664 88.6705 111.053 88.1734C110.288 87.552 109.66 87.6499 109.065 87.1637C108.294 86.5344 108.754 87.9011 107.912 87.3696C107.582 87.1618 107.298 87.2386 106.457 86.3066C105.616 85.3745 105.233 86.0614 105.768 86.8381C106.303 87.6148 104.543 86.7621 103.714 86.0763C103.054 85.5307 103.102 87.319 104.249 88.1734C105.768 89.3051 107.069 89.5715 108.224 89.5715Z"
        fill="#EDEDED"
      />
      <ellipse
        cx="65.1431"
        cy="137.857"
        rx="38.5714"
        ry="1.28571"
        fill="#D4D4D4"
      />
      <path
        d="M26.5717 96.2857C26.5717 94.3922 28.1067 92.8572 30.0002 92.8572H94.7977C96.2817 92.8572 97.5973 93.8119 98.0573 95.2228L103.546 112.053C103.657 112.397 103.715 112.755 103.715 113.116V128.857C103.715 130.751 102.179 132.286 100.286 132.286H30.0002C28.1067 132.286 26.5717 130.751 26.5717 128.857V96.2857Z"
        fill="#222222"
        stroke="#222222"
        stroke-width="1.28571"
      />
      <rect
        width="13.7143"
        height="13.7143"
        rx="1.71429"
        transform="matrix(-1 0 0 1 81.429 101.429)"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.28571"
      />
      <path
        d="M100.072 101.429H90.0004C89.0537 101.429 88.2861 102.196 88.2861 103.143V113.429C88.2861 114.375 89.0537 115.143 90.0004 115.143H103.715V113.118C103.715 112.756 103.657 112.396 103.545 112.052L100.072 101.429Z"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.28571"
      />
      <rect
        width="13.7143"
        height="13.7143"
        rx="1.71429"
        transform="matrix(-1 0 0 1 64.2863 101.429)"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.28571"
      />
      <rect
        width="13.7143"
        height="13.7143"
        rx="1.71429"
        transform="matrix(-1 0 0 1 47.1432 101.429)"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.28571"
      />
      <path
        d="M25.9291 119.194C25.9291 119.146 25.9898 119.122 26.0243 119.156C26.1758 119.305 26.3922 119.478 26.568 119.478C26.7514 119.478 26.9504 119.314 27.105 119.153C27.1389 119.118 27.2 119.141 27.2 119.19V120.74C27.2 121.052 27.2768 121.292 27.4304 121.461C27.584 121.629 27.8095 121.713 28.1068 121.713C28.4041 121.713 28.632 121.629 28.7905 121.461C28.9491 121.292 29.0284 121.052 29.0284 120.74V119.188C29.0284 119.14 29.0894 119.116 29.1239 119.15C29.2756 119.301 29.4955 119.478 29.6737 119.478C29.8546 119.478 30.0507 119.318 30.2044 119.16C30.2384 119.125 30.2993 119.148 30.2993 119.197V120.732C30.2993 121.198 30.2002 121.592 30.002 121.914C29.8038 122.236 29.5363 122.479 29.1993 122.642C28.8673 122.806 28.4957 122.888 28.0845 122.888C27.6732 122.888 27.3041 122.808 26.977 122.65C26.655 122.486 26.3998 122.244 26.2115 121.921C26.0232 121.594 25.9291 121.198 25.9291 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M32.125 119.194C32.125 119.146 32.1857 119.122 32.2203 119.156C32.3717 119.305 32.5882 119.478 32.7639 119.478C32.9473 119.478 33.1463 119.314 33.3009 119.153C33.3348 119.118 33.3959 119.141 33.3959 119.19V120.74C33.3959 121.052 33.4727 121.292 33.6263 121.461C33.7799 121.629 34.0054 121.713 34.3027 121.713C34.6 121.713 34.8279 121.629 34.9865 121.461C35.145 121.292 35.2243 121.052 35.2243 120.74V119.188C35.2243 119.14 35.2853 119.116 35.3198 119.15C35.4715 119.301 35.6915 119.478 35.8696 119.478C36.0505 119.478 36.2466 119.318 36.4003 119.16C36.4343 119.125 36.4952 119.148 36.4952 119.197V120.732C36.4952 121.198 36.3961 121.592 36.1979 121.914C35.9997 122.236 35.7322 122.479 35.3952 122.642C35.0633 122.806 34.6916 122.888 34.2804 122.888C33.8691 122.888 33.5 122.808 33.173 122.65C32.8509 122.486 32.5957 122.244 32.4074 121.921C32.2191 121.594 32.125 121.198 32.125 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M38.3209 119.194C38.3209 119.146 38.3816 119.122 38.4162 119.156C38.5677 119.305 38.7841 119.478 38.9598 119.478C39.1432 119.478 39.3422 119.314 39.4969 119.153C39.5308 119.118 39.5919 119.141 39.5919 119.19V120.74C39.5919 121.052 39.6687 121.292 39.8223 121.461C39.9759 121.629 40.2013 121.713 40.4986 121.713C40.7959 121.713 41.0238 121.629 41.1824 121.461C41.3409 121.292 41.4202 121.052 41.4202 120.74V119.188C41.4202 119.14 41.4812 119.116 41.5157 119.15C41.6674 119.301 41.8874 119.478 42.0655 119.478C42.2464 119.478 42.4425 119.318 42.5962 119.16C42.6302 119.125 42.6912 119.148 42.6912 119.197V120.732C42.6912 121.198 42.5921 121.592 42.3939 121.914C42.1957 122.236 41.9281 122.479 41.5912 122.642C41.2592 122.806 40.8876 122.888 40.4763 122.888C40.0651 122.888 39.6959 122.808 39.3689 122.65C39.0468 122.486 38.7916 122.244 38.6034 121.921C38.4151 121.594 38.3209 121.198 38.3209 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M44.4875 119.194C44.4875 119.146 44.5483 119.122 44.5828 119.156C44.7343 119.305 44.9507 119.478 45.1265 119.478C45.3098 119.478 45.5089 119.314 45.6635 119.153C45.6974 119.118 45.7585 119.141 45.7585 119.19V120.74C45.7585 121.052 45.8353 121.292 45.9889 121.461C46.1425 121.629 46.3679 121.713 46.6652 121.713C46.9625 121.713 47.1905 121.629 47.349 121.461C47.5076 121.292 47.5868 121.052 47.5868 120.74V119.188C47.5868 119.14 47.6478 119.116 47.6823 119.15C47.8341 119.301 48.054 119.478 48.2321 119.478C48.413 119.478 48.6091 119.318 48.7629 119.16C48.7968 119.125 48.8578 119.148 48.8578 119.197V120.732C48.8578 121.198 48.7587 121.592 48.5605 121.914C48.3623 122.236 48.0947 122.479 47.7578 122.642C47.4258 122.806 47.0542 122.888 46.6429 122.888C46.2317 122.888 45.8625 122.808 45.5355 122.65C45.2134 122.486 44.9583 122.244 44.77 121.921C44.5817 121.594 44.4875 121.198 44.4875 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M50.6542 119.194C50.6542 119.146 50.7149 119.122 50.7494 119.156C50.9009 119.305 51.1173 119.478 51.2931 119.478C51.4765 119.478 51.6755 119.314 51.8301 119.153C51.864 119.118 51.9251 119.141 51.9251 119.19V120.74C51.9251 121.052 52.0019 121.292 52.1555 121.461C52.3091 121.629 52.5346 121.713 52.8319 121.713C53.1292 121.713 53.3571 121.629 53.5156 121.461C53.6742 121.292 53.7535 121.052 53.7535 120.74V119.188C53.7535 119.14 53.8145 119.116 53.849 119.15C54.0007 119.301 54.2206 119.478 54.3988 119.478C54.5797 119.478 54.7758 119.318 54.9295 119.16C54.9635 119.125 55.0244 119.148 55.0244 119.197V120.732C55.0244 121.198 54.9253 121.592 54.7271 121.914C54.5289 122.236 54.2614 122.479 53.9244 122.642C53.5924 122.806 53.2208 122.888 52.8096 122.888C52.3983 122.888 52.0292 122.808 51.7021 122.65C51.3801 122.486 51.1249 122.244 50.9366 121.921C50.7483 121.594 50.6542 121.198 50.6542 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M56.8208 119.194C56.8208 119.146 56.8815 119.122 56.9161 119.156C57.0675 119.305 57.284 119.478 57.4597 119.478C57.6431 119.478 57.8421 119.314 57.9967 119.153C58.0306 119.118 58.0917 119.141 58.0917 119.19V120.74C58.0917 121.052 58.1685 121.292 58.3221 121.461C58.4757 121.629 58.7012 121.713 58.9985 121.713C59.2958 121.713 59.5237 121.629 59.6823 121.461C59.8408 121.292 59.9201 121.052 59.9201 120.74V119.188C59.9201 119.14 59.9811 119.116 60.0156 119.15C60.1673 119.301 60.3873 119.478 60.5654 119.478C60.7463 119.478 60.9424 119.318 61.0961 119.16C61.1301 119.125 61.191 119.148 61.191 119.197V120.732C61.191 121.198 61.0919 121.592 60.8937 121.914C60.6955 122.236 60.428 122.479 60.091 122.642C59.7591 122.806 59.3874 122.888 58.9762 122.888C58.5649 122.888 58.1958 122.808 57.8688 122.65C57.5467 122.486 57.2915 122.244 57.1032 121.921C56.9149 121.594 56.8208 121.198 56.8208 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M62.9874 119.194C62.9874 119.146 63.0481 119.122 63.0827 119.156C63.2342 119.305 63.4506 119.478 63.6263 119.478C63.8097 119.478 64.0087 119.314 64.1634 119.153C64.1973 119.118 64.2584 119.141 64.2584 119.19V120.74C64.2584 121.052 64.3352 121.292 64.4888 121.461C64.6424 121.629 64.8678 121.713 65.1651 121.713C65.4624 121.713 65.6903 121.629 65.8489 121.461C66.0074 121.292 66.0867 121.052 66.0867 120.74V119.188C66.0867 119.14 66.1477 119.116 66.1822 119.15C66.3339 119.301 66.5539 119.478 66.732 119.478C66.9129 119.478 67.109 119.318 67.2627 119.16C67.2967 119.125 67.3577 119.148 67.3577 119.197V120.732C67.3577 121.198 67.2586 121.592 67.0604 121.914C66.8622 122.236 66.5946 122.479 66.2577 122.642C65.9257 122.806 65.5541 122.888 65.1428 122.888C64.7316 122.888 64.3624 122.808 64.0354 122.65C63.7133 122.486 63.4581 122.244 63.2699 121.921C63.0816 121.594 62.9874 121.198 62.9874 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M69.1544 119.194C69.1544 119.146 69.2151 119.122 69.2497 119.156C69.4011 119.305 69.6176 119.478 69.7933 119.478C69.9767 119.478 70.1757 119.314 70.3304 119.153C70.3643 119.118 70.4254 119.141 70.4254 119.19V120.74C70.4254 121.052 70.5022 121.292 70.6558 121.461C70.8094 121.629 71.0348 121.713 71.3321 121.713C71.6294 121.713 71.8573 121.629 72.0159 121.461C72.1744 121.292 72.2537 121.052 72.2537 120.74V119.188C72.2537 119.14 72.3147 119.116 72.3492 119.15C72.5009 119.301 72.7209 119.478 72.899 119.478C73.0799 119.478 73.276 119.318 73.4297 119.16C73.4637 119.125 73.5247 119.148 73.5247 119.197V120.732C73.5247 121.198 73.4256 121.592 73.2274 121.914C73.0292 122.236 72.7616 122.479 72.4247 122.642C72.0927 122.806 71.7211 122.888 71.3098 122.888C70.8985 122.888 70.5294 122.808 70.2024 122.65C69.8803 122.486 69.6251 122.244 69.4368 121.921C69.2486 121.594 69.1544 121.198 69.1544 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M75.321 119.194C75.321 119.146 75.3818 119.122 75.4163 119.156C75.5678 119.305 75.7842 119.478 75.96 119.478C76.1433 119.478 76.3424 119.314 76.497 119.153C76.5309 119.118 76.592 119.141 76.592 119.19V120.74C76.592 121.052 76.6688 121.292 76.8224 121.461C76.976 121.629 77.2014 121.713 77.4987 121.713C77.796 121.713 78.024 121.629 78.1825 121.461C78.3411 121.292 78.4203 121.052 78.4203 120.74V119.188C78.4203 119.14 78.4813 119.116 78.5158 119.15C78.6676 119.301 78.8875 119.478 79.0656 119.478C79.2465 119.478 79.4426 119.318 79.5963 119.16C79.6303 119.125 79.6913 119.148 79.6913 119.197V120.732C79.6913 121.198 79.5922 121.592 79.394 121.914C79.1958 122.236 78.9282 122.479 78.5913 122.642C78.2593 122.806 77.8877 122.888 77.4764 122.888C77.0652 122.888 76.696 122.808 76.369 122.65C76.0469 122.486 75.7918 122.244 75.6035 121.921C75.4152 121.594 75.321 121.198 75.321 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M81.4873 119.194C81.4873 119.146 81.548 119.122 81.5826 119.156C81.734 119.305 81.9505 119.478 82.1262 119.478C82.3096 119.478 82.5086 119.314 82.6633 119.153C82.6972 119.118 82.7582 119.141 82.7582 119.19V120.74C82.7582 121.052 82.835 121.292 82.9886 121.461C83.1422 121.629 83.3677 121.713 83.665 121.713C83.9623 121.713 84.1902 121.629 84.3488 121.461C84.5073 121.292 84.5866 121.052 84.5866 120.74V119.188C84.5866 119.14 84.6476 119.116 84.6821 119.15C84.8338 119.301 85.0538 119.478 85.2319 119.478C85.4128 119.478 85.6089 119.318 85.7626 119.16C85.7966 119.125 85.8575 119.148 85.8575 119.197V120.732C85.8575 121.198 85.7584 121.592 85.5602 121.914C85.362 122.236 85.0945 122.479 84.7575 122.642C84.4256 122.806 84.054 122.888 83.6427 122.888C83.2314 122.888 82.8623 122.808 82.5353 122.65C82.2132 122.486 81.958 122.244 81.7697 121.921C81.5814 121.594 81.4873 121.198 81.4873 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M87.6543 119.194C87.6543 119.146 87.715 119.122 87.7496 119.156C87.901 119.305 88.1175 119.478 88.2932 119.478C88.4766 119.478 88.6756 119.314 88.8302 119.153C88.8641 119.118 88.9252 119.141 88.9252 119.19V120.74C88.9252 121.052 89.002 121.292 89.1556 121.461C89.3092 121.629 89.5347 121.713 89.832 121.713C90.1293 121.713 90.3572 121.629 90.5158 121.461C90.6743 121.292 90.7536 121.052 90.7536 120.74V119.188C90.7536 119.14 90.8146 119.116 90.8491 119.15C91.0008 119.301 91.2208 119.478 91.3989 119.478C91.5798 119.478 91.7759 119.318 91.9296 119.16C91.9636 119.125 92.0245 119.148 92.0245 119.197V120.732C92.0245 121.198 91.9254 121.592 91.7272 121.914C91.529 122.236 91.2615 122.479 90.9245 122.642C90.5926 122.806 90.2209 122.888 89.8097 122.888C89.3984 122.888 89.0293 122.808 88.7023 122.65C88.3802 122.486 88.125 122.244 87.9367 121.921C87.7484 121.594 87.6543 121.198 87.6543 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M93.8209 119.194C93.8209 119.146 93.8816 119.122 93.9162 119.156C94.0677 119.305 94.2841 119.478 94.4598 119.478C94.6432 119.478 94.8422 119.314 94.9969 119.153C95.0308 119.118 95.0919 119.141 95.0919 119.19V120.74C95.0919 121.052 95.1687 121.292 95.3223 121.461C95.4759 121.629 95.7013 121.713 95.9986 121.713C96.2959 121.713 96.5238 121.629 96.6824 121.461C96.8409 121.292 96.9202 121.052 96.9202 120.74V119.188C96.9202 119.14 96.9812 119.116 97.0157 119.15C97.1674 119.301 97.3874 119.478 97.5655 119.478C97.7464 119.478 97.9425 119.318 98.0962 119.16C98.1302 119.125 98.1912 119.148 98.1912 119.197V120.732C98.1912 121.198 98.0921 121.592 97.8939 121.914C97.6957 122.236 97.4281 122.479 97.0912 122.642C96.7592 122.806 96.3876 122.888 95.9763 122.888C95.5651 122.888 95.1959 122.808 94.8689 122.65C94.5468 122.486 94.2916 122.244 94.1034 121.921C93.9151 121.594 93.8209 121.198 93.8209 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M99.9875 119.194C99.9875 119.146 100.048 119.122 100.083 119.156C100.234 119.305 100.451 119.478 100.626 119.478C100.81 119.478 101.009 119.314 101.163 119.153C101.197 119.118 101.258 119.141 101.258 119.19V120.74C101.258 121.052 101.335 121.292 101.489 121.461C101.642 121.629 101.868 121.713 102.165 121.713C102.463 121.713 102.69 121.629 102.849 121.461C103.008 121.292 103.087 121.052 103.087 120.74V119.188C103.087 119.14 103.148 119.116 103.182 119.15C103.334 119.301 103.554 119.478 103.732 119.478C103.913 119.478 104.109 119.318 104.263 119.16C104.297 119.125 104.358 119.148 104.358 119.197V120.732C104.358 121.198 104.259 121.592 104.06 121.914C103.862 122.236 103.595 122.479 103.258 122.642C102.926 122.806 102.554 122.888 102.143 122.888C101.732 122.888 101.363 122.808 101.036 122.65C100.713 122.486 100.458 122.244 100.27 121.921C100.082 121.594 99.9875 121.198 99.9875 120.732V119.194Z"
        fill="#FF0018"
      />
      <path
        d="M29.078 120.799C29.0799 120.847 29.138 120.867 29.17 120.833C29.307 120.683 29.4854 120.508 29.6628 120.508C29.8467 120.508 30.0463 120.672 30.2014 120.833C30.2353 120.868 30.2963 120.844 30.2963 120.795V119.431C30.2963 119.119 30.3732 118.879 30.5272 118.711C30.6811 118.542 30.9071 118.458 31.2051 118.458C31.503 118.458 31.7315 118.542 31.8904 118.711C32.0493 118.879 32.1288 119.119 32.1288 119.431V120.797C32.1288 120.846 32.1896 120.87 32.2242 120.836C32.3762 120.685 32.5968 120.508 32.7755 120.508C32.9569 120.508 33.1536 120.668 33.3077 120.826C33.3417 120.861 33.4026 120.838 33.4026 120.789V119.438C33.4026 118.973 33.3032 118.58 33.1046 118.258C32.9059 117.936 32.6378 117.694 32.3001 117.531C31.9674 117.367 31.5949 117.286 31.1827 117.286C30.7705 117.286 30.4006 117.365 30.0728 117.523C29.75 117.686 29.4942 117.929 29.3055 118.251C29.1168 118.577 29.0225 118.973 29.0225 119.438L29.078 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M35.2739 120.799C35.2759 120.847 35.3339 120.867 35.3659 120.833C35.5029 120.683 35.6813 120.508 35.8587 120.508C36.0426 120.508 36.2423 120.672 36.3973 120.833C36.4312 120.868 36.4922 120.844 36.4922 120.795V119.431C36.4922 119.119 36.5692 118.879 36.7231 118.711C36.8771 118.542 37.103 118.458 37.401 118.458C37.6989 118.458 37.9274 118.542 38.0863 118.711C38.2452 118.879 38.3247 119.119 38.3247 119.431V120.797C38.3247 120.846 38.3856 120.87 38.4201 120.836C38.5722 120.685 38.7928 120.508 38.9714 120.508C39.1528 120.508 39.3495 120.668 39.5036 120.826C39.5376 120.861 39.5985 120.838 39.5985 120.789V119.438C39.5985 118.973 39.4992 118.58 39.3005 118.258C39.1019 117.936 38.8337 117.694 38.496 117.531C38.1633 117.367 37.7908 117.286 37.3786 117.286C36.9664 117.286 36.5965 117.365 36.2687 117.523C35.9459 117.686 35.6902 117.929 35.5015 118.251C35.3127 118.577 35.2184 118.973 35.2184 119.438L35.2739 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M41.4406 120.799C41.4425 120.847 41.5006 120.867 41.5325 120.833C41.6695 120.683 41.848 120.508 42.0254 120.508C42.2093 120.508 42.4089 120.672 42.5639 120.833C42.5978 120.868 42.6588 120.844 42.6588 120.795V119.431C42.6588 119.119 42.7358 118.879 42.8897 118.711C43.0437 118.542 43.2696 118.458 43.5676 118.458C43.8656 118.458 44.094 118.542 44.2529 118.711C44.4118 118.879 44.4913 119.119 44.4913 119.431V120.797C44.4913 120.846 44.5522 120.87 44.5867 120.836C44.7388 120.685 44.9594 120.508 45.138 120.508C45.3195 120.508 45.5162 120.668 45.6703 120.826C45.7043 120.861 45.7651 120.838 45.7651 120.789V119.438C45.7651 118.973 45.6658 118.58 45.4671 118.258C45.2685 117.936 45.0003 117.694 44.6626 117.531C44.3299 117.367 43.9574 117.286 43.5453 117.286C43.1331 117.286 42.7631 117.365 42.4353 117.523C42.1125 117.686 41.8568 117.929 41.6681 118.251C41.4794 118.577 41.385 118.973 41.385 119.438L41.4406 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M47.6072 120.799C47.6091 120.847 47.6672 120.867 47.6992 120.833C47.8361 120.683 48.0146 120.508 48.192 120.508C48.3759 120.508 48.5755 120.672 48.7305 120.833C48.7645 120.868 48.8254 120.844 48.8254 120.795V119.431C48.8254 119.119 48.9024 118.879 49.0564 118.711C49.2103 118.542 49.4363 118.458 49.7342 118.458C50.0322 118.458 50.2606 118.542 50.4196 118.711C50.5785 118.879 50.6579 119.119 50.6579 119.431V120.797C50.6579 120.846 50.7188 120.87 50.7533 120.836C50.9054 120.685 51.126 120.508 51.3047 120.508C51.4861 120.508 51.6828 120.668 51.8369 120.826C51.8709 120.861 51.9317 120.838 51.9317 120.789V119.438C51.9317 118.973 51.8324 118.58 51.6338 118.258C51.4351 117.936 51.1669 117.694 50.8293 117.531C50.4965 117.367 50.1241 117.286 49.7119 117.286C49.2997 117.286 48.9297 117.365 48.602 117.523C48.2792 117.686 48.0234 117.929 47.8347 118.251C47.646 118.577 47.5516 118.973 47.5516 119.438L47.6072 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M53.7738 120.799C53.7757 120.847 53.8338 120.867 53.8658 120.833C54.0028 120.683 54.1812 120.508 54.3586 120.508C54.5425 120.508 54.7421 120.672 54.8972 120.833C54.9311 120.868 54.9921 120.844 54.9921 120.795V119.431C54.9921 119.119 55.069 118.879 55.223 118.711C55.3769 118.542 55.6029 118.458 55.9009 118.458C56.1988 118.458 56.4273 118.542 56.5862 118.711C56.7451 118.879 56.8246 119.119 56.8246 119.431V120.797C56.8246 120.846 56.8854 120.87 56.92 120.836C57.072 120.685 57.2926 120.508 57.4713 120.508C57.6527 120.508 57.8494 120.668 58.0035 120.826C58.0375 120.861 58.0984 120.838 58.0984 120.789V119.438C58.0984 118.973 57.999 118.58 57.8004 118.258C57.6017 117.936 57.3336 117.694 56.9959 117.531C56.6632 117.367 56.2907 117.286 55.8785 117.286C55.4663 117.286 55.0964 117.365 54.7686 117.523C54.4458 117.686 54.19 117.929 54.0013 118.251C53.8126 118.577 53.7183 118.973 53.7183 119.438L53.7738 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M59.9404 120.799C59.9424 120.847 60.0004 120.867 60.0324 120.833C60.1694 120.683 60.3478 120.508 60.5252 120.508C60.7092 120.508 60.9088 120.672 61.0638 120.833C61.0977 120.868 61.1587 120.844 61.1587 120.795V119.431C61.1587 119.119 61.2357 118.879 61.3896 118.711C61.5436 118.542 61.7695 118.458 62.0675 118.458C62.3655 118.458 62.5939 118.542 62.7528 118.711C62.9117 118.879 62.9912 119.119 62.9912 119.431V120.797C62.9912 120.846 63.0521 120.87 63.0866 120.836C63.2387 120.685 63.4593 120.508 63.6379 120.508C63.8193 120.508 64.016 120.668 64.1701 120.826C64.2041 120.861 64.265 120.838 64.265 120.789V119.438C64.265 118.973 64.1657 118.58 63.967 118.258C63.7684 117.936 63.5002 117.694 63.1625 117.531C62.8298 117.367 62.4573 117.286 62.0451 117.286C61.633 117.286 61.263 117.365 60.9352 117.523C60.6124 117.686 60.3567 117.929 60.168 118.251C59.9792 118.577 59.8849 118.973 59.8849 119.438L59.9404 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M66.1071 120.799C66.109 120.847 66.1671 120.867 66.1991 120.833C66.336 120.683 66.5145 120.508 66.6919 120.508C66.8758 120.508 67.0754 120.672 67.2304 120.833C67.2643 120.868 67.3253 120.844 67.3253 120.795V119.431C67.3253 119.119 67.4023 118.879 67.5562 118.711C67.7102 118.542 67.9361 118.458 68.2341 118.458C68.5321 118.458 68.7605 118.542 68.9194 118.711C69.0783 118.879 69.1578 119.119 69.1578 119.431V120.797C69.1578 120.846 69.2187 120.87 69.2532 120.836C69.4053 120.685 69.6259 120.508 69.8045 120.508C69.986 120.508 70.1827 120.668 70.3368 120.826C70.3708 120.861 70.4316 120.838 70.4316 120.789V119.438C70.4316 118.973 70.3323 118.58 70.1336 118.258C69.935 117.936 69.6668 117.694 69.3291 117.531C68.9964 117.367 68.6239 117.286 68.2118 117.286C67.7996 117.286 67.4296 117.365 67.1018 117.523C66.779 117.686 66.5233 117.929 66.3346 118.251C66.1459 118.577 66.0515 118.973 66.0515 119.438L66.1071 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M72.2741 120.799C72.276 120.847 72.3341 120.867 72.366 120.833C72.503 120.683 72.6815 120.508 72.8589 120.508C73.0428 120.508 73.2424 120.672 73.3974 120.833C73.4313 120.868 73.4923 120.844 73.4923 120.795V119.431C73.4923 119.119 73.5693 118.879 73.7232 118.711C73.8772 118.542 74.1031 118.458 74.4011 118.458C74.6991 118.458 74.9275 118.542 75.0864 118.711C75.2453 118.879 75.3248 119.119 75.3248 119.431V120.797C75.3248 120.846 75.3857 120.87 75.4202 120.836C75.5723 120.685 75.7929 120.508 75.9715 120.508C76.153 120.508 76.3496 120.668 76.5038 120.826C76.5378 120.861 76.5986 120.838 76.5986 120.789V119.438C76.5986 118.973 76.4993 118.58 76.3006 118.258C76.102 117.936 75.8338 117.694 75.4961 117.531C75.1634 117.367 74.7909 117.286 74.3788 117.286C73.9666 117.286 73.5966 117.365 73.2688 117.523C72.946 117.686 72.6903 117.929 72.5016 118.251C72.3129 118.577 72.2185 118.973 72.2185 119.438L72.2741 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M78.4403 120.799C78.4423 120.847 78.5003 120.867 78.5323 120.833C78.6693 120.683 78.8477 120.508 79.0251 120.508C79.209 120.508 79.4086 120.672 79.5637 120.833C79.5976 120.868 79.6586 120.844 79.6586 120.795V119.431C79.6586 119.119 79.7355 118.879 79.8895 118.711C80.0434 118.542 80.2694 118.458 80.5674 118.458C80.8653 118.458 81.0938 118.542 81.2527 118.711C81.4116 118.879 81.4911 119.119 81.4911 119.431V120.797C81.4911 120.846 81.5519 120.87 81.5865 120.836C81.7385 120.685 81.9591 120.508 82.1378 120.508C82.3192 120.508 82.5159 120.668 82.67 120.826C82.704 120.861 82.7649 120.838 82.7649 120.789V119.438C82.7649 118.973 82.6655 118.58 82.4669 118.258C82.2682 117.936 82.0001 117.694 81.6624 117.531C81.3297 117.367 80.9572 117.286 80.545 117.286C80.1328 117.286 79.7629 117.365 79.4351 117.523C79.1123 117.686 78.8565 117.929 78.6678 118.251C78.4791 118.577 78.3848 118.973 78.3848 119.438L78.4403 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M84.6073 120.799C84.6092 120.847 84.6673 120.867 84.6993 120.833C84.8363 120.683 85.0147 120.508 85.1921 120.508C85.376 120.508 85.5756 120.672 85.7307 120.833C85.7646 120.868 85.8256 120.844 85.8256 120.795V119.431C85.8256 119.119 85.9025 118.879 86.0565 118.711C86.2104 118.542 86.4364 118.458 86.7344 118.458C87.0323 118.458 87.2608 118.542 87.4197 118.711C87.5786 118.879 87.658 119.119 87.658 119.431V120.797C87.658 120.846 87.7189 120.87 87.7535 120.836C87.9055 120.685 88.1261 120.508 88.3048 120.508C88.4862 120.508 88.6829 120.668 88.837 120.826C88.871 120.861 88.9318 120.838 88.9318 120.789V119.438C88.9318 118.973 88.8325 118.58 88.6339 118.258C88.4352 117.936 88.1671 117.694 87.8294 117.531C87.4967 117.367 87.1242 117.286 86.712 117.286C86.2998 117.286 85.9298 117.365 85.6021 117.523C85.2793 117.686 85.0235 117.929 84.8348 118.251C84.6461 118.577 84.5518 118.973 84.5518 119.438L84.6073 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M90.7739 120.799C90.7759 120.847 90.8339 120.867 90.8659 120.833C91.0029 120.683 91.1813 120.508 91.3587 120.508C91.5426 120.508 91.7423 120.672 91.8973 120.833C91.9312 120.868 91.9922 120.844 91.9922 120.795V119.431C91.9922 119.119 92.0692 118.879 92.2231 118.711C92.3771 118.542 92.603 118.458 92.901 118.458C93.1989 118.458 93.4274 118.542 93.5863 118.711C93.7452 118.879 93.8247 119.119 93.8247 119.431V120.797C93.8247 120.846 93.8856 120.87 93.9201 120.836C94.0722 120.685 94.2928 120.508 94.4714 120.508C94.6528 120.508 94.8495 120.668 95.0036 120.826C95.0376 120.861 95.0985 120.838 95.0985 120.789V119.438C95.0985 118.973 94.9992 118.58 94.8005 118.258C94.6019 117.936 94.3337 117.694 93.996 117.531C93.6633 117.367 93.2908 117.286 92.8786 117.286C92.4664 117.286 92.0965 117.365 91.7687 117.523C91.4459 117.686 91.1902 117.929 91.0015 118.251C90.8127 118.577 90.7184 118.973 90.7184 119.438L90.7739 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M96.9406 120.799C96.9425 120.847 97.0006 120.867 97.0325 120.833C97.1695 120.683 97.348 120.508 97.5254 120.508C97.7093 120.508 97.9089 120.672 98.0639 120.833C98.0978 120.868 98.1588 120.844 98.1588 120.795V119.431C98.1588 119.119 98.2358 118.879 98.3897 118.711C98.5437 118.542 98.7696 118.458 99.0676 118.458C99.3656 118.458 99.594 118.542 99.7529 118.711C99.9118 118.879 99.9913 119.119 99.9913 119.431V120.797C99.9913 120.846 100.052 120.87 100.087 120.836C100.239 120.685 100.459 120.508 100.638 120.508C100.819 120.508 101.016 120.668 101.17 120.826C101.204 120.861 101.265 120.838 101.265 120.789V119.438C101.265 118.973 101.166 118.58 100.967 118.258C100.768 117.936 100.5 117.694 100.163 117.531C99.8299 117.367 99.4574 117.286 99.0453 117.286C98.6331 117.286 98.2631 117.365 97.9353 117.523C97.6125 117.686 97.3568 117.929 97.1681 118.251C96.9794 118.577 96.885 118.973 96.885 119.438L96.9406 120.799Z"
        fill="#FF0018"
      />
      <path
        d="M26.5717 119.857L27.3037 121.877C27.5786 122.635 28.6533 122.628 28.9189 121.867L30.2115 118.16C30.4723 117.412 31.5213 117.387 31.8163 118.123L33.4164 122.11C33.707 122.834 34.7349 122.825 35.0128 122.096L36.5763 117.995C36.8706 117.223 37.9788 117.273 38.2024 118.068L39.2755 121.884C39.4919 122.653 40.5507 122.734 40.8811 122.006L42.7224 117.95C43.0421 117.245 44.0576 117.291 44.3123 118.022L45.7219 122.064C45.9792 122.802 47.0089 122.839 47.3183 122.122L49.1856 117.792C49.5053 117.05 50.5791 117.122 50.7978 117.899L51.9183 121.884C52.1348 122.653 53.1936 122.734 53.524 122.006L55.3003 118.093C55.6283 117.37 56.6774 117.443 56.9026 118.204L58.0085 121.939C58.2338 122.7 59.2829 122.773 59.6109 122.05L61.4724 117.95C61.7921 117.245 62.8076 117.291 63.0623 118.022L64.4232 121.925C64.6865 122.68 65.7487 122.695 66.0335 121.948L67.6069 117.821C67.8941 117.068 68.968 117.091 69.2215 117.857L70.5468 121.858C70.8003 122.623 71.8742 122.647 72.1614 121.894L73.7141 117.821C74.0013 117.068 75.0751 117.091 75.3286 117.857L76.654 121.858C76.9075 122.623 77.9813 122.647 78.2685 121.894L79.8212 117.821C80.1084 117.068 81.1823 117.091 81.4358 117.857L82.7611 121.858C83.0146 122.623 84.0885 122.647 84.3757 121.894L85.9283 117.821C86.2156 117.068 87.2894 117.091 87.5429 117.857L88.8682 121.858C89.1218 122.623 90.1956 122.647 90.4828 121.894L92.0355 117.821C92.3227 117.068 93.3966 117.091 93.6501 117.857L94.9957 121.919C95.2469 122.677 96.3074 122.71 96.6049 121.969L98.2048 117.982C98.4926 117.265 99.5079 117.265 99.7957 117.982L101.403 121.988C101.7 122.727 102.756 122.697 103.011 121.943L103.715 119.857V129.286C103.715 130.943 102.371 132.286 100.715 132.286H29.5717C27.9148 132.286 26.5717 130.943 26.5717 129.286V119.857Z"
        fill="#FF0018"
      />
      <path
        d="M103.072 123.286L97.7145 123.286C97.2411 123.286 96.8574 123.669 96.8574 124.143C96.8574 124.616 97.2411 125 97.7145 125H103.072V123.286Z"
        fill="white"
      />
      <path
        d="M27.429 130.143L23.5718 130.143V131.857H27.429V130.143Z"
        fill="#222222"
      />
      <path
        d="M26.5717 96.2857C26.5717 94.3922 28.1067 92.8572 30.0002 92.8572H94.7977C96.2817 92.8572 97.5973 93.8119 98.0573 95.2228L103.546 112.053C103.657 112.397 103.715 112.755 103.715 113.116V128.857C103.715 130.751 102.179 132.286 100.286 132.286H30.0002C28.1067 132.286 26.5717 130.751 26.5717 128.857V96.2857Z"
        stroke="#222222"
        stroke-width="1.28571"
      />
      <circle
        cx="6.85714"
        cy="6.85714"
        r="6.85714"
        transform="matrix(-1 0 0 1 90.0005 123.714)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.28571"
      />
      <circle
        cx="1.71429"
        cy="1.71429"
        r="1.71429"
        transform="matrix(-1 0 0 1 84.8574 128.857)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.28571"
      />
      <circle
        cx="6.85714"
        cy="6.85714"
        r="6.85714"
        transform="matrix(-1 0 0 1 52.2863 123.714)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.28571"
      />
      <circle
        cx="1.71429"
        cy="1.71429"
        r="1.71429"
        transform="matrix(-1 0 0 1 47.1432 128.857)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.28571"
      />
      <path
        d="M16.8362 131.857H21.0791H23.1432C22.761 131.469 21.9965 130.506 21.0791 129.76C19.9324 128.828 18.9907 128.975 18.0976 128.245C16.9416 127.301 17.6307 129.351 16.3674 128.554C15.8736 128.243 15.4467 128.358 14.1853 126.96C12.9239 125.562 12.349 126.592 13.1517 127.757C13.9544 128.922 11.3142 127.643 10.0705 126.614C9.08104 125.796 9.15308 128.478 10.8732 129.76C13.1517 131.458 15.1041 131.857 16.8362 131.857Z"
        fill="#EDEDED"
      />
      <ellipse
        cx="147.857"
        cy="172.143"
        rx="51.4286"
        ry="1.71429"
        fill="#D4D4D4"
      />
      <path
        d="M96.4287 116.714C96.4287 114.19 98.4754 112.143 101 112.143H187.397C189.375 112.143 191.13 113.416 191.743 115.297L199.061 137.738C199.21 138.195 199.286 138.674 199.286 139.155V160.143C199.286 162.668 197.239 164.714 194.714 164.714H101C98.4754 164.714 96.4287 162.668 96.4287 160.143V116.714Z"
        fill="#222222"
        stroke="#222222"
        stroke-width="1.71429"
      />
      <rect
        width="18.2857"
        height="18.2857"
        rx="2.28571"
        transform="matrix(-1 0 0 1 169.572 123.571)"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.71429"
      />
      <path
        d="M194.429 123.571H181C179.738 123.571 178.714 124.595 178.714 125.857V139.571C178.714 140.834 179.738 141.857 181 141.857H199.286V139.157C199.286 138.674 199.21 138.195 199.06 137.736L194.429 123.571Z"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.71429"
      />
      <rect
        width="18.2857"
        height="18.2857"
        rx="2.28571"
        transform="matrix(-1 0 0 1 146.714 123.571)"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.71429"
      />
      <rect
        width="18.2857"
        height="18.2857"
        rx="2.28571"
        transform="matrix(-1 0 0 1 123.857 123.571)"
        fill="white"
        stroke="#FF0018"
        stroke-width="1.71429"
      />
      <path
        d="M95.5717 147.259C95.5717 147.194 95.6527 147.163 95.6987 147.208C95.9007 147.407 96.1893 147.637 96.4236 147.637C96.6681 147.637 96.9335 147.418 97.1396 147.204C97.1848 147.157 97.2663 147.188 97.2663 147.254V149.319C97.2663 149.736 97.3687 150.056 97.5735 150.281C97.7783 150.505 98.0789 150.618 98.4753 150.618C98.8717 150.618 99.1756 150.505 99.387 150.281C99.5984 150.056 99.7041 149.736 99.7041 149.319V147.251C99.7041 147.186 99.7854 147.154 99.8314 147.2C100.034 147.401 100.327 147.637 100.564 147.637C100.806 147.637 101.067 147.424 101.272 147.213C101.317 147.166 101.399 147.197 101.399 147.262V149.31C101.399 149.931 101.267 150.456 101.002 150.885C100.738 151.315 100.381 151.638 99.932 151.856C99.4894 152.074 98.9939 152.183 98.4456 152.183C97.8972 152.183 97.405 152.078 96.969 151.866C96.5396 151.648 96.1993 151.325 95.9483 150.895C95.6972 150.459 95.5717 149.931 95.5717 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M103.833 147.259C103.833 147.194 103.914 147.163 103.96 147.208C104.162 147.407 104.451 147.637 104.685 147.637C104.93 147.637 105.195 147.418 105.401 147.204C105.446 147.157 105.528 147.188 105.528 147.254V149.319C105.528 149.736 105.63 150.056 105.835 150.281C106.04 150.505 106.34 150.618 106.737 150.618C107.133 150.618 107.437 150.505 107.648 150.281C107.86 150.056 107.966 149.736 107.966 149.319V147.251C107.966 147.186 108.047 147.154 108.093 147.2C108.295 147.401 108.588 147.637 108.826 147.637C109.067 147.637 109.329 147.424 109.534 147.213C109.579 147.166 109.66 147.197 109.66 147.262V149.31C109.66 149.931 109.528 150.456 109.264 150.885C108.999 151.315 108.643 151.638 108.193 151.856C107.751 152.074 107.255 152.183 106.707 152.183C106.159 152.183 105.666 152.078 105.23 151.866C104.801 151.648 104.461 151.325 104.21 150.895C103.959 150.459 103.833 149.931 103.833 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M112.094 147.259C112.094 147.194 112.175 147.163 112.221 147.208C112.423 147.407 112.712 147.637 112.946 147.637C113.191 147.637 113.456 147.418 113.662 147.204C113.707 147.157 113.789 147.188 113.789 147.254V149.319C113.789 149.736 113.891 150.056 114.096 150.281C114.301 150.505 114.601 150.618 114.998 150.618C115.394 150.618 115.698 150.505 115.91 150.281C116.121 150.056 116.227 149.736 116.227 149.319V147.251C116.227 147.186 116.308 147.154 116.354 147.2C116.556 147.401 116.85 147.637 117.087 147.637C117.328 147.637 117.59 147.424 117.795 147.213C117.84 147.166 117.921 147.197 117.921 147.262V149.31C117.921 149.931 117.789 150.456 117.525 150.885C117.261 151.315 116.904 151.638 116.455 151.856C116.012 152.074 115.516 152.183 114.968 152.183C114.42 152.183 113.928 152.078 113.492 151.866C113.062 151.648 112.722 151.325 112.471 150.895C112.22 150.459 112.094 149.931 112.094 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M120.316 147.259C120.316 147.194 120.397 147.163 120.443 147.208C120.645 147.407 120.934 147.637 121.168 147.637C121.413 147.637 121.678 147.418 121.884 147.204C121.93 147.157 122.011 147.188 122.011 147.254V149.319C122.011 149.736 122.113 150.056 122.318 150.281C122.523 150.505 122.824 150.618 123.22 150.618C123.616 150.618 123.92 150.505 124.132 150.281C124.343 150.056 124.449 149.736 124.449 149.319V147.251C124.449 147.186 124.53 147.154 124.576 147.2C124.778 147.401 125.072 147.637 125.309 147.637C125.55 147.637 125.812 147.424 126.017 147.213C126.062 147.166 126.143 147.197 126.143 147.262V149.31C126.143 149.931 126.011 150.456 125.747 150.885C125.483 151.315 125.126 151.638 124.677 151.856C124.234 152.074 123.739 152.183 123.19 152.183C122.642 152.183 122.15 152.078 121.714 151.866C121.284 151.648 120.944 151.325 120.693 150.895C120.442 150.459 120.316 149.931 120.316 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M128.539 147.259C128.539 147.194 128.619 147.163 128.666 147.208C128.867 147.407 129.156 147.637 129.39 147.637C129.635 147.637 129.9 147.418 130.106 147.204C130.152 147.157 130.233 147.188 130.233 147.254V149.319C130.233 149.736 130.335 150.056 130.54 150.281C130.745 150.505 131.046 150.618 131.442 150.618C131.838 150.618 132.142 150.505 132.354 150.281C132.565 150.056 132.671 149.736 132.671 149.319V147.251C132.671 147.186 132.752 147.154 132.798 147.2C133.001 147.401 133.294 147.637 133.531 147.637C133.772 147.637 134.034 147.424 134.239 147.213C134.284 147.166 134.365 147.197 134.365 147.262V149.31C134.365 149.931 134.233 150.456 133.969 150.885C133.705 151.315 133.348 151.638 132.899 151.856C132.456 152.074 131.961 152.183 131.412 152.183C130.864 152.183 130.372 152.078 129.936 151.866C129.506 151.648 129.166 151.325 128.915 150.895C128.664 150.459 128.539 149.931 128.539 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M136.761 147.259C136.761 147.194 136.842 147.163 136.888 147.208C137.09 147.407 137.378 147.637 137.613 147.637C137.857 147.637 138.123 147.418 138.329 147.204C138.374 147.157 138.455 147.188 138.455 147.254V149.319C138.455 149.736 138.558 150.056 138.763 150.281C138.967 150.505 139.268 150.618 139.664 150.618C140.061 150.618 140.365 150.505 140.576 150.281C140.788 150.056 140.893 149.736 140.893 149.319V147.251C140.893 147.186 140.975 147.154 141.021 147.2C141.223 147.401 141.516 147.637 141.754 147.637C141.995 147.637 142.256 147.424 142.461 147.213C142.507 147.166 142.588 147.197 142.588 147.262V149.31C142.588 149.931 142.456 150.456 142.191 150.885C141.927 151.315 141.57 151.638 141.121 151.856C140.679 152.074 140.183 152.183 139.635 152.183C139.086 152.183 138.594 152.078 138.158 151.866C137.729 151.648 137.388 151.325 137.137 150.895C136.886 150.459 136.761 149.931 136.761 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M144.983 147.259C144.983 147.194 145.064 147.163 145.11 147.208C145.312 147.407 145.601 147.637 145.835 147.637C146.079 147.637 146.345 147.418 146.551 147.204C146.596 147.157 146.678 147.188 146.678 147.254V149.319C146.678 149.736 146.78 150.056 146.985 150.281C147.19 150.505 147.49 150.618 147.887 150.618C148.283 150.618 148.587 150.505 148.798 150.281C149.01 150.056 149.115 149.736 149.115 149.319V147.251C149.115 147.186 149.197 147.154 149.243 147.2C149.445 147.401 149.738 147.637 149.976 147.637C150.217 147.637 150.478 147.424 150.683 147.213C150.729 147.166 150.81 147.197 150.81 147.262V149.31C150.81 149.931 150.678 150.456 150.414 150.885C150.149 151.315 149.793 151.638 149.343 151.856C148.901 152.074 148.405 152.183 147.857 152.183C147.308 152.183 146.816 152.078 146.38 151.866C145.951 151.648 145.611 151.325 145.36 150.895C145.108 150.459 144.983 149.931 144.983 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M153.205 147.259C153.205 147.194 153.286 147.163 153.332 147.208C153.534 147.407 153.823 147.637 154.057 147.637C154.302 147.637 154.567 147.418 154.773 147.204C154.819 147.157 154.9 147.188 154.9 147.254V149.319C154.9 149.736 155.002 150.056 155.207 150.281C155.412 150.505 155.713 150.618 156.109 150.618C156.505 150.618 156.809 150.505 157.021 150.281C157.232 150.056 157.338 149.736 157.338 149.319V147.251C157.338 147.186 157.419 147.154 157.465 147.2C157.667 147.401 157.961 147.637 158.198 147.637C158.439 147.637 158.701 147.424 158.906 147.213C158.951 147.166 159.032 147.197 159.032 147.262V149.31C159.032 149.931 158.9 150.456 158.636 150.885C158.372 151.315 158.015 151.638 157.566 151.856C157.123 152.074 156.628 152.183 156.079 152.183C155.531 152.183 155.039 152.078 154.603 151.866C154.173 151.648 153.833 151.325 153.582 150.895C153.331 150.459 153.205 149.931 153.205 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M161.427 147.259C161.427 147.194 161.508 147.163 161.555 147.208C161.756 147.407 162.045 147.637 162.279 147.637C162.524 147.637 162.789 147.418 162.995 147.204C163.041 147.157 163.122 147.188 163.122 147.254V149.319C163.122 149.736 163.224 150.056 163.429 150.281C163.634 150.505 163.935 150.618 164.331 150.618C164.727 150.618 165.031 150.505 165.243 150.281C165.454 150.056 165.56 149.736 165.56 149.319V147.251C165.56 147.186 165.641 147.154 165.687 147.2C165.89 147.401 166.183 147.637 166.42 147.637C166.661 147.637 166.923 147.424 167.128 147.213C167.173 147.166 167.254 147.197 167.254 147.262V149.31C167.254 149.931 167.122 150.456 166.858 150.885C166.594 151.315 166.237 151.638 165.788 151.856C165.345 152.074 164.85 152.183 164.301 152.183C163.753 152.183 163.261 152.078 162.825 151.866C162.395 151.648 162.055 151.325 161.804 150.895C161.553 150.459 161.427 149.931 161.427 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M169.65 147.259C169.65 147.194 169.73 147.163 169.777 147.208C169.979 147.407 170.267 147.637 170.501 147.637C170.746 147.637 171.011 147.418 171.217 147.204C171.263 147.157 171.344 147.188 171.344 147.254V149.319C171.344 149.736 171.447 150.056 171.651 150.281C171.856 150.505 172.157 150.618 172.553 150.618C172.95 150.618 173.253 150.505 173.465 150.281C173.676 150.056 173.782 149.736 173.782 149.319V147.251C173.782 147.186 173.863 147.154 173.909 147.2C174.112 147.401 174.405 147.637 174.642 147.637C174.884 147.637 175.145 147.424 175.35 147.213C175.395 147.166 175.477 147.197 175.477 147.262V149.31C175.477 149.931 175.344 150.456 175.08 150.885C174.816 151.315 174.459 151.638 174.01 151.856C173.567 152.074 173.072 152.183 172.523 152.183C171.975 152.183 171.483 152.078 171.047 151.866C170.617 151.648 170.277 151.325 170.026 150.895C169.775 150.459 169.65 149.931 169.65 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M177.872 147.259C177.872 147.194 177.953 147.163 177.999 147.208C178.201 147.407 178.49 147.637 178.724 147.637C178.968 147.637 179.234 147.418 179.44 147.204C179.485 147.157 179.567 147.188 179.567 147.254V149.319C179.567 149.736 179.669 150.056 179.874 150.281C180.079 150.505 180.379 150.618 180.776 150.618C181.172 150.618 181.476 150.505 181.687 150.281C181.899 150.056 182.004 149.736 182.004 149.319V147.251C182.004 147.186 182.086 147.154 182.132 147.2C182.334 147.401 182.627 147.637 182.865 147.637C183.106 147.637 183.367 147.424 183.572 147.213C183.618 147.166 183.699 147.197 183.699 147.262V149.31C183.699 149.931 183.567 150.456 183.303 150.885C183.038 151.315 182.682 151.638 182.232 151.856C181.79 152.074 181.294 152.183 180.746 152.183C180.197 152.183 179.705 152.078 179.269 151.866C178.84 151.648 178.5 151.325 178.249 150.895C177.997 150.459 177.872 149.931 177.872 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M186.094 147.259C186.094 147.194 186.175 147.163 186.221 147.208C186.423 147.407 186.712 147.637 186.946 147.637C187.19 147.637 187.456 147.418 187.662 147.204C187.707 147.157 187.789 147.188 187.789 147.254V149.319C187.789 149.736 187.891 150.056 188.096 150.281C188.301 150.505 188.601 150.618 188.998 150.618C189.394 150.618 189.698 150.505 189.909 150.281C190.121 150.056 190.226 149.736 190.226 149.319V147.251C190.226 147.186 190.308 147.154 190.354 147.2C190.556 147.401 190.849 147.637 191.087 147.637C191.328 147.637 191.59 147.424 191.794 147.213C191.84 147.166 191.921 147.197 191.921 147.262V149.31C191.921 149.931 191.789 150.456 191.525 150.885C191.26 151.315 190.904 151.638 190.454 151.856C190.012 152.074 189.516 152.183 188.968 152.183C188.42 152.183 187.927 152.078 187.491 151.866C187.062 151.648 186.722 151.325 186.471 150.895C186.22 150.459 186.094 149.931 186.094 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M194.316 147.259C194.316 147.194 194.397 147.163 194.443 147.208C194.645 147.407 194.934 147.637 195.168 147.637C195.413 147.637 195.678 147.418 195.884 147.204C195.929 147.157 196.011 147.188 196.011 147.254V149.319C196.011 149.736 196.113 150.056 196.318 150.281C196.523 150.505 196.823 150.618 197.22 150.618C197.616 150.618 197.92 150.505 198.131 150.281C198.343 150.056 198.449 149.736 198.449 149.319V147.251C198.449 147.186 198.53 147.154 198.576 147.2C198.778 147.401 199.071 147.637 199.309 147.637C199.55 147.637 199.812 147.424 200.017 147.213C200.062 147.166 200.143 147.197 200.143 147.262V149.31C200.143 149.931 200.011 150.456 199.747 150.885C199.482 151.315 199.126 151.638 198.676 151.856C198.234 152.074 197.738 152.183 197.19 152.183C196.642 152.183 196.149 152.078 195.713 151.866C195.284 151.648 194.944 151.325 194.693 150.895C194.442 150.459 194.316 149.931 194.316 149.31V147.259Z"
        fill="#FF0018"
      />
      <path
        d="M99.7705 149.399C99.7731 149.462 99.8505 149.49 99.8931 149.443C100.076 149.243 100.314 149.011 100.55 149.011C100.795 149.011 101.062 149.23 101.268 149.443C101.314 149.49 101.395 149.459 101.395 149.394V147.574C101.395 147.158 101.497 146.838 101.703 146.614C101.908 146.39 102.209 146.278 102.607 146.278C103.004 146.278 103.308 146.39 103.52 146.614C103.732 146.838 103.838 147.158 103.838 147.574V149.396C103.838 149.461 103.919 149.493 103.965 149.447C104.168 149.247 104.462 149.011 104.7 149.011C104.942 149.011 105.205 149.224 105.41 149.435C105.455 149.481 105.537 149.45 105.537 149.385V147.584C105.537 146.964 105.404 146.439 105.139 146.01C104.874 145.582 104.517 145.258 104.067 145.041C103.623 144.823 103.126 144.714 102.577 144.714C102.027 144.714 101.534 144.82 101.097 145.031C100.666 145.248 100.325 145.572 100.074 146C99.8222 146.436 99.6964 146.964 99.6964 147.584L99.7705 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M108.032 149.399C108.034 149.462 108.112 149.49 108.154 149.443C108.337 149.243 108.575 149.011 108.811 149.011C109.057 149.011 109.323 149.23 109.529 149.443C109.575 149.49 109.656 149.459 109.656 149.394V147.574C109.656 147.158 109.759 146.838 109.964 146.614C110.169 146.39 110.47 146.278 110.868 146.278C111.265 146.278 111.57 146.39 111.781 146.614C111.993 146.838 112.099 147.158 112.099 147.574V149.396C112.099 149.461 112.18 149.493 112.227 149.447C112.429 149.247 112.723 149.011 112.962 149.011C113.204 149.011 113.466 149.224 113.671 149.435C113.717 149.481 113.798 149.45 113.798 149.385V147.584C113.798 146.964 113.665 146.439 113.4 146.01C113.136 145.582 112.778 145.258 112.328 145.041C111.884 144.823 111.387 144.714 110.838 144.714C110.288 144.714 109.795 144.82 109.358 145.031C108.928 145.248 108.587 145.572 108.335 146C108.083 146.436 107.958 146.964 107.958 147.584L108.032 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M116.254 149.399C116.256 149.462 116.334 149.49 116.376 149.443C116.559 149.243 116.797 149.011 117.033 149.011C117.279 149.011 117.545 149.23 117.752 149.443C117.797 149.49 117.878 149.459 117.878 149.394V147.574C117.878 147.158 117.981 146.838 118.186 146.614C118.391 146.39 118.693 146.278 119.09 146.278C119.487 146.278 119.792 146.39 120.004 146.614C120.215 146.838 120.321 147.158 120.321 147.574V149.396C120.321 149.461 120.403 149.493 120.449 149.447C120.651 149.247 120.946 149.011 121.184 149.011C121.426 149.011 121.688 149.224 121.893 149.435C121.939 149.481 122.02 149.45 122.02 149.385V147.584C122.02 146.964 121.887 146.439 121.623 146.01C121.358 145.582 121 145.258 120.55 145.041C120.106 144.823 119.61 144.714 119.06 144.714C118.51 144.714 118.017 144.82 117.58 145.031C117.15 145.248 116.809 145.572 116.557 146C116.305 146.436 116.18 146.964 116.18 147.584L116.254 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M124.476 149.399C124.478 149.462 124.556 149.49 124.599 149.443C124.781 149.243 125.019 149.011 125.256 149.011C125.501 149.011 125.767 149.23 125.974 149.443C126.019 149.49 126.1 149.459 126.1 149.394V147.574C126.1 147.158 126.203 146.838 126.408 146.614C126.613 146.39 126.915 146.278 127.312 146.278C127.709 146.278 128.014 146.39 128.226 146.614C128.438 146.838 128.544 147.158 128.544 147.574V149.396C128.544 149.461 128.625 149.493 128.671 149.447C128.873 149.247 129.168 149.011 129.406 149.011C129.648 149.011 129.91 149.224 130.115 149.435C130.161 149.481 130.242 149.45 130.242 149.385V147.584C130.242 146.964 130.109 146.439 129.845 146.01C129.58 145.582 129.222 145.258 128.772 145.041C128.328 144.823 127.832 144.714 127.282 144.714C126.733 144.714 126.239 144.82 125.802 145.031C125.372 145.248 125.031 145.572 124.779 146C124.528 146.436 124.402 146.964 124.402 147.584L124.476 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M132.698 149.399C132.701 149.462 132.778 149.49 132.821 149.443C133.003 149.243 133.241 149.011 133.478 149.011C133.723 149.011 133.989 149.23 134.196 149.443C134.241 149.49 134.323 149.459 134.323 149.394V147.574C134.323 147.158 134.425 146.838 134.63 146.614C134.836 146.39 135.137 146.278 135.534 146.278C135.932 146.278 136.236 146.39 136.448 146.614C136.66 146.838 136.766 147.158 136.766 147.574V149.396C136.766 149.461 136.847 149.493 136.893 149.447C137.096 149.247 137.39 149.011 137.628 149.011C137.87 149.011 138.132 149.224 138.338 149.435C138.383 149.481 138.464 149.45 138.464 149.385V147.584C138.464 146.964 138.332 146.439 138.067 146.01C137.802 145.582 137.445 145.258 136.994 145.041C136.551 144.823 136.054 144.714 135.504 144.714C134.955 144.714 134.462 144.82 134.025 145.031C133.594 145.248 133.253 145.572 133.002 146C132.75 146.436 132.624 146.964 132.624 147.584L132.698 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M140.92 149.399C140.923 149.462 141 149.49 141.043 149.443C141.226 149.243 141.464 149.011 141.7 149.011C141.945 149.011 142.211 149.23 142.418 149.443C142.463 149.49 142.545 149.459 142.545 149.394V147.574C142.545 147.158 142.647 146.838 142.853 146.614C143.058 146.39 143.359 146.278 143.756 146.278C144.154 146.278 144.458 146.39 144.67 146.614C144.882 146.838 144.988 147.158 144.988 147.574V149.396C144.988 149.461 145.069 149.493 145.115 149.447C145.318 149.247 145.612 149.011 145.85 149.011C146.092 149.011 146.354 149.224 146.56 149.435C146.605 149.481 146.686 149.45 146.686 149.385V147.584C146.686 146.964 146.554 146.439 146.289 146.01C146.024 145.582 145.667 145.258 145.216 145.041C144.773 144.823 144.276 144.714 143.727 144.714C143.177 144.714 142.684 144.82 142.247 145.031C141.816 145.248 141.475 145.572 141.224 146C140.972 146.436 140.846 146.964 140.846 147.584L140.92 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M149.142 149.399C149.145 149.462 149.222 149.49 149.265 149.443C149.448 149.243 149.686 149.011 149.922 149.011C150.167 149.011 150.434 149.23 150.64 149.443C150.685 149.49 150.767 149.459 150.767 149.394V147.574C150.767 147.158 150.869 146.838 151.075 146.614C151.28 146.39 151.581 146.278 151.978 146.278C152.376 146.278 152.68 146.39 152.892 146.614C153.104 146.838 153.21 147.158 153.21 147.574V149.396C153.21 149.461 153.291 149.493 153.337 149.447C153.54 149.247 153.834 149.011 154.072 149.011C154.314 149.011 154.577 149.224 154.782 149.435C154.827 149.481 154.908 149.45 154.908 149.385V147.584C154.908 146.964 154.776 146.439 154.511 146.01C154.246 145.582 153.889 145.258 153.439 145.041C152.995 144.823 152.498 144.714 151.949 144.714C151.399 144.714 150.906 144.82 150.469 145.031C150.038 145.248 149.697 145.572 149.446 146C149.194 146.436 149.068 146.964 149.068 147.584L149.142 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M157.365 149.399C157.367 149.462 157.445 149.49 157.487 149.443C157.67 149.243 157.908 149.011 158.145 149.011C158.39 149.011 158.656 149.23 158.863 149.443C158.908 149.49 158.989 149.459 158.989 149.394V147.574C158.989 147.158 159.092 146.838 159.297 146.614C159.502 146.39 159.804 146.278 160.201 146.278C160.598 146.278 160.903 146.39 161.115 146.614C161.327 146.838 161.432 147.158 161.432 147.574V149.396C161.432 149.461 161.514 149.493 161.56 149.447C161.762 149.247 162.057 149.011 162.295 149.011C162.537 149.011 162.799 149.224 163.004 149.435C163.05 149.481 163.131 149.45 163.131 149.385V147.584C163.131 146.964 162.998 146.439 162.734 146.01C162.469 145.582 162.111 145.258 161.661 145.041C161.217 144.823 160.721 144.714 160.171 144.714C159.622 144.714 159.128 144.82 158.691 145.031C158.261 145.248 157.92 145.572 157.668 146C157.417 146.436 157.291 146.964 157.291 147.584L157.365 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M165.587 149.399C165.589 149.462 165.667 149.49 165.71 149.443C165.892 149.243 166.13 149.011 166.367 149.011C166.612 149.011 166.878 149.23 167.085 149.443C167.13 149.49 167.211 149.459 167.211 149.394V147.574C167.211 147.158 167.314 146.838 167.519 146.614C167.724 146.39 168.026 146.278 168.423 146.278C168.82 146.278 169.125 146.39 169.337 146.614C169.549 146.838 169.655 147.158 169.655 147.574V149.396C169.655 149.461 169.736 149.493 169.782 149.447C169.985 149.247 170.279 149.011 170.517 149.011C170.759 149.011 171.021 149.224 171.226 149.435C171.272 149.481 171.353 149.45 171.353 149.385V147.584C171.353 146.964 171.221 146.439 170.956 146.01C170.691 145.582 170.333 145.258 169.883 145.041C169.439 144.823 168.943 144.714 168.393 144.714C167.844 144.714 167.35 144.82 166.913 145.031C166.483 145.248 166.142 145.572 165.89 146C165.639 146.436 165.513 146.964 165.513 147.584L165.587 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M173.809 149.399C173.812 149.462 173.889 149.49 173.932 149.443C174.115 149.243 174.352 149.011 174.589 149.011C174.834 149.011 175.1 149.23 175.307 149.443C175.352 149.49 175.434 149.459 175.434 149.394V147.574C175.434 147.158 175.536 146.838 175.742 146.614C175.947 146.39 176.248 146.278 176.645 146.278C177.043 146.278 177.347 146.39 177.559 146.614C177.771 146.838 177.877 147.158 177.877 147.574V149.396C177.877 149.461 177.958 149.493 178.004 149.447C178.207 149.247 178.501 149.011 178.739 149.011C178.981 149.011 179.243 149.224 179.449 149.435C179.494 149.481 179.575 149.45 179.575 149.385V147.584C179.575 146.964 179.443 146.439 179.178 146.01C178.913 145.582 178.556 145.258 178.105 145.041C177.662 144.823 177.165 144.714 176.616 144.714C176.066 144.714 175.573 144.82 175.136 145.031C174.705 145.248 174.364 145.572 174.113 146C173.861 146.436 173.735 146.964 173.735 147.584L173.809 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M182.031 149.399C182.034 149.462 182.111 149.49 182.154 149.443C182.337 149.243 182.575 149.011 182.811 149.011C183.056 149.011 183.323 149.23 183.529 149.443C183.574 149.49 183.656 149.459 183.656 149.394V147.574C183.656 147.158 183.758 146.838 183.964 146.614C184.169 146.39 184.47 146.278 184.867 146.278C185.265 146.278 185.569 146.39 185.781 146.614C185.993 146.838 186.099 147.158 186.099 147.574V149.396C186.099 149.461 186.18 149.493 186.226 149.447C186.429 149.247 186.723 149.011 186.961 149.011C187.203 149.011 187.466 149.224 187.671 149.435C187.716 149.481 187.797 149.45 187.797 149.385V147.584C187.797 146.964 187.665 146.439 187.4 146.01C187.135 145.582 186.778 145.258 186.327 145.041C185.884 144.823 185.387 144.714 184.838 144.714C184.288 144.714 183.795 144.82 183.358 145.031C182.927 145.248 182.586 145.572 182.335 146C182.083 146.436 181.957 146.964 181.957 147.584L182.031 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M190.254 149.399C190.256 149.462 190.334 149.49 190.376 149.443C190.559 149.243 190.797 149.011 191.033 149.011C191.278 149.011 191.545 149.23 191.751 149.443C191.797 149.49 191.878 149.459 191.878 149.394V147.574C191.878 147.158 191.98 146.838 192.186 146.614C192.391 146.39 192.692 146.278 193.09 146.278C193.487 146.278 193.791 146.39 194.003 146.614C194.215 146.838 194.321 147.158 194.321 147.574V149.396C194.321 149.461 194.402 149.493 194.448 149.447C194.651 149.247 194.945 149.011 195.183 149.011C195.425 149.011 195.688 149.224 195.893 149.435C195.938 149.481 196.02 149.45 196.02 149.385V147.584C196.02 146.964 195.887 146.439 195.622 146.01C195.357 145.582 195 145.258 194.55 145.041C194.106 144.823 193.609 144.714 193.06 144.714C192.51 144.714 192.017 144.82 191.58 145.031C191.149 145.248 190.808 145.572 190.557 146C190.305 146.436 190.179 146.964 190.179 147.584L190.254 149.399Z"
        fill="#FF0018"
      />
      <path
        d="M96.4287 148.143L97.4048 150.835C97.7713 151.846 99.2043 151.838 99.5584 150.822L101.282 145.879C101.63 144.882 103.028 144.85 103.422 145.83L105.555 151.146C105.942 152.111 107.313 152.099 107.684 151.128L109.768 145.66C110.161 144.631 111.638 144.697 111.936 145.757L113.367 150.845C113.656 151.87 115.067 151.978 115.508 151.007L117.963 145.599C118.389 144.66 119.743 144.721 120.083 145.695L121.962 151.086C122.305 152.07 123.678 152.119 124.091 151.162L126.581 145.389C127.007 144.4 128.439 144.496 128.73 145.532L130.224 150.845C130.513 151.87 131.925 151.978 132.365 151.007L134.734 145.79C135.171 144.827 136.57 144.924 136.87 145.938L138.345 150.919C138.645 151.933 140.044 152.03 140.481 151.067L142.963 145.599C143.389 144.66 144.743 144.721 145.083 145.695L146.897 150.9C147.248 151.906 148.665 151.927 149.044 150.93L151.142 145.428C151.525 144.423 152.957 144.455 153.295 145.475L155.062 150.81C155.4 151.83 156.832 151.862 157.215 150.858L159.285 145.428C159.668 144.423 161.1 144.455 161.438 145.475L163.205 150.81C163.543 151.83 164.975 151.862 165.358 150.858L167.428 145.428C167.811 144.423 169.243 144.455 169.581 145.475L171.348 150.81C171.686 151.83 173.118 151.862 173.501 150.858L175.571 145.428C175.954 144.423 177.386 144.455 177.724 145.475L179.491 150.81C179.829 151.83 181.261 151.862 181.644 150.858L183.714 145.428C184.097 144.423 185.529 144.455 185.867 145.475L187.661 150.892C187.996 151.903 189.41 151.947 189.806 150.958L191.939 145.643C192.323 144.687 193.677 144.687 194.061 145.643L196.204 150.983C196.599 151.969 198.008 151.929 198.347 150.923L199.286 148.143V160.714C199.286 162.923 197.495 164.714 195.286 164.714H100.429C98.2196 164.714 96.4287 162.923 96.4287 160.714V148.143Z"
        fill="#FF0018"
      />
      <path
        d="M198.429 152.714L191.286 152.714C190.655 152.714 190.143 153.226 190.143 153.857C190.143 154.488 190.655 155 191.286 155H198.429V152.714Z"
        fill="white"
      />
      <path
        d="M97.5716 161.857L92.4287 161.857V164.143H97.5716V161.857Z"
        fill="#222222"
      />
      <path
        d="M96.4287 116.714C96.4287 114.19 98.4754 112.143 101 112.143H187.397C189.375 112.143 191.13 113.416 191.743 115.297L199.061 137.738C199.21 138.195 199.286 138.674 199.286 139.155V160.143C199.286 162.668 197.239 164.714 194.714 164.714H101C98.4754 164.714 96.4287 162.668 96.4287 160.143V116.714Z"
        stroke="#222222"
        stroke-width="1.71429"
      />
      <circle
        cx="9.14286"
        cy="9.14286"
        r="9.14286"
        transform="matrix(-1 0 0 1 181 153.286)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.71429"
      />
      <circle
        cx="2.28571"
        cy="2.28571"
        r="2.28571"
        transform="matrix(-1 0 0 1 174.143 160.143)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.71429"
      />
      <circle
        cx="9.14286"
        cy="9.14286"
        r="9.14286"
        transform="matrix(-1 0 0 1 130.714 153.286)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.71429"
      />
      <circle
        cx="2.28571"
        cy="2.28571"
        r="2.28571"
        transform="matrix(-1 0 0 1 123.857 160.143)"
        fill="#EDEDED"
        stroke="#222222"
        stroke-width="1.71429"
      />
      <path
        d="M83.4479 164.143H89.1052H91.8573C91.3477 163.625 90.3283 162.341 89.1052 161.347C87.5762 160.104 86.3206 160.3 85.1298 159.327C83.5885 158.069 84.5072 160.802 82.8228 159.739C82.1645 159.324 81.5953 159.477 79.9134 157.613C78.2316 155.749 77.465 157.123 78.5353 158.676C79.6056 160.23 76.0852 158.524 74.4269 157.153C73.1077 156.061 73.2038 159.638 75.4972 161.347C78.5353 163.61 81.1384 164.143 83.4479 164.143Z"
        fill="#EDEDED"
      />
    </svg>
  );
};
