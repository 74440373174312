import { FlexContainer } from "@busie/ui-kit";
import React from "react";

interface Props {
  address: string;
}

export const StopAddress: React.FC<Props> = ({ address }) => {
  return (
    <FlexContainer direction="row" fullWidth>
      {address}
    </FlexContainer>
  );
};
