import { CircularProgress, palette } from "@busie/ui-kit";
import { styled } from "@mui/system";

export const GrayCircle = styled(CircularProgress)(({ theme }) => {
  return `
    color: ${palette.black.plus80};
    position: absolute;
  `;
});

export const ColoredProgress = styled(CircularProgress)`
  &&.MuiCircularProgress-colorSuccess {
    color: ${palette.green.main};
  }

  &&.MuiCircularProgress-colorWarning {
    color: ${palette.supernova.main};
  }

  &&.MuiCircularProgress-colorError {
    color: ${palette.red.main};
  }
`;

export const Container = styled("div")`
  position: relative;
  width: 23px;
  min-width: 23px;
  max-width: 23px;
`;
