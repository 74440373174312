import React from "react";

import FormControlLabelStyled from "./styled/FormControlLabelStyled";
import Checkbox from "@mui/material/Checkbox";
import { createHtmlAttrId } from "../helpers";

export interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
  name?: string;
  indeterminate?: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  labelColor?: string;
  labelWeight?: number | string;
  disabled?: boolean;
}

const CustomCheckbox: React.FC<React.PropsWithChildren<CheckboxProps>> = (
  props: CheckboxProps
) => {
  return (
    <FormControlLabelStyled
      id={`checkbox-${createHtmlAttrId(props.name)}`}
      labelPlacement={props.labelPlacement}
      color={props.labelColor}
      fontWeight={props.labelWeight}
      control={
        <Checkbox
          indeterminate={props.indeterminate}
          checked={props.checked}
          onChange={props.onChange}
          name={props.name}
          disabled={props.disabled || false}
        />
      }
      label={props.name || ""}
    />
  );
};

export default CustomCheckbox;
