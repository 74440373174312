import { AddIcon, palette } from "@busie/ui-kit";
import React from "react";
import { AddQuoteButtonStyled } from "./styled";

interface Props {
  onClick: () => void;
}

export const AddQuoteButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
}) => (
  <AddQuoteButtonStyled
    onClick={onClick}
    icon={<AddIcon color={palette.black.main} />}
  />
);
