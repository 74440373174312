import { styled } from "@mui/system";
import { keyframes } from "@mui/styled-engine";

import { Theme } from "@mui/system";

interface Props {
  theme?: Theme;
  margin?: string;
}
const sideMoving = keyframes`
from {
  left: 0;
}
50% {
  left: calc(100% - 80px);
}
to {
  left: 0;
}
`;

const LoadingBlockStyled = styled("div")<Props>(({ theme, margin }: Props) => {
  const palette = theme?.palette;
  return {
    textAlign: "center",
    margin: margin || "80px 0",
    ".title": {
      color: palette?.black?.plus10,
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "100%",
      marginBottom: "4px",
    },
    ".subtitle": {
      color: palette?.black?.plus20,
      fontSize: "10px",
      marginBottom: "16px",
    },
    ".loading-bar": {
      width: "100%",
      height: "8px",
      background: palette?.black?.plus80,
      position: "relative",

      ".progress-bar": {
        position: "absolute",
        left: "0",
        height: "100%",
        width: "80px",
        animation: `${sideMoving} 3.5s infinite cubic-bezier(0.4, 0.2, 0.6, 0.8)`,
        background: palette?.red?.main,
      },
    },
  };
});

export default LoadingBlockStyled;
