import { Trip, TripLegPriceStructure } from "@busie/utils";
import { useEffect, useState } from "react";

export const useTripVehiclePricing = (
  trip: Trip,
  vehicleId: string
): TripLegPriceStructure[] => {
  const [pricing, setPricing] = useState<TripLegPriceStructure[]>([]);

  useEffect(() => {
    if (!trip || !vehicleId) return setPricing([]);

    const pricing = trip._legs.reduce<TripLegPriceStructure[]>((acc, leg) => {
      for (const legPrice of leg._legPrice) {
        if (legPrice.vehicleId === vehicleId) {
          acc.push(legPrice);
        }
      }
      return acc;
    }, []);

    setPricing(pricing);
  }, [trip, vehicleId]);

  return pricing;
};

export const useTripVehicleFlatTripRate = (
  vehiclePricing: TripLegPriceStructure[]
): number => {
  const [flatTripRate, setFlatTripRate] = useState<number>(0);

  useEffect(() => {
    if (!vehiclePricing) return setFlatTripRate(0);

    const flatTripRate = vehiclePricing.reduce(
      (acc, cv) => Math.max(acc, cv.tripFlatRate),
      0
    );

    setFlatTripRate(flatTripRate);
  }, [vehiclePricing]);

  return flatTripRate;
};

/**
 * @return Map of unix timestamps (ms since Jan 1, 1970) for each trip date to the max flat day rate for that day
 */
export const useTripVehicleFlatDayRates = (
  vehiclePricing: TripLegPriceStructure[]
): Map<number, number> => {
  const [flatDayRates, setFlatDayRates] = useState<Map<number, number>>(
    new Map()
  );

  useEffect(() => {
    if (!vehiclePricing) return setFlatDayRates(new Map());

    const flatDayRates = vehiclePricing.reduce<Map<number, number>>(
      (acc, cv) => {
        const { year, month, day } = cv.legPriceData.legDetails;
        const date = new Date(year, month, day, 0, 0, 0, 0);
        const unixTimestamp = date.getTime();
        const flatDayRate = cv.flatDayRate;

        if (acc.has(unixTimestamp)) {
          acc.set(
            unixTimestamp,
            Math.max(acc.get(unixTimestamp) || 0, flatDayRate)
          );
        } else {
          acc.set(unixTimestamp, flatDayRate);
        }

        return acc;
      },
      new Map()
    );

    setFlatDayRates(flatDayRates);
  }, [vehiclePricing]);

  return flatDayRates;
};
