import React from "react";
import {
  Button,
  InputText,
  ModalContent,
  NoteText,
  Popup,
  palette,
  FlexContainer,
} from "@busie/ui-kit";

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

export const SendQuoteConfirmation: React.FC<
  React.PropsWithChildren<Props>
> = ({ onCancel, onConfirm, open }) => {
  return (
    <Popup isPopupOpened={open} onClose={onCancel} title="Send Quote Again?">
      <ModalContent>
        <InputText>
          Are you sure you want to send this Quote again? This action doesn't
          affect the Quote status.
        </InputText>
        <NoteText>
          This will send a Quote Confirmation Email to the email address set as
          the Quote's "main contact", which includes Trip itinerary details and
          the total quoted price
        </NoteText>
        <NoteText color={palette.red.main}>
          This action cannot be undone.
        </NoteText>
        <FlexContainer justify="flex-end" align="center" columnGap={2} pt={4}>
          <Button typestyle="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button async onClick={onConfirm}>
            Confirm
          </Button>
        </FlexContainer>
      </ModalContent>
    </Popup>
  );
};

export default SendQuoteConfirmation;
