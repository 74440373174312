import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Divider,
  palette,
  FormContainer,
  TextInput,
  Subtitle,
  Callout,
} from "@busie/ui-kit";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./model";
import { SendContractParams } from "../model";

interface Props {
  email: string;
  name: string;
  onSubmit: (data: SendContractParams) => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  onCancel,
  email,
  name,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SendContractParams>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: { email, name },
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Send E-Contract">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Subtitle fontSize="14px">Signer Contact Info</Subtitle>

        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Signer Name"
              errorMessage={errors.name?.message}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Signer Email"
              errorMessage={errors.email?.message}
            />
          )}
        />

        <Divider />

        <Subtitle fontSize="14px">Admin Contact Info</Subtitle>

        <Callout color={palette.black.plus30}>
          <span role="img" aria-label="info">
            ℹ️
          </span>{" "}
          If either input is left blank the default from{" "}
          <em>Organization Settings</em> will be used for that value instead.
        </Callout>

        <Controller
          name="adminName"
          control={control}
          render={({ field }) => <TextInput {...field} label="Admin Name" />}
        />

        <Controller
          name="adminEmail"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label="Admin Email"
              errorMessage={errors.adminEmail?.message}
            />
          )}
        />

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Send E-Contract
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
