import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import { withLDProvider, ProviderConfig } from "launchdarkly-react-client-sdk";

interface Context {
  anonymous?: boolean;
  key?: string;
  name?: string;
  [attribute: string]: unknown;
}

interface Options extends ProviderConfig {
  enabled: boolean;
  context?: Context | (Context & { kind: string });
  streaming?: boolean;
}

export const useFeatureFlags = (
  ApplicationCore: React.FC<React.PropsWithChildren<unknown>>,
  { enabled, clientSideID, context, streaming = false }: Options
) =>
  (enabled &&
    withLDProvider({
      clientSideID,
      context,
      options: {
        streaming,
        inspectors: [
          {
            type: "flag-used",
            name: "dd-inspector",
            method: (key: string, detail) => {
              datadogRum.onReady(() => {
                datadogRum.addFeatureFlagEvaluation(key, detail.value);
              });
            },
          },
        ],
      },
    })(ApplicationCore)) ||
  ApplicationCore;
