import { Amenity } from "@busie/utils";

export const duplicate = (amenities: string[]) => {
  return amenities.reduce((acc, amenity) => {
    let newAmenity;
    switch (amenity) {
      case "ARMREST":
        newAmenity = Amenity.ARMREST;
        break;
      case "CUSTOM":
        newAmenity = Amenity.CUSTOM;
        break;
      case "DVD_PLAYER":
        newAmenity = Amenity.DVD_PLAYER;
        break;
      case "FOOTRESTS":
        newAmenity = Amenity.FOOTRESTS;
        break;
      case "HEADRESTS":
        newAmenity = Amenity.HEADRESTS;
        break;
      case "KNEELING_COACH":
        newAmenity = Amenity.KNEELING_COACH;
        break;
      case "OUTLETS":
        newAmenity = Amenity.OUTLETS;
        break;
      case "RECLINING_SEATS":
        newAmenity = Amenity.RECLINING_SEATS;
        break;
      case "RESTROOM":
        newAmenity = Amenity.RESTROOM;
        break;
      case "SEATBELTS":
        newAmenity = Amenity.SEATBELTS;
        break;
      case "USB_PORTS":
        newAmenity = Amenity.USB_PORTS;
        break;
      case "WIFI":
        newAmenity = Amenity.WIFI;
        break;
      default:
        break;
    }

    return newAmenity ? [...acc, newAmenity] : acc;
  }, [] as number[]);
};
