import React from "react";

import SelectRecipeAssignment from "./SelectRecipeAssignment";
// import CookbookColor from "./CookbookColor";
import RecipeNameField from "./RecipeNameField";

import RecipeInCookbookFormStyled from "./styled/RecipeInCookbookFormStyled";
// import { Cookbook } from "~/rates/RateMenuPage/store/store.types";

// interface Props {
//   cookbooks: Cookbook[];
// }

const RecipeInCookbookForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <RecipeInCookbookFormStyled>
      <SelectRecipeAssignment />
      {/* <CookbookColor /> */}
      <RecipeNameField />
    </RecipeInCookbookFormStyled>
  );
};

export default RecipeInCookbookForm;
