import { styled, Theme } from "@mui/system";

interface Props {
  theme?: Theme;
  isFullPage?: boolean;
}

const MapStyled = styled("div")<Props>(({ isFullPage }) => {
  return isFullPage
    ? {
        position: "fixed",
        inset: 0,
        zIndex: 0,
      }
    : {
        height: "30vh",
        width: "100%",
      };
});

export default MapStyled;
