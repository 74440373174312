import React from "react";

import { DrawerDetailsBlock, palette, PropertyString } from "@busie/ui-kit";

import { VehicleLabel } from "./VehicleLabel";

import { Trip, VehicleTypes } from "@busie/utils";
import { useTripVehicles } from "@busie/core";
interface Props {
  trip: Trip;
}

export const Vehicles: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
}) => {
  const vehicles = useTripVehicles(trip);
  return (
    <DrawerDetailsBlock collapsible name="Vehicles">
      {vehicles.map((vehicle) => (
        <PropertyString
          key={vehicle.id}
          name={<VehicleLabel type={VehicleTypes[vehicle.vehicleType.type]} />}
        >
          <span style={{ color: palette?.red?.main }}>
            {vehicle.licensePlate}
          </span>
        </PropertyString>
      ))}
    </DrawerDetailsBlock>
  );
};
