import { RuleType, IngredientType } from "@busie/utils";

export const cookbookColors: string[] = [
  "transparent",
  "#FF0018",
  "#DA00FF",
  "#00B0FF",
  "#00C814",
  "#FFCC00",
  "#EDEDED",
  "#222222",
];

interface DisplayRuleType {
  name: string;
  value: RuleType;
}

export const ruleTypeOptions: DisplayRuleType[] = [
  {
    name: "Date",
    value: "DATE",
  },
  {
    name: "Total mileage",
    value: "TOTAL_MILES",
  },
  {
    name: "Daily mileage",
    value: "DAILY_MILES",
  },
  {
    name: "Leg mileage",
    value: "LEG_MILES",
  },
  {
    name: "Total time",
    value: "TOTAL_TIME",
  },
  {
    name: "Daily time",
    value: "DAILY_TIME",
  },
  {
    name: "Leg time",
    value: "LEG_TIME",
  },
  {
    name: "Day of week",
    value: "DAY_OF_WEEK",
  },
  {
    name: "Month",
    value: "MONTH",
  },
  {
    name: "Standard",
    value: "STANDARD",
  },
];

export const weekdayItems = [
  { name: "Sunday", value: 0 },
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
];

export const monthItems = [
  { name: "January", value: 0 },
  { name: "February", value: 1 },
  { name: "March", value: 2 },
  { name: "April", value: 3 },
  { name: "May", value: 4 },
  { name: "June", value: 5 },
  { name: "July", value: 6 },
  { name: "August", value: 7 },
  { name: "September", value: 8 },
  { name: "October", value: 9 },
  { name: "November", value: 10 },
  { name: "December", value: 11 },
];

export const ingredientTypes: { [Key in IngredientType]: string } = {
  LIVE_MILES: "Live miles",
  HOURLY: "Hourly cost",
  DAILY_MINIMUM: "Daily minimum",
  LEG_MINIMUM: "Leg minimum",
  DEAD_HEAD_MILES: "Deadhead miles",
  PER_NIGHT: "Per night",
  PER_DAY_NO_TRAVEL: "Per day “without travel”",
  EXTRA_DRIVER_FEE: "Per extra driver",
  FLAT_LEG_RATE: "Per leg",
  FLAT_TRIP_RATE: "Per trip",
  FLAT_DAY_RATE: "Per day",
};

export const ingredientTypesList = Object.keys(ingredientTypes).map((type) => ({
  name: ingredientTypes[type as IngredientType],
  value: type,
}));

export const baseIngredientTypes: IngredientType[] = [
  "LIVE_MILES",
  "HOURLY",
  "DAILY_MINIMUM",
  "LEG_MINIMUM",
  "DEAD_HEAD_MILES",
];

export const sideIngredientTypes: IngredientType[] = [
  "PER_NIGHT",
  "PER_DAY_NO_TRAVEL",
  "EXTRA_DRIVER_FEE",
  "FLAT_LEG_RATE",
  "FLAT_TRIP_RATE",
  "FLAT_DAY_RATE",
];

export const ruleTypes: { [Key in RuleType]: string } = {
  DATE: "Date",
  TOTAL_MILES: "Total mileage",
  DAILY_MILES: "Daily mileage",
  LEG_MILES: "Leg mileage",
  TOTAL_TIME: "Total time",
  DAILY_TIME: "Daily time",
  LEG_TIME: "Leg time",
  DAY_OF_WEEK: "Day of week",
  MONTH: "Month",
  STANDARD: "Standard",
};
