import React from "react";
import { observer } from "mobx-react-lite";

import RateMenuPageStore from "~/rates/RateMenuPage/store";
import RateRuleForm from "~/rates/RateMenuPage/components/CreateRecipeModal/RateRuleForm";
import IngredientsSelectionForm from "~/rates/RateMenuPage/components/CreateRecipeModal/IngredientsSelectionForm";
import RateRuleFormStyled from "~/rates/RateMenuPage/components/CreateRecipeModal/styled/RateRuleFormStyled";

const RateRuleAndIngredientsBlock: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const store = RateMenuPageStore.createRecipeModal;

    return (
      <>
        <RateRuleFormStyled>
          <RateRuleForm
            value={
              store.formData.ruleTypeValue[store.formData.ruleType] as string
            }
            typeValue={store.formData.ruleType}
            onTypeChange={store.setRuleType}
            onValueChange={store.setRateTypeValue}
          />
        </RateRuleFormStyled>
        <IngredientsSelectionForm />
      </>
    );
  });

export default RateRuleAndIngredientsBlock;
