import { styled } from "@mui/system";

const DateFieldStyled = styled("div")(() => {
  return {
    width: "100%",
    maxWidth: "200px",
  };
});

export default DateFieldStyled;
