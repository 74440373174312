import { palette, theme } from "@busie/ui-kit";
import { styled } from "@mui/styles";

const ReservationFormTabStyled = styled("div")(
  ({ isActive, isPast }: { isActive?: boolean; isPast?: boolean }) => {
    const color = isActive ? palette.black.main : palette.black.plus50;
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 1,
      columnGap: theme.spacing(1),
      borderBottom: `${isActive ? palette.black.main : palette.black.plus50} ${
        isActive ? 2 : 1
      }px solid`,
      "& > *:first-child": {
        height: theme.spacing(2),
        minWidth: theme.spacing(2),
        maxWidth: theme.spacing(2),
        border: `${color} 1px solid`,
        borderRadius: "100px",
        backgroundColor: isActive
          ? palette.black.main
          : isPast
          ? palette.black.plus50
          : "transparent",
        color:
          isActive || isPast ? palette.black.plus100 : palette.black.plus50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "12px",
      },
      "& > *:last-child": {
        color: color,
      },
    };
  }
);

export default ReservationFormTabStyled;
