import { AmountField } from "@busie/ui-kit";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  min: number;
  max: number;
  errorMessage?: string;
}

export const Controlled: <T extends FieldValues>(
  props: Props<T>
) => React.ReactElement = ({
  control,
  name,
  label,
  min,
  max,
  errorMessage,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <AmountField
          {...field}
          label={label}
          minValue={min}
          maxValue={max}
          errorMessage={errorMessage}
        />
      )}
    />
  );
};
