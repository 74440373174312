import * as yup from "yup";

export const schema = yup.object({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email().required("required"),
  phoneNumber: yup
    .string()
    .nullable()
    .required("required")
    .matches(/^\+[0-9]+$/, "must be a valid phone number"),
  metadata: yup
    .array()
    .of(yup.object({ name: yup.string(), value: yup.string() })),
});
