import React from "react";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": {
        "pricing-table-id": string;
        "publishable-key": string;
        "client-reference-id": string;
      };
    }
  }
}

interface Props {
  pricingTableId: string;
  publishableKey: string;
  organizationId: string;
}

export const PricingTable: React.FC<React.PropsWithChildren<Props>> = ({
  pricingTableId,
  publishableKey,
  organizationId,
}) => {
  if (!pricingTableId || !publishableKey || !organizationId) return null;

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      client-reference-id={organizationId}
    ></stripe-pricing-table>
  );
};
