// import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

export const usePagination = (): [number, (page: number) => void] => {
  const history = useHistory();
  const { search } = useLocation();

  const currentPage = Number(new URLSearchParams(search).get("page")) || 1;

  const setPage = (page: number) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page.toString());
    history.push({
      search: `?${searchParams.toString()}`,
    });
  };

  return [currentPage, setPage];
};
