import { FilterValues } from "./interface";

export const defaultFilter: FilterValues = {
  dispatches: [],
  statuses: [],
  pickupDates: [null, null],
  createdDates: [null, null],
  assigneeId: "ALL",
};

const FILTER_ASSIGNEE_UNASSIGNED = { value: "UNASSIGNED", name: "Unassigned" };
const FILTER_ASSIGNEE_ALL = { value: "ALL", name: "All" };

export const DEFAULT_ASSIGNEE_FILTERS = [
  FILTER_ASSIGNEE_ALL,
  FILTER_ASSIGNEE_UNASSIGNED,
];
