import { createGroupMetadata } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";
import { useMutation } from "react-query";
import { customersModel } from "~/CustomersPage/entity";

export const useCreateGroupMetadata = (id: string) => {
  const token = useCustomerAuthToken();

  return useMutation(async (data: { key: string; value: string }) => {
    const result = await createGroupMetadata(token, id, data);

    customersModel.invalidateCustomersQueries();

    return result;
  });
};
