import React from "react";

import CustomerForm from "./CustomerForm";
import CustomerFormEmbedded from "./embedded/CustomerForm";

interface Props {
  guestAuthToken?: string;
  embedded?: boolean;
  customersAuthToken?: string;
  children?: React.ReactNode;
  goNext: () => void;
}

const CustomerFormBlock: React.FC<React.PropsWithChildren<Props>> = ({
  customersAuthToken = "",
  guestAuthToken = "",
  ...props
}: Props) => {
  if (props.embedded) {
    return <CustomerFormEmbedded guestAuthToken={guestAuthToken} {...props} />;
  }
  return <CustomerForm customersAuthToken={customersAuthToken} {...props} />;
};

export default CustomerFormBlock;
