import { ChefQuote } from "@busie/api";
import { useUsers } from "@busie/core";
import {
  H3,
  Loading,
  OrganizationMemberWithAvatar,
  Text,
  palette,
} from "@busie/ui-kit";
import { centsToDollars, currencyFormatter } from "@busie/utils";
import React from "react";
import {
  Assignee,
  DrawerObjectDetails,
} from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  quote: ChefQuote;
}

export const QuoteInfo: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
}) => {
  return (
    <DrawerObjectDetails
      name="Quote Info"
      primaryProperties={[
        ["ID", quote._id],
        ["Quoted Price", currencyFormatter(centsToDollars(quote.PRICE))],
      ]}
      secondaryProperties={[
        ["Trips Subtotal", currencyFormatter(centsToDollars(quote.SUBTOTAL))],
        [<H3>Surcharges</H3>, ""],
        // ["Driver Gratuity", `${quote.SURCHARGES.DRIVER_GRATUITY}%`],
        ["Fuel Surcharge", `${quote.SURCHARGES.FUEL_SURCHARGE}%`],
        ["Last Minute Surcharge", `${quote.SURCHARGES.LAST_MINUTE_SURCHARGE}%`],
        ["Tax", `${quote.SURCHARGES.TAX_SURCHARGE}%`],
        [<H3>Markups</H3>, ""],
        [
          "Network Markup",
          `${quote.MARKUPS["network_percentage_markup"]}% + ${currencyFormatter(
            centsToDollars(quote.MARKUPS["network_fixed_markup"])
          )}`,
        ],
        [
          "Platform Markup",
          `${
            quote.MARKUPS["platform_percentage_markup"]
          }% + ${currencyFormatter(
            centsToDollars(quote.MARKUPS["platform_fixed_markup"])
          )}`,
        ],
        [
          "Online Payment Markup",
          `${quote.MARKUPS["payment_percentage_markup"]}% + ${currencyFormatter(
            centsToDollars(quote.MARKUPS["payment_fixed_markup"])
          )}`,
        ],
      ]}
    />
  );
};
