import { Loading, Modal } from "@busie/ui-kit";
import React from "react";
import { GroupForm } from "~/CustomersPage/entity";
import { useUpdateGroup } from "./model";
import { Group } from "@busie/utils";
import { MutateOptions } from "react-query";
import { UpdateGroupPayload } from "@busie/api";
import { customersModel } from "~/CustomersPage/entity";

interface Props {
  groupId: string;
  isOpen: boolean;
  onSuccess: (group: Group) => void;
  onError: (e: unknown) => void;
  onClose: () => void;
}

export const Feature: React.FC<Props> = ({
  isOpen,
  groupId,
  onSuccess,
  onError,
  onClose,
}) => {
  const {
    data: group,
    isLoading,
    isError,
  } = customersModel.useFetchGroup(groupId);
  const { mutateAsync: updateGroup } = useUpdateGroup(groupId);

  const options: MutateOptions<Group, unknown, UpdateGroupPayload, unknown> = {
    onSuccess,
    onError,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <Loading padding="0px" />
      ) : isError || !group ? (
        <div>Could not fetch group for editing</div>
      ) : (
        <GroupForm
          group={group}
          onSubmit={async ({ name, type }) =>
            updateGroup({ name, type }, options)
          }
          onCancel={onClose}
        />
      )}
    </Modal>
  );
};
