import React from "react";

import {
  Select as BaseSelect,
  Loading,
  OrganizationMemberWithAvatar,
  Text,
  palette,
} from "@busie/ui-kit";
import { UsersMap } from "@busie/core";
import { getAssigneeFilterOptions } from "../../lib";

interface Props {
  users: UsersMap;
  selected: string;
  loading: boolean;
  onChange: (value: string) => void;
}

const shouldUseMemberWithAvatar = (selected: string) =>
  selected !== "UNASSIGNED" && selected !== "ALL";

const renderValue = (selected: string, loading: boolean, users: UsersMap) =>
  loading ? (
    <Loading size={24} padding="0" />
  ) : shouldUseMemberWithAvatar(selected as string) ? (
    <OrganizationMemberWithAvatar
      color={palette.black.plus10}
      size={24}
      name={users[selected as string]?.name ?? ""}
      imgSrc={users[selected as string]?.picture ?? ""}
    />
  ) : (
    <Text color={palette.black.plus10}>
      {(selected as string)[0] + (selected as string).slice(1).toLowerCase()}
    </Text>
  );

export const Select: React.FC<Props> = ({
  onChange,
  selected,
  users,
  loading,
}) => (
  <BaseSelect
    label="Assignee"
    hideErrorSpace
    onChange={(e) => onChange(e.target.value as string)}
    value={selected}
    items={getAssigneeFilterOptions(users ?? {})}
    renderValue={(selected) => renderValue(selected as string, loading, users)}
    fullWidth
  />
);
