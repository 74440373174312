import { ChefQuote } from "@busie/api";
import { useUsers } from "@busie/core";
import {
  Button,
  DrawerDetailsBlock,
  FlexContainer,
  H2,
  Loading,
  PropertyString,
  palette,
} from "@busie/ui-kit";
import { QuoteAndBookingStatus } from "@busie/utils";
import React from "react";
import { Assignee, QnBStatusText } from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  quote: ChefQuote;
  onClickSendQuote: () => void;
  onClickDeclineQuote: () => void;
  onClickConfirmQuote: () => void;
  onClickAcceptQuote: () => void;
}

export const StatusBlock: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
  onClickSendQuote,
  onClickDeclineQuote,
  onClickConfirmQuote,
  onClickAcceptQuote,
}) => {
  const { data: usersMap = {}, isLoading: isUsersLoading } = useUsers();

  const getStatusBlock = (status: QuoteAndBookingStatus) => {
    const StatusText = <QnBStatusText status={status} />;
    switch (status) {
      case QuoteAndBookingStatus.PENDING:
        return (
          <>
            {StatusText}
            <Button onClick={onClickConfirmQuote}>Send Quote</Button>
          </>
        );
      case QuoteAndBookingStatus.CONFIRMED:
        return (
          <>
            {StatusText}
            <Button onClick={onClickAcceptQuote}>Accept Quote</Button>
          </>
        );
      case QuoteAndBookingStatus.ACCEPTED:
        return StatusText;
      case QuoteAndBookingStatus.DECLINED:
        return StatusText;
      default:
        return <span>Could not determine status...</span>;
    }
  };

  return (
    <DrawerDetailsBlock noHeader>
      <FlexContainer direction="column" rowGap={1} fullWidth align="center">
        <FlexContainer
          direction="row"
          justify="space-between"
          fullWidth
          align="center"
        >
          <H2 weight={600}>Status</H2>

          <FlexContainer align="center" columnGap={5}>
            {getStatusBlock(quote.STATUS as QuoteAndBookingStatus)}
          </FlexContainer>
        </FlexContainer>
        {![
          QuoteAndBookingStatus.PENDING,
          QuoteAndBookingStatus.CANCELED,
          QuoteAndBookingStatus.DECLINED,
        ].includes(quote.STATUS as QuoteAndBookingStatus) && (
          <PropertyString
            justify="space-between"
            margin="16px 0 5px"
            name="Quote confirmation email"
            nameColor={palette.black.main}
          >
            <Button typestyle="secondary" onClick={onClickSendQuote}>
              Send Quote
            </Button>
          </PropertyString>
        )}
        {![
          QuoteAndBookingStatus.DECLINED,
          QuoteAndBookingStatus.CANCELED,
        ].includes(quote.STATUS as QuoteAndBookingStatus) && (
          <PropertyString
            justify="space-between"
            name="Decline Quote"
            margin="16px 0 5px"
            nameColor={palette.red.main}
          >
            <Button typestyle="accent" async onClick={onClickDeclineQuote}>
              Decline
            </Button>
          </PropertyString>
        )}
        {quote.REASON_FOR_DECLINE && quote.STATUS === "DECLINED" && (
          <FlexContainer direction="column" fullWidth>
            <PropertyString
              justify="space-between"
              name="Reason For Decline"
              margin="16px 0 5px"
            >
              {quote.REASON_FOR_DECLINE}
            </PropertyString>
          </FlexContainer>
        )}
        <PropertyString
          justify="space-between"
          name="Assignee"
          margin="16px 0 5px"
        >
          {isUsersLoading ? (
            <Loading size={24} padding="0px" />
          ) : (
            <Assignee
              assignee={
                quote.ASSIGNEE_ID && usersMap[quote.ASSIGNEE_ID]
                  ? {
                      name: usersMap[quote.ASSIGNEE_ID].name,
                      imgSrc: usersMap[quote.ASSIGNEE_ID].picture,
                    }
                  : undefined
              }
            />
          )}
        </PropertyString>
      </FlexContainer>
    </DrawerDetailsBlock>
  );
};
