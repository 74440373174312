import * as yup from "yup";

const IS_REQUIRED = "";

export const schema = yup.object({
  bookingId: yup.string().required(IS_REQUIRED),
  amount: yup.number().moreThan(0).required(IS_REQUIRED),
  customer: yup
    .object({
      id: yup.string().required(IS_REQUIRED),
      groupId: yup.string().required(IS_REQUIRED),
      groupName: yup.string().required(IS_REQUIRED),
      name: yup.string().required(IS_REQUIRED),
      email: yup.string().required(IS_REQUIRED).email("must be a valid email"),
    })
    .required(IS_REQUIRED),
  revenueShareConfigId: yup.string().required(IS_REQUIRED),
  daysUntilDue: yup
    .number()
    .required(IS_REQUIRED)
    .moreThan(0, "must be at least one day"),
});
