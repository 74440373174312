import { useAmplitude } from "@busie/core";
import { notificationStore } from "@busie/features";
import { Modal } from "@busie/ui-kit";
import { Invoice } from "@busie/utils";
import React from "react";
import { SendInvoiceForm } from "~/QuotesAndBookingsPage/entity/Invoice";
import { useSendInvoice } from "./model";

interface Props {
  isOpen: boolean;
  invoiceId: string;
  email: string;
  onClose: () => void;
  onInvoiceSent: (invoice: Invoice) => Promise<void> | void;
}

export const SendInvoice: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  invoiceId,
  email,
  onClose,
  onInvoiceSent,
}) => {
  const { mutateAsync: sendInvoice } = useSendInvoice();

  const onSubmit = async () => {
    try {
      await sendInvoice(invoiceId, {
        onSuccess: async (result) => {
          onClose();
          await onInvoiceSent(result);
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <SendInvoiceForm email={email} onSubmit={onSubmit} onCancel={onClose} />
    </Modal>
  );
};
