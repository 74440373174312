import { styled } from "@mui/system";

import { inputLabelClasses } from "@mui/material/InputLabel";

import { InputLabelDotStyles } from "../../../../styles";

const DepartureBlockStyled = styled("div")((props) => {
  const palette = props.theme.palette;
  return {
    display: "grid",
    gridTemplateColumns: "266px 160px 160px",
    columnGap: "16px",
    paddingBottom: "24px",
    paddingLeft: "31px",
    borderLeft: `2px dashed ${palette.green.main}`,
    [props.theme.breakpoints.down("tablet")]: {
      border: 0,
      paddingLeft: 0,
      gridAutoColumns: "1fr",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
    },
    // flexDirection: "row",
    [`.${inputLabelClasses.root}`]: {
      position: "relative",
      overflow: "initial",
      marginTop: "0",
      height: "12px",
      lineHeight: "1",
    },

    ".address-input": {
      [props.theme.breakpoints.down("tablet")]: {
        gridColumn: "1/4",
        marginBottom: "16px",
      },
      [`.${inputLabelClasses.root}`]: {
        "&:before": {
          ...InputLabelDotStyles,
          left: "-36px",
          [props.theme.breakpoints.down("tablet")]: {
            display: "none",
          },
        },
      },
    },
    ".departure-date-input": {
      [props.theme.breakpoints.down("tablet")]: {
        gridColumn: "1/3",
      },
    },
    "&.last-stop-block": {
      borderColor: "transparent",
      display: "block",
      maxWidth: "356px",

      [props.theme.breakpoints.down("tablet")]: {
        maxWidth: "100%",
      },
      [`.address-input .${inputLabelClasses.root}`]: {
        "&:before": {
          backgroundColor: palette.black.main,
        },
      },
    },
    ".helper-text": {
      fontSize: "10px",
      marginTop: "8px",
      color: palette.black.plus10,
      "&.text-right": {
        textAlign: "right",
        gridColumnStart: 2,
        gridColumnEnd: 4,
      },
    },

    ".error-message": {
      gridColumnStart: 2,
      gridColumnEnd: 4,
    },
  };
});

export default DepartureBlockStyled;
