import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import RateMenuPageStore from "~/rates/RateMenuPage/store";

import { FormLabel } from "@busie/ui-kit";
import { TextInput, TextInputEvent } from "@busie/ui-kit";

import RecipeNameFieldStyled from "~/rates/RateMenuPage/components/CreateRecipeModal/styled/RecipeNameFieldStyled";

const ERROR_MESSAGE = "This field is required";

const RecipeNameField: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const store = RateMenuPageStore.createRecipeModal;

    const [isNameDirty, setIsNameDirty] = useState(false);
    const errorMessage =
      isNameDirty && !store.formData.name ? ERROR_MESSAGE : "";
    const handleChange = (e: TextInputEvent) => {
      setIsNameDirty(true);
      store.updateRecipeName(e.target.value);
    };
    return (
      <RecipeNameFieldStyled>
        <FormLabel>Recipe Name</FormLabel>
        <TextInput
          required
          placeholder="e.g. Standard Organization Rates"
          value={store.formData.name}
          onChange={handleChange}
          errorMessage={errorMessage}
        />
      </RecipeNameFieldStyled>
    );
  }
);

export default RecipeNameField;
