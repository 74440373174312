import { Button, FlexContainer } from "@busie/ui-kit";

interface Props {
  onClose: () => void;
  onReset: () => void;
  onApply: () => void;
}

export const FilterControls: React.FC<Props> = ({
  onClose,
  onReset,
  onApply,
}) => (
  <FlexContainer
    direction="row"
    columnGap={2}
    align="center"
    justify="flex-end"
    fullWidth
  >
    <Button typestyle="secondary" onClick={onClose}>
      Close
    </Button>
    <Button typestyle="secondary" onClick={onReset}>
      Reset
    </Button>
    <Button typestyle="primary" onClick={onApply}>
      Apply
    </Button>
  </FlexContainer>
);
