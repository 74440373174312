import { styled } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";

const MenuItemStyled = styled(MenuItem)((props) => {
  const palette = props.theme.palette;
  return {
    lineHeight: "150%",
    minHeight: "auto",
    color: palette.black.plus10,
  };
});

export default MenuItemStyled;
