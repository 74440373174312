import React from "react";
import { Box } from "@mui/system";

import {
  FlexContainer,
  Loading,
  palette,
  theme,
  Subtitle,
} from "@busie/ui-kit";
import { Customer, Group } from "@busie/utils";

import { CustomersOrganizationRow } from "./CustomersOrganizationRow";

interface Props {
  isLoading: boolean;
  areCustomersNotFound: boolean;
  organizations: Group[];
  onCustomerAdd(id: string): void;
  onCustomerEdit(customer: Customer): void;
  onCustomerDelete(customer: Customer): void;
  onCustomerClick(customer: Customer): void;
  onGroupClick(group: Group): void;
  onGroupEdit(id: string): void;
  createCustomerView: React.ReactNode;
  updateCustomerView: React.ReactNode;
  deleteCustomerView: React.ReactNode;
  updateGroupView: React.ReactNode;
}

export const CustomersOrganizationsList: React.FC<
  React.PropsWithChildren<Props>
> = ({
  isLoading,
  areCustomersNotFound,
  organizations,
  onCustomerAdd,
  onCustomerEdit,
  onCustomerDelete,
  onCustomerClick,
  onGroupClick,
  onGroupEdit,
  createCustomerView,
  updateCustomerView,
  deleteCustomerView,
  updateGroupView,
}) => {
  return (
    <>
      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        px={6}
        py={2}
        fullWidth
        sx={{ borderBottom: `1px ${palette.black.plus70} solid` }}
      >
        <FlexContainer fullWidth justify="flex-start" align="center">
          <Subtitle>Group</Subtitle>
        </FlexContainer>
        <FlexContainer fullWidth justify="flex-start" align="center" pl={4}>
          <Subtitle>Type</Subtitle>
        </FlexContainer>
        <FlexContainer fullWidth justify="flex-start" align="center" pl={4}>
          <Subtitle>Members</Subtitle>
        </FlexContainer>
        <FlexContainer fullWidth justify="flex-end" align="center">
          {/* empty for spacing purposes */}
        </FlexContainer>
      </FlexContainer>

      {isLoading ? (
        <Loading />
      ) : areCustomersNotFound ? (
        <p>No customers found!</p>
      ) : (
        <>
          <FlexContainer direction="column" w="100%">
            {organizations.map((organization) => (
              <CustomersOrganizationRow
                key={organization.id}
                organization={organization}
                onCustomerAdd={() => onCustomerAdd(organization.id)}
                onCustomerEdit={onCustomerEdit}
                onCustomerDelete={onCustomerDelete}
                onCustomerClick={onCustomerClick}
                onGroupClick={onGroupClick}
                onGroupEdit={() => onGroupEdit(organization.id)}
              />
            ))}
          </FlexContainer>
          {createCustomerView}
          {updateCustomerView}
          {deleteCustomerView}
          {updateGroupView}
        </>
      )}
    </>
  );
};
