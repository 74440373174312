import React from "react";

interface Props {
  color?: string;
}

export const AddIcon: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11"
        y="3"
        width="2"
        height="18"
        rx="1"
        fill={props.color || "#fff"}
      />
      <rect
        x="3"
        y="11"
        width="18"
        height="2"
        rx="1"
        fill={props.color || "#fff"}
      />
    </svg>
  );
};
