import { styled } from "@mui/system";

export const ListControlsButtonsStyled = styled("div")(() => {
  return {
    "&": {
      marginLeft: "20px",
      // ".menu-parent": {
      //   position: "relative",
      // },
      // ".menu-block": {
      //   position: "absolute",
      //   bottom: "8px",
      // },
    },
  };
});
