import React from "react";

import { datetimeFormatter as dateFormat } from "@busie/utils";

import { H4, Text, Button, FlexContainer, theme } from "@busie/ui-kit";

import ColumnsStyled from "../styled/ColumnsStyled";
import VehicleBookingStyled from "../styled/VehicleBookingStyled";

import RevokeVehicleReservationPopup from "./RevokeVehicleReservationPopup";
import ReservationTypeBadge from "~/vehicles/Vehicles/components/ReservationTypeBadge";
import { ReservationStatuses, ReservationTypes } from "@busie/utils";
import ReservationStatusBadge from "~/vehicles/Vehicles/components/ReservationStatusBadge";
import { useMediaQuery } from "@mui/material";

interface Props {
  type: ReservationTypes;
  dateRange: [Date | null, Date | null];
  vehicleTypeName: string;
  reservationId: string;
  status: ReservationStatuses;
}

const VehicleBooking: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  dateRange,
  vehicleTypeName,
  reservationId,
  status,
}: Props) => {
  const [showRevokePopup, setShowRevokePopup] = React.useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const DatesTextComponent = isMobile ? H4 : Text;
  return (
    <VehicleBookingStyled fullWidth align="center" justify="space-between">
      <ColumnsStyled isMobile={isMobile}>
        <ReservationTypeBadge type={type} isMobile={isMobile} />
        <FlexContainer align="center">
          <DatesTextComponent>
            {`${
              dateRange[0] &&
              dateFormat(dateRange[0], isMobile ? "complete-short" : "datetime")
            }${
              dateRange[1] &&
              " - " +
                dateFormat(
                  dateRange[1],
                  isMobile ? "complete-short" : "datetime"
                )
            }`}
          </DatesTextComponent>
        </FlexContainer>
        <ReservationStatusBadge
          status={status}
          maxWidth="120px"
          isMobile={isMobile}
        />
        <div className="actions-block">
          <Button
            size="small"
            typestyle="secondary"
            onClick={() => setShowRevokePopup(true)}
          >
            Revoke
          </Button>
        </div>
      </ColumnsStyled>
      <RevokeVehicleReservationPopup
        onClose={() => setShowRevokePopup(false)}
        type={type}
        id={reservationId}
        vehicleTypeName={vehicleTypeName}
        isPopupOpened={showRevokePopup}
      />
    </VehicleBookingStyled>
  );
};

export default VehicleBooking;
