import { styled } from "@mui/system";

const Header = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "48px",
  };
});

export default Header;
