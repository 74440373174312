import React from "react";

import { FlexContainer, MenuHeader } from "@busie/ui-kit";
import { Button, AddIcon } from "@busie/ui-kit";

import CookbooksFilter from "./CookbooksFilter";

interface Props {
  onAddRateRecipe: () => void;
  typeFilter: string;
  onTypeFilterChange: (value: string) => void;
  nameFilter: string;
  onNameFilterChange: (value: string) => void;
}

const PageContainer: React.FC<React.PropsWithChildren<Props>> = ({
  onAddRateRecipe,
  children,
  typeFilter,
  onTypeFilterChange,
  nameFilter,
  onNameFilterChange,
}) => {
  return (
    <MenuHeader outlined>
      <FlexContainer direction="column" fullWidth noPadding maxW={1360}>
        <FlexContainer
          direction="row"
          justify="space-between"
          fullWidth
          py={2}
          px={3}
        >
          <CookbooksFilter
            typeFilter={typeFilter}
            onTypeFilterChange={onTypeFilterChange}
            nameFilter={nameFilter}
            onNameFilterChange={onNameFilterChange}
          />
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              onAddRateRecipe();
            }}
          >
            Add rate recipe
          </Button>
        </FlexContainer>

        {children}
      </FlexContainer>
    </MenuHeader>
  );
};

export default PageContainer;
