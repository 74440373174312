import React, { useRef } from "react";
import TimePicker from "react-datepicker";

import { DayjsExt, dayjsExt } from "@busie/utils";
import TimeInputStyled from "./TimeInputStyled";
import "react-datepicker/dist/react-datepicker.css";
import InputLabel from "../InputLabel";
import { createHtmlAttrId } from "../helpers";

interface Props {
  value: DayjsExt | null;
  label?: string;
  required?: boolean;
  onChange?: (value: DayjsExt | null) => void;
  disabled?: boolean;
  placeholder?: string;
  excludedTimes?: Date[];
  fixedWidth?: string;
  withPadding?: boolean;
}

const TimeInput: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  label,
  required,
  onChange,
  disabled,
  placeholder,
  excludedTimes,
  fixedWidth,
  withPadding,
}: Props) => {
  const containerEl = useRef(null);
  const id = `time-input-${createHtmlAttrId(label)}`;
  const [dateTime, setDateTime] = React.useState(value?.toDate() || new Date());

  return (
    // @ts-expect-error TS expects theme for some reason
    <TimeInputStyled
      ref={containerEl}
      fixedWidth={fixedWidth}
      withPadding={withPadding}
    >
      {label && (
        <InputLabel htmlFor={id} required={required}>
          {label}
        </InputLabel>
      )}
      <TimePicker
        selected={dateTime}
        onChange={(date: Date) => {
          setDateTime(date);
          onChange && onChange(dayjsExt(date));
        }}
        showTimeSelect
        showTimeSelectOnly
        placeholderText={placeholder || "Select time"}
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        excludeTimes={excludedTimes}
        disabled={disabled}
      />
    </TimeInputStyled>
  );
};

export default TimeInput;
