import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/system";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import { RuleType, reorder } from "@busie/utils";

import {
  Popup,
  FlexContainer,
  Button,
  DragHandle,
  Subtitle,
  InputText,
} from "@busie/ui-kit";

import { ruleTypeOptions } from "~/rates/RateMenuPage/store/constants";

interface Props {
  handleClose: () => void;
  handleSubmit: (newOrder: RuleType[]) => void;
  isPopupOpened: boolean;
  cookbookName: string;
  initialOrder: RuleType[];
}

const ModalContent = styled("div")(({ theme }) => {
  return {
    maxWidth: "480px",
    padding: theme?.spacing(5),
    paddingTop: 0,
  };
});

const RulesContainer = styled(FlexContainer)(({ theme }) => {
  return {
    backgroundColor: theme?.palette?.black.plus80,
    padding: theme?.spacing(1),
    margin: `${theme?.spacing(3)} 0`,
  };
});

const RuleTypeComponent = styled(FlexContainer)(({ theme }) => {
  return {
    backgroundColor: theme?.palette?.black.plus85,
    padding: theme?.spacing(0.5),
  };
});

const RulesOrderModal: FC<React.PropsWithChildren<Props>> = ({
  handleClose,
  isPopupOpened,
  handleSubmit,
  initialOrder,
  cookbookName,
}) => {
  const [order, setOrder] = useState<RuleType[]>([]);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setOrder(initialOrder);
  }, [initialOrder]);

  const sortedRuleTypeOptions = useMemo(() => {
    return order.map((ruleType) =>
      ruleTypeOptions.find((rto) => rto.value === ruleType)
    );
  }, [order]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newOrder = reorder(
      order,
      result.source.index,
      result.destination.index
    ) as RuleType[];

    setOrder(newOrder);
    setIsDirty(true);
  };

  const onSave = async () => {
    await handleSubmit(order);
    await setIsDirty(false);
  };

  const onClose = useCallback(() => {
    handleClose();
    setOrder(initialOrder);
  }, [initialOrder, handleClose]);

  return (
    <Popup
      onClose={onClose}
      isPopupOpened={isPopupOpened}
      title="Rule Priority"
    >
      <ModalContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <InputText>
            The calculation of each leg price is influenced by the rules order,
            established in the cookbook. If necessary, you can change the rules
            order by moving a specific rule higher or lower in the list.
          </InputText>

          <Droppable droppableId="rulesOrderDroppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <RulesContainer
                  fullWidth
                  direction="column"
                  borderBetweenY
                  align="stretch"
                >
                  {sortedRuleTypeOptions.map((ruleType, index) => (
                    <Draggable
                      key={index}
                      index={index}
                      draggableId={index.toString()}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RuleTypeComponent
                            fullWidth
                            columnGap={1}
                            align="center"
                          >
                            <DragHandle />
                            <InputText>{ruleType?.name}</InputText>
                          </RuleTypeComponent>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </RulesContainer>
              </div>
            )}
          </Droppable>

          <InputText>Cookbook to which the rules order applies:</InputText>
          <Subtitle>● {cookbookName}</Subtitle>

          <FlexContainer justify="flex-end" align="center" columnGap={2} pt={4}>
            <Button typestyle="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!isDirty} async onClick={onSave}>
              Save
            </Button>
          </FlexContainer>
        </DragDropContext>
      </ModalContent>
    </Popup>
  );
};

export default RulesOrderModal;
