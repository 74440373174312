import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

import { VehicleIcon } from "@busie/core";
import {
  Divider,
  FlexContainer,
  H2,
  H3,
  H4,
  IconButton,
  Recipe,
  ScrollableFlexContainer,
  Text,
  theme,
} from "@busie/ui-kit";
import {
  centsToDollars,
  currencyFormatter,
  dayjsExt,
  Vehicle,
  VehicleTypes,
} from "@busie/utils";
import { Experience } from "@busie/api";
import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import { getTripFlatRate } from "./helpers";

interface Props {
  trip: Experience;
  vehicles: Vehicle[];
  isOpen: boolean;
  onClose: () => void;
  onClickRecipe: (recipeId: string) => void;
}

export const TripPriceBreakdown: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  vehicles,
  isOpen,
  onClose,
  onClickRecipe,
}) => {
  const dailyPriceBreakdown = trip.TRIP_DAILY_PRICE_BREAKDOWN;

  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const [vehiclesMap, setVehiclesMap] = useState<Map<string, Vehicle>>(
    new Map()
  );

  // const vehiclesMap = new Map(vehicles.map((v) => [v.id, v]));

  useEffect(() => {
    const map = new Map(vehicles.map((v) => [v.id, v]));
    setVehiclesMap(map);
  }, [vehicles]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <FlexContainer direction="column" rowGap={1}>
        <H2>Trip Price Breakdown</H2>
        <Divider />
        <ScrollableFlexContainer
          direction="column"
          rowGap={1}
          fullWidth
          pb={2}
          px={1}
        >
          <FlexContainer justify="space-between" fullWidth>
            <H2 weight={600}>Total Trip Price</H2>
            <H2 weight={600}>
              {currencyFormatter(centsToDollars(Number(trip.PRICE)))}
            </H2>
          </FlexContainer>

          <FlexContainer justify="space-between" fullWidth>
            <H2 weight={500}>Flat Trip Rate</H2>
            <H2 weight={500}>
              {currencyFormatter(centsToDollars(Number(getTripFlatRate(trip))))}
            </H2>
          </FlexContainer>

          <Divider />

          <H2 weight={600}>Daily Rates</H2>

          <FlexContainer direction="column" fullWidth>
            {(dailyPriceBreakdown || []).map(
              ({
                TIMESTAMP,
                FLAT_DAY_RATE,
                PRICE,
                VEHICLES_PRICE_BREAKDOWN,
              }) => (
                <FlexContainer
                  direction="column"
                  fullWidth
                  key={TIMESTAMP}
                  rowGap={2}
                >
                  <FlexContainer
                    direction="row"
                    justify="space-between"
                    fullWidth
                    align="center"
                  >
                    <FlexContainer fullWidth>
                      <H3 weight={600}>
                        {dayjsExt(TIMESTAMP).format("dddd, L")}
                      </H3>
                    </FlexContainer>

                    <FlexContainer direction="column" align="center" fullWidth>
                      <H4>Flat Day Rate</H4>
                      <Text weight={500}>
                        {currencyFormatter(centsToDollars(FLAT_DAY_RATE))}
                      </Text>
                    </FlexContainer>

                    <FlexContainer
                      direction="column"
                      fullWidth
                      align="flex-end"
                    >
                      <H4>Daily Subtotal</H4>
                      <Text weight={500}>
                        {currencyFormatter(centsToDollars(Number(PRICE)))}
                      </Text>
                    </FlexContainer>
                  </FlexContainer>

                  <FlexContainer direction="column" fullWidth align="center">
                    {VEHICLES_PRICE_BREAKDOWN.map((vpb, index) => (
                      <FlexContainer
                        direction="row"
                        fullWidth
                        justify="space-between"
                        align="center"
                        key={index}
                      >
                        <FlexContainer
                          direction="row"
                          fullWidth
                          align="center"
                          columnGap={1}
                        >
                          <VehicleIcon
                            type={
                              (vehiclesMap.get(vpb.ID)?.vehicleType
                                ?.type as VehicleTypes) || 0
                            }
                            size={24}
                          />
                          <H3 weight={400}>
                            {vehiclesMap.get(vpb.ID)?.licensePlate}
                          </H3>
                        </FlexContainer>

                        <FlexContainer
                          direction="column"
                          fullWidth
                          align="center"
                          bordered={
                            vpb.DAILY_MINIMUM > (vpb.DAILY_SERVICE_RATE ?? 0) &&
                            vpb.DAILY_MINIMUM > vpb.SUBTOTAL
                          }
                        >
                          <H4>Daily Minimum</H4>
                          <Text weight={500}>
                            {currencyFormatter(
                              centsToDollars(vpb.DAILY_MINIMUM)
                            )}
                          </Text>
                        </FlexContainer>
                        <FlexContainer
                          direction="column"
                          fullWidth
                          align="center"
                          bordered={
                            vpb.DAILY_SERVICE_RATE > vpb.DAILY_MINIMUM &&
                            vpb.DAILY_SERVICE_RATE > vpb.SUBTOTAL
                          }
                        >
                          <H4>Service Time Total</H4>
                          <Text weight={500}>
                            {currencyFormatter(
                              centsToDollars(vpb.DAILY_SERVICE_RATE || 0)
                            )}
                          </Text>
                        </FlexContainer>
                        <FlexContainer
                          direction="column"
                          fullWidth
                          align="flex-end"
                          bordered={
                            vpb.SUBTOTAL > vpb.DAILY_MINIMUM &&
                            vpb.SUBTOTAL > (vpb.DAILY_SERVICE_RATE ?? 0)
                          }
                        >
                          <H4>Vehicle Subtotal</H4>
                          <Text weight={500}>
                            {currencyFormatter(centsToDollars(vpb.SUBTOTAL))}
                          </Text>
                        </FlexContainer>
                      </FlexContainer>
                    ))}
                  </FlexContainer>
                </FlexContainer>
              )
            )}
          </FlexContainer>

          <Divider margin={isMobile ? 5 : 0} />

          <FlexContainer>
            <H2 weight={600}>Leg Subtotals</H2>
          </FlexContainer>

          <FlexContainer direction="column" fullWidth>
            {trip.LEGS.map((leg, index) => (
              <FlexContainer direction="column" fullWidth key={index}>
                <H3>
                  {`Leg ${index + 1}`} -{" "}
                  {dayjsExt(leg.DEPARTURE_DATETIME).format("L")}
                </H3>
                {leg.LEG_PRICE.map((lp) => (
                  <FlexContainer
                    direction="row"
                    fullWidth
                    justify="space-between"
                    align="center"
                    key={lp.VEHICLE_ID}
                  >
                    <FlexContainer
                      direction="row"
                      fullWidth
                      align="center"
                      columnGap={1}
                    >
                      <VehicleIcon
                        type={
                          (vehiclesMap.get(lp.VEHICLE_ID)?.vehicleType
                            ?.type as VehicleTypes) || 0
                        }
                        size={24}
                      />
                      <H3 weight={400}>
                        {vehiclesMap.get(lp.VEHICLE_ID)?.licensePlate}
                      </H3>
                    </FlexContainer>
                    <FlexContainer direction="column" fullWidth align="center">
                      <H4>Leg Subtotal</H4>
                      <Text weight={500}>
                        {currencyFormatter(centsToDollars(lp.SUBTOTAL))}
                      </Text>
                    </FlexContainer>
                    <FlexContainer
                      direction="column"
                      fullWidth
                      align="flex-end"
                    >
                      <H4>Recipe</H4>
                      <IconButton
                        icon={<Recipe />}
                        onClick={() =>
                          onClickRecipe(lp.LEG_PRICE_DATA.RECIPE_ID)
                        }
                      />
                    </FlexContainer>
                  </FlexContainer>
                ))}
                <Divider margin={0} />
              </FlexContainer>
            ))}
          </FlexContainer>
        </ScrollableFlexContainer>
      </FlexContainer>
    </Modal>
  );
};
