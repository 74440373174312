import cx from "classnames";
import {
  Button,
  DoneIcon,
  FlexContainer,
  FormContainer,
  H3,
  NoteText,
  PathIcon,
  PropertyString,
  Subtitle,
  TimeIcon,
  palette,
} from "@busie/ui-kit";
import { centsToDollars, currencyFormatter, metersToMiles } from "@busie/utils";
import { useMediaQuery } from "@mui/material";
import React from "react";
import { Dayjs } from "dayjs";
import { BlockContainer } from "~/QuotesAndBookingsPage/shared/ui";

interface Leg {
  start: string;
  destination: string;
  travelTime: number;
  departure: Dayjs;
  arrival: Dayjs;
  price: number;
  meters: number;
}

interface Props {
  summaryData: {
    tripPrice: number;
    passengers: number;
    wheelchairs: number;
    amenities: number[];
    contactName: string;
    contactEmail: string;
    contactNumber: string;
    totalMeters: number;
    liveMeters: number;
    deadHeadMeters: number;
    legs: Leg[];
  };
  onClose?: () => void;
}

export const Summary: React.FC<React.PropsWithChildren<Props>> = ({
  summaryData: {
    tripPrice,
    passengers,
    wheelchairs,
    contactName,
    contactEmail,
    contactNumber,
    amenities,
    totalMeters,
    liveMeters,
    deadHeadMeters,
    legs,
  },
  onClose,
}) => {
  const isMobile = useMediaQuery("@media (max-width: 650px)");

  return (
    <FlexContainer direction="column" fullWidth>
      <FlexContainer
        direction="row"
        justify="space-between"
        fullWidth
        align="center"
        bordered
        sx={{ position: "sticky", top: "0" }}
      >
        <FlexContainer
          direction="row"
          justify="flex-start"
          columnGap={1}
          align="center"
        >
          <DoneIcon color={palette.green.main} />
          <Subtitle>Trip Created</Subtitle>
        </FlexContainer>
        <FlexContainer direction="row" columnGap={4}>
          <FlexContainer direction="column" align="flex-end">
            <NoteText>Trip Subtotal</NoteText>
            <H3 margin="0">
              {currencyFormatter(centsToDollars(Math.ceil(Number(tripPrice))))}
            </H3>
          </FlexContainer>
          {onClose && (
            <Button typestyle="primary" onClick={onClose}>
              Close window
            </Button>
          )}
        </FlexContainer>
      </FlexContainer>

      <FormContainer title="Main Contact" fullWidth>
        <PropertyString name="Name">{contactName}</PropertyString>
        <PropertyString name="Email">
          <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </PropertyString>
        <PropertyString name="Phone number">{contactNumber}</PropertyString>
      </FormContainer>

      <FormContainer title="Trip Information" fullWidth>
        <PropertyString name="Passengers amount">{passengers}</PropertyString>
        <PropertyString name="Accessible seating">{wheelchairs}</PropertyString>
        {amenities.length ? (
          <PropertyString name="Amenities">
            {amenities.map((amenity) => (
              <span>{amenity}</span>
            ))}
          </PropertyString>
        ) : (
          ""
        )}
      </FormContainer>

      <FormContainer title="Route information" fullWidth>
        <PropertyString name="Trip distance">
          {metersToMiles(totalMeters) || 0} mi
        </PropertyString>
        <PropertyString name="Total live miles">
          {metersToMiles(liveMeters) || 0} mi
        </PropertyString>
        <PropertyString name="Total dead head miles">
          {metersToMiles(deadHeadMeters) || 0} mi
        </PropertyString>

        <Subtitle margin="40px 0 0 ">Legs information</Subtitle>
        <FlexContainer direction="column">
          {legs.map((item, index) => (
            <FlexContainer
              key={`row-${index + 1}`}
              justify="space-evenly"
              align="center"
              fullWidth
            >
              <BlockContainer sx={{ width: "100%" }}>
                <FlexContainer
                  direction="row"
                  justify="space-between"
                  wrap="wrap"
                  fullWidth
                  align="center"
                  sx={{ marginBottom: "13px" }}
                >
                  <H3 color={palette.black.plus30}>{`Leg ${index + 1}`}</H3>

                  <FlexContainer direction="row" align="center" columnGap={1}>
                    <PathIcon />
                    <H3>{`${metersToMiles(item.meters)} mi`}</H3>
                  </FlexContainer>

                  <FlexContainer direction="row" align="center" columnGap={1}>
                    <TimeIcon />
                    <H3>{`${Math.ceil(
                      item.travelTime / (1000 * 60 * 60)
                    )} hours`}</H3>
                  </FlexContainer>

                  <FlexContainer>
                    <H3>{item.price}</H3>
                  </FlexContainer>
                </FlexContainer>

                <FlexContainer justify="space-evenly" columnGap={2}>
                  <div
                    className={cx({
                      departures: true,
                      end: false,
                      start: true,
                      last: false,
                      first: true,
                    })}
                    style={{ width: "100%" }}
                  >
                    <div className={cx({ origin: true, start: true })}>
                      {item.start}
                      <div
                        style={{
                          marginTop: "4px",
                          color: palette?.black?.plus30,
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Departing: {item.departure.format("L LT")}
                      </div>
                    </div>
                    <div className={cx({ destination: true, end: false })}>
                      {item.destination}
                      <div
                        style={{
                          marginTop: "4px",
                          color: palette?.black?.plus30,
                          fontWeight: 500,
                          fontSize: "10px",
                        }}
                      >
                        Estimated Arrival: {item.arrival.format("L LT")}
                      </div>
                    </div>
                  </div>
                </FlexContainer>
              </BlockContainer>
            </FlexContainer>
          ))}
        </FlexContainer>
      </FormContainer>
    </FlexContainer>
  );
};
