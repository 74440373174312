import { styled } from "@mui/system";

import { Theme } from "@mui/system";

interface Props {
  theme?: Theme;
}

export const VehicleLabelStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    ".icon": {
      marginRight: "8px",
      verticalAlign: "middle",
      svg: {
        width: "24px",
        height: "24px",
      },
    },
    ".text": {
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "100%",
      color: palette?.black?.main,
    },
  };
});
