import { ChefBooking } from "@busie/api";
import { useUsers } from "@busie/core";
import { H2, Loading } from "@busie/ui-kit";
import { centsToDollars, currencyFormatter } from "@busie/utils";
import React from "react";
import {
  Assignee,
  DrawerObjectDetails,
} from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  booking: ChefBooking;
}

export const Details: React.FC<Props> = ({ booking }) => {
  return (
    <DrawerObjectDetails
      name="Booking Info"
      primaryProperties={[
        ["ID", booking._id],
        ["Price", currencyFormatter(centsToDollars(booking.QUOTE.PRICE))],
        ["Balance", currencyFormatter(centsToDollars(booking.BALANCE))],
      ]}
      secondaryProperties={[
        [
          "Trips Subtotal",
          currencyFormatter(centsToDollars(booking.QUOTE.SUBTOTAL)),
        ],
        [<H2 weight={900}>Surcharges</H2>, ""],
        // ["Driver Gratuity", `${booking.QUOTE.SURCHARGES.DRIVER_GRATUITY}%`],
        ["Fuel Surcharge", `${booking.QUOTE.SURCHARGES.FUEL_SURCHARGE}%`],
        [
          "Last Minute Surcharge",
          `${booking.QUOTE.SURCHARGES.LAST_MINUTE_SURCHARGE}%`,
        ],
        ["Tax", `${booking.QUOTE.SURCHARGES.TAX_SURCHARGE}%`],
        [<H2 weight={900}>Markups</H2>, ""],
        [
          "Network Markup",
          `${
            booking.QUOTE.MARKUPS["network_percentage_markup"]
          }% + ${currencyFormatter(
            centsToDollars(booking.QUOTE.MARKUPS["network_fixed_markup"])
          )}`,
        ],
        [
          "Platform Markup",
          `${
            booking.QUOTE.MARKUPS["platform_percentage_markup"]
          }% + ${currencyFormatter(
            centsToDollars(booking.QUOTE.MARKUPS["platform_fixed_markup"])
          )}`,
        ],
        [
          "Online Payment Markup",
          `${
            booking.QUOTE.MARKUPS["payment_percentage_markup"]
          }% + ${currencyFormatter(
            centsToDollars(booking.QUOTE.MARKUPS["payment_fixed_markup"])
          )}`,
        ],
      ]}
    />
  );
};
