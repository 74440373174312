import React, { useEffect, useState } from "react";

import { DrawerDetailsBlock, Loading, PropertyString } from "@busie/ui-kit";
import { useCustomer } from "~/CustomersPage/entity/model";

interface Props {
  customerId: string;
  title: string;
  collapsible?: boolean;
}

export const CustomerProfileDetails: React.FC<
  React.PropsWithChildren<Props>
> = ({ customerId, title, collapsible }: Props) => {
  const { data: customer, isFetching, isLoading } = useCustomer(customerId);

  const [loading, setLoading] = useState(isFetching || isLoading);

  useEffect(() => {
    setLoading(isFetching || isLoading);
  }, [isFetching, isLoading]);

  if (loading) {
    return (
      <DrawerDetailsBlock collapsible={collapsible} name={title}>
        <Loading size={24} padding="0px" />
      </DrawerDetailsBlock>
    );
  }

  if (!customer) return null;

  return (
    <DrawerDetailsBlock collapsible={collapsible} name={title}>
      <PropertyString name="Name">{customer.name}</PropertyString>
      <PropertyString name="Email">
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${customer.email}`}
          className="force-print"
        >
          {customer.email}
        </a>
      </PropertyString>
      <PropertyString name="Phone Number">
        +{customer.countryCode} ({customer.areaCode}){" "}
        {customer.phoneNumber?.slice(0, 3)}-{customer.phoneNumber?.slice(3)}
      </PropertyString>
    </DrawerDetailsBlock>
  );
};
