import { CompanyHolidayRepeat, DayjsExt, dayjsExt } from "@busie/utils";
import { capitalize } from "@busie/ui-kit";

const FORMATS = {
  NONE: "MMM DD, YYYY",
  MONTHLY: "D",
  ANNUALLY: "MMM D",
};

export const getHolidayDateString = (
  dateFrom: DayjsExt,
  dateTo: DayjsExt,
  repeat: CompanyHolidayRepeat
): string => {
  const friendlyRepeat = repeat === "NONE" ? "" : capitalize(repeat);
  const isRange = !dayjsExt(dateFrom).isSame(dateTo, "day");
  return (
    friendlyRepeat +
    (isRange
      ? ` from ${dayjsExt(dateFrom).format(FORMATS[repeat])} to ${dayjsExt(
          dateTo
        ).format(FORMATS[repeat])}`
      : ` on ${dayjsExt(dateFrom).format(FORMATS[repeat])}`)
  );
};
