import qs from "qs";
import { ReservationForm } from "@busie/api";
import { history } from "@busie/utils";
import VehiclesStore from "~/vehicles/store";
import { dayjsExt, ReservationTypes, Reservation } from "@busie/utils";

export const validateReservationForm = (form: ReservationForm): boolean => {
  // is every vehicle select has value
  if (
    form.selectedVehicles.length !==
      form.selectedVehicles.filter((item) => item).length ||
    form.endAt.isBefore(form.startAt)
  ) {
    return false;
  }

  // isn't past

  if (
    dayjsExt(form.startAt).isBefore(new Date()) ||
    dayjsExt(form.endAt).isBefore(new Date())
  ) {
    return false;
  }

  // is no duplicate vehicles selected;
  const uniqueVehicleIds = new Set(
    form.selectedVehicles
      .map((item) => {
        if (item) return item.id;
        return undefined;
      })
      .filter((item) => item)
  );
  if (uniqueVehicleIds.size !== form.selectedVehicles.length) {
    return false;
  }

  if (form.reason === ReservationTypes.OTHER && !form.description) {
    return false;
  }

  // is timeinput filled
  // if (form.startAt.time.format("HH:mm A").length !== 7 || form.endAt.time.format("HH:mm A").length !== 7) {
  //   return false;
  // }

  return true;
};

export const filterByDateRange = (reservation: Reservation): boolean => {
  const [startAt, endAt] = VehiclesStore.selectedDateRange;

  if (!startAt && !endAt) return true;

  const reservationDate = dayjsExt(reservation.startAt);

  return (
    reservationDate.isAfter(startAt) &&
    (endAt ? reservationDate.isBefore(endAt) : true)
  );
};

interface FilterParams {
  type?: string;
  status?: string;
}
export const getFilterParams = (): FilterParams => {
  const searchParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const result: FilterParams = {};
  if (searchParams.vehicle) result.type = `${searchParams.vehicle}`;
  if (searchParams.status) result.status = `${searchParams.status}`;
  return result;
};

export const getFormattedDate = (
  start: Date | undefined,
  end: Date | undefined,
  period: "month" | "week" | "day",
  isTablet: boolean
): string => {
  try {
    if (period === "month") {
      return dayjsExt(start).format(`${isTablet ? "MMM" : "MMMM"} YYYY`);
    } else if (period === "week") {
      if (dayjsExt(start).isSame(end, "year")) {
        if (dayjsExt(start).isSame(end, "month")) {
          return `${dayjsExt(start).format("D")}-${dayjsExt(end).format(
            "D"
          )} ${dayjsExt(start).format("MMM YYYY")}`;
        } else {
          return `${dayjsExt(start).format("D MMM")} - ${dayjsExt(end).format(
            "D MMM"
          )} ${dayjsExt(start).format("YYYY")}`;
        }
      } else {
        return `${dayjsExt(start).format("D MMM YY")} - ${dayjsExt(end).format(
          "D MMM YY"
        )}`;
      }
    }
    return dayjsExt(start).format("DD MMM YYYY");
  } catch (err) {
    console.error(err);
  }
  return "";
};

export const addMonths = (date: Date, howMany: number): Date => {
  try {
    return dayjsExt(date).add(howMany, "month").toDate();
  } catch (err) {
    console.error(err);
  }
  return date;
};
