import { SxProps, Theme } from "@mui/system";
import React from "react";

import TableContainerStyled from "./styled/TableContainerStyled";

interface Props {
  children: React.ReactNode;
  fullwidth?: boolean;
  sx?: SxProps<Theme> | undefined;
  isMobile?: boolean;
}

const TableContainer: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <TableContainerStyled
      sx={props.sx}
      fullwidth={props.fullwidth}
      isMobile={!!props.isMobile}
    >
      {props.children}
    </TableContainerStyled>
  );
};

export default TableContainer;
