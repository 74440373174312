export enum ReservationTypes {
  CHARTER,
  LINE_RUN,
  MAINTENANCE,
  OTHER,
}

export enum ReservationStatuses {
  PENDING,
  CONFIRMED,
  IN_PROGRESS,
  COMPLETE,
  DECLINED,
}
