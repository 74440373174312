import {
  AmountIcon,
  CalculateIcon,
  FlexContainer,
  H3,
  TimeIcon,
} from "@busie/ui-kit";
import { DriverPayRates } from "@busie/utils";
import { Icon } from "@mui/material";
import React from "react";
import { DecoratedListItem } from "~/settings/shared/ui";

interface Props {
  payRate: DriverPayRates;
}

export const ListItem: React.FC<React.PropsWithChildren<Props>> = ({
  payRate,
}) => {
  return (
    <DecoratedListItem icon={<CalculateIcon />} title={payRate.name}>
      <FlexContainer direction="column" justify="space-between" fullWidth>
        <FlexContainer direction="column" justify="space-between" fullWidth>
          <Icon>
            <TimeIcon />
          </Icon>
          <H3 weight={500}>{payRate.cadence}</H3>
        </FlexContainer>
        <FlexContainer direction="column" justify="space-between" fullWidth>
          <Icon>
            <AmountIcon />
          </Icon>
          {/* TODO: This needs a currency conversion... */}
          <H3 weight={500}>{payRate.amount}</H3>
        </FlexContainer>
      </FlexContainer>
    </DecoratedListItem>
  );
};
