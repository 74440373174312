import { ReservationPopup } from "@busie/api";
import { dayjsExt } from "@busie/utils";

export const reservationPopupInitialState: ReservationPopup = {
  isOpen: false,
  vehicles: [],
  vehicleTypes: [],
  form: {
    selectedVehicles: [],
    startAt: dayjsExt().set("h", 9),
    endAt: dayjsExt().set("h", 10),
    reason: 2,
    selectedVehicleReservation: [],
    description: "",
  },
};
