import { styled } from "@mui/system";
import palette from "../theme/palette";

interface Props {
  color?: string;
}

export const NoteText = styled("p")<Props>(
  ({ color = palette?.black?.main }: Props) => {
    return {
      color: color,
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "150%",
      margin: 0,
    };
  }
);
