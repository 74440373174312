import { CreateInvoicedCustomerParams, CreateInvoiceParams } from "@busie/api";
import { notificationStore } from "@busie/features";
import { FlexContainer, IconButton, Modal, PlusIcon } from "@busie/ui-kit";
import { Invoice } from "@busie/utils";
import React, { useState } from "react";
import { CreateCustomer, SelectGroupMember } from "~/CustomersPage/features";
import { useCreateInvoice } from "./model";
import { CreateInvoiceForm } from "~/QuotesAndBookingsPage/entity/Invoice";

interface Props {
  isOpen: boolean;
  organizationId: string;
  amount: number;
  bookingId: string;
  customerGroupId: string;
  mainContactId: string;
  revenueShareConfigId: string;
  dateSuggestions?: [string, Date][];
  onClose: () => void;
  onInvoiceCreated: (invoice: Invoice) => Promise<void> | void;
}
export const CreateInvoice: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  organizationId,
  amount,
  bookingId,
  customerGroupId,
  mainContactId,
  revenueShareConfigId,
  dateSuggestions = [],
  onClose,
  onInvoiceCreated,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState(mainContactId);
  const [isCreateCustomerVisible, setIsCreateCustomerVisible] = useState(false);
  const [createInvoicedCustomerParams, setCreateInvoicedCustomerParams] =
    useState<CreateInvoicedCustomerParams>();
  const { mutateAsync } = useCreateInvoice();

  const onSubmit = async (formValues: CreateInvoiceParams) => {
    try {
      await mutateAsync(formValues, {
        onSuccess: async (invoice) => {
          onClose();
          await onInvoiceCreated(invoice);
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CreateInvoiceForm
        organizationId={organizationId}
        amount={amount}
        bookingId={bookingId}
        dateSuggestions={dateSuggestions}
        onSubmit={onSubmit}
        onCancel={() => {
          setSelectedCustomer(mainContactId);
          setCreateInvoicedCustomerParams(undefined);
          onClose();
        }}
        customer={createInvoicedCustomerParams}
        revenueShareConfigId={revenueShareConfigId}
        invoicedCustomerSlot={
          <>
            <FlexContainer align="center" columnGap={2}>
              <SelectGroupMember
                groupId={customerGroupId}
                initialValue={selectedCustomer}
                onSelect={(profile) =>
                  setCreateInvoicedCustomerParams({
                    id: profile.id,
                    groupId: customerGroupId,
                    name: profile.name,
                    // note: we're assuming that the email and groupName will always be present due to the
                    // return value of the selector
                    email: profile.email,
                    groupName: profile.groupName,
                  })
                }
              />
              <IconButton
                icon={<PlusIcon color="black" />}
                style={{ marginTop: "8px" }}
                onClick={() => setIsCreateCustomerVisible(true)}
              />
            </FlexContainer>

            <CreateCustomer
              visible={isCreateCustomerVisible}
              hide={() => setIsCreateCustomerVisible(false)}
              onCreate={(customer) => setSelectedCustomer(customer.id)}
              organizationId={customerGroupId}
            />
          </>
        }
      />
    </Modal>
  );
};
