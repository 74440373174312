import { NoteText, H3, palette, theme } from "@busie/ui-kit";
import { Box } from "@mui/system";
import React from "react";

interface Props {
  item: {
    vehicleType: JSX.Element;
    licensePlate: string;
    totalSeats: number;
    wheelchairAccessible: JSX.Element;
    spab: JSX.Element;
    amenities: JSX.Element;
    dispatch: string | undefined;
    actions: JSX.Element;
  };
}

const MobileVehicleView: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: palette.black.plus100,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        border: `1px ${palette.black.plus70} solid`,
        borderRadius: "4px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        rowGap: theme.spacing(2),
        gridTemplateAreas:
          "'type plate controls' 'amenities amenities amenities' 'seats wheelchair spab'",
        "& span": {
          opacity: 1,
        },
      }}
    >
      <div style={{ gridArea: "type" }}>
        <NoteText>Vehicle Type</NoteText>
        {props.item.vehicleType}
      </div>
      <div style={{ gridArea: "plate" }}>
        <NoteText>Licence Plate</NoteText>
        <H3 margin="0">{props.item.licensePlate}</H3>
      </div>
      <div style={{ gridArea: "controls" }}>{props.item.actions}</div>
      <div style={{ gridArea: "amenities" }}>
        <NoteText>Amenities</NoteText>
        {props.item.amenities}
      </div>
      <div style={{ gridArea: "seats" }}>
        <NoteText>Total Seats</NoteText>
        <H3 margin="0">{props.item.totalSeats}</H3>
      </div>
      <div style={{ gridArea: "wheelchair" }}>
        <NoteText>Wheelchair</NoteText>
        {props.item.wheelchairAccessible}
      </div>
      <div style={{ gridArea: "spab" }}>
        <NoteText>SPAB Certified</NoteText>
        {props.item.spab}
      </div>
    </Box>
  );
};

export default MobileVehicleView;
