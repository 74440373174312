import { QuotesAndBookingsSettings } from "@busie/utils";
import { FormData } from "../interface";

export const getDefaultValues = (
  settings?: QuotesAndBookingsSettings
): FormData => ({
  lastMinuteSurge: settings?.lastMinuteSurge ?? 0,
  fuelSurcharge: settings?.fuelSurcharge ?? 0,
  taxSurcharge: settings?.taxSurcharge ?? 0,
  email: settings?.email ?? "",
  defaultSignerName: settings?.defaultSignerName ?? "",
  enablePlatformPricingMarkup: settings?.enablePlatformPricingMarkup ?? false,
  enableAssigneeContractSigner: settings?.enableAssigneeContractSigner ?? false,
});
