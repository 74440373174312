import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";

import {
  Button,
  Popup,
  FlexContainer,
  NumberInput,
  TextInput,
  TextInputEvent,
  Select,
  SelectChangeEvent,
  Title,
} from "@busie/ui-kit";
import { Ingredient, IngredientType } from "@busie/utils";
import { ingredientTypesList } from "~/rates/RateMenuPage/store/constants";
import { dollarsToCents } from "@busie/utils";
import { useMediaQuery } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  pricePerKmToPricePerMile,
  pricePerMileToPricePerKm,
  shouldConvertIngredientValue,
} from "~/rates/RateMenuPage/store";

interface Props {
  isPopupOpened: boolean;
  isEdit: boolean;
  onClose: () => void;
  onUpdate: (value: string, type: "name" | "value" | "type") => void;
  ingredient: Ingredient;
  valueInDollars: number;
  onSubmit: (ingredient: Ingredient) => void;
}

const FormWrapper = styled("div")(() => ({
  width: "100%",
}));

const IngredientPopup: React.FC<React.PropsWithChildren<Props>> = ({
  isPopupOpened,
  onClose,
  ingredient,
  valueInDollars,
  onSubmit,
  isEdit,
}: Props) => {
  const [name, setName] = useState("");
  const [type, setType] = useState<IngredientType>("HOURLY");
  const [value, setValue] = useState(0);
  const { rolloutMinimumDayRate, useMetricSystem } =
    useFlags<{ rolloutMinimumDayRate: boolean; useMetricSystem: boolean }>();

  useEffect(() => {
    setName(ingredient.name);
    setType(ingredient.type);
    setValue(
      useMetricSystem && shouldConvertIngredientValue(ingredient.type)
        ? parseFloat(pricePerMileToPricePerKm(valueInDollars.toString()))
        : valueInDollars
    );
  }, [ingredient.name, ingredient.type, valueInDollars, useMetricSystem]);

  const isSubmitDisabled = !name || !value;

  const handleSubmit = () => {
    onSubmit({
      ...ingredient,
      name,
      type,
      value: dollarsToCents(
        useMetricSystem && shouldConvertIngredientValue(type)
          ? parseFloat(pricePerKmToPricePerMile(value.toString()))
          : value
      ),
    });
  };
  const isMobile = useMediaQuery("@media (max-width: 800px)");

  const ingredientTypes = ingredientTypesList.filter(({ value }) =>
    !rolloutMinimumDayRate && value === "DAILY_MINIMUM" ? false : true
  );

  return (
    <Popup
      title={<Title>{isEdit ? "Edit ingredient" : "Add ingredient"}</Title>}
      isPopupOpened={isPopupOpened}
      onClose={onClose}
    >
      <FlexContainer
        w={isMobile ? "auto" : 480}
        px={5}
        pb={5}
        rowGap={3}
        direction="column"
      >
        <FormWrapper>
          <TextInput
            required={true}
            label="Ingredient name"
            placeholder="e.g. Overtime"
            value={name}
            onChange={(e: TextInputEvent) => setName(e.target.value)}
          />
          <Select
            label="Type"
            onChange={(e: SelectChangeEvent) => setType(e.target.value)}
            items={ingredientTypes}
            getName={(item) => item.name}
            getValue={(item) => item.value}
            value={type}
            required
          />
          <NumberInput
            required={true}
            label="Value"
            placeholder="e.g. $10.00"
            isMoneyField={true}
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
          />
        </FormWrapper>
        <FlexContainer justify="flex-end" columnGap={2} fullWidth>
          <Button typestyle="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            typestyle="primary"
            async
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            Save
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Popup>
  );
};

export default IngredientPopup;
