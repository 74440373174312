import React from "react";
import { AxiosError } from "axios";
import { useReactToPrint } from "react-to-print";
import { useQueryClient } from "react-query";

import {
  useDispatchLocationsAuthToken,
  useTripsAuthToken,
  useVehiclesAuthToken,
} from "@busie/core";
import { Reservation, ReservationStatuses } from "@busie/utils";
import {
  Drawer,
  DrawerHeader,
  DrawerDetailsBlock,
  PropertyString,
  Button,
  FlexContainer,
  DrawerHeaderButton,
  Loading,
} from "@busie/ui-kit";
import { Comment } from "@busie/api";
import { datetimeFormatter as dateFormat, dayjsExt } from "@busie/utils";
import VehicleTypeLabel from "~/vehicles/VehiclesList/components/VehicleTypeLabel";
import { TripInfo } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/TripInfo";
import { AdditionalInfo } from "~/QuotesAndBookingsPage/entity/DetailsDrawer/TripAdditionalInfo";

import {
  getReservationStatusBadgeContents,
  getReservationTypeBadgeContents,
} from "../helpers";
import {
  useComments,
  useCommentsMutation,
  useDispatchLocation,
  useReservationMutation,
  useTrip,
} from "../hooks/useQueries";
import CommentsBlock from "./CommentsBlock";
import { useFetchDriver } from "~/drivers/model";
import { CustomerProfileDetails } from "../../../../shared/CustomerProfileDetails";
import { notificationStore } from "@busie/features";

interface Props {
  reservation: Reservation | null;
  onEdit: () => void;
  onClose: () => void;
  onReservationEdited: (reservationData: Reservation) => void;
}

const ReservationDetailsDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  reservation,
  onEdit,
  onClose,
  onReservationEdited,
}) => {
  const dispatchLocationAuthToken = useDispatchLocationsAuthToken();
  const vehiclesAuthToken = useVehiclesAuthToken();
  const tripsAuthToken = useTripsAuthToken();
  const dispatchLocationId = reservation?.vehicle?.dispatchLocationId;

  const {
    data: driver,
    isLoading: isDriverLoading,
    isFetching: isDriverFetching,
    isError: isDriverError,
  } = useFetchDriver(reservation?.driverId);

  const queryClient = useQueryClient();
  const { data: comments = [] } = useComments(vehiclesAuthToken, reservation);
  const { data: dispatchLocation } = useDispatchLocation(
    dispatchLocationAuthToken,
    dispatchLocationId
  );
  const isPast = dayjsExt().isAfter(reservation?.startAt);
  const canDecline =
    !isPast &&
    [ReservationStatuses.PENDING, ReservationStatuses.CONFIRMED].includes(
      reservation?.status as ReservationStatuses
    );
  const onAddedComment = (comment: Comment) => {
    queryClient.setQueryData(
      ["comments", reservation?.id],
      [comment, ...comments]
    );
  };
  const { mutate: addComment } = useCommentsMutation(
    vehiclesAuthToken,
    reservation?.id as string,
    onAddedComment
  );

  const onReservationEdit = (reservationData: Reservation) => {
    onReservationEdited(reservationData);
  };

  const { mutateAsync: saveReservation } = useReservationMutation(
    vehiclesAuthToken,
    onReservationEdit
  );

  const { data: trip } = useTrip(tripsAuthToken, reservation?.tripId);
  const [infoExpanded, setInfoExpanded] = React.useState(false);

  const componentRef = React.useRef(null);
  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Reservation Details",
  });

  const decline = async (reservation: Reservation) => {
    if (!canDecline) return;
    await saveReservation({
      ...reservation,
      status: ReservationStatuses.DECLINED,
    });
  };

  const confirm = async (reservation: Reservation) => {
    try {
      await saveReservation({
        ...reservation,
        status: ReservationStatuses.CONFIRMED,
      });
    } catch (e) {
      const error = e as AxiosError;
      notificationStore.setErrorNotification(
        "Failed to confirm reservation",
        error?.response?.data?.errors?.[0]
      );
    }
  };

  if (!reservation) return null;
  const statusInfo = getReservationStatusBadgeContents(reservation.status);
  return (
    <Drawer anchor="right" open={!!reservation} onClose={onClose}>
      <DrawerHeader title="Reservation Details" onClose={onClose}>
        {!isPast && <DrawerHeaderButton text="Edit" onClick={onEdit} />}
      </DrawerHeader>
      <div className="reservation-details-wrapper" ref={componentRef}>
        <DrawerDetailsBlock collapsible name="Reservation Info">
          <PropertyString
            justify="space-between"
            name={
              <VehicleTypeLabel
                vehicleType={reservation.vehicle?.vehicleType?.type || 0}
              />
            }
          >
            {reservation.vehicle?.licensePlate}
          </PropertyString>
          <PropertyString justify="space-between" name="Type">
            {getReservationTypeBadgeContents(reservation.type).text}
          </PropertyString>
          <PropertyString justify="space-between" name="Status">
            <div style={{ color: statusInfo.color }}>{statusInfo.text}</div>
          </PropertyString>
          <PropertyString name="Dispatch location" justify="space-between">
            {dispatchLocation?.name}
          </PropertyString>
          {reservation.driverId && (
            <PropertyString justify="space-between" name="Assigned Driver">
              {isDriverFetching || isDriverLoading ? (
                <Loading size={24} padding="0px" />
              ) : !driver || isDriverError ? (
                "Unknown Driver"
              ) : (
                driver.firstName + " " + driver.lastName
              )}
            </PropertyString>
          )}

          <PropertyString name="Reservation start date" justify="space-between">
            {dateFormat(new Date(reservation.startAt), "datetime")}
          </PropertyString>
          <PropertyString name="Reservation end date" justify="space-between">
            {dateFormat(new Date(reservation.endAt), "datetime")}
          </PropertyString>
        </DrawerDetailsBlock>
        {trip && (trip._mainContactId || trip._tripPlannerId) && (
          <CustomerProfileDetails
            title="Main Contact Info"
            collapsible={true}
            customerId={trip._mainContactId || trip._tripPlannerId}
          />
        )}
        <DrawerDetailsBlock
          collapsible
          name={`Comments (${comments?.length || 0})`}
        >
          <CommentsBlock comments={comments} onAddComment={addComment} />
        </DrawerDetailsBlock>
        {trip && dispatchLocation && (
          <TripInfo trip={trip} dispatchLocationName={dispatchLocation.name} />
        )}
        {trip?._additionalInformation && (
          <AdditionalInfo
            trip={trip}
            expanded={infoExpanded}
            setExpanded={setInfoExpanded}
          />
        )}
      </div>
      <FlexContainer
        align="center"
        justify="space-between"
        columnGap={2}
        sx={{ margin: "auto 40px 25px" }}
      >
        <Button
          typestyle="primary"
          async
          sx={{ flexGrow: 1 }}
          onClick={() => confirm(reservation)}
          disabled={reservation.status === ReservationStatuses.CONFIRMED}
        >
          Confirm
        </Button>
        <Button
          typestyle="secondary"
          async
          sx={{ flexGrow: 1 }}
          onClick={print}
        >
          Print
        </Button>
        <Button
          typestyle="secondary"
          async
          sx={{ flexGrow: 1 }}
          onClick={() => decline(reservation)}
          disabled={!canDecline}
        >
          Decline
        </Button>
      </FlexContainer>
    </Drawer>
  );
};

export default ReservationDetailsDrawer;
