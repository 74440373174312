import React from "react";
import { styled } from "@mui/system";
import { palette, FlexContainer, EditIcon, DeleteIcon } from "@busie/ui-kit";

const IconContainer = styled("span")(() => ({
  opacity: 0,
  cursor: "pointer",
}));

interface Props {
  onDelete: () => void;
  onEdit: () => void;
}

export const VehicleActionButtons: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  return (
    <FlexContainer columnGap={2} justify="center" align="center">
      <IconContainer onClick={() => props.onEdit()}>
        <EditIcon color={palette?.black?.plus30} />
      </IconContainer>
      <IconContainer onClick={() => props.onDelete()}>
        <DeleteIcon color={palette?.black?.plus30} />
      </IconContainer>
    </FlexContainer>
  );
};

export default VehicleActionButtons;
