import { styled } from "@mui/system";
import theme from "../../theme";

import { Theme } from "@mui/system";

interface Props {
  theme?: Theme;
  color?: string;
  background?: string;
  maxWidth?: string;
  minWidth?: string;
  margin?: string;
}

const BadgeStyled = styled("div", {
  shouldForwardProp: (prop) => prop !== "maxWidth" && prop !== "minWidth",
})<Props>((props: Props) => {
  return {
    "&": {
      fontWeight: "600",
      background: props.background,
      border: `1px solid ${props.color}`,
      color: props.color,
      boxSizing: "border-box",
      borderRadius: "44px",
      fontSize: "14px",
      lineHeight: "100%",
      padding: "8px 16px",
      display: "inline-block",
      width: "100%",
      minWidth: props.minWidth,
      maxWidth: props.maxWidth || "120px",
      textAlign: "center",
      textTransform: "capitalize",
      margin: props.margin || 0,
      height: theme?.spacing(4),
    },
  };
});

export const SmallBadgeStyled = styled("span", {
  shouldForwardProp: (prop) => prop !== "maxWidth" && prop !== "minWidth",
})<Props>((props: Props) => {
  return {
    display: "block",
    height: theme.spacing(2),
    width: theme.spacing(2),
    backgroundColor: props.background,
    borderRadius: "100%",
    margin: "auto",
  };
});

export default BadgeStyled;
