import React, { FC, useMemo, useState } from "react";
import {
  FlexContainer,
  palette,
  IconButton,
  ChevronDown,
  ChevronNext,
  SettingsIcon,
  Subtitle,
  AccordeonHeaderStyled,
  RightMostIconContainer,
} from "@busie/ui-kit";
import { styled } from "@mui/system";
import { RuleType, Cookbook, COOKBOOK_TYPE_LABELS } from "@busie/utils";
import { updateCookbookRulePriority } from "@busie/api";
import RulesOrderModal from "./RulesOrderModal";
import uniq from "lodash.uniq";
import flatten from "lodash.flatten";
import { baseIngredientTypes } from "~/rates/RateMenuPage/store/constants";
import { CookbooksTableColumns } from "~/rates/RateMenuPage/components/CookbooksTableView/CookbooksTableColumns";
import {
  useShowBaseIngredients,
  useShowRecipes,
  useShowRules,
  useShowSideIngredients,
} from "~/rates/RateMenuPage/hooks";

interface Props {
  authToken: string;
  cookbook: Cookbook;
  detailed?: boolean;
  onExpandToggle: () => void;
  expanded: boolean;
}

const CookbookHeader: FC<React.PropsWithChildren<Props>> = ({
  authToken,
  cookbook,
  expanded,
  onExpandToggle,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  const onSettingsClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsPopupOpened(true);
  };

  const onModalClose = () => {
    setIsPopupOpened(false);
  };

  const onModalSubmit = async (
    authToken: string,
    id: string,
    form: RuleType[]
  ) => {
    await updateCookbookRulePriority(authToken, id, form);
    onModalClose();
  };

  const ingredients = useMemo(() => {
    return flatten(cookbook.recipes.map((recipe) => recipe.ingredients));
  }, [cookbook]);

  const recipesCount = useMemo(() => {
    return cookbook.recipes.length;
  }, [cookbook]);
  const rulesCount = useMemo(() => {
    return uniq(cookbook.recipes.map((recipe) => recipe.rule)).length;
  }, [cookbook]);
  const baseIngredientsCount = useMemo(() => {
    return ingredients.filter((ingredient) =>
      baseIngredientTypes.includes(ingredient.type)
    ).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookbook, ingredients]);
  const sideIngredientsCount = useMemo(() => {
    return ingredients.filter(
      (ingredient) => !baseIngredientTypes.includes(ingredient.type)
    ).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookbook, ingredients]);

  const cookbookType = COOKBOOK_TYPE_LABELS[cookbook.type];

  const showSideIngrdients = useShowSideIngredients();
  const showBaseIngredients = useShowBaseIngredients();
  const showRules = useShowRules();
  const showRecipes = useShowRecipes();

  return (
    <>
      <AccordeonHeaderStyled
        direction="row"
        fullWidth
        justify="space-between"
        align="center"
        px={0}
        py={2}
        onClick={() => onExpandToggle()}
        expanded={expanded}
      >
        <CookbooksTableColumns>
          <FlexContainer
            direction="row"
            columnGap={2}
            sx={{ gridColumn: showSideIngrdients ? "1/3" : "" }}
          >
            {expanded ? <ChevronDown /> : <ChevronNext color="currentColor" />}
            <Subtitle>{cookbook.name || cookbook.id.split("-")[0]}</Subtitle>
          </FlexContainer>

          <FlexContainer direction="column">
            <Subtitle>{cookbookType}</Subtitle>
          </FlexContainer>

          {showRecipes && (
            <FlexContainer
              direction="row"
              align="center"
              columnGap={1}
              className="small-item"
            >
              <Subtitle>{recipesCount}</Subtitle>
            </FlexContainer>
          )}

          {showRules && (
            <FlexContainer
              direction="row"
              align="center"
              columnGap={1}
              className="small-item"
            >
              <Subtitle>{rulesCount}</Subtitle>
            </FlexContainer>
          )}

          {showBaseIngredients && (
            <FlexContainer
              direction="row"
              align="center"
              columnGap={1}
              className="large-item"
            >
              <Subtitle>{baseIngredientsCount}</Subtitle>
            </FlexContainer>
          )}

          {showSideIngrdients && (
            <FlexContainer
              direction="row"
              align="center"
              columnGap={1}
              className="large-item"
            >
              <Subtitle>{sideIngredientsCount}</Subtitle>
            </FlexContainer>
          )}
        </CookbooksTableColumns>

        <RightMostIconContainer
          type="button"
          icon={<SettingsIcon />}
          onClick={(event: MouseEvent) => onSettingsClick(event)}
          highlightOnHover={false}
          className="right-most-icon"
        />
      </AccordeonHeaderStyled>

      <RulesOrderModal
        cookbookName={cookbook.name || cookbook.id.split("-")[0]}
        handleClose={onModalClose}
        handleSubmit={async (newOrder) =>
          await onModalSubmit(authToken, cookbook.id, newOrder)
        }
        isPopupOpened={isPopupOpened}
        initialOrder={cookbook.rulePriority}
      />
    </>
  );
};

export default CookbookHeader;
