import React from "react";

interface Props {
  color?: string;
}

export const LicenseIcon: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8H21"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 10H14.8H17.6M12 12H17M12 15H15.5H19"
        stroke="black"
        stroke-width="0.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 10H5Z"
        stroke="#222222"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 15C21 17 19.5 18 18 18C16.5 18 6 18 6 18C4.343 18 3 16.657 3 15V7C3 5.343 4.343 4 6 4H18C19.657 4 21 5.343 21 7C21 7 21 13 21 15Z"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12 11H16M12 14H16.5" stroke="black" stroke-width="0.5" />
      <g clip-path="url(#clip0_17101_233409)">
        <path
          d="M9.33329 14.5V14.1667C9.33329 13.9899 9.26305 13.8203 9.13803 13.6953C9.01301 13.5702 8.84344 13.5 8.66663 13.5H7.33329C7.15648 13.5 6.98691 13.5702 6.86189 13.6953C6.73686 13.8203 6.66663 13.9899 6.66663 14.1667V14.5"
          stroke="#3B3B3B"
          stroke-width="0.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00004 12.8333C8.36823 12.8333 8.66671 12.5349 8.66671 12.1667C8.66671 11.7985 8.36823 11.5 8.00004 11.5C7.63185 11.5 7.33337 11.7985 7.33337 12.1667C7.33337 12.5349 7.63185 12.8333 8.00004 12.8333Z"
          stroke="#3B3B3B"
          stroke-width="0.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17101_233409">
          <rect width="4" height="4" fill="white" transform="translate(6 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};
