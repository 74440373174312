import React from "react";
import { observer } from "mobx-react-lite";

// import { deleteRecipe } from "actions/rates";
import { deleteReservation } from "@busie/api";
import store from "~/vehicles/store";

import { WarningPopup } from "@busie/ui-kit";
import { ReservationTypes } from "@busie/utils";
import { notificationStore } from "@busie/features";

interface Props {
  id: string;
  isPopupOpened: boolean;
  onClose: () => void;
  type: ReservationTypes;
  vehicleTypeName?: string;
}

const getContents = (
  type: ReservationTypes,
  vehicleTypeName?: string
): {
  title: string;
  content: string;
} => {
  switch (type) {
    case 1:
      return {
        title: "Revoke Booking",
        content: `Are you sure you want to revoke the ${vehicleTypeName?.toLowerCase()} booking?`,
      };
    case 2:
      return {
        title: "Revoke Maintenance",
        content: `Are you sure you want to revoke the ${vehicleTypeName?.toLowerCase()} maintenance?`,
      };
    default:
      return {
        title: "Revoke Reservation",
        content: `Are you sure you want to revoke the ${vehicleTypeName?.toLowerCase()} reservation?`,
      };
  }
};
const RevokeVehicleReservationPopup: React.FC<React.PropsWithChildren<Props>> =
  observer(({ id, isPopupOpened, onClose, type, vehicleTypeName }: Props) => {
    const contents = getContents(type, vehicleTypeName);
    const handlerOnDelete = async () => {
      try {
        await deleteReservation(store.authToken, id);
        await store.fetchReservations();
        await store.setVehicles();
      } catch (e) {
        notificationStore.setNotificationFromError(e);
      } finally {
        onClose();
      }
    };
    return (
      <WarningPopup
        title={contents.title}
        isPopupOpened={isPopupOpened}
        onClose={onClose}
        onConfirm={handlerOnDelete}
        confirmText="Revoke"
      >
        {contents.content}
      </WarningPopup>
    );
  });

export default RevokeVehicleReservationPopup;
