import { Modal } from "@busie/ui-kit";
import { Group } from "@busie/utils";
import React from "react";
import { CustomFieldForm } from "~/CustomersPage/entity";
import { useCreateGroupMetadata } from "./model";

interface Props {
  groupId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (group: Group) => void;
  onError: (e: unknown) => void;
}

export const AddCustomField: React.FC<Props> = ({
  groupId,
  isOpen,
  onClose,
  onSuccess,
  onError,
}) => {
  const { mutateAsync: addCustomField } = useCreateGroupMetadata(groupId);

  const onSubmit = (data: { key: string; value: string }) =>
    addCustomField(data, { onSuccess, onError });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CustomFieldForm mode="create" onClose={onClose} onSubmit={onSubmit} />
    </Modal>
  );
};
