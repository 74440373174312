import { createCustomerMetadata } from "@busie/api";
import { useCustomerAuthToken } from "@busie/core";
import { useMutation } from "react-query";
import { customersModel } from "~/CustomersPage/entity";

export const useCreateCustomerMetadata = (id: string) => {
  const token = useCustomerAuthToken();

  return useMutation(async (data: { key: string; value: string }) => {
    const result = await createCustomerMetadata(token, id, data);

    customersModel.invalidateCustomersQueries();
    return result;
  });
};
