import { FlexContainer, H2, Text } from "@busie/ui-kit";
import { centsToDollars, currencyFormatter } from "@busie/utils";
import React from "react";

interface Props {
  /**
   * @param price - The price of the quote in US Cents
   */
  price: number;
  controls?: React.ReactNode;
}

export const Footer: React.FC<Props> = ({ price, controls }) => {
  return (
    <FlexContainer direction="row" justify="space-between" pt={2} fullWidth>
      <FlexContainer direction="column" rowGap={0} align="flex-start" fullWidth>
        <Text fontSize="14px" weight={500}>
          Total price
        </Text>
        <H2 weight={600}>{currencyFormatter(centsToDollars(price))}</H2>
      </FlexContainer>

      <FlexContainer direction="row" columnGap={2} justify="flex-end" fullWidth>
        {controls}
      </FlexContainer>
    </FlexContainer>
  );
};
