import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { OrganizationTripSettings, UpdateTripSettingsParams } from "@busie/api";
import {
  Button,
  FormContainer,
  AmountField,
  InputText,
  palette,
  Callout,
  FlexContainer,
  H2,
  ToggleSwitch,
} from "@busie/ui-kit";

import {
  MAX_DAILY_DRIVE_TIME,
  MAX_PICKUP_RADIUS,
  MIN_DAILY_DRIVE_TIME,
  MIN_PICKUP_RADIUS,
  schema,
} from "./model";

interface Props {
  settings: OrganizationTripSettings;
  onSubmit: (data: UpdateTripSettingsParams) => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  settings,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm<UpdateTripSettingsParams>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      ...settings,
      prorateHours: settings.prorateHours ?? true,
      prorateMiles: settings.prorateMiles ?? true,
    },
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="" fullWidth bordered={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer direction="column" rowGap={4}>
          <FlexContainer direction="column" rowGap={1}>
            <H2>Max Daily Drive Time</H2>
            <InputText color={palette.black.plus30}>
              Maximum # of hours a driver can drive in a day.
            </InputText>
            <Callout color={palette.black.main}>
              <span role="img" aria-label="info">
                ℹ️
              </span>{" "}
              <em>This will trigger an extra driver charge when applicable.</em>
            </Callout>
            <Controller
              name="maxDailyDriveTime"
              control={control}
              render={({ field }) => (
                <AmountField
                  {...field}
                  minValue={MIN_DAILY_DRIVE_TIME}
                  maxValue={MAX_DAILY_DRIVE_TIME}
                  errorMessage={errors.maxDailyDriveTime?.message}
                />
              )}
            />
          </FlexContainer>

          <FlexContainer direction="column" rowGap={1}>
            <H2>Max Trip Pickup Radius</H2>
            <InputText color={palette.black.plus30}>
              Maximum distance your organization is willing to travel for a
              pickup.
            </InputText>
            <Callout color={palette.black.main}>
              <span role="img" aria-label="info">
                ℹ️
              </span>{" "}
              <em>
                This distance is “as the crow flies”, not necessarily driving
                distance.
              </em>
            </Callout>
            <Controller
              name="maxPickupRadius"
              control={control}
              render={({ field }) => (
                <AmountField
                  {...field}
                  minValue={MIN_PICKUP_RADIUS}
                  maxValue={MAX_PICKUP_RADIUS}
                  errorMessage={errors.maxPickupRadius?.message}
                />
              )}
            />
          </FlexContainer>

          <Controller
            name="prorateHours"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FlexContainer direction="column" rowGap={1}>
                <H2>Prorate Hourly Pricing</H2>
                <InputText color={palette.black.plus30}>
                  We'll{" "}
                  {value
                    ? "use the exact time"
                    : "round up to the nearest hour"}{" "}
                  for service time and travel time for pricing calculations.
                </InputText>

                <Callout color={palette.black.main}>
                  <span role="img" aria-label="info">
                    ℹ️
                  </span>{" "}
                  <em>
                    The time displayed in trip details will not change. This is
                    only for price calculation purposes.
                  </em>
                </Callout>
                <ToggleSwitch
                  onChange={(ev) => onChange(ev.target.checked)}
                  checked={value}
                />
              </FlexContainer>
            )}
          />
          <Controller
            name="prorateMiles"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FlexContainer direction="column" rowGap={1}>
                <H2>Prorate Mileage Pricing</H2>
                <InputText color={palette.black.plus30}>
                  We'll{" "}
                  {value
                    ? "use the exact mileage"
                    : "round up to the nearest mile"}{" "}
                  for mileage based pricing calculations.
                </InputText>

                <Callout color={palette.black.main}>
                  <span role="img" aria-label="info">
                    ℹ️
                  </span>{" "}
                  <em>
                    The mileage displayed in trip details will not change. This
                    is only for price calculation purposes.
                  </em>
                </Callout>
                <ToggleSwitch
                  onChange={(ev) => onChange(ev.target.checked)}
                  checked={value}
                />
              </FlexContainer>
            )}
          />
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
