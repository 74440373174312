import React from "react";
import {
  InputText,
  Subtitle,
  Badge,
  FlexContainer,
  palette,
} from "@busie/ui-kit";
import { Integration } from "@busie/utils";

import { IntegrationCardStyled, IntegrationIcon } from "./styled";

export interface Props {
  integration: Integration;
  revenueShare: number | null;
  onClick: () => void;
}

const getBadgeProps = (enabled?: boolean) => {
  if (enabled) {
    return {
      color: palette.green.minus5,
      background: palette.green.plus55,
      children: "Enabled",
    };
  }
  return {
    color: palette.black.plus60,
    background: palette.black.plus100,
    children: "Disabled",
  };
};

export const IntegrationCard: React.FC<React.PropsWithChildren<Props>> = ({
  integration,
  onClick,
  revenueShare,
}) => {
  return (
    <IntegrationCardStyled
      variant="outlined"
      enabled={integration.status === "ACTIVE"}
      onClick={onClick}
    >
      <FlexContainer direction="column" pt={5} pb={4} align="center">
        <IntegrationIcon src={integration.logo} />
        <InputText
          margin={integration ? "0" : "21px 0 0 "}
          color={palette.black.plus50}
        >
          {revenueShare != null && `${revenueShare}% revenue share`}
        </InputText>
        <Subtitle>{integration.organizationName}</Subtitle>
        <InputText color={palette.black.plus50} margin=" 0 0 18px">
          {integration.integrationType === "SOURCE" ? "Source" : "Destination"}
        </InputText>

        <Badge
          {...getBadgeProps(integration.status === "ACTIVE")}
          minWidth="120px"
        />
      </FlexContainer>
    </IntegrationCardStyled>
  );
};
