import { styled } from "@mui/system";

export const TableControlsStyled = styled("div")<{ isMobile?: boolean }>(
  ({ theme, isMobile = false }) => {
    return {
      "&": {
        padding: "0 16px 12px",
        display: "flex",
        gap: "16px",
        wrap: isMobile ? "wrap" : "nowrap",
        align: "center",
        width: "100%",
        [theme.breakpoints.down("tablet")]: {
          padding: "0px",
          gap: "8px",
        },
      },
    };
  }
);
