interface Props {
  borderColor?: string;
  backgroundColor?: string;
}

const Point = (
  colors: Props = {
    borderColor: "#3B3B3B",
    backgroundColor: "white",
  }
): string => {
  return `<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5418 7C11.5418 9.93172 9.25291 12.25 6.50016 12.25C3.74741 12.25 1.4585 9.93172 1.4585 7C1.4585 4.06828 3.74741 1.75 6.50016 1.75C9.25291 1.75 11.5418 4.06828 11.5418 7Z" 
fill="${colors.backgroundColor}" 
stroke="${colors.borderColor}"
stroke-width="2"/>
</svg>`;
};

export default Point;
