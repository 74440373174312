import React from "react";

interface Props {
  size?: number;
}

export const ReservationIcon: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const size = props.size || 24;
  return (
    <svg
      width={size * 1.125}
      height={size}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 1H8V0H22C23.1046 0 24 0.895431 24 2V13H23V2C23 1.44772 22.5523 1 22 1ZM23 21V22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22V2C1 1.44772 1.44772 1 2 1H3V0H2C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V21H23Z"
        fill="#3B3B3B"
      />
      <path
        d="M3 0V8L4.5 6.5L5.5 8L6.5 6.5L8 8V0"
        stroke="#3B3B3B"
        stroke-width="0.5"
      />
      <path
        d="M10.5 21H3.5"
        stroke="#3B3B3B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 19H3.5"
        stroke="#3B3B3B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 17H3.5"
        stroke="#3B3B3B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 15H3.5"
        stroke="#3B3B3B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7454 5.08696C11.7145 5.14871 11.7395 5.2238 11.8013 5.25467C11.863 5.28554 11.9381 5.26051 11.969 5.19877L11.7454 5.08696ZM12.6797 4.58929H12.3363V4.83929H12.6797V4.58929ZM11.8412 4.8953L11.7454 5.08696L11.969 5.19877L12.0648 5.0071L11.8412 4.8953ZM12.3363 4.58929C12.1267 4.58929 11.935 4.70776 11.8412 4.8953L12.0648 5.0071C12.1162 4.90426 12.2214 4.83929 12.3363 4.83929V4.58929Z"
        fill="#222222"
      />
      <rect
        x="11.6428"
        y="8.35718"
        width="0.857143"
        height="0.25"
        rx="0.125"
        fill="#222222"
      />
      <path
        d="M14.3474 5.25322C14.3951 5.06243 14.5665 4.92859 14.7632 4.92859H22.3571V6.64287H14.5489C14.2701 6.64287 14.0655 6.38085 14.1331 6.11036L14.3474 5.25322Z"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <path
        d="M13.1964 9.21426H12.5C12.0266 9.21426 11.6428 8.8305 11.6428 8.35712V7.64561C11.6428 7.19577 11.7313 6.75034 11.9033 6.33468L12.2713 5.44535C12.6691 4.48402 13.607 3.85712 14.6474 3.85712H21.5C21.9734 3.85712 22.3571 4.24087 22.3571 4.71426V8.35712C22.3571 8.8305 21.9734 9.21426 21.5 9.21426H21.0178M14.8303 9.21426H16.8928H19.3839"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <path
        d="M12.5 4.92859H13.4389C13.7214 4.92859 13.9267 5.19729 13.8523 5.46992L13.4432 6.96992C13.3924 7.15638 13.223 7.28573 13.0298 7.28573H11.6428"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <circle
        cx="14"
        cy="9.00003"
        r="0.857143"
        stroke="#222222"
        stroke-width="0.25"
      />
      <circle
        cx="20.2143"
        cy="9.00003"
        r="0.857143"
        stroke="#222222"
        stroke-width="0.25"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 21.5V21.5C18.5145 21.5 16.5 19.4855 16.5 17V17C16.5 14.5145 18.5145 12.5 21 12.5V12.5C23.4855 12.5 25.5 14.5145 25.5 17V17C25.5 19.4855 23.4855 21.5 21 21.5Z"
        stroke="#00C814"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.9998 16L20.4998 18.5L18.9998 17"
        stroke="#00C814"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
