import React from "react";
import { styled } from "@mui/system";

interface OrganizationLogoProps {
  src?: string;
}

const StyledImage = styled("img")`
  min-height: 16px;
  max-height: 18px;
`;

export const OrganizationLogo: React.FC<
  React.PropsWithChildren<OrganizationLogoProps>
> = (props) => <StyledImage {...props} />;
