import * as yup from "yup";

export const MAX_DAILY_DRIVE_TIME = 10;
export const MIN_DAILY_DRIVE_TIME = 1;

export const MAX_PICKUP_RADIUS = 2000;
export const MIN_PICKUP_RADIUS = 10;

export const schema = yup.object({
  maxDailyDriveTime: yup
    .number()
    .required("is required")
    .min(MIN_DAILY_DRIVE_TIME, `must be greater than ${MIN_DAILY_DRIVE_TIME}`)
    .max(10, `must be less than ${MAX_DAILY_DRIVE_TIME}`),
  maxPickupRadius: yup
    .number()
    .required("is required")
    .max(MAX_PICKUP_RADIUS, `must be less than ${MAX_PICKUP_RADIUS}`)
    .min(MIN_PICKUP_RADIUS, `must be greater than ${MIN_PICKUP_RADIUS}`),
});
