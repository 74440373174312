import React from "react";

import { ContractsTable } from "./widgets";
import { useAmplitude } from "@busie/core";

export const Page: React.FC = () => {
  const { track } = useAmplitude();

  track("contracts page viewed");

  return <ContractsTable />;
};
