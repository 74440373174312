import React from "react";
import { observer } from "mobx-react";
import VehiclesStore from "~/vehicles/store";

import { CommonTable } from "@busie/ui-kit";
import VehiclesStatsContainer from "./components/VehiclesStatsContainer";
import TableContainer from "./components/styled/TableContainer";
import VehicleTypeLabel from "../components/VehicleTypeLabel";
import { currencyFormatter, centsToDollars } from "@busie/utils";

const VehiclesStats: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const store = VehiclesStore;

    const getStatsTotal = (): { [key: string]: string | number } => {
      const statsTotal = {
        vehicleType: "Total",
        vehicleId: "",
        totalMiles: 0,
        totalPassengers: 0,
        totalTrips: 0,
        totalDaysRented: 0,
        totalIncome: 0,
      };

      store.vehicleStats.forEach((stat) => {
        statsTotal.totalMiles += stat.totalMiles;
        statsTotal.totalPassengers += stat.totalPassengers;
        statsTotal.totalTrips += stat.totalTrips;
        statsTotal.totalDaysRented += stat.totalDaysRented;
        statsTotal.totalIncome += stat.totalIncome;
      });

      return {
        ...statsTotal,
        totalMiles: statsTotal.totalMiles.toLocaleString(),
        totalPassengers: statsTotal.totalPassengers.toLocaleString(),
        totalTrips: statsTotal.totalTrips.toLocaleString(),
        totalDaysRented: statsTotal.totalDaysRented.toLocaleString(),
        totalIncome: currencyFormatter(statsTotal.totalIncome),
      };
    };

    const tableConfig = {
      columnNames: [
        {
          id: "vehicle-type",
          name: "Vehicle type",
          ordered: false,
          width: "20%",
        },
        { id: "vehicle-id", name: "Vehicle ID", ordered: false, width: "15%" },
        {
          id: "total-miles",
          name: "Total miles",
          ordered: false,
          width: "15%",
        },
        {
          id: "total-passengers",
          name: "Total passengers",
          ordered: false,
          width: "13%",
        },
        {
          id: "total-trips",
          name: "Total trips",
          ordered: false,
          width: "10%",
        },
        {
          id: "total-days-rented",
          name: "Total days rented",
          ordered: false,
          width: "15%",
        },
        {
          id: "total-income",
          name: "Total income",
          ordered: false,
          width: "12%",
        },
      ],
      data: [
        ...store.vehicleStats.map((v) => ({
          vehicleType: <VehicleTypeLabel vehicleType={v.vehicleType} />,
          vehicleId: v.vehicleId.toLocaleString(),
          totalMiles: v.totalMiles.toLocaleString(),
          totalPassengers: v.totalPassengers.toLocaleString(),
          totalTrips: v.totalTrips.toLocaleString(),
          totalDaysRented: v.totalDaysRented.toLocaleString(),
          totalIncome: currencyFormatter(centsToDollars(v.totalIncome)),
        })),
        getStatsTotal(),
      ],
    };

    return (
      <VehiclesStatsContainer>
        <TableContainer>
          <CommonTable
            columnNames={tableConfig.columnNames}
            data={tableConfig.data}
          />
        </TableContainer>
      </VehiclesStatsContainer>
    );
  }
);

export default VehiclesStats;
