import React from "react";
import { Subtitle } from "../Subtitle";
import { FlexContainer, IconButton, PlusIcon } from "@busie/ui-kit";
import { SelectGroupMember } from "~/CustomersPage/features";
import { GroupMember } from "~/CustomersPage/features/SelectGroupMember/model";

interface Props {
  groupId: string;
  selectedContactId: string;
  onSelect: (member: GroupMember) => void;
  onCreate: () => void;
}

export const MainContact: React.FC<Props> = ({
  groupId,
  selectedContactId,
  onSelect,
  onCreate,
}) => {
  return (
    <FlexContainer
      px={2}
      rowGap={1}
      direction="column"
      align="flex-start"
      fullWidth
    >
      <Subtitle>Main Contact</Subtitle>

      <FlexContainer direction="row" align="center" columnGap={2} fullWidth>
        <SelectGroupMember
          groupId={groupId}
          initialValue={selectedContactId}
          onSelect={onSelect}
        />

        <IconButton
          icon={<PlusIcon color="black" />}
          style={{ marginTop: "8px" }}
          onClick={onCreate}
        />
      </FlexContainer>
    </FlexContainer>
  );
};
