// TODO: use react query instead of mobx
import { makeAutoObservable } from "mobx";

import { Customer, DispatchLocation, Trip } from "@busie/utils";
import {
  fetchCustomersByIds,
  fetchQuotes,
  getDispatchLocations,
  QuotesResponse,
  QuoteWithAttrs,
} from "@busie/api";
import { fetchTrips } from "@busie/api";
import { notificationStore } from "@busie/features";
import {
  getFilterParams,
  collectQuotesWithTrips,
} from "~/QuotesAndBookingsPage/shared/lib";

import { ARCHIVED_QUOTES_STATUSES, ITEMS_PER_PAGE } from "../constants";

type AuthTokens = {
  quotes: string;
  trips: string;
  customers: string;
  dispatchLocations: string;
};

class ArchivedQuotesStore {
  authTokens: AuthTokens = {
    quotes: "",
    trips: "",
    customers: "",
    dispatchLocations: "",
  };
  quotesResponse: QuotesResponse | null = null;
  customers: Customer[] = [];
  dispatchLocations: DispatchLocation[] = [];
  items: QuoteWithAttrs[] = [];
  isFetching = true;

  constructor() {
    makeAutoObservable(this);
    this.setItems = this.setItems.bind(this);
  }
  public async setItems(page?: number): Promise<void> {
    console.log("set items");
    this.isFetching = true;
    try {
      const trips = await fetchTrips(this.authTokens["trips"], {
        // populateLegs: true,
      });
      const tripIdMap = new Map(trips.map((trip) => [trip._id, trip]));

      const filterParams = getFilterParams();

      const quotesResponse = await fetchQuotes(this.authTokens["quotes"], {
        status: ARCHIVED_QUOTES_STATUSES,
        locationId:
          filterParams.locationId === "ALL" ? null : filterParams.locationId,
        page,
        itemsPerPage: ITEMS_PER_PAGE,
        pickupDateFrom: filterParams.pickupDateFrom,
        pickupDateTo: filterParams.pickupDateTo,
        [`${filterParams.searchParam}`]: filterParams.searchValue,
      });
      this.quotesResponse = quotesResponse;
      const quotes = quotesResponse.resultSet;

      if (!quotes.length) {
        this.isFetching = false;
        return;
      }

      const filteredTrips = quotes.reduce((acc, quote) => {
        if (tripIdMap.has(quote._experienceId)) {
          acc.push(tripIdMap.get(quote._experienceId) as Trip);
        }
        return acc;
      }, [] as Trip[]);

      const customerIds = filteredTrips.map((trip) => trip._tripPlannerId);
      const customers = await fetchCustomersByIds(
        this.authTokens["customers"],
        customerIds
      );
      const quotesWithTrips = await collectQuotesWithTrips(quotes, {
        customers,
        trips: filteredTrips,
      });

      this.dispatchLocations = await getDispatchLocations(
        this.authTokens.dispatchLocations
      );

      this.items = quotesWithTrips;

      this.isFetching = false;
    } catch (e: unknown) {
      notificationStore.setNotificationFromError(e);
    }
  }

  public setAuthTokens(authTokens: AuthTokens): void {
    this.authTokens = authTokens;
  }
  public reset() {
    console.log("archived quotes store reset");
    this.authTokens = {
      quotes: "",
      trips: "",
      customers: "",
      dispatchLocations: "",
    };
    this.customers = [];
    this.dispatchLocations = [];
    this.items = [];
    this.isFetching = true;
  }
}

export const store = new ArchivedQuotesStore();
