import * as yup from "yup";

export const schema = yup.object({
  lastMinuteSurge: yup.number().min(0).max(100).required(),
  fuelSurcharge: yup.number().min(0).max(100).required(),
  taxSurcharge: yup.number().min(0).max(100).required(),
  email: yup.string().email().required(),
  defaultSignerName: yup.string().required(),
  enablePlatformPricingMarkup: yup.boolean().required(),
  enableAssigneeContractSigner: yup.boolean().required(),
});
