import { useMutation } from "react-query";
import { createDriver } from "@busie/api";
import { CreateDriverParams } from "@busie/api";
import { useDriversAuthToken } from "@busie/core";
import { invalidateDriversQueries } from "~/drivers/model";

export const useCreateDriver = () => {
  const driversAuthToken = useDriversAuthToken();

  const mutation = useMutation(async (data: CreateDriverParams) => {
    const driver = await createDriver(driversAuthToken, data);

    invalidateDriversQueries();
    return driver;
  });

  return mutation;
};
