import { SxProps, Theme } from "@mui/system";
import React from "react";
import InputLabel from "../InputLabel";
import TextAreaStyled from "./TextArea.styled";

interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  sx?: SxProps<Theme>;
}

const TextArea: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  label,
  sx,
  ...props
}) => {
  return (
    <>
      <InputLabel htmlFor={props.id}>{label}</InputLabel>
      <TextAreaStyled sx={sx} {...props}>
        {children}
      </TextAreaStyled>
    </>
  );
};

export default TextArea;
