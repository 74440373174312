import React from "react";

import { ChefQuote } from "@busie/api";
import { QuotesIntegration, dayjsExt } from "@busie/utils";
import {
  DrawerObjectDetails,
  OrganizationLogo,
} from "~/QuotesAndBookingsPage/shared/ui";
import { ArrowRight, FlexContainer, H4, Loading, palette } from "@busie/ui-kit";

interface Props {
  destinationId: string;
  sourceId: string;
  expirationDate: string;
  integrations: QuotesIntegration;
  revenueShareDestinations: {
    organizationId: string;
    apportionmentValue: number;
  }[];
}

export const Details: React.FC<React.PropsWithChildren<Props>> = ({
  integrations,
  destinationId,
  sourceId,
  expirationDate,
  revenueShareDestinations,
}) => {
  const sourceRevShare = revenueShareDestinations.find(
    (dest) => dest.organizationId === sourceId
  );
  const destinationRevShare = revenueShareDestinations.find(
    (dest) => dest.organizationId === destinationId
  );

  return (
    <DrawerObjectDetails
      name="Integration Info"
      secondaryProperties={[
        [
          "Path",
          <FlexContainer
            direction="row"
            justify="space-evenly"
            columnGap={2}
            align="center"
            fullWidth
          >
            <FlexContainer direction="column" rowGap={1} align="center">
              <OrganizationLogo
                src={integrations.organizationLogos[sourceId]}
              />
              {sourceRevShare && (
                <H4>Rev Share: {sourceRevShare.apportionmentValue}%</H4>
              )}
            </FlexContainer>

            <ArrowRight size={24} color={palette.black.main} />

            <FlexContainer direction="column" rowGap={1} align="center">
              <OrganizationLogo
                src={integrations.organizationLogos[destinationId]}
              />
              {destinationRevShare && (
                <H4>Rev Share: {destinationRevShare.apportionmentValue}%</H4>
              )}
            </FlexContainer>
          </FlexContainer>,
        ],
        ["Expiration Date", dayjsExt(expirationDate).format("dddd, L LT")],
      ]}
    />
  );
};
