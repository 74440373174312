/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import store from "~/vehicles/store";
import {
  H4,
  DatePicker,
  FlexContainer,
  palette,
  TimeInput,
} from "@busie/ui-kit";
import { AdapterDayjs as AdapterDateDayJS } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DayjsExt, dayjsExt } from "@busie/utils";

import DateFieldStyled from "./styled/DateFieldStyled";
import TimeFieldStyled from "~/vehicles/Vehicles/components/ReservationForm/styled/TimeFieldStyled";

const DatesFields: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  const form = store.reservationPopup.form;
  const selectedVehiclesReservations =
    store.reservationPopup.form.selectedVehicleReservation;

  const excludedTimes = useCallback(
    (day: DayjsExt) =>
      // construct an array of 15 minutes intervals: ...12:00, 12:15, 12:30...
      [...Array(24).keys()]
        .reduce<Date[]>(
          (acc, hour) => [
            ...acc,
            ...[0, 15, 30, 45].map((minute) =>
              day
                .startOf("day")
                .add(hour, "hour")
                .add(minute, "minute")
                .toDate()
            ),
          ],
          []
        )
        // and filter it
        .filter((timeOption) => {
          if (dayjsExt(timeOption).isBefore(new Date())) return true;
          return selectedVehiclesReservations.some(
            (res) =>
              dayjsExt(res.startAt).add(-1, "minute").isBefore(timeOption) &&
              dayjsExt(res.endAt).add(1, "minute").isAfter(dayjsExt(timeOption))
          );
        }),
    [selectedVehiclesReservations]
  );

  const closestDeadline = useMemo(
    () =>
      selectedVehiclesReservations
        .filter((res) => dayjsExt(res.startAt).isAfter(form.startAt))
        .sort((res1, res2) =>
          dayjsExt(res1.startAt).isBefore(res2.startAt) ? -1 : 1
        )?.[0],
    [selectedVehiclesReservations, form.startAt]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateDayJS}>
      <div>
        <H4 color={palette?.black?.plus40}>Reservation start date</H4>
        <FlexContainer pb={1} columnGap={2}>
          <DateFieldStyled>
            <DatePicker
              value={form.startAt.toDate()}
              disablePast
              shouldDisableDate={(date) =>
                selectedVehiclesReservations.some(
                  (res) =>
                    dayjsExt(res.startAt).isBefore(date) &&
                    dayjsExt(res.endAt).isAfter(dayjsExt(date).add(1, "day"))
                )
              }
              onChange={(value) => store.setReservationDate("startAt", value)}
            />
          </DateFieldStyled>
          <TimeFieldStyled>
            <TimeInput
              value={form.startAt}
              onChange={(value) => {
                store.setReservationTime("startAt", value?.toDate() || null);
              }}
              excludedTimes={excludedTimes(form.startAt)}
            />
          </TimeFieldStyled>
        </FlexContainer>

        <H4 color={palette?.black?.plus40}>Reservation end date</H4>
        <FlexContainer columnGap={2}>
          <DateFieldStyled>
            <DatePicker
              value={form.endAt.toDate()}
              minDate={form.startAt.toDate()}
              shouldDisableDate={(date) => {
                if (closestDeadline) {
                  return dayjsExt(closestDeadline.startAt).isBefore(date);
                }
                return false;
              }}
              onChange={(value) => store.setReservationDate("endAt", value)}
            />
          </DateFieldStyled>
          <TimeFieldStyled>
            <TimeInput
              value={form.endAt}
              onChange={(value) =>
                store.setReservationTime(
                  "endAt",
                  (value || dayjsExt("10:00 AM", "HH:mm A")).toDate()
                )
              }
              excludedTimes={excludedTimes(form.startAt)}
            />
          </TimeFieldStyled>
        </FlexContainer>
      </div>
    </LocalizationProvider>
  );
});

export default DatesFields;
