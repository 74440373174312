import React from "react";

import { Modal } from "@busie/ui-kit";
import { notificationStore } from "@busie/features";
import { VoidContractForm } from "~/QuotesAndBookingsPage/entity";
import { VoidedContractInfo } from "@busie/utils";
import { useAmplitude } from "@busie/core";

import { useVoidContract } from "./model";

interface Props {
  isOpen: boolean;
  bookingId: string;
  quoteId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const VoidContract: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  bookingId,
  quoteId,
  onClose,
  onSuccess,
}) => {
  const { mutateAsync: voidContract } = useVoidContract();

  const { track } = useAmplitude();

  const onVoidContract = async (payload: VoidedContractInfo) => {
    try {
      await voidContract(
        { payload, bookingId },
        {
          onSuccess: () => {
            track("e-contract void", {
              bookingId,
              quoteId,
              reason: payload.reason,
            });

            notificationStore.setNotification({
              type: "success",
              header: "Success",
              message: "E-Contract voided successfully",
            });
            onSuccess();
            onClose();
          },
          onError: (e) => {
            notificationStore.setErrorNotification(
              "There was an error voiding the E-Contract. Please try again.",
              (e as Error).message || "An unknown error occurred"
            );
          },
        }
      );
    } catch (e) {
      notificationStore.setErrorNotification(
        "There was an error voiding the E-Contract. Please try again.",
        (e as Error).message || "An unknown error occurred"
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <VoidContractForm onCancel={onClose} onSubmit={onVoidContract} />
    </Modal>
  );
};
