/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import {
  AmountField,
  Button,
  FlexContainer,
  Loading,
  palette,
  Text,
  Divider as BusieDivider,
  ScrollableFlexContainer,
} from "@busie/ui-kit";
import { styled } from "@mui/system";

import { FormContainer } from "@busie/ui-kit";

import { RouteBlock } from "./RouteBlock";
import { store } from "../model";
import { observer } from "mobx-react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  isDispatchLocationPatchAllowed,
  User,
  currencyFormatter,
  centsToDollars,
  TripLeg,
} from "@busie/utils";
import { InfoBlock } from "./InfoBlock";

export type EditFormType = "quote" | "booking";

interface Props {
  type: EditFormType;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

const Divider = () => <BusieDivider margin={10} />;

export const EditTripForm: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ type, onSubmit, onClose }) => {
    const { user } = useAuth0<User>();
    let isLoading = false;

    const addLeg = (currentLeg: TripLeg, index: number) => {
      isLoading = true;
      store.addLeg(currentLeg, index);
      isLoading = false;
    };

    const removeLeg = (index: number) => {
      isLoading = true;
      store.removeLeg(index);
      isLoading = false;
    };

    if (
      user &&
      store.trip &&
      store.quote &&
      store.quoteId &&
      (type === "booking" ? store.booking && store.bookingId : true)
    ) {
      return (
        <>
          <InfoBlock />

          <FormContainer title="Passengers" fullWidth bordered={false}>
            <AmountField
              value={store.trip?._passengers || 0}
              label="Passengers amount"
              onChange={(e) => {
                if (store.trip && Number.isInteger(e)) {
                  store.setTrip({ ...store.trip, _passengers: e as number });
                }
              }}
              minValue={0}
            />
            <AmountField
              value={store.trip._wheelchairs}
              label="Accessible seating"
              onChange={(e) => {
                if (store.trip && Number.isInteger(e)) {
                  store.setTrip({ ...store.trip, _wheelchairs: e as number });
                }
              }}
              minValue={0}
            />
          </FormContainer>

          <Divider />

          <ScrollableFlexContainer maxHeight="40vh" h="40vh">
            {isLoading ? (
              <Loading />
            ) : (
              <RouteBlock
                isAdmin={isDispatchLocationPatchAllowed(user)}
                legs={store.trip?._legs}
                onAddLeg={addLeg}
                onRemoveLeg={removeLeg}
              />
            )}
          </ScrollableFlexContainer>

          <Divider />

          <FlexContainer
            direction="row"
            justify="space-between"
            align="center"
            pb={3}
            fullWidth
          >
            <FlexContainer direction="column" rowGap={0.5}>
              <Text>Total price</Text>
              <Text>
                {currencyFormatter(
                  centsToDollars(store.quote?._totalPrice || 0)
                )}
              </Text>
            </FlexContainer>
            <FlexContainer columnGap={1}>
              <Button typestyle="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button typestyle="primary" onClick={onSubmit} async>
                Save
              </Button>
            </FlexContainer>
          </FlexContainer>
        </>
      );
    }

    return <Loading />;
  }
);
