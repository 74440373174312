import { styled, Theme } from "@mui/system";
import { HeaderLinkProps } from "../HeaderLink";

import { NavLink } from "react-router-dom";

interface Props extends HeaderLinkProps {
  theme: Theme;
}

const HeaderLinkStyled = styled(NavLink)((props: Props) => {
  const palette = props.theme?.palette;
  return {
    "&": {
      color: palette?.black?.plus50,
      boxShadow: `inset 0px 0px 0px 0px ${palette?.black?.plus100}`,
      padding: "20px 24px",
      textDecoration: "none",
      fontWeight: "600",
      fontSize: "16px",
      transition: "color .2s ease, box-shadow .2s ease",
    },
    "&.selected": {
      color: palette?.black?.plus10,
      boxShadow: `inset 0px -4px 0px 0px ${palette?.black?.plus10}`,
    },
  };
});

export default HeaderLinkStyled;
