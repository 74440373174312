import { Modal } from "@busie/ui-kit";
import { Customer } from "@busie/utils";
import React from "react";
import { CustomFieldForm } from "~/CustomersPage/entity";
import { useCreateCustomerMetadata } from "./model";

interface Props {
  customerId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (customer: Customer) => void;
  onError: (e: unknown) => void;
}

export const AddCustomField: React.FC<Props> = ({
  isOpen,
  customerId,
  onClose,
  onSuccess,
  onError,
}) => {
  const { mutateAsync: addCustomField } = useCreateCustomerMetadata(customerId);

  const onSubmit = async (data: { key: string; value: string }) =>
    addCustomField(data, { onSuccess, onError });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CustomFieldForm mode="create" onClose={onClose} onSubmit={onSubmit} />
    </Modal>
  );
};
