import { FlexContainer, FormContainer } from "@busie/ui-kit";
import React from "react";

interface Props {
  children: React.ReactNode;
}

export const Container: React.FC<Props> = ({ children }) => {
  return (
    <FlexContainer
      rowGap={1}
      direction="column"
      align="flex-start"
      px={2}
      fullWidth
    >
      <FormContainer title="Customer Provided Additional Information">
        {children}
      </FormContainer>
    </FlexContainer>
  );
};
