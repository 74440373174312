import React from "react";

interface Props {
  color?: string;
  className?: string;
  size?: number;
}

export const IngredientIcon: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const size = props.size || 24;
  return (
    <svg
      width={size}
      height={size}
      style={{
        minHeight: size,
        minWidth: size,
      }}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 18.5H3"
        stroke={props.color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14.5H3"
        stroke={props.color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 10.5H3"
        stroke={props.color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6.5H3"
        stroke={props.color || "currentColor"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
