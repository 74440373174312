import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { Integration, isOrganizationSettingsAllowed, User } from "@busie/utils";
import { Text, FlexContainer, H1, Hr, Loading, palette } from "@busie/ui-kit";

import {
  useQuotesAndBookingsAuthToken,
  usePaymentsAuthToken,
} from "@busie/core";
import { notificationStore } from "@busie/features";

import { SettingsContainer } from "~/settings/shared/ui";

import { QuoteIntegrationsStore as store, paymentStore } from "../../store";
import {
  CreateIntegrationBlock,
  UpdateIntegrationModal,
  CardsGrid,
  StackedIntegrationBlock,
} from "./ui";

export const Page: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  const history = useHistory();
  const { user, isLoading } = useAuth0<User>();
  const authToken = useQuotesAndBookingsAuthToken();
  const paymentAuthToken = usePaymentsAuthToken();
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<Integration>();

  React.useEffect(() => {
    if (isLoading || !user) return;

    if (!isOrganizationSettingsAllowed(user)) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user]);

  const onNotificationChange = async () => {
    await store.getIntegrations(user?.org_id || "");
  };

  React.useEffect(() => {
    if (authToken) {
      store.setAuthToken(authToken);
      store.getIntegrations(user?.org_id || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  React.useEffect(() => {
    if (paymentAuthToken && !paymentStore.account) {
      paymentStore.setAuthToken(paymentAuthToken);
      paymentStore.getIsAccountLinked(user?.org_id || "");
    }
    if (paymentAuthToken && store.integrations.length > 0) {
      const configIds = store.integrations.map((i) => i.revenueShareConfigId);
      paymentStore.getRevenueShares(configIds, user?.org_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAuthToken, store.integrations]);

  const isPageLoading = store.loading;

  if (isPageLoading) return <Loading />;

  return (
    <SettingsContainer value="Integration">
      <FlexContainer
        direction="row"
        justify="space-between"
        align="center"
        fullWidth
      >
        <H1>Integrations</H1>
        <FlexContainer direction="row" align="center" columnGap={1}>
          <Text color={palette.red.main} weight={300}>
            Current Platform Fee:{" "}
            {paymentStore.account?.currentPlatformFee || 0}%
          </Text>
          <CreateIntegrationBlock
            onCreateSuccess={() => {
              notificationStore.setSuccessNotification(
                "Success",
                "Created a new integration"
              );
              store.getIntegrations(user?.org_id || "");
            }}
          />
        </FlexContainer>
      </FlexContainer>
      <Hr />

      {store.pendingIntegrations.length > 0 && (
        <StackedIntegrationBlock
          integrations={store.pendingIntegrations}
          onChange={onNotificationChange}
        />
      )}
      <br />
      <CardsGrid
        integrations={store.integrations}
        revenueShares={paymentStore.revenueShares}
        onClick={(integration: Integration) => {
          setSelectedIntegration(integration);
          setIsEditModalOpen(true);
        }}
      />

      <UpdateIntegrationModal
        isOpen={isEditModalOpen}
        integration={selectedIntegration}
        onClose={() => {
          setIsEditModalOpen(false);
          store.getIntegrations(user?.org_id || "");
        }}
      />
    </SettingsContainer>
  );
});
