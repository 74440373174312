import { useMutation } from "react-query";

import {
  CreateTripData,
  UpdateTripData,
  createTrip,
  updateTrip,
} from "@busie/api";
import { invalidateTrips, useTripsAuthToken } from "@busie/core";
import { Trip } from "@busie/utils";

export const useCreateTrip = () => {
  const authToken = useTripsAuthToken();

  const mutation = useMutation(async (data: CreateTripData): Promise<Trip> => {
    const result = await createTrip(authToken, data);

    invalidateTrips();
    return result;
  });

  return mutation;
};

export const useUpdateTrip = (id: string) => {
  const authToken = useTripsAuthToken();

  return useMutation(async (data: UpdateTripData): Promise<Trip> => {
    const result = await updateTrip(authToken, id, data);

    invalidateTrips();

    return result;
  });
};
