import React from "react";

import { FlexContainer, H3, H4, theme } from "@busie/ui-kit";
import { ReservationTypes } from "@busie/utils";
import { Box } from "@mui/system";
import { getReservationTypeBadgeContents } from "../CalendarView/helpers";

interface Props {
  type: ReservationTypes;
  quantity?: number;
  isMobile?: boolean;
}

const ReservationTypeBadge: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const contents = getReservationTypeBadgeContents(props.type);
  const Text = props.isMobile ? H4 : H3;
  return (
    <FlexContainer align="center" columnGap={1}>
      {!props.isMobile && (
        <Box
          sx={{
            backgroundColor: contents.background,
            border: `1px solid ${contents.color}`,
            borderRadius: "44px",
            height: theme?.spacing(1),
            width: theme?.spacing(1),
            minWidth: theme?.spacing(1),
          }}
        />
      )}
      <Text color={contents.color}>
        {`${contents.text}${props.quantity ? ` (${props.quantity})` : ""}`}
      </Text>
    </FlexContainer>
  );
};

export default ReservationTypeBadge;
