export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface LocationGeometry {
  lat: () => number;
  lng: () => number;
}

export interface GooglePlace {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: {
    location: LocationGeometry;
  };
  place_id: string;
  html_attributions: string[];
}

export const initialPlaceData: GooglePlace = {
  address_components: [{ long_name: "", short_name: "", types: [""] }],
  formatted_address: "",
  geometry: {
    location: {
      lat: () => 0,
      lng: () => 0,
    },
  },
  place_id: "",
  html_attributions: [""],
};

export interface RouteStop extends GooglePlace {
  id?: string;
}
