import React from "react";

import {
  VehicleIconProps,
  MotorcoachIcon,
  SleeperBusIcon,
  MiniBusIcon,
  EntertainerCoachIcon,
  DoubleDeckerIcon,
  VanIcon,
  SchoolBusIcon,
  TrolleyIcon,
  ExecutiveCoachIcon,
  BlackCarSuvIcon,
} from "@busie/ui-kit";
import { VehicleTypes } from "@busie/utils";

interface Props extends VehicleIconProps {
  type: VehicleTypes;
}

export const VehicleIcon: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  size = 56,
  color,
}) => {
  switch (type) {
    case VehicleTypes.MOTOR_COACH:
      return <MotorcoachIcon size={size} color={color} />;
    case VehicleTypes.SLEEPER_COACH:
      return <SleeperBusIcon size={size} color={color} />;
    case VehicleTypes.MINI_BUS:
      return <MiniBusIcon size={size} color={color} />;
    case VehicleTypes.ENTERTAINER_COACH:
      return <EntertainerCoachIcon size={size} color={color} />;
    case VehicleTypes.DOUBLE_DECKER:
      return <DoubleDeckerIcon size={size} color={color} />;
    case VehicleTypes.VAN:
      return <VanIcon size={size} color={color} />;
    case VehicleTypes.SCHOOL_BUS:
      return <SchoolBusIcon size={size} color={color} />;
    case VehicleTypes.TROLLEY:
      return <TrolleyIcon size={size} color={color} />;
    case VehicleTypes.EXECUTIVE_COACH:
      return <ExecutiveCoachIcon size={size} color={color} />;
    case VehicleTypes.BLACK_CAR:
      return <BlackCarSuvIcon size={size} color={color} />;
    default:
      return <div>No icon</div>;
  }
};
