import { Booking, Quote, Trip } from "@busie/utils";
import React from "react";
import { BookingStatusBlock } from "./BookingStatusBlock";
import { QuoteStatusBlock } from "./QuoteStatusBlock";

interface Props {
  quotesAuthToken: string;
  trip: Trip;
  quote: Quote;
  onUpdate: () => void;
  booking?: Booking;
}

export const StatusBlock: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  quote,
  booking,
  quotesAuthToken,
  onUpdate,
}) => {
  if (booking) {
    return (
      <BookingStatusBlock
        booking={booking}
        trip={trip}
        quotesAuthToken={quotesAuthToken}
        onUpdate={onUpdate}
      />
    );
  }

  return (
    <QuoteStatusBlock
      quote={quote}
      quotesAuthToken={quotesAuthToken}
      trip={trip}
      onUpdate={onUpdate}
    />
  );
};
