import React from "react";
import { useHistory } from "react-router";
import { useMediaQuery } from "@mui/material";

import { DispatchLocation, LogicalQuoteState } from "@busie/utils";

import { TableControlsStyled } from "./styled";
import { FilterTypeOption, FilterTypeSelector } from "./FilterTypeSelector";
import { SearchBlock } from "./SearchBlock";
import { AcceptedStatus, FilterBlock } from "./FilterBlock";
import { AddQuoteButton } from "./AddQuoteButton";

interface Props {
  type: LogicalQuoteState;
  dispatchLocations: DispatchLocation[];
  acceptedStatuses: AcceptedStatus[];
  filterTypeOptions: FilterTypeOption[];
}

export const TableControls: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  dispatchLocations,
  acceptedStatuses,
  filterTypeOptions,
}: Props) => {
  const isMobile = useMediaQuery("@media (max-width: 960px)");
  const history = useHistory();
  const searchType = (
    type.split(" ")[0] === "archived" ? type.split(" ")[1] : type
  ) as "quote" | "booking";

  return (
    <TableControlsStyled>
      <FilterTypeSelector value={`${type}s`} options={filterTypeOptions} />
      <FilterBlock
        type={type}
        dispatchLocations={dispatchLocations}
        acceptedStatuses={acceptedStatuses}
      />
      {isMobile && (
        <AddQuoteButton
          onClick={() => {
            history.push("/quotes-and-bookings/add-quote");
          }}
        />
      )}
      <SearchBlock type={searchType} />
    </TableControlsStyled>
  );
};
