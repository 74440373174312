import { VehicleStat, VehicleTypesValues } from "@busie/utils";
import Faker from "faker";

export const vehicleStatsMock: VehicleStat[] = [
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
  {
    vehicleType: Faker.random.arrayElement(VehicleTypesValues),
    vehicleId: Faker.vehicle.vin(),
    totalMiles: Faker.datatype.number(1000),
    totalPassengers: Faker.datatype.number(100),
    totalTrips: Faker.datatype.number(100),
    totalDaysRented: Faker.datatype.number(500),
    totalIncome: Faker.datatype.number(10000),
  },
];
