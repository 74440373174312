import React, { useState, useEffect } from "react";
import { Checkbox, Icon } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { env } from "@busie/utils";
import {
  FlexContainer,
  TableCell,
  TableRow,
  DeleteIcon,
  EditIcon,
  LocationIcon,
} from "@busie/ui-kit";
import { DispatchLocation, GooglePlace } from "@busie/utils";
import { datetimeFormatter } from "@busie/utils";

import { ButtonsWrapperStyled } from "./styled";

interface Props {
  location: DispatchLocation;
  isSelected?: boolean;
  onToggle: (id: string) => void;
  onDelete: (ids: string[]) => void;
  onEdit: (id: string, data: { name: string; location: GooglePlace }) => void;
}

export const DispatchListItem: React.FC<React.PropsWithChildren<Props>> = ({
  location,
  isSelected,
  onToggle,
  onDelete,
  onEdit,
}) => {
  const [areButtonsVisible, setAreButtonsVisible] = useState(false);
  const address = `${location.street}, ${location.state}, ${location.country}`;
  const { getPlacePredictions, placePredictions, placesService } =
    usePlacesService({
      apiKey: env("BUSIE_GOOGLE_MAPS_API_KEY"),
    });

  const editItem = () => getPlacePredictions({ input: address });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails: GooglePlace) =>
          onEdit(location.id, {
            name: location.name.trim(),
            location: {
              address_components: placeDetails.address_components,
              formatted_address: placeDetails.formatted_address,
              place_id: placeDetails.place_id,
              geometry: placeDetails.geometry,
              html_attributions: placeDetails.html_attributions,
            },
          })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions]);

  return (
    <TableRow
      hover={true}
      onMouseEnter={() => setAreButtonsVisible(true)}
      onMouseLeave={() => setAreButtonsVisible(false)}
    >
      <TableCell>
        <Checkbox
          sx={{
            padding: 0,
          }}
          checked={isSelected}
          onChange={() => onToggle(location.id)}
        />
      </TableCell>
      <TableCell>{location.name}</TableCell>
      <TableCell>
        <FlexContainer align="center" columnGap={1}>
          <LocationIcon size={"24"} />
          <span>{address}</span>
        </FlexContainer>
      </TableCell>
      <TableCell>
        {datetimeFormatter(new Date(location.createdAt), "datetime")}
      </TableCell>
      <TableCell width={110}>
        {areButtonsVisible && (
          <ButtonsWrapperStyled>
            <Icon
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={editItem}
            >
              <EditIcon />
            </Icon>
            <Icon
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => onDelete([location.id])}
            >
              <DeleteIcon />
            </Icon>
          </ButtonsWrapperStyled>
        )}
      </TableCell>
    </TableRow>
  );
};
