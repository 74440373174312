import React from "react";

import { Button, DrawerDetailsBlock } from "@busie/ui-kit";

import { AdditionalActionsStyled } from "./styled";
import { updateBookingStatus } from "@busie/api";

interface Props {
  status: string;
  quotesAuthToken: string;
  bookingId: string;
  onUpdate: () => void;
  onPrint: () => void;
}

const statusesToCancel = ["PENDING", "CONFIRMED", "PAID"];

export const AdditionalActions: React.FC<React.PropsWithChildren<Props>> = ({
  status,
  quotesAuthToken,
  ...props
}: Props) => {
  if (!statusesToCancel.includes(status)) return <div />;

  const hanlderCancel = async () => {
    await updateBookingStatus(quotesAuthToken, props.bookingId, "CANCELED");
    await props.onUpdate();
  };
  return (
    <DrawerDetailsBlock
      collapsible
      name="Additional actions"
      className="no-print"
    >
      <AdditionalActionsStyled>
        <Button async onClick={hanlderCancel}>
          Cancel booking
        </Button>
        <Button typestyle="secondary" async onClick={props.onPrint}>
          Print
        </Button>
      </AdditionalActionsStyled>
    </DrawerDetailsBlock>
  );
};
