import React from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation, useHistory } from "react-router-dom";
import {
  Text,
  Popup,
  Drawer as BusieDrawer,
  DrawerHeader,
  Loading,
  palette,
} from "@busie/ui-kit";
import {
  fetchBooking,
  fetchQuote,
  fetchQuoteIntegration,
  fetchTrip,
  getRevenueShareConfig,
  listRevenueShareConfigs,
  RevenueShareConfig,
} from "@busie/api";
import { Booking, Quote, Trip } from "@busie/utils";
import { useAmplitude } from "@busie/core";
import { quotesStore } from "~/QuotesAndBookingsPage/model";
import { getTripType, tripType } from "~/QuotesAndBookingsPage/shared/lib";

import { StatusBlock } from "../../entity/DetailsDrawer/StatusBlock";
import { BookingInfo } from "../../entity/DetailsDrawer/BookingInfo";
import { TripInfo } from "../../entity/DetailsDrawer/TripInfo";
import { TripRoute } from "../../entity/DetailsDrawer/TripRoute";
import { TripPlanner } from "../../entity/DetailsDrawer/TripPlanner";
import { TripIntegrationInfo } from "../../entity/DetailsDrawer/TripIntegrationInfo";
import { AdditionalActions } from "../../entity/DetailsDrawer/BookingAdditionalActions";
import { AdditionalInfo } from "../../entity/DetailsDrawer/TripAdditionalInfo";
import { TripType } from "../../entity/DetailsDrawer/TripType";
import { Contract } from "../../entity/DetailsDrawer/Contract";
import { Vehicles } from "../../entity/DetailsDrawer/Vehicles";
import { EditForm } from "../../entity/DetailsDrawer/EditForm";
import { SendContract } from "../../features/SendContract";
import { SendContractButton } from "../../entity/DetailsDrawer/Contract/SendContractButton";
import { VoidContractButton } from "../../entity/DetailsDrawer/Contract/VoidContractButton";
import { VoidContract } from "../../features/VoidContract";
import { BookingPaymentBlock } from "./ui/BookingPaymentBlock";
import { CustomerProfileDetails } from "../../../shared/CustomerProfileDetails";

interface Props {
  authTokens: {
    quotes: string;
    customers: string;
    trips: string;
    payments: string;
  };
  onListUpdate: () => void;
  isCustomerView?: boolean;
}

export const Drawer: React.FC<React.PropsWithChildren<Props>> = ({
  authTokens,
  onListUpdate,
  isCustomerView,
}: Props) => {
  const [isSendContractOpen, setIsSendContractOpen] = React.useState(false);
  const [isVoidContractOpen, setIsVoidContractOpen] = React.useState(false);

  const [trip, setTrip] = React.useState<Trip>({} as Trip);
  const [tripType, setTripType] = React.useState<tripType>();
  const [quote, setQuote] = React.useState<Quote>({} as Quote);
  const [booking, setBooking] = React.useState<Booking>({} as Booking);
  const [revShareConfig, setRevShareConfig] =
    React.useState<RevenueShareConfig>({} as RevenueShareConfig);
  const [dialogStatus, setDialogStatus] = React.useState<{
    open: boolean;
    mode: "view" | "edit";
  }>({
    open: false,
    mode: "view",
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [infoExpanded, setInfoExpanded] = React.useState(false);
  const [updatesCount, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const { track } = useAmplitude();

  const handlerUpdateQuoteAndTrip = async () => {
    const booking = await fetchBooking(
      authTokens["quotes"],
      searchParams.get("bookingId") || ""
    );
    setBooking(booking);

    const quote = await fetchQuote(authTokens["quotes"], booking._quoteId);
    setQuote(quote);

    let revShareConfig: RevenueShareConfig;
    if (quote._quotesIntegrationId) {
      const integration = await fetchQuoteIntegration(
        authTokens["quotes"],
        quote._quotesIntegrationId
      );
      revShareConfig = await getRevenueShareConfig(
        authTokens["payments"],
        integration.integration._revenueShareConfigId
      );
    } else {
      const revShares = await listRevenueShareConfigs(
        authTokens["payments"],
        quote._organizationId
      );
      // Assuming there will always be one default config
      revShareConfig = revShares.find(
        (rs) => rs.configType === "DEFAULT"
      ) as RevenueShareConfig;
    }
    setRevShareConfig(revShareConfig);

    const trip = await fetchTrip(authTokens["trips"], quote._experienceId, {
      populateLegs: true,
    });
    setTrip(trip);
    setTripType(
      getTripType(
        // first leg destination is the pickup
        trip._legs[0]._destinationLocation,
        // last leg start location is the destination
        trip._legs[trip._legs.length - 1]._startLocation
      )
    );
    setLoading(false);
    track("booking viewed", {
      balance: booking._balance,
      bookingId: booking._id,
      isRoundTrip:
        getTripType(
          trip._legs[0]._destinationLocation,
          trip._legs[trip._legs.length - 1]._startLocation
        ) === "round_trip",
      quoteId: quote._id,
      numberOfLegs: trip._legs.length,
      numberOfPassengers: trip._passengers,
      price: quote._totalPrice,
      tripId: trip._id,
    });
  };

  React.useEffect(() => {
    if (searchParams.has("bookingId")) {
      if (searchParams.get("mode") === "edit") {
        setDialogStatus({ open: true, mode: "edit" });
      } else {
        setDialogStatus({ open: true, mode: "view" });
      }
    } else {
      return;
    }
    authTokens["quotes"] &&
      authTokens["trips"] &&
      authTokens["payments"] &&
      handlerUpdateQuoteAndTrip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authTokens["quotes"], authTokens["trips"], authTokens["payments"]]);

  const handlerOnClose = () => {
    searchParams.delete("bookingId");
    history.replace({
      search: `?${searchParams.toString()}`,
    });

    setDialogStatus({ open: false, mode: "view" });
  };
  const handlerUpdate = async () => {
    setLoading(true);
    await handlerUpdateQuoteAndTrip();
    await onListUpdate();
  };
  const integration = quotesStore.getIntegration(
    quote._quotesIntegrationId || "",
    quote._organizationId
  );

  const componentRef = React.useRef(null);
  const printingRef = React.useRef(false);
  const handlePrint = () => {
    printingRef.current = true;
    if (infoExpanded) {
      forceUpdate();
    } else {
      setInfoExpanded(true);
    }
  };

  React.useEffect(() => {
    if (printingRef.current === true) {
      printingRef.current = false;
      print();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printingRef.current, updatesCount]);

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Trip Details",
  });

  const handleClose = () => {
    searchParams.delete("mode");
    history.replace({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    // TODO: BookingDetails and QuoteDetails are very similar in terms of markup. It could be beneficial to consolidate
    <>
      <BusieDrawer
        anchor="right"
        open={dialogStatus.open && dialogStatus.mode === "view"}
        onClose={handlerOnClose}
      >
        <DrawerHeader title="Trip Details" onClose={handlerOnClose}>
          <Text
            color={palette.black.plus100}
            onClick={() => {
              setDialogStatus({ open: true, mode: "edit" });
              searchParams.set("mode", "edit");
              history.replace({
                search: `?${searchParams.toString()}`,
              });
            }}
            style={{ cursor: "pointer" }}
          >
            Edit
          </Text>
        </DrawerHeader>
        {loading ? (
          <Loading />
        ) : (
          <div className="trip-details-wrapper" ref={componentRef}>
            <TripType tripType={tripType} />
            <StatusBlock
              quote={quote}
              quotesAuthToken={authTokens["quotes"]}
              trip={trip}
              booking={booking}
              onUpdate={handlerUpdate}
            />

            <Contract
              booking={booking}
              sendContractButton={
                <SendContractButton
                  onClick={() => setIsSendContractOpen(true)}
                  disabled={
                    !!booking._contractId &&
                    (!booking._contractExpired || booking._contractSigned)
                  }
                />
              }
              sendContract={
                <SendContract
                  isOpen={isSendContractOpen}
                  data={{
                    bookingId: booking._id,
                    name: quote._contactName,
                    email: quote._contactEmail || "",
                  }}
                  metadata={{
                    balance: booking._balance,
                    price: quote._totalPrice || 0,
                    quoteId: quote._id,
                  }}
                  onClose={() => setIsSendContractOpen(false)}
                  onSuccess={handlerUpdate}
                />
              }
              voidContract={
                <VoidContract
                  bookingId={booking._id}
                  quoteId={quote._id}
                  isOpen={isVoidContractOpen}
                  onClose={() => setIsVoidContractOpen(false)}
                  onSuccess={handlerUpdate}
                />
              }
              voidContractButton={
                <VoidContractButton
                  onClick={() => setIsVoidContractOpen(true)}
                />
              }
            />

            {booking._contractSigned && (
              <BookingPaymentBlock
                booking={booking}
                quote={quote}
                trip={trip}
                revenueShareConfigId={revShareConfig._id}
                onUpdate={handlerUpdate}
              />
            )}
            <BookingInfo trip={trip} quote={quote} booking={booking} />
            {trip && (trip._mainContactId || trip._tripPlannerId) && (
              <CustomerProfileDetails
                title="Main Contact Info"
                collapsible={true}
                customerId={trip._mainContactId || trip._tripPlannerId}
              />
            )}
            {trip._additionalInformation && (
              <AdditionalInfo
                trip={trip}
                expanded={infoExpanded}
                setExpanded={setInfoExpanded}
              />
            )}
            <Vehicles trip={trip} />
            <TripInfo trip={trip} showPriceData={true} />
            <TripRoute trip={trip} quote={quote} />
            <TripPlanner trip={trip} quote={quote} />
            {integration && (
              <TripIntegrationInfo quote={quote} integration={integration} />
            )}
            {!isCustomerView && (
              <AdditionalActions
                bookingId={booking._id}
                onUpdate={handlerUpdate}
                onPrint={handlePrint}
                status={booking._status}
                quotesAuthToken={authTokens["quotes"]}
              />
            )}
          </div>
        )}
      </BusieDrawer>
      <Popup
        isPopupOpened={dialogStatus.open && dialogStatus.mode === "edit"}
        title="Edit quote"
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "800px",
            maxWidth: "800px",
          },
        }}
      >
        <EditForm type="quote" id={booking._quoteId} onClose={handleClose} />
      </Popup>
    </>
  );
};
