import { palette } from "@busie/ui-kit";

import { ContractStatus } from "~/contracts/entity";

export const getBadgeContents = (type: ContractStatus) => {
  switch (type) {
    case "ACTIVE":
      return {
        text: "Active",
        background: palette.green.plus55,
        color: palette.green.minus5,
      };
    case "PENDING":
      return {
        text: "Pending",
        background: palette.supernova.plus40,
        color: palette.supernova.minus10,
      };
    case "CANCELED":
      return {
        text: "Canceled",
        background: palette.red.plus45,
        color: palette.red.minus5,
      };

    case "SUSPENDED":
      return {
        text: "Suspended",
        background: palette.purple.plus40,
        color: palette.purple.minus10,
      };

    case "EXPIRED":
      return {
        text: "Expired",
        background: palette.black.plus80,
        color: palette.black.plus10,
      };
    default:
      throw new Error("Invalid contract status");
  }
};
