export type GroupType =
  | "WEDDING"
  | "CORPORATE"
  | "SCHOOL"
  | "ATHLETIC"
  | "OTHER";

export const GROUP_TYPES: GroupType[] = [
  "WEDDING",
  "CORPORATE",
  "SCHOOL",
  "ATHLETIC",
  "OTHER",
];

export type GroupNote = {
  id: string;
  text: string;
  groupId: string;
  createdBy: string;
  createdAt: string;
  updatedAt?: string;
  updatedBy?: string;
};

export type GroupMetadata = {
  id: string;
  key: string;
  value: string;
  groupId: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
};

export type Group = {
  id: string;
  organizationId: string;
  isActive: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string | null;
  deletedAt: string | null;
  deletedBy: string | null;
  name: string;
  type: GroupType;
  members: Customer[];
  cookBookId: string | null;
  mainContactProfileId: string | null;
  memberIds: string[];
  notes: GroupNote[];
  metadata: GroupMetadata[];
};

export type CustomerNote = {
  id: string;
  text: string;
  profileId: string;
  createdBy: string;
  createdAt: string;
  updatedAt?: string;
  updatedBy?: string;
};

export type CustomerMetadata = {
  id: string;
  key: string;
  value: string;
  profileId: string;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
};

export type Customer = {
  id: string;
  name: string;
  groupIds: string[];
  groups: Group[];
  groupName?: string;
  groupId?: string;
  email?: string;
  countryCode?: string;
  areaCode?: string;
  phoneNumber?: string;
  userId: string;
  rating?: number;
  createdAt: Date;
  notes: CustomerNote[];
  metadata: CustomerMetadata[];
};
