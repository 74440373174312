import React from "react";

import { FilterSelector } from "@busie/ui-kit";

export interface FilterTypeOption {
  name: string;
  link: string;
}

interface Props {
  value: string;
  options: FilterTypeOption[];
}

export const FilterTypeSelector: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  options,
}: Props) => {
  return <FilterSelector value={value} options={options} />;
};
