import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  Divider,
  FlexContainer,
  palette,
  NoteText,
  Text,
  TextInput,
  FormContainer,
  Select,
  capitalize,
} from "@busie/ui-kit";
import { Invoice, InvoiceVoidReason } from "@busie/utils";
import { VoidInvoiceData } from "@busie/api";

import { schema } from "./schema";
import { INVOICE_VOID_REASON_OPTIONS } from "./constants";

interface Props {
  email: string;
  onSubmit: (voidInvoiceData: VoidInvoiceData) => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  email,
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    reset,
    getValues,
    getFieldState,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<VoidInvoiceData>({
    mode: "onChange",
    defaultValues: {
      voidReason: "" as unknown as InvoiceVoidReason,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Void Invoice">
      <Text>Are you sure you want to void this invoice?</Text>

      <NoteText>
        This will automatically void the invoice and send an email to the
        customer associated with the invoice{" "}
        <em>
          <b>({email})</b>
        </em>{" "}
      </NoteText>

      <br />

      <NoteText color={palette.red.main}>
        This action cannot be undone.
      </NoteText>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer
          align="flex-start"
          direction="column"
          columnGap={2}
          fullWidth
        >
          <Controller
            name="voidReason"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                items={INVOICE_VOID_REASON_OPTIONS.map((option) => ({
                  name: capitalize(option),
                  value: option,
                }))}
                label="Void Reason"
                placeholder="Select a reason for voiding this invoice"
                errorMessage={errors.voidReason?.message}
                fullWidth
              />
            )}
          />
          {getFieldState("voidReason").isDirty &&
          getValues("voidReason") === "OTHER" ? (
            <Controller
              name="voidReasonDetail"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="Void Reason Details"
                  placeholder="Please enter a custom reason for voiding this invoice"
                  errorMessage={errors.voidReasonDetail?.message}
                  fullWidth
                />
              )}
            />
          ) : null}
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            Void Invoice
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
