import { Button, FlexContainer, H4, PlusIcon, palette } from "@busie/ui-kit";
import React from "react";
import { Subtitle } from "../Subtitle";

export const FlatFees: React.FC = () => {
  return (
    <FlexContainer
      px={2}
      rowGap={1.25}
      direction="column"
      align="flex-start"
      fullWidth
    >
      <FlexContainer direction="row" align="center" columnGap={4}>
        <Subtitle color={palette.black.plus40}>
          Fees & Additional Charges
        </Subtitle>
        <H4 color={palette.black.plus40}>Coming Soon!</H4>
      </FlexContainer>

      <Button
        disabled
        startIcon={<PlusIcon color={palette.black.plus60} />}
        typestyle="tertiary"
        size="small"
        onClick={() => undefined}
      >
        Add Flat Fee
      </Button>
    </FlexContainer>
  );
};
