import { styled, Theme } from "@mui/system";
import { FlexContainer } from "@busie/ui-kit";

interface Props {
  theme?: Theme;
}

const VehicleBookingStyled = styled(FlexContainer)(({ theme }: Props) => {
  const palette = theme?.palette;
  return {
    boxShadow: `inset 0px 1px 0px ${palette?.black?.plus70}`,
    transition: "background .2s ease",
    ".actions-block": {
      opacity: 0,
      visibility: "hidden",
      transition: "opacity .2s ease",
    },
    "&:hover": {
      background: palette?.black?.plus85,
      ".actions-block": {
        opacity: 1,
        visibility: "visible",
      },
    },
  };
});

export default VehicleBookingStyled;
