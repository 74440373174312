import React from "react";
import { CalculateIcon, FlexContainer, IconButton } from "@busie/ui-kit";
import { centsToDollars, currencyFormatter, Quote, Trip } from "@busie/utils";
import { TripPriceBreakdown } from "./TripPriceBreakdown";

interface Props {
  quote: Quote;
  trip: Trip;
}

export const QuotePrice: React.FC<React.PropsWithChildren<Props>> = ({
  quote,
  trip,
}: Props) => {
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const price = currencyFormatter(centsToDollars(Number(quote._totalPrice)));

  return (
    <FlexContainer align="center" columnGap={2}>
      {price}
      <IconButton
        icon={<CalculateIcon color="black" />}
        onClick={() => setIsPopupOpen(true)}
      />
      <TripPriceBreakdown
        isOpen={isPopupOpen}
        trip={trip}
        quote={quote}
        onClose={() => setIsPopupOpen(false)}
      />
    </FlexContainer>
  );
};
