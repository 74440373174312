import React from "react";
import { Grid, Avatar } from "@mui/material";
import { datetimeFormatter as dateFormat } from "@busie/utils";
import { styled } from "@mui/system";
import { palette } from "@busie/ui-kit";
interface Props {
  username: string;
  picture: string;
  body: string;
  createdAt: string;
}

const CommentContainerStyled = styled(Grid)(() => {
  return {
    ".username": {
      fontSize: "14px",
      fontWeight: 600,
      color: palette.black.main,
      margin: 0,
      textAlign: "left",
    },
    ".date": {
      fontSize: "10px",
      fontWeight: 500,
      color: palette.black.plus50,
    },
    ".body": {
      fontSize: "14px",
      fontWeight: 500,
      color: palette.black.plus30,
      margin: "10px 0 20px",
    },
  };
});

const ReservationComment: React.FC<React.PropsWithChildren<Props>> = ({
  username,
  picture,
  body,
  createdAt,
}) => {
  return (
    <CommentContainerStyled container wrap="nowrap" spacing={2}>
      <Grid item sx={{ paddingLeft: "0 !important" }}>
        <Avatar alt={username} src={picture} />
      </Grid>
      <Grid justifyContent="left" item zeroMinWidth>
        <div className="username">{username}</div>
        <div className="date">{dateFormat(new Date(createdAt), "date")}</div>
        <div className="body">{body}</div>
      </Grid>
    </CommentContainerStyled>
  );
};

export default ReservationComment;
