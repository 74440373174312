import React from "react";
import { FlexContainer } from "../FlexContainer";
import IconButton from "../IconButton";
import { CloseIcon } from "../icons";
import palette from "../theme/palette";

import HeaderStyled from "./styled/HeaderStyled";

interface Props {
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const DrawerHeader: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <HeaderStyled>
      <div className="title">{props.title}</div>
      <FlexContainer
        justify="center"
        align="center"
        columnGap={3}
        className="controls"
      >
        {props.children}

        <IconButton
          onClick={props.onClose}
          type="button"
          highlightOnHover={false}
          icon={<CloseIcon color={palette?.black?.plus100} />}
        />
      </FlexContainer>
    </HeaderStyled>
  );
};

export default DrawerHeader;
