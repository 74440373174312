import { InvoicePaymentMethod } from "@busie/utils";
import * as yup from "yup";

export const paymentMethodOptions: InvoicePaymentMethod[] = [
  "ACH",
  "CASH",
  "CHECK",
  "CREDIT_CARD",
  "OTHER",
];

export const schema = yup.object({
  paymentMethod: yup
    .string()
    .oneOf<InvoicePaymentMethod>(
      paymentMethodOptions,
      `Payment Method must be one of: ${paymentMethodOptions.join(", ")}`
    )
    .required("Payment method is required"),
});
