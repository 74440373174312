import { Rule } from "@busie/utils";
import { datetimeFormatter as dateFormat } from "@busie/utils";
import { monthItems, weekdayItems } from "~/rates/RateMenuPage/store/constants";

export const prettifyRuleValue = (rule: Rule): string => {
  switch (rule.type) {
    case "STANDARD":
      return "--";

    case "DATE":
      return dateFormat(new Date(rule.value), "date");

    case "TOTAL_MILES":
    case "DAILY_MILES":
    case "LEG_MILES":
      return `${rule.value} miles`;

    case "TOTAL_TIME":
    case "DAILY_TIME":
    case "LEG_TIME":
      return `${rule.value} hours`;

    case "DAY_OF_WEEK":
      return (
        weekdayItems.find(({ value }) => value === +rule.value)?.name || ""
      );
    case "MONTH":
      return monthItems.find(({ value }) => value === +rule.value)?.name || "";

    default:
      return rule.value;
  }
};
