import React from "react";

import { Button, H1, ChevronPrev } from "@busie/ui-kit";

import { palette } from "@busie/ui-kit";

import { Styled } from "./styled";

interface Props {
  title: string;
  onNavBack?: () => void;
}

export const PageHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  onNavBack,
}: Props) => {
  return (
    <Styled>
      <div className="controls">
        {onNavBack && (
          <Button
            typestyle="tertiary"
            onClick={onNavBack}
            startIcon={<ChevronPrev color={palette?.black?.plus10} />}
          >
            Back
          </Button>
        )}
      </div>
      <H1 className="page-title">{title}</H1>
    </Styled>
  );
};
