import { styled } from "@mui/material/styles";

import { default as MuiTable, TableProps } from "@mui/material/Table";

const TableStyled = styled(MuiTable)<TableProps>(() => {
  return {
    // boxShadow: "none",
  };
});

export default TableStyled;
