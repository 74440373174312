import React from "react";
import { styled } from "@mui/system";

import { Text, palette, theme } from "@busie/ui-kit";

import ColumnsStyled from "./styled/ColumnsStyled";
import { useMediaQuery } from "@mui/material";

const CollapsedViewHeaderStyled = styled(ColumnsStyled)(() => {
  return {
    backgroundColor: palette?.black?.plus80,
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
  };
});

const CollapsedViewHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <CollapsedViewHeaderStyled isMobile={isMobile}>
      <Text>Vehicle type</Text>
      <Text>Vehicle ID</Text>
      <div> </div>
    </CollapsedViewHeaderStyled>
  );
};

export default CollapsedViewHeader;
