import React from "react";
import { VehicleIconProps as Props } from ".";

export const DoubleDeckerIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="36" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="18" y="24" width="8" height="8" rx="1" stroke="#FF0018" />
      <path
        d="M20.5 42H34.75M13.25 42H7C5.89543 42 5 41.1046 5 40V30.8379C5 30.6142 5.03752 30.3921 5.11101 30.1809L8.53288 20.343C8.81256 19.5389 9.57057 19 10.4219 19H50V40C50 41.1046 49.1046 42 48 42H43.375"
        stroke="#FF0018"
      />
      <path
        d="M15.4315 10.0423C15.7819 9.39977 16.4554 9 17.1873 9H48C49.1046 9 50 9.89543 50 11V19H13V15.01C13 14.6754 13.084 14.3461 13.2442 14.0523L15.4315 10.0423Z"
        stroke="#FF0018"
      />
      <path
        d="M7 24H13C13.5523 24 14 24.4477 14 25V31C14 31.5523 13.5523 32 13 32H5"
        stroke="#FF0018"
      />
      <path
        d="M15 12H25C25.5523 12 26 12.4477 26 13V15C26 15.5523 25.5523 16 25 16H13"
        stroke="#FF0018"
        strokeLinecap="square"
      />
      <rect x="28" y="24" width="8" height="8" rx="1" stroke="#FF0018" />
      <rect x="38" y="24" width="8" height="8" rx="1" stroke="#FF0018" />
      <rect x="28" y="12" width="8" height="4" rx="1" stroke="#FF0018" />
      <rect x="38" y="12" width="8" height="4" rx="1" stroke="#FF0018" />
      <circle cx="17" cy="42" r="4" stroke="#222222" />
      <circle cx="39" cy="42" r="4" stroke="#222222" />
    </svg>
  );
};

export default DoubleDeckerIcon;
