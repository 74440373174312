import { ChefLocation, CreateLocationData } from "@busie/api";

export const duplicate = ({
  LATITUDE,
  LONGITUDE,
  ADDRESS,
  STREET,
  CITY,
  STATE,
  COUNTRY,
}: ChefLocation): CreateLocationData => ({
  latitude: LATITUDE,
  longitude: LONGITUDE,
  address: ADDRESS,
  street: STREET,
  city: CITY,
  state: STATE,
  country: COUNTRY,
});
