import { ChefContract } from "@busie/api";
import {
  ClientCell,
  ContractACVCell,
  ContractBillingCyclesCell,
  ContractNumberCell,
  ContractStatusCell,
  CreatedAtCell,
} from "../ui";
import { datetimeFormatter, dayjsExt } from "@busie/utils";
import { ContractStatus } from "~/contracts/entity";

export const getTableData = (contracts: ChefContract[], small: boolean) =>
  contracts.map(
    ({
      _id,
      START_DATE,
      END_DATE,
      RENEWAL_DATE,
      CREATED_AT,
      STATUS,
      MAIN_CONTACT,
      CLIENT,
      QUOTES,
      BOOKINGS,
      RENEWALS,
    }) => ({
      id: <ContractNumberCell value={_id} />,
      client: (
        <ClientCell
          groupName={CLIENT.NAME}
          mainContactName={MAIN_CONTACT.NAME}
        />
      ),
      billingCycles: (
        <ContractBillingCyclesCell quotes={QUOTES} bookings={BOOKINGS} />
      ),
      acv: <ContractACVCell quotes={QUOTES} renewals={RENEWALS || []} />,
      createdAt: <CreatedAtCell value={CREATED_AT} />,
      startDate: datetimeFormatter(dayjsExt(START_DATE), "dddd, L"),
      endDate: datetimeFormatter(dayjsExt(END_DATE), "dddd, L"),
      renewalDate: datetimeFormatter(dayjsExt(RENEWAL_DATE), "dddd, L"),
      status: (
        <ContractStatusCell value={STATUS as ContractStatus} small={small} />
      ),
    })
  );
