import { ReservationStatuses, ReservationTypes } from "@busie/utils";
import { palette } from "@busie/ui-kit";
import { styled } from "@mui/styles";
import {
  getReservationTypeBadgeContents,
  getReservationStatusBadgeContents,
} from "../helpers";

const ReservationBadgeStyled = styled("div")(
  ({
    type,
    isPast,
    isMobile,
  }: {
    type: ReservationTypes;
    isPast?: boolean;
    isMobile?: boolean;
  }) => {
    const color = isPast
      ? palette.black.plus80
      : getReservationTypeBadgeContents(type).background;
    return {
      minHeight: isMobile ? "40px" : "24px",
      maxHeight: isMobile ? "40px" : "24px",
      fontSize: "12px",
      backgroundColor: color,
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      borderRadius: "2px",
      columnGap: "8px",
      border: "transparent 1px solid",
      "&:hover": {
        cursor: "pointer",
        border: `${palette.black.plus40} 1px solid`,
      },
      overflowY: "hidden",
    };
  }
);

export const ReservationStatusIndicatorStyled = styled("div")(
  ({ status, isPast }: { status: ReservationStatuses; isPast?: boolean }) => {
    const color = isPast
      ? palette.black.plus60
      : getReservationStatusBadgeContents(status).color;
    return {
      height: "8px",
      width: "8px",
      backgroundColor: color,
      borderRadius: "100px",
    };
  }
);

export const ReservationsWrapperStyled = styled("div")(
  ({ isMobile }: { isMobile?: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: "2px",
    maxHeight: isMobile ? "40vh" : "130px",
    overflowY: "scroll",
  })
);

export default ReservationBadgeStyled;
