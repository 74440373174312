import { styled } from "@mui/system";

import theme from "../../theme";
import palette from "../../theme/palette";

interface Props {
  outlined?: boolean;
}

const TablePaginationStyled = styled("div")<Props>(
  ({ outlined = true }: Props) => {
    return {
      "&": {
        width: "100%",
        fontWeight: "500",
        fontSize: "14px",
        background: palette.black.plus100,
        color: palette.black.plus30,
        letterSpacing: "0.04em",
        padding: "9px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "0px 0px 4px 4px",
        border: outlined ? `1px solid ${palette.black.plus70}` : "none",
        ".controls": {
          display: "flex",
          alignItems: "center",
          span: {
            margin: "0 10px",
          },
        },
        [theme.breakpoints.down("tablet")]: {
          justifyContent: "center",
          border: 0,
          background: "transparent",
        },
      },
    };
  }
);

export default TablePaginationStyled;
