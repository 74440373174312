import React from "react";
import { useMediaQuery } from "@mui/material";

import {
  theme,
  PathIcon,
  DrawerDetailsBlock,
  Text,
  palette,
} from "@busie/ui-kit";

import { tripType } from "~/QuotesAndBookingsPage/shared/lib";
import { TripTypeStyled } from "./styled";

type Props = { tripType: tripType | undefined };

export const TripType: React.FC<React.PropsWithChildren<Props>> = ({
  tripType,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
  const tripTypeCopy =
    tripType
      ?.split("_")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ") || undefined;

  return (
    <DrawerDetailsBlock noHeader padding="16px 18px 8px 18px">
      <TripTypeStyled>
        <PathIcon
          size={isMobile ? "24px" : "16px"}
          color={palette.blue.minus10 || "#00000"}
        />
        <Text color={palette.blue.minus10 || "#00000"}>
          {tripTypeCopy
            ? `This is a ${tripTypeCopy}`
            : "Unable to determine Trip Type"}
        </Text>
      </TripTypeStyled>
    </DrawerDetailsBlock>
  );
};

export default TripType;
