import React from "react";
import { styled } from "@mui/styles";
import { Text, palette, Select } from "@busie/ui-kit";
import { Vehicle, VehicleTypes } from "@busie/utils";
import { observer } from "mobx-react-lite";
import store from "~/vehicles/store";

interface Props {
  vehicles: Vehicle[];
  vehicleType: VehicleTypes;
  index: number;
}

const SelectStyled = styled(Select)(() => {
  return {
    width: "100%",
    textAlign: "left",
  };
});

const VehicleSelect: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ vehicles, vehicleType, index }: Props) => {
    const items = vehicles.filter(
      (vehicle) => vehicle.vehicleType.type === vehicleType
    );
    if (!items.length) {
      return <Text color={palette?.red?.minus5}>No vehicle to choose</Text>;
    }
    return (
      <SelectStyled
        items={items}
        defaultValue=""
        onChange={(e) => store.selectVehicleToReserve(index, e.target.value)}
        getName={(item) => item.licensePlate}
        getValue={(item) => item}
      />
    );
  }
);

export default VehicleSelect;
