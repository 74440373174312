import React from "react";

import { Trip } from "@busie/utils";
import { DrawerDetailsBlock } from "@busie/ui-kit";
import { TripLegsDetails } from "@busie/features";

interface Props {
  trip: Trip;
  dispatchLocationName?: string;
  showPriceData?: boolean;
}

export const TripInfo: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  dispatchLocationName,
  showPriceData = false,
}: Props) => {
  return (
    <DrawerDetailsBlock collapsible name="Trip Info">
      <TripLegsDetails
        trip={trip}
        dispatchLocationNameProp={dispatchLocationName}
        showPriceData={showPriceData}
      />
    </DrawerDetailsBlock>
  );
};
