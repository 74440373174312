import React, { FC } from "react";
import { CookbooksTableColumns } from "~/rates/RateMenuPage/components/CookbooksTableView/CookbooksTableColumns";
import { styled } from "@mui/styles";
import { Text, palette, theme } from "@busie/ui-kit";
import {
  useShowBaseIngredients,
  useShowSideIngredients,
  useShowRules,
  useShowRecipes,
} from "~/rates/RateMenuPage/hooks";

const Container = styled(CookbooksTableColumns)(() => {
  return {
    borderBottom: `1px solid ${palette.black.plus60}`,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  };
});

export const TableHeader: FC<React.PropsWithChildren<unknown>> = () => {
  const showSideIngrdients = useShowSideIngredients();
  const showBaseIngredients = useShowBaseIngredients();
  const showRules = useShowRules();
  const showRecipes = useShowRecipes();
  return (
    <Container>
      <Text sx={{ gridColumn: showSideIngrdients ? "1/3" : "" }}>Cookbook</Text>
      <Text>Type</Text>
      {showRecipes && <Text>Recipe</Text>}
      {showRules && <Text>Rule</Text>}
      {showBaseIngredients && <Text>Base Ingredient</Text>}
      {showSideIngrdients && <Text>Side Ingredient</Text>}
    </Container>
  );
};
