import React from "react";
import { styled } from "@mui/system";

import { Text, DrawerDetailsBlock, palette } from "@busie/ui-kit";
import { Trip } from "@busie/utils";

interface Props {
  trip: Trip;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const COLLAPSED_MAX_LENGTH = 500;

interface ExpandProps {
  expanded: boolean;
}

const ExpandHandle = styled("span")<ExpandProps>(({ expanded }) => {
  return {
    cursor: "pointer",
    fontWeight: 700,
    color: palette.black.plus40,
    zIndex: 2,
    whiteSpace: "nowrap",
    boxShadow: expanded ? "" : "-20px 0px 10px -1px rgba(255,255,255,.8)",
  };
});

export const AdditionalInfo: React.FC<React.PropsWithChildren<Props>> = ({
  trip,
  expanded,
  setExpanded,
}) => {
  const showExpand = trip._additionalInformation
    ? trip._additionalInformation.length > COLLAPSED_MAX_LENGTH
    : false;
  const text = trip._additionalInformation || "";
  return (
    <DrawerDetailsBlock name="Additional Info" collapsible>
      <Text weight={400} color={palette.black.main}>
        {expanded ? text : text.slice(0, COLLAPSED_MAX_LENGTH - 1)}
        {showExpand && (
          <ExpandHandle
            className="no-print"
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? " Show less" : "...Show more"}
          </ExpandHandle>
        )}
      </Text>
    </DrawerDetailsBlock>
  );
};
