import React from "react";
import { observer } from "mobx-react-lite";

import { WarningPopup } from "@busie/ui-kit";
import { deleteLocationStore } from "~/settings/store/dispatch";

export const DeleteLocationPopup: React.FC<React.PropsWithChildren<unknown>> =
  observer(() => {
    const isMultipleLocations =
      deleteLocationStore.locationsToDelete.length > 1;
    const text = isMultipleLocations
      ? "Are you sure you want to delete these dispatch locations?"
      : "Are you sure you want to delete the dispatch location?";

    return (
      <WarningPopup
        title={`Delete dispatch location${isMultipleLocations ? "s" : ""}?`}
        isPopupOpened={deleteLocationStore.isDeleteModalOpen}
        onClose={deleteLocationStore.closeDeleteModal}
        onConfirm={deleteLocationStore.deleteSelectedLocations}
        confirmText="Delete"
      >
        {text}
      </WarningPopup>
    );
  });
