import { styled } from "@mui/system";
import palette from "../../theme/palette";

interface Props {
  color?: string;
}

export const CalloutStyled = styled("div")(({ color }: Props) => ({
  paddingBottom: "5px",
  color: color || palette.black.main,
  fontSize: "10px",
  width: "100%",
}));
