import {
  Button,
  DoneIcon,
  FlexContainer,
  H3,
  NoteText,
  Subtitle,
} from "@busie/ui-kit";
import { observer } from "mobx-react";
import FormContainerStyled from "../styled/FormContainerStyled";
import { centsToDollars, currencyFormatter } from "@busie/utils";
import { useState } from "react";

interface Props {
  onClose?: () => void;
  onClickDone: () => void;
  price: number;
  showPrice?: boolean;
  embedded?: boolean;
}

export const TripCreatedBlock: React.FC<React.PropsWithChildren<Props>> =
  observer(
    ({
      onClose: handleOnClose,
      price,
      onClickDone: handleOnClickDone,
      showPrice = true,
      embedded = false,
    }: Props) => {
      const [isLoading, setIsLoading] = useState(false);

      const onClickDone = async () => {
        setIsLoading(true);
        handleOnClickDone();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      };

      return (
        <FormContainerStyled sx={{ padding: "20px" }}>
          <FlexContainer columnGap={2} justify="space-between" align="center">
            <FlexContainer columnGap={2}>
              <DoneIcon />
              <Subtitle className="title">
                Quote{embedded ? " Request Received!" : " Created!"}
              </Subtitle>
            </FlexContainer>

            {showPrice && (
              <FlexContainer direction="column">
                <div style={{ textAlign: "right" }}>
                  <NoteText>{embedded ? "Estimated " : ""}Price</NoteText>
                  <H3 margin="0">
                    {currencyFormatter(
                      centsToDollars(Math.ceil(Number(price)))
                    )}
                  </H3>
                </div>
              </FlexContainer>
            )}

            <Button
              loading={isLoading}
              typestyle="primary"
              onClick={onClickDone}
            >
              {embedded ? "Next" : "Done"}
            </Button>

            {handleOnClose && (
              <Button onClick={handleOnClose}>Close Window</Button>
            )}
          </FlexContainer>
        </FormContainerStyled>
      );
    }
  );
