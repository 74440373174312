import React, { useState } from "react";
import { QuoteAndBookingStatus, ReasonForDecline } from "@busie/utils";
import {
  Button,
  InputText,
  NoteText,
  palette,
  FlexContainer,
  Modal,
  FormContainer,
  Divider,
  TextInput,
  Select,
} from "@busie/ui-kit";

interface Props {
  oldStatus: QuoteAndBookingStatus;
  newStatus: QuoteAndBookingStatus;
  onConfirm: (reason?: string) => void;
  onCancel: () => void;
  open: boolean;
  isNetworkQuote: boolean;
}

export const StatusUpdateConfirmation: React.FC<
  React.PropsWithChildren<Props>
> = ({ oldStatus, newStatus, onCancel, onConfirm, open, isNetworkQuote }) => {
  const [reason, setReason] = useState("");
  const [showOthersText, setShowOthersText] = useState(false);

  const getFriendlyStatus = (status: QuoteAndBookingStatus) =>
    ({
      [QuoteAndBookingStatus.PENDING]: "Pending",
      [QuoteAndBookingStatus.CONFIRMED]: "Confirmed",
      [QuoteAndBookingStatus.PAID]: "Rejected",
      [QuoteAndBookingStatus.CANCELED]: "Cancelled",
      [QuoteAndBookingStatus.COMPLETE]: "Complete",
      [QuoteAndBookingStatus.ACCEPTED]: "Accepted",
      [QuoteAndBookingStatus.DECLINED]: "Declined",
    }[status]);

  const getStatusChangeNotes = (
    prevStatus: QuoteAndBookingStatus,
    newStatus: QuoteAndBookingStatus
  ): string => {
    if (prevStatus === "PENDING" && newStatus === "CONFIRMED") {
      return "This will send a Quote Confirmation Email to the email address set as the Quotes 'main contact', which includes Trip itinerary details and total quoted price";
    }

    if (prevStatus === "CONFIRMED" && newStatus === "ACCEPTED") {
      return "This will create a PENDING Booking and will automatically send an e-contract to first be signed by the Admin of your organization, and then by the main contact for this quote";
    }

    if (newStatus === "DECLINED") {
      const networkQuoteText =
        "Since this is a Network Quote, you will no longer have access to most of the data associated with this quote. ";
      return `This will archive this quote. ${
        (isNetworkQuote && networkQuoteText) || ""
      }This will not send an email to the main contact for this quote.`;
    }

    return "";
  };

  const getReasonForDeclineItems = () => {
    const items = Object.values(ReasonForDecline).map((value) => ({
      name: value,
      value,
    }));
    return items;
  };

  const handleSelectReasonForDecline = (value: string) => {
    setReason(value);
    setShowOthersText(value === ReasonForDecline.OTHER);
  };

  const getDeclinedReason = () => {
    return (
      <>
        <Divider />
        <FlexContainer direction="column">
          <Select
            items={getReasonForDeclineItems()}
            onChange={(e) => handleSelectReasonForDecline(e.target.value)}
            label="Select a reason for decline"
            fullWidth
          ></Select>
          {showOthersText && (
            <TextInput
              onChange={(e) => setReason(e.target.value)}
              type="text"
              label="Enter the reason for decline"
              fullWidth
            />
          )}
        </FlexContainer>
      </>
    );
  };

  return (
    <Modal isOpen={open} onClose={onCancel}>
      <FormContainer title="Confirm Quote Status Change">
        <InputText>
          Are you sure you want to transition this Quote from{" "}
          <b>
            {getFriendlyStatus(oldStatus)} → {getFriendlyStatus(newStatus)}
          </b>
          ?
        </InputText>

        <NoteText>{getStatusChangeNotes(oldStatus, newStatus)}</NoteText>

        <NoteText color={palette.red.main}>
          This action cannot be undone.
        </NoteText>

        {newStatus === QuoteAndBookingStatus.DECLINED && getDeclinedReason()}

        <FlexContainer justify="flex-end" align="center" columnGap={2} pt={4}>
          <Button typestyle="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button async onClick={() => onConfirm(reason)}>
            Confirm
          </Button>
        </FlexContainer>
      </FormContainer>
    </Modal>
  );
};
