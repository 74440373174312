import { ErrorBoundary, Badge, SmallBadge } from "@busie/ui-kit";
import React from "react";
import { ContractStatus } from "~/contracts/entity";
import { getBadgeContents } from "./lib";

interface Props {
  value: ContractStatus;
  small: boolean;
  maxWidth?: string;
}

export const Cell: React.FC<Props> = ({ value, small, maxWidth }) => {
  return (
    <ErrorBoundary errorText="Could not determine contract status">
      {small ? (
        <SmallBadge
          color={getBadgeContents(value).color}
          text={getBadgeContents(value).text}
        />
      ) : (
        <Badge
          color={getBadgeContents(value).color}
          background={getBadgeContents(value).background}
          maxWidth={maxWidth}
          minWidth="120px"
        >
          {getBadgeContents(value).text}
        </Badge>
      )}
    </ErrorBoundary>
  );
};
