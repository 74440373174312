import { Select as BaseSelect, FlexContainer } from "@busie/ui-kit";
import { Chip } from "@mui/material";
import React from "react";

interface Props {
  selected: string[];
  items: { name: string; value: string }[];
  onChange: (value: string[]) => void;
  getName: (id: string) => string;
}

const renderValue = (selected: unknown, getName: (id: string) => string) => (
  <FlexContainer justify="flex-start" direction="row" wrap="wrap" columnGap={1}>
    {(selected as string[]).map((dispatch) => (
      <Chip key={dispatch} label={getName(dispatch)} />
    ))}
  </FlexContainer>
);

export const Select: React.FC<Props> = ({
  onChange,
  getName,
  selected,
  items,
}) => (
  <BaseSelect
    label="Trip Dispatch Location"
    onChange={(e) => onChange(e.target.value as string[])}
    hideErrorSpace
    value={selected}
    renderValue={(selected) => renderValue(selected, getName)}
    items={items}
    multiple
    fullWidth
  />
);
