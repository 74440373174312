import { styled, Theme } from "@mui/system";
interface Props {
  theme?: Theme;
}

export const ErrorMessage = styled("p")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    "&": {
      color: palette?.red?.main,
      fontWeight: "400",
      fontSize: "10px",
      lineHeight: "150%",
      margin: "0",
      height: "14px",
    },
  };
});
