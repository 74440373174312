import { palette } from "@busie/ui-kit";
import { styled } from "@mui/system";

export const Styled = styled("span")(() => {
  return {
    cursor: "pointer",
    fontWeight: 100,
    color: palette.black.plus10,
    zIndex: 2,
    whiteSpace: "nowrap",
    boxShadow: "-20px 0px 10px -1px rgba(255,255,255,.8)",
    fontSize: "10px",
  };
});
