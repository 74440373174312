import { NumberFormatValues } from "react-number-format";

export const percentageValidation = (
  inputObj: NumberFormatValues,
  platformFee = 0
): boolean => {
  const { value } = inputObj;
  const numberValue = Number(value);
  return numberValue <= 100 - platformFee && numberValue >= 0;
};
