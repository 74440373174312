import React from "react";
import { Dayjs } from "dayjs";
import { FlexContainer } from "@busie/ui-kit";
import { dayjsExt } from "@busie/utils";

interface Props {
  date: string | Date | number | Dayjs | undefined;
}

export const Text: React.FC<Props> = ({ date }) => {
  return (
    <FlexContainer direction="row" fullWidth>
      {dayjsExt(date).format("L LT")}
    </FlexContainer>
  );
};
