import React from "react";

interface Props {
  color?: string;
  size?: number;
}

export const SplitIcon: React.FC<Props> = ({
  color = "#3B3B3B",
  size = 24,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.9999" cy="21.7143" r="1.71429" fill={color} />
      <path d="M7 3L9 7H5L7 3Z" fill={color} />
      <path d="M17 3L19 7H15L17 3Z" fill={color} />
      <path
        d="M12 19V16.8721C12 16.2172 11.719 15.5937 11.2284 15.1598L7.77165 12.1034C7.28098 11.6695 7 11.046 7 10.391V7"
        stroke={color}
        stroke-linecap="round"
      />
      <path
        d="M12 19V16.8721C12 16.2172 12.281 15.5937 12.7716 15.1598L16.2284 12.1034C16.719 11.6695 17 11.046 17 10.391V7"
        stroke={color}
        stroke-linecap="round"
      />
    </svg>
  );
};
