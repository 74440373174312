import { styled, Theme } from "@mui/system";

interface Props {
  theme?: Theme;
  hasContent?: boolean;
  isFullWidth?: boolean;
  outlined?: boolean;
}

const MenuHeaderStyled = styled("div")((props: Props) => {
  const palette = props.theme?.palette;
  return {
    width: "100%",
    maxWidth: props.isFullWidth ? "" : "1360px",
    boxSizing: "border-box",
    margin: `16px auto ${props.outlined ? "16px" : "0"}`,
    backgroundColor: props.outlined ? "#fff" : "transparent",
    boxShadow: "",
    border: props.outlined ? `1px solid ${palette?.black?.plus70}` : "none",
    // justifyContent: "flex-end",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-between",
    textAlign: "left",
  };
});

export default MenuHeaderStyled;
