import { styled } from "@mui/system";

import { inputStyles } from "../shared/inputStyles";

interface Props {
  margin?: string | number;
  width?: string;
}

const PlaceFieldBlockStyled = styled("div")<Props>((props) => {
  const palette = props.theme.palette;
  return {
    width: props.width,
    ".field-block": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",

      ".input-block": {
        width: "100%",
        maxWidth: "420px",
        position: "relative",
        [props.theme.breakpoints.down("tablet")]: {
          marginRight: "8px",
        },
        ".start-adornment": {
          position: "absolute",
          top: "10px",
          left: "10px",
        },
      },
      "input.pac-target-input": {
        ...inputStyles,
        width: "100%",
        boxSizing: "border-box",
        fontFamily: "Poppins, sans-serif",
        background: palette?.black?.plus100,
        border: `1px solid ${palette?.black?.plus80}`,
        margin: props.margin || "0 0 20px 0",
        "&:focus": {
          outline: `1px solid ${palette?.black.plus10}`,
        },
        "&::-webkit-input-placeholder": {
          color: "rgba(0, 0, 0, 0.29)",
        },

        "&:-ms-input-placeholder": {
          color: "rgba(0, 0, 0, 0.29)",
        },

        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.29)",
        },
        "&::-moz-placeholder": {
          color: "rgba(0, 0, 0, 0.29)",
        },
        "&:disabled": {
          WebkitTextFillColor: "rgb(158, 158, 158)",
        },
      },
    },
  };
});

export default PlaceFieldBlockStyled;
