import React from "react";

interface Props {
  color?: string;
  size?: number;
}
const FootrestsIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = "#222",
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="footrests"
    >
      <path
        fill={color}
        stroke={color}
        d="M10.781 44.218a.5.5 0 01.512-.12l2.553.85a1 1 0 00.316.052h2.22a1 1 0 01.894.553l.362.723a.5.5 0 01-.447.724h-4.068a.996.996 0 01-.242-.03l-3.024-.756a.5.5 0 01-.233-.838l1.157-1.157z"
      ></path>
      <path stroke={color} strokeLinecap="round" d="M17 45l3-6"></path>
      <path
        fill={color}
        stroke={color}
        d="M33.786 17.713a1 1 0 01.958-.713H35.8a2 2 0 011.992 2.19L36.11 36.843a1 1 0 01-1.238.875l-2.32-.58a1 1 0 01-.715-.683l-1.223-4.075c-.075-.251-.1-.514-.075-.774l.442-4.415c.013-.127.037-.253.074-.375l2.731-9.103z"
      ></path>
      <path
        fill={color}
        stroke={color}
        d="M19.667 34.5a1 1 0 01.931-.44L30 35l4.38.876a1 1 0 01.773.738l.536 2.144A1 1 0 0134.72 40H22.333a1 1 0 01-.6-.2l-2.976-2.232a1 1 0 01-.232-1.355l1.142-1.713z"
      ></path>
      <circle cx="33.5" cy="36.5" r="1.5" fill="#fff" stroke={color}></circle>
      <circle cx="20.5" cy="38.5" r="1.5" fill="#fff" stroke={color}></circle>
    </svg>
  );
};

export default FootrestsIcon;
