import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

import { isOrganizationSettingsAllowed, User, env } from "@busie/utils";
import { Button, H1, H2, H3, Hr, Loading, palette } from "@busie/ui-kit";
import { SettingsContainer } from "~/settings/shared/ui";

import { usePlan } from "@busie/core";
import { PricingTable } from "./ui";

export const Plan: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user, isLoading: isUserLoading } = useAuth0<User>();
  const history = useHistory();

  const { data: plan, isLoading: isPlanLoading } = usePlan(user?.org_id);

  useEffect(() => {
    if (isUserLoading || !user) return;

    if (!isOrganizationSettingsAllowed(user)) history.goBack();
  }, [history, user, isUserLoading]);

  if (isPlanLoading || isUserLoading || !plan) return <Loading />;

  // plan.subscriptionType = "FREEMIUM";
  return (
    <SettingsContainer value="Plan">
      {plan?.subscriptionType === "FREEMIUM" ? (
        <div className="pricing-table">
          <PricingTable
            pricingTableId={env("STRIPE_PRICING_TABLE_ID")}
            publishableKey={env("STRIPE_PUBLISHABLE_KEY")}
            organizationId={user?.org_id as string}
          />
        </div>
      ) : (
        <>
          <H1>Plan</H1>
          <Hr />
          <H2>Manage Subscription</H2>
          <div className="dashboard-link">
            <H3>Current Plan: {plan.subscriptionType}</H3>
            <H3 color={palette.black.plus30} weight={500}>
              Use the button below to visit the subscription management page
            </H3>
            <Button
              typestyle="primary"
              size="small"
              onClick={() => window.open(env("STRIPE_CUSTOMER_PORTAL_URL"))}
            >
              Manage Subscription
            </Button>
          </div>
        </>
      )}
    </SettingsContainer>
  );
};
