import React from "react";
import { observer } from "mobx-react-lite";
import { H4, palette, Select } from "@busie/ui-kit";
import store from "~/vehicles/store";

import InputFieldStyled from "./styled/InputFieldStyled";
import { ReservationTypes } from "@busie/utils";

const ReasonOptions = [
  {
    name: "Charter",
    value: ReservationTypes.CHARTER,
  },
  {
    name: "Line run",
    value: ReservationTypes.LINE_RUN,
  },
  {
    name: "Maintenance",
    value: ReservationTypes.MAINTENANCE,
  },
  {
    name: "Other",
    value: ReservationTypes.OTHER,
  },
];

const ReasonField: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  return (
    <InputFieldStyled>
      <H4 color={palette?.black?.plus40}>What is the reservation reason?</H4>
      <Select
        onChange={(e) => store.setReservationReason(e.target.value)}
        items={ReasonOptions}
        value={store.reservationPopup.form.reason}
      />
    </InputFieldStyled>
  );
});

export default ReasonField;
