import React from "react";
import { styled } from "@mui/system";

import {
  Modal,
  FlexContainer,
  palette,
  DoneIcon,
  WarningIcon,
  H2,
  H3,
} from "@busie/ui-kit";
import { Notification } from "~/settings/store/payment";

const StyledContainer = styled(FlexContainer)(() => ({
  width: "370px",
  height: "306px",
  boxShadow: "0px 8px 24px rgba(34, 34, 34, 0.15)",
  borderRadius: "4px",
  margin: "0 auto",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  h3: {
    color: palette?.black?.plus30,
    fontWeight: 500,
    marginTop: "5px",
  },

  svg: {
    marginBottom: "30px",
  },
}));

const icons = {
  success: () => <DoneIcon size="58" color={palette?.green?.main} />,
  error: () => <WarningIcon size="58" color={palette?.red?.main} />,
};

interface Props {
  isOpen: boolean;
  notification: Notification;
  onClose: () => void;
}

export const StatusModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  notification,
  onClose,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <StyledContainer bordered>
      {icons[notification.type]()}
      <H2>{notification.content.title}</H2>
      <H3>{notification.content.text}</H3>
    </StyledContainer>
  </Modal>
);
