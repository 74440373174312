import * as yup from "yup";

import { DayjsExt, dayjsExt } from "@busie/utils";

import { Place } from "./interface";
import { FieldValues } from "react-hook-form";
import { RouteResponse } from "@busie/api";

export interface FormValues extends FieldValues {
  mainContact: string;
  additionalInformation: string;
  dispatchId: string;
  passengers: number;
  wheelchairs: number;
  stops: Place[];
  departures: DayjsExt[];
  route: RouteResponse;
  spab: boolean;
}

const waypointSchema = () =>
  yup.object({
    linkId: yup.string(),
    sideOfStreet: yup.string(),
    position: yup.object({
      latitude: yup.number(),
      longitude: yup.number(),
    }),
    spot: yup.number(),
    label: yup.string(),
    shapeIndex: yup.number(),
  });

export const schema = yup.object({
  mainContact: yup.string().required(),
  additionalInformation: yup.string(),
  dispatchId: yup.string().required(),
  passengers: yup.number().required().moreThan(0),
  wheelchairs: yup.number().required().min(0),
  spab: yup.boolean(),
  stops: yup.array().of(
    yup.object({
      formatted_address: yup.string().required(),
      geometry: yup.object({
        location: yup.object({
          lat: yup.number().required(),
          lng: yup.number().required(),
        }),
      }),
    })
  ),
  departures: yup
    .array()
    .of(
      yup
        .mixed()
        .test("is-dayjs", "Invalid date", (value) => dayjsExt(value).isValid())
    )
    .required(),
  route: yup
    .object({
      summaryText: yup.string(),
      metricSystem: yup.string(),
      currency: yup.string(),
      totalDistance: yup.number(),
      totalDistancePerState: yup.object(),
      estimateTravelTime: yup.number(),
      estimateTravelTimeWithTraffic: yup.number(),
      estimateTotalCost: yup.number(),
      estimateDriverCost: yup.number(),
      estimateVehicleCost: yup.number(),
      tollCost: yup.number(),
      shapes: yup.array().of(yup.number()),
      waypoints: yup.array().of(waypointSchema()),
      legs: yup
        .array()
        .of(
          yup.object({
            start: waypointSchema(),
            end: waypointSchema(),
            distance: yup.number(),
            estimateTravelTime: yup.number(),
            maneuvers: yup.array().of(
              yup.object({
                id: yup.string().required(),
                position: yup.object({
                  latitude: yup.number(),
                  longitude: yup.number(),
                }),
                instruction: yup.string(),
                estimateTravelTime: yup.number(),
                distance: yup.number(),
              })
            ),
          })
        )
        .required(),
    })
    .required(),
});
