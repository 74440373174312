import { styled } from "@mui/system";

interface Props {
  isMobile?: boolean;
}

const ColumnsStyled = styled("div")(({ isMobile }: Props) => {
  return {
    display: "grid",
    gridTemplateColumns: `2fr ${isMobile ? 2 : 5}fr 1.5fr 1fr`,
    alignItems: "center",
    width: "100%",
    textAlign: "left",
    padding: isMobile ? "8px" : "16px",
  };
});

export default ColumnsStyled;
