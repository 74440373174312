import React, { useEffect, useState } from "react";

import { Trip, dayjsExt } from "@busie/utils";
import { useAmplitude } from "@busie/core";
import {
  Divider,
  H2,
  Loading,
  ScrollableFlexContainer,
  Stepper,
  theme,
} from "@busie/ui-kit";
import { CreateTripData, RouteResponse } from "@busie/api";
import { notificationStore } from "@busie/features";

import {
  getStepIcon,
  // useCreateTrip,
  useTripMainContact,
  useCreateTripRoute,
  invalidateCreateTripQueries,
} from "./model";
import { Styled } from "./ui/styled";
import {
  DeparturesForm,
  RouteForm,
  SelectCustomerForm,
  TripDetailsForm,
} from "./ui";
import { TripDetailsFormData } from "./ui/TripDetailsForm/model";
import { Summary } from "./ui/Summary";
import { useMediaQuery } from "@mui/material";
import { Modal } from "~/QuotesAndBookingsPage/shared/ui";
import { useCreateTrip } from "~/QuotesAndBookingsPage/entity";

interface Props {
  quoteId: string;
  customerGroupId: string;
  mainContactId: string;
  isOpen: boolean;
  organizationId: string;
  onClose: () => void;
  onTripCreated: (trip: Trip) => Promise<void> | void;
}

export const Widget: React.FC<React.PropsWithChildren<Props>> = ({
  quoteId,
  customerGroupId,
  mainContactId,
  organizationId,
  isOpen,
  onClose,
  onTripCreated,
}) => {
  const isMobile = useMediaQuery("@media (max-width: 650px)");
  const steps = ["Customer", "Trip", "Route", "Departures", "Summary"];

  const INITIAL_STEP = 0;

  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);

  const [tripMainContactId, setTripMainContactId] =
    useState<string>(mainContactId);

  const [tripData, setTripData] = useState<TripDetailsFormData>({
    passengers: 0,
    wheelchairs: 0,
    additionalInfo: "",
    amenities: [],
    spab: false,
  });

  const [routeData, setRouteData] = useState<RouteResponse>({
    totalDistance: 0,
    legs: [],
    tollCost: 0,
    summaryText: "",
    shapes: [],
    metricSystem: "",
    estimateTravelTime: 0,
    estimateTravelTimeWithTraffic: 0,
    currency: "",
    totalDistancePerState: {},
    estimateTotalCost: 0,
    estimateDriverCost: 0,
    estimateVehicleCost: 0,
    waypoints: [],
  });

  const [legData, setLegData] = useState<
    // create an interface for this
    {
      start: {
        address: string;
        latitude: number;
        longitude: number;
      };
      destination: {
        address: string;
        latitude: number;
        longitude: number;
      };
      travelTime: number;
      travelDistance: number;
    }[]
  >([]);

  const [trip, setTrip] = useState<Trip>();

  const { data: mainContact, isLoading: isMainContactLoading } =
    useTripMainContact(tripMainContactId);

  const { mutateAsync: createTripRoute, isLoading: isCreateTripRouteLoading } =
    useCreateTripRoute();

  const { mutateAsync: createTrip, isLoading: isCreateTripLoading } =
    useCreateTrip();

  const { track } = useAmplitude();

  useEffect(() => () => setCurrentStep(INITIAL_STEP), [isOpen]);

  const onSubmit = async (data: CreateTripData) => {
    try {
      await createTrip(data, {
        onSuccess: async (result) => {
          track("trip added to quote", {
            quoteId,
            customerGroupId,
            newTrip: result,
          });

          notificationStore.setNotification({
            type: "success",
            message:
              "Your trip has been created and is being added to the desired quote. It may take a few seconds for these updates to take effect.",
            header: "Trip Created!",
          });

          invalidateCreateTripQueries();

          setTrip(result);
          onTripCreated(result);
        },
        onError: (e) => {
          notificationStore.setNotificationFromError(e);
        },
      });
    } catch (e) {
      notificationStore.setNotificationFromError(e);
    }
  };

  const getActiveForm = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SelectCustomerForm
            defaultValue={mainContactId}
            groupId={customerGroupId}
            onCancel={onClose}
            onSubmit={(result) => {
              setTripMainContactId(result.id);
              setCurrentStep((s) => s + 1);
            }}
          />
        );
      case 1:
        return (
          <TripDetailsForm
            onCancel={onClose}
            onSubmit={(data: TripDetailsFormData) => {
              setTripData(data);
              setCurrentStep((s) => s + 1);
            }}
          />
        );
      case 2:
        return (
          <RouteForm
            onCancel={onClose}
            onSubmit={async (data) => {
              const { pickup, destination, stops } = data;

              const start = `${pickup.geometry.location.lat},${pickup.geometry.location.lng}`;
              const end = `${destination.geometry.location.lat},${destination.geometry.location.lng}`;
              const waypoints = stops.map(
                (s) => `${s.geometry.location.lat},${s.geometry.location.lng}`
              );

              const result = await createTripRoute({
                start,
                end,
                waypoints,
                vehicleType: "MOTOR_COACH",
                routeType: start === end ? "ROUND_TRIP" : "ONE_WAY",
              });

              setRouteData(result);

              const legData = [
                {
                  start: {
                    address: pickup.formatted_address,
                    latitude: pickup.geometry.location.lat,
                    longitude: pickup.geometry.location.lng,
                  },
                  destination: {
                    address: stops.length
                      ? stops[0].formatted_address
                      : destination.formatted_address,
                    latitude: stops.length
                      ? stops[0].geometry.location.lat
                      : destination.geometry.location.lat,
                    longitude: stops.length
                      ? stops[0].geometry.location.lng
                      : destination.geometry.location.lng,
                  },
                  travelTime: result.legs[0].estimateTravelTime * 1000,
                  travelDistance: result.legs[0].distance,
                },
                ...stops.map((s, index, arr) => ({
                  start: {
                    address: s.formatted_address,
                    latitude: s.geometry.location.lat,
                    longitude: s.geometry.location.lng,
                  },
                  destination: {
                    address:
                      index === arr.length - 1
                        ? destination.formatted_address
                        : arr[index + 1].formatted_address,
                    latitude:
                      index === arr.length - 1
                        ? destination.geometry.location.lat
                        : arr[index + 1].geometry.location.lat,
                    longitude:
                      index === arr.length - 1
                        ? destination.geometry.location.lng
                        : arr[index + 1].geometry.location.lng,
                  },
                  travelTime: result.legs[0].estimateTravelTime * 1000,
                  travelDistance: result.legs[0].distance,
                })),
              ];

              setLegData(legData);
              setCurrentStep((s) => s + 1);
            }}
          />
        );
      case 3:
        return (
          <DeparturesForm
            onCancel={onClose}
            legData={legData.map((leg) => ({
              start: leg.start.address,
              destination: leg.destination.address,
              travelTime: leg.travelTime,
            }))}
            onSubmit={async (data) => {
              const createTripData: CreateTripData = {
                quoteId,
                organizationId,
                passengers: tripData.passengers,
                additionalInformation: tripData.additionalInfo,
                wheelchairs: tripData.wheelchairs,
                spab: tripData.spab,
                meters: routeData.totalDistance,
                amenities: tripData.amenities,
                hours: routeData.estimateTravelTime / (1000 * 60 * 60),
                legs: legData.map((leg, index) => ({
                  startLocation: leg.start,
                  destinationLocation: leg.destination,
                  departureDatetime: dayjsExt(
                    data.departures[index]
                  ).toISOString(),
                  arrivalDatetime: dayjsExt(data.departures[index])
                    .add(leg.travelTime, "ms")
                    .toISOString(),
                  meters: leg.travelDistance,
                  hours: leg.travelTime / (1000 * 60 * 60),
                })),
                tripPlannerId: mainContactId,
                tripPlannerGroupId: customerGroupId,
                mainContactId: mainContactId,
              };

              await onSubmit(createTripData);
              setCurrentStep((s) => s + 1);
            }}
          />
        );
      case 4:
        return (
          <Summary
            summaryData={{
              passengers: tripData.passengers,
              wheelchairs: tripData.wheelchairs,
              amenities: tripData.amenities,
              contactName: mainContact?.name || "",
              contactEmail: mainContact?.email || "",
              contactNumber: mainContact
                ? `+${mainContact.countryCode} (${mainContact.areaCode}) ${mainContact.phoneNumber}`
                : "",
              totalMeters: trip?._meters || 0,
              liveMeters: trip?._liveMeters || 0,
              deadHeadMeters: trip?._deadHeadMeters || 0,
              tripPrice: trip?._price || 0,
              legs:
                trip?._legs.map((leg) => ({
                  start: leg._startLocation.address as string,
                  destination: leg._destinationLocation.address as string,
                  travelTime: leg._hours,
                  departure: dayjsExt(leg._departureDateTime),
                  arrival: dayjsExt(leg._arrivalDateTime),
                  price: leg._legPrice.reduce(
                    (sum, lp) => sum + lp.subtotal,
                    0
                  ),
                  meters: leg._meters,
                })) || [],
            }}
            onClose={onClose}
          />
        );
      default:
        return <div>Something went wrong</div>;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {/* TODO: This should be dynamic based some prop provded by the parent */}
      <H2>Add Trip to Quote</H2>

      <Divider />

      <Stepper step={currentStep} getIcon={getStepIcon} stepList={steps} />

      <ScrollableFlexContainer
        direction="column"
        rowGap={isMobile ? 2 : 0}
        minW={isMobile ? "375px" : "500px"}
        maxW="740px"
        sx={{
          margin: isMobile ? 0 : "16px auto 0",
          [theme.breakpoints.down("tablet")]: {
            padding: "15px 25px",
          },
        }}
        align="center"
      >
        {isCreateTripLoading ||
        isMainContactLoading ||
        isCreateTripRouteLoading ? (
          <Loading />
        ) : (
          getActiveForm(currentStep)
        )}
      </ScrollableFlexContainer>
    </Modal>
  );
};
