import React from "react";
import get from "lodash.get";
import { observer } from "mobx-react-lite";
import { useLocation, useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";

import {
  datetimeFormatter as dateFormat,
  currencyFormatter,
  centsToDollars,
  metersToMiles,
  DispatchLocation,
} from "@busie/utils";
import {
  palette,
  CommonTable,
  TableContainer,
  Loading,
  TablePagination,
} from "@busie/ui-kit";
import {
  StatusBadge,
  TableControls,
  MobileList,
} from "~/QuotesAndBookingsPage/shared/ui";
import { QuotesResponse, QuoteWithAttrs } from "@busie/api";
import {
  ITEMS_PER_PAGE,
  archivedQuotesStore,
} from "~/QuotesAndBookingsPage/model";

import { ACCEPTED_STATUSES, FILTER_TYPE_OPTIONS } from "./model";

interface Props {
  quotes: QuoteWithAttrs[];
  quotesResponse: QuotesResponse | null;
  dispatchLocations: DispatchLocation[];
  currentPage: number;
  setPage: (page: number) => void;
}

export const Table: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    quotes,
    quotesResponse,
    dispatchLocations,
    currentPage,
    setPage,
  }: Props) => {
    console.log("Table loaded");
    const { search } = useLocation();
    const history = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

    const searchParams = new URLSearchParams(search);
    const filterStatus = searchParams.get("status");
    const handlerOnSetTrip = (id: string) => {
      searchParams.set("quoteId", id);
      searchParams.set("page", currentPage.toString());

      history.replace({
        search: `?${searchParams.toString()}`,
      });
    };

    const tableConfig = {
      columnNames: [
        { id: "quote-id", name: "Quote ID", ordered: false },
        { id: "trip-planner", name: "Trip Planner", ordered: false },
        { id: "passengers", name: "Passengers", ordered: false },
        { id: "trip-distance", name: "Trip Distance", ordered: false },
        { id: "pickup-date", name: "Pick Up Date", ordered: false },
        { id: "price", name: "Price", ordered: false },
        { id: "created", name: "Created", ordered: false },
        { id: "status", name: "Status", ordered: false },
      ],
      checkboxKeys: quotes.map((item) => {
        return item["_id"];
      }),
      data: quotes.map((item) => {
        const trip = item.trip;
        if (!trip) return {};
        return {
          id: (
            <span style={{ color: palette.red?.plus5 }}>
              {item["_id"].split("-")[0]}
            </span>
          ),
          tripPlanner: item.tripPlanner?.name,
          passengers: trip._passengers,
          miles: `${metersToMiles(trip._meters)} mi`,
          departureDateTime: dateFormat(
            new Date(trip._startDate as string),
            "date"
          ),
          price: currencyFormatter(centsToDollars(item?._price || 0)),
          createdAt: item["_createdAt"]
            ? dateFormat(new Date(item["_createdAt"]), "datetime")
            : "",
          status: <StatusBadge type={item["_status"]} />,
        };
      }),
    };

    const TableComponent = isMobile ? MobileList : CommonTable;

    return (
      <TableContainer sx={{ marginTop: "16px" }} isMobile={isMobile}>
        <Box>
          <TableControls
            filterTypeOptions={FILTER_TYPE_OPTIONS}
            acceptedStatuses={ACCEPTED_STATUSES}
            type="archived quote"
            dispatchLocations={dispatchLocations}
          />
          <div className="table-container">
            {archivedQuotesStore.isFetching ? (
              <Loading />
            ) : (
              <TableComponent
                columnNames={tableConfig.columnNames}
                data={tableConfig.data}
                withCheckbox={!!filterStatus && filterStatus !== "ALL"}
                checkboxKeys={tableConfig.checkboxKeys}
                onRowClick={(id: string) => handlerOnSetTrip(id)}
              />
            )}
          </div>
        </Box>
        {quotesResponse && (
          <TablePagination
            itemsTotal={quotesResponse.total}
            pageNumber={currentPage}
            perPage={ITEMS_PER_PAGE}
            currentOffset={ITEMS_PER_PAGE * (currentPage - 1)}
            nextOffset={quotesResponse.next || quotesResponse.total}
            onNextClick={() => setPage(currentPage + 1)}
            onPrevClick={() => setPage(currentPage - 1)}
            onToEndClick={() =>
              setPage(Math.ceil(quotesResponse.total / ITEMS_PER_PAGE))
            }
            onToBeginningClick={() => setPage(1)}
          />
        )}
      </TableContainer>
    );
  }
);
