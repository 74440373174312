import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { SearchBlockStyled, SearchFilterBlockStyled } from "./styled";
import {
  palette,
  TextInput,
  IconButton,
  RadioButton,
  RadioButtonGroup,
  RadioButtonChangeEvent,
  SearchIcon,
  ConfigurationIcon,
} from "@busie/ui-kit";
import { ClickAwayListener, Paper } from "@mui/material";

const searchParamOptions = [
  { name: "Contact name", value: "contactName" },
  { name: "Contact email", value: "contactEmail" },
  { name: "Customer group name", value: "customerGroupName" },
];

interface Props {
  type: "quote" | "booking";
}
// ADDED REACT.FC
export const SearchBlock: React.FC<React.PropsWithChildren<Props>> = ({
  type,
}: Props) => {
  const history = useHistory();
  const { search } = useLocation();

  const searchValues = new URLSearchParams(search);

  const [isSearchFormShown, setIsSearchFormShown] =
    React.useState<boolean>(false);

  const [searchParam, setSearchParam] = React.useState<string>(
    searchValues.get("searchParam") || searchParamOptions[0].value
  );
  const [searchValue, setSearchValue] = React.useState<string>(
    searchValues.get("searchValue")?.trim() || ""
  );

  const searchOptions =
    type === "booking"
      ? [...searchParamOptions, { name: "Quote ID", value: "quoteId" }]
      : searchParamOptions;
  const setParams = (searchParam: string, searchValue: string) => {
    searchValues.set("searchParam", searchParam);
    searchValues.set("searchValue", searchValue.trim());
    searchValues.set("page", "1");
    history.push({
      search: `?${searchValues.toString()}`,
    });
  };
  const toggleSearchForm = () => {
    setIsSearchFormShown(!isSearchFormShown);
  };
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams(searchParam, searchValue);
  };
  return (
    <SearchBlockStyled>
      <form onSubmit={submitForm} method="POST">
        <TextInput
          darkBackground={false}
          startAdornment={<SearchIcon color={palette.black.plus30} />}
          placeholder={
            searchOptions.find((item) => item.value === searchParam)?.name
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          endAdornment={
            <IconButton
              onClick={toggleSearchForm}
              highlightOnHover={false}
              icon={<ConfigurationIcon color={palette.black.plus30} />}
            />
          }
        />
      </form>
      {isSearchFormShown && (
        <SearchFilterBlockStyled>
          <ClickAwayListener onClickAway={() => setIsSearchFormShown(false)}>
            <Paper
              elevation={3}
              sx={{
                padding: "15px",
              }}
            >
              <RadioButtonGroup
                value={searchParam}
                name="search-param"
                onChange={(e: RadioButtonChangeEvent) =>
                  setSearchParam(e.target.value)
                }
              >
                {searchOptions.map((item) => {
                  return <RadioButton value={item.value} label={item.name} />;
                })}
              </RadioButtonGroup>
            </Paper>
          </ClickAwayListener>
        </SearchFilterBlockStyled>
      )}
    </SearchBlockStyled>
  );
};
