export enum Amenity {
  ARMREST,
  CUSTOM,
  DVD_PLAYER,
  FOOTRESTS,
  HEADRESTS,
  KNEELING_COACH,
  OUTLETS,
  RECLINING_SEATS,
  RESTROOM,
  SEATBELTS,
  USB_PORTS,
  WIFI,
}

export const AmenityValues = Object.values(Amenity).filter(
  (amen) => typeof amen !== "string"
) as Amenity[];
