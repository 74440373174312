import React, { FC, useState } from "react";

import { Cookbook } from "@busie/utils";
import { FlexContainer, palette } from "@busie/ui-kit";

import RecipeView from "./RecipeView/RecipeView";
import CookbookHeader from "~/rates/RateMenuPage/components/CookbooksTableView/CookbookView/CookbookHeader/CookbookHeader";
import { styled } from "@mui/styles";
import { Collapse } from "@mui/material";

interface Props {
  authToken: string;
  cookbook: Cookbook;
}

interface ContainerProps {
  expanded: boolean;
}

const Container = styled(FlexContainer)((props: ContainerProps) => {
  return {
    // height: 56,
    backgroundColor: props.expanded ? palette.black.plus85 : "#fff",
  };
});

const CookbookView: FC<React.PropsWithChildren<Props>> = ({
  authToken,
  cookbook,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Container direction="column" fullWidth expanded={expanded}>
      <CookbookHeader
        authToken={authToken}
        cookbook={cookbook}
        detailed
        expanded={expanded}
        onExpandToggle={() => setExpanded(!expanded)}
      />

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ width: "100%" }}
      >
        <FlexContainer p={2} direction="column" rowGap={1}>
          {cookbook.recipes.map(
            (recipe, index) =>
              recipe.rule?.id && (
                <RecipeView recipe={recipe} key={index} detailed />
              )
          )}
        </FlexContainer>
      </Collapse>
    </Container>
  );
};

export default CookbookView;
