import { DrawerDetailsBlock } from "@busie/ui-kit";
import React from "react";
import { propertyTuple } from "~/QuotesAndBookingsPage/shared/lib";
import {
  PrimaryPropertyString,
  SecondaryPropertyString,
} from "~/QuotesAndBookingsPage/shared/ui";

interface Props {
  name: string;
  primaryProperties?: propertyTuple[];
  secondaryProperties?: propertyTuple[];
}

// TODO: move this to a shared lib.
export const DrawerObjectDetails: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  primaryProperties = [],
  secondaryProperties = [],
}) => {
  return (
    <DrawerDetailsBlock name={name} collapsible>
      {primaryProperties.map(([name, value], index) => (
        <PrimaryPropertyString
          name={name}
          value={value}
          key={`${name?.toString()} - ${index}`}
        />
      ))}

      {secondaryProperties.map(([name, value], index) => (
        <SecondaryPropertyString
          name={name}
          value={value}
          key={`${name?.toString()} - ${index}`}
        />
      ))}
    </DrawerDetailsBlock>
  );
};
