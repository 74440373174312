import React from "react";
import { Box } from "@mui/system";

import { CustomersEmptyState, H1, Text } from "@busie/ui-kit";

import { AddCustomerButton } from "./AddCustomerButton";

export const EmptyCustomers: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "40px",
        flexGrow: 1,
        h1: {
          textAlign: "center",
          margin: "14px 0 0",
        },
        p: {
          textAlign: "center",
          color: "#6E6E6E",
        },
      }}
    >
      <CustomersEmptyState />
      <div>
        <H1>It's empty here.</H1>
        <Text>Add your first customer.</Text>
      </div>
      <AddCustomerButton />
    </Box>
  );
};
