import { makeAutoObservable } from "mobx";
import { Recipe, RuleType } from "@busie/utils";
import { updateRuleType, updateRuleValue, updateRecipeName } from "@busie/api";
import store from "~/rates/RateMenuPage/store/store";

export interface RecipeEditData {
  name: string;
  id: string;
  type: RuleType;
  value: string | Date;
  ruleId: string;
}

const initialRecipeData: RecipeEditData = {
  name: "",
  id: "",
  type: "LEG_MILES",
  value: "",
  ruleId: "",
};

export class RecipeEdit {
  isModalOpen = false;
  isRuleName = false;
  recipe: RecipeEditData = initialRecipeData;

  constructor() {
    makeAutoObservable(this);
    this.openNameEditModal = this.openNameEditModal.bind(this);
    this.openRuleEditModal = this.openRuleEditModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateIngredientValue = this.updateIngredientValue.bind(this);
  }
  getIsSubmitDisabled(): boolean {
    if (this.isRuleName && this.recipe.name) return false;

    return !this.recipe.type || this.recipe.value == null;
  }
  closeModal(): void {
    this.isModalOpen = false;
  }
  setInitialState(): void {
    this.recipe = initialRecipeData;
    this.isRuleName = false;
  }
  openNameEditModal(recipe: Recipe): void {
    this.setInitialState();
    this.isModalOpen = true;
    this.isRuleName = true;
    this.recipe = {
      ...initialRecipeData,
      name: recipe.name,
      id: recipe.id,
    };
  }
  openRuleEditModal(recipe: Recipe): void {
    this.setInitialState();
    this.recipe = {
      ...initialRecipeData,
      type: recipe.rule.type,
      value:
        recipe.rule.type === "DATE"
          ? new Date(recipe.rule.value)
          : recipe.rule.value,
      ruleId: recipe.rule.id,
    };
    this.isModalOpen = true;
    this.isRuleName = false;
  }
  updateIngredientValue(
    value: string | Date | number,
    type: "name" | "value" | "type"
  ): void {
    this.recipe = { ...this.recipe, [type]: value };
  }
  async updateRecipeRule(authToken: string): Promise<void> {
    await Promise.all([
      updateRuleType(authToken, this.recipe.type, this.recipe.ruleId),
      updateRuleValue(authToken, this.recipe.value, this.recipe.ruleId),
    ]).then(() => this.recipe);
  }

  async submitData(authToken: string): Promise<void> {
    if (!store.rates.activeRecipe) return;
    if (this.isRuleName) {
      await updateRecipeName(authToken, this.recipe.name, this.recipe.id);
      store.rates.setActiveRecipe({
        ...store.rates.activeRecipe,
        name: this.recipe.name,
      });
    } else {
      await this.updateRecipeRule(authToken);
      store.rates.setActiveRecipe({
        ...store.rates.activeRecipe,
        rule: {
          ...store.rates.activeRecipe.rule,
          type: this.recipe.type,
          value: this.recipe.value as string,
        },
      });
    }
    this.closeModal();
  }
}
