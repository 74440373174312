import { styled } from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import palette from "../../theme/palette";

const ToolbarStyled = styled(Toolbar)(() => {
  return {
    "&": {
      paddingLeft: 0,
      justifyContent: "space-between",

      span: {
        color: palette?.black?.plus10,
        fontSize: "15px",
      },
    },
  };
});

export default ToolbarStyled;
