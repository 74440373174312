import React from "react";
import { observer } from "mobx-react-lite";
import { H4, InputText, FlexContainer, palette } from "@busie/ui-kit";
import { Vehicle, VehicleTypes } from "@busie/utils";
import { VehicleIcon, mapVehicleTypeToName } from "@busie/core";
import store from "~/vehicles/store";

import VehicleSelect from "./VehicleSelect";

interface Props {
  vehicles?: Vehicle[];
  vehicleTypes?: VehicleTypes[];
}

const VehicleSelectionField: React.FC<React.PropsWithChildren<Props>> =
  observer(({ vehicles, vehicleTypes }: Props) => {
    return (
      <div>
        {vehicles && (
          <H4 color={palette?.black?.plus40}>License plate number</H4>
        )}
        <FlexContainer direction="column" rowGap={2}>
          {vehicles?.map((vehicle) => {
            return (
              <FlexContainer columnGap={2} key={vehicle.id} align="center">
                <FlexContainer align="center" columnGap={1} w={160}>
                  <VehicleIcon type={vehicle.vehicleType.type} size={24} />
                  <InputText>
                    {mapVehicleTypeToName(vehicle.vehicleType.type)}
                  </InputText>
                </FlexContainer>
                <div>
                  <InputText>{vehicle.licensePlate}</InputText>
                </div>
              </FlexContainer>
            );
          })}
          {vehicleTypes?.map((vehicleType, index) => {
            return (
              <FlexContainer columnGap={2} key={index} align="center">
                <FlexContainer align="center" columnGap={1} w={160}>
                  <VehicleIcon type={vehicleType} size={24} />
                  <InputText>{mapVehicleTypeToName(vehicleType)}</InputText>
                </FlexContainer>

                <FlexContainer w={160}>
                  <VehicleSelect
                    index={index}
                    vehicles={store.vehicles}
                    vehicleType={vehicleType}
                  />
                </FlexContainer>
              </FlexContainer>
            );
          })}
        </FlexContainer>
      </div>
    );
  });

export default VehicleSelectionField;
