import styled from "@emotion/styled";
import palette from "../../theme/palette";

export const DividerStyled = styled("div")<{ margin?: number; width?: string }>(
  ({ margin = 20, width = "100%" }) => ({
    width: width,
    minWidth: width,
    height: "1px",
    minHeight: "1px",
    backgroundColor: palette.black.plus70,
    margin: `${margin}px 0px`,
  })
);
