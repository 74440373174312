import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormContainer, TextInput, Button, Select } from "@busie/ui-kit";
import { GROUP_TYPES, Group } from "@busie/utils";
import { schema, FormValues } from "./schema";
import { capitalize } from "lodash";

interface Props {
  group?: Pick<Partial<Group>, "name" | "type">;
  onSubmit: (data: FormValues) => void;
  onCancel: () => void;
}

export const GroupForm: React.FC<Props> = ({
  group: { name, type } = {},
  onSubmit,
  onCancel,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      name: name ?? "",
      type: type ?? "OTHER",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => () => reset(), [reset]);

  return (
    <FormContainer title="Edit Group">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              autoComplete="off"
              aria-autocomplete="none"
              label="Name"
              placeholder="Acme Corp"
              errorMessage={errors.name?.message}
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Group Type"
              items={GROUP_TYPES.map((value) => ({
                value,
                name: capitalize(value),
              }))}
              errorMessage={errors.type?.message}
            />
          )}
        />

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            type="button"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
            loading={isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
