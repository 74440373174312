import { styled, Theme } from "@mui/system";
import { buttonClasses } from "@mui/material/Button";

interface Props {
  embedded?: boolean;
  theme?: Theme;
}

const QuoteFormStyled = styled("div")<Props>(({ embedded, theme }) => {
  const palette = theme?.palette;
  return {
    padding: embedded ? "30px 15px" : "0",
    width: "100%",
    margin: "auto",
    backgroundColor: palette?.black?.plus85,
    [theme.breakpoints.down("tablet")]: {
      padding: "0 0 30px 0",
      [`.${buttonClasses.root}`]: {
        width: "100%",
        maxWidth: "none !important",
      },
    },
  };
});

export default QuoteFormStyled;
