import React from "react";

import { Badge, SmallBadge } from "@busie/ui-kit";
import { ReservationStatuses } from "@busie/utils";
import { getReservationStatusBadgeContents } from "../CalendarView/helpers";

interface Props {
  status: ReservationStatuses;
  maxWidth: string;
  quantity?: number;
  isMobile?: boolean;
}

const ReservationStatusBadge: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const contents = getReservationStatusBadgeContents(props.status);
  return props.isMobile ? (
    <SmallBadge color={contents.color} text={contents.text}></SmallBadge>
  ) : (
    <Badge
      maxWidth={props.maxWidth}
      color={contents.color}
      background={contents.background}
      margin={"4px 0"}
    >
      {`${contents.text}${props.quantity ? ` (${props.quantity})` : ""}`}
    </Badge>
  );
};

export default ReservationStatusBadge;
