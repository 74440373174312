import React from "react";

import { FlexContainer, CheckmarkIcon, Subtitle, Title } from "@busie/ui-kit";
import FormContainer from "../FormContainer";

const OnSubmitMessageBlock: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <FormContainer>
      <FlexContainer align="center" direction="column" rowGap={2} pb={5}>
        <CheckmarkIcon size={"100"} />
        <Title>Thank you!</Title>
        <Subtitle align="center">
          We’ve received your Quote and someone will contact you shortly
        </Subtitle>
        <Subtitle align="center">
          Please note that this quote is an estimate, and is subject to change
          at the discretion of the transportation provider. Below is a
          non-exhaustive list of items which may affect the initially quoted
          price:
        </Subtitle>
        <ul>
          <li>Long Trips</li>
          <li>Overnight Trips</li>
          <li>Alcohol Fees</li>
          <li>Gas Surcharges (current Gas Price)</li>
          <li>Toll Fees</li>
        </ul>
      </FlexContainer>
    </FormContainer>
  );
};

export default OnSubmitMessageBlock;
