import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { styled } from "@mui/system";

import {
  FlexContainer,
  IconButton,
  Menu,
  MoreIcon,
  Text,
  palette,
} from "@busie/ui-kit";
import { Ingredient } from "@busie/utils";
import { currencyFormatter, centsToDollars } from "@busie/utils";
import store, {
  pricePerMileToPricePerKm,
  shouldConvertIngredientValue,
} from "~/rates/RateMenuPage/store";

import DeleteIngredientPopup from "../DeletePopup/DeleteIngredientPopup";

import {
  ingredientTypes,
  baseIngredientTypes,
} from "~/rates/RateMenuPage/store/constants";
import { useFlags } from "launchdarkly-react-client-sdk";

interface Props {
  ingredient: Ingredient;
}

const TypePill = styled(Text)(
  () => `
  color: ${palette?.black?.plus20};
  border-radius: 8px;
  padding: 0 12px;
  display: inline-block;
`
);

const BaseTypePill = styled(TypePill)(
  () => `
  background-color: ${palette?.black?.plus80};
`
);

const SideTypePill = styled(TypePill)(
  () => `
  background-color: #fff;
  border: 1px solid ${palette?.black?.plus70};
`
);

const Container = styled(FlexContainer)(() => ({
  backgroundColor: "#fff",
  position: "relative",
}));

const IngredientCard: FC<React.PropsWithChildren<Props>> = observer(
  ({ ingredient }) => {
    const [showDeletePopup, setShowDeletePopup] =
      React.useState<boolean>(false);

    const { useMetricSystem } = useFlags<{ useMetricSystem: boolean }>();

    const ingredientValue =
      useMetricSystem && shouldConvertIngredientValue(ingredient.type)
        ? Math.ceil(
            parseFloat(pricePerMileToPricePerKm(ingredient.value as string))
          )
        : parseInt(ingredient.value as string);

    return (
      <Container fullWidth py={2} px={3} align="center">
        <FlexContainer className="ingredient-column">
          {baseIngredientTypes.includes(ingredient.type) ? (
            <BaseTypePill>Base</BaseTypePill>
          ) : (
            <SideTypePill>Side</SideTypePill>
          )}
        </FlexContainer>
        <Text className="name-column">{ingredient.name}</Text>
        <Text className="type-column">
          {ingredientTypes[ingredient.type] || ingredient.type}
        </Text>
        <Text className="value-column">
          {currencyFormatter(centsToDollars(ingredientValue))}
        </Text>
        <div className="actions-column">
          <Menu
            top="0"
            right="100%"
            toggler={<IconButton icon={<MoreIcon />} />}
            items={[
              {
                content: "Edit",
                action: () => store.ingredientModal.openEditModal(ingredient),
              },
              { content: "Delete", action: () => setShowDeletePopup(true) },
            ]}
          />
        </div>
        <DeleteIngredientPopup
          onClose={() => setShowDeletePopup(false)}
          id={ingredient.id}
          isPopupOpened={showDeletePopup}
        />
      </Container>
    );
  }
);

export default IngredientCard;
