import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@busie/utils";
import React from "react";
import { useCreateCustomerNote } from "./model";
import { CreateNoteForm } from "~/CustomersPage/entity";

interface Props {
  customerId: string;
  onSuccess: () => void;
  onError: (e: unknown) => void;
}

export const AddNote: React.FC<Props> = ({
  customerId,
  onSuccess,
  onError,
}) => {
  const { user } = useAuth0<User>();

  const { mutateAsync: createNote } = useCreateCustomerNote(customerId);

  const onSubmit = async ({ text }: { text: string }) =>
    createNote({ text }, { onSuccess, onError });

  return (
    <CreateNoteForm
      name={user?.name || ""}
      imgSrc={user?.picture || ""}
      onSubmit={onSubmit}
    />
  );
};
