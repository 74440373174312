import React from "react";
import { getExpirationDateDonutProps } from "./lib";
import { ColoredProgress, Container, GrayCircle } from "./styled";

export interface TTLDonutProps {
  expirationDate?: string;
}

export const TTLDonut: React.FC<React.PropsWithChildren<TTLDonutProps>> = ({
  expirationDate,
}) => {
  const props = getExpirationDateDonutProps(expirationDate);
  return (
    <Container>
      <GrayCircle
        size={`${props.size}%`}
        value={100}
        thickness={7}
        variant="determinate"
      />
      <ColoredProgress
        size={`${props.size}%`}
        value={props.value}
        thickness={7}
        variant="determinate"
        color={props.color}
      />
    </Container>
  );
};
export default TTLDonut;
