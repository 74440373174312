import { QuoteAndBookingStatus } from "@busie/utils";
import {
  INVALID_ACTION_MODAL_COPY,
  CONFIRM_BOOKING_MODAL_COPY,
  ModalCopy,
  MARK_PAID_MODAL_COPY,
  CANCEL_BOOKING_MODAL_COPY,
} from "./constants";

export const getModalCopy = (newStatus: QuoteAndBookingStatus): ModalCopy => {
  let result = INVALID_ACTION_MODAL_COPY;

  switch (newStatus) {
    case QuoteAndBookingStatus.CONFIRMED:
      result = CONFIRM_BOOKING_MODAL_COPY;
      break;
    case QuoteAndBookingStatus.PAID:
      result = MARK_PAID_MODAL_COPY;
      break;
    case QuoteAndBookingStatus.CANCELED:
      result = CANCEL_BOOKING_MODAL_COPY;
      break;
    default:
      break;
  }

  return result;
};
