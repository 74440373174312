import React from "react";
import { Vehicle } from "@busie/utils";
import groupBy from "lodash.groupby";
import { FlexContainer, H4, palette, theme } from "@busie/ui-kit";
import AvailableVehicleType from "./AvailableVehicleType";

interface Props {
  availableVehicles: Vehicle[];
  selectedTypes: {
    [key: string]: number;
  };
  setSelectedTypes: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
}

const AvailableVehicleTypesList: React.FC<React.PropsWithChildren<Props>> = ({
  availableVehicles,
  selectedTypes,
  setSelectedTypes,
}) => {
  const vehiclesPerType = groupBy(availableVehicles, "vehicleTypeId");

  return (
    <FlexContainer
      direction="column"
      sx={{
        padding: theme?.spacing(2),
        overflowY: "scroll",
        height: "350px",
      }}
    >
      <H4 color={palette?.black?.plus40}>
        Choose vehicle type you want to reserve
      </H4>
      {Object.keys(vehiclesPerType).map((typeId) => (
        <AvailableVehicleType
          selectedVehiclesAmount={selectedTypes[typeId] || 0}
          setSelectedTypes={setSelectedTypes}
          key={typeId}
          vehicleTypeId={typeId}
          vehiclesNumber={vehiclesPerType[typeId].length}
        />
      ))}
    </FlexContainer>
  );
};

export default AvailableVehicleTypesList;
