import { FlexContainer, palette, theme } from "@busie/ui-kit";
import React from "react";

export const PopupListItemWrapper: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <FlexContainer
      align="center"
      justify="space-between"
      sx={{
        backgroundColor: palette?.black?.plus80,
        minHeight: theme?.spacing(6),
        width: "100%",
        padding: `0 ${theme?.spacing(1)}`,
        marginBottom: theme?.spacing(0.5),
        userSelect: "none",
        display: "flex",
        borderRadius: theme?.spacing(0.5),
      }}
    >
      {children}
    </FlexContainer>
  );
};
