import React from "react";
import { capitalize } from "../../helpers";

import palette from "../../theme/palette";
import { StatusTextWithDot } from "./StatusTextWithDot";

interface Props {
  color: string;
  value: string;
}
export const ContractStatusText: React.FC<React.PropsWithChildren<Props>> = ({
  color = palette.black.main,
  value,
}: Props) => {
  return <StatusTextWithDot color={color} status={capitalize(value)} />;
};
