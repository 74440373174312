import { Button } from "@busie/ui-kit";
import React from "react";

interface Props {
  onClick: () => void;
}

export const VoidContractButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
}) => (
  <Button typestyle="secondary" onClick={onClick}>
    Void
  </Button>
);
