import { palette } from "@busie/ui-kit";
import { Box } from "@mui/system";
import store from "~/vehicles/store";
import { addHours, startOfDay } from "date-fns";
import { observer } from "mobx-react";
import React from "react";
import HeaderCell from "./HeaderCell";

const Header: React.FC<React.PropsWithChildren<unknown>> = observer(() => {
  const vehiclesTotal = store.vehicles.length;
  const hourHeaders: JSX.Element[] = [];

  for (let i = 0; i < 24; i++) {
    const hour = addHours(startOfDay(store.selectedCalendarDay), i);
    hourHeaders.push(
      <HeaderCell i={i} hour={hour} selectedDay={store.selectedCalendarDay} />
    );
  }

  return (
    <>
      <Box
        sx={{
          height: "73px",
          backgroundColor: palette.black.plus85,
          display: "flex",
          alignItems: "flex-end",
          padding: "13px 16px",
        }}
      >
        Total vehicles ({vehiclesTotal})
      </Box>
      {hourHeaders}
    </>
  );
});

export default Header;
