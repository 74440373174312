import React from "react";
import { palette, SmallBadge } from "@busie/ui-kit";

import { Badge } from "@busie/ui-kit";

interface Props {
  type: string;
  maxWidth?: string;
  isTablet?: boolean;
}

const getBadgeContents = (type: string) => {
  switch (type) {
    case "ACCEPTED":
      return {
        text: "Accepted",
        background: palette.green.plus55,
        color: palette.green.minus5,
      };
    case "PENDING":
      return {
        text: "Pending",
        background: palette.supernova.plus40,
        color: palette.supernova.minus10,
      };
    case "CANCELED":
      return {
        text: "Canceled",
        background: palette.red.plus45,
        color: palette.red.minus5,
      };

    case "DECLINED":
      return {
        text: "Declined",
        background: palette.red.plus45,
        color: palette.red.minus5,
      };

    case "IN_PROGRESS":
      return {
        text: "In progress",
        background: palette.purple.plus40,
        color: palette.purple.minus10,
      };
    case "PAID":
      return {
        text: "Paid",
        background: palette.green.plus55,
        color: palette.green.minus5,
      };
    case "COMPLETED":
      return {
        text: "Complete",
        background: palette.black.plus80,
        color: palette.black.plus10,
      };
    default:
      return {
        text: "Confirmed",
        background: palette.blue.plus40,
        color: palette.blue.minus10,
      };
  }
};

export const StatusBadge: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const contents = getBadgeContents(props.type);
  if (props.isTablet) {
    return <SmallBadge color={contents.color} text={contents.text} />;
  }
  return (
    <Badge
      color={contents.color}
      background={contents.background}
      maxWidth={props.maxWidth}
      minWidth="120px"
    >
      {contents.text}
    </Badge>
  );
};
