import * as yup from "yup";

const IS_REQUIRED = "is required";

const requiredString = () => yup.string().required(IS_REQUIRED);
const requiredNumber = () => yup.number().required(IS_REQUIRED);

export const schema = yup.object({
  bookingId: requiredString(),
  amount: yup.number().moreThan(0).required(IS_REQUIRED),
  customer: yup
    .object({
      id: requiredString(),
      groupId: requiredString(),
      groupName: requiredString(),
      name: requiredString(),
      email: requiredString().email("must be a valid email"),
    })
    .required(IS_REQUIRED),
  revenueShareConfigId: requiredString(),
  daysUntilDue: requiredNumber().moreThan(0, "must be at least one day"),
});
