import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { FormContainer } from "@busie/ui-kit";
import {
  InputLabel,
  TextInput,
  ErrorMessage,
  palette,
  Button,
  PageLoader,
  NumberInput,
} from "@busie/ui-kit";

import { validationSchema } from "./validationSchema";
import { customersModel } from "..";
import { Customer } from "@busie/utils";
import PhoneInput, {
  DefaultInputComponentProps,
} from "react-phone-number-input/react-hook-form-input";

interface OrganizationOption {
  label: string;
  value: string;
}

interface FormValues {
  name: string;
  email: string;
  phoneNumber: string;
  organization: OrganizationOption;
}

interface Props {
  customer?: Customer;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  organizationId?: string;
}

export const CustomerForm: React.FC<React.PropsWithChildren<Props>> = ({
  customer,
  onSubmit,
  onCancel,
  organizationId,
}) => {
  // TODO: [BUSIE-1460] Disable new group creation/selection if the organizationId is passed.
  const mode = customer ? "update" : "create";
  const { isFetched: areOrganizationsFetched, data: organizations = [] } =
    customersModel.useCustomersOrganizations();
  const filter = createFilterOptions<OrganizationOption>();
  const customerOrganization = customersModel.useCustomersOrganization(
    customer?.groupIds[0] || organizationId || ""
  );

  const organizationInitialValue = useMemo(
    () =>
      customerOrganization
        ? {
            label: customerOrganization.name,
            value: customerOrganization.id,
          }
        : {
            label: "",
            value: "",
          },
    [customerOrganization]
  );

  const defaultValues: FormValues = {
    name: customer?.name || "",
    email: customer?.email || "",
    phoneNumber: customer?.phoneNumber || "",
    organization: organizationInitialValue,
  };

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("organization", organizationInitialValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [organizationInitialValue, setValue]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const organizationOptions = useMemo(() => {
    const options = organizations.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
    const newOrgs: OrganizationOption[] = options;

    options.forEach((norg) => {
      const matches = options.filter((o) => o.label === norg.label);
      const hasDuplicates = matches.length > 1;

      if (hasDuplicates) {
        matches.forEach((match, matchIndex) => {
          const index = options.findIndex((x) => x.value === match.value);

          if (matchIndex > 0) {
            newOrgs[index].label = `${newOrgs[index].label} (${matchIndex})`;
          }
        });
      }
    });

    return newOrgs;
  }, [organizations]);

  return (
    <FormContainer title={mode === "create" ? "New customer" : "Edit customer"}>
      {areOrganizationsFetched ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ height: "90px" }}>
            <InputLabel>Organization</InputLabel>
            <Controller
              name="organization"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={organizationOptions}
                  disablePortal
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  // TODO:
                  getOptionLabel={(option) =>
                    (option as { label: string; value: string }).label || ""
                  }
                  onChange={(_, value) => {
                    if (value === null) return;
                    if (typeof value === "string") {
                      setValue(
                        "organization",
                        { label: value, value },
                        { shouldDirty: true, shouldValidate: true }
                      );
                    } else {
                      setValue("organization", value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.label
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        value: inputValue,
                        label: `Create "${inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select or create new"
                      sx={{
                        ".MuiOutlinedInput-root": {
                          height: "40px",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: `1px solid ${palette?.black?.plus10}`,
                        },
                      }}
                    />
                  )}
                />
              )}
            />
            <ErrorMessage style={{ marginBottom: "13px" }}>
              {errors.organization?.value?.message}
            </ErrorMessage>
          </div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Full Name"
                placeholder="e.g. Jim Figo"
                errorMessage={errors.name?.message}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Email"
                errorMessage={errors.email?.message}
              />
            )}
          />

          <PhoneInput
            defaultCountry="US"
            control={control}
            name="phoneNumber"
            label="Phone Number"
            rules={{ required: true }}
            inputComponent={
              TextInput as DefaultInputComponentProps["inputComponent"]
            }
            errorMessage={errors.phoneNumber?.message}
            required
          />

          <div className="form-submit-control">
            <Button
              typestyle="secondary"
              type="button"
              onClick={onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!isDirty || !isValid || isSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      ) : (
        <PageLoader />
      )}
    </FormContainer>
  );
};
