import React from "react";

interface Props {
  size?: number | string;
  color?: string;
}

export const PathIcon: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const size = props.size || 24;
  const color = props.color || "#323232";
  return (
    <svg
      width={size}
      height={size}
      style={{
        minHeight: size,
        minWidth: size,
      }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.27614 3.05752C5.79684 3.57822 5.79684 4.42244 5.27614 4.94314C4.75545 5.46384 3.91122 5.46384 3.39052 4.94314C2.86983 4.42244 2.86983 3.57822 3.39052 3.05752C3.91122 2.53682 4.75544 2.53682 5.27614 3.05752"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.156 12H5.75133C4.784 12 4 11.1047 4 10.0007V9.99867C4 8.89467 4.784 7.99933 5.75133 7.99933H10.248C11.2153 7.99933 11.9993 7.104 11.9993 6V6C12 4.894 11.2147 3.99867 10.2467 4L7.5 4.00333"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.894 13.4556L12.82 12.3163C13.0607 12.1736 13.0607 11.8249 12.82 11.6829L10.894 10.5436C10.6467 10.3969 10.334 10.5756 10.334 10.8629V13.1363C10.334 13.4236 10.6467 13.6023 10.894 13.4556Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
