import * as yup from "yup";

import { GROUP_TYPES, GroupType } from "@busie/utils";

export interface FormValues {
  name: string;
  type: GroupType;
}

export const schema = yup.object({
  name: yup.string().required("Name is required"),
  type: yup.string().oneOf(GROUP_TYPES).required("Group type is required"),
});
