import { Location, RouteStop } from "@busie/utils";

export const routeStopToLocation = (stop: RouteStop): Omit<Location, "_id"> => {
  return {
    id: stop.id || "",
    longitude: stop.geometry.location.lng(),
    latitude: stop.geometry.location.lat(),
    address: stop.formatted_address,
  };
};
