import React, { useCallback } from "react";
import { styled } from "@mui/system";
import { Button, FlexContainer, ChevronPrev, AddIcon } from "@busie/ui-kit";
import { useHistory } from "react-router";
import VehicleFleetFilter from "./VehicleFleetFilter";

const Header = styled("div")(() => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "48px",
  };
});

const Container = styled("div")(() => ({
  width: "100%",
  maxWidth: 1360,
  marginBottom: "40px",
}));

const ButtonContainer = styled(FlexContainer)(() => ({
  padding: "20px",
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const VehicleListContainer: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => {
  const { children } = props;
  const history = useHistory();
  const onBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <Header>
        <Button
          typestyle="tertiary"
          startIcon={<ChevronPrev />}
          onClick={onBackClick}
        >
          Back
        </Button>

        <h2>My vehicle fleet</h2>
        <span />
      </Header>

      <ButtonContainer justify="space-between" bordered>
        <FlexContainer justify="flex-start" align="center">
          {/* Commenting out based on PT-210  */}
          {/* <Button
            typestyle="secondary"
            startIcon={<Stats />}
            onClick={() => history.push("/vehicles/my-vehicles/stats")}
          >
            Statistics
          </Button> */}
          <VehicleFleetFilter />
        </FlexContainer>

        <FlexContainer columnGap={3} justify="flex-end" align="center">
          {/* Commenting out based on PT-209  */}
          {/* <Button typestyle="secondary" startIcon={<Upload />}>
            Upload CSV
          </Button> */}

          <Button
            startIcon={<AddIcon />}
            onClick={() => history.push("/vehicles/new-vehicle")}
          >
            Add vehicle
          </Button>
        </FlexContainer>
      </ButtonContainer>

      {children}
    </Container>
  );
};

export default VehicleListContainer;
