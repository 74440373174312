import React from "react";
import cx from "classnames";

import { datetimeFormatter, TripLeg } from "@busie/utils";

import { LegDateTimeStyled } from "./styled";

interface Props {
  leg: TripLeg;
  dispatchLocationName: string;
  isDeadLeg?: boolean;
  isFirstLeg?: boolean;
  isLastLeg?: boolean;
}

export const LegBlockContent: React.FC<React.PropsWithChildren<Props>> = ({
  leg,
  dispatchLocationName,
  isFirstLeg = false,
  isLastLeg = false,
  isDeadLeg = false,
}) => {
  const start =
    isDeadLeg && isFirstLeg
      ? dispatchLocationName
      : leg._startLocation.address || "";

  const destination =
    isDeadLeg && isLastLeg
      ? dispatchLocationName
      : leg._destinationLocation.address || "";

  const departureDateTimeText = `Departure - ${datetimeFormatter(
    new Date(leg._departureDateTime),
    "departure-arrival"
  )}`;

  const arrivalDateTimeText = `Estimated Arrival - ${datetimeFormatter(
    new Date(leg._arrivalDateTime),
    "departure-arrival"
  )}`;

  return (
    <div
      className={cx({
        departures: true,
        end: !destination,
        start: !start,
        last: isLastLeg,
        first: isFirstLeg,
      })}
    >
      <div className={cx({ origin: true, start: !start })}>
        {start || "Dispatch Location"}
        <LegDateTimeStyled>{departureDateTimeText}</LegDateTimeStyled>
      </div>
      <div className={cx({ destination: true, end: !destination })}>
        {destination || "Dispatch Location"}
        <LegDateTimeStyled>{arrivalDateTimeText}</LegDateTimeStyled>
      </div>
    </div>
  );
};
