import { palette } from "@busie/ui-kit";
import { styled } from "@mui/system";

export const BookingRequestedStyled = styled("div")(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "13.33px 14.33px",
    columnGap: "9.33px",
    borderRadius: "4px",
    backgroundColor: palette.green.plus55 || "#E0FFE3",
    color: palette.green.minus5 || "#00AD11",
  };
});
