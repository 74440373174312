export const makeStringSnakeCased = (value?: string): string => {
  if (!value) return "unnamed";
  return value.toLocaleLowerCase().split(" ").join("_");
};

export const createHtmlAttrId = (value?: string): string => {
  if (!value) return "unnamed";
  const str = makeStringSnakeCased(value);
  return str.replace(/^[^a-z]+|[^\w:.-]+/gi, "");
};

export const capitalize = (phrase: string): string =>
  phrase
    .toLowerCase()
    .replace(/_/g, " ")
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");
