import React from "react";

import BadgeStyled, { SmallBadgeStyled } from "./styled/BadgeStyled";

type Props = React.PropsWithChildren<{
  maxWidth?: string;
  color?: string;
  background?: string;
  minWidth?: string;
  margin?: string;
}>;

const Badge: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  return (
    <BadgeStyled
      color={props.color}
      background={props.background}
      maxWidth={props.maxWidth}
      minWidth={props.minWidth}
      margin={props.margin}
    >
      {props.children}
    </BadgeStyled>
  );
};

export const SmallBadge: React.FC<
  React.PropsWithChildren<{ color?: string; text?: string }>
> = (props) => {
  return <SmallBadgeStyled title={props.text} background={props.color} />;
};

export default Badge;
