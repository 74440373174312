import { InvoiceStatus } from "@busie/utils";

export const MARK_PAID_INVOICE_SET: Set<InvoiceStatus> = new Set([
  "FINALIZED",
  "SENT",
]);

export const PREVIEW_INVOICE_SET: Set<InvoiceStatus> = new Set([
  "FINALIZED",
  "SENT",
  "PAID",
  "PAYMENT_FAILED",
]);

export const VOID_INVOICE_SET: Set<InvoiceStatus> = new Set([
  "FINALIZED",
  "SENT",
]);
