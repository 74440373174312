import React, { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { RadioGroup } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { CreatePaymentLinkData } from "@busie/api";
import {
  Button,
  Divider,
  FlexContainer,
  FormContainer,
  NoteText,
  palette,
  Text,
  TextInput,
} from "@busie/ui-kit";

import { getPaymentOptions, schema } from "./model";
import { PaymentAmountOption } from "./ui/PaymentAmountOption";

interface Props {
  bookingId: string;
  balance: number;
  contactEmail: string;
  onSubmit: (data: CreatePaymentLinkData) => void;
  onCancel: () => void;
}

export const Form: React.FC<React.PropsWithChildren<Props>> = ({
  bookingId,
  balance,
  contactEmail,
  onSubmit,
  onCancel,
}) => {
  const defaultValues = useMemo(
    () => ({
      contactEmail,
      bookingIds: [bookingId],
      amount: balance,
    }),
    [bookingId, balance, contactEmail]
  );

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<CreatePaymentLinkData>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => () => reset(), [reset, setValue, defaultValues]);

  const paymentOptions = getPaymentOptions(balance);

  return (
    <FormContainer title="Create Payment Link">
      <Text>Are you sure you want to create (and send) a payment link?</Text>

      <NoteText>
        This will automatically send a branded email to the customer with a{" "}
        summary of their balance due on this booking, and a link to pay the{" "}
        amount specified here.
      </NoteText>

      <br />

      <NoteText color={palette.red.main}>
        This action cannot be undone.
      </NoteText>

      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexContainer
          align="flex-start"
          direction="column"
          columnGap={2}
          fullWidth
        >
          <Controller
            name="contactEmail"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Contact Email"
                fullWidth
                errorMessage={errors.contactEmail?.message}
              />
            )}
          />

          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} sx={{ width: "100%" }}>
                {paymentOptions.map(({ label, value, amount, disabled }) => (
                  <PaymentAmountOption
                    key={label}
                    amount={amount}
                    value={value}
                    label={label}
                    disabled={disabled}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FlexContainer>

        <div className="form-submit-control">
          <Button
            typestyle="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            typestyle="primary"
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Create Payment Link
          </Button>
        </div>
      </form>
    </FormContainer>
  );
};
